(function () {


    var app = angular.module('phapApp');

    app.component('incidentDetails', {
        templateUrl: 'app/_components/incidentDetails.html',
        bindings: {
            item: '<' //create/edit
        },
        controller: function () {
        },
        controllerAs: 'incidentDetails'
    });

})();