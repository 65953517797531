(function () {

    var app = angular.module('phapApp');

    app.component('displayGrid', {
        templateUrl: 'app/administration/security/Components/displayGrid.html',
        bindings: {
            gridOptions: '=',
            originalDataSet: '=',
            gridApi: '=',
            updatedList: '=',
            statusUpdateCallback:'='
        },
        controller: ['$rootScope', '$scope', 'uiGridConstants', 'utils', function ($rootScope, $scope, uiGridConstants, utils) {

            var vm = this;
            vm.updatedList = [];
            vm.rowtpl = '<div ng-class="{\'row-deleted\':row.entity.isDeleted}"><div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader,\'row-group-header\':col.groupHeader }" ui-grid-cell></div></div>';
            vm.$onInit = function () {
                vm.gridOptions.rowTemplate = vm.rowtpl;
                vm.gridOptions.onRegisterApi = function (gridApi) {
                    vm.gridApi = gridApi;
                }
            }

            $scope.deleteRow = function (rowEntity) {
                var title = 'Warning';
                var content = "Are you sure you want to mark this permission as delete?";
                utils.confirm(title, content, 'Yes', 'No')
                    .then(function () {
                        var index = vm.gridOptions.data.indexOf(rowEntity);
                        rowEntity.isDeleted = true;
                        rowEntity.isUpdated = true;
                        if (vm.isCreateNewVersion) {
                            vm.isUpdated = true;
                            vm.updatedList[index] = rowEntity;
                        } else {
                            vm.updatedList.push(rowEntity);
                        }
                        vm.statusUpdateCallback(vm.updatedList);
                        },
                    function () {
                        return;
                    });
            }


        }],
        controllerAs: 'displayGridVm'
    });

})();