(function () {
	angular.module('phapApp')
		.factory('Constants', function () {
			
			var cardActivityRequestTypeCodes = {
				preloadCards: 'PreloadCards',
				addFundsToPreloadCards: 'AddFundsToPreloadCards',
				removeFundsFromPreloadedcards: 'RemoveFundsFromPreloadedcards',
				cancelPreloadedAndUnusedCards: 'CancelPreloadedAndUnusedCards',
				cancelIssuedCards: 'CancelIssuedCards',
				cancelLostIssuedCards: 'CancelLostIssuedCards',
				cancelStolenIssuedCards:'CancelStolenIssuedCards',
				cancelExpiringIssuedCards: 'CancelExpiringIssuedCards',				
				removeFundsFromIssuedcards: 'RemoveFundsFromIssuedcards',
				cancelExpiredCards:'CancelExpiredCards'
			};
			var cardActivityRequestOrderStatusCodes = {
				draft: { id: 1, description: 'Draft' },
				pendingApproval: { id: 2, description: 'Pending approval' },
				pendingReview: { id: 3, description: 'Pending review' },
				inProgress: { id: 4, description: 'In progress' },
				closed: { id: 5, description: 'Closed' },
				cancelled: { id: 6, description: 'Cancelled' },
				error: { id: 7, description: 'Error' },
				amendedAndClosed: { id: 8, description: 'Amended and closed' },
				deleted: { id: 9, description: 'Deleted' }
			};
			var cardActivityRequestStatusCodes = {
				draft: { id: 1, description: 'Draft' },
				processing: { id: 2, description: 'Processing' },
				successful: { id: 3, description: 'Successful' },
				failed: { id: 4, description: 'Failed' },
				removed: { id: 5, description: 'Removed' },
				cancelled: { id: 6, description: 'Cancelled' },
				deleted: { id: 7, description: 'Deleted' }
			};

			return {
				
				configuration: {
					minAdults: 0,
					maxAdults: 20,
					minChildren: 0,
					maxChildren: 50
				},
				barcode: {
					length: 16,
					autoDetectDelay: 100 // delay in milliseconds to auto detect a scanned card!
				},
				cardType: [
					{ Id: false, Name: "Single load" },
					{ Id: true, Name: "Reloadable" }
				],
				backgroundProcessMessage: "The PHAP application is currently processing this request. You will be notified once the request has completed. Select OK to continue working",
				backgroundMessageDescriptive:"The PHAP application is currently processing this assignment request. This process may take several seconds when assigning IDs to a large number of cards. PHAP will automatically refresh your page to the Card Search on completion of this request.",

				//card scanned related constants
				cardLength: "11",
				cardLengthErrorMessage: "Card ID must be 11 digits long. Card ID is invalid.",
				//
				//cardPayment validation error messages
				cardInactive: "The selected card in not in a valid state.",
				amountExceeded: "Amount exceeds the remaining amount.",
				cardNoCheckout: "The card selected has not been checked out and can not be applied to any payment. To continue making this payment select a card that has been checked out to this incident",
				cardCheckoutToDifferentIncident: "The card selected for this payment is checked out and assigned to a different Incident. To continue making this payment select a card that has been checked out to this incident",
				cardsStatusInvalid: "One or more cards do not have a valid status.",
				cardsStatusInactive: "One or more cards do not have a active status.",
				//

				mode: {
					create: 'create',
					edit: 'edit',
					view: 'view',
					withdraw: 'withdraw'
				},
				TransportType: {
					courier: 1,
					staff: 2
				},
				locationType: {
					centralHeadOffice: 1,
					regionHeadOffice: 2,
					regionSubOffice: 3,
					reliefCenter: 4
				},
				IncidentStatus: {
					Open: 0,
					Closed: 1
				},
                Privileges: {
                    Reports: 'Reports',
					FulfillShipmentOrder: 'FulfillShipmentOrder'
				},
				// Relief
				ReliefApplicationState: {
					Eligible: 0,
					Ineligible: 1,
					Withdrawn: 2,
					Active: 3,
					Closed: 4,
					Draft: 5,
					ClientDraft: 6,
					Submitted: 7,
					PendingAssessment: 8
				},
				ReliefApplicationStateNames: [
					{ Id: 0, Name: 'Eligible' },
					{ Id: 1, Name: 'Ineligible' },
					{ Id: 2, Name: 'Withdrawn' },
					{ Id: 3, Name: 'Active' },
					{ Id: 4, Name: 'Closed' },
					{ Id: 5, Name: 'Draft' },
					{ Id: 6, Name: 'Client draft' },
					{ Id: 7, Name: 'Submitted' },
					{ Id: 8, Name: 'Pending Assessment' }
				],
				// Re-establishment
				YesNoNotyet: {
					No: 0,
					NotYet: 1,
					Yes: 2
				},
				// Re-establishment
				ReestablishApplicationState: {
					Eligible: 0,
					Ineligible: 1,
					Withdrawn: 2,
					Active: 3,
					Closed: 4,
					Draft: 5
				},
				contactMethods: {
					mail: 0,
					phone: 1,
					email: 2
				},
				genders: {
					male: 1,
					female: 2,
					other: 3
				},
				idTypes: {
					driverLicense: 1,
					passport: 2,
					ageCard: 3,
					healthCard: 4,
					medicare: 5,
					centrelink: 6
				},
				memberTypes: {
					owner: 1,
					privateTenant: 2,
					publicHousingTenant: 3
                },
                reportTypes: {
                    emergencyManagement: 0,
                    financeManagement: 1
                },
				reestablishSections: {
					debrisRemoval: 'DebrisRemoval',
					insuranceDetails: 'InsuranceDetails',
					weeklyIncome: 'WeeklyIncome',
					principalPlaceOfResidence: 'PrincipalPlaceOfResidence',
					altAccom: 'AltAccom',
					urgentNeeds: 'UrgentNeeds',
					essentialHousehold: 'EssentialHousehold',
					damageAssessment: 'DamageAssessment',
					essentialRepairs: 'EssentialRepairs',
					safetyReconnection: 'SafetyReconnection',
					demolitionRebuilding: 'DemolitionRebuilding',
					exceptionalCircumstances: 'ExceptionalCircumstances',
					paymentAdjustments: 'PaymentAdjustments'
				},
				cardShippingUrgency: {
					high: 1,
					medium: 2,
					low: 3
				},
				cardShippingUrgencyNames: [
					{ id: 1, name: 'High' },
					{ id: 2, name: 'Medium' },
					{ id: 3, name: 'Low' }
				],
				cardStatus: {
					loaded: 1,
					received: 2,
					notReceived: 3,
					stored: 4,
					allocated: 5,
					inTransit: 6,
					checkedOut: 7,
					issued: 8,
					closed: 9
				},
				cardShippingStatus: {
					new: 1,
					inProgress: 4,
					inTransit: 5,
					shipmentError: 6,
					withdrawn: 7,
					completed: 8
				},
				cardShippingTaskStatus: {
					open: 1,
					inProgress: 2,
					closed: 3
				},
				cardShippingTaskType: {
					fulfillCardShipmentTask: 1,
					receiveCardShipmentTask: 2
				},
				cardReceiveType: {
					batch: 'batch',
					order: 'order'
				},
				cardBatchStatus: {
					open: 'Open',
					loaded: 'Loaded',
					reconciled: 'Reconciled',
					fileError: 'File error',
					pending: 'Pending'
				},
				cardShippingStatusNames: [
					{ id: 1, name: 'New' },
					{ id: 4, name: 'In progress' },
					{ id: 5, name: 'In transit' },
					{ id: 6, name: 'Shipment error' },
					{ id: 7, name: 'Withdrawn' },
					{ id: 8, name: 'Completed' }
				],
				cardStatusNames: [
					{ id: 1, name: 'Loaded' },
					{ id: 2, name: 'Received' },
					{ id: 3, name: 'Not received' },
					{ id: 4, name: 'Stored' },
					{ id: 5, name: 'Allocated' },
					{ id: 6, name: 'In transit' },
					{ id: 7, name: 'Checked out' },
					{ id: 8, name: 'Issued' },
					{ id: 9, name: 'Closed' }
				],
				applicationType: {
					relief: 1,
					reestablish: 2
				},
				pageState: {
					reliefForm: { title: 'RELIEF APPLICATION', id: '#content-container' },
					reliefPayment: { title: 'RELIEF PAYMENT', id: '#paymentDetails' },
					reliefAdjustment: { title: 'RELIEF ADJUSTMENT', id: '#adjustmentDetails' },
					reestablishForm: { title: 'RE-ESTABLISHMENT APPLICATION', id: '#content-container' },
					reestablishPayment: { title: 'RE-ESTABLISHMENT PAYMENT', id: '#paymentDetails' },
					reestablishAdjustments: { title: 'RE-ESTABLISHMENT ADJUSTMENT', id: '#adjustmentDetails' },
					configurationList: { title: 'Configuration List', id: '#content-container' },
					userRoleManagement: { title: 'User and role management', id: '#content-container' }
				},
				reestablishPageState: {
					form: 1,
					payment: 2
				},
				paymentStatus: {
					active: { id: 1, description: 'Active' },
					pendingCancel: { id: 2, description: 'Pending Cancel' },
					pendingApproval: { id: 10, description: 'Pending Approval' },
					pendingReview: { id: 11, description: 'Pending Review' },
					approved: { id: 12, description: 'Approved' },
					paid: { id: 50, description: 'Paid' },
					deleted: { id: 51, description: 'Deleted' },
					cancelled: { id: 52, description: 'Cancelled' }
				},
				paymentMethod: {
					card: { id: 1, description: 'Card' },
					cheque: { id: 2, description: 'Cheque' },
					directDeposit: { id: 3, description: 'Eft' }
				},
				paymentType: {
					initial: { id: 1, description: 'Initial' },
					further: { id: 2, description: 'Further' },
					exceptional: { id: 3, description: 'Exceptional' }
				},
				errorMessage:
				{
					invalidStatusForCheckout: 'Invalid card status.',
					invalidStatusForCheckin: 'Invalid card status.',
					invalidStatusForStoreLocation:
					'The card status is invalid.Please ensure the status is Stored or Received.',
					invalidMultipleLocation: 'Cards cannot be selected from multiple locations.',
					concurrencyMessage:
					'Unable to save: The record to be saved has already been updated by another user.Please refresh current page to get the latest version.'
				},
				pageMode: {
					add: "Add",
					update: "Update",
					view: "View"
				},
				documentDefaultSection: '$DEFAULT$',
				vendorStatus: {
					PendingUpdate: 0,
					Active: 1,
					Inactive: 2,
					Exception: 3
				},
				configurationMenuIds: {
					incidentList: {
						incidentType: 'incidentType',
						emergencyType: 'emergencyType',
						incidentStatus: 'incidentStatus'
					},
					general: {
						gender: 'gender',
						idTypes: 'idTypes'
					},
					location: {
						lga: 'lga',
						rc: 'reliefCentre',
						region: 'region',
						division: 'division'
					},
					reliefApplication: {
						immediateReliefRequirements: 'immediateReliefRequirements',
						reliefAssistanceAmount: 'reliefAssistanceAmount'
					},
					reEstablishment: {
						principlePlaceOfResidence: 'principlePlaceOfResidence',
						maxEssentialHouseHold: 'maxEssentialHouseHold',
						reEstablishmentAmount: 'reEstablishmentAmount',
						uninhabitableDays: 'uninhabitableDays',
						alternateAccomodationWeeks: 'alternateAccomodationWeeks',
						noOfDays: 'noOfDays',
						reEstablishmentAssistanceAmount: 'reEstablishmentAssistanceAmount'
					},
					finance: {
						cardLimit: 'cardLimit',
						glString: 'glString'
					}
				},	
				cardActivityRequestTypeCodes: cardActivityRequestTypeCodes,

				cardActivityRequestType: [
					{ code: cardActivityRequestTypeCodes.preloadCards, id: 1, description: "Pre-load cards", amountRequired: true, multiCardApprovalRequired: true },
					{ code: cardActivityRequestTypeCodes.addFundsToPreloadCards, id: 2, description: "Add funds to pre-loaded cards", amountRequired: true, forPreloaded: true, multiCardApprovalRequired: true },
					{ code: cardActivityRequestTypeCodes.removeFundsFromPreloadedcards, id: 3, description: "Remove funds from pre-loaded cards", amountRequired: true, forPreloaded: true, forActive: true },
					{ code: cardActivityRequestTypeCodes.cancelPreloadedAndUnusedCards, id: 4, description: "Cancel pre-loaded and unused cards", forPreloaded: true, forActive: true },
					{ code: cardActivityRequestTypeCodes.cancelIssuedCards, id: 5, description: "Cancel issued cards", forActive: true },
					{ code: cardActivityRequestTypeCodes.cancelLostIssuedCards, id: 6, description: "Cancel lost issued cards", forActive: true },
					{ code: cardActivityRequestTypeCodes.cancelStolenIssuedCards, id: 7, description: "Cancel stolen issued cards", forActive: true },
					{ code: cardActivityRequestTypeCodes.cancelExpiringIssuedCards, id: 8, description: "Cancel expiring issued cards", forActive: true },
					{ code: cardActivityRequestTypeCodes.cancelExpiredCards, id: 10, description: "Cancel expired cards", forActive: true },
					{ code: cardActivityRequestTypeCodes.removeFundsFromIssuedcards, id: 9, description: "Remove funds from issued cards", forActive: true, amountRequired: true, }

				],
				cardActivityRequestStatusCodes: cardActivityRequestStatusCodes,

				cardActivityRequestOrderStatusCodes: cardActivityRequestOrderStatusCodes,
				cardActivityRequestOrderStatus: [
					{ code: cardActivityRequestOrderStatusCodes.draft.description, id: 1, label: cardActivityRequestOrderStatusCodes.draft.description, allowEdit: true },
					{ code: cardActivityRequestOrderStatusCodes.pendingApproval.description, id: 2, label: cardActivityRequestOrderStatusCodes.pendingApproval.description, allowApproval: true },
					{ code: cardActivityRequestOrderStatusCodes.pendingReview.description, id: 3, label: cardActivityRequestOrderStatusCodes.pendingReview.description, allowEdit: true },
					{ code: cardActivityRequestOrderStatusCodes.inProgress.description, id: 4, label: cardActivityRequestOrderStatusCodes.inProgress.description },
					{ code: cardActivityRequestOrderStatusCodes.closed.description, id: 5, label: cardActivityRequestOrderStatusCodes.closed.description },
					{ code: cardActivityRequestOrderStatusCodes.cancelled.description, id: 6, label: cardActivityRequestOrderStatusCodes.cancelled.description },
					{ code: cardActivityRequestOrderStatusCodes.error.description, id: 7, label: cardActivityRequestOrderStatusCodes.error.description, allowEdit: true },
					{ code: cardActivityRequestOrderStatusCodes.amendedAndClosed.description, id: 8, label: cardActivityRequestOrderStatusCodes.amendedAndClosed.description },
					{ code: cardActivityRequestOrderStatusCodes.deleted.description, id: 9, label: cardActivityRequestOrderStatusCodes.deleted.description }
				],

				userRoleMenuId: {
					role: 'roleManagement',
					user: 'userManagement'
				},

				activityResultStatus: {
					approval: 1,
					success: 2,
					error: 3
				},

				activityRequestOrderMode: {
					save: 1,
					submit: 2,
					delete: 3
				},
				cardStocktakeStatus: {
					PendingApproval: 1,
					Cancelled: 2,
					Complete: 3

				},
				cardStocktakeStatusDisplay: [
					{ id: 1, label: "PendingApproval" },
					{ id: 2, label: "Cancelled" },
					{ id: 3, label: "Complete" }
                ],
                TransactionStatus:
                {
                    Draft:0,
                    ReadyToGo:1,
                    Sent:2,
                    Completed:3,
                    Cancelled:4,
                    Void:5,
                    Error:6,
                    Replaced:7,
                    Processed:8,
                    InProgress:9
                },
                TransactionTypeGL:
                {
                    Unknown : 0,
                    LoadPrePaidCard : 9001,
                    AddValueToUnissuedPrepaidCard:9002,
                    UnloadUnissuedPrepaidCard:9003,
                    IssuePrepaidCard:9004,
                    AddValueToIssuedPrepaidCard:9005,
                    LoadAndIssueCard:9006,
                    AddValueToIssuedCard:9007,
                    UnloadValueFromIssuedCard:9008,
                    LoadCard : 9009,
                    IssueCard:9010,
                    ChequePayment:9011,
                    CancelChequePayment: 9012,
                    DebitCardAdjustment: 9017,
                    CreditCardAdjustment: 9018
                },
                TransactionTypeAP:
                {
                    Unknown: 0,
                    DirectCreditNonGst: 9013,
                    DirectCreditGst: 9014,
                    CancelDirectCreditNonGst: 9015,
                    CancelDirectCreditGst: 9016,
                },
                GridDateFormat: 'date:\'dd-MM-yyyy\'',
                DatePickerFormat: 'dd-MM-yyyy',
                GLDropDownDateFormat: 'dd/MM/yyyy',
                fileTimeStampFormat: 'YYYYMMDDhhmm',
                HistoryTimeStampDateFormat: 'dd-MM-yyyy hh:mm:ss t',
                printDateFormat: 'dd-MM-yyyy',
                MaximumUploadDocumentCount: 5, 
                FileMaxUploadSize: 10,
                MaximumSearchResultAllowed : 500
			}
		});
})();