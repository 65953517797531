(function () {
    angular
        .module('phapApp')
        .directive('dynamicTemplate', ['utils', 'security', 'Constants', '$compile',
            function (utils, security, Constants, $compile) {
                return {
                    transclude: true,
                    link: function (scope, element, attrs, ctrl) {
                        if (scope.actionTemplate) {
                            var compiled = $compile(scope.actionTemplate)(scope);
                            element.append(compiled);
                        }
                    },
                    controller: function () { }
                };
            }
        ]);
})();