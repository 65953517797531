(function () {

    var app = angular.module('phapApp');

    app.controller('emergencyReliefPaymentsmodifyPopupCtrl', ['$scope', 'Constants', 'utils', 'isAdd', 'data',
        function ($scope, constants, utils, isAdd, data) {
            var vm = this;
            var itemIndex = data.list.indexOf(data.item);
            vm.title = isAdd ? 'Add new relief assistance amount ' : 'Edit relief assistance amount';
            vm.data = data.item;
            vm.oldItemIndex = data.oldItemIndex;
            vm.originalItem = angular.copy(data.item);
            vm.currentItems = data.list;
            vm.errors = [];
            vm.displayData = {
                minAdults: constants.configuration.minAdults,
                maxAdults: constants.configuration.maxAdults,
                minChildren: constants.configuration.minChildren,
                maxChildren: constants.configuration.maxChildren
            }

            vm.isValidData = function () {
                vm.errors = [];
                var errorMessage = "Relief assistance amount - Adults children combination is not supported";
                if (utils.parseDollarToNumber(vm.data.data.amountToBePaid) == "") {
                    vm.errors.push({
                        message: "Amount to be paid not valid"
                    });
                    return false;
                }
                for (var i = 0; i < vm.currentItems.length; i++) {
                    if (isAdd || i !== vm.oldItemIndex) {
                        if (vm.currentItems[i].data.numberOfAdults === vm.data.data.numberOfAdults &&
                            vm.currentItems[i].data.numberOfChildren === vm.data.data.numberOfChildren) {
                            vm.errors.push({
                                message: errorMessage
                            });
                            return false;
                        }

                        if (vm.data.data.numberOfAdults === 0 && vm.data.data.numberOfChildren !== 1) {
                            vm.errors.push({
                                message: errorMessage
                            });
                            return false;
                        }
                    }
                }
                return true;
            };

            vm.applyChanges = function () {
                if (!$scope.reliefPayments.form.$invalid && vm.isValidData()) {

                    var insertIndex = - 1;
                    var groups = [];

                    angular.forEach(vm.currentItems, function (item) {
                        if (item.data.numberOfAdults === vm.data.data.numberOfAdults) {
                            if (item.data.numberOfChildren === (vm.data.data.numberOfChildren - 1) && (vm.data.data.id!==0) && item.data.id === vm.data.data.id) {
                                insertIndex = vm.currentItems.indexOf(item);
                            }
                            else if (item.data.numberOfChildren === (vm.data.data.numberOfChildren - 1)) {
                                insertIndex = vm.currentItems.indexOf(item) + 1;
                            } else {
                                //Check is same item value updated
                                if (item.data.numberOfChildren === vm.data.data.numberOfChildren) {
                                    insertIndex = vm.oldItemIndex;
                                } else {
                                    groups.push(item);
                                }

                            }
                        }
                    });

                    //if the items not in sequence find the correct index
                    if (insertIndex === -1 && groups.length > 0) {
                        angular.forEach(groups, function (group) {
                            if (vm.data.data.numberOfChildren < group.data.numberOfChildren) {
                                if (insertIndex === -1) {
                                    insertIndex = vm.currentItems.indexOf(group);
                                }

                            }
                        });
                        if (insertIndex === -1) {
                            if (isAdd)
                                insertIndex = vm.currentItems.indexOf(groups[groups.length - 1]) + 1;
                            else {
                                var maxChild = 0;
                                angular.forEach(groups, function (group) {
                                    if (maxChild < group.data.numberOfChildren) {
                                        maxChild = group.data.numberOfChildren
                                    }
                                });
                                angular.forEach(groups, function (group) {
                                    if (maxChild == group.data.numberOfChildren) {
                                        insertIndex = vm.currentItems.indexOf(group)
                                    }
                                });
                            }
                        }
                    }

                    //Remove the currency formats and take the number
                    vm.data.data.amountToBePaid = utils.parseDollarToNumber(vm.data.data.amountToBePaid);
                    vm.data.isAdd = isAdd;
                    vm.data.isUpdated = !isAdd;

                    var data = {
                        isAdd: isAdd,
                        isUpdated: !isAdd,
                        item: vm.data,
                        insertIndex: insertIndex,
                        oldItemIndex: vm.oldItemIndex
                    }
                    $scope.$close(data);
                }
            }
            vm.close = function () {
                $scope.$dismiss({ $value: 'cancel' });
            }

        }
    ]);

})();
