(function () {
	angular.module('phapApp')
		.directive('numericOnly', function () {

			// NOTE: 
			//      Do not use type= "number" on the control as that appears to block input from arriving
			//      at this directive, this directive will exclude HEX characters in a numeric input!

			return {
				require: ['ngModel', 'numericOnly'],
				bindToController: {
					default: '=?',
					min: '=?',
					max: '=?',
                    maxExpr: '&?',
                    disableDecimal: '=?'
				},
				controller: function () {
				},
				link: function (scope, element, attrs, ctrls) {
					var modelCtrl = ctrls[0];
					var ctrl = ctrls[1];

					modelCtrl.$formatters.push(function (value) {
						if (modelCtrl.$isEmpty(value)) {
							if (ctrl.default !== undefined) {
								return ctrl.default;
							}
						}
						return value;
					});

					modelCtrl.$parsers.push(function (inputValue) {

						var transformedInput = inputValue;

						if (transformedInput && transformedInput.length) {

							// remove non-numeric letters
							transformedInput = transformedInput.replace(/[^\d\.\-\+]/g, '');

							// remove decimal
							if (ctrl.disableDecimal) {
								transformedInput = transformedInput.replace(/\./g, '');
							}

							// parse
							var parsedValue, dotIncluded;
							if (transformedInput.length > 0) {
								dotIncluded = transformedInput.indexOf('.') >= 0;
								parsedValue = ctrl.disableDecimal ? parseInt(transformedInput) : parseFloat(transformedInput);

								if (ctrl.min !== undefined && parsedValue < ctrl.min) {
									parsedValue = ctrl.min;
								}

                                if (ctrl.max !== undefined && parsedValue > ctrl.max) {
                                    parsedValue = ctrl.max;
                                }

                                if (ctrl.maxExpr !== undefined && parsedValue > ctrl.maxExpr()) {
                                    parsedValue = ctrl.maxExpr();
                                }

								transformedInput = parsedValue.toString();
								if (dotIncluded && transformedInput.indexOf('.') < 0) {
									transformedInput += '.';
								}
							}
						}

						if (transformedInput == null || transformedInput == "" || transformedInput.indexOf('NaN') >= 0) {
							transformedInput = ctrl.default === undefined ? "" : ctrl.default.toString();
						}

						if (transformedInput !== inputValue) {
							modelCtrl.$setViewValue(transformedInput);
							modelCtrl.$render();
						}

						return transformedInput;
					});
				}
			};
		});

	angular.module('phapApp')
		.directive('customValidator', [function () {
			return {
				restrict: 'A',
				require: 'ngModel',
				scope: { validateFunction: '&' },
				link: function (scope, elm, attr, ngModelCtrl) {
					ngModelCtrl.$parsers.push(function (value) {
						var result = scope.validateFunction({ 'value': value });
						if (result || result === false) {
							if (result.then) {
								result.then(function (data) {           //For promise type result object
									ngModelCtrl.$setValidity(attr.customValidator, data);
								}, function (error) {
									ngModelCtrl.$setValidity(attr.customValidator, false);
								});
							}
							else {
								ngModelCtrl.$setValidity(attr.customValidator, result);
								return result ? value : undefined;      //For boolean result return based on boolean value
							}
						}
						return value;
					});
				}
			};
		}]);

	angular.module('phapApp')
		.directive('noSpecialChars', function () {
			return {
				require: 'ngModel',
				restrict: 'A',
				link: function (scope, element, attrs, modelCtrl) {
					modelCtrl.$parsers.push(function (inputValue) {
						if (inputValue == null)
							return ''
						var cleanInputValue = inputValue.replace(/[^a-zA-Z0-9-]*$/gi, '');
						if (cleanInputValue !== inputValue) {
							modelCtrl.$setViewValue(cleanInputValue);
							modelCtrl.$render();
						}
						return cleanInputValue;
					});
				}
			}
        });

    angular.module('phapApp')
    .directive('numberText', function () {
        return {
            require: 'ngModel',
            restrict: 'A',
            link: function (scope, element, attr, ngModelCtrl) {
                function fromUser(text) {
                    if (text) {
                        var transformedInput = text.replace(/[^0-9-]/g, '');
                        if (transformedInput !== text) {
                            ngModelCtrl.$setViewValue(transformedInput);
                            ngModelCtrl.$render();
                        }
                        return transformedInput;
                    }
                    return '';
                }
                ngModelCtrl.$parsers.push(fromUser);
            }
        };
    });



	angular.module('phapApp')
		.directive('phone', function () {
			return {
				restrice: 'A',
				require: 'ngModel',
				link: function (scope, element, attrs, ctrl) {
					ctrl.$error = false;
					var PHONE_REGEXP = /^[(]{0,1}[0-9]{3}[)\.\- ]{0,1}[0-9]{3}[\.\- ]{0,1}[0-9]{4}$/;
					angular.element(element).bind('blur', function () {
						var value = this.value;
						if (PHONE_REGEXP.test(value)) {
							// Valid input	
							ctrl.$error = false;
							ctrl.$invalid = false;
							ctrl.$setViewValue(value);
						}
						else {
							// Invalid input 				
							this.value = ''
							ctrl.$setViewValue('');							
							ctrl.$error = true;
							ctrl.$invalid = true;
							return undefined;
						}
					});
				}
			}
		});
})();
