(function () {
	var app = angular.module('phapApp');
	app.component('cardBulkUpload', {
		templateUrl: 'app/financeManagement/cards/cardBulkUpload.html',
		bindings: {
			mode: '@'
		},
        controller: ['$scope', 'cardBulkUploadService', '$routeParams', '$location', 'Constants', 'utils', 'FileUploader', 'security', 'uiGridConstants', 'navigationService', 'notificationService', '$interval','documentService',
            function ($scope, cardBulkUploadService, $routeParams, $location, constants, utils, FileUploader, security, uiGridConstants, navigationService, notificationService, $interval, documentService) {
				var vm = this;
				var isRunningInExtraSmallScreen = $(document).width() < 768;

				$scope.fileUploader = createFileUploaderForAttachment();

				vm.$onInit = function () {
					navigationService.setCurrentMenuStatus(
						navigationService.topMenuCodes.financeManagement,
						navigationService.pageCodes.cardSearch);

					vm.loaded = false;
					vm.errorMessage = null;
					vm.successMessage = null;
					vm.selectedBatch = null;
					vm.constants = constants;
					vm.currentUserSecurityInfo = null;

					vm.receiveCards = function () {
						var selectedBatch = vm.gridApi.selection.getSelectedRows();
						if (selectedBatch && selectedBatch.length > 0) {
							vm.form.$setPristine();
							$location.path("/financeManagement/cards/receiveBatch/" + selectedBatch[0].id);
						}
					}

					vm.assignLocation = function () {
						var selectedBatch = vm.gridApi.selection.getSelectedRows();
						if (selectedBatch && selectedBatch.length > 0) {
							vm.form.$setPristine();

							$location.path('/financeManagement/cards/search/' + selectedBatch[0].batchNumber + '/' + constants.cardStatus.received);
						}
					}

					vm.getCardImportReport = function (batchId) {
						cardBulkUploadService.getCardImportReport(batchId)
							.then(function (data) {
								var file = new Blob([data], { type: 'text/csv' });
								var timeStamp = moment().format(constants.fileTimeStampFormat);
								saveAs(file, 'CardImport_' + timeStamp + '.csv');
							});
					}
					vm.downloadPurchaseOrder = function (row) {
						cardBulkUploadService.getPurchaseOrder(row.entity.id)
							.then(function (data) {
								try {
									var file = new Blob([data]);
									saveAs(file, row.entity.attachmentFileName);
								}
								catch (e) {
									$scope.utility.openPopup('errorDownloadDoc');
								}
							});
					}

					vm.getCardReconciliationReport = function (batchId) {
						cardBulkUploadService.getCardReconciliationReport(batchId)
							.then(function (data) {
								var file = new Blob([data], { type: 'text/csv' });
								var timeStamp = moment().format(constants.fileTimeStampFormat);
								saveAs(file, 'CardReconciliation_' + timeStamp + '.csv');
							});
					}
					vm.deleteAttachment = function (batchId) {
						vm.selectedBatch = null;
						cardBulkUploadService.deleteAttachment(batchId)
							.then(function (response) {
								if (response.data.length > 0) {
									$scope.noResultsFound = false;
									vm.gridOptions.data = response.data;
								}
								if (response.data === "ConcurrencyException") {
									vm.successMessage = null;
									vm.errorMessage = constants.errorMessage.concurrencyMessage;
								}
							},
							function (response) {
								vm.loaded = true;
								vm.successMessage = null;
								vm.errorMessage = 'Unable to delete the attachment. Please try again.';
							});
					};



					security.getCurrentUserSecurityInfo()
						.then(function (result) {
							vm.currentUserSecurityInfo = result;
							vm.gridOptions = getGridOptions();
							loadGridData();
						},
						function () {
							vm.errorMessage = 'Could not retrieve user security information.';
						});
				};

				var loadGridData = function () {
					cardBulkUploadService.getBulkUploadDetails().then(
						function (response) {
							if (response.data.length > 0) {
								$scope.noResultsFound = false;
								vm.gridOptions.data = response.data;
							}
							vm.loaded = true;
						},
						function (response) {
							vm.loaded = true;
							vm.errorMessage = 'Could not retrieve batch information.'
						}
					);
				}

				var getBatchColumnDefs = function () {
					return [
						{
							field: 'id',
							visible: false
						},
						{
							field: 'attachmentFileName',
							visible: false
						},
						{
							field: 'batchNumber',
							displayName: 'Batch number',
							enableHiding: false,
							width: isRunningInExtraSmallScreen ? '11%' : '11%',
							enableColumnMenu: false
						},
						{
							field: 'cardBatchStatus',
							displayName: 'Batch status',
							enableHiding: false,
							width: isRunningInExtraSmallScreen ? '10%' : '10%',
							enableColumnMenu: false
						},
						{
							field: 'dateCreatedDisplay',
							type: 'date',
							displayName: 'Card import date',
							enableHiding: false,
							width: isRunningInExtraSmallScreen ? '12%' : '12%',
							enableColumnMenu: false
						},
						{
							field: 'attachmentFileName',
							displayName: 'Purchase order',
							enableHiding: false,
							enableSorting: false,
							width: isRunningInExtraSmallScreen ? '11%' : '11%',
							enableColumnMenu: false,
							cellTemplate:
							'<a id="{{row.entity.id}}-import-purchase-order" href="" data-select-row="false" ng-click="grid.appScope.downloadPurchaseOrder(row)" >{{row.entity.attachmentDisplay}}</a> ' +
							'<a id="{{row.entity.id}}-purchase-order" ng-if="row.entity.isPurchaseOrderAttached == true" href="" data-select-row="false" title="Remove attachment" ng-click="$event.stopPropagation();grid.appScope.deleteAttachment(row)" >' +
							'<span class="glyphicon glyphicon-remove"></span>' +
							'</a>' +
							'<a ng-if="row.entity.isPurchaseOrderAttached == false" href="" data-select-row="false" title="Add attachment" ng-click="$event.stopPropagation();grid.appScope.chooseFile(row)">' +
							'<span class="glyphicon glyphicon-plus" ></span>' +
							'</a>'
						},
						{
							field: 'cardImportReport',
							displayName: 'Card import report',
							enableHiding: false,
							enableSorting: false,
							width: isRunningInExtraSmallScreen ? '13%' : '13%',
							enableColumnMenu: false,
							cellTemplate: '<a id="{{row.entity.id}}-import-report" href="" data-select-row="false" ' +
							'ng-if="' + vm.currentUserSecurityInfo.privileges.BatchCardImport + ' && row.entity.cardBatchStatus!=\'' + constants.cardBatchStatus.open + '\' && row.entity.cardBatchStatus!=\'' + constants.cardBatchStatus.pending + '\'" ' +
							'ng-click="grid.appScope.runCardImportReport(row)" >Download</a>'
						},
						{
							field: 'reconciliationReport',
							displayName: 'Reconciliation report',
							enableHiding: false,
							enableSorting: false,
							width: isRunningInExtraSmallScreen ? '14%' : '14%',
							enableColumnMenu: false,
							cellTemplate: '<a id="{{row.entity.id}}-rec-report" href="" data-select-row="false" ' +
							'ng-if="' + vm.currentUserSecurityInfo.privileges.ReceiveCardsForBatch + ' && row.entity.cardBatchStatus==\'' + constants.cardBatchStatus.reconciled + '\'" ' +
							'ng-click="grid.appScope.runCardReconciliationReport(row)" >Download</a>'
						},
						{
							field: 'fileName',
							displayName: 'Filename',
							enableHiding: false,
							width: isRunningInExtraSmallScreen ? '29%' : '29%',
							enableColumnMenu: false

						}
					]
				};

				var getGridOptions = function () {
					return {
						enableSorting: true,
						enableFiltering: false,
						enableHorizontalScrollbar: uiGridConstants.scrollbars.ALWAYS,
						enableVerticalScrollbar: uiGridConstants.scrollbars.ALWAYS,
						enableRowSelection: true,
						enableFullRowSelection: true,
						enableRowHeaderSelection: true,
						enableSelectAll: false,
						multiSelect: false,
						columnDefs: getBatchColumnDefs(),
						appScopeProvider: {
							selectRow: function (row, event) {
								selectRow(row, event);
							},
							runCardImportReport: function (row) {
								runCardImportReport(row);
							},
							downloadPurchaseOrder: function (row) {
								downloadPurchaseOrder(row);
							},
							deleteAttachment: function (row) {
								vm.selectedBatch = null;
								deleteAttachment(row);
							},
							chooseFile: function (row) {
								vm.selectedBatch = null;
								chooseFile(row);
							},
							runCardReconciliationReport: function (row) {
								runCardReconciliationReport(row);
							}
						},
						rowTemplate:
						'<div ng-click="grid.appScope.selectRow(row, $event)"> ' +
						'<div ' +
						'class="ui-grid-cell" ' +
						'ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" ' +
						'ui-grid-cell ' +
						'ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader }" ' +
						'style="cursor:pointer"> ' +
						'</div>' +
						'</div>',

						onRegisterApi: function (gridApi) {
                            vm.gridApi = gridApi;
                            // call resize every 500 ms for 5 s after modal finishes opening - usually only necessary on a bootstrap modal
                            $interval(function () {
                                vm.gridApi.core.handleWindowResize();
                            }, 500, 10);
						}
					}
				};

				var selectRow = function (row, event) {
					var shouldSelect = event.target.attributes['data-select-row'];
					if (!shouldSelect || shouldSelect.value === 'true') {
						vm.selectedBatch = row.isSelected ? row.entity : null;
					}
					else {
						row.isSelected = !row.isSelected;
						vm.selectedBatch = row.isSelected ? row.entity : null;
					}
				}

				var runCardImportReport = function (row) {
					vm.getCardImportReport(row.entity.id);
				}
				var downloadPurchaseOrder = function (row) {
					vm.downloadPurchaseOrder(row);
				}

				var runCardReconciliationReport = function (row) {
					vm.getCardReconciliationReport(row.entity.id);
				}
				var deleteAttachment = function (row) {

					vm.deleteAttachment(row.entity.id);
				}
				var chooseFile = function (row) {

					vm.chooseFile(row.entity.id);
				}

				var chooseFile = function (row) {
					vm.batchId = row.entity.id;
					$("#file-input").click();
                }

                var discardFile = function (bytes) {
                    var fileSample = documentService.calculateFileSize(bytes);
                    if (fileSample.number > 2) //everthing above MB
                        return true;
                    if (fileSample.number == 2 && fileSample.size > constants.FileMaxUploadSize) // MB and greater than 2
                        return true;
                    return false;
                }

				function createFileUploaderForAttachment() {
					var fileUploader = $scope.uploader = new FileUploader({});

					//method executes after the file is chosen in file uploader
					fileUploader.onAfterAddingFile = function (fileItem) {
						var userId;
						vm.successMessage = null;
						security.getActiveUser(false).then(
							function (data) {
								userId = data.id;
								var bearerToken = sessionStorage.getItem('adal.idtoken');
								if (!bearerToken) {
									vm.errorMessage = "Current user is unauthorized";
								}
								else {
									fileItem.headers = {
										Authorization: 'Bearer ' + bearerToken,
										userId: userId
									};
								}
                                
                                if (fileItem && fileItem.file.size > 0) {
                                    //check size
                                    if (discardFile(fileItem.file.size))
                                    {
                                        document.getElementById('file-input').value = "";
                                        vm.errorMessage = "The file cannot be more than " + constants.FileMaxUploadSize+ " MB.";
                                    }
									//donot check if file can be attached when adding attachment from the row itself
									else if (vm.batchId || checkIfFileCanBeAttached(fileItem)) {
										if (checkForValidFileType(fileItem)) {
											cardBulkUploadService.saveAttachment(fileItem, vm.batchId);
											vm.batchId = null;
										}
									}
								}
								else {
									document.getElementById('file-input').value = "";
									vm.errorMessage = "The file cannot be processed: an empty file has been selected.";
								}
							}
						);
					}
						fileUploader.onErrorItem = function (fileItem, response, status, headers) {
							vm.successMessage = null;
							document.getElementById('file-input').value = "";
							if (status === 409) {
								vm.errorMessage = constants.errorMessage.concurrencyMessage;
							}
							else {
								vm.errorMessage = "The file could not be uploaded, please try again.";
							}
						};
						fileUploader.onSuccessItem = function (fileItem, response, status, headers) {
							vm.errorMessage = null;
							vm.successMessage = "File upload successful";
							document.getElementById('file-input').value = "";
							vm.gridOptions.data = response;
						};
						return fileUploader;
					}

					var rowsRendered = function () {
						$scope.filteredRecords = $scope.gridApi.core.getVisibleRows($scope.gridApi.grid).length;
					};
					var checkIfFileCanBeAttached = function (fileItem) {

						if (checkIfPendingFileExists() == false) {
							document.getElementById('file-input').value = "";
							vm.errorMessage = "A pending batch record with an associated attachment already exists. Attach a new file by clicking the 'X' icon to remove the existing attachment.";
							return false;
						}
						return true;
					}
					var checkForValidFileType = function (fileItem) {
						if (isValidExtensions(fileItem.file.type, fileItem.file.name) == false) {
							document.getElementById('file-input').value = "";
							vm.errorMessage = "The file cannot be processed: the file format is incorrect";
							return false;
						}
						return true;
					}

					var isValidExtensions = function (fileType, fileName) {
						switch (fileType) {
							case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
							case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
							case 'application/pdf':							
							case 'text/plain':
							case 'application/msword':
							case 'application/vnd.ms-excel':
								return true;
								break;
							default:
								return false;
						}
						return false;
					}
					var checkIfPendingFileExists = function () {
						var res = vm.gridOptions.data
						if (_.find(vm.gridOptions.data, function (item) {
							return item.cardBatchStatus.toLowerCase() === "pending" && item.isPurchaseOrderAttached;
						})) {
							return false;
						}
						return true;
					}
				}],
		controllerAs: 'cbu'
	});

})();