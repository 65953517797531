//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////// Ineligible warning popup ///////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Usage                                                                                                            //
//<div ineligible-warning-popup                                                                                     //
//    condition="popupCondition"                                                                                    //
//    title="Ineligible application!"                                                                               //
//    content="Re-establishment assistance is not available to owners with both Structural and Contents insurance." //
//    on-cancel="cancelMethod()"                                                                                    //
//    on-ineligible="ineligibleMethod()" />                                                                         //
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

(function () {
    angular.module('phapApp')
        .directive('ineligibleWarningPopup', ['$ngConfirm', function ($ngConfirm) {
            return {
                replace: true,
                scope: {
                    condition: '=',
                    title: '@',
                    content: '@',
                    cancelButtonText: '@',
                    onCancel: '&onCancel',
                    onIneligible: '&onIneligible'                    
                },
                controller: ['$scope', '$attrs',
                    function ($scope, $attrs) {                        

                        $scope.$watch('condition', function (nv, ov) {
                            if (nv) {
                                $scope.condition = false;
                                showWarning();
                            }
                        });

                        var showWarning = function () {
                            $ngConfirm({
                                title: $attrs.title,
                                content: $attrs.content,
                                columnClass: 'large',
                                scope: $scope,
                                buttons: {
                                    somethingElse: {
                                        text: 'Ineligible',
                                        btnClass: 'btn btn-default btn-sm',
                                        action: function (scope, button) {
                                            $scope.onIneligible();                                            
                                        }
                                    },
                                    cancel: {
                                        text: ($attrs.cancelButtonText == null || $attrs.cancelButtonText === undefined || $attrs.cancelButtonText.length <= 0) ? 'Proceed' : $attrs.cancelButtonName,
                                        btnClass: 'btn btn-primary btn-sm',
                                        action: function () {
                                            $scope.onCancel();
                                        }
                                    }
                                }
                            });
                        };                        
                    }],
                link: function (scope, element, attrs, model) {                                        
                }                
            };
        }
        ])
})();