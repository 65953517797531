(function () {
    var app = angular.module('phapApp');
    app.service('callbackRequestService', ['$http', '$q', 'config', 'security', 'Constants', 'notificationService', '$rootScope', 'utils',
        function ($http, $q, config) {
            var getUsersForCallbackRequestAssignment = function () {
                var url = config.apiBaseUrl + "security/all-users-that-have-privilege/ViewMyCallbackRequestTasks";

                return $http.get(url).then(
                    function (result) {
                        return result.data.users;
                    });
            };

            var getUnassignedCallbackTasks = function (pageNumber, pageSize) {
                var deferred = $q.defer();

                $http.get(config.apiBaseUrl + 'callback-requests/unassigned?pageNumber=' + pageNumber + '&pageSize=' + pageSize)
                    .then(
                        function (response) {
                            deferred.resolve(response.data);
                        },
                        function (data) {
                            deferred.reject(data);
                        }
                    );

                return deferred.promise;
            };

            var getMyCallbackTasks = function (pageNumber, pageSize) {
                var deferred = $q.defer();

                $http.get(config.apiBaseUrl + 'callback-requests/mine?pageNumber=' + pageNumber + '&pageSize=' + pageSize)
                    .then(
                        function (response) {
                            deferred.resolve(response.data);
                        },
                        function (data) {
                            deferred.reject(data);
                        }
                    );

                return deferred.promise;
            };

            var reassign = function (callbackRequestId, newAssigneeId) {
                var deferred = $q.defer();

                $http.post(config.apiBaseUrl + 'callback-requests/' + callbackRequestId + '/reassign', { targetUserId: newAssigneeId })
                    .then(function (data) {
                        deferred.resolve(data);
                    }, function (data) {
                        deferred.reject(data);
                    });

                return deferred.promise;
            }

            var save = function (callbackRequestId, notes, assigneeUserId, status) {
                var deferred = $q.defer();

                $http.post(config.apiBaseUrl + 'callback-requests/' + callbackRequestId + '/update', { notes: notes, assigneeUserId: assigneeUserId, status: status })
                    .then(function (data) {
                        deferred.resolve(data);
                    }, function (data) {
                        deferred.reject(data);
                    });

                return deferred.promise;
            };

            return {
                getMyCallbackTasks: getMyCallbackTasks,
                getUnassignedCallbackTasks: getUnassignedCallbackTasks,
                getUsersForCallbackRequestAssignment: getUsersForCallbackRequestAssignment,
                reassign: reassign,
                save: save
            }
        }]);
})();