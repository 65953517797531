(function () {
	angular.module('phapApp')
		.service('locationService', ['$http', '$q', 'config', function ($http, $q, config) {

			
			var uploadLocationUrl = config.apiBaseUrl + "location/upload";
			var getLocationDetailsWithRegionUrl = config.apiBaseUrl + "location/region-lga";
            var getLocationFileUrl = config.apiBaseUrl + "location/download";
            var getAllDistributionDetailsUrl = config.apiBaseUrl + "location";
			var matchLga = function (lga) {
				var url = matchLgaUrl.replace("{lga}", lga);
				return $http.get(url);
			}
			var searchLga = function (lga) {
				var url = searchLgaUrl.replace("{lga}", lga);
				return $http.get(url);
			}
            var getlocationDetailsWithRegionAndLga = function () {
                var deferred = $q.defer();
                var res = $http.get(getLocationDetailsWithRegionUrl)
                    .then(function (response, status, headers, config) {
                        deferred.resolve(response.data);
                    },
                    function (error, status, headers, config) {
                        deferred.reject(data);
                    });
                return deferred.promise;
            };

			var getLocationFile = function () {
				var url = getLocationFileUrl;
				var deferred = $q.defer();
				var res = $http({
					method: 'POST',
					url: url,
					responseType: 'arraybuffer'
				}).then(function (response, status, headers, config) {
					deferred.resolve(response.data);
				}, function (data, status, headers, config) {
					deferred.reject(data);
				});

				return deferred.promise;
			}

			var uploadLocationFile = function (fileItem) {
				var url = uploadLocationUrl;
				var formdata = {};
				fileItem.formData.push(formdata);
				fileItem.url = url;
				fileItem.upload();
            }	

            var getAllDistributionDetails = function () {
                var deferred = $q.defer();
                var res = $http.get(getAllDistributionDetailsUrl)
                    .then(function (response, status, headers, config) {
                        deferred.resolve(response.data);
                    },
                    function (error, status, headers, config) {
                        deferred.reject(data);
                    });
                return deferred.promise;
            };

			return {
				matchLga: matchLga,
				searchLga: searchLga,
				uploadLocationFile: uploadLocationFile,
				getLocationFile: getLocationFile,
                getlocationDetailsWithRegionAndLga: getlocationDetailsWithRegionAndLga,
                getAllDistributionDetails: getAllDistributionDetails
			};

		}]);
})();