(function () {

    var app = angular.module('phapApp');
    app.component('unassignedCallbackTasks', {
        templateUrl: 'app/home/dashboard/unassignedCallbackTasks.html',
        bindings: {
        },
        controller: ['callbackRequestService', function (callbackRequestService) {
            var vm = this;

            vm.pageSize = 5;
            vm.pageNumber = 1;

            vm.assignCallbackToUser =
                function (taskId, targetUserId) {
                    // Don't proceed if the user has not chosen a reassignment target
                    if (typeof targetUserId === 'undefined') return;

                    var taskBeingAssigned =
                        _.find(
                            vm.tasks.pageRecords,
                            function (item) {
                                return item.id == taskId;
                            }
                        );

                    taskBeingAssigned.assignmentInProgress = true;

                    callbackRequestService.reassign(taskId, targetUserId)
                        .then(
                            function () {
                                // The server returned success - the reassignment was successful. Refresh the current page
                                vm.loadPage(vm.pageNumber);
                            },
                            function () {
                                // The server returned failure - the reassignment was unsuccessful. Hide the 
                                // indication that assignment is in progress.
                                var indexOfEntryToRemove =
                                    vm.tasks.pageRecords.map(function (item) { return item.id; }).indexOf(taskId);

                                vm.tasks.pageRecords[indexOfEntryToRemove].assignmentInProgress = false;
                            }
                        );
                };

            vm.users = null;

            vm.loadPage = function (pageNumber) {
                vm.loading = true;
                vm.tasks = null;

                callbackRequestService.getUnassignedCallbackTasks(pageNumber, vm.pageSize).then(
                    function (tasks) {
                        vm.loading = false;

                        vm.tasks = tasks;

                        vm.pageNumber = pageNumber;
                        vm.firstRecord = (vm.pageNumber - 1) * vm.pageSize + 1;
                        vm.lastRecord = vm.firstRecord + vm.tasks.pageRecords.length - 1;

                        vm.maximumPermittedPage = Math.ceil(vm.tasks.totalCount / vm.pageSize);
                    }
                )
            }

            vm.loadPage(1);

            callbackRequestService.getUsersForCallbackRequestAssignment().then(
                function (users) {
                    vm.users =
                        _.sortBy(
                            users,
                            function (x) { return (x.givenName + ' ' + x.surname).toLowerCase(); }
                        );
                });
        }],
        controllerAs: 'vm'
    });

})();