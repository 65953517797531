//this custom scroll directive is been written for reestablish form
//as there was a requirement to
//anchor the scroll bar at certain positions based on unlocked sections 
//of the form, so that user is automatically 
//anchored to the first question of the unlcked form section
phapApp
	.directive('autoScrollTo', ['$location', '$timeout', '$routeParams', 'utils',
		function ($location, $timeout, $routeParams, utils) {
			return {
				restrict: "A",

				link: function (scope, elem, attr) {
					elem.click(function () {
						$timeout(customScrollFunction, 1);

					});
					var customScrollFunction = function () {
						if (attr && $routeParams.fromCreate == "true") {
							var item = JSON.parse(attr.autoScrollTo);
							if (item && item.applicant && item.applicant.memberType) {
								if ($('#insuranceSummary').is(':visible')) {
									var anchor = '';
									if ($('#altAccom').is(':visible')) {
										anchor = $("a:contains('Alternate accommodation')");
										if (anchor.length > 0) {
											anchor[0].click();
										}
									}
									else {

										var showCategoryAssesment = ((item.applicant.memberType.id === 1 || item.applicant.memberType.id === 2) &&
											(item.insuranceHasStructure == true ||
												item.insuranceHasStructure == false ||
												item.insuranceHasContent == true ||
												item.insuranceHasContent == false
											))
										if (showCategoryAssesment == true) {
											anchor = $("a:contains('Insurance details')");
										}
										else {
											anchor = $("a:contains('Insurance summary')");
										}
										if (anchor.length > 0) {
											anchor[0].click();
										}
									}
								}

								else {
									var anchor = $("a:contains('Insurance summary')");
									if (anchor.length > 0) {
										anchor[0].click();
									}

								}

							}
							else {
								var height = 500;
								$('html, body').animate({
									scrollTop: height
								}, 10);
							}
						}
					}
					$timeout(customScrollFunction, 1);
				}
			}

		}]);
