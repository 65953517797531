(function () {

    var app = angular.module('phapApp');

    app.component('division', {
        templateUrl: 'app/administration/configuration/division.html',
        bindings: {
            mode: '@'
        },
        controller: ['$scope', '$filter', 'utils', 'referenceService', 'security', function ($scope, $filter, utils, referenceService, security) {
            var vm = this;
            vm.originalDataSet = [];
            vm.gridApi = null;
            vm.historyUrl = "division/0";
            var getDivisionList = function () {
                referenceService.getDivisionList().then(
                    function (divisionList) {
                        var list = [];
                        angular.forEach(divisionList.data, function (value, key) {
                            this.push({ data: value, isUpdated: false, isAdded: false });
                        }, list);
                        vm.gridOptions.data = list;
                        vm.originalDataSet = angular.copy(vm.gridOptions.data);
                        $scope.$broadcast('displayLoading', false);
                    });
            }

            var init = function () {
                getSecurity();
            };
            var getSecurity = function () {
                $scope.$broadcast('displayLoading', true);
                //getting logged in user security
                security.getCurrentUserSecurityInfo()
                    .then(function (result) {
                        vm.gridOptions.privileges = result.privileges.UpdateGeneralAdmin;
                        vm.gridOptions.showPrivilegePanel = true;
                        if (vm.gridOptions.privileges)
                            getDivisionList();
                    },
                    function () {
                        vm.gridOptions.privileges = false;
                    });
            };

            vm.gridOptions = {
                showPrivilegePanel: false,
                enableCellEdit: false,
                enableSorting: true,
                enableCellEditOnFocus: true,
                newObject: { data: {id:0, name: null, isDeleted: false, isReadOnly: false }, isUpdated: false, isAdded: true }
            };

            vm.gridOptions.columnDefs = [
                { name: 'data.name', displayName: 'Division', enableCellEdit: true, enableColumnMenu: false, enableSorting: true },
                {
                    name: 'data.isDeleted', displayName: '', enableCellEdit: false, enableColumnMenu: false, width: 100, enableSorting: false,
                    cellClass: function (grid, row) {

                        if (row.entity.data.isReadOnly) {
                            return "grid-readonly-button";
                        }
                        return "grid-Delete-button";
                    },
                    cellTemplate: '<a ng-if="!row.entity.data.isReadOnly && !row.entity.data.isDeleted" title="Delete" ng-click="grid.appScope.deleteRow(row.entity)"><span class="glyphicon glyphicon-trash grid-Delete-button" ></span></a>' +
                    '<a ng-if="!row.entity.data.isReadOnly && row.entity.data.isDeleted" title="ReActivate" ng-click="grid.appScope.reActivate(row.entity)"><span class="glyphicon glyphicon-plus grid-Add-button" ></span></a>' +
                    '<span ng-if="row.entity.data.isReadOnly" title="This division cannot be deleted as there are active region(s) associated. In order to delete all regions, need to be dissociated" class="glyphicon glyphicon-trash grid-Delete-disabled-button" ></span>'

                }
            ];

            vm.validation = function (row, colDef, currentData) {
                if (colDef.name === 'data.name') {
                    if (utils.isNullOrEmpty(row.name)) {
                        return { isValid: false, errors: ['Division name required'] };
                    }
                   
                    var duplicates = [];
                    var newItemsCount = 0;
                    angular.forEach(vm.gridOptions.data, function (value, key) {
                        if (value.data.id!=0 && value.data.name.toLowerCase() == row.name.toLowerCase()) {
                            this.push(row);
                        }
                        if (value.data.id == 0) {
                            newItemsCount++;
                        }
                        
                    }, duplicates);
                    if (duplicates.length > 0) {
                        return { isValid: false, errors: ['Duplicate division names not allowed'] };
                    }

                    if (newItemsCount > 1) {
                        var sameNames=[]
                        angular.forEach(vm.gridOptions.data, function (value, key) {
                            if (value.data.id == 0 && value.data.name.toLowerCase() == row.name.toLowerCase()) {
                                this.push(row);
                            }

                        }, sameNames);

                        if (sameNames.length > 1) {
                            return { isValid: false, errors: ['Duplicate division names not allowed'] };
                        }
                    }
                }

                return { isValid: true, error: '' }
            }

            vm.saveDivisionList = function (list, refreshAfterSave) {
                referenceService.saveDivision(list).then(
                    function (result) {
                        if (result.data.length > 0) {
                            getDivisionList();
                            refreshAfterSave(result);
                        }

                    },
                    // Error
                    function (result) {
                        refreshAfterSave(result);
                    });
            }

            init();
         
        }],
        controllerAs: 'divisionVm'
    });

})();