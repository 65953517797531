(function () {

    var app = angular.module('phapApp');

    app.component('searchList',
        {
            templateUrl: 'app/administration/security/Components/listWithSearch.html',
            bindings: {
                originalData: '=',
                data: '=',
                fullOriginalData: '=',
                selectCallback: '=',
                createNewCallback: '=',
                showCreateNew: '<',
                searchPlaceholderText: '=',
                type: '=' //popupLeftList, mainLeftList,

            },
            controller: ['$filter', function ($filter) {

                var vm = this;

                var startsWith = function (actual, expected) {
                    var lowerStr = (actual + "").toLowerCase();
                    return lowerStr.indexOf(expected.toLowerCase()) === 0;
                }

                vm.refreshData = function () {

                    vm.data = $filter('filter')(vm.originalData, vm.searchText, vm.fullOriginalData, startsWith);

                    if (vm.fullOriginalData) {
                        var matchNames = $filter('filter')(vm.originalData, vm.searchText, startsWith);
                        function matchName(userInfo) {
                            var matchFound = false;
                            vm.data.forEach(function (matchedData) {
                                if (matchedData.name === userInfo.name) {
                                    matchFound = true;
                                }
                            });
                            return matchFound;
                        }
                        vm.data = vm.fullOriginalData.filter(matchName); 
                    }
                };
                vm.selectItem = function (item) {
                    vm.selectCallback(item);
                }
                vm.createNew = function () {
                    vm.createNewCallback();
                }
            }
            ],
            controllerAs: 'searchListVm'
        });
})();