(function () {
    angular.module('phapApp')
        .directive('memberSearchResults', ['$compile', '$timeout', 'uiGridConstants', '$window', 'Constants', function ($compile, $timeout, uiGridConstants, $window, Constants) {
                return {
                    restrict: 'EA',
                    require: 'ngModel',
                    scope: {
                        ngModel: '=',
                        selectMember: "&"
                    },
                    templateUrl: "app/_directives/memberSearchResults.html",
                    link: function ($scope, elem, attrs, ctrl) {

                        var isRunningInExtraSmallScreen = $(document).width() < 768;
                        var dateFormat = Constants.DatePickerFormat;

                        var rowsRendered = function () {
                            $scope.filteredRecords = $scope.gridApi.core.getVisibleRows($scope.gridApi.grid).length;
                        };
                        $scope.dob = moment($scope.ngModel.filter.dob).format(dateFormat);

                        $scope.isGlobalSearch = !$scope.ngModel.filter.isIncidentSearch &&
                            !$scope.ngModel.filter.householdMembersInCurrentIncident &&
                            !$scope.ngModel.filter.isMemberValidation;

                        var addressStrings = [];
                        var string1 = "";
                        var string2 = "";
                        var address = $scope.ngModel.filter.address;
                        if (!!address) {
                            if (!!address.unitNo) {
                                string1 += address.unitNo + "/";
                            }
                            if (!!address.streetNo) {
                                string1 += address.streetNo;
                                if (string1.length > 0) {
                                    string1 += " ";
                                }
                            }
                            if (!!address.streetName) {
                                string1 += address.streetName;
                                if (!!string1) {
                                    addressStrings.push(string1);
                                }
                            }
                            if (!!address.suburb) {
                                string2 += address.suburb;
                            }
                            if (!!address.postcode) {
                                if (string2.length > 0) {
                                    string2 += ", ";
                                }
                                string2 += address.postcode;
                            }
                            if (!!string2) {
                                addressStrings.push(string2);
                            }

                        }
                        $scope.addressStrings = addressStrings;

                        var $exactMatchFound = false;
                        _.each($scope.ngModel.members, function (m) {
                            if (m.isExactMatch) {
                                    $exactMatchFound = true;
                                }
                            });
                        $scope.exactMatchFound = $exactMatchFound;

                        _.each($scope.ngModel.members, function(m) {
                                m.fullName = m.member.givenName + " " + m.member.surname;
                            });


                        var getColumnDefs = function () {
                                return [
                                    {
                                        field: 'member.id',
                                        visible: false
                                    },
                                    {
                                        field: 'isExactMatch',
                                        visible: false
                                    },
                                    {
                                        field: 'applicationId',
                                        visible: false
                                    },
                                    {
                                        field: 'applicationNumber',
                                        displayName: 'Application',
                                        enableHiding: false,
                                        visible: !$scope.isGlobalSearch,
                                        cellTemplate:
                                            '<div class="ui-grid-cell-contents"><a href="" target="_blank">{{row.entity.applicationNumber}}</a></div>'
                                    },
                                    {
                                        field: 'fullName',
                                        displayName: 'Name',
                                        enableHiding: false,
                                        cellClass: 'cell-class',
                                        cellTemplate: '<div class="ui-grid-cell-contents">{{row.entity.fullName}}</div>'
                                    },
                                    {
                                        field: 'member.knownAs',
                                        displayName: 'Known As',
                                        enableHiding: false,
                                        visible: $scope.isGlobalSearch,
                                        cellClass: 'cell-class',
                                        cellTemplate: '<div class="ui-grid-cell-contents">{{row.entity.member.knownAs}}</div>'
                                    },
                                    {
                                        field: 'member.genderDescription',
                                        displayName: 'Gender',
                                        enableHiding: false,
                                        cellClass: 'cell-class',
                                        visible: $scope.isGlobalSearch
                                    },
                                    {
                                        field: 'member.dob',
                                        displayName: 'DOB',
                                        enableHiding: false,
                                        cellClass: 'cell-class date-field',
                                        cellFilter: Constants.GridDateFormat,
                                        width: $scope.isGlobalSearch ? '15%' : '20%',
                                        cellTemplate : '<div style="text-align: left" class="date-field">{{row.entity.member.dob | phapDate}}</div>'
                                },
                                {
                                    field: 'member.contactDetails.address.addressString',
                                    displayName: 'Address',
                                    enableHiding: false,
                                    cellClass: 'cell-class',
                                    cellTemplate: '<div >{{row.entity.member.contactDetails.address.addressString}}</div>'
                                },
                                {
                                    field: 'member.contactDetails.phone',
                                    displayName: 'Phone',
                                    enableHiding: false,
                                    cellClass: 'cell-class',
                                    cellTemplate: '<div class="ui-grid-cell-contents">{{row.entity.member.contactDetails.phone}}</div>'
                                    
                                },
                                {
                                    field: 'member.contactDetails.email',
                                    displayName: 'Email',
                                    enableHiding: false,
                                    cellClass: 'cell-class',
                                    cellTemplate: '<div class="ui-grid-cell-contents">{{row.entity.member.contactDetails.email}}</div>'
                                }
                            ];

                        };

                        // Called only if $scope.ngModel.filter.householdMembersInCurrentIncident == true.
                        // Displays the the latest application for the selected member (for which the household members appearing in the 
                        // current incident were returned )
                        $scope.showApplicationForSelectedMember = function () {
                            if ($scope.ngModel.filter.applicationType === Constants.applicationType.relief) {
                                return "#/home/relief/edit/" + $scope.ngModel.filter.applicationId;
                            }
                            else if ($scope.ngModel.filter.applicationType === Constants.applicationType.reestablish) {
                                return "#/home/reestablish/edit/" + $scope.ngModel.filter.applicationId;
                            }
                        }

                        $scope.showApplication = function (id, applicationType) {
                            if (event) {
                                event.stopPropagation();
                            }
                            var appType = null;
                            if (applicationType === Constants.applicationType.relief) {
                                appType = 'relief';
                            }
                            else if (applicationType === Constants.applicationType.reestablish) {
                                appType = 'reestablish';
                            }
                            if (!!appType) {
                                var path = "#/home/" + appType + "/edit/" + id;
                                $window.open(path);
                            }
                        };


                        $scope.gridOptions = {
                            enableSorting: false,
                            enableColumnMenus: false,
                            enableFiltering: false,
                            enableHorizontalScrollbar: uiGridConstants.scrollbars.NEVER,
                            enableVerticalScrollbar: uiGridConstants.scrollbars.ALWAYS,
                            enableRowSelection: true,
                            enableFullRowSelection: true,
                            enableRowHeaderSelection: false,
                            enableSelectAll: false,
                            multiSelect: false,
                            rowHeight: 56,
                            columnDefs: getColumnDefs(),
                            appScopeProvider: {
                                onDblClick: function (row) {
                                    if (!$scope.iosOrMobile) {
                                        $scope.selectItem(row.entity);
                                    }
                                },
                                onEditTouchend: function (row) {
                                    $scope.selectItem(row.entity);
                                },
                                selectRow: function (row) {
                                    //TODO: change background colour?
                                    $scope.selectItem(row.entity);
                                }
                            },
                            rowTemplate:
                            '<div ' +
                                    'ng-dblclick="grid.appScope.onDblClick(row)" ' +
                                    'ng-click="grid.appScope.selectRow(row, $event, 1)"> ' +
                                    '<div ' +
                                    'class="ui-grid-cell" ' +
                                    'ng-class="{ \'row-exact-match\': row.entity.isExactMatch && !grid.appScope.ngModel.filter.isIncidentSearch}" ' +
                                    'ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" ' +
                                    'ui-grid-cell ' +
                                    'ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader }" ' +
                                    'style="cursor:pointer"> ' +
                                    '</div>' +
                            '</div>',



                            onRegisterApi: function (gridApi) {
                                $scope.gridApi = gridApi;
                                $scope.gridApi.core.on.rowsRendered($scope, rowsRendered);
                            }
                        };

                        $scope.clickConditions = function() {
                            return $scope.isGlobalSearch;
                        }

                        $scope.gridOptions.data = $scope.ngModel.members;

                        var rowtpl = '<div ng-class="{\'green\':true, \'blue\':row.entity.count==1 }"><div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader }" ui-grid-cell></div></div>';

                        $scope.getTableHeight = function (height) {
                            var rowHeight = height; // your row height
                            var headerHeight = height; // your header height
                            var rowsToDisplay = $scope.gridOptions.data.length <= 6 ? $scope.gridOptions.data.length : 6;
                            var tableHeight = rowsToDisplay * rowHeight + headerHeight;
                            var result = tableHeight + "px";
                            return result;
                        };

                        $scope.tableHeight = $scope.getTableHeight(56);

                        $scope.selectItem = function(entity) {
                            if ($scope.isGlobalSearch) {
                                if (confirm('Are you sure you want to select this member?.')) {
                                    $scope.selectMember({ member: entity.member });
                                }
                            }
                            else if (event) {
                                if (event.target.tagName.toLowerCase() === 'a') {
                                    var appType = $scope.ngModel.filter.householdMembersInCurrentIncident
                                        ? $scope.ngModel.filter.currentApplicationType
                                        : $scope.ngModel.filter.applicationType;
                                    $scope.showApplication(entity.applicationId, appType);
                                }
                            }
                        }


                    }


                }
     
        }]);
})();