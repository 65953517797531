////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////// Google address widget /////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/// Usage: /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/// <div app-Search-Addtional-Filters region-options="regions" ng-model="search" region-dropdown-settings="regionDropdownSettings"//
//region - translation - texts="regionDropdownTranslationTexts" lga-options="lgas" lga-dropdown-settings="lgaDropdownSettings"//////
///lga - translation - texts="lgaDropdownTranslationTexts" state - options="states" state - dropdown - settings="stateDropdownSettings"
//state - translation - texts="stateDropdownTranslationTexts"></div >//////////////////////////////////////////////////////////////


(function () {
    angular.module('phapApp')
        .directive('appSearchAddtionalFilters', ['lgaService', function (lgaService, $parse) {
                return {
                    require: 'ngModel',
                    replace: true,
                    templateUrl: '/app/_directives/appSearchAddtionalFilters.html',
                    scope: {
                        ngModel: '=',    
                        regionOptions: '=',
                        regionTranslationTexts: '=',
                        regionDropdownSettings: '=',
                        lgaOptions: '=',
                        lgaTranslationTexts: '=',
                        lgaDropdownSettings: '=',
                        stateOptions: '=',
                        stateTranslationTexts: '=',
                        stateDropdownSettings: '=',

                    },
                    link: function (scope, element, attrs, model) {

                        scope.region = {};
                        scope.lga = {};
                        scope.state = {};

                        scope.$watch('ngModel', function (newValue) {
                            if (!newValue) return;
                            scope.region = newValue.region;
                            scope.lga = newValue.lga;
                            scope.state = newValue.state;
                        });

                    }
                };
            }
        ])
})();
