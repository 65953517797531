(function () {
var app = angular.module('phapApp');

app.component("reestablishSearch", {
    templateUrl: "app/home/reestablish/search/reestablishSearch.html",
    bindings: {
    },
    controllerAs: "vm",
    controller: [
        '$scope', '$location', 'uiGridConstants', 'config', '$cookies', 'security', 'applicationSearchService', 'cacheService', 'navigationService', 'notificationService','Constants',
        function ($scope, $location, uiGridConstants, config, $cookies, security, applicationSearchService, cacheService, navigationService, notificationService, Constants) {
                    var vm = this;

                    navigationService.setCurrentMenuStatus(
                        navigationService.topMenuCodes.emergencyManagement,
                        navigationService.pageCodes.reestablishApplications);

                    var getColumnDefs = function () {
                        return [
                            {
                                field: 'id',
                                visible: false
                            },
                            {
                                field: 'applicationNumber',
                                displayName: 'Application #',
                                enableHiding: false,
                                width: '12%',
                                enableColumnMenu: false
                            },
                            {
                                field: 'applicantName',
                                displayName: 'Applicant name',
                                enableHiding: false,
                                width: '20%',
                                enableColumnMenu: false
                            },
                            {
                                field: 'affectedAddress',
                                displayName: 'Affected address',
                                enableHiding: false,
                                width: '36%',
                                enableColumnMenu: false
                            },
                            {
                                field: 'dateCreated',
                                displayName: 'Created date',
                                enableHiding: false,
                                width: '12%',
                                enableColumnMenu: false,
                                cellClass: 'date-field',
                                cellFilter: Constants.GridDateFormat
                            },
                            {
                                field: 'state',
                                displayName: 'Status',
                                enableHiding: false,
                                width: '10%',
                                enableColumnMenu: false
                            },
                            {
                                field: 'entitlementAmount',
                                displayName: 'Amount',
                                enableHiding: false,
                                width: '10%',
                                enableColumnMenu: false,
                                cellClass: 'currency',
                                cellTemplate: '<div class="ui-grid-cell-contents">{{row.entity.state.toLowerCase() === "draft" ? "" : (row.entity.entitlementAmount | currency)}}</div>'
                            }
                        ];
                    };

                    // Returns the number of rows currently in the grid
                    var rowsRendered = function () {
                        vm.filteredRecords = vm.gridApi.core.getVisibleRows(vm.gridApi.grid).length;
                    };

                    var getGridOptions = function () {
                        return {
                            enableSorting: true,
                            enableFiltering: false,
                            enableHorizontalScrollbar: uiGridConstants.scrollbars.ALWAYS,
                            enableVerticalScrollbar: uiGridConstants.scrollbars.ALWAYS,
                            enableRowSelection: true,
                            enableFullRowSelection: true,
                            enableRowHeaderSelection: false,
                            enableSelectAll: false,
                            multiSelect: false,
                            height: 40,
                            enableRowHashing: false,
                            columnDefs: getColumnDefs(),
                            appScopeProvider: {
                                onDblClick: function (row) {
                                    if (!vm.iosOrMobile) {
                                        vm.openItem(row.entity);
                                    }
                                },
                                onEditTouchend: function (row) {
                                    vm.openItem(row.entity);
                                },
                                selectRow: function (row) {
                                    vm.openItem(row.entity);
                                }
                            },

                            rowTemplate:
                            '<div ' +
                            'ng-dblclick="grid.appScope.onDblClick(row)" ' +
                            'ng-click="grid.appScope.selectRow(row, $event, 1)"> ' +
                            '<div ' +
                            'class="ui-grid-cell" ' +
                            'ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" ' +
                            'ui-grid-cell ' +
                            'ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader }" ' +
                            'style="cursor:pointer"> ' +
                            '</div>' +
                            '</div>',

                            onRegisterApi: function (gridApi) {
                                vm.gridApi = gridApi;
                                vm.gridApi.core.on.rowsRendered($scope, rowsRendered);
                            }
                        };
                    };

                    vm.clearFilters = function () {
                        vm.gridOptions.data = [];
                        vm.search = {
                            applicationNumber: "",
                            givenName: "",
                            surname: "",
                            dob: null,
                            phoneOrMobile:"",
                            emailAddress:"",
                            address: {
                                unitNo: "",
                                streetNo: "",
                                streetName: "",
                                suburb: "",
                                postcode: ""
                            }
                        };
                        vm.item = {
                            googleAddress: "",
                            googleSearchAddress: ""
                        };
                        vm.allowExport = false;
                        vm.noResultsFound = false;

                        // Save filter state
                        cacheService.removeItemFromCache('reestablishSearchFilters.' + $cookies.get("UserId"));
                        
                    };
                    
                    vm.searchExport = function () {
                        if (vm.gridOptions.data.length === 0) {
                            return;
                        }
                        vm.exportToFile = true;
                        // have to use saved data to get the data that is contained in the data grid otherwise it
                        // will get the data that is on the screen
                        var savedData = vm.retrieveFilters();

                        if (savedData) {
                            vm.searchReestablishApplications(savedData);
                        }
                    };

                    vm.clickSearch = function() {
                        if (!validateForm()) {
                            return;
                        }

                        if (!vm.exportToFile) {
                            vm.submitting = true;
                            // Save filter state 
                            vm.saveFilters();
                        }

                        vm.searchReestablishApplications(vm.search);
                    }

                    function validateForm() {
                        vm.form.dob.$setValidity("invalidDate", vm.search.dob !== undefined);
                        return vm.form.$valid;
                    }

                    vm.searchReestablishApplications = function (searchObject) {
                        if (!vm.exportToFile)
                            vm.gridOptions.data = [];

                        var searchCriteria = {
                            memberGivenName: searchObject.givenName,
                            memberSurname: searchObject.surname,
                            memberDOB: searchObject.dob,
                            memberPhoneOrMobile: searchObject.phoneOrMobile,
                            memberEmailAddress: searchObject.emailAddress,
                            applicationNumber: searchObject.applicationNumber,
                            address: {
                                unitNo: searchObject.address.unitNo,
                                streetName: searchObject.address.streetName,
                                streetNo: searchObject.address.streetNo,
                                suburb: searchObject.address.suburb,
                                postcode: searchObject.address.postcode
                            }
                        }

                        applicationSearchService.searchReestablishmentApplications(searchCriteria, vm.exportToFile)
                            .then(
                                // Success
                                function (result) {
                                    if (vm.exportToFile) {
                                        var file = new Blob([result.data], { type: 'text/csv' });
                                        saveAs(file, 'ReestablishmentApplicationsList.csv');
                                        vm.exportToFile = false;
                                        return;
                                    }
                                    vm.submitting = false;

                                    if (result.data.length > 0) {
                                        vm.gridOptions.data = result.data;
                                        vm.allowExport = true;
                                        vm.noResultsFound = false;
                                    } else {
                                        vm.noResultsFound = true;
                                        vm.allowExport = false;
                                    }
                                },
                                // Error
                                function (result) {
                                    vm.submitting = false;
                                }
                            );
                    };

                    vm.openItem = function (entity) {
                        $location.path("/home/reestablish/edit/" + entity.id);
                    };

                    vm.saveFilters = function () {
                        cacheService.saveToCache('reestablishSearchFilters.' + $cookies.get("UserId"), vm.search);
                    };

                    vm.retrieveFilters = function () {
                        return cacheService.readFromCache('reestablishSearchFilters.' + $cookies.get("UserId"));
                    };

                    vm.loadSavedFilters = function()
                    {
                        var savedData = vm.retrieveFilters();

                        if (savedData) {
                            if (savedData.dob) {
                                savedData.dob = new Date(savedData.dob);
                            };
                            vm.search = savedData;
                        } else {
                            vm.clearFilters();
                        }
                    }
            
                    vm.$onInit = function () {
                        vm.gridOptions = getGridOptions();
                        vm.hasPermissions = true;
                        
                        security.getCurrentUserSecurityInfo()
                            .then(function (result) {
                                if (result && result.privileges.SearchReestablish) {
                                    vm.hasPermissions = true;
                                    initialise();
                                } else {
                                    vm.hasPermissions = false;
                                }
                                },
                                function () {
                                    vm.hasPermissions = false;
                                });
                    };

                    function initialise() {
                        vm.totalRecords = 50;
                        vm.filteredRecords = 0;
                        vm.allowExport = false;

                        //Initialize scope variables
                        vm.noResultsFound = false;
                        vm.submitting = false;
                        vm.exportToFile = false;
                        vm.dateFormat = Constants.DatePickerFormat;

                        vm.hasPermissions = true;

                        vm.dateOptions = {
                            dateFormat : Constants.DatePickerFormat,
                            maxDate: new Date(2050, 1, 1),
                            minDate: new Date(1900, 1, 1),
                            startingDay: 1
                        };
                        vm.loadSavedFilters();
                    }
            
        }]
});

})();