(function () {

    angular.module('phapApp')
        .factory('notificationService', ['$rootScope', function ($rootScope) {

            return {
                subscribe: function (scope, eventName, callback) {
                    var handler = $rootScope.$on(eventName, callback);
                    scope.$on('$destroy', handler);
                },

                notify: function (eventName, value) {
                    $rootScope.$emit(eventName, value);
                }
            };
        }]);

})();