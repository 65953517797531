(function () {
    angular.module('phapApp')
        .directive('initialPayment', ['utils', function (utils) {
            return {
                scope: true,
                restrict: 'E',
                templateUrl: 'app/home/reestablish/_initial-payment.html',
                controller: ['$scope', 'reestablishService', function ($scope, reestablishService) {


                    $scope.item.maxReestablishmentInitialPayment = null;
                    $scope.$watchGroup(['item.incident.effectiveDate', 'item.paymentAmountsTotalClaimAmount', 'item.weeklyIncomeMaximumPayment'], function (nv, ov) {
                        if (nv != ov && nv && nv[0]) {
                            reestablishService.loadPeriodConfig(nv[0]).then(function (data) {
                                if (data.initialPayment) {
                                    $scope.item.maxReestablishmentInitialPayment = data.initialPayment;
                                    reestablishService.calculateInitialPayment($scope.item);
                                }
                            });
                        }
                    });

                    $scope.$watchGroup(['adults', 'children', 'item.weeklyIncomeTotalWeeklyIncome', 'item.paymentAmountsTotalClaimAmount', 'item.weeklyIncomeMaximumPayment', 'item.evidenceRequired', 'item.exceptionalCircumstancesExist', 'item.exceptionalCircumstancesAmount'], function (nv, ov) {
                        reestablishService.calculateInitialPayment($scope.item);
                    });

                   

                    $scope.getFormSections = function () {
                        return angular.extend({}, $scope.formSections.categoriesOfAssessment.subMenu, $scope.formSections.eligibilityCriteria.subMenu);
                    }

                }]
            };
        }]);
})();