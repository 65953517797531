//<!--Google maps/ places configuration -- >
//<add key="GooglePlacesAPIKey" value="AIzaSyDOh2bmnvAx4dsIEquSDsv9hTtfqwLk4UY" />
//<add key="GoogleMapsClientId" value="gme-dohs" />
// 
// load script as follows:
// <script type="text/javascript" src="https://maps.googleapis.com/maps/api/js?libraries=places&key=@System.Configuration.ConfigurationManager.AppSettings["GooglePlacesAPIKey"]"></script >

(function () {
    angular.module('phapApp')
        //directive to call the google api for address and custom logic for address validation
        .directive('address', [function () {
            return {
                require: 'ngModel',
                scope: {
                    callbackFn: '&',
                    ngModel: '=',
                    details: '=?'
                },
                link: function (scope, element, attrs, model) {
                    // Create google places autocomplete. Supply options that limit its suggestions.
                    var targetElement = element[0];

                    scope.gPlace =
                        new google.maps.places.Autocomplete(
                            targetElement,
                            {
                                types: ['address'],
                                componentRestrictions: {
                                    country: "au"
                                }
                            }
                        );

                    // Called when the google places autocomplete's "place_changed" event fires
                    var updatePlace = function () {
                        if (!scope.gPlace) return;

                        var place = scope.gPlace.getPlace();

                        scope.$apply(function () {
                            // Set the raw entered text as the ng-model
                            model.$setViewValue(element.val());

                            scope.details = place;

                            scope.callbackFn({
                                arg1: element.val(),
                                arg2: place
                            });
                        });
                    }

                    google.maps.event.addListener(scope.gPlace, 'place_changed', updatePlace);
                }
            };
        }
    ])
})();