(function () {
    angular.module('phapApp')
        .directive('applicationDocuments', function () {
            return {
                require: 'ngModel',
                templateUrl: '/app/_directives/applicationDocuments.html',
                scope: {
                    section: '&',
                    showSize: '&',
                    showType: '&',
                    showDate: '&',
                    showUser: '&',
                    showHeader: '&',
                    documentTypes: '<',
                    isDisabled: '<',
                    hide: '<',
                    title: '@',
                    onChange: '&',
                    onFileSelect: '&',
                    onCancel: '&',
                    controlInverse: '=',
                    initialExpand: '<',
                    hideHeader: '<',
                    expanded: '=?'
                },  
                controller: ['$scope', 'documentService', 'config', 'utils', '$timeout', function ($scope, documentService, config, utils, $timeout) {

                    $scope.expanded = true;
                    if (!angular.isDefined($scope.isDisabled)) {
                        $scope.isDisabled = false;
                    }
                    

                    $scope.addFiles = function (ids) {
                        if ($scope.documents && ids) {
                            ids.forEach(function (id) {
                                $scope.documents.push(id);
                                $scope.documentsChanged();
                            });
                        }
                    };



                    $scope.deleteFile = function (id) {
                        if ($scope.documents) {
                            var index = $scope.documents.indexOf(id);
                            $scope.documents.splice(index, 1);
                            $scope.documentsChanged();
                        }
                    };

                    $scope.selectFile = function (options) {
                        $scope.onFileSelect({ options: options });
                    };

                    $scope.cancelUpload = function () {
                        $scope.onCancel();
                    };

                    if ($scope.controlInverse) {
                        $scope.controlInverse.$newUpload = function (documentTypes) {
                            $scope.$broadcast('newUpload', documentTypes);
                        };
                    }

                }],

                link: function ($scope, elem, attrs, ngModelCtrl) {
                    
                    ngModelCtrl.$render = function () {
                        var section = $scope.section();
                        if (ngModelCtrl.$viewValue) {
                            if (ngModelCtrl.$viewValue[section] == null) {
                                ngModelCtrl.$viewValue[section] = [];
                            }
                            $scope.documents = ngModelCtrl.$viewValue[section];
                            ngModelCtrl.$setPristine();
                        }
                    };

                    $scope.documentsChanged = function () {
                        // set dirty manually
                        ngModelCtrl.$setDirty();
                        $scope.onChange();
                    };
                }
            };
        });
})();