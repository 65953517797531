(function () {

	var app = angular.module('phapApp');
	app.component('cardActivitySearch', {
		templateUrl: 'app/financeManagement/cardActivity/cardActivitySearch.html',
		bindings: {
		},
		controller: ['$scope', '$routeParams', '$location', 'uiGridConstants', 'cardService', 'cardStorageService',
			'Constants', 'config', '$window', '$cookies', '$timeout', 'security', 'cacheService', '$q',
            '$uibModal', 'utils', 'localStorageService', 'navigationService', 'notificationService','$interval',
			function ($scope, $routeParams, $location, uiGridConstants, cardService, cardStorageService, constants,
                config, $window, $cookies, $timeout, security, cacheService, $q, $uibModal, utils, localStorageService, navigationService, notificationService, $interval) {
				var vm = this;
				var isRunningInExtraSmallScreen = false;
                vm.hasPermission = false;

                navigationService.setCurrentMenuStatus(
                    navigationService.topMenuCodes.financeManagement,
                    navigationService.pageCodes.cardActivitySearch);

				vm.$onInit = function () {
					vm.constants = constants;
					vm.loaded = false;
					vm.firstSearch = undefined;
					vm.searching = false;
					vm.exporting = false;
					vm.allPromises = [];
					vm.filterVisible = true;
					vm.gridOptions = getGridOptions();
					vm.gridOptions.data = [];
                    vm.refdata = {
                        locations: null,
                        regions: null,
                        activityRequestTypes: [],
                        activityRequestStatuses: vm.constants.cardActivityRequestOrderStatus
                    };
					var keyMapping = { 'description': 'label', 'id': 'id', 'code': 'code' };
					vm.refdata.activityRequestTypes = _.map(vm.constants.cardActivityRequestType, function (currentObject) {
						return _.transform(currentObject, function (result, value, key) {
							result[keyMapping[key]] = value;
						});
					});

					$scope.ddSelectSelected = {}; // M
					vm.initSearchParms();
					vm.allPromises.push(
						security.getCurrentUserSecurityInfo().then(
							function (result) {

								vm.currentUserSecurityInfo = result;
								vm.hasPermission = result.privileges.SearchCards;
							},
							function () {
								vm.currentUserSecurityInfo = {};
							})
					);
					
					$q.all(vm.allPromises).then(
						function (result) {
							vm.loadSavedFilters();
							vm.loaded = true;

						}, function (data) {
							var result = data;
						});
				};
				// #region Init routines
				vm.initSearchParms = function () {
					vm.search = {
						orderNumber: '',
						searchActivityRequestTypes: [],
						searchActivityRequestStatuses: [],
						activityRequestStartDate: null,
						activityRequestEndDate: null,
						activityRequestEndDateError: false,
						activityRequestStartDateError: false
					};
					vm.totalSearchResults = 0;
					vm.noResultsFound = false;
				};

                vm.checkActivityRequestSearchDates = function () {
                    var activityRequestStartDate = null;
                    var activityRequestEndDate = null;
                    vm.searchForm.activityRequestEndDate.$setValidity('greaterThanActivityRequestStartDate', true);
                    vm.searchForm.activityRequestStartDate.$setValidity('greaterThanActivityRequestStartDate', true);
                    if (vm.search.activityRequestEndDate) {
                        activityRequestStartDate = moment(vm.search.activityRequestStartDate).add(1, 'days');
                    }
                    if (vm.search.activityRequestEndDate) {
                        activityRequestEndDate = moment(vm.search.activityRequestEndDate).add(1, 'days');
                        if (!activityRequestStartDate || moment(activityRequestEndDate, 'YYYY-MM-DD').isBefore(activityRequestStartDate)) {
                            vm.searchForm.activityRequestEndDate.$setValidity('greaterThanActivityRequestStartDate', false);
                        }
                    }
                };
				// #endregion

				// #region Search
                var getSearchCriteria = function () {
                    var searchCriteria = angular.copy(vm.search);
                    var activityRequestTypeIds = [];
                    var activityRequestStatusIds = [];
                    _.map(searchCriteria.searchActivityRequestTypes, function (item) {
                        return activityRequestTypeIds.push(item.id);
                    });
                    _.map(searchCriteria.searchActivityRequestStatuses, function (item) {
                        return activityRequestStatusIds.push(item.id);
                    });
                    searchCriteria.activityRequestTypeIds = activityRequestTypeIds;
                    searchCriteria.activityRequestStatusIds = activityRequestStatusIds;
                    searchCriteria.currentPage = undefined;
                    searchCriteria.recordsPerPage = undefined;

                    return searchCriteria;
                };

				vm.exportToCsv = function () {
					vm.exporting = true;
					var savedData = vm.retrieveFilters();
					//have to use saved data to get the data that is contained in the data grid otherwise it
					//will get the data that is on the screen
					if (savedData) {
						vm.search = savedData;
						cardService.exportCardActivitySearch(getSearchCriteria(false)).then(
							function (response) {
								vm.exporting = false;
								var file = new Blob([response.data], { type: 'text/csv' });
								saveAs(file, 'CardActivityList.csv');
							},
							function (response) {
								vm.exporting = false;
							});
					}
				};

				vm.getSearchResults = function (newSearch) {

					vm.searching = true;
					if (newSearch) {
						vm.gridOptions.data = [];
					}

					vm.gridApi.selection.clearSelectedRows();
					vm.saveFilters();
					var promise = $q.defer();

					cardService.cardActivitySearch(getSearchCriteria()).then(
						function (response) {
							vm.totalSearchResults = response.data.length;
							vm.gridOptions.data = response.data;
							vm.noResultsFound = vm.totalSearchResults === 0;

							vm.searchComplete = false;
							vm.searching = false;
						}, function (error) {
							vm.searching = false;
							$scope.gridApi.infiniteScroll.dataLoaded();
							promise.reject();
						});
					return promise.promise;
				};

				// #region Grid
				var openItem = function (row) {
					var typeId = _.find(vm.constants.cardActivityRequestType, function (item) {
						return item.code.toLowerCase() == row.entity.activityRequestType.toLowerCase()
					}).id;
					$location.path("/financeManagement/cards/cardActivity/" + typeId + '/' + row.entity.id);

				};
				var getGridColumnDefs = function () {
                    return [
                        {
                            field: 'id',
                            visible: false,
                            enableHiding: false

                        },
                        {
                            field: 'orderNumber',
                            displayName: 'Order number',
                            enableHiding: false,
                            enableColumnMenu: false,
                            width: '15%'
                        },
                        {
                            field: 'activityRequestDate',
                            displayName: 'Activity request date',
                            cellClass: 'date-field',
                            enableHiding: false,
                            enableColumnMenu: false,
                            cellFilter: constants.GridDateFormat,
                            width: '15%'
                        },
                        {
                            field: 'activityRequestStatus',
                            displayName: 'Status',
                            enableHiding: false,
                            enableColumnMenu: false,
                            width: '15%'
                        },
                        {
                            field: 'activityRequestType',
                            displayName: 'Type',
                            enableHiding: false,
                            enableColumnMenu: false,
                            width: '20%'
                        },
                        {
                            field: 'numberOfCards',
                            displayName: 'Number of cards',
                            type: 'number',
                            enableHiding: false,
                            enableColumnMenu: false,
                            width: '15%'
                        },
                        {
                            field: 'createdBy',
                            displayName: 'Created by',
                            enableHiding: false,
                            enableColumnMenu: false,
                            width: '20%'
                        }
                    ];
				};
				function saveState() {
					var state = vm.gridApi.saveState.save();
					localStorageService.set('cardActivitySearch.gridState.' + $cookies.get("UserId"), state);
				}

				function restoreState() {
					$timeout(function () {
                        var state = localStorageService.get('cardActivitySearch.gridState.' + $cookies.get("UserId"));
						if (state) vm.gridApi.saveState.restore($scope, state);
					});
				}
				var getGridOptions = function () {
                    return {
                        enableGridMenu: false,
                        enableSorting: true,
                        enableFiltering: false,
                        enableColumnResizing: true,
                        enableHorizontalScrollbar: uiGridConstants.scrollbars.ALWAYS,
                        enableVerticalScrollbar: uiGridConstants.scrollbars.ALWAYS,
                        enableRowSelection: false,
                        enableFullRowSelection: false,
                        enableRowHeaderSelection: false,
                        enableSelectAll: false,
                        multiSelect: false,
                        enableRowHashing: false,
                        columnDefs: getGridColumnDefs(),
                        appScopeProvider: {
                            selectRow: function (row) {
                                openItem(row);
                            }
                        },
                        rowTemplate:
                        '<div ng-click="grid.appScope.selectRow(row);"> ' +
                        '<div class="ui-grid-cell" ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" ui-grid-cell ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader }" style="cursor:pointer"> ' +
                        '</div>' +
                        '</div>',
                        onRegisterApi: function (gridApi) {
                            vm.gridApi = gridApi;
                            vm.gridApi.colResizable.on.columnSizeChanged($scope, saveState);
                            vm.gridApi.core.on.columnVisibilityChanged($scope, saveState);
                            vm.gridApi.core.on.filterChanged($scope, saveState);
                            vm.gridApi.core.on.sortChanged($scope, saveState);
                            // call resize every 500 ms for 5 s after modal finishes opening - usually only necessary on a bootstrap modal
                            $interval(function () {
                                vm.gridApi.core.handleWindowResize();
                            }, 500, 10);
                            // Restore previously saved state.
                            restoreState();
                        }
                    };
				};

				// #endregion

				// #region Filters 
				// TODO: FM: Add page to filters to allow for caching per page if this component is used on multiple pages ??
				vm.clearFilters = function () {
					vm.initSearchParms();
					vm.gridOptions.data = [];
					if (vm.gridApi && vm.gridApi.selection) {
						vm.gridApi.selection.clearSelectedRows();
					}

					if (vm.enableSearchPaging) {
						$timeout(function () {
							// timeout required to allow for digest cycle to complete and grid to finish refresh.
							vm.gridApi.infiniteScroll.resetScroll(false, false);
						});
					}
					vm.filterVisible = true;
					cacheService.removeItemFromCache('cardActivitySearchFilters.' + $cookies.get("UserId"));
				};

				vm.saveFilters = function () {
                    cacheService.saveToCache('cardActivitySearchFilters.' + $cookies.get("UserId"), vm.search);
				};

				vm.retrieveFilters = function () {
                    return cacheService.readFromCache('cardActivitySearchFilters.' + $cookies.get("UserId"));
				};

                vm.loadSavedFilters = function () {
                    var savedData = vm.retrieveFilters();
                    if (savedData) {
                        vm.search = savedData;
                    } else {
                        vm.clearFilters();
                    }
                };
				// #endregion

				// #region Button methods				

                vm.isMinimumRowsSelected = function () {
                    return vm.gridApi.selection.getSelectedRows().length > 0;
                };
                vm.redirectToCardActivity = function (typeId) {
                    if (vm.gridApi.selection.getSelectedRows()[0]) {
                        $location.path("/financeManagement/cards/cardActivity/" + typeId + '/' + vm.gridApi.selection.getSelectedRows()[0].id);
                    }
                    else {
                        $location.path("/financeManagement/cards/cardActivity/" + typeId);
                    }
                };

                vm.hasSelectedRows = function () {
                    vm.selectedRows = vm.gridApi.selection.getSelectedRows();
                    return vm.selectedRows.length > 0;
                };
				// #endregion

				// #region Drop filter
				vm.requestTypeDropdownTranslationTexts = {
					buttonDefaultText: "All request types",
					uncheckAll: "Clear filter"
				};
				vm.statusDropdownTranslationTexts = {
					buttonDefaultText: "All request statuses",
					uncheckAll: "Clear filter"
				};
				vm.statusDropdownSettings = {
					smartButtonMaxItems: 3,
					smartButtonTextConverter: function (item) { return item; },
					buttonClasses: "btn-multi-select-bare",
					showCheckAll: false
				};
				// #endregion
			}
		],
		controllerAs: 'cardActivitySearch'
	});

})();