(function () {
    angular.module('phapApp')
        .controller(
            'reliefApplicationsController',
            [
                '$scope', 'reliefService', '$location', '$cookies', '$timeout', '$http', '$q', 'uiGridConstants', 'config', '$window', '$cookies', 'security', 'navigationService', 'notificationService', 'Constants',
                function ($scope, reliefService, $location, $cookies, $timeout, $http, $q, uiGridConstants, config, $window, $cookies, security, navigationService, notificationService, Constants) {

                    navigationService.setCurrentMenuStatus(
                        navigationService.topMenuCodes.emergencyManagement,
                        navigationService.pageCodes.reliefApplications);



                    $scope.dateFormat = Constants.DatePickerFormat;

                    $scope.currentUserSecurityInfo = {};
                    $scope.allowExport = false;

                    var isRunningInExtraSmallScreen = $(document).width() < 768;

                    reliefService.loadSearchFilterRefData().then(function (data) {
                        $scope.refData = data;
                        $scope.regions = [];
                        _.map(data.regions, function (item) {
                            return $scope.regions.push({ id: item.id, label: item.name });
                        });

                        $scope.lgas = [];
                        _.map(data.lgas, function (item) {
                            return $scope.lgas.push({ id: item.lgaCode, label: item.name });
                        });


                        $scope.states = [];
                        _.map(data.applicationStatuses, function (item, index) {
                            return $scope.states.push({ id: parseInt(item.substring(1, item.indexOf(","))), label: item.substring(3, item.length - 1) });
                        });

                        $scope.restoreFilters();
                    });



                    $scope.regionDropdownSettings = {
                        smartButtonMaxItems: 3,
                        smartButtonTextConverter: function (item) { return item; },
                        buttonClasses: "btn-multi-select-bare",
                        showCheckAll: false
                    };

                    $scope.lgaDropdownSettings = {
                        smartButtonMaxItems: 2,
                        smartButtonTextConverter: function (item) { return item; },
                        buttonClasses: "btn-multi-select-bare",
                        showCheckAll: false
                    };

                    $scope.stateDropdownSettings = {
                        smartButtonMaxItems: 3,
                        smartButtonTextConverter: function (item) { return item; },
                        buttonClasses: "btn-multi-select-bare",
                        showCheckAll: false
                    };

                    $scope.regionDropdownTranslationTexts = {
                        buttonDefaultText: "All regions",
                        uncheckAll: "Clear region filter"
                    };

                    $scope.lgaDropdownTranslationTexts = {
                        buttonDefaultText: "All lgas",
                        uncheckAll: "Clear lga filter"
                    };

                    $scope.stateDropdownTranslationTexts = {
                        buttonDefaultText: "All status",
                        uncheckAll: "Clear lga filter"
                    };

                    $scope.errorCountForField = function (fieldName) {
                        var field = $scope.frm[fieldName];

                        if (!field) {
                            throw "frm does not contain a key '" + fieldName + "'";
                        }

                        return _.keys(field.$error).length;
                    };

                    $scope.dateOptions = {
                        dateFormat: Constants.DatePickerFormat,
                        maxDate: new Date(2050, 1, 1),
                        minDate: new Date(1900, 1, 1),
                        startingDay: 1
                    };

                    var getColumnDefs = function () {
                        return [
                            {
                                field: 'id',
                                visible: false
                            },
                            {
                                field: 'applicationNumber',
                                displayName: 'Application #',
                                enableHiding: false,
                                width: '12%',
                                enableColumnMenu: false
                            },
                            {
                                field: 'applicantName',
                                displayName: 'Applicant name',
                                enableHiding: false,
                                width: '20%',
                                enableColumnMenu: false
                            },
                            {
                                field: 'affectedAddress',
                                displayName: 'Affected address',
                                enableHiding: false,
                                width: '32%',
                                enableColumnMenu: false
                            },
                            {
                                field: 'dateCreated',
                                displayName: 'Created date',
                                enableHiding: false,
                                width: '10%',
                                enableColumnMenu: false,
                                cellClass: 'date-field',
                                cellFilter: Constants.GridDateFormat
                            },
                            {
                                field: 'state',
                                displayName: 'Status',
                                enableHiding: false,
                                width: '8%',
                                enableColumnMenu: false
                            },
                            {
                                field: 'entitlementAmount',
                                displayName: 'Amount',
                                enableHiding: false,
                                width: '8%',
                                enableColumnMenu: false,
                                cellClass: 'currency',
                                cellTemplate: '<div class="ui-grid-cell-contents">{{row.entity.state.toLowerCase() === "draft" ? "" : (row.entity.entitlementAmount | currency)}}</div>'
                            },
                            {
                                field: 'dob',
                                displayName: 'Date of Birth',
                                enableHiding: false,
                                width: '10%',
                                enableColumnMenu: false,
                                cellClass: 'date-field',
                                cellFilter: Constants.GridDateFormat
                            }

                        ]
                    };

                    // Returns the number of rows currently in the grid
                    var rowsRendered = function () {
                        $scope.filteredRecords = $scope.gridApi.core.getVisibleRows($scope.gridApi.grid).length;
                    };

                    $scope.saveState = function (data) {
                        $window.localStorage.setItem("reliefSearch.gridState." + $cookies.get("UserId"), JSON.stringify($scope.gridApi.saveState.save()));

                    };

                    $scope.restoreState = function () {
                        var state = $window.localStorage.getItem("reliefSearch.gridState." + $cookies.get("UserId"));
                        if (state) {
                            $scope.gridApi.saveState.restore($scope, JSON.parse(state));
                        }


                    };

                    $scope.gridOptions = {
                        enableSorting: true,
                        enableFiltering: false,
                        enableHorizontalScrollbar: uiGridConstants.scrollbars.ALWAYS,
                        enableVerticalScrollbar: uiGridConstants.scrollbars.ALWAYS,
                        enableRowSelection: true,
                        enableFullRowSelection: true,
                        enableRowHeaderSelection: false,
                        enableSelectAll: false,
                        multiSelect: false,
                        enableRowHashing: false,
                        columnDefs: getColumnDefs(),
                        appScopeProvider: {
                            onDblClick: function (row) {
                                if (!$scope.iosOrMobile) {
                                    $scope.openItem(row.entity);
                                }
                            },
                            onEditTouchend: function (row) {
                                $scope.openItem(row.entity);
                            },
                            selectRow: function (row) {
                                $scope.openItem(row.entity);
                            }
                        },

                        rowTemplate:
                            '<div ' +
                            'ng-dblclick="grid.appScope.onDblClick(row)" ' +
                            'ng-click="grid.appScope.selectRow(row, $event, 1)"> ' +
                            '<div ' +
                            'class="ui-grid-cell" ' +
                            'ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" ' +
                            'ui-grid-cell ' +
                            'ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader }" ' +
                            'style="cursor:pointer"> ' +
                            '</div>' +
                            '</div>',

                        onRegisterApi: function (gridApi) {
                            $scope.gridApi = gridApi;
                            $scope.restoreState();
                            $scope.gridApi.core.on.rowsRendered($scope, rowsRendered);
                        }
                    };

                    deferredGetCurrentUserSecurityInfo = $q.defer();
                    security.getCurrentUserSecurityInfo()
                        .then(function (result) {
                            $scope.currentUserSecurityInfo = result;

                            deferredGetCurrentUserSecurityInfo.resolve();
                        },
                            function () {
                                $scope.currentUserSecurityInfo = {};
                                deferredGetCurrentUserSecurityInfo.reject('HTTP call failed');
                            });

                    $scope.saveFilters = function () {
                        $window.sessionStorage.setItem("reliefSearch.givenName", $scope.search.givenName);
                        $window.sessionStorage.setItem("reliefSearch.surname", $scope.search.surname);
                        $window.sessionStorage.setItem("reliefSearch.dob", $scope.search.dob);
                        $window.sessionStorage.setItem("reliefSearch.applicationNumber", $scope.search.applicationNumber);
                        $window.sessionStorage.setItem("reliefSearch.phoneOrMobile", $scope.search.phoneOrMobile);
                        $window.sessionStorage.setItem("reliefSearch.emailAddress", $scope.search.emailAddress);

                        $window.sessionStorage.setItem("reliefSearch.address.unitNo", $scope.search.address.unitNo);
                        $window.sessionStorage.setItem("reliefSearch.address.streetNo", $scope.search.address.streetNo);
                        $window.sessionStorage.setItem("reliefSearch.address.streetName", $scope.search.address.streetName);
                        $window.sessionStorage.setItem("reliefSearch.address.suburb", $scope.search.address.suburb);
                        $window.sessionStorage.setItem("reliefSearch.address.postcode", $scope.search.address.postcode);
                    };

                    $scope.restoreFilters = function (noRefresh) {
                        var searchObject = {};
                        searchObject.address = {};

                        var givenName = $window.sessionStorage.getItem("reliefSearch.givenName");
                        if (givenName != null && givenName != 'undefined') {
                            searchObject.givenName = givenName;
                        }
                        var surname = $window.sessionStorage.getItem("reliefSearch.surname");
                        if (surname != null && surname != 'undefined') {
                            searchObject.surname = surname;
                        }
                        var dob = $window.sessionStorage.getItem("reliefSearch.dob");
                        if (dob != null && dob != 'undefined' && dob != 'null') {
                            searchObject.dob = new Date(dob);
                        }
                     
                        var phoneOrMobile = $window.sessionStorage.getItem("reliefSearch.phoneOrMobile");                        
                        if (phoneOrMobile != null && phoneOrMobile != 'undefined') {
                            searchObject.phoneOrMobile = phoneOrMobile;
                        }

                        var emailAddress = $window.sessionStorage.getItem("reliefSearch.emailAddress");
                        if (emailAddress != null && emailAddress != 'undefined') {
                            searchObject.emailAddress = emailAddress;
                        }

                        var applicationNumber = $window.sessionStorage.getItem("reliefSearch.applicationNumber");
                        if (applicationNumber != null && applicationNumber != 'undefined') {
                            searchObject.applicationNumber = applicationNumber;
                        }

                        var unitNo = $window.sessionStorage.getItem("reliefSearch.address.unitNo");
                        if (unitNo != null && unitNo != 'undefined') {
                            searchObject.address.unitNo = unitNo;
                        }
                        var streetNo = $window.sessionStorage.getItem("reliefSearch.address.streetNo");
                        if (streetNo != null && streetNo != 'undefined') {
                            searchObject.address.streetNo = streetNo;
                        }
                        var streetName = $window.sessionStorage.getItem("reliefSearch.address.streetName");
                        if (streetName != null && streetName != 'undefined') {
                            searchObject.address.streetName = streetName;
                        }
                        var suburb = $window.sessionStorage.getItem("reliefSearch.address.suburb");
                        if (suburb != null && suburb != 'undefined') {
                            searchObject.address.suburb = suburb;
                        }
                        var postcode = $window.sessionStorage.getItem("reliefSearch.address.postcode");
                        if (postcode != null && postcode != 'undefined') {
                            searchObject.address.postcode = postcode;
                        }

                        return searchObject;
                    };

                    function isValidDate(d) {
                        if (Object.prototype.toString.call(d) === "[object Date]") {
                            // it is a date
                            if (isNaN(d.getTime())) {  // d.valueOf() could also work
                                return false
                            }
                            else {
                                return true
                            }
                        }
                        else {
                            return false
                        }
                    }

                    $scope.clearFilters = function () {
                        $scope.search.applicationNumber = "";
                        $scope.search.givenName = "";
                        $scope.search.surname = "";
                        $scope.search.dob = null;
                        $scope.search.phoneOrMobile = "";
                        $scope.search.emailAddress = "";
                        $scope.item.googleAddress = "";
                        $scope.item.googleSearchAddress = "";
                        $scope.search.address.unitNo = "";
                        $scope.search.address.streetNo = "";
                        $scope.search.address.streetName = "";
                        $scope.search.address.suburb = "";
                        $scope.search.address.postcode = "";
                        $scope.search.draftApplications = false;

                        $scope.gridOptions.data = [];

                        // $scope.$apply();

                        $scope.search = {};
                        $scope.search.region = [];
                        $scope.search.lga = [];
                        $scope.search.state = [];
                        $scope.item = {};
                        $scope.search.address = {};

                        // Save filter state
                        $scope.saveFilters();
                        $scope.allowExport = false;
                        //Save grid state
                        $scope.saveState($scope.gridOptions.data);
                    };

                    $scope.searchReliefApplicationsExport = function () {
                        if ($scope.gridOptions.data.length === 0) {
                            return;
                        }
                        $scope.exportToFile = true;
                        var savedData = $scope.restoreFilters(true);

                        if (savedData) {
                            var searchObject = angular.copy(savedData);
                            $scope.searchReliefApplications(searchObject);
                        }

                    };

                    $scope.searchApplications = function () {
                        $scope.searchReliefApplications($scope.search);
                    }

                    $scope.searchReliefApplications = function (searchObject) {
                        if (!$scope.exportToFile)
                            $scope.gridOptions.data = [];

                        if ($scope.frm && !$scope.frm.$valid) {
                            $scope.showInvalidSearchMessage = true;

                            return;
                        }

                        $scope.showInvalidSearchMessage = false;

                        var dob = null;
                        if (isValidDate(searchObject.dob)) {
                            dob = moment(searchObject.dob).format('YYYY/MM/DD');
                        }

                        // Save filter state
                        $scope.saveFilters();

                        //reliefService.loadRefData();

                        var regionIds = [];
                        _.map($scope.search.region, function (item) {
                            return regionIds.push(item.id);
                        });

                        var lgaCodes = [];
                        _.map($scope.search.lga, function (item) {
                            return lgaCodes.push(item.id);
                        });

                        var states = [];
                        _.map($scope.search.state, function (item) {
                            return states.push(item.id);
                        });

                        if (!$scope.exportToFile)
                            $scope.submitting = true;
                        $http
                            .post(
                                config.apiBaseUrl + ($scope.exportToFile ? "relief/searchcsv" : "relief/search"),
                                {
                                    memberGivenName: searchObject.givenName,
                                    memberSurname: searchObject.surname,
                                    memberDOB: dob,
                                    memberPhoneOrMobile: searchObject.phoneOrMobile,
                                    memberEmailAddress: searchObject.emailAddress,
                                    applicationNumber: searchObject.applicationNumber,
                                    address: {
                                        unitNo: searchObject.address.unitNo,
                                        streetName: searchObject.address.streetName,
                                        streetNo: searchObject.address.streetNo,
                                        suburb: searchObject.address.suburb,
                                        postcode: searchObject.address.postcode,
                                    },
                                    draftApplications: searchObject.draftApplications,
                                    lgas: lgaCodes,
                                    states: states,
                                    regions: regionIds
                                }
                            )
                            .then(
                                // Success
                                function (result) {
                                    if ($scope.exportToFile) {
                                        var file = new Blob([result.data], { type: 'text/csv' });
                                        saveAs(file, 'ReliefApplicationsList.csv');
                                        $scope.exportToFile = false;
                                        return;
                                    }
                                    $scope.submitting = false;
                                    $scope.validationError = false;
                                    if (result.data.success == false) {
                                        $scope.validationError = true;
                                        $scope.validationErrorMsg = result.data.message;
                                        return;
                                    }
                                    else
                                        $scope.validationError = false;

                                    $scope.gridOptions.data = result.data.applications;
                                    $scope.noResultsFound = false;
                                    $scope.allowExport = true;
                                    if ($scope.gridOptions.data.length == 0) {
                                        $scope.noResultsFound = true;
                                    }
                                    // Save grid state
                                    $scope.saveState($scope.gridOptions.data);
                                },
                                // Error
                                function (result) {
                                    $scope.submitting = false;
                                }
                            );
                    };

                    $scope.totalRecords = 50;
                    $scope.filteredRecords = 7;

                    //Initialize scope variables
                    $scope.noResultsFound = false;
                    $scope.submitting = false;
                    $scope.exportToFile = false;

                    //$scope.clearFilters();
                    $scope.search = {};
                    $scope.item = {};
                    $scope.search.address = {};

                    $scope.search.applicationNumber = "";
                    $scope.search.givenName = "";
                    $scope.search.surname = "";
                    $scope.search.dob = null;
                    $scope.search.phoneOrMobile = "";
                    $scope.search.emailAddress = "";
                    $scope.item.googleAddress = "";
                    $scope.item.googleSearchAddress = "";
                    $scope.search.address.unitNo = "";
                    $scope.search.address.streetNo = "";
                    $scope.search.address.streetName = "";
                    $scope.search.address.suburb = "";
                    $scope.search.address.postcode = "";
                    $scope.search = $scope.restoreFilters();
                    $scope.search.region = [];
                    $scope.search.lga = [];
                    $scope.search.state = [];

                    $scope.openItem = function (entity) {
                        $location.path("/home/relief/edit/" + entity.id);
                    };

                    $scope.newIncident = function () {
                        $location.path("/home/relief/create");
                    };
                }
            ]
        );
}());