(function () {
	angular.module('phapApp')
		.directive('reestablishWeeklyIncomeThreshold',
		[
			'$window', function ($window) {
				return {
					restrict: 'E',
					scope: true,
					templateUrl: 'app/home/reestablish/_weekly-income-threshold.html',
					controller: ['$scope', 'Constants', 'utils', 'reestablishService', weeklyIncomeCtrl]
				};
			}
		]);

	function weeklyIncomeCtrl($scope, Constants, utils, reestablishService) {
		$scope.Constants = Constants;
		$scope.weeklyIncomeUploadController = {};

		var weeklyIncomeDocumentsAttached = function () {
			if ($scope.item && $scope.item.documents) {
				var documents = $scope.item.documents[Constants.reestablishSections.weeklyIncome];
				if (documents === undefined || documents.length === 0) {
					return false;
				}
				return true;
			}
			return false;
		};

		// #region Business Rule checking

		$scope.$on('onMemberChangeNotification', function () {
			$scope.refreshWeeklyIncome();
		});

		$scope.$on('dataLoaded', function () {
			$scope.refreshWeeklyIncome();
			$scope.weeklyIncomeCheckBusinessRules();
		});

		$scope.$watchCollection('[item.weeklyIncomeTotalWeeklyIncome, item.weeklyIncomeLimit, item.weeklyIncomeIncomeEvidenceAttached]', function (newValues) {
			$scope.weeklyIncomeCheckBusinessRules();
		});

		// Check the business rules for this section, if they have been overridden then set the 
		// flat to indicate this on the screen (show an icon) and to be picked up in the XXX sectionn
		$scope.weeklyIncomeCheckBusinessRules = function () {

			var docsAttached = weeklyIncomeDocumentsAttached();

			$scope.formSections.eligibilityCriteria.subMenu.weeklyIncome.rulesOverridden = $scope.item.weeklyIncomeTotalWeeklyIncome > $scope.item.weeklyIncomeLimit || $scope.item.weeklyIncomeIncomeEvidenceAttached === Constants.YesNoNotyet.No;
			$scope.formSections.eligibilityCriteria.subMenu.weeklyIncome.evidenceRequired = $scope.item.weeklyIncomeIncomeEvidenceAttached === Constants.YesNoNotyet.NotYet;
			$scope.item.incomeExceedsThreshold = $scope.item.weeklyIncomeTotalWeeklyIncome > $scope.item.weeklyIncomeLimit;
			reestablishService.setExceptionalCircumstances($scope.formSections, $scope.item);
		}

		// #endregion

		var confirm = function () {
			var title = 'Evidence documents required!';
			var content = 'Evidence attached has been selected as Yes and no attachments have been loaded. \nDocuments must be loaded in order to set the Evidence attached field to Yes.';
			utils.confirm(title, content, 'Proceed', 'Cancel')
				.then(function () {
					$scope.item.weeklyIncomeIncomeEvidenceAttached = Constants.YesNoNotyet.NotYet;
				}, function () {
					$scope.item.weeklyIncomeIncomeEvidenceAttached = null;
				});
		};

		$scope.weeklyIncomeCheckEvidence = function () {
			if (!weeklyIncomeDocumentsAttached()) {
				$scope.weeklyIncomeUploadController.$newUpload();
			}
		};
		$scope.weeklyIncomeOnCancelUpdate = function () {
			if (!weeklyIncomeDocumentsAttached()) {
				confirm();
			}
		};
		$scope.weeklyIncomeOnFileListChanged = function () {
			if ($scope.item && $scope.item.weeklyIncomeIncomeEvidenceAttached === Constants.YesNoNotyet.Yes) {
				if (!weeklyIncomeDocumentsAttached()) {
					confirm();
				}
			}
			$scope.weeklyIncomeCheckBusinessRules();
		};

		$scope.weeklyIncomeShowNoSelectedWarning = function () {
			var title = 'Weekly income';
			var content = 'Re-establishment assistance is not available if evidence of Income is not provided';
			utils.confirm(title, content, 'Proceed', 'Ineligible')
				.then(function () {
					// NOTE: We leave the button as NO, as this is an override / proceed scenario
				}, function () {
					$scope.saveAsIneligible();
				});
		};

		$scope.onEditIncomeAmounts = function () {
			var scope = $scope.$new(false);
			$scope.refreshWeeklyIncome();
			$scope.openModal("app/home/reestablish/edit-income-amounts.html", "EditIncomeAmountsModalCtrl2", scope);
		}

		$scope.openModal = function (templateUrl, controller, scope, resolve, windowClass) {
			utils.openModal(templateUrl, controller, scope, resolve, windowClass);
		};

		$scope.memberHasNameAndDOB = function (member) {
			if (!angular.isDefined(member))
				return false;
			if (!angular.isDefined(member.givenName) || member.givenName === '')
				return false;
			if (!angular.isDefined(member.surname) || member.surname === '')
				return false;

			if (!angular.isDefined(member.dob) || member.dob === '')
				return false;
			return true;
		};

		$scope.adultOrChild = function (m) {
			if ($scope.isChild(m) === true) return 'No';
			if ($scope.isChild(m) === false) return 'Yes';
			return '';
		};


		// #region calculation and thresholds
		$scope.weeklyIncomeThresholds = [];
		$scope.refreshWeeklyIncome = function () {
			$scope.validMemberExists = false;
			if ($scope.memberHasNameAndDOB($scope.item.applicant)) $scope.validMemberExists = true;
			if ($scope.item.householdMembers && _.find($scope.item.householdMembers, function (m) { return $scope.memberHasNameAndDOB(m); })) $scope.validMemberExists = true;
			$scope.getWeeklyThresholds().then(function () {
				if ($scope.frm.$dirty) {
					$scope.calc($scope.item);
				}
			});
		};

		$scope.getWeeklyThresholds = function () {
			$scope.fetchingData = true;
			return reestablishService.getWeeklyThresholds($scope.item.incident.incidentDate, $scope.item.adults, $scope.item.children)
				.then(function (results) {

					if (results.ok) {

						// NOTE: Only 3 possible data sets are valid, any other number of results
						// essentially means that the configuration is invalid!
						if (results.data.length === 3) {
							angular.copy(_.sortBy(results.data, ['weeklyIncomeLimit']), $scope.weeklyIncomeThresholds);
						}

					}

					$scope.fetchingData = false;
				});
		};

		$scope.calc = function (weeklyApplication) {
			weeklyApplication.weeklyIncomeTotalWeeklyIncome = $scope.memberHasNameAndDOB(weeklyApplication.applicant) ? utils.parseDollarAmount(weeklyApplication.applicant.weeklyIncome) : 0;
			if (weeklyApplication.householdMembers) {
				weeklyApplication.weeklyIncomeTotalWeeklyIncome += _.reduce(weeklyApplication.householdMembers, function (sum, m) { return $scope.memberHasNameAndDOB(m) ? sum + utils.parseDollarAmount(m.weeklyIncome) : sum; }, 0);
			}
			if ($scope.weeklyIncomeThresholds) {
				var index = _.findIndex($scope.weeklyIncomeThresholds, function (thres) { return weeklyApplication.weeklyIncomeTotalWeeklyIncome <= thres.weeklyIncomeLimit; });
				weeklyApplication.weeklyIncomeLimit = _.nth($scope.weeklyIncomeThresholds, index).weeklyIncomeLimit;
				weeklyApplication.weeklyIncomeMaximumPayment = index < 0 ? 0 : $scope.weeklyIncomeThresholds[index].maximumPayment;
			}
		};
		
		//#endregion
	};
})();