(function () {

    var app = angular.module('phapApp');

    app.component('effectivePeriodConfigGrid', {
        templateUrl: 'app/administration/configuration/effectivePeriodConfigGrid.html',
        bindings: {
            versionList: '=',
            selectedVersionId: '=',
            gridOptions: '=',
            originalDataSet: '=',
            customSaveConfirmation: '=',
            popupInfo: '=',
            saveCallback: '=',
            validator: '=', //this method should return {isValid:true/false,errors:['error message']}
            saveValidator: '=',
            selectChangeCallback: '=',
            deleteConfirmationMessage: '=',
            deleteCallback: '=',
            versionDropdwonLabel: '@',
            caluculateMaxAdults:'<',
            configContainerClass: '=',
            history: '='
        },
        controller: ['$rootScope', '$scope', 'utils', 'Constants', function ($rootScope, $scope, utils, constants) {

            var vm = this;
            vm.saveModel = null;
            vm.gridApi = null;
            vm.isNewVersion = false;
            vm.isReadOnly = true;
            vm.dateFormat = constants.DatePickerFormat;
            var today = new Date();
            vm.setSelectedVersion = function () {
                if (vm.versionList && vm.versionList.versions && vm.versionList.versions.length > 0) {
                    angular.forEach(vm.versionList.versions,
                        function (item) {
                            if (item.id === vm.selectedVersionId) {
                                vm.selectedVersion = item;
                                if (vm.selectedVersion.isEditable) {
                                    vm.isNewOrEdit = true;
                                    vm.isEditVersion = true;
                                    $scope.$broadcast('createEditNewVersion', false);
                                }
                                vm.saveModel = {
                                    id: item.id,
                                    effectiveDate: new Date(item.effectiveFromDate),
                                    Data: item.data,
                                    isAdded: false,
                                    isDeleted: false
                                };

                            }
                        });

                    vm.isReadOnly = vm.selectedVersion.isReadOnly;
                }
            }
            vm.dateOptions = {
                formatYear: 'yyyy',
                formatMonth: 'MMM',
                maxDate: new Date(today.getFullYear() + 50, today.getMonth(), today.getDate()),
                minDate: new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1),
                startingDay: 1
            };

            vm.clear = function () {
                if (vm.versionList.isAllowCreateNew) {
                    vm.isNewVersion = false;
                    vm.isNewOrEdit = false;
                }
                else {
                    vm.setSelectedVersion();
                    if ($scope.admin) {
                        $scope.admin.$setPristine();
                    }
                }
            }

            vm.disabledCreate = function () {
                return vm.versionList.isAllowCreateNew === false || vm.isNewVersion;
            }

            vm.disabledDelete = function () {
                return vm.isReadOnly || vm.versionList.isAllowCreateNew;
            }

            vm.showDateSelector = function () {
                return ((vm.selectedVersion && vm.selectedVersion.isEditable) || vm.isNewVersion);
            }


            vm.versionChange = function () {

                vm.setSelectedVersion();
                vm.selectChangeCallback(vm.selectedVersionId);
                $scope.$broadcast('versionChanged');
            }

            vm.$onInit = function () {

                vm.createNew = function () {
                    if (vm.versionList.isAllowCreateNew) {
                        //make payment item editable
                        var items = angular.copy(vm.originalDataSet);
                        angular.forEach(items, function (item) {
                            item.data.isReadOnly = false;
                            item.data.isAdded = true;
                            item.isReadOnly = false;
                            item.isAdded = true;
                        });

                        vm.saveModel = {
                            effectiveDate: '',
                            Data: items,
                            isAdded: true,
                            isDeleted: false
                        };

                        vm.gridOptions.data = vm.saveModel.Data;
                        vm.isNewVersion = true;
                        vm.isNewOrEdit = true;
                        vm.isReadOnly = false;
                        vm.isEditVersion = false;
                        vm.gridApi.core.refresh();
                        $scope.$broadcast('createEditNewVersion', true);
                    }
                }

                vm.validation = function (row, colDef, currentData) {
                    return vm.validator(row, colDef, currentData);
                }
                vm.saveValidation = function (list) {
                    vm.saveModel.Data = list;
                    return vm.saveValidator(vm.saveModel);
                }

                vm.save = function (list, callback, isSucess) {
                    if (isSucess) {
                        vm.saveModel.effectiveDate = utils.getDateStringFromLocalDate(vm.saveModel.effectiveDate);
                        vm.saveModel.Data = list;
                        vm.isNewVersion = false;
                        vm.saveCallback(vm.saveModel, callback);
                        if ($scope.admin) {
                            $scope.admin.$setPristine();
                        }
                    }
                    else {
                        vm.ErrorCallback = callback;
                    }

                }

                vm.dateSelect = function () {
                    $scope.$broadcast('effectiveDateChange', vm.saveModel.effectiveDate);
                }

                vm.DeleteVersion = function () {
                    var title = 'Warning';
                    var content = vm.deleteConfirmationMessage ? vm.deleteConfirmationMessage : "Are you sure you want to delete selected version?";
                    utils.confirm(title, content, 'Yes', 'Cancel')
                        .then(function () {
                            vm.isNewVersion = false;
                            vm.deleteCallback(vm.selectedVersionId);
                        },
                        function () {
                            return;
                        });
                }
            }

            vm.$onChanges = function () {
                vm.setSelectedVersion();
            }

            $scope.$on('selectedVersionDataChange', function (event, value) {
                vm.setSelectedVersion();
            });

        }],
        controllerAs: 'effectivePeriodConfigGridVm'
    });

})();