(function () {
    angular
        .module('phapApp')
        .controller('AddressEditModalCtrl', ['$scope', '$location', '$timeout', 'utils', 'lgaService', function ($scope, $location, $timeout, utils, lgaService) {
            // Initialise the edit address if it doesnt already exist.
            if ($scope.editAddress === undefined) {
                $scope.editAddress = {
                    unitNo: '',
                    streetNo: '',
                    streetName: '',
                    suburb: '',
                    postcode: '', 
                    state: ''
                }
            }

            // Initialise the LGA object if it doesnt already exist.
            if ($scope.editLga === undefined) {
                $scope.editLga = {
                    lgaCode: 0,
                    name: ''
                }
            }
            
            $scope.showEditTitle = $scope.editTitle === undefined ? false : true;
            
            $scope.$watch('googleAddress', function (newValue, oldValue) {
                if (newValue && newValue.address_components) {
                    mapGoogleMapsAddress(newValue, $scope.editAddress, $scope.addressDetails);

                    if ($scope.showLga) {
                        var lga = getFieldFromGoogleAddressComponents($scope.addressComponents, "administrative_area_level_2");

                        if (lga !== undefined) {
							lgaService.matchLga(lga).then(function (result) {
                                $scope.editLga = result.data;
                            },
                                function (error) {
                                   
                                    $scope.editLga = [
                                        {
                                            lgaCode: null,
                                            name: null
                                        }
                                    ];
                                });
                        }
                    }
                }
            });

            var mapGoogleMapsAddress = function (gMapsAddress, toAddress, addressString) {
                if (gMapsAddress.address_components) {
                    var ac = {};
                    _.each(gMapsAddress.address_components, function (c) {
                        _.each(c.types, function (t) {
                            ac[t] = c;
                        });
                    });

                    $scope.addressComponents = ac;

                    toAddress.unitNo = getFieldFromGoogleAddressComponents(ac, "subpremise", true);
                    toAddress.streetNo = getFieldFromGoogleAddressComponents(ac, "street_number", true);
                    toAddress.streetName = getFieldFromGoogleAddressComponents(ac, "route", true);
                    toAddress.suburb = getFieldFromGoogleAddressComponents(ac, "locality", true);
                    // restrict suburb to 18 characters to comply with Indue API
                    if (toAddress.suburb && toAddress.suburb.length > 18) {
                        toAddress.suburb = toAddress.suburb.substring(0, 18);
                    }
                    toAddress.postcode = getFieldFromGoogleAddressComponents(ac, "postal_code", true);
                    toAddress.state = getFieldFromGoogleAddressComponents(ac, "administrative_area_level_1", false);

                    // Apply patches to the address, as there are some tweaks we apply to normalise the google stuff
                    utils.applyAddressPatches(toAddress, addressString);
                }
            };

            function getFieldFromGoogleAddressComponents(addressComponents, fieldName, isLongName) {
                var entry = addressComponents[fieldName];
                if (!entry) return null;
                if (isLongName)
                    return entry.long_name;
                else
                    return entry.short_name;
            };

            var hideElements = function () {
                $timeout(function () {
                    angular.element('.modal-backdrop').remove();
                    angular.element('.pac-container').hide();
                    angular.element('body').removeClass('modal-open');
                }, 200);
            };
            this.saveAddr = function () {
                var parentScope = $scope.origin;
                parentScope.showAddress = $scope.editAddress;
                parentScope.state.editAddressOpen = false;

                $scope.$close(true);
                hideElements();
            };

            this.cancelEdit = function () {
                var parentScope = $scope.origin;
                parentScope.state.editAddressOpen = false;
                $scope.$close(true);
                hideElements();
            };

            $timeout(function () {
                angular.element('.modal .address-view').find('[name="' + $scope.focus + '"]').focus();
            }, 300);
        }]);
})();
