(function () {
    'use strict';
    angular
        .module('phapApp')
        .factory('adjustmentService', adjustmentService);
    adjustmentService.$inject = ['$http', '$q', 'config'];

    function adjustmentService($http, $q, config) {
        var getAdjustments = function (id) {
            return $http.post(config.apiBaseUrl + "payment/GetAdjustmentPayment", id);
        };

        var saveAdjustment = function (data) {
            var deferred = $q.defer();
            var res = $http.post(config.apiBaseUrl + "payment/ApplyAdjustmentAmount", data)
                .then(function (data, status, headers, config) {
                    deferred.resolve(data);
                }, function (data, status, headers, config) {
                    deferred.reject(data);
                });
            return deferred.promise;
        };
        var service = {
            getAdjustments: getAdjustments,
            saveAdjustment: saveAdjustment
        };

        return service;
    }
})();