(function () {
    var app = angular.module('phapApp');

    app.service('errorHandlingService', ['$http', '$q', 'config', 'security', 'Constants', function ($http, $q, config, security, constants) {

        var getPage = function (pageIndex, pageSize, type, category, activeOnly) {
            if (type == null) type = '-1';
            if (category == null) category = '-1';
            var url = [config.apiBaseUrl + "errorlog/get-page", pageIndex, pageSize, type, category, activeOnly || false].join('/');
            return $http.get(url);
        };

        var resetAPInvoice = function (data) {
            var deferred = $q.defer();
            var res = $http.post(config.apiBaseUrl + "payment/reset-ap-invoice/", data)
                .then(function (data, status, headers, config) {
                    deferred.resolve(data);
                }, function (data, status, headers, config) {
                    deferred.reject(data);
                });
            return deferred.promise;
        }

        var resetGLBatch = function (data) {
            var deferred = $q.defer();
            var res = $http.post(config.apiBaseUrl + "payment/reset-gl-batch/", data)
                .then(function (data, status, headers, config) {
                    deferred.resolve(data);
                }, function (data, status, headers, config) {
                    deferred.reject(data);
                });
            return deferred.promise;
        }

        var resetBatches = function (errorId) {
            return $http.post(config.apiBaseUrl + "errorlog/reset-batches/" + errorId);
        };

        var resetTransactions = function (errorId) {
            return $http.post(config.apiBaseUrl + "errorlog/reset-transactions/" + errorId);
        };

        var closeError = function (errorId) {
            return $http.post(config.apiBaseUrl + "errorlog/close-error/" + errorId);
        };

        //AP Error handling
        var getApErrorDetails = function (errorId) {
            return $http.get(config.apiBaseUrl + "payment/ap-error-details/" + errorId);
        };

        var getApErrorSearch = function (data) {
            var deferred = $q.defer();
            var res = $http.post(config.apiBaseUrl + "payment/ap-error-search/", data)
                .then(function (data, status, headers, config) {
                    deferred.resolve(data);
                }, function (data, status, headers, config) {
                    deferred.reject(data);
                });
            return deferred.promise;
        };
        var exportAPSearch = function (searchParms) {
            var deferred = $q.defer();
            var res = $http.post(config.apiBaseUrl + 'payment/ap-error-search-export', searchParms)
                .then(function (data, status, headers, config) {
                    deferred.resolve(data);
                }, function (data, status, headers, config) {
                    deferred.reject(data);
                });
            return deferred.promise;
        };
        var exportAPTransactionSearch = function (searchParms) {
            var deferred = $q.defer();
            var res = $http.post(config.apiBaseUrl + 'payment/ap-error-transaction-search-export', searchParms)
                .then(function (data, status, headers, config) {
                    deferred.resolve(data);
                }, function (data, status, headers, config) {
                    deferred.reject(data);
                });
            return deferred.promise;
        };
        var getApTransactionSearch = function (data) {
            var deferred = $q.defer();
            var res = $http.post(config.apiBaseUrl + "payment/ap-error-transaction-search/", data)
                .then(function (data, status, headers, config) {
                    deferred.resolve(data);
                }, function (data, status, headers, config) {
                    deferred.reject(data);
                });
            return deferred.promise;
        };
        var exportAPTransactionSearchDetails = function (id) {
            var deferred = $q.defer();
            var res = $http.post(config.apiBaseUrl + 'payment/ap-error-transaction-search-export-grid/' + id)
                .then(function (data, status, headers, config) {
                    deferred.resolve(data);
                }, function (data, status, headers, config) {
                    deferred.reject(data);
                });
            return deferred.promise;
        };
        //GL Error handling
        var getGLErrorDetails = function (errorId) {
            return $http.get(config.apiBaseUrl + "payment/gl-error-details/" + errorId);
        };

        var getGLErrorSearch = function (data) {
            var deferred = $q.defer();
            var res = $http.post(config.apiBaseUrl + "payment/gl-error-search/", data)
                .then(function (data, status, headers, config) {
                    deferred.resolve(data);
                }, function (data, status, headers, config) {
                    deferred.reject(data);
                });
            return deferred.promise;
        };
        var exportGLSearch = function (searchParms) {
            var deferred = $q.defer();
            var res = $http.post(config.apiBaseUrl + 'payment/gl-error-search-export', searchParms)
                .then(function (data, status, headers, config) {
                    deferred.resolve(data);
                }, function (data, status, headers, config) {
                    deferred.reject(data);
                });
            return deferred.promise;
        };
        var exportGLTransactionSearch = function (searchParms) {
            var deferred = $q.defer();
            var res = $http.post(config.apiBaseUrl + 'payment/gl-error-transaction-search-export', searchParms)
                .then(function (data, status, headers, config) {
                    deferred.resolve(data);
                }, function (data, status, headers, config) {
                    deferred.reject(data);
                });
            return deferred.promise;
        };
        var getGLTransactionSearch = function (data) {
            var deferred = $q.defer();
            var res = $http.post(config.apiBaseUrl + "payment/gl-error-transaction-search/", data)
                .then(function (data, status, headers, config) {
                    deferred.resolve(data);
                }, function (data, status, headers, config) {
                    deferred.reject(data);
                });
            return deferred.promise;
        };
        var exportGLTransactionSearchDetails = function (id) {
            var deferred = $q.defer();
            var res = $http.post(config.apiBaseUrl + 'payment/gl-error-transaction-search-export-grid/' + id)
                .then(function (data, status, headers, config) {
                    deferred.resolve(data);
                }, function (data, status, headers, config) {
                    deferred.reject(data);
                });
            return deferred.promise;
        };



        return {
            getPage: getPage,
            resetAPInvoice: resetAPInvoice,
            resetGLBatch: resetGLBatch,
            resetBatches: resetBatches,
            resetTransactions: resetTransactions,
            closeError: closeError,
            getApErrorSearch: getApErrorSearch,
            exportAPSearch: exportAPSearch,
            getApErrorDetails: getApErrorDetails, 
            getApTransactionSearch: getApTransactionSearch,
            exportAPTransactionSearch: exportAPTransactionSearch,
            exportAPTransactionSearchDetails: exportAPTransactionSearchDetails,
            getGLErrorSearch: getGLErrorSearch,
            exportGLSearch: exportGLSearch,
            getGLErrorDetails: getGLErrorDetails,
            getGLTransactionSearch: getGLTransactionSearch,
            exportGLTransactionSearch: exportGLTransactionSearch,
            exportGLTransactionSearchDetails: exportGLTransactionSearchDetails
        }
    }]);

})();