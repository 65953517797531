(function () {
    angular
        .module('phapApp')
        .directive('resetBatch', [function () {
            return {
                scope: {
                    errorRow: "="
                },
                templateUrl: "app/administration/errorLog/resetBatch.html",
                controller: ['$scope', 'errorHandlingService', 'referenceService', 'utils', function (scope, errorHandlingService, referenceService, utils) {

                    scope.errorRefs = {};
                    var refCall = referenceService.getErrorRefs().then(function (response) {
                        scope.errorRefs = response.data;
                        isErrorIsOpen();
                    });

                    var isErrorIsOpen = function () {
                        scope.errorIsOpen = scope.errorRow.entity.status == scope.errorRefs.errorStatus.Logged;
                    };

                    scope.reset = function () {
                        scope.okMsg = null;
                        var id = scope.errorRow.entity.id;
                        var jo = JSON.parse(scope.errorRow.entity.referenceKey);

                        utils.confirm(
                            "Reset Transactions",
                            "If the appropriate modifications have NOT been made to the transactions to resolve the error the same error will be re-published. <br/>Select Proceed to publish a new Batch. <br/>Select Cancel to make further changes, as required",
                            "Proceed",
                            "Cancel"
                        ).then(function () {
                            (jo.batchIds ? errorHandlingService.resetBatches(id) : errorHandlingService.resetTransactions(id))
                                .then(function (response) {
                                    updateData(response.data);
                                    scope.okMsg = "Transactions reset successfully.";
                                });
                        });
                        
                    };

                    scope.override = function () { };

                    scope.close = function () {
                        scope.okMsg = null;
                        errorHandlingService.closeError(scope.errorRow.entity.id).then(function (response) {
                            updateData(response.data);
                            scope.okMsg = "Error closed successfully.";
                        });
                    };

                    function updateData(data) {
                        _.merge(scope.errorRow.entity, data);
                        isErrorIsOpen();
                    }

                }]
            };
        }]);
})();