(function () {

    angular.module('phapApp').controller('AffectedAddressCheckModalCtrl', ['$scope', '$location', 'applications', 'address', 'defer', 'utils', function ($scope, $location, applications, address, defer, utils) {
        this.applications = applications;
        this.address = address;
        this.proceedWhenAffectedAddressInUse = function () {
            $scope.state.proceedOnAddressInUse = true;
            $scope.state.affectedAddressModalOpened = false;         
            defer.resolve();
            $scope.$close(true);
        };
        this.saveAsIneligibleAffectedAddressInUse = function () {
            $scope.saveAsIneligible();
            $scope.state.proceedOnAddressInUse = false;          
            defer.resolve();
            $scope.$close(true);
        };

        this.closeAddressModal = function () {       
            $scope.state.proceedOnAddressInUse = false;
            $scope.state.addressInUse = false;
            $scope.state.affectedAddressModalOpened = false;
            defer.resolve();
            $scope.$close(true);
        };
    }]);

    angular.module('phapApp').controller('AffectedAddressLgaCheckModalCtrl', ['$scope', '$location', 'lgaInvalidModel', 'defer', 'Constants', function ($scope, $location, lgaInvalidModel, defer, Constants) {
        this.lgaInvalidModel = lgaInvalidModel;
        this.cancelWhenAffectedAddressLgaInvalid = function () {
            $scope.state.cancelWhenAffectedAddressLgaInvalid = true;
            $scope.state.affectedAddressLgaModalOpened = false;
            defer.resolve();
            $scope.$close(true);
        };
        this.saveAsIneligibleWhenAffectedAddressLgaInvalid = function () {
            $scope.item.howPeopleApplyingAffected = false;
            $scope.state.cancelWhenAffectedAddressLgaInvalid = false;
            $scope.state.affectedAddressLgaModalOpened = false;
            this.lgaInvalidModel.failedToSave = false;
            var localRefToLgaInvalidModel = this.lgaInvalidModel;

            $scope.saveAsIneligible()
                .then(
                function () {
                    defer.resolve();
                    $scope.$close(true);
                },
                function () {
                    localRefToLgaInvalidModel.failedToSave = true;
                    defer.reject();
                }
                );
        };
    }]);

    //Pop up controller for the cancel button in relief Payment section.
    angular.module('phapApp').controller('popupController', ['$scope', '$location', '$uibModalInstance', 'chequePaymentModel', 'Constants',
        function ($scope, $location, $uibModalInstance, chequePaymentModel, Constants) {
            var $ctrl = this;
            $ctrl.chequePaymentModel = chequePaymentModel;
            //When selected Yes
            $ctrl.confirmDeletePayments = function () {
                $uibModalInstance.close(true);
            }
            //When selected No
            $ctrl.cancelDeletePayments = function () {
                $uibModalInstance.dismiss('cancel');
            }
            //when selected proceed in save relief payments
            $ctrl.confirmSavePayments = function () {
                $uibModalInstance.close(true);
            }
        }
    ]);

    angular
        .module('phapApp')
        .controller('AffectedAddressEditModalCtrl', ['$scope', '$location', '$timeout', function ($scope, $location, $timeout) {

            // Flag that when in 'edit mode' we need to see the google adress lookup field
            $scope.showGoogleAddress = true;

            $timeout(function () {
                angular.element('.modal .affected-addr').find('[name="' + $scope.focus + '"]').focus();
            }, 300);
            var hideElements = function () {
                $timeout(function () {
                    angular.element('.modal-backdrop').remove();
                    angular.element('.pac-container').hide();
                    angular.element('body').removeClass('modal-open');
                }, 200);
            };
            this.saveAddr = function () {
                var parentScope = $scope.origin;
                if ($scope.item.affectedAddress != $scope.originAffectedAddress) {
                    $scope.frm.$setDirty();
                }
                parentScope.validateAffectedAddress($scope.item.affectedAddress)
                    .then(function (valid) {
                        if (valid) {
                            parentScope.validateLga($scope.item.affectedAddress.lga)
                                .then(function (isValid) {
                                    if (isValid !== true) {
                                        $timeout(function () {
                                            angular.element('.modal .affected-addr').find('[name="affectedAddress_lga"]').focus();
                                        }, 300);
                                    }
                                    else {
                                        $scope.state.affectedAddressEditModalOpened = false;
                                        $scope.$close(true);
                                        hideElements();
                                    }
                                });
                        }
                    });
            };
            this.cancelEdit = function () {
                $scope.state.affectedAddressEditModalOpened = false;              
                $scope.item.affectedAddress = $scope.originAffectedAddress;
                $scope.item.address = '';
                $scope.$close(true);
                hideElements();
            };
        }]);

}());

angular.module('phapApp').controller('SearchMemberResultsModalCtrl', ['$scope', '$location', 'defer', 'memberSearchResultsModel', 'Constants',
    function ($scope, $location, defer, memberSearchResultsModel, Constants) {
        this.memberSearchResultsModel = memberSearchResultsModel;

        if (memberSearchResultsModel.filter.isIncidentSearch) {
            this.title = "Affected person found in the current incident!";
        }
        else if (memberSearchResultsModel.filter.householdMembersInCurrentIncident || memberSearchResultsModel.filter.isMemberValidation) {
            this.title = "Household Members found in the current incident!";
        } else {

            this.title = "Member search results";
        }

        var $exactMatchFound = false;
        _.each(memberSearchResultsModel.members,
            function (m) {
                if (m.isExactMatch) {
                    $exactMatchFound = true;
                }
            });
        this.exactMatchFound = $exactMatchFound;

        this.saveAsIneligibleWhenMemberFoundInIncident = function () {
            $scope.state.cancelWhenMemberFoundInCurrentIncident = false;
            $scope.state.memberInCurrentIncidentModalOpened = false;
            $scope.item.previouslyReceivedPayment = true;
            $scope.state.cancelWhenAffectedAddressLgaInvalid = false;
            $scope.state.affectedAddressLgaModalOpened = false;
            this.memberSearchResultsModel.failedToSave = false;
            var memberSearchResultsModel = this.memberSearchResultsModel;

            $scope.saveAsIneligible()
                .then(
                function () {
                    defer.resolve();
                    $scope.$close(true);
                },
                function () {
                    memberSearchResultsModel.failedToSave = true;
                    defer.reject();
                }
                );
        };

        this.cancelWhenExactMatchFoundInIncident = function () {
            $scope.state.memberSearchResultsModalOpened = false;
            $scope.state.cancelWhenExactMatchFoundInIncident = true;
            defer.resolve(true);
            $scope.$close(true);
        };

        this.proceedWhenMemberFoundInIncident = function () {
            $scope.state.memberSearchResultsModalOpened = false;
            $scope.state.proceedWhenMemberFoundInIncident = true;
            defer.resolve(false);
            $scope.$close(true);
        };

        this.cancelWhenMemberNotFound = function () {
            $scope.state.cancelWhenMemberNotFound = true;
            $scope.state.memberSearchResultsModalOpened = false;
            defer.resolve(false);
            $scope.$close(true);
        };

        this.selectMember = function (member) {
            $scope.state.memberSearchResultsModalOpened = false;
            member.validated = true;
            $scope.$parent.memberSearchSelectedMember = member;
            defer.resolve(true);
            $scope.$close(true);
        };

        this.cancelWhenMembersInCurrentIncident = function () {
            $scope.state.memberSearchResultsModalOpened = false;
            $scope.state.cancelWhenMembersInCurrentIncident = true;
            defer.resolve(true);
            $scope.$close(true);
        };
    }
]);


angular.module('phapApp').controller('MemberInCurrentIncidentModalCtrl', ['$scope', '$location', 'memberModel', 'defer', 'Constants',
    function ($scope, $location, memberModel, defer, Constants) {
        this.memberModel = memberModel;

        var dateFormat = Constants.DatePickerFormat;

        this.dob = moment(memberModel.member.dob).format(dateFormat);

        this.showApplication = function () {
            if (this.memberModel.applicationType === Constants.applicationType.relief) {
                return "#/home/relief/edit/" + this.memberModel.applicationId;
            }
            else if (this.memberModel.applicationType === Constants.applicationType.reestablish) {
                return "#/home/reestablish/edit/" + this.memberModel.applicationId;
            }
        }

        this.cancelWhenMemberFoundInIncident = function () {
            $scope.state.cancelWhenMemberFoundInCurrentIncident = true;
            $scope.state.memberInCurrentIncidentModalOpened = false;
            defer.resolve();
            $scope.$close(true);
        };
        this.saveAsIneligibleWhenMemberFoundInIncident = function () {
            $scope.state.cancelWhenMemberFoundInCurrentIncident = false;
            $scope.state.memberInCurrentIncidentModalOpened = false;
            $scope.item.previouslyReceivedPayment = true;
            this.memberModel.failedToSave = false;
            var memberModel = this.memberModel;

            $scope.saveAsIneligible()
                .then(
                function () {
                    defer.resolve();
                    $scope.$close(true);
                },
                function () {
                    memberModel.failedToSave = true;
                    defer.reject();
                }
                );
        };
    }]);
