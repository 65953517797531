(function () {

    var app = angular.module('phapApp');
    app.component('pendingReviewTasks', {
        templateUrl: 'app/home/dashboard/pendingReviewTasks.html',
        bindings: {
            tasks: '='
        },
        controller: ['$rootScope', function ($rootScope) {
            var vm = this;

            


        }],
        controllerAs: 'vm'
    });

})();