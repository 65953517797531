(function () {

    var app = angular.module('phapApp');

    app.component('role', {
        templateUrl: 'app/administration/security/role.html',
        bindings: {
            mode: '@'
        },
        controller: ['$scope','$routeParams', '$filter', 'utils', 'securityManagementService', 'notificationService','navigationService',
            function ($scope, $routeParams, $filter, utils, securityManagementService, notificationService, navigationService) {

                var vm = this;
                vm.currentSection = $routeParams.name;

                navigationService.setCurrentMenuStatus(
                    navigationService.topMenuCodes.administration,
                    navigationService.pageCodes.userRoleManagement,
                    vm.currentSection);

                vm.searchPlaceholderText = 'Search Roles';
                vm.leftList = 'mainLeftList';
                vm.menuIds = {
                    permission: 'permission',
                    member: 'member'
                };
                vm.gridApi = null;
                vm.updatedList = [];
                vm.members = [];
                vm.selectedMenu = vm.menuIds.permission;
                vm.popupInfo = {
                    templateUrl: 'app/administration/security/createEditRole.html',
                    controller: 'createEditRoleCtrl',
                    windowClass: 'create-edit-modal-window'
                };
                vm.addPermissionPopupInfo = {
                    templateUrl: 'app/administration/security/addNewPermission.html',
                    controller: 'addNewPermissionCtrl',
                    windowClass: 'weekly-income-modal-window'
                };

                vm.addUsersPopupInfo = {
                    templateUrl: 'app/administration/security/addMember.html',
                    controller: 'addNewMembersCtrl',
                    windowClass: 'weekly-income-modal-window'
                };

                var getRole = function (id) {
                    securityManagementService.getRole(id).then(
                        function (role) {
                            vm.selectedRole = role.data;
                            vm.showPermissios = true;
                            vm.gridOptions.data = vm.selectedRole.rolePrivileges;
                            vm.gridOriginalData = angular.copy(vm.gridOptions.data);
                            vm.loaded = true;
                            getRoleMembers(id);
                        });
                }

                var getRoleMembers = function (id) {
                    securityManagementService.getRoleMembers(id).then(
                        function (members) {
                            vm.members = members.data;
                            vm.memberGridOptions.data = vm.members;
                            vm.memberGridOriginalData = angular.copy(vm.memberGridOptions.data);
                        });
                }
                var init = function () {
                    vm.loaded = false;
                    securityManagementService.getAllRoles().then(
                        function (roleList) {
                            vm.data = roleList.data;
                            vm.originalDataSet = angular.copy(vm.data);
                            getRole(vm.data[0].id);
                        });

                };

                var createRolePopupCallback = function (data) {
                    notificationService.notify('showLoadingPanel', true);
                    securityManagementService.createRole(data).then(
                        function (result) {
                            securityManagementService.getAllRoles().then(
                                function (roleList) {
                                    vm.data = roleList.data;
                                    vm.originalDataSet = vm.data;
                                    getRole(result.data.id);
                                    vm.showSavedMessage = true;
                                    notificationService.notify('showLoadingPanel', false);
                                });
                        });
                }

                var updateRolePopupCallback = function (data) {
                    notificationService.notify('showLoadingPanel', true);
                    securityManagementService.updateRole(data).then(
                        function (result) {
                            securityManagementService.getAllRoles().then(
                                function (roleList) {
                                    vm.data = roleList.data;
                                    vm.originalDataSet = vm.data;
                                    vm.showSavedMessage = true;
                                    getRole(result.data.id);
                                    notificationService.notify('showLoadingPanel', false);

                                });
                        });
                }

                var savePermissionCallback = function (data) {
                    vm.showSavedMessage = true;
                    getRole(vm.selectedRole.id);

                }

                var saveMembersCallback = function () {
                    vm.showSavedMessage = true;
                    getRoleMembers(vm.selectedRole.id);
                }

                var addPermissionPopup = function () {
                    var windowClass = vm.addPermissionPopupInfo.windowClass ? vm.addPermissionPopupInfo.windowClass : null;
                    var resolve = {
                        data: {
                            roleId: vm.selectedRole.id
                        }

                    }
                    utils.openModal(vm.addPermissionPopupInfo.templateUrl,
                        vm.addPermissionPopupInfo.controller,
                        $scope,
                        resolve,
                        windowClass,
                        savePermissionCallback,
                        vm.addPermissionPopupInfo.size);
                }

                var addMembersPopup = function () {
                    var windowClass = vm.addUsersPopupInfo.windowClass ? vm.addUsersPopupInfo.windowClass : null;
                    var resolve = {
                        data: {
                            roleId: vm.selectedRole.id
                        }

                    }
                    utils.openModal(vm.addUsersPopupInfo.templateUrl,
                        vm.addUsersPopupInfo.controller,
                        $scope,
                        resolve,
                        windowClass,
                        saveMembersCallback,
                        vm.addUsersPopupInfo.size);

                }

                vm.gridOptions = {
                    enableCellEdit: false,
                    enableSorting: true,
                    enableCellEditOnFocus: false
                };

                vm.gridOptions.columnDefs = [
                    { name: 'name', displayName: 'Permission', enableCellEdit: false, enableColumnMenu: false, enableSorting: false },
                    {
                        name: 'isDeleted', displayName: '', enableCellEdit: false, enableColumnMenu: false, width: 100, enableSorting: false,
                        cellClass: function (grid, row) {
                            return "grid-Delete-button";
                        },
                        cellTemplate: '<a ng-if="!row.entity.isDeleted" title="Delete" ng-click="grid.appScope.deleteRow(row.entity)"><span class="glyphicon glyphicon-trash grid-Delete-button" ></span></a>'

                    }
                ];

                vm.memberGridOptions = {
                    enableCellEdit: false,
                    enableSorting: true,
                    enableCellEditOnFocus: false
                };

                vm.memberGridOptions.columnDefs = [
                    { name: 'givenName', displayName: 'Given Name', enableCellEdit: false, enableColumnMenu: false, enableSorting: false },
                    { name: 'surname', displayName: 'Surname', enableCellEdit: false, enableColumnMenu: false, enableSorting: false },
                    { name: 'userName', displayName: 'Username', enableCellEdit: false, enableColumnMenu: false, enableSorting: false },

                ];

                vm.showSelectedRole = function (role) {
                    vm.showSavedMessage = false;
                    getRole(role.id);
                }

                vm.showPermissions = function () {
                    vm.selectedMenu = vm.menuIds.permission;
                    vm.gridOptions.data = vm.selectedRole.rolePrivileges;
                    vm.gridApi.core.refresh();
                    vm.showSavedMessage = false;
                }

                vm.showMembers = function () {
                    vm.selectedMenu = vm.menuIds.member;
                    vm.showSavedMessage = false;
                }

                vm.createNewRole = function () {
                    var windowClass = vm.popupInfo.windowClass ? vm.popupInfo.windowClass : null;
                    var resolve = {
                        isAdd: true,
                        data: {
                            existingRoles: vm.data,
                            role: {
                                id: 0,
                                name: ''
                            }
                        }

                    }
                    utils.openModal(vm.popupInfo.templateUrl,
                        vm.popupInfo.controller,
                        $scope,
                        resolve,
                        windowClass,
                        createRolePopupCallback,
                        vm.popupInfo.size);
                }

                vm.editRole = function () {
                    var windowClass = vm.popupInfo.windowClass ? vm.popupInfo.windowClass : null;
                    var resolve = {
                        isAdd: false,
                        data: {
                            existingRoles: vm.data,
                            role: {
                                id: vm.selectedRole.id,
                                name: vm.selectedRole.name
                            }
                        }

                    }
                    utils.openModal(vm.popupInfo.templateUrl,
                        vm.popupInfo.controller,
                        $scope,
                        resolve,
                        windowClass,
                        updateRolePopupCallback,
                        vm.popupInfo.size);
                }

                vm.deleteRole = function () {
                    var title = 'Warning';
                    var content = "Are you sure you want to deactivate this role?";
                    utils.confirm(title, content, 'Yes', 'No')
                        .then(function () {
                            notificationService.notify('showLoadingPanel', true);
                            securityManagementService.removeRole(vm.selectedRole.id).then(
                                function () {
                                    securityManagementService.getAllRoles().then(
                                        function (roleList) {
                                            vm.data = roleList.data;
                                            vm.originalDataSet = vm.data;
                                            getRole(vm.selectedRole.id);
                                            notificationService.notify('showLoadingPanel', false);
                                        });
                                });
                        },
                        function () {
                            return;
                        });
                }

                vm.reactivateRole = function () {
                    var title = 'Warning';
                    var content = "Are you sure you want to reactivate this role?";
                    utils.confirm(title, content, 'Yes', 'No')
                        .then(function () {
                            notificationService.notify('showLoadingPanel', true);
                            securityManagementService.reactivateRole(vm.selectedRole.id).then(
                                function () {
                                    securityManagementService.getAllRoles().then(
                                        function (roleList) {
                                            vm.data = roleList.data;
                                            vm.originalDataSet = vm.data;
                                            getRole(vm.selectedRole.id);
                                            notificationService.notify('showLoadingPanel', false);
                                        });
                                });
                        },
                        function () {
                            return;
                        });
                }
                vm.clear = function () {

                    var title = 'Warning';
                    var content = "Are you sure you want to discard unsaved data?";
                    utils.confirm(title, content, 'Yes', 'Cancel')
                        .then(function () {
                            vm.updatedList = [];
                            vm.showSavedMessage = false;
                            vm.gridOptions.data = angular.copy(vm.gridOriginalData);
                            vm.gridApi.core.refresh();
                            $scope.role.$setPristine();

                        },
                        function () {
                            return;
                        });

                }

                vm.save = function () {
                    var title = 'Warning';
                    var content = vm.customSaveConfirmation ? vm.customSaveConfirmation : "Saving will commit all changes made on this page";
                    utils.confirm(title, content, 'Proceed', 'Cancel')
                        .then(function () {
                            notificationService.notify('showLoadingPanel', true);
                            vm.isUpdated = false;
                            vm.showSavedMessage = false;
                            vm.submitting = true;
                            vm.pageErrors = [];
                            securityManagementService.removeRolePermission(vm.updatedList).then(
                                function () {
                                    vm.updatedList = [];
                                    getRole(vm.selectedRole.id);
                                    vm.submitting = false;
                                    vm.showSavedMessage = true;
                                    notificationService.notify('showLoadingPanel', false);
                                });
                        },
                        function () {
                            return;
                        });
                }

                vm.gridUpdated = function (list) {
                    $scope.role.$setDirty();
                }



                vm.addItems = function () {
                    if (vm.selectedMenu == vm.menuIds.permission)
                        addPermissionPopup();
                    if (vm.selectedMenu == vm.menuIds.member)
                        addMembersPopup();
                }

                vm.disabledSave = function () {
                    return vm.updatedList.length == 0
                }

                vm.disabledCancel = function () {
                    return vm.updatedList.length == 0
                }

                init();
            }],
        controllerAs: 'roleVm'
    });

})();