(function () {
    angular.module('phapApp')
        .service('utils', ['$http', '$q', '$cookies', 'config', '$uibModal', '$ngConfirm', '$timeout',
            function ($http, $q, $cookies, config, $uibModal, $ngConfirm, $timeout) {

                var getAge = function (birthDate, effectiveDate) {

                    var dob = moment(birthDate);
                    if (!dob.isValid())
                        return null;

                    var relativeToDate = moment(effectiveDate || moment());

                    var age = relativeToDate.diff(dob, 'years');
                    return age;
                };

                var parseDollarAmount = function (value) {
                    var val = parseFloat(value);
                    if (isNaN(val)) {
                        return 0;
                    }
                    return val;
                }
                var parseDollarToNumber = function (value) {
                    var val = parseFloat(value);
                    if (isNaN(val)) {
                        return value.replace(/[^0-9\.-]+/g, "");
                    }
                    return val;
                }

                var openModal = function (templateUrl, controller, scope, resolve, windowClass, callback, size, backdrop, keyboard) {
                    windowClass = windowClass !== null ? windowClass : 'finance-popup';
                    var container = angular.element('.page');
                    if (container.length == 0) container = angular.element('body');
                    var modalInstance = $uibModal.open({
                        appendTo: container,
                        ariaLabelledBy: 'modal-title',
                        ariaDescribedBy: 'modal-body',
                        templateUrl: templateUrl,
                        controller: controller,
                        controllerAs: '$ctrl',
                        size: size ? size : 'lg',
                        windowClass: windowClass,
                        backdrop: backdrop || 'static',
                        keyboard: keyboard || false,
                        scope: scope,
                        resolve: resolve
                    });

                    modalInstance.result.then(function (result) {
                        callback(result);
                    });

                    return modalInstance.closed.then(function () {
                        angular.element("body").css("overflow", "initial");
                    });

                };
                function clearErrors($scope) {
                    if ($scope.frm) {
                        // Clear form level $error
                        $scope.frm.$error = {};
                        $scope.errors = [];

                        // Clear all field-level $error data
                        _.each($scope.frm, function (value, key) {
                            if (key[0] !== '$') {
                                if (value.$error) {
                                    value.$error = {};
                                }
                            }
                        });
                    }
                }


                function updateErrors($scope, result) {
                    clearErrors($scope);

                    var form_errs, field_errs;
                    form_errs = field_errs = $scope.errors = [];

                    // Copy both form-level and field-level error info from response
                    if (result.data && result.data.errors && result.data.errors.length) {
                        $scope.errors = result.data.errors;
                        form_errs = _.filter($scope.errors, function (e) { return _.isEmpty(e.field); });
                        field_errs = _.difference($scope.errors, form_errs);
                    }

                    // set form-level errors
                    _.each(form_errs, function (e) {
                        $scope.frm.$error[e.message.toLowerCase().replace(/\./g, '_')] = true;
                    });

                    // set field-level errors
                    _.each(field_errs, function (e) {
                        var targetField = getFormFieldCaseInsensitive($scope, e.field);

                        if (targetField) {
                            var messageSegments = e.message.split('_');

                            targetField.$error[messageSegments[0].toLowerCase()] = true;
                            targetField.$$element.addClass('invalid');

                        var f = e.field.toLowerCase().replace(/\./g, '_').replace(/\[\d+]/g, '');
                        _.each($scope.frm, function (value, key) {
                            if (key.toLowerCase().indexOf(f + '_') >= 0) {
                                value.$$element.addClass('invalid');
                            }
                        });
                        if (messageSegments.length > 1) {
                            if (messageSegments[0] === "MemberInCurrentIncident") {
                                if (messageSegments.length === 4) {
                                    targetField.validationArguments = [];
                                    var url = "#/home/" + messageSegments[3].toLowerCase() + "/edit/" + messageSegments[1];
                                    targetField.validationArguments.push(url);
                                    targetField.validationArguments.push(messageSegments[2]);
                                }

                                } else {
                                    targetField.validationArguments = [parseInt(messageSegments[1])];
                                }
                            }
                        }
                    });
                };

                var getFormFieldCaseInsensitive = function ($scope, f) {

                    // If we're dealing with household members, adjust the returned field names so we'll match them with the client side names
                    if (f && f.indexOf("HouseholdMembers") >= 0) {
                        f = f.replace('HouseholdMembers', 'member')
                            .replace('[', '_')
                            .replace('].', '_');
                    }

                    // { "message":"Validation Failed", "errors":[{ "field": "Applicant_DOB", "message": "Required" }, { "field": "Applicant_Address", "message": "Required" }, { "field": "Applicant_Address", "message": "Required" }, { "field": "Applicant_Surname", "message": "Required" }, { "field": "Applicant_GivenName", "message": "Required" }] }

                    var targetField = $scope.frm[f];

                    f = f.toLowerCase().replace(/\./g, '_').replace(/\[\d+]/g, '');

                    _.each($scope.frm, function (value, key) {
                        if (key.toLowerCase() === f) {
                            targetField = value;

                            return false;
                        }
                    });

                    return targetField;
                };

                function padLeft(nr, n, str) {
                    return Array(n - String(nr).length + 1).join(str || '0') + nr;
                }

                function arrayToObj(arr, propName) {
                    if (arr && _.isArray(arr)) {
                        var obj = {};
                        _.each(arr, function (r) {
                            obj[r[propName]] = true;
                        });
                        return obj;
                    }
                    return arr;
                }

                function applyAddressPatches(toAddress, addressString) {
                    // Scenario 1.  No street number was found, so let's see if we can find one!
                    if (toAddress.streetNo === null) {
                        // Okay there was no street number provided, so let's have a quick look to see if we can
                        // patch this as google doesnt always find the information, BUT we'll only do a quick
                        // test so we dont go nuts looking for different combos.....
                        //
                        //      i.e.  Unit 1/23 Some street - Skip these (with alpha's at the start)
                        //      i.e.  Appt 1/23 Some street - Skip these (with alpha's at the start)
                        //  BUT - we'll fix the following ones which google doesnt get right
                        //      100 Grampians Road, Mafeking, Victoria, Australia
                        //      105-107 Grampians Road, Mafeking, Victoria, Australia

                        var firstCharIdx = addressString.search(/[a-zA-Z]/);
                        if (firstCharIdx > 0 && firstCharIdx < 10) {
                            // NOTE: We'll use the first 10 chars only incase there is some 
                            // strange number, if so the user can manually fix enter this item
                            var uptoChar = addressString.substring(0, firstCharIdx).trim();
                            var slashIdx = uptoChar.indexOf("/");
                            if (slashIdx === -1) {
                                slashIdx = uptoChar.indexOf("\\");
                            }
                            if (slashIdx === -1) {
                                toAddress.streetNo = uptoChar;
                            } else {

                                var unitNo = uptoChar.substring(0, slashIdx).trim();
                                var streetNo = uptoChar.substring(slashIdx + 1, slashIdx).trim();

                                toAddress.unitNo = unitNo;
                                toAddress.streetNo = streetNo;
                            }
                        }
                    }
                    else if (toAddress.streetNo !== null && toAddress.unitNo !== null) {

                        // Check for a building number being broken up into a unit/street number

                        // i.e. 3-4 Mernda Village Drive, Mernda, Victoria, Australia
                        //      ends up being (from google)
                        //         Unit 4 Street 3 
                        var comboLength = toAddress.unitNo.length + 1 + toAddress.streetNo.length;
                        var comboComp = addressString.substring(0, comboLength);

                        // Check if we've got a building number being split into unit/street number, if so
                        // we'll put it back
                        var idxDash = comboComp.indexOf("-");
                        if (idxDash !== -1) {

                            // We've found a 3-4 scenario, let's see if google has it backward, if so
                            // we swap things back around the way it appears in the address string
                            var reBuilt = toAddress.unitNo + '-' + toAddress.streetNo;
                            if (comboComp !== reBuilt) {
                                var unitHold = toAddress.streetNo + '-' + toAddress.unitNo;
                                toAddress.streetNo = unitHold;
                                toAddress.unitNo = null;
                            }
                        }
                    }

                };


                function confirm(title, content, okText, cancelText, scope) {
                    var deferred = $q.defer();
                    var options = {
                        title: title,
                        content: content,
                        columnClass: 'large',
                        buttons: {
                            cancel: {
                                text: (cancelText && cancelText.length) ? cancelText : "Cancel",
                                btnClass: 'btn-default popupButton',
                                action: function () {
                                    deferred.reject();
                                }
                            },
                            ok: {
                                text: (okText && okText.length) ? okText : "Ok",
                                btnClass: 'btn-primary',
                                action: function (scope, button) {
                                    deferred.resolve();
                                }
                            }
                        }
                    };
                    if (scope) {
                        options.scope = scope;
                    }
                    $ngConfirm(options);
                    return deferred.promise;
                }

                function alert(content, title, okText) {
                    var deferred = $q.defer();

                    var buttonText = angular.isDefined(okText) ? okText : "Ok";
                    $ngConfirm({
                        title: title,
                        content: content,
                        columnClass: 'large',
                        buttons: {
                            ok: {
                                text: buttonText,
                                btnClass: 'btn-primary popupButton',
                                action: function (scope, button) {
                                    deferred.resolve();
                                }
                            }
                        }
                    });
                    return deferred.promise;
                }

                function getStringFromCamelCaseString(str) {
                    if (str && str.length) {
                        str = str.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
                        return str[0].toUpperCase() + str.slice(1);
                    }
                    return str;
                }

                var promises = {};
                function httpGetOnce(url, refresh) {
                    if (refresh !== true && promises[url]) {
                        return promises[url];
                    }
                    return promises[url] = $http.get(url);
                }



                function expandSections(expanded, formSections) {
                    for (var key in formSections) {
                        if (formSections.hasOwnProperty(key)) {
                            if (key !== "privacy" || !expanded && key === "privacy") {
                                formSections[key].expanded = expanded;
                            }
                            if (formSections[key].subMenu) {
                                for (var key2 in formSections[key].subMenu) {
                                    if (formSections[key].subMenu.hasOwnProperty(key2)) {
                                        formSections[key].subMenu[key2].expanded = expanded;
                                    }
                                }
                            }
                        }
                    }
                    scrollToTop();
				}

				function scrollToBottom() {
					$("html, body").animate({ scrollTop: 20000 }, 100);		
				}
                function scrollToTop(id) {
                    if (id) {
                        $timeout(function () {

                            if (angular.element('#fixed-form-header')[0].offsetTop > 0) {
                                var headerBannerHeight = angular.element('#header-banner')[0].offsetHeight;
                                var pos = angular.element(id).offset().top - angular.element('#fixed-form-header')[0].offsetHeight - headerBannerHeight;
                                angular.element('body,html').scrollTop(pos);
                            }
                        });
                    }
                }

                function isNullOrEmpty(value) {
                    return (value === undefined || value === null ||
                        (typeof (value) === "string" && value.trim() === ''));
                }

                function getDateStringFromLocalDate(date) {
                    if (!date) return null;


                    return padLeft(date.getFullYear(), 4, "0") + "-" + padLeft((date.getMonth() + 1), 2, "0") + "-" + padLeft(date.getDate(), 2, "0");
                }

				return {
					scrollToBottom: scrollToBottom,
                    applyAddressPatches: applyAddressPatches,
                    getAge: getAge,
                    parseDollarAmount: parseDollarAmount,
                    openModal: openModal,
                    clearErrors: clearErrors,
                    updateErrors: updateErrors,
                    getFormFieldCaseInsensitive: getFormFieldCaseInsensitive,
                    padLeft: padLeft,
                    arrayToObj: arrayToObj,
                    confirm: confirm,
                    alert: alert,
                    getStringFromCamelCaseString: getStringFromCamelCaseString,
                    httpGetOnce: httpGetOnce,
                    expandSections: expandSections,
                    scrollToTop: scrollToTop,
                    isNullOrEmpty: isNullOrEmpty,
                    parseDollarToNumber: parseDollarToNumber,
                    getDateStringFromLocalDate: getDateStringFromLocalDate
                    
                }
            }]);
})();