(function () {

	var app = angular.module('phapApp');
	app.component('cardActivity', {
		templateUrl: 'app/financeManagement/cardActivity/cardActivity.html',
		bindings: {
			searchgridapi: '<',
			setType: '&',
            setRegionUserAccess: '&',
            userregionaccess: '=',
			scanCard: '='
		},
		controller: ['$scope', 'Constants', 'utils', 'uiGridConstants', 'cardService', 'security', '$q', '$routeParams', '$location', 'notificationService', '$timeout', '$interval',
			function ($scope, constants, utils, uiGridConstants, cardService, security, $q, $routeParams, $location, notificationService, $timeout, $interval) {
				var vm = this;

				vm.noResultsFound = false;
				vm.activityLoaded = false;
				vm.showApprovalButtons = false;
				vm.showRejectionReason = false;
				vm.allPromises = [];
				vm.IsSaveInProgress = false;
				vm.IsSubmitInProgress = false;
				vm.closedStatusId = constants.cardActivityRequestOrderStatusCodes.closed.id;
				vm.uploadController = {};
				vm.documentSection = "ActivityRequest";
				vm.getScannedCard = function () {
					if (vm.scanCard == false) {
						vm.scanCard = !vm.scanCard;
						if (vm.scannedCardId) {
							if (vm.scannedCardId.length > 0 && vm.scannedCardId.length != constants.cardLength) {
								utils.alert(constants.cardLengthErrorMessage);
								return;
							}
							else {
								vm.scanning = true;
								var searchCriteria = {};
								searchCriteria.cardId = vm.scannedCardId;
								searchCriteria.searchMode = "activity";
								searchCriteria.searchCardStatuses = [];
								searchCriteria.excludedCardIds = [];
								cardService.cardSearch(searchCriteria).then(
									function (response) {
										vm.scanning = false;
										if (response.data.cardResults.length === 0) {
											utils.alert('Card not found.');
										}
										else {
											var card = response.data.cardResults[0];
											if (isValidCardRegionLocation(card) && isValidCardLocation(card)) {
												var cardArray = [];
												cardArray.push(card);
												vm.addToList(cardArray);
												vm.scannedCardId = '';
												document.getElementById('scannedCardID').focus();
											}
										}
										vm.scanCard = false;
									},
									function (error) {
										vm.scanCard = false;
										document.getElementById('scannedCardID').focus();
									});
							}
						}
					}
				}

				var isValidCardLocation = function (card) {
					if (vm.gridOptions.data.length > 0) {
						firstCardAdded = vm.gridOptions.data[0];
						if (card.region !== firstCardAdded.region) {
							utils.alert('Cards cannot be selected from multiple regions.<br/>Please check the card location and remove cards from the allocation panel if necessary.');
							return false;
						}
					}
					return true;
				}
				var isValidCardRegionLocation = function (card) {
					if (_.find(cardService.regionsForuser, function (o) { return o.name == card.region; })) {

						return true;
					}
					utils.alert('Card belongs to a invalid region/location.');
					return false;
				}
				vm.printOrder = function (divName) {
					vm.printElement(document.getElementById("printableArea"));
					window.print();
				}
				vm.printElement = function (elem) {
					var domClone = elem.cloneNode(true);
					var $printSection = document.getElementById("printSection");
					if (!$printSection) {
						var $printSection = document.createElement("div");
						$printSection.id = "printSection";
						document.body.appendChild($printSection);
					}
					$printSection.innerHTML = "";
					$printSection.appendChild(domClone);
				}
				vm.$onInit = function () {
					vm.activityRequestTypes = constants.cardActivityRequestType;
					vm.activityRequestStatuses = constants.cardActivityRequestOrderStatus;
					vm.gridOptions = getGridOptions();

					initialiseRequestOrder();

					vm.setType({ value: vm.activityRequestType.description.toLowerCase(), mode: $routeParams.id ? "Edit" : "Create" });

					vm.allPromises.push(
						security.getCurrentUserSecurityInfo().then(
							function (result) {
								vm.currentUserSecurityInfo = result;
							},
							function () {
								vm.currentUserSecurityInfo = {};
							})
					);
					vm.allPromises.push(security.getActiveUser(false).then(
						function (data) {
							vm.currentUserLoggedIn = data;
							vm.userRegions = data.regions;
						}
					));
					$q.all(vm.allPromises).then(
						function (result) {
							if ($routeParams.id && vm.currentUserSecurityInfo.privileges.CreateActivityRequestOrder) {
								getSavedOrder($routeParams.id);
							}
							else {
								vm.activityLoaded = true;
							}
						});
				}

				vm.showDetails = function () {
					if (vm.currentUserSecurityInfo.privileges) {
						return vm.activityLoaded && !vm.noResultsFound && vm.currentUserSecurityInfo.privileges.CreateActivityRequestOrder;

					}
					return false;
				}

				function initialiseRequestOrder() {
					vm.currentGridData = [];
					vm.gridOptions.data = vm.currentGridData;
					vm.formSubmitted = false;
					vm.activityRequestType = _.find(vm.activityRequestTypes, function (item) {
						return item.id == $routeParams.requestType;
					});
					vm.activityRequestOrder = {
						id: 0,
						activityRequestOrderNumber: '',
						activityRequestTypeId: vm.activityRequestType ? vm.activityRequestType.id : 0,
						activityOrderStatusId: constants.cardActivityRequestOrderStatusCodes.draft.id,
						activityOrderStatusDisplay: constants.cardActivityRequestOrderStatusCodes.draft.description,
						amount: null,
						activityRequests: [],
						documents: {}
					}
				}

				var getColumnDefs = function () {
					return [
						{
							field: 'id',
							visible: false,
							enableHiding: false
						},
						{
							field: 'cardIdToDisplay',
							displayName: 'Card ID',
							enableHiding: false,
                            enableColumnMenu: false,
                            width: '20%'
						},
						{
							field: 'activityRequestNumber',
							displayName: 'Transaction number',
							enableHiding: false,
                            enableColumnMenu: false,
                            width: '20%'
						},
						{
							field: 'availableBalance',
							displayName: 'Balance',
							enableHiding: true,
							enableColumnMenu: false,
							cellFilter: 'phapcurrency',
                            cellClass: 'currency',
                            width: '20%'
						},
						{
							field: 'region',
							displayName: 'DHHS region',
							enableHiding: false,
                            enableColumnMenu: false,
                            width: '20%'
						},
						{
							field: 'activityRequestStatusDisplay',
							displayName: 'Transaction status',
							enableHiding: false,
                            enableColumnMenu: false,
                            width: '20%'
						},
						{
							field: 'oracleStatus',
							displayName: 'Oracle status',
							visible: false,
							enableHiding: false,
                            enableColumnMenu: false
						}
					];
				};

				var getGridOptions = function () {
					return {
						enableSorting: true,
						enableFiltering: false,
						enableColumnResizing: true,
						enableHorizontalScrollbar: uiGridConstants.scrollbars.ALWAYS,
						enableVerticalScrollbar: uiGridConstants.scrollbars.ALWAYS,
						enableRowSelection: true,
						enableFullRowSelection: true,
						enableRowHeaderSelection: true,
						enableSelectAll: true,
						multiSelect: true,
						enableRowHashing: false,
						columnDefs: getColumnDefs(),
						appScopeProvider: {
							openItem: function (row) {
								openItem(row);
							}
						},
						onRegisterApi: function (gridApi) {
							vm.gridApi = gridApi;
							// call resize every 500 ms for 5 s after modal finishes opening - usually only necessary on a bootstrap modal
							$interval(function () {
								vm.gridApi.core.handleWindowResize();
							}, 500, 10);
						}
					}
				};

				vm.hasSelectedSearchItems = function () {
					if (vm.searchgridapi && vm.searchgridapi.selection.getSelectedCount() > 0) {
						return true;
					}
				}

				vm.hasSelectedActivityItems = function () {
					if (vm.gridApi && vm.gridApi.selection && vm.gridApi.selection.getSelectedRows().length > 0) {
						return true;
					}
				}


				vm.isValidAmount = function () {
					if (!vm.activityRequestType || !vm.activityRequestOrder) return true;
					if (!vm.activityRequestType.amountRequired) return true;
					else {
						return (vm.activityRequestOrder.amount !== undefined && vm.activityRequestOrder.amount !== "" && vm.activityRequestOrder.amount !== null && vm.activityRequestOrder.amount * 1 > 0);
					}
				}

				vm.addToList = function (cardArray) {
					resetMessages();
					if (!vm.activityRequestType) {
						vm.formSubmitted = true;
						return;
					}
					var title = 'Warning';
					var content = "";
					var cardsToAdd = [];
					if (cardArray) {
						cardsToAdd = cardArray;
					}
					else {
						var cardsToAdd = getCardsToAdd();
					}
					if (validateNumberOfCardsAllocated(cardsToAdd) === true) {
						var alreadySelectedCardsRegion = vm.gridOptions.data.length > 0 ? vm.gridOptions.data[0].region : null;
						var cards = getValidAndInvalidCards(cardsToAdd, alreadySelectedCardsRegion, vm.gridOptions.data);
						if (!cards.regionMatch) {
							content = "Selected cards do not belong to the same region. For an activity request you can add only cards that belong to a single region.";
							utils.alert(content);
						}
						else if (cards.cardAlreadyAddedDifference > 0) {
							content = "Selected cards has already been added to activity request";
							utils.alert(content);
						}
						else {
							if (cards.inactiveCards.length > 0) {
								var cardList = "";
								angular.forEach(cards.inactiveCards, function (card) {
									if (cardList === "")
										cardList += card.cardId;
									else
										cardList += ", " + card.cardId;
								});
								content = "The following cards are not in a valid state to be applied to this activity request: <br /> " + cardList + ".";
							}

							if (cards.cardsToAdd.length > 0) {
								// add data here
								vm.currentGridData = vm.currentGridData.concat(cards.cardsToAdd);
								vm.activityRequestOrder.activityRequests = vm.activityRequestOrder.activityRequests.concat(cards.cardsToAdd);
								vm.gridOptions.data = vm.currentGridData;
								vm.showOrder = true;
								vm.listUpdated = true;
								$scope.cardActivityForm.requestList.$setDirty();
							}
							// if there are cards that couldn't be added show a message
							if (cards.cardsThatCannotBeAdded.length > 0) {
								if (content != "") {
									content += "<br />"
								}
								content += getReason(cards.cardsThatCannotBeAdded);
							}
							// if there is a message then display it.
							if (content != "") {
								utils.alert(content, title, 'OK');
							}
						}
					}
				}
				vm.clickCancel = function () {
					$location.path("/financeManagement/cards/cardActivitySearch");
				}

				vm.deleteRequests = function () {
					resetMessages();
					if (vm.hasSelectedActivityItems()) {
						var rowsToDelete = vm.gridApi.selection.getSelectedRows();

						if (_.find(rowsToDelete, function (item) { return (item.activityRequestStatus != constants.cardActivityRequestStatusCodes.draft.id && item.activityRequestStatus != constants.cardActivityRequestStatusCodes.failed.id); })) {
							content = 'You can only delete draft or failed requests.';
							utils.alert(content, 'Delete warning', 'OK');
							return;
						}

						var title = "Delete Confirmation";
						var content = "Are you sure you wish to delete?";

						utils.confirm(title, content, 'OK', "Cancel")
							.then(function () {
								angular.forEach(rowsToDelete, function (entity, index) {
									var indexToDelete = vm.currentGridData.indexOf(entity);
									// update the table
									if (entity.activityRequestStatus == constants.cardActivityRequestStatusCodes.failed.id) {
										var item = _.find(vm.currentGridData, function (x) {
											return x.id == entity.id;
										});
										item.activityRequestStatus = constants.cardActivityRequestStatusCodes.removed.id;
										item.activityRequestStatusDisplay = constants.cardActivityRequestStatusCodes.removed.description;
									} else {
										vm.currentGridData.splice(indexToDelete, 1);
									}

									// now update the list to save and mark items as deleted
									var itemToDelete = _.find(vm.activityRequestOrder.activityRequests,
										function (item) {
											return (item.cardId == entity.cardId);
										});
									if (itemToDelete.activityRequestNumber === '') {
										vm.activityRequestOrder.activityRequests.splice(itemToDelete, 1);
									}
									else {
										itemToDelete.isDeleted = true;
									}



								});
								// only save if we have any activity requests with numbers
								if (_.some(rowsToDelete, function (item) { return item.activityRequestNumber; })) {

									vm.activityRequestOrder.mode = constants.activityRequestOrderMode.delete;
									notificationService.notify('showLoadingPanel', true);
									cardService.cardActivityRequestsDelete(vm.activityRequestOrder).then(function (result) {
										vm.pageErrors = [];
										setRequestOrder(result.data);
										vm.gridApi.core.refresh();
										vm.requestsDeleted = true;
										notificationService.notify('showLoadingPanel', false);

									},
										function (result) { // Error
											notificationService.notify('showLoadingPanel', false);
											showErrors(result);
										});
								}
							}, function () {
							});
					}
				}


				function resetMessages() {
					vm.requestsDeleted = false;
					vm.orderSaved = false;
					vm.orderDeleted = false;
					vm.error = false;
				}
				vm.saveRequestOrder = function () {
					resetMessages();
					if (validateNumberOfCardsAllocated(vm.activityRequestOrder.activityRequests) === true) {
						notificationService.notify('showLoadingPanel', true);
						var isNew = vm.activityRequestOrder.id == 0;
						vm.IsSaveInProgress = true;
						vm.activityRequestOrder.mode = constants.activityRequestOrderMode.save;

						cardService.cardActivityOrderSave(vm.activityRequestOrder).then(function (result) {
							vm.IsSaveInProgress = false;
							vm.pageErrors = [];
							setRequestOrder(result.data);
							vm.gridApi.core.refresh();
							if (isNew) {
								history.replaceState({ activityRequestOrder: 'vm.activityRequestOrder.id' }, "Activity Request Order", $location.absUrl() + "/" + vm.activityRequestOrder.id);
							}
							$scope.cardActivityForm.$setPristine();
							vm.orderSaved = true;
							notificationService.notify('showLoadingPanel', false);

						},
							function (result) {
								vm.IsSaveInProgress = false;
								notificationService.notify('showLoadingPanel', false);
								vm.gridApi.core.refresh();
								// Error
								if (result.status == -1 && result.statusText == "") {
									utils.alert('<p>Activity Request ' + vm.activityRequestOrder.activityRequestOrderNumber + " has been successfully processed.</p><p>Please refresh the PHAP page by either:</p><p>-Selecting 'F5' on the keyboard; or</p> <p>-Re-opening the Activity Request Order.", 'Save successful', 'OK');
								}
								else {									
									showErrors(result);
								}
							});
					}

				}

				function setInProgress() {
					angular.forEach(vm.currentGridData,
						function (value, key) {
							if (value.activityRequestStatus == constants.cardActivityRequestStatusCodes.draft.id ||
								value.activityRequestStatus == constants.cardActivityRequestStatusCodes.failed.id) {
								value.activityRequestStatusDisplay = constants.cardActivityRequestStatusCodes.processing
									.description;
							}
						});
				}

				vm.submitRequestOrder = function () {
					vm.IsSubmitInProgress= true;
					utils.alert(constants.backgroundProcessMessage,'Submit');
					resetMessages();
					var savedgrid = angular.copy(vm.currentGridData);
					vm.activityRequestOrder.mode = constants.activityRequestOrderMode.submit;
					var currentStatus = vm.activityRequestOrder.activityOrderStatusDisplay;

					// if there are any requests that are draft or failed then we don't need to submit
					if (_.some(vm.currentGridData, function (item) { return (item.activityRequestStatus == constants.cardActivityRequestStatusCodes.draft.id || item.activityRequestStatus == constants.cardActivityRequestStatusCodes.failed.id); })) {
						setInProgress();
					} else {
						content = 'You can only submit draft or failed requests.';
						utils.alert(content, 'Submit warning', 'OK');
						return;
					}
					// just show the status to in progress and leave the id as draft.  it will get updated on the server
					vm.activityRequestOrder.activityOrderStatusDisplay = constants.cardActivityRequestOrderStatusCodes.inProgress.description;

					cardService.cardActivityOrderSubmit(vm.activityRequestOrder).then(
						function (result) {
							vm.IsSubmitInProgress = false;
							vm.pageErrors = [];
							setRequestOrder(result.data);

							$scope.cardActivityForm.$setPristine();
						},
						// Error
						function (result) {
							vm.IsSubmitInProgress = false;
							if (result.status == -1 && result.statusText == "") {
								utils.alert('<p>Activity Request ' + vm.activityRequestOrder.activityRequestOrderNumber + " has been successfully processed.</p><p>Please refresh the PHAP page by either:</p><p>-Selecting 'F5' on the keyboard; or</p> <p>-Re-opening the Activity Request Order.", 'Submit successful', 'OK');
							}
							else {								
								vm.activityRequestOrder.activityOrderStatusDisplay = currentStatus;
								vm.currentGridData = savedgrid;
								vm.gridOptions.data = vm.currentGridData;
								showErrors(result);
							}
						}
					);
				}

				function showErrors(result) {
					vm.pageErrors = [];
					if (result.data && result.data.errors && result.data.errors.length > 0) {
						var errorList = [];
						for (var i = 0; i < result.data.errors.length; i++) {
							errorList.push(result.data.errors[i].message);
						}
						vm.pageErrors = vm.pageErrors.concat(errorList);
					}
				}

				vm.showHistory = function () {
					$location.path('/administration/history/activity-request-order/' + vm.activityRequestOrder.id + '/' + vm.activityRequestOrder.activityRequestOrderNumber);
				}

				vm.deleteOrder = function () {
					resetMessages();
					vm.activityRequestOrder.mode = constants.activityRequestOrderMode.delete;
					notificationService.notify('showLoadingPanel', true);
					cardService.deleteActivityOrder(vm.activityRequestOrder).then(function (result) {
						notificationService.notify('showLoadingPanel', false);
						vm.orderDeleted = true;
						utils.alert('Card activity request order deleted successfully. You will be redirected back to the card activity search page.', 'Information', 'OK');
						$location.path("/financeManagement/cards/cardActivitySearch");
					}, function () {
						notificationService.notify('showLoadingPanel', false);
						vm.error = true;
					});
				}

				vm.canDeleteOrder = function () {
					if (vm.activityRequestOrder && vm.currentGridData) {
						return vm.currentGridData.length > 0 && vm.activityRequestOrder.id > 0
							&& (vm.activityRequestOrder.activityOrderStatusId == constants.cardActivityRequestOrderStatusCodes.draft.id ||
								vm.activityRequestOrder.activityOrderStatusId == constants.cardActivityRequestOrderStatusCodes.pendingApproval.id ||
								vm.activityRequestOrder.activityOrderStatusId == constants.cardActivityRequestOrderStatusCodes.pendingReview.id)
							&& !vm.IsSubmitInProgress;
					}

				}

				vm.approveOrder = function () {
					vm.IsSubmitInProgress = true;
					resetMessages();
					utils.alert(constants.backgroundProcessMessage, 'Approve order');
					var approvalModel = {
						id: vm.activityRequestOrder.id,
						rejectionReason: '',
						rowVersion: vm.activityRequestOrder.rowVersion
					};
					cardService.approveActivityOrder(approvalModel).then(
						function (result) {
							vm.IsSubmitInProgress = false;
							setRequestOrder(result.data);
						},
						// Error
						function (result) {
							vm.IsSubmitInProgress = false;
							if (result.status == -1 && result.statusText == "") {
								$location.path("/financeManagement/cards/cardActivitySearch");
							}
							else {
								showErrors(result);
							}
						}
					);
				}

				vm.rejectOrder = function () {
					vm.IsSubmitInProgress = true;
					resetMessages();
					var resolve = {
						order: function () {
							return vm.activityRequestOrder;
						}
					}
					utils.openModal('app/financeManagement/cardActivity/activityRequestRejectPopup.html', 'activityRequestRejectPopupController', $scope, resolve, null, rejectCallback).then(
						function () {
							vm.IsSubmitInProgress = false;
							utils.alert('Card activity request order rejected successfully.', 'Rejected', 'OK');
						}
					);
				}

				function rejectCallback(result) {
					vm.IsSubmitInProgress = false;
					//callback result will be undefined when the user clicks cancel
					if (result !== undefined) {
						setRequestOrder(result.data);
					}
				}

				vm.reloadData = function (result) {
					vm.loadData({ data: result, paymentId: vm.payment.paymentId });
				}

				vm.canCloseOrder = function () {
					return vm.currentGridData.length > 0 && vm.activityRequestOrder.id > 0
						&& (vm.activityRequestOrder.activityOrderStatusId == constants.cardActivityRequestOrderStatusCodes.error.id);
				}

				vm.canSave = function () {
					return (vm.currentGridData.length > 0 || _.find(vm.activityRequestOrder.activityRequests, function (checkDelete) { return checkDelete.isDeleted == true }))
						&& formIsValid() && formIsDirty() && vm.statusIsValid();
				}

				vm.canSubmit = function () {
					return vm.currentGridData.length > 0 && formIsValid()
						&& !formIsDirty() && vm.statusIsValid()
						&& orderHasValidSubmitStatus();
				}

				var orderHasValidSubmitStatus = function () {
					return vm.activityRequestOrder &&
						vm.activityRequestOrder.activityOrderStatusId == constants.cardActivityRequestOrderStatusCodes.draft.id ||
						vm.activityRequestOrder.activityOrderStatusId == constants.cardActivityRequestOrderStatusCodes.error.id ||
						vm.activityRequestOrder.activityOrderStatusId == constants.cardActivityRequestOrderStatusCodes.pendingReview.id;
				}

				vm.clickCancel = function () {
					$location.path("/financeManagement/cards/cardActivitySearch");
				}

				vm.statusIsValid = function () {
					if (!vm.activityRequestOrder || !vm.activityRequestOrder.id) {
						return true;
					}
					if (_.find(vm.activityRequestStatuses, function (status) { return status.allowEdit && status.id === vm.activityRequestOrder.activityOrderStatusId; })) {
						return true;
					}
					return false;
				}


				vm.canChangeOrder = function () {
					if (vm.activityRequestOrder && !vm.IsSubmitInProgress &&
						(vm.activityRequestOrder.activityOrderStatusId == constants.cardActivityRequestOrderStatusCodes.draft.id ||
							vm.activityRequestOrder.activityOrderStatusId == constants.cardActivityRequestOrderStatusCodes.pendingApproval.id ||
							vm.activityRequestOrder.activityOrderStatusId == constants.cardActivityRequestOrderStatusCodes.pendingReview.id ||
							vm.activityRequestOrder.activityOrderStatusId == constants.cardActivityRequestOrderStatusCodes.error.id)) {
						return true;
					}
					return false;
				}

				function formIsDirty() {
					if ($scope.cardActivityForm.$dirty) {
						vm.orderSaved = false;
						vm.orderDeleted = false;
						vm.error = false;
					};
					return ($scope.cardActivityForm.$dirty);
				}

				function formIsValid() {
					return (vm.isValidAmount() &&
						vm.activityRequestType != null &&
						$scope.cardActivityForm.$valid);
				}

				function getSavedOrder(id) {
					cardService.getCardActivityOrder(id).then(function (result) {
						if (result.data) {
							setRequestOrder(result.data);
						} else {
							vm.noResultsFound = true;
						}

					}, function (data) {
					})
				}

				function getColumnByName(name) {
					var column = null;
					angular.forEach(vm.gridOptions.columnDefs, function (value) {
						if (value.field == name) {
							column = value;
							return false;
						}
					});
					return column;
				}

				function hasBeenSubmitted() {
					return (vm.activityRequestOrder.activityOrderStatusId == constants.cardActivityRequestOrderStatusCodes.closed.id ||
						vm.activityRequestOrder.activityOrderStatusId == constants.cardActivityRequestOrderStatusCodes.amendedAndClosed.id ||
						vm.activityRequestOrder.activityOrderStatusId == constants.cardActivityRequestOrderStatusCodes.error.id);
				}

				function setRequestOrder(data) {
					vm.activityRequestOrder = data;
					vm.currentGridData = _.filter(data.activityRequests, function (x) { return x.activityRequestStatus != constants.cardActivityRequestStatusCodes.deleted.id });
					vm.gridOptions.data = vm.currentGridData;
					var oracleStatusCol = getColumnByName("oracleStatus");
					if (oracleStatusCol) {
						oracleStatusCol.visible = hasBeenSubmitted();
					}
					vm.showApprovalButtons = determineApprovalAccess(data);
					vm.showRejectionReason = !utils.isNullOrEmpty(vm.activityRequestOrder.rejectionReason);
					//set existing region id as the benchmark for future cards to add validation
					vm.setRegionUserAccess({ activityRequestOrderRegionId: data.activityRequestOrderRegionId });
					vm.activityLoaded = true;
				}

				function determineApprovalAccess(data) {
					// Must have privilege
					if (!vm.currentUserSecurityInfo.privileges.ApproveActivityRequestOrder) {
						return false;
					}

					// Current status must allow for approvals
					if (!_.find(vm.activityRequestStatuses, function (status) { return status.allowApproval && status.id === vm.activityRequestOrder.activityOrderStatusId; })) {
						return false;
					}

					// Must have access within activity request region
					if (!vm.currentUserSecurityInfo.userHasAccessToRegion(data.activityRequestOrderRegionId)) {
						return false;
					}

					// Must not be the creater of the request
					if (vm.activityRequestOrder.createdBy === vm.currentUserLoggedIn.id) {
						return false;
					}

					// Must not be the most recent updater of the request, unless they rejected the request.
					if (vm.activityRequestOrder.updatedBy === vm.currentUserLoggedIn.id &&
						vm.activityRequestOrder.rejectedBy !== vm.currentUserLoggedIn.id) {
						return false;
					}
					return true;
				}
				function validateNumberOfCardsAllocated(cardarray) {
					if (cardarray.length > 50) {
						utils.alert("A maximum of 50 cards can be applied to a single Activity Request Order. There are currently more than 50 cards associated with this order. This order can not be processed until the number of cards assigned is 50 or less.");
						return false;
					}
					return true;
				}
				function getCardsToAdd() {
					var selectedCards = vm.searchgridapi.selection.getSelectedRows();
					var selectedCardIds = _.map(vm.searchgridapi.selection.getSelectedRows(), 'id');
					var currentCardIds = _.map(vm.currentGridData, 'cardId');
					// get the card ids to add
					var newCardIds = _.difference(selectedCardIds, currentCardIds);
					// get the cards to add
					return selectedCards.filter(function (card) {
						return newCardIds.indexOf(card.id) > -1;
					});
				}

				function getValidAndInvalidCards(selectedCards, alreadySelectedCardsRegion, alreadySelectedCards) {
					var cardAlreadyAddedDifference = 0
					if (alreadySelectedCards.length > 0) {
						cardAlreadyAddedDifference = $.grep(_.map(selectedCards, "id"), function (element) {
							return $.inArray(element, _.map(vm.gridOptions.data, "cardId")) !== -1;
						}).length;
					}

					var regionMatch = cardRegionCheck(selectedCards, alreadySelectedCardsRegion);
					//Get the active cards
					var activeCards = selectedCards.filter(filterActive);
					//Identify inactive cards
					//exclude for expired cards
					if (vm.activityRequestType.id != 10) {
						var inactiveCards = _.difference(selectedCards, activeCards);
					}
					else {
						var inactiveCards = [];
                    }
					// now only get the cards that we can add based on the request type
					var allowedCards = activeCards.filter(filterAction);

					// convert the cars to the array that we want for the grid and that will be saved to the db
					var cardsToAdd = _.map(allowedCards, function (card) {
						return {
							id: 0,
							activityRequestNumber: '',
							activityRequestStatusDisplay: constants.cardActivityRequestOrderStatusCodes.draft.description,
							activityRequestStatus: constants.cardActivityRequestOrderStatusCodes.draft.id,
							cardId: card.id,
							cardIdToDisplay: card.cardId,
							availableBalance: card.cardBalance,
							recipientName: card.recipientName,
							region: card.region
						};
					});
					// get all cards that couldn't be added
					var cardsThatCannotBeAdded = _.difference(activeCards, allowedCards);

					return {
						regionMatch: regionMatch,
						cardsToAdd: cardsToAdd,
						cardsThatCannotBeAdded: cardsThatCannotBeAdded,
						inactiveCards: inactiveCards,
						cardAlreadyAddedDifference: cardAlreadyAddedDifference
					};
				}

				function filterActive(card) {
					return card.active;
				}

				function cardRegionCheck(selectedCards, existingSelectedRegion) {
					var region = selectedCards[0].region;

					angular.forEach(selectedCards, function (card, index) {
						if (region.toLowerCase() !== card.region)
							return false;
					});
					if (existingSelectedRegion) {
						if (region !== existingSelectedRegion) {
							return false;
						}
					}

					return true;
				}

				function filterAction(card) {
					switch (vm.activityRequestType.code) {
						case constants.cardActivityRequestTypeCodes.preloadCards: {
							// if preloaded then has to be active and not already preloaded
							return card.active.toLowerCase() == "yes" && card.preloaded.toLowerCase() == "no";
						}
						case constants.cardActivityRequestTypeCodes.addFundsToPreloadCards: {
							// if add funds then the card must be preloaded and reloadable
							return card.preloaded.toLowerCase() == "yes" && card.reloadable;
						}
						case constants.cardActivityRequestTypeCodes.removeFundsFromPreloadedcards: {
							// if add funds then the card must be preloaded
							return card.active.toLowerCase() == "yes" && card.preloaded.toLowerCase() == "yes";
						}
						case constants.cardActivityRequestTypeCodes.cancelPreloadedAndUnusedCards: {
							return card.active.toLowerCase() == "yes" && card.preloaded.toLowerCase() == "yes";
						}
						case constants.cardActivityRequestTypeCodes.cancelIssuedCards: {
							// if cancel lost or stolen cards then the card must be  active and issued
							return card.active.toLowerCase() == "yes" && card.cardStatus === "Issued";
						}
						case constants.cardActivityRequestTypeCodes.removeFundsFromIssuedcards: {
							return card.active.toLowerCase() == "yes" && card.cardStatus === "Issued";
						}						
						case constants.cardActivityRequestTypeCodes.cancelExpiredCards: {
							return card.active.toLowerCase() == "yes" && card.cardStatus === "Closed" && card.cardBalance > 20;
                        }
					}
					return true;
				}

				function getReason(invalidCards) {
					var cardList = "";
					angular.forEach(invalidCards, function (card) {
						if (vm.activityRequestType.code == constants.cardActivityRequestTypeCodes.preloadCards) {
							if (cardList === "")
								cardList += card.cardId + ":$" + card.cardValue;
							else
								cardList += ", " + card.cardId + ":$" + card.cardValue;
						}
						else {
							if (cardList === "")
								cardList += card.cardId;
							else
								cardList += ", " + card.cardId;
						}

					});
					switch (vm.activityRequestType.code) {
						case constants.cardActivityRequestTypeCodes.preloadCards: {
							return "The following selected cards cannot be added to the request order: " + cardList + ". The activity requests can not be applied.";
						}
						case constants.cardActivityRequestTypeCodes.addFundsToPreloadCards: {
							return "The following selected cards cannot be added to the request order: " + cardList + ". The activity requests can not be applied.";
						}
						case constants.cardActivityRequestTypeCodes.cancelPreloadedAndUnusedCards: {
							return "The following selected cards cannot be added to the request order: " + cardList + ". The activity requests can not be applied.";
						}
						case constants.cardActivityRequestTypeCodes.removeFundsFromPreloadedcards: {
							return "The following selected cards cannot be added to the request order: " + cardList + ". The activity requests can not be applied.";
						}
						case constants.cardActivityRequestTypeCodes.cancelIssuedCards: {
							return "Card(s) selected " + cardList + " not in a valid state and can't be cancelled";
						}
						case constants.cardActivityRequestTypeCodes.cancelExpiredCards: {
							return "Card(s) selected " + cardList + " not in a valid state or available balance less than $20 and can't be cancelled";
						}
						case constants.cardActivityRequestTypeCodes.removeFundsFromIssuedcards: {
							return "Card(s) selected " + cardList + " not in a valid state and can't be cancelled";
						}

					}
				}
			}],
		controllerAs: 'vm'
	});

})();