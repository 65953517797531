(function () {
    angular.module('phapApp')
        .directive('debrisRemoval', ['Constants', 'utils', function (Constants, utils) {
            return {
                scope: true,
                restrict: 'E',
                templateUrl: 'app/home/reestablish/_debris.html',
                controller: ['$scope','reestablishService', function ($scope, reestablishService) {

                    $scope.uploadController = {};

                    // #region Business Rule checking

                    $scope.$watchCollection('[item.amountOfDebrisRemoval, item.evidenceAttachedOfDebrisRemoval]', function (newValues) {
                        if (!angular.isDefined(newValues[0]))
                            return;
                        $scope.debrisBusinssRules();
                    });

                    $scope.$on('dataLoaded', function () {
                        $scope.debrisBusinssRules();
                    });

                    $scope.debrisBusinssRules = function () {
                        var amount = utils.parseDollarAmount($scope.item.amountOfDebrisRemoval);
                        $scope.formSections.categoriesOfAssessment.subMenu.debrisRemoval.evidenceRequired = ($scope.item.applicant.memberType != null && $scope.item.applicant.memberType.id === Constants.memberTypes.owner && amount > 0 && $scope.item.evidenceAttachedOfDebrisRemoval === false);
                        reestablishService.setExceptionalCircumstances($scope.formSections, $scope.item);
                    };

                    // #endregion 

                    var noDocumentsAttached = function () {
                        if ($scope.item && $scope.item.documents) {
                            var documents = $scope.item.documents[Constants.reestablishSections.debrisRemoval];
                            return !angular.isDefined(documents) || documents.length === 0;
                        }
                        return false;
                    };

                    var confirm = function () {
                        var title = 'Evidence documents required!';
                        var content = 'Evidence attached has been selected as Yes and no attachments have been loaded. \nDocuments must be loaded in order to set the Evidence attached field to Yes.';
                        utils.confirm(title, content, 'Proceed', 'Cancel')
                            .then(function () {
                                $scope.item.evidenceAttachedOfDebrisRemoval = false;
                            }, function () {
                                $scope.item.evidenceAttachedOfDebrisRemoval = null;
                            });
                    };

                    $scope.debrisCheckEvidence = function () {
                        if (noDocumentsAttached()) {
                            $scope.uploadController.$newUpload();
                        }
                    };

                    $scope.$watch('item.applicant.memberType.id', function (nv, ov) {
                        if (!$scope.loaded || !$scope.item.documents) return;
                        var documents = $scope.item.documents[Constants.reestablishSections.debrisRemoval];
                        if (nv != Constants.memberTypes.owner) {
                            if (documents && documents.length) {
                                if (nv != null && ov != null && nv != ov) {
                                    utils.alert('Evidence documents are attached for removal of debris. Please remove them before changing applicant to tenant.', 'Evidence documents attached!');
                                    $scope.item.applicant.memberType.id = Constants.memberTypes.owner;
                                    return;
                                }
                            } 
                            $scope.item.amountOfDebrisRemoval = 0;
                        } 
                    });

                    var debrisVarifyDocumentsAttached = function () {
                        if ($scope.item && $scope.item.evidenceAttachedOfDebrisRemoval) {
                            if (noDocumentsAttached()) {
                                confirm();
                            }
                        }
                    };

                    $scope.debrisOnCancelUpdate = function () {
                        debrisVarifyDocumentsAttached();
                    };

                    $scope.debrisOnFileListChanged = function () {
                        debrisVarifyDocumentsAttached();
                    };
                }]
            };
        }]);
})();