(function () {
    angular.module('phapApp')
        .directive('eligibilityOptions', ['$window', function ($window) {
            return {
                restrict: 'E',
                templateUrl: 'app/home/relief/_eligibilityOptions.html',
                controller: ['$scope', 'lgaService', '$uibModal', 'utils', '$q', controller]
            };
        }]);



    function controller($scope, lgaService, $uibModal, utils, $q) {
    
    }


})();