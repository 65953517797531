(function () {

    var app = angular.module('phapApp');

    app.component('incidentStatus', {
        templateUrl: 'app/administration/configuration/incidentStatus.html',
        bindings: {
            mode: '@' //create/edit
        },
        controller: ['$scope', '$filter', 'utils', 'referenceService', 'security', function ($scope, $filter, utils, referenceService, security) {

            var vm = this;
            vm.originalDataSet = [];
            vm.gridApi = null;
            vm.historyUrl = "incidentStatus/0";
            var getIncidentStatuses = function() {
                referenceService.getIncidentStatuses().then(
                    function (emergencyTypes) {
                        var list = [];
                        angular.forEach(emergencyTypes.data, function (value, key) {
                            this.push({ data: value, isUpdated: false, isAdded: false });
                        }, list);
                        vm.gridOptions.data = list;
                        vm.originalDataSet = angular.copy(vm.gridOptions.data);
                        $scope.$broadcast('displayLoading', false);
                    });
            }

            var init = function () {
                getSecurity();
            };

            var getSecurity = function () {
                $scope.$broadcast('displayLoading', true);
                //getting logged in user security
                security.getCurrentUserSecurityInfo()
                    .then(function (result) {
                        vm.gridOptions.privileges = result.privileges.UpdateIncidentAdmin;
                        vm.gridOptions.showPrivilegePanel = true;
                        if (vm.gridOptions.privileges)
                            getIncidentStatuses();

                    },
                    function () {
                        vm.gridOptions.privileges = false;
                    });
            };

            vm.gridOptions = {
                showPrivilegePanel : false,
                enableCellEdit: false,
                enableSorting: false,
                enableCellEditOnFocus: true,
                newObject: { data: { name: null, isDeleted: false, isReadOnly: false }, isUpdated: false, isAdded: true }
            };
            
            vm.gridOptions.columnDefs = [
                { name: 'data.name', displayName: 'Incident status', enableCellEdit: true, enableColumnMenu: false },
                {
                    name: 'data.isDeleted', displayName: '', enableCellEdit: false, enableColumnMenu: false, width: 100,
                    cellClass: function (grid, row, col, rowRenderIndex, colRenderIndex) {

                        return "grid-readonly-button";
                    },
                    cellTemplate: '<span title="Read only" class="glyphicon glyphicon-lock" ></span>'

                }
               ];
            
            init();

        }],
        controllerAs: 'incidentStatusVm'
    });

})();