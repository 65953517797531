(function () {

    var app = angular.module('phapApp');

    app.component('glTableCell', {
        templateUrl: 'app/administration/configuration/glTableCell.html',
        bindings: {
            segment: '=',
            rules: '='
        },
        controller: ['$scope', '$filter', 'uiGridConstants', 'utils', function ($scope, $filter, uiGridConstants, utils) {
            var vm = this;

            var buildFriendlyName = function (ruleCode) {
                return ruleCode.replace(/([a-z])([A-Z])/g, '$1 $2');
            };

            var displayRuleOrName = function (ruleCode) {
                if (vm.rules)
                    return vm.rules[ruleCode].rule;
                else
                    buildFriendlyName(ruleCode);
            };
            var getRuleClass = function (ruleCode) {
                if (vm.rules)
                    return "glRules-"+vm.rules[ruleCode].rule;
            };


            vm.displayRuleCode = function () {
                if (vm.segment) {
                    var start = '<span ';
                    var end = '</span>';
                    var body = '';
                    if (vm.segment.constantValue)
                        body = '>' + vm.segment.constantValue;
                    else {
                        body = 'title = "' + buildFriendlyName(vm.segment.ruleDefinitionCode)+'" class = "' + getRuleClass(vm.segment.ruleDefinitionCode) + '">' + displayRuleOrName(vm.segment.ruleDefinitionCode);
                    }

                    return start + body + end;
                }
            };



        }],
        controllerAs: 'glTableCellVm'
    });

})();