
(function () {
    angular.module('phapApp')
        .filter('phapDate', ['$filter', 'Constants', function ($filter, Constants) {

            var angularCustomDateFilter = function (date) {
                return $filter('date')(date, Constants.DatePickerFormat);
            };


            return function (theDate, attr) {

                if (theDate) {
                    var newDate;

                    if (theDate == '0001-01-01T00:00:00' || theDate == '01-Jan-0001' || theDate == '01-01-0001') {
                        //null
                        return "";
                    }
                    else if (theDate.getMonth) {
                        //angular date object
                        newDate = theDate;
                    }
                    else if (theDate._i) {
                        // moment date object
                        newDate = new Date(theDate._i);
                    }
                    else {
                        //string
                        newDate = new Date(theDate);
                        if (isNaN(newDate.getTime())) {
                            return theDate;
                        }
                        else {
                            // no time component match
                            newDate = moment(theDate).toDate();
                        }
                    }
                    return angularCustomDateFilter(newDate);
                }


            };
        }])
        .directive('dateformat', ['$filter', function ($filter) {
            return {
                require: '?ngModel',
                link: function (scope, elem, attrs, ctrl) {
                    if (!ctrl) return;

                    if (attrs.dateformat === "phapDate") {
                        ctrl.$viewValue = $filter(attrs.dateformat)(elem.val());
                    }

                    ctrl.$formatters.unshift(function () {
                        return $filter(attrs.dateformat)(ctrl.$modelValue);
                    });

                    elem.bind('blur', function () {
                        ctrl.$viewValue = $filter(attrs.dateformat)(elem.val());
                    });
                }
            };
        }]);
})();