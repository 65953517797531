(function () {
    angular.module('phapApp')
        .directive('memberDetails', ['$window', function ($window) {
            return {
                restrict: 'E',
                scope: true,
                templateUrl: 'app/home/relief/_memberDetailsTemplate.html',
                controller: ['$scope', 'lgaService', '$uibModal', 'utils', '$q', '$location', 'searchMemberService', '$timeout', 'Constants',  controller],
                link: function (scope, elem, attr) {
                    var watches = ['member', 'prefix', 'isApplicant'];
                    watches.forEach(function (w) {
                        if (attr[w]) {
                            scope.$watch(function () {
                                return scope.$eval(attr[w]);
                            }, function (nv) {
                                scope[w] = nv;
                            });
                        }
                    });
                }
            };
        }]);



    function controller($scope, lgaService, $uibModal, utils, $q, $location, searchMemberService, $timeout, Constants) {

        $scope.Constants = Constants;

        $scope.$popups = {
            dob: { opened: false }
        };

        $scope.openModal = function (templateUrl, controller, scope, resolve, windowClass) {
            utils.openModal(templateUrl, controller, scope, resolve, windowClass);
        };
       
        $scope.clickOccupancyType = function (memberType)
        {
            $scope.item.applicant.memberType = memberType;
            if ($scope.item.applicant.memberType.id === Constants.memberTypes.privateTenant || 
                $scope.item.applicant.memberType.id === Constants.memberTypes.publicHousingTenant)
            {
                $scope.clickTenant();
            }
        }

        $scope.isPhoneMandatory = function () {
            return $scope.member &&
                $scope.member.contactDetails &&
                $scope.member.contactDetails.preferredContactMethod === Constants.contactMethods.phone;
        }

        // Recalculate the set of source addresses whenever the load postal address dropdown is
        // opened
        $scope.toggleAddressDropdown = function (isOpen) {
            if (isOpen) {
                updateAvailableAddresses();
            }
        }

        var updateAvailableAddresses = function () {
            // We're going to build a mapping from the "addressString" (e.g. "50 Lonsdale St Melbourne 3000")
            // to the full address object. Why? This gives us de-duplication of addresses easily.
            var tempMap = {};

            function addFieldValueToKey(v, separator, preKey) {
                if (!v || !v.length) return preKey;

                var result = preKey;

                if (preKey.length) result += separator;

                result += v;

                return result;
            }

            function getKeyForAddress(ad) {
                var key = "";

                key = addFieldValueToKey(ad.unitNo, " ", key);
                key = addFieldValueToKey(ad.streetNo, "/", key);
                key = addFieldValueToKey(ad.streetName, " ", key);
                key = addFieldValueToKey(ad.suburb, " ", key);
                key = addFieldValueToKey(ad.postcode, " ", key);
                key = addFieldValueToKey(ad.state, " ", key);
                    
                return key;
            };

            function addEntryToAddressSet(sourceDescription, address) {
                if (!address) return;

                var key = getKeyForAddress(address);

                if (!key || !key.length) return;

                if (tempMap[key]) {
                    tempMap[key].sourceDescription += ", " + sourceDescription;
                } else {
                    tempMap[key] = {
                        sourceDescription: sourceDescription,
                        addressString: key,
                        addressDetails: address
                    };
                }
            };

            // Add this householder's address as the first entry; we'll remove this later, as it's
            // obviously not a sensible source to copy from
            addEntryToAddressSet("This member", $scope.member.contactDetails.address);

            // Add the affected address to the set of available copy sources
            addEntryToAddressSet("Affected address", $scope.$parent.$parent.item.affectedAddress.address);

            // Add applicant's and each member's address to the set of available copy sources
            var addContactAddressForHouseholdMember = function (m) {
                var contactName = "unnamed household member";

                if (m.givenName || m.surname) {
                    contactName = m.givenName + " " + m.surname;
                }

                addEntryToAddressSet("Contact address for " + contactName, m.contactDetails.address);
            }

            // First the applicant
            addContactAddressForHouseholdMember($scope.$parent.$parent.item.applicant);

            // Then the members
            _.each($scope.$parent.$parent.item.householdMembers, addContactAddressForHouseholdMember)

            // Remove the entry from the list of possible copy source addresses that matches the current
            // member's address, if any; no sense offering to copy the same values
            if ($scope.member.contactDetails.address) {
                delete tempMap[getKeyForAddress($scope.member.contactDetails.address)];
            }

            $scope.member.$availableAddresses = _.values(tempMap)
        };

        $scope.loadAddress = function (targetMember, sourceAddress) {
            if (targetMember) {
                if (!targetMember.contactDetails) {
                    targetMember.contactDetails = {};
                }

                if (!targetMember.contactDetails.address) {
                    targetMember.contactDetails.address = {};
                }

                angular.copy(sourceAddress, targetMember.contactDetails.address);
            }
        };

        $scope.$watch('member.dob', function (nv, ov) {
            if (nv != ov) {
                $scope.member.isChild = $scope.isChild($scope.member);
                $scope.member.disableChildCheckBox = true;
            }
        });

        $scope.setValidatedStatus = function (member, savedValue, oldValue, newValue) {
            if (!newValue) {
                return;
            }
            if (!oldValue) {
                oldValue = savedValue;
            }
            if (!oldValue) {
                // For a new value set validated to false (Validate is required)
                member.validated = false;
            }
            else if (newValue.toLowerCase() !== oldValue.toLowerCase()) {
                // If it is an existing application, and givenName/surname changed to saved value, set validated to true (value is not changed)
                member.validated = false;
            }

        };

        $scope.onMemberChange = function (member, type, event) {
            var dateFormat = Constants.DatePickerFormat;
            if (!!member.savedGivenName && !!member.savedSurname && !!member.savedDob &&
                !!member.givenName && !!member.surname && !!member.dob &&
                member.givenName.toLowerCase() === member.savedGivenName.toLowerCase() &&
                member.surname.toLowerCase() === member.savedSurname.toLowerCase() &&
                moment(member.dob).format(dateFormat) === moment(member.savedDob).format(dateFormat)) {
                member.validated = true;
            }
            if (type === 'dob') {
                if (event && event.type === 'click') {
                    $scope.checkValidation(member, type, true);
                }
            }
            $scope.onMemberChangeNotifier(member, type);
        };

        $scope.checkValidation = function (member, type, onDateSelection) {
            if ((!onDateSelection && $scope.$popups.dob.opened) || !member) {
                return;
            }
            var dateFormat = Constants.DatePickerFormat;
            if (!!member.savedGivenName && !!member.savedSurname && !!member.savedDob &&
                !!member.givenName && !!member.surname && !!member.dob &&
                member.givenName.toLowerCase() === member.savedGivenName.toLowerCase() &&
                member.surname.toLowerCase() === member.savedSurname.toLowerCase() &&
                moment(member.dob).format(dateFormat) === moment(member.savedDob).format(dateFormat)) {
                member.validated = true;
                return;
            }
            if (type === "givenName") {
                if (!member.givenName) {
                    return;
                }
                $scope.setValidatedStatus(member, member.savedGivenName, member.oldGivenName, member.givenName);
                member.oldGivenName = member.givenName;
            }
            else if (type === "surname") {
                if (!member.surname) {
                    return;
                }
                $scope.setValidatedStatus(member, member.savedSurname, member.oldSurname, member.surname);
                member.oldSurname = member.surname;
            }
            else if (type === "dob") {
                if (!member.dob) {
                    return;
                }
              
                var dob = moment(member.dob).format(dateFormat);
                var savedDob = null;
                if (!!member.savedDob) {
                    savedDob = moment(member.savedDob).format(dateFormat);
                }
                var oldDob = null;
                if (!!member.oldDob) {
                    oldDob = moment(member.oldDob).format(dateFormat);
                }
                $scope.setValidatedStatus(member, savedDob, oldDob, dob);
                member.oldDob = member.dob;
            }
        }

        $scope.searchMember = function (member) {
            if ((!$scope.item ||
                !$scope.item.affectedAddress ||
                !$scope.item.affectedAddress.address ||
                !$scope.item.affectedAddress.address.streetName ||
                !$scope.item.affectedAddress.address.suburb) &&
                (!!member.givenName ||
                !!member.surname ||
                !!member.dob)
                ) {
                member.givenName = '';
                member.surname = '';
                member.dob = '';
                member.validated = false;
                alert('Please enter affected address first');
                return false;
            }
            var appType = $scope.getApplicationType();
            if (!appType) {
                return false;
            }

            if ($scope.$popups.dob.opened) {
                $scope.$popups.dob.opened = false;
            }

            if (!member ||
                !member.givenName ||
                !member.surname ||
                !member.dob ||
                member.validated === true) {
                return false;
            }

            var enteredDob = moment(member.dob).toDate();
            var dob = moment(member.dob).add(1, 'days');
            var model = {
                incidentId: $scope.item.incidentId,
                givenName: member.givenName,
                surname: member.surname,
                dob: dob,
                knownAs: member.knownAs,
                email: member.contactDetails.email,
                address: $scope.item.affectedAddress.address,
                applicationId: $scope.id,
                isIncidentSearch: true,
                applicationType: appType
            };

            // First search if the current member appears in the incident. If yes, display search results popup and withdraw the application.
            searchMemberService.incidentMemberSearch(model).then(function (result) {
                // display popup
                if (!!result && !!result.data && !!result.data.members && result.data.members.length > 0) {
                        model.dob = enteredDob;
                        member.id = result.data.members[0].member.id;
                        member.contactDetails = result.data.members[0].member.contactDetails;
                        $scope.openMemberSearchResultsModal(result.data.members, model);
                } else {
                    member.validated = true;
                    $scope.searchMembersInOtherIncidents(member, model, dob, enteredDob);
                }
            },
            function (error) {
                // display error?
                alert("Error searching for a member in the current incident");
            });
            return true;
        };

        $scope.getApplicationType = function () {
            var path = $location.path();
            var appType = null;
            if (path.indexOf('/relief/') > 0) {
                appType = Constants.applicationType.relief;
            }
            else if (path.indexOf('/reestablish/') > 0) {
                appType = Constants.applicationType.reestablish;
            }
            return appType;
        };

        $scope.searchMembersInOtherIncidents = function (member, model, dob, enteredDob) {
            var appType = $scope.getApplicationType();
            model.isIncidentSearch = false;
            model.dob = dob;
            searchMemberService.memberSearch(model).then(function (result) {
                if (!!result &&
                    !!result.data &&
                    !!result.data.members &&
                    result.data.members.length > 0) {
                    model.dob = enteredDob;
                    $scope.openMemberSearchResultsModal(result.data.members, model)
                        .then(function (memberFound) {
                            if (memberFound) {
                                // call API to retrieve all members linked to the current member
                                var searchModel = model;
                                searchModel.memberId = $scope.memberSearchSelectedMember.id;
                                searchMemberService.householdMembersSearch(searchModel)
                                    .then(function (response) {
                                        if (!!response && !!response.data) {
                                            if (!!response.data.membersInCurrentIncident && response.data.membersInCurrentIncident.length > 0 &&
                                                response.data.selectedMemberInCurrentIncident) {
                                                model.isIncidentSearch = true;
                                                var memberInCurrentIncident = response.data.membersInCurrentIncident[0];
                                                member.id = memberInCurrentIncident.member.id;
                                                member.givenName = memberInCurrentIncident.member.givenName;
                                                member.surname = memberInCurrentIncident.member.surname;
                                                member.dob = new Date(memberInCurrentIncident.member.dob);
                                                member.contactDetails = memberInCurrentIncident.member.contactDetails;
                                                $scope.openMemberSearchResultsModal(response.data.membersInCurrentIncident, model);
                                            }
                                            else {
                                                if (!!response.data.members && response.data.members.length > 0) {
                                                    $scope.copyMemberDetails(member,
                                                        response.data.members);
                                                    member.validated = true;
                                                }
                                                if (!!response.data.membersInCurrentIncident &&
                                                    response.data.membersInCurrentIncident.length > 0) {
                                                    var membersInCurrentIncidentModel = model;
                                                    membersInCurrentIncidentModel.householdMembersInCurrentIncident = true;
                                                    model.givenName = member.givenName;
                                                    model.surname = member.surname;
                                                    model.dob = member.dob;
                                                    model.email = member.contactDetails.email;
                                                    model.address = member.contactDetails.address;
                                                    model.knownAs = member.knownAs;
                                                    model.currentApplicationType = model.applicationType;
                                                    model.applicationType = response.data.applicationType;
                                                    model.applicationNumber = response.data.applicationNumber;
                                                    model.applicationId = response.data.applicationId;
                                                    $scope.openMemberSearchResultsModal(response.data.membersInCurrentIncident, model);
                                                }
                                            }

                                        } else {
                                            // Error
                                            alert("Error retrieving household members for the selected member");
                                        }
                                    },
                                    function (error) {
                                        alert("Error retrieving household members for the selected member");
                                    });
                            }
                            else {
                                member.validated = true;
                            }
                        });
                    //return defer.promise;
                } else {
                    // Member was not found, do nothing
                    member.validated = true;
                }
            },
            function (error) {
                // display error?
                alert("Error searching for a member not in the current incident");
            });
        };

        $scope.copyMemberDetails = function(member, copyMembers) {
            if ($scope.item) {
                if (!$scope.item.householdMembers) {
                    $scope.item.householdMembers = [];
                }
            }
            _.each(copyMembers,
                function(m) {
                    if (m.isExactMatch) {
                        // copy everything from m.member to member
                        $scope.copyMember(member, m.member);
                    } else {
                        m.member.dob = new Date(m.member.dob);
                        m.member.oldGivenName = m.member.givenName;
                        m.member.oldSurname = m.member.surname;
                        m.member.oldDob = m.member.dob;
                        m.member.weeklyIncome = '';
                        //TODO: Clarify what to do in this situation 
                        //TODO: Search for M1 resulted in returned M1, M2, M3, updating M1 and adding M2 and M3. 
                        //TODO: The user changed M3 details (givenName or surname or dob). It triggers search (validate button). When searching for M3 the same M1, M2, M3 are found.
                        //TODO:  What to do?
                        //TODO:  Options:
                        //TODO:   1. Don't update M1, M2. Update M3 only (current behaviour).
                        //TODO:   2. Update M1, M2, M3 each time (commented out behaviour). Issue: M1, M2 may have been retrieved and updated before th search for M3. If they will be updated now, the changes may be overwritten.
                        var memberFound = false;
                        if ($scope.item.applicant.id === m.member.id) {
                            memberFound = true;
                            //$scope.copyMember($scope.applicant, m.member);

                        } else {
                            _.each($scope.item.householdMembers,
                                function(a) {
                                    if (a.id === m.member.id) {
                                        //$scope.copyMember($scope.applicant, m.member);
                                        memberFound = true;
                                    }
                                });
                        }
                        if (!memberFound) {
                            $scope.item.householdMembers.push(m.member);
                            $scope.onMemberChangeNotifier($scope.item.householdMembers[$scope.item.householdMembers.length - 1], 'dob');
                        }

                    }
                });
        };

        $scope.copyMember = function(member, existingMember) {
            // copy everything from existingMember to member
            member.id = existingMember.id;
            member.givenName = existingMember.givenName;
            member.surname = existingMember.surname;
            member.dob = new Date(existingMember.dob);
            member.knownAs = existingMember.knownAs;
            member.contactDetails = existingMember.contactDetails;
            member.genderId = existingMember.genderId;
            member.gender = existingMember.gender;
            member.idType = existingMember.idType;
            member.idNumber = existingMember.idNumber;
            member.validated = existingMember.validated;
            member.oldGivenName = member.givenName;
            member.oldSurname = member.surname;
            member.oldDob = member.dob;
            $scope.onMemberChangeNotifier(member, 'dob');
        }

        $scope.openMemberSearchResultsModal = function (members, model) {
            var deferred = $q.defer();
            if (!$scope.state.memberSearchResultsModalOpened) {
                $scope.state.memberSearchResultsModalOpened = true;
                var memberSearchResultsModel = {
                    filter: model,
                    members: members,
                    applicationType: $scope.getApplicationType()
                };

                var resolve = {
                    memberSearchResultsModel: function () {
                        return memberSearchResultsModel;
                    },
                    defer: function () {
                        return deferred;
                    }
                };
                var popupClass = 'search-results-modal-popup';

                if (!model.isIncidentSearch && !model.householdMembersInCurrentIncident) {
                    popupClass += " search-results";
                }
                $scope.openModal('app/home/relief/_memberSearch.html', 'SearchMemberResultsModalCtrl', $scope, resolve, popupClass);

            } else {
                deferred.resolve();
            }
            return deferred.promise;
        };

        $scope.openMemberInCurrentIncidentModal = function (member, applicationId, applicationNumber, applicationType) {
            var deferred = $q.defer();
            if (!$scope.state.memberInCurrentIncidentModalOpened) {
                $scope.state.memberInCurrentIncidentModalOpened = true;
                var model = {
                    member: member,
                    applicationId: applicationId,
                    applicationType: applicationType,
                    applicationNumber: applicationNumber
                };

                var resolve = {
                    memberModel: function () {
                        return model;
                    },
                    defer: function () {
                        return deferred;
                    }
                };
                $scope.openModal('app/home/relief/_memberInCurrentIncident.html', 'MemberInCurrentIncidentModalCtrl', $scope, resolve);

            } else {
                deferred.resolve();
            }
            return deferred.promise;
        }

        $scope.clickTenant = function () {
            //check the applicants member type.  we only reset the values when the applicant is tenant.
            //we don't care if this is not applicant
            if ($scope.isApplicant) {
                $scope.item.amountOfDebrisRemoval = 0;
                $scope.item.essentialRepairsClaimAmount = 0;
                $scope.item.safetyReconnectionClaimAmount = 0;
                $scope.item.demolitionRebuildingClaimAmount = 0;
            }
        }
    }

})();