(function () {

    var app = angular.module('phapApp');

    app.component('errorList', {
        templateUrl: 'app/administration/errorLog/errorList.html',
        bindings: {
        },
        controller: ['$scope', '$filter', 'utils', 'referenceService', 'errorHandlingService', 'uiGridConstants', '$timeout',
            function ($scope, $filter, utils, referenceService, errorHandlingService, uiGridConstants, $timeout) {  
            
            var ctrl = this;

            ctrl.pageSize = 100;
            ctrl.keepPages = 10;
            ctrl.firstPage = ctrl.lastPage = 0;
            ctrl.enableUp = function () { return ctrl.firstPage > 0; };
            ctrl.enableDown = function (newData) { return newData != null && newData.length == ctrl.pageSize; };

            ctrl.data = [];

            ctrl.errorRefs = {};
            var refCall = referenceService.getErrorRefs().then(function(response) {
                ctrl.errorRefs = response.data;
            });

            ctrl.errorTypeFilter = function () {
                return ctrl.errorRefs.errorTypes[this.type];
            };
            ctrl.errorCategoryFilter = function (input) {
                return ctrl.errorRefs.errorCategories[this.category];
            }
            ctrl.errorStatusFilter = function (input) { 
                return ctrl.errorRefs.errorStatus[this.status];
            };

            ctrl.gridOptions = {
                infiniteScrollRowsFromEnd: 40,
                infiniteScrollUp: true,
                infiniteScrollDown: true,
                enableSorting: false,
                enableRowSelection: true,
                enableRowHeaderSelection: false,
                multiSelect: false,
                modifierKeysToMultiSelect: false,
                noUnselect: true,
                enableHorizontalScrollbar: uiGridConstants.scrollbars.NEVER,
                onRegisterApi: function (gridApi) {
                    ctrl.gridApi = gridApi;
                    gridApi.infiniteScroll.on.needLoadMoreData($scope, ctrl.getDataDown);
                    gridApi.infiniteScroll.on.needLoadMoreDataTop($scope, ctrl.getDataUp);
                    gridApi.selection.on.rowSelectionChanged($scope, ctrl.selectRow);
                },
                columnDefs: [
                    { name: 'errorNumber', displayName: 'Error Number' },
                    { name: 'errorCode', displayName: 'Error Code' },
                    { name: 'getType', displayName: 'Type', field: "getType()" },
                    { name: 'getCategory', displayName: 'Category', field: "getCategory()" },
                    { name: 'severity', displayName: 'Severity' },
                    { name: 'getStatus', displayName: 'Status', field: "getStatus()" },
                    { name: 'createdTime', displayName: 'Created Time', cellFilter: "date:'dd/MM/yyyy HH:mm:ss'" },
                    {
                        name: 'status', displayName: '', enableCellEdit: false, enableColumnMenu: false, width: 100, enableSorting: false,
                        cellClass: 'text-center',
                        cellTemplate: '<a ng-if="row.entity.closedTime == null" title="Details""><span class="glyphicon glyphicon-eye-open" ></span></a>'
                    }
                ]
            };

            ctrl.getPage = function (direction) {
                var page = direction == 'down' ? ctrl.lastPage++ : ctrl.firstPage--;
                return errorHandlingService.getPage(page, ctrl.pageSize)
                    .then(function (response) {
                        var newData = response.data;
                        for (var k in newData) {
                            newData[k].getType = ctrl.errorTypeFilter;
                            newData[k].getCategory = ctrl.errorCategoryFilter;
                            newData[k].getStatus = ctrl.errorStatusFilter;
                        }
                        ctrl.gridApi.infiniteScroll.saveScrollPercentage();
                        ctrl.data = direction == 'down' ? ctrl.data.concat(newData) : newData.concat(ctrl.data);
                        ctrl.gridOptions.data = ctrl.data;
                        return ctrl.gridApi.infiniteScroll
                            .dataLoaded(ctrl.enableUp(), ctrl.enableDown(newData))
                            .then(function () { ctrl.checkDataLength(direction); });
                    })
                    .catch(function (error) {
                        return ctrl.gridApi.infiniteScroll.dataLoaded(ctrl.enableUp(), false);
                    });
            };

            ctrl.selectRow = function (row) {
                var resolve = {
                    errorRow: function () { return row; }
                };
                utils.openModal('app/administration/errorLog/errorAction.html', 'errorActionController', $scope, resolve, null, function () { }, null, true, true)
                    .then(function () {
                        ctrl.gridApi.selection.clearSelectedRows();
                    });
            };

            ctrl.getDataDown = function () {
                return ctrl.getPage('down');
            };

            ctrl.getDataUp = function () {
                return ctrl.getPage('up');
            };

            ctrl.checkDataLength = function (discardDirection) {
                // work out whether we need to discard a page, if so discard from the direction passed in
                if ((ctrl.lastPage - ctrl.firstPage) > (ctrl.keepPages - 1)) {
                    // we want to remove a page
                    ctrl.gridApi.infiniteScroll.saveScrollPercentage();

                    if (discardDirection === 'up') {
                        ctrl.gridOptions.data = ctrl.data = ctrl.data.slice(ctrl.pageSize);
                        ctrl.firstPage++;
                        $timeout(function () {
                            // wait for grid to ingest data changes
                            ctrl.gridApi.infiniteScroll.dataRemovedTop(ctrl.enableUp(), ctrl.data.length % ctrl.pageSize == 0);
                        });
                    } else {
                        ctrl.gridOptions.data = ctrl.data = ctrl.data.slice(0, ctrl.keepPages * ctrl.pageSize);
                        ctrl.lastPage--;
                        $timeout(function () {
                            // wait for grid to ingest data changes
                            ctrl.gridApi.infiniteScroll.dataRemovedBottom(ctrl.enableUp(), ctrl.data.length % ctrl.pageSize == 0);
                        });
                    }
                }
            };

            refCall.then(function () {
                ctrl.getDataDown();
            });
        }]
    });

})();