// allow you to format a text input field.
// <input type="text" ng-model="test" format="number" />
// <input type="text" ng-model="test" format="currency" />
(function () {
    angular.module('phapApp')
        .directive('format', ['$filter', function ($filter) {
            return {
                require: '?ngModel',
                link: function (scope, elem, attrs, ctrl) {
                    if (!ctrl) return;

                    if (attrs.format === "currency" || attrs.format === "phapcurrency" || attrs.format === "phapcurrencycent") {
                        elem.addClass("currency");
                    }

                    ctrl.$formatters.unshift(function () {
                        return $filter(attrs.format)(ctrl.$modelValue);
                    });

                    elem.bind('blur', function () {
                        if (attrs.format != "phapcurrencycent") {
                            var plainNumber = elem.val().replace(/[^\d|\-+|\.+]/g, '');
                            if (plainNumber.split(".")[0] >= 9999999999) {
                                plainNumber = 9999999999;
                            }
                            ctrl.$viewValue = $filter(attrs.format)(plainNumber);
                            elem.val(ctrl.$viewValue);
                            var number = $filter('number')(plainNumber, 2)
                            ctrl.$setViewValue(number.toString().trim().replace(/,/g, '') * 1);
                        }
                    });
                }
            };
        }]);
})();