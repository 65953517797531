(function () {

    var app = angular.module('phapApp');

    app.controller('addNewMembersCtrl', ['$scope', 'securityManagementService', 'utils', 'data',
        function ($scope, securityManagementService, utils, data) {
            var vm = this;
            vm.searchPlaceholderText = 'Search Users';
            vm.popupLeftList = 'popupLeftList';
            vm.selectedUsers = [];
            var init = function () {
                securityManagementService.getUnAssignUsers(data.roleId).then(
                    function (data) {
                        vm.users = data.data;
                        vm.originalDataSet = angular.copy(vm.users);
                    });

            }

            vm.addSelectedRole = function (item) {
                var index = vm.selectedUsers.indexOf(item);
                if (index === -1)
                    vm.selectedUsers.push(item);
            }

            vm.close = function () {
                $scope.$dismiss({ $value: 'cancel' });
            }

            vm.deleteSelectedUsers = function (index) {
                if (index !== -1)
                    vm.selectedUsers.splice(index, 1);
            }

            vm.applyChanges = function () {
                var title = 'Warning';
                var content = "Saving will override the existing role for selected users and add users to this role";
                utils.confirm(title, content, 'Proceed', 'Cancel')
                    .then(function () {
                        vm.submitting = true;
                        vm.pageErrors = [];
                        securityManagementService.addMembers(data.roleId, vm.selectedUsers).then(
                            function () {
                                $scope.$close();
                            });
                    });
            }

            vm.disabledSave = function () {
                return vm.selectedUsers.length === 0;
            }

            init();
        }
    ]);

})();
