(function () {
    var app = angular.module('phapApp');

    app.component('addressSearch', {
        templateUrl: 'app/_components/addressSearch.html',
        bindings: {
            details: '=',
            form: '<',
            readonly: '<',
            citylabel: '@'
        },
        controller: ['$element', '$scope', 'utils', function ($element, $scope, utils) {

            var vm = this;


            vm.$onInit = function () {
                // Create google places autocomplete. Supply options that limit its suggestions.
                var targetElement = angular.element('#addressinput')[0];

                vm.gPlace =
                    new google.maps.places.Autocomplete(
                        targetElement,
                        {
                            types: ['address'],
                            componentRestrictions: {
                                country: "au"
                            }
                        }
                    );

                google.maps.event.addListener(vm.gPlace, 'place_changed', updatePlace);

                if (utils.isNullOrEmpty(vm.details) == false) {
                    vm.displayAddressLine2 = !utils.isNullOrEmpty(vm.details.addressLine2);
                    vm.displayAddressLine3 = !utils.isNullOrEmpty(vm.details.addressLine3);
                    vm.displayAddressLine4 = !utils.isNullOrEmpty(vm.details.addressLine4);
                }
            }

            vm.displayAddressLine = function (addressLine) {
                switch (addressLine) {
                    case 2:
                        vm.displayAddressLine2 = true;
                        break;
                    case 3:
                        vm.displayAddressLine3 = true;
                        break;
                    case 4:
                        vm.displayAddressLine4 = true;
                        break;
                }
            }

            // Called when the google places autocomplete's "place_changed" event fires
            var updatePlace = function () {
                if (!vm.gPlace) return;

                var selectedAddress = vm.gPlace.getPlace();
                var addressComponents = {};

                _.each(selectedAddress.address_components, function (c) {
                    _.each(c.types, function (t) {
                        addressComponents[t] = c;
                    });
                });

                if (vm.details === null) {
                    vm.details = {
                        id: 0,
                        unitNo: "",
                        streetNo: "",
                        streetName: "",
                        suburb: "",
                        postcode: ""
                    };
                }

                $scope.$apply(function () {
                    var unitNo = getFieldFromGoogleAddressComponents(addressComponents, "subpremise");
                    var streetNo = getFieldFromGoogleAddressComponents(addressComponents, "street_number");
                    var streetName = getFieldFromGoogleAddressComponents(addressComponents, "route");                    
                    var addressLine1 = [];
                    vm.details.suburb = getFieldFromGoogleAddressComponents(addressComponents, "locality");
                    vm.details.postcode = getFieldFromGoogleAddressComponents(addressComponents, "postal_code");
                    vm.details.state = getFieldFromGoogleAddressComponents(addressComponents, "administrative_area_level_1")
                    vm.details.city = getFieldFromGoogleAddressComponents(addressComponents, "administrative_area_level_2");

                    vm.details.addressLine1 = '';
                    if (utils.isNullOrEmpty(unitNo) === false) {
                        addressLine1.push(unitNo);
                    }
                    if (utils.isNullOrEmpty(streetNo) === false) {
                        addressLine1.push(streetNo);
                    }
                    if (utils.isNullOrEmpty(streetName) === false) {
                        addressLine1.push(streetName);
                    }
                    vm.details.addressLine1 = addressLine1.join(" ");
                    //clearing out GPO address
                    vm.details.addressLine2 = '';
                    vm.details.addressLine3 = '';
                    vm.details.addressLine4 = '';
                    vm.displayAddressLine2 = false;
                    vm.displayAddressLine3 = false;
                    vm.displayAddressLine4 = false;
                    vm.form.$setDirty();
                });

            }

            function getFieldFromGoogleAddressComponents(addressComponents, fieldName) {
                var entry = addressComponents[fieldName];

                if (!entry) return null;

                return entry.long_name;
            };
        }],
        controllerAs: 'address'
    });

})();