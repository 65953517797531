(function () {
    angular.module('phapApp')
        .directive('essentialHousehold', ['Constants', 'utils', function (Constants, utils) {
            return {
                scope: true,
                restrict: 'E',
                templateUrl: 'app/home/reestablish/_essential-household.html',
                controller: ['$scope', 'reestablishService', function ($scope, reestablishService) {

                    $scope.uploadController = {};

                    // #region Business Rule checking

                    $scope.$watchCollection('[item.essentialHouseholdTotalAmount, item.essentialHouseholdEvidenceAttached]', function (newValues) {
                        if (!angular.isDefined(newValues[0]))
                            return;
                        $scope.essentialHouseholdBusinssRules();
                    });

                    $scope.$on('dataLoaded', function () {
                        $scope.essentialHouseholdBusinssRules();
                    });

                    $scope.essentialHouseholdBusinssRules = function () {
                        var amount = utils.parseDollarAmount($scope.item.essentialHouseholdTotalAmount);
                        $scope.formSections.categoriesOfAssessment.subMenu.essentialHousehold.evidenceRequired = (amount > 0 && $scope.item.essentialHouseholdEvidenceAttached === false); 
                        reestablishService.setExceptionalCircumstances($scope.formSections, $scope.item);
                    };

                    // #endregion 

                    var essentialHouseholdVarifyDocumentsAttached = function () {
                        if ($scope.item && $scope.item.essentialHouseholdEvidenceAttached) {
                            if (noDocumentsAttached()) {
                                confirm();
                            }
                        }
                    };

                    var noDocumentsAttached = function () {
                        if ($scope.item && $scope.item.documents) {
                            var documents = $scope.item.documents[Constants.reestablishSections.essentialHousehold];
                            return documents == null || documents.length == 0;
                        }
                        return false;
                    };

                    var confirm = function () {
                        var title = 'Evidence documents required!';
                        var content = 'Evidence attached has been selected as Yes and no attachments have been loaded. \nDocuments must be loaded in order to set the Evidence attached field to Yes.';
                        utils.confirm(title, content, 'Proceed', 'Cancel')
                            .then(function () {
                                $scope.item.essentialHouseholdEvidenceAttached = false;
                            }, function () {
                                $scope.item.essentialHouseholdEvidenceAttached = null;
                            });
                    };

                    $scope.checkEvidence = function () {
                        if (noDocumentsAttached()) {
                            $scope.uploadController.$newUpload();
                        }
                    };

                    $scope.onCancelUpdate = function () {
                        essentialHouseholdVarifyDocumentsAttached();
                    };

                    $scope.onFileListChanged = function () {
                        essentialHouseholdVarifyDocumentsAttached();
                    };

                    $scope.maxEssentialHouseholdAssistance = null;
                    $scope.$watch('item.incident.effectiveDate', function (nv, ov) {
                        if (nv != ov && nv != null && ($scope.item.essentialHouseholdItems == null || $scope.item.essentialHouseholdItems.length == 0)) {
                            reestablishService.loadPeriodConfig(nv).then(function (data) {
                                if ($scope.item.essentialHouseholdItems == null || $scope.item.essentialHouseholdItems.length == 0) {
                                    if (data.essentialHouseholdItems) {
                                        $scope.maxEssentialHouseholdAssistance = data.essentialHouseholdItems;
                                        $scope.item.essentialHouseholdItems = angular.copy($scope.maxEssentialHouseholdAssistance);
                                        $scope.item.essentialHouseholdTotalAmount = 0;
                                    }
                                }
                               });
                        }
                    });

                    $scope.onQtyChanged = function (item) {
                        if (item && $scope.item && $scope.item.essentialHouseholdItems) {
                            var total = 0;
                            angular.forEach($scope.item.essentialHouseholdItems, function (v, k) {
                                v.amount = (v.max || 0) * (v.quantity || 0);
                                total += v.amount;
                            });
                            $scope.item.essentialHouseholdTotalAmount = total;
                        }
                    };                    
                }]
            };
        }]);
})();