(function () {
	angular.module('phapApp')
		.filter('startsWith', function () {
			return function (items, letter, param1, param2, param3) {
				return _.filter(items, function (i) {
					var parameter1 = i[param1] ? i[param1] : "";
					var parameter2 = i[param2] ? ' ' + i[param2] : "";
					var stringToCompare = parameter1 + parameter2;
					var res = (stringToCompare).toLowerCase().indexOf(letter.toLowerCase(), 0) == 0;
					if (i.isActive != undefined) {
						return (stringToCompare).toLowerCase().indexOf(letter.toLowerCase(), 0) == 0 && i.isActive == true;
					}
					else {
						return (stringToCompare).toLowerCase().indexOf(letter.toLowerCase(), 0) == 0;
					}
				})
			};
        });

    angular.module('phapApp')
        .filter('capitalize', function () {
            return function (input) {
                return (!!input) ? input.charAt(0).toUpperCase() + input.substr(1).toLowerCase() : '';
            }
        });
})();



