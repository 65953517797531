(function () {
    var app = angular.module('phapApp');

    app.service('userService', ['$http', '$q', 'config', 'security', 'Constants', function ($http, $q, config, security, constants) {

        var getUserTasks = function () {
            var deferred = $q.defer();
            var result = $http.get(config.apiBaseUrl + "user/task-list")
                .then(function (data, status, headers, config) {
                    deferred.resolve(data);
                }, function (data, status, headers, config) {
                    deferred.reject(data);
                });
            return deferred.promise;
        }

        return {
            getUserTasks: getUserTasks
        }
    }]);

})();