(function () {

    var app = angular.module('phapApp');

    app.controller('addNewPermissionCtrl', ['$scope', 'securityManagementService', 'utils', 'data',
        function ($scope, securityManagementService, utils, data) {
            var vm = this;
            vm.searchPlaceholderText = 'Search Permissions';
            vm.popupLeftList = 'popupLeftList';
            vm.selectedPermissions = [];
            var init = function () {
                securityManagementService.getUnAssignPermissions(data.roleId).then(
                    function (data) {
                        vm.permissions = data.data;
                        vm.originalDataSet = angular.copy(vm.permissions);
                    });
                
            }

            vm.addSelectedRole = function (item) {
                var index = vm.selectedPermissions.indexOf(item);
                if (index === -1)
                    vm.selectedPermissions.push(item);
            }

            vm.close=function() {
                $scope.$dismiss({ $value: 'cancel' });
            }

            vm.deleteSelectedPermission = function (index) {
                if (index !== -1)
                    vm.selectedPermissions.splice(index, 1);    
            }

            vm.applyChanges=function() {
                var title = 'Warning';
                var content = "Saving will commit all changes made on this page";
                utils.confirm(title, content, 'Proceed', 'Cancel')
                    .then(function() {
                        vm.submitting = true;
                        vm.pageErrors = [];
                        securityManagementService.addRolePermissions(data.roleId, vm.selectedPermissions).then(
                            function() {
                                $scope.$close();
                            });
                    });
            }

            vm.disabledSave=function() {
                return vm.selectedPermissions.length == 0;
            }

            init();
        }
    ]);

})();
