(function () {

	var app = angular.module('phapApp');

	app.component('apErrorSearch', {
		templateUrl: 'app/errorHandling/apSearch.html',

		controller: ['$scope', '$routeParams', '$timeout', '$cookies', '$uibModal', '$location', 'notificationService', 'navigationService', 'errorHandlingService', 'uiGridConstants', 'localStorageService', 'utils', '$interval', 'Constants', 'security', 'cacheService',
			function ($scope, $routeParams, $timeout, $cookies, $uibModal, $location, notificationService, navigationService, errorHandlingService, uiGridConstants, localStorageService, utils, $interval, Constants, security, cacheService) {

				navigationService.setCurrentMenuStatus(
					'errorHandling', 'apErrorHandling');

				var vm = this;
				vm.status = Constants.TransactionStatus;

				vm.exportToCsv = function () {
					vm.exporting = true;
					vm.beforeSearch();
					errorHandlingService.exportAPSearch(vm.formattedSearch).then(
						function (response) {
							vm.exporting = false;
							var file = new Blob([response.data], { type: 'text/csv' });
							saveAs(file, 'APSearchList.csv');
						},
						function (response) {
							vm.exporting = false;
						});

				};
				vm.clearFilters = function () {
					vm.search = { includeArchived: false };
					vm.dateOptionsTo.minDate = null;
					vm.dateOptionsFrom.maxDate = new Date();
					vm.gridOptions.data = [];
					if (vm.gridApi && vm.gridApi.selection) {
						vm.gridApi.selection.clearSelectedRows();
					}
					vm.filterVisible = true;
                    cacheService.removeItemFromCache('apSearchFilters.' + $cookies.get("UserId"));
				};
				vm.saveFilters = function () {
					cacheService.saveToCache('apSearchFilters.' + $cookies.get("UserId"), vm.search);
				};

				vm.retrieveFilters = function () {
					return cacheService.readFromCache('apSearchFilters.' + $cookies.get("UserId"));
                };

				vm.loadSavedFilters = function () {
					var savedData = vm.retrieveFilters();
					if (savedData) {
						vm.search = savedData;
					} else {
						vm.clearFilters();
					}
				}
				vm.setData = function (data) {
					vm.results = data;
					vm.gridOptions.data = angular.copy(vm.results);
					vm.noResultsFound = vm.results.length == 0;
				};

				vm.beforeSearch = function () {
					vm.formattedSearch = [];
					vm.formattedSearch = angular.copy(vm.search);
					if (vm.search.fromDate)
						vm.formattedSearch.fromDate = vm.formattedSearch.fromDate.getFullYear() + '-' + (vm.formattedSearch.fromDate.getMonth() + 1) + '-' + vm.formattedSearch.fromDate.getDate();
					if (vm.search.toDate)
						vm.formattedSearch.toDate = vm.formattedSearch.toDate.getFullYear() + '-' + (vm.formattedSearch.toDate.getMonth() + 1) + '-' + vm.formattedSearch.toDate.getDate();
				};

				vm.getSearchResults = function () {
					vm.loaded = false;
					vm.beforeSearch();
					vm.searching = true;
					vm.saveFilters();
					errorHandlingService.getApErrorSearch(vm.formattedSearch).then(
						function (result) {
							vm.loaded = true;
							vm.searching = false;
							vm.results = [];
							vm.setData(result.data)
						},
						function (result) {
							vm.loaded = true;
							vm.searching = false;
						}
					);
				};



				var getGridOptions = function () {
					return {
						enableGridMenu: true,
						enableSorting: true,
						enableFiltering: false,
						enableColumnResizing: true,
						enableHorizontalScrollbar: uiGridConstants.scrollbars.ALWAYS,
						enableVerticalScrollbar: uiGridConstants.scrollbars.ALWAYS,
						enableRowSelection: true,
						enableFullRowSelection: true,
						enableRowHeaderSelection: false,
						enableSelectAll: false,
						multiSelect: false,
						enableRowHashing: false,
						columnDefs: getGridColumnDefs(),
						appScopeProvider: {
							openItem: function (row) {
								openItem(row);
							}
						},
						rowStyle: function (row) {
							return 'status-' + row.entity.status + '-class';
						},
						rowTemplate:
						'<div ng-click="grid.appScope.selectRow();" ng-class="grid.options.rowStyle(row)"> ' +
						'<div class="ui-grid-cell" ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" ui-grid-cell ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader}" style="cursor:pointer"> ' +
						'</div>' +
						'</div>',
						onRegisterApi: function (gridApi) {
							vm.gridApi = gridApi;
							vm.gridApi.core.on.rowsRendered($scope, rowsRendered);
							vm.gridApi.colResizable.on.columnSizeChanged($scope, saveState);
							vm.gridApi.core.on.columnVisibilityChanged($scope, saveState);
							vm.gridApi.core.on.filterChanged($scope, saveState);
							vm.gridApi.core.on.sortChanged($scope, saveState);
							// call resize every 500 ms for 5 s after modal finishes opening - usually only necessary on a bootstrap modal
							$interval(function () {
								vm.gridApi.core.handleWindowResize();
							}, 500, 10);
							// Restore previously saved state.
							restoreState();
						}
					}
				};

				function saveState() {
					var state = vm.gridApi.saveState.save();
					localStorageService.set('apSearch.gridState.' + $cookies.get("UserId"), state);
				}

				function restoreState() {
					$timeout(function () {
						var state = localStorageService.get('apSearch.gridState.' + $cookies.get("UserId"));
						if (state) vm.gridApi.saveState.restore($scope, state);
					});
				}

				var rowsRendered = function () {

					vm.filteredRecords = vm.gridApi.core.getVisibleRows(vm.gridApi.grid).length;
					if ($routeParams.orderNumber && vm.gridOptions.data.length > 0) {
						vm.gridApi.selection.selectAllVisibleRows();
					}
					else {
						vm.gridApi.selection.clearSelectedRows();
					}
				};

				var getGridColumnDefs = function () {
					return [
						{
							field: 'batchNumber',
							displayName: 'Batch number',
							visible: true,
							enableHiding: false,
							enableColumnMenu: false,
							cellTemplate: '<div class="ui-grid-cell-contents">' +
							'<a href="" ng-click="grid.appScope.openItem(row.entity.batchNumber)">{{row.entity.batchNumber}}</a>' +
                            '</div>',
                            width: '12%'
						},
						{
							field: 'replacementBatchId',
							displayName: 'Replacement batch number',
							visible: true,
							enableHiding: false,
							enableColumnMenu: false,
							cellTemplate: '<div class="ui-grid-cell-contents">' +
							'<a href="" ng-click="grid.appScope.openItem(row.entity.replacementBatchId)">{{row.entity.replacementBatchId}}</a>' +
                            '</div>',
                            width: '12%'
						},
						{
							field: 'date',
							displayName: 'Date',							
							enableHiding: true,
							enableColumnMenu: false,
							cellClass: 'date-field',
                            cellFilter: Constants.GridDateFormat,
                            width: '10%'
						},
						{
							field: 'status',
							displayName: 'Status',
							enableHiding: false,
                            enableColumnMenu: false,
                            width: '12%'
						},
						{
							field: 'vendorName',
							displayName: 'Vendor',
							enableHiding: true,
                            enableColumnMenu: false,
                            width: '12%'
						},
						{
							field: 'vendorId',
							displayName: 'Vendor ID',
							enableHiding: true,
                            enableColumnMenu: false,
                            width: '10%'
						},
						{
							field: 'paymentId',
							displayName: 'Payment ID',
							enableHiding: true,
                            enableColumnMenu: false,
                            width: '10%'
						},
						{
							field: 'rejectionReason',
							displayName: 'Rejection reason',
							enableHiding: true,
                            enableColumnMenu: false,
                            width: '12%'
						},
						{
							field: 'amount',
							displayName: 'Amount',
							cellFilter: 'currency',
							enableHiding: false,
							cellClass: 'currency',
                            enableColumnMenu: false,
                            width: '10%'
						}
					]
				};

				var openItem = function (batchNumber) {
					$location.path('/errorHandling/ap/details/' + batchNumber)
				};

				vm.setToMindate = function () {
					vm.dateOptionsTo.minDate = new Date(vm.search.fromDate);
				};

				vm.setFromMaxdate = function () {
					vm.dateOptionsFrom.maxDate = new Date(vm.search.toDate);
				};

				vm.dateOptionsFrom = {
					maxDate: new Date(),
					dateFormat: Constants.DatePickerFormat
				};

				vm.dateOptionsTo = {
					maxDate: new Date(),
					dateFormat: Constants.DatePickerFormat
				};

				var checkPermission = function () {
					security.getCurrentUserSecurityInfo().then(
						function (result) {
							vm.currentUserSecurityInfo = result;
							vm.hasPermission = result.privileges.ViewPhapSystemMonitoring;
							vm.hasResetPermission = result.privileges.EditPhapSystemMonitoring;
							if (vm.hasPermission) {
								vm.loadSavedFilters();
								vm.getSearchResults();
							}
						},
						function () {
							vm.currentUserSecurityInfo = {};
						})
				};

				vm.$onInit = function () {
					vm.loaded = true;
					vm.searching = false;
					vm.noResultsFound = false;
					vm.exporting = false;
					vm.hasPermission = true;
					vm.gridOptions = getGridOptions();

					vm.gridOptions.data = [];
					vm.results = [];
					vm.search = { includeArchived: false };
					checkPermission();
				};
			}],
		controllerAs: 'apErrorVM'
	});

})();