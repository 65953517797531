(function () {
    var app = angular.module('phapApp');

    app.filter('vendorstatus', function () {
        return function (input) {
            var filterText = '';
            switch (input) {
                case 0:
                    filterText = 'Pending update';
                    break;
                case 1:
                    filterText = 'Active';
                    break;
                case 2:
                    filterText = 'Inactive';
                    break;
                case 3:
                    filterText = 'Exception';
                    break;
                default:
                    filterText = 'Pending update';
            }

            return filterText;
        };
    });

})();