(function () {
	var app = angular.module('phapApp');

    app.factory('navigationService', ['$http', 'security', '$q', 'Constants', 'config', '$location', 'dashboardService', 'configurationService','notificationService',
        function ($http, security, $q, Constants, config, $location, dashboardService, configurationService, notificationService) {
			var service = this;
            var priviliges = {};

			var currentMenuStatus = {
				currentSectionCode: '',
				currentPageCode: null,
				currentLeftSubMenuCode: null
			}

            service.topMenuCodes = {
                onlineApplicationManagement: 'onlineApplicationManagement',
				emergencyManagement: 'emergencyManagement',
                financeManagement: 'financeManagement',
                administration: 'administration',
                errorHandling: 'errorHandling'
            }

            service.pageCodes = {
                onlineApplicationManagement: 'onlineApplicationManagement',
				administrationIncidents: 'administrationIncidents',
				reliefApplications: 'reliefApplications',
				reestablishApplications: 'reestablishApplications',
				vendorManagement: 'vendorManagement',
                cardShipment: 'cardShipment',
                cardShipment2: 'cardShipment2',
                cardSearch: 'cardSearch',
				chequeSearch: 'chequeSearch',
                cardActivity: 'cardActivity',
                cardActivitySearch: 'cardActivitySearch',
                cardStocktakeSearch: 'cardStocktakeSearch',
				financedashboard: 'financedashboard',
				administration: 'administration',
				administrationconfiguration: 'administrationconfiguration',
                userRoleManagement: 'userrolemanagement',
                directDeposit: 'directDeposit',
                errorHandling: 'errorHandling',
                apErrorHandling: 'apErrorHandling',
                apErrorHandlingDetails: 'apErrorHandlingDetails',
                glErrorHandling: 'glErrorHandling',
                glErrorHandlingDetails: 'glErrorHandlingDetails',
                fmReportMenu: 'fmReportMenu',
                emReportMenu: 'emReportMenu'
			}

			service.getCurrentMenuStatus = function () {
				return currentMenuStatus;
            }

            service.menu = [];

            service.reportLinks = [];

			var configurationMenu = {
				general: {
					id: 'generalList', sectionName: 'General', expanded: true, showNav: true, pageState: Constants.pageState.configurationList, preventCollapse: true, security: 'UpdateGeneralAdmin',
					subMenu: {
						gender: {
							id: Constants.configurationMenuIds.general.gender, sectionName: 'Gender', expanded: true, pageState: Constants.pageState.configurationList, showNav: true, url: '/administration/configuration/' + Constants.configurationMenuIds.general.gender
						},
						idTypes: { id: Constants.configurationMenuIds.general.idTypes, sectionName: 'Member Id type', expanded: true, pageState: Constants.pageState.configurationList, showNav: true, url: '/administration/configuration/' + Constants.configurationMenuIds.general.idTypes }
					}
				},
				location: {
                    id: 'locationList', sectionName: 'Locations', expanded: true, showNav: true, pageState: Constants.pageState.configurationList, preventCollapse: true, security: 'UpdateLocationAndRegionsAdmin',
					subMenu: {
						lga: { id: Constants.configurationMenuIds.location.lga, sectionName: 'Local government area', expanded: true, pageState: Constants.pageState.configurationList, showNav: true, url: '/administration/configuration/' + Constants.configurationMenuIds.location.lga },
                        rc: { id: Constants.configurationMenuIds.location.rc, sectionName: 'Office locations and relief centres', expanded: true, pageState: Constants.pageState.configurationList, showNav: true, url: '/administration/configuration/' + Constants.configurationMenuIds.location.rc },
                        region: { id: Constants.configurationMenuIds.location.region, sectionName: 'DHHS regions', expanded: true, pageState: Constants.pageState.configurationList, showNav: true, url: '/administration/configuration/' + Constants.configurationMenuIds.location.region },
                        division: { id: Constants.configurationMenuIds.location.division, sectionName: 'DHHS divisions', expanded: true, pageState: Constants.pageState.configurationList, showNav: true, url: '/administration/configuration/' + Constants.configurationMenuIds.location.division }
					}
				},
				incidentList: {
					id: 'incidentList', sectionName: 'Incident', expanded: true, showNav: true, pageState: Constants.pageState.configurationList, preventCollapse: true, security: 'UpdateIncidentAdmin',
					subMenu: {
						incidentType: { id: Constants.configurationMenuIds.incidentList.incidentType, sectionName: 'Incident category', expanded: true, pageState: Constants.pageState.configurationList, showNav: true, url: '/administration/configuration/' + Constants.configurationMenuIds.incidentList.incidentType },
						emergencyType: { id: Constants.configurationMenuIds.incidentList.emergencyType, sectionName: 'Emergency type', expanded: true, pageState: Constants.pageState.configurationList, showNav: true, url: '/administration/configuration/' + Constants.configurationMenuIds.incidentList.emergencyType },
						incidentStatus: { id: Constants.configurationMenuIds.incidentList.incidentStatus, sectionName: 'Incident status', expanded: true, pageState: Constants.pageState.configurationList, showNav: true, url: '/administration/configuration/' + Constants.configurationMenuIds.incidentList.incidentStatus }
					}
				},
				reliefApplication: {
					id: 'reliefApplication', sectionName: 'Relief Applications', expanded: true, showNav: true, pageState: Constants.pageState.configurationList, preventCollapse: true, security: 'UpdateReliefAdmin',
					subMenu: {
						immediateReliefRequirements: { id: Constants.configurationMenuIds.reliefApplication.immediateReliefRequirements, sectionName: 'Immediate relief requirements', expanded: true, pageState: Constants.pageState.configurationList, showNav: true, url: '/administration/configuration/' + Constants.configurationMenuIds.reliefApplication.immediateReliefRequirements },
						ReliefAssistanceAmount: { id: Constants.configurationMenuIds.reliefApplication.reliefAssistanceAmount, sectionName: 'Relief assistance amount', expanded: true, pageState: Constants.pageState.configurationList, showNav: true, url: '/administration/configuration/' + Constants.configurationMenuIds.reliefApplication.reliefAssistanceAmount }
					}
				},
				reEstablishment: {
					id: 'reEstablishment', sectionName: 'Re-establishment Details', expanded: true, showNav: true, pageState: Constants.pageState.configurationList, preventCollapse: true, security: 'UpdateReestablishAdmin',
					subMenu: {
						principlePlaceOfResidence: { id: Constants.configurationMenuIds.reEstablishment.principlePlaceOfResidence, sectionName: 'Document Id type', expanded: true, pageState: Constants.pageState.configurationList, showNav: true, url: '/administration/configuration/' + Constants.configurationMenuIds.reEstablishment.principlePlaceOfResidence },
						maxEssentialHouseHold: { id: Constants.configurationMenuIds.reEstablishment.maxEssentialHouseHold, sectionName: 'Maximum essential houseHold', expanded: true, pageState: Constants.pageState.configurationList, showNav: true, url: '/administration/configuration/' + Constants.configurationMenuIds.reEstablishment.maxEssentialHouseHold },
						reEstablishmentAmount: { id: Constants.configurationMenuIds.reEstablishment.reEstablishmentAmount, sectionName: 'Initial Re-establishment Payment', expanded: true, pageState: Constants.pageState.configurationList, showNav: true, url: '/administration/configuration/' + Constants.configurationMenuIds.reEstablishment.reEstablishmentAmount },
						reEstablishmentAssistanceAmount: { id: Constants.configurationMenuIds.reEstablishment.reEstablishmentAssistanceAmount, sectionName: 'Re-establishment assistance amount', expanded: true, pageState: Constants.pageState.configurationList, showNav: true, url: '/administration/configuration/' + Constants.configurationMenuIds.reEstablishment.reEstablishmentAssistanceAmount }
					}
				},
				finance: {
				    id: 'finance', sectionName: 'Finance', expanded: true, showNav: true, pageState: Constants.pageState.configurationList, preventCollapse: true, security: 'UpdateGeneralAdmin',
				    subMenu: {}
				}
            };

			var userRoleMenu = {
				role: {
					id: Constants.userRoleMenuId.role, sectionName: 'Role management', expanded: true, pageState: Constants.pageState.userRoleManagement, showNav: true, url: '/administration/security/' + Constants.userRoleMenuId.role, security: 'UpdateRolesAndPrivileges'
                },
				user: {
                    id: Constants.userRoleMenuId.user, sectionName: 'User management', expanded: true, pageState: Constants.pageState.userRoleManagement, showNav: true, url: '/administration/security/' + Constants.userRoleMenuId.user, security: 'UpdateRolesAndPrivileges'
				}
            }

            var fmReportMenu = null;

            var emReportMenu = null;

			var subMenus = {
				reliefMenu: { menuName: "Application", menu: {} },
				reestablishMenu: { menuName: "Application", menu: {} },
				configurationMenu: { menu: configurationMenu },
                userRoleMenu: { menu: userRoleMenu },
                fmReportMenu: { menu: fmReportMenu },
                emReportMenu: { menu: emReportMenu }
			};
            
			var menuReference = {
                sections: [
                    {
                        code: service.topMenuCodes.onlineApplicationManagement,
                        label: 'Online application management',
                        url: '/home/online-application-management',
                        showSubMenu: false,
                        defaultPageCode: service.pageCodes.onlineApplicationManagement,
                        pages: [
                            {
                                code: service.pageCodes.onlineApplicationManagement,
                                url: '/home/online-application-management',
                                label: 'Queues',
                                showSubMenu: false,
                                collapseMenu: function () { return true; }
                            }
                        ]
                    },
                    {
						code: service.topMenuCodes.emergencyManagement,
						label: 'Emergency management',
						url: '/administration/incidents',
						defaultPageCode: service.pageCodes.administrationIncidents,
						pages: [
							{
								code: service.pageCodes.administrationIncidents,
								url: '/administration/incidents',
								label: 'Incidents',
								security: 'SearchIncident'
							},
							{
								code: service.pageCodes.reliefApplications,
								url: '/home/relief',
								label: 'Relief',
								showSubMenu: false,
								subMenu: subMenus.reliefMenu,
								collapseMenu: service.reliefMenuCollapse,
								subMenuClass: 'relief'
							},
							{
								code: service.pageCodes.reestablishApplications,
								url: '/home/reestablish/search',
								label: 'Re-establishment',
								security: 'SearchReestablish',
								showSubMenu: false,
								subMenu: subMenus.reestablishMenu,
								collapseMenu: service.reestablishMenuCollapse,
								subMenuClass: 'reestablish'
							},
                            {
                                code: service.pageCodes.emReportMenu,
                                url: '',
                                label: 'Reports',
                                showSubMenu: true,
                                subMenu: subMenus.emReportMenu,
                                collapseMenu: true,
                                security: 'Reports'
                            }
						]
					},
					{
						code: service.topMenuCodes.financeManagement,
						label: 'Finance management',
						url: '/financeManagement/dashboard',
						defaultPageCode: service.pageCodes.financedashboard,
						pages: [
							{
								code: service.pageCodes.financedashboard,
								url: '/financeManagement/dashboard',
								label: 'Order shipment tracking'
							},
                            {
                                code: service.pageCodes.cardSearch,
                                url: '/financeManagement/cards/search',
                                label: 'Cards management',
                                security: 'SearchCards'
                            }, 
                            {
								code: service.pageCodes.cardShipment2,
								url: '/financeManagement/cardShipment/search',
								label: 'Card shipments',
                                security: 'SearchCardShipmentOrder'
							},
                            {
                                code: service.pageCodes.cardActivitySearch,
                                url: '/financeManagement/cards/cardActivitySearch',
                                label: 'Card activities',
                                security: 'SearchActivityRequestOrder'
							},
                            {
                                code: service.pageCodes.cardStocktakeSearch,
                                url: '/financeManagement/cards/stocktakeSearch',
                                label: 'Card stocktakes',
                                security: 'CardStocktake'
                            },
                            {
								code: service.pageCodes.chequeSearch,
								url: '/financeManagement/cheques/chequeSearch',
								label: 'Cheque search',
								security: 'SearchCheques'
                            },
                            {
                                code: service.pageCodes.directDeposit,
                                url: '/financeManagement/directDeposit',
                                label: 'Direct deposit search',
                                security: 'SearchDirectDeposits'
                            },
							{
								code: service.pageCodes.vendorManagement,
								url: '/home/vendor/search',
								label: 'Vendors',
								security: 'SearchVendor'
                            },
                            {
                                code: service.pageCodes.fmReportMenu,
                                url: '',
                                label: 'Reports',
                                showSubMenu: true,
                                subMenu: subMenus.fmReportMenu,
                                collapseMenu: true,
                                security: 'Reports'
                            }
						]
                    },
					{
						code: service.topMenuCodes.administration,
						label: 'Administration',
						url: '/administration/configuration/gender',
						defaultPageCode: 'administrationconfiguration',
						pages: [
							{
								code: service.pageCodes.administrationconfiguration,
								label: 'Configurable Lists',
								showSubMenu: true,
								subMenu: subMenus.configurationMenu,
								collapseMenu: service.configMenuCollapse
							},
							{
								code: service.pageCodes.userRoleManagement,
								showSubMenu: true,
								url: '/administration/security/roleManagement',
								label: 'Security',
								subMenu: subMenus.userRoleMenu
							}
						]
                    },
                    {
                        code: service.topMenuCodes.errorHandling,
                        label: 'PHAP system monitoring',
                        url: '/errorHandling/ap/',
                        defaultPageCode: 'errorHandling',
                        pages: [
                            {
                                code: service.pageCodes.apErrorHandling,
                                url: '/errorHandling/ap',
                                label: 'Oracle AP batch search',
                                security: 'ViewPhapSystemMonitoring'
                            },
                            {
                                code: service.pageCodes.apErrorHandlingDetails,
                                url: '/errorHandling/ap/details',
                                label: 'Oracle AP transaction search',
                                security: 'ViewPhapSystemMonitoring'
                            },
                            {
                                code: service.pageCodes.glErrorHandling,
                                url: '/errorHandling/gl',
                                label: 'Oracle GL batch search',
                                security: 'ViewPhapSystemMonitoring'
                            },
                            {
                                code: service.pageCodes.glErrorHandlingDetails,
                                url: '/errorHandling/gl/details',
                                label: 'Oracle GL transaction search',
                                security: 'ViewPhapSystemMonitoring'
                            }
                        ]
                    }
				]
            };

            service.subMenus = subMenus;

			function filterMenuItems(privileges) {
				service.menu.sections = [];
				menuReference.sections.forEach(function (section) {
					var menuSection = {
						code: section.code,
						label: section.label,
						url: section.url,
						defaultPageCode: section.defaultPageCode,
						pages: []
					};
					section.pages.forEach(function (page) {
						// no security so just add the page
						if (!page.security && hasMenuItems(page)) {
							menuSection.pages.push(page);
						} else {
							// has security so check if the user has the correct privilege
							if (privileges[page.security] && hasMenuItems(page)) {
								menuSection.pages.push(page);
							}
						}
                    })

                    if (menuSection.pages.length > 0) {
						service.menu.sections.push(menuSection);
					}
				})
			}

            function hasMenuItems(page) {
                // Some pages should return true here as they can have submenus but may not always have them.
                if (page.code == service.pageCodes.reliefApplications ||
                    page.code == service.pageCodes.reestablishApplications ||
                    page.code == service.pageCodes.fmReportMenu ||
                    page.code == service.pageCodes.emReportMenu) {
                    return true;
                }

				if (!page.subMenu || page.subMenu.menu === {}) return true;
				// check if there is no security property (it means anyone can access) or they have the privilege
				var containsMenuItems = _.some(page.subMenu.menu, function (value) {
					return (value.security == undefined || priviliges[value.security] !== undefined);
				});

				return containsMenuItems;
			}

			function getMenu() {
				var deferred = $q.defer();
                if (service.menu.length === 0) {
					security.getCurrentUserSecurityInfo().then(function (result) {
                        priviliges = result.privileges;
                        filterMenuItems(result.privileges);
                        if (result.privileges[Constants.Privileges.Reports]) {
                            getReportMenu().then(function (result) {
                                deferred.resolve(service.menu);
                            });
                        } else {
                            deferred.resolve(service.menu);
                        }
					}, function (data, status, headers, config) {
						deferred.reject(data);
					});
				} else {
					deferred.resolve(service.menu);
				}

				//get configuration menus
				configurationService.getReestablishmentConfigurations(service.menu).then(function (result) {
                    result.forEach(function (value, i) {
                        if (configurationMenu[value.section] && configurationMenu[value.section].subMenu) {
                            configurationMenu[value.section].subMenu[value.key] = {};
                            configurationMenu[value.section].subMenu[value.key].id = value.key;
                            configurationMenu[value.section].subMenu[value.key].sectionName = value.description;
                            configurationMenu[value.section].subMenu[value.key].expanded = true;
                            configurationMenu[value.section].subMenu[value.key].pageState = Constants.pageState.configurationList;
                            configurationMenu[value.section].subMenu[value.key].showNav = true;
                            configurationMenu[value.section].subMenu[value.key].url = '/administration/configuration/' + value.key;
                        }
					})
				});

				return deferred.promise;
			}

            var getReports = function() {
                var deferred = $q.defer();
                var res = $http.get(config.apiBaseUrl + 'report/report-list')
                    .then(function (response, status, headers, config) {
                        deferred.resolve(response.data);
                    }, function (data, status, headers, config) {
                        deferred.reject(data);
                    });

                return deferred.promise;
            }

            var getReportMenu = function () {
                var deferred = $q.defer();
                if (service.reportLinks.length == 0) {
                    getReports().then(
                        function (response) {
                            service.reportLinks = response;
                            buildReportSubMenus();
                            deferred.resolve(service.reportLinks);
                        },
                        function (data) {
                            deferred.reject(data);
                        }
                    );
                }
                else {
                    buildReportSubMenus();
                    deferred.resolve(service.reportLinks);
                }
                return deferred.promise;
            }

            var buildReportSubMenus = function()
            {
                if (service.subMenus.fmReportMenu.menu == null || service.subMenus.emReportMenu.menu == null)
                {
                    service.subMenus.fmReportMenu.menu = {};
                    service.subMenus.emReportMenu.menu = {};

                    service.reportLinks.forEach(function (report) {
                        var reportNameNoSpace = report.name.replace(/\s+/g, '');
                        var reportId = 'report' + report.id;

                        // Add page code for each report
                        service.pageCodes[reportId] = reportNameNoSpace;

                        // Add report submenu entries
                        if (report.type == Constants.reportTypes.financeManagement) {
                            service.subMenus.fmReportMenu.menu[reportNameNoSpace] = {
                                id: reportId,
                                sectionName: report.name,
                                expanded: true,
                                showNav: true,
                                url: '/reports/' + report.id
                            };
                        }
                        else if (report.type == Constants.reportTypes.emergencyManagement) {
                            service.subMenus.emReportMenu.menu[reportNameNoSpace] = {
                                id: reportId,
                                sectionName: report.name,
                                expanded: true,
                                showNav: true,
                                url: '/reports/' + report.id
                            };
                        }

                    });
                }
            }

            service.setCurrentMenuStatus = function (topMenuCode, leftMenuCode, leftMenuSubCode) {
                if (leftMenuCode == service.pageCodes.fmReportMenu || leftMenuCode == service.pageCodes.emReportMenu)
                {
                    // Ensure report menu is expanded when we are on a report page.
                    _.find(
                        _.find(service.menu.sections, function (r) { return r.code == topMenuCode }).pages,
                        function (p) { return p.code == leftMenuCode }
                    ).collapseMenu = false;
                };

                currentMenuStatus = {
                    currentSectionCode: topMenuCode,
                    currentPageCode: leftMenuCode,
                    currentLeftSubMenuCode: leftMenuSubCode
                };

                notificationService.notify('clickTopMenu', {
                    sectionCode: topMenuCode,
                    pageCode: leftMenuCode,
                    leftSubMenuCode: leftMenuSubCode
                });
            }

			return {
				getMenu: getMenu,
                subMenus: subMenus,
				currentSectionCode: currentMenuStatus.currentSectionCode,
				getCurrentMenuStatus: service.getCurrentMenuStatus,
                setCurrentMenuStatus: service.setCurrentMenuStatus,
				topMenuCodes: service.topMenuCodes,
                pageCodes: service.pageCodes,
                getReportMenu: getReportMenu
			}

		}]);

})();