
(function () {
	angular.module('phapApp')
		.directive('browserCompatibleEnter', function () {
			return function (scope, element, attrs) {
				element.bind("keydown keypress", function (event) {
					if (event.which === 13) {
						scope.$apply(function () {
							if (element.val().length > 10) {
								scope.$eval(attrs.browserCompatibleEnter);	
							}
						});
						
						if (element.val().length < 11) {
							event.preventDefault();
						}
					}
				});
			};
		});
})();