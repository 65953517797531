(function () {

    var app = angular.module('phapApp');

    app.controller('addUserRegionsCtrl', ['$scope', 'securityManagementService', 'utils', 'data',
        function ($scope, securityManagementService, utils, data) {
            var vm = this;
            vm.searchPlaceholderText = 'Search Regions';
            vm.popupLeftList = 'popupLeftList';
            vm.selectedRegions = [];
            var init = function () {
                securityManagementService.getUnAssignRegions(data.userId).then(
                    function (data) {
                        vm.regions = data.data;
                        vm.originalDataSet = angular.copy(vm.regions);
                    });

            }

            vm.addSelectedRegion = function (item) {
                var index = vm.selectedRegions.indexOf(item);
                if (index === -1)
                    vm.selectedRegions.push(item);
            }

            vm.close = function () {
                $scope.$dismiss({ $value: 'cancel' });
            }

            vm.deleteSelectedRegion = function (index) {
                if (index !== -1)
                    vm.selectedRegions.splice(index, 1);
            }

            vm.applyChanges = function () {
                var title = 'Warning';
                var content = "Saving will commit all changes made on this page";
                utils.confirm(title, content, 'Proceed', 'Cancel')
                    .then(function () {
                        vm.submitting = true;
                        vm.pageErrors = [];
                        securityManagementService.addUserRegions(data.userId, vm.selectedRegions).then(
                            function () {
                                $scope.$close();
                            });
                    });
            }

            vm.disabledSave = function () {
                return vm.selectedRegions.length == 0;
            }

            init();
        }
    ]);

})();
