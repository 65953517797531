(function () {

    var app = angular.module('phapApp');

    app.component('keyValueGrid', {
        templateUrl: 'app/administration/configuration/keyValueGrid.html',
        bindings: {
            gridOptions: '=',
            originalDataSet: '=',
            saveCallback: '=',
            popupInfo: '=',
            deleteCallback: '=',
            history: '=',
            validator: '=' //this method should return {isValid:true/false,errors:['error message']}
        },
        controller: ['$scope', '$filter', '$uibModal', 'uiGridConstants', 'utils', 'configurationService', 'Constants', function ($scope, $filter, $uibModal, uiGridConstants, utils, configurationService, Constants) {

            var vm = this;
            vm.pageErrors = [];
            vm.showSavedMessage = false;
            vm.showNewPanel = false;
            
            vm.submitting = false;
            vm.dateOptions = {
                minDate: new Date().setDate(new Date().getDate() + 1),
                dateFormat: Constants.DatePickerFormat
            };

            vm.initNewItem = function () {
                vm.newItem = {
                    description: '',
                    value: 0
                };
            };
            vm.cancelFutureVersion = function () {
                var content = "Are you sure you want to cancel the changes?";
                var title = 'Warning';
                utils.confirm(title, content, 'Yes', 'No')
                    .then(function () {
                        vm.gridOptions = angular.copy(vm.originalDataSet);
                        configurationService.setHouseHoldValueRules(vm.gridOptions)
                        vm.setPreviousVersion(vm.gridOptions);
                    },
                    function () {
                        return;
                    });
            };
            vm.addNewItem = function () {
                var newItem = angular.copy(vm.gridOptions.items[0]);
                var futurePeriod = angular.copy(newItem.futurePeriod);
                newItem.id = 0;
                newItem.description = vm.newItem.description;
                newItem.value = vm.newItem.value;
                futurePeriod.id = 0;
                futurePeriod.value = vm.newItem.value;
                futurePeriod.isDeleted = false;
                futurePeriod.isReadOnly = false;
                newItem.futurePeriod = futurePeriod;
                newItem.selectedPeriod = futurePeriod;
                newItem.periods = [];
                newItem.periods.push(futurePeriod)
                newItem.currentPeriod = null;
                vm.gridOptions.items.push(newItem);
                vm.newItem = {};
            };
            vm.deleteOrActivate = function (period) {
                var content = "";
                if (period.isDeleted) 
                    content = "Are you sure you want to reactivate this?";
                else
                    content = "Are you sure you want to delete this?";
                var title = 'Warning';
                utils.confirm(title, content, 'Yes', 'No')
                    .then(function () {
                        period.isDeleted = !period.isDeleted;
                    },
                    function () {
                        return;
                    });
            }
            vm.refreshAfterSave = function (result) {
                if (result.status == 200) {
                    vm.showSavedMessage = true;
                    vm.submitting = false;
                    if ($scope.admin) {
                        $scope.admin.$setPristine();
                    }
                } else {
                    //Add Errors
                    vm.submitting = false;
                    vm.pageErrors = [];
                    if (result.data.errors.length > 0) {
                        var errorList = [];
                        for (var i = 0; i < result.data.errors.length; i++) {
                            errorList.push(result.data.errors[i].message);
                        }
                        vm.pageErrors = vm.pageErrors.concat(errorList);
                    }
                }
            };

            vm.deleteVersion = function () {
                var title = 'Warning';
                var content = vm.deleteConfirmationMessage ? vm.deleteConfirmationMessage : "Are you sure you want to delete selected version?";
                utils.confirm(title, content, 'Yes', 'Cancel')
                    .then(function () {
                        vm.deleteCallback(vm.gridOptions.selectedVersion.id);
                        vm.showSavedMessage = true;
                    },
                    function () {
                        return;
                    });
            }

            vm.update = function () {
                var result = vm.validator(vm.gridOptions);
                if (result.isValid) {
                    var title = 'Warning';
                    var content = "The following changes will affect the assistance amount offered to DHHS PHAP clients from the date selected. Ensure this change has been endorsed through formal change management processes and all relevant testing has been conducted";
                    utils.confirm(title, content, 'Proceed', 'Cancel')
                        .then(function () {
                            vm.submitting = true;
                            vm.pageErrors = [];
                            var fromdate = vm.gridOptions.effectiveFromDate.getFullYear() + '-' + (vm.gridOptions.effectiveFromDate.getMonth() + 1) + '-' + vm.gridOptions.effectiveFromDate.getDate();
                            vm.gridOptions.items.forEach(function (item) {
                                item.futurePeriod.set.effectiveFromDate = fromdate;
                                item.futurePeriod.value = utils.parseDollarToNumber(item.futurePeriod.value);
                            });
                            vm.originalGridOptions = angular.copy(vm.gridOptions);
                            vm.saveCallback(vm.gridOptions.items, vm.refreshAfterSave);
                            vm.showSavedMessage = true;
                        },
                        function () {
                            return;
                        });
                }
                else {
                    vm.pageErrors.push(result.error);
                }
            }

            vm.disabledUpdate = function () {
                return vm.pageErrors.length !== 0;
            }

            vm.calculateEndDate = function (effectiveToDate) {
                var endDate = "";
                if (effectiveToDate == null)
                    endDate = " onwards";
                else {
                    endDate = " to " + $filter('date')(effectiveToDate, Constants.DatePickerFormat);
                    var today = new Date($filter('date')(new Date()));
                    var end = new Date($filter('date')(effectiveToDate, Constants.DatePickerFormat));
                    if (end >= today)
                        endDate = endDate + " (current)"
                }
                return endDate
            };

            vm.setPreviousVersion = function () {
                vm.showSavedMessage = false;
                vm.pageErrors = [];
                configurationService.setPreviousVersions(vm.gridOptions);
            };

            vm.addNew = function () {
                var modalInstance = $uibModal.open({
                    templateUrl: vm.popupInfo.templateUrl,
                    controller: vm.popupInfo.controller,
                    controllerAs: 'vm',
                    size: vm.popupInfo.size,
                    resolve: {
                        newItem: function () {
                            return vm.newItem;
                        }
                    }
                });

                modalInstance.result.then(function (newItem) {
                    vm.newItem = newItem;
                    vm.addNewItem();
                });

            };

            vm.addNewVersion = function () {
                vm.originalGridOptions = angular.copy(vm.gridOptions);
                var newDateTomorrow = new Date().setDate(new Date().getDate() + 1)
                var today = new Date($filter('date')(new Date()));
                var tomorrow = new Date($filter('date')(newDateTomorrow));
                var currentPeriod;
                vm.gridOptions.periodsList.forEach(function (period) {
                    if (new Date(period.effectiveFromDate) <= today && period.effectiveToDate == null) {
                        currentPeriod = period;
                        period.effectiveToDate = new Date();
                    }
                });
                var newPeriod = angular.copy(currentPeriod);
                newPeriod.effectiveFromDate = tomorrow;
                newPeriod.effectiveToDate = null;
                newPeriod.id = 0;
                
                vm.gridOptions.items.forEach(function (item) {
                    item.futurePeriod = angular.copy(item.currentPeriod);
                    item.futurePeriod.set = angular.copy(newPeriod);
                    item.futurePeriod.id = 0;
                    item.periods.push(item.futurePeriod);
                });
                vm.gridOptions.periodsList.push(newPeriod);
                vm.gridOptions.selectedVersion = newPeriod;
                vm.setPreviousVersion(newPeriod)
            };

            vm.initNewItem();

        }],
        controllerAs: 'keyValueGridVm'
    });

})();