(function () {
    angular.module('phapApp')
        .directive('insuranceSummary',
            [
                '$window', function ($window) {
                    return {
                        restrict: 'E',
                        templateUrl: 'app/home/reestablish/insuranceSummary.html',
                        controller: ['$scope', 'Constants', 'utils', insuranceSummaryCtrl]
                    };
                }
            ]);

    function insuranceSummaryCtrl($scope, Constants, utils) {
    };
})();