(function () {
    angular.module('phapApp')
        .directive('exceptionalCircumstances', ['Constants', 'utils', function (Constants, utils) {
            return {
                scope: true,
                restrict: 'E',
                templateUrl: 'app/home/reestablish/_exceptional-circumstances.html',
                controller: ['$scope', function ($scope) {

                    $scope.exceptCircumUploadController = {};

                    // #region Business Rule checking

                    $scope.$watchCollection('[item.exceptionalCircumstancesAmount, item.exceptionalCircumstancesEvidenceAttached]', function (newValues) {
                        if (!angular.isDefined(newValues[0]))
                            return;
                        $scope.exceptCircumBusinssRules();
                    });

                    $scope.$on('dataLoaded', function () {
                        $scope.exceptCircumBusinssRules();
                    });

                    $scope.exceptCircumBusinssRules = function () {
                        $scope.formSections.exceptionalCircumstances.rulesOverridden =
                            ($scope.item.specialConsiderationAmount > 0 &&
                                ($scope.item.specialConsiderationEvidenceAttached === null || $scope.item.specialConsiderationEvidenceAttached === false));
                    };

                    $scope.getFormSections = function () {
                        return angular.extend({}, $scope.formSections.categoriesOfAssessment.subMenu, $scope.formSections.eligibilityCriteria.subMenu);
                    }

                    // #endregion 

                    var exceptCircumNoDocumentsAttached = function () {
                        if ($scope.item && $scope.item.documents) {
                            var documents = $scope.item.documents[Constants.reestablishSections.exceptionalCircumstances];
                            return !angular.isDefined(documents) || documents.length === 0;
                        }
                        return false;
                    };

                    $scope.exceptCircumCheckEvidence = function () {
                        if (exceptCircumNoDocumentsAttached()) {
                            $scope.exceptCircumUploadController.$newUpload();
                        }
                    };

                    $scope.exceptCircumNotYetPrompt = function () {
                        var title = 'Exceptional circumstances';
                        var content = 'Re-establishment assistance is only available when Exceptional Circumstances Panel approval is able to be confirmed.  Evidence attached must equal Yes.';
                        utils.alert(content, title, 'Proceed')
                            .then(function () {
                                $scope.item.exceptionalCircumstancesEvidenceAttached = false;
                            });
                    };

                    var exceptCircumConfirm = function () {
                        var title = 'Evidence documents required!';
                        var content = 'Evidence attached has been selected as Yes and no attachments have been loaded. \nDocuments must be loaded in order to set the Evidence attached field to Yes.';
                        utils.alert(content, title, 'Proceed')
                            .then(function () {
                                $scope.item.exceptionalCircumstancesEvidenceAttached = false;
                            });
                    };

                    var exceptCircumVarifyDocumentsAttached = function () {

                        if ($scope.item && $scope.item.exceptionalCircumstancesEvidenceAttached) {
                            if (exceptCircumNoDocumentsAttached()) {
                                exceptCircumConfirm();
                            }
                        }
                    };

                    $scope.exceptCircumOnCancelUpdate = function () {
                        exceptCircumVarifyDocumentsAttached();
                    };

                    $scope.exceptCircumOnFileListChanged = function () {
                        exceptCircumVarifyDocumentsAttached();
                    };

                }]
            };
        }]);
})();