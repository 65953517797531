(function () {
    var app = angular.module('phapApp');
    app.component('chequeSearch', {
        templateUrl: 'app/financeManagement/cheques/chequeSearch.html',
        bindings: {
            mode: '@',

        },
        controller: ['$scope', '$routeParams', '$location', 'uiGridConstants', 'chequeService',
            'Constants', 'config', '$window', '$cookies', '$timeout', 'security', 'cacheService', '$q',
            'utils', 'localStorageService', 'navigationService', 'notificationService', '$interval',
            function ($scope, $routeParams, $location, uiGridConstants, chequeService, constants,
                config, $window, $cookies, $timeout, security, cacheService, $q, utils, localStorageService, navigationService, notificationService, $interval) {
                var vm = this;
                var isRunningInExtraSmallScreen = false;
                vm.hasPermission = false;

                navigationService.setCurrentMenuStatus(
                    navigationService.topMenuCodes.financeManagement,
                    navigationService.pageCodes.chequeSearch);

                vm.$onInit = function () {
                    vm.constants = constants;
                    vm.hasPermission = false;
                    vm.loaded = false;
                    vm.firstSearch = undefined;
                    vm.searching = false;
                    vm.exporting = false;
                    vm.enableSearchPaging = true; // This can be made in to an attribute of the component to allow for paging / non paging searches
                    vm.allPromises = [];
                    vm.filterVisible = true;
                    vm.presentedTimeFilter = { show: false };
                    vm.paymentTimeFilter = { show: false };
                    vm.gridOptions = getGridOptions();
                    vm.gridOptions.data = [];

                    vm.initSearchParms();


                    notificationService.notify('clickTopMenu', {
                        sectionCode: navigationService.topMenuCodes.financeManagement,
                        pageCode: navigationService.pageCodes.chequeSearch
                    });


                    vm.allPromises.push(
                        security.getCurrentUserSecurityInfo().then(
                            function (result) {
                                vm.currentUserSecurityInfo = result;
                                vm.hasPermission = result.privileges.SearchCheques;

                            },
                            function () {
                                vm.currentUserSecurityInfo = {};
                            })
                    );
                    $q.all(vm.allPromises).then(
                        function (result) {
                            vm.initSearchParms();
                            vm.loadSavedFilters();
                            vm.loaded = true;
                        });
                };
                // #region Init routines
                vm.initSearchParms = function () {
                    vm.search = {
                        chequeNumber: '',
                        incidentNumber: '',
                        presentedDate: '',
                        paymentDate: '',
                        chequeNumberFrom: '',
                        chequeNumberTo: '',
                        givenName: '',
                        lastName: '',
                        amount: '',
                        paymentStartDate: null,
                        paymentEndDate: null,
                        paymentEndDateError: false,
                        paymentStartDateError: false,
                        presentedStartDate: null,
                        presentedEndDate: null,
                        presentedEndDateError: false,
                        presentedStartDateError: false
                    };

                    vm.totalSearchResults = 0;
                    vm.noResultsFound = false;
                };

                // #endregion

                // #region Search
                var getSearchCriteria = function () {
                    var searchCriteria = angular.copy(vm.search);
                    return searchCriteria;
                }

                vm.exportToCsv = function () {
                    vm.exporting = true;
                    var savedData = vm.retrieveFilters();

                    if (savedData) {
                        vm.search = savedData;
                        chequeService.exportSearch(getSearchCriteria()).then(
                            function (response) {
                                vm.exporting = false;
                                var file = new Blob([response.data], { type: 'text/csv' });
                                saveAs(file, 'ChequePaymentlist.csv');
                            },
                            function (response) {
                                vm.exporting = false;
                            });
                    }
                };

                vm.checkPaymentSearchDates = function () {
                    var paymentStartDate = null;
                    var paymentEndDate = null;
                    vm.searchForm.paymentEndDate.$setValidity('greaterThanPaymentStartDate', true);
                    if (vm.search.paymentStartDate) {
                        paymentStartDate = moment(vm.search.paymentStartDate);
                    }
                    if (vm.search.paymentEndDate) {
                        paymentEndDate = moment(vm.search.paymentEndDate);
                        if (!paymentStartDate || moment(paymentEndDate, 'YYYY-MM-DD').isBefore(paymentStartDate)) {
                            vm.searchForm.paymentEndDate.$setValidity('greaterThanPaymentStartDate', false);
                        }
                    }
                }
                vm.checkPresentedSearchDates = function () {
                    var presentedStartDate = null;
                    var presentedEndDate = null;
                    vm.searchForm.presentedEndDate.$setValidity('greaterThanPresentedStartDate', true);
                    if (vm.search.presentedEndDate) {
                        presentedStartDate = moment(vm.search.presentedStartDate);
                    }
                    if (vm.search.presentedEndDate) {
                        presentedEndDate = moment(vm.search.presentedEndDate);
                        if (!presentedStartDate || moment(presentedEndDate, 'YYYY-MM-DD').isBefore(presentedStartDate)) {
                            vm.searchForm.presentedEndDate.$setValidity('greaterThanPresentedStartDate', false);
                        }
                    }
                }

                vm.getSearchResults = function () {
                    vm.searching = true;

                    vm.saveFilters();
                    var promise = $q.defer();
                    chequeService.chequeSearch(getSearchCriteria()).then(
                        function (response) {
                            vm.totalSearchResults = response.data.length;
                            vm.noResultsFound = vm.totalSearchResults == 0;
                            vm.gridOptions.data = response.data;

                            vm.firstSearch = vm.firstSearch == undefined;
                            vm.loaded = true;
                            vm.searching = false;
                        }, function (error) {
                            vm.loaded = true;
                            vm.searching = false;
                            $scope.gridApi.infiniteScroll.dataLoaded();
                            promise.reject();
                        });
                    return promise.promise;
                };

                // #region Grid
                var openItem = function (row) {
                    $location.path("/financeManagement/cheques/details/" + row.entity.paymentId);
                };

                var getGridColumnDefs = function () {
                    return [
                        {
                            field: 'chequeNumber',
                            displayName: 'Cheque number',
                            enableHiding: false,
                            enableColumnMenu: false,
                            width: '12%'
                        },
                        {
                            field: 'incidentNumber',
                            displayName: 'Incident number',
                            enableHiding: true,
                            enableColumnMenu: false,
                            width: '13%'
                        },
                        {
                            field: 'recipientName',
                            displayName: 'Recipient name',
                            enableHiding: true,
                            enableColumnMenu: false,
                            width: '20%'
                        },
                        {
                            field: 'amount',
                            displayName: 'Amount',
                            enableHiding: false,
                            enableColumnMenu: false,
                            cellClass: 'currency',
                            width: '11%'
                        },
                        {
                            field: 'paymentStatus',
                            displayName: 'Payment status',
                            enableHiding: true,
                            enableColumnMenu: false,
                            width: '14%'
                        },
                        {
                            field: 'paymentDate',
                            displayName: 'Payment date',
                            enableHiding: true,
                            enableColumnMenu: false,
                            cellClass: 'date-field',
                            cellFilter: constants.GridDateFormat,
                            width: '15%'
                        },
                        {
                            field: 'presentedDate',
                            displayName: 'Presented date',
                            enableHiding: true,
                            enableColumnMenu: false,
                            cellFilter: constants.GridDateFormat,
                            cellClass: 'date-field',
                            width: '15%'
                        },
                    ]
                };
                function saveState() {
                    var state = vm.gridApi.saveState.save();
                    localStorageService.set('chequeSearch.gridState.' + $cookies.get("UserId"), state);
                }

                function restoreState() {
                    $timeout(function () {
                        var state = localStorageService.get('chequeSearch.gridState.' + $cookies.get("UserId"));
                        if (state) vm.gridApi.saveState.restore($scope, state);
                    });
                }
                var getGridOptions = function () {
                    return {
                        enableGridMenu: false,
                        enableSorting: true,
                        enableFiltering: false,
                        enableColumnResizing: true,
                        enableHorizontalScrollbar: uiGridConstants.scrollbars.ALWAYS,
                        enableVerticalScrollbar: uiGridConstants.scrollbars.ALWAYS,
                        enableRowSelection: false,
                        enableFullRowSelection: false,
                        enableRowHeaderSelection: false,
                        enableSelectAll: false,
                        multiSelect: false,
                        enableRowHashing: false,
                        columnDefs: getGridColumnDefs(),
                        appScopeProvider: {
                            openItem: function (row) {
                                openItem(row);
                            }
                        },
                        rowTemplate:
                        '<div ng-click="grid.appScope.openItem(row);"> ' +
                        '<div class="ui-grid-cell" ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" ui-grid-cell ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader }" style="cursor:pointer"> ' +
                        '</div>' +
                        '</div>',
                        onRegisterApi: function (gridApi) {
                            vm.gridApi = gridApi;
                            vm.gridApi.core.on.rowsRendered($scope, rowsRendered);
                            vm.gridApi.colResizable.on.columnSizeChanged($scope, saveState);
                            vm.gridApi.core.on.columnVisibilityChanged($scope, saveState);
                            vm.gridApi.core.on.filterChanged($scope, saveState);
                            vm.gridApi.core.on.sortChanged($scope, saveState);
                            // call resize every 500 ms for 5 s after modal finishes opening - usually only necessary on a bootstrap modal
                            $interval(function () {
                                vm.gridApi.core.handleWindowResize();
                            }, 500, 10);
                            // Restore previously saved state.
                            restoreState();
                        }
                    }
                };

                var rowsRendered = function () {
                    vm.filteredRecords = vm.gridApi.core.getVisibleRows(vm.gridApi.grid).length;
                    if ($routeParams.orderNumber && vm.firstSearch && vm.gridOptions.data.length > 0) {
                        vm.gridApi.selection.selectAllVisibleRows();
                    }
                    else {
                        vm.gridApi.selection.clearSelectedRows();
                    }
                };
                // #endregion


                vm.clearFilters = function () {
                    vm.initSearchParms();
                    vm.paymentTimeFilter.show = false;
                    vm.presentedTimeFilter.show = false;
                    vm.gridOptions.data = [];
                    if (vm.enableSearchPaging) {
                        $timeout(function () {
                            // timeout required to allow for digest cycle to complete and grid to finish refresh.
                            vm.gridApi.infiniteScroll.resetScroll(false, false);
                        });
                    }
                    vm.filterVisible = true;
                    cacheService.removeItemFromCache('chequeSearchFilters.' + $cookies.get("UserId"));
                };

                vm.saveFilters = function () {
                    cacheService.saveToCache('chequeSearchFilters.' + $cookies.get("UserId"), vm.search);
                };

                vm.retrieveFilters = function () {
                    return cacheService.readFromCache('chequeSearchFilters.' + $cookies.get("UserId"));
                };

                vm.loadSavedFilters = function () {
                    var savedData = vm.retrieveFilters();
                    if (savedData) {
                        vm.search = savedData;
                    } else {
                        vm.clearFilters();
                    }
                }
                // #endregion

                vm.hasSelectedRows = function () {
                    vm.selectedRows = vm.gridApi.selection.getSelectedRows();
                    return vm.selectedRows.length > 0;
                }
                vm.hideActivityRequest = function () {
                    vm.showCardActivity = false;
                }
                $scope.openModal = function (templateUrl, controller, scope, resolve, windowClass, callback, size) {

                    utils.openModal(templateUrl, controller, scope, resolve, windowClass, callback, 'xlg');

                };

            }
        ],
        controllerAs: 'chequeSearch'
    });
})();