(function () {
    angular.module('phapApp')
        .directive('memberSearch', ['$window', function ($window) {
            return {
                restrict: 'E',
                templateUrl: 'app/home/relief/_memberSearch.html',
                controller: ['$scope', 'searchMemberService', '$uibModal', 'utils', '$q', controller]
            };
        }]);



    function controller($scope, searchMemberService, $uibModal, utils, $q) {




    }

})();