(function () {

    var app = angular.module('phapApp');

    app.component('apErrorDetails', {
        templateUrl: 'app/errorHandling/apDetails.html',

        controller: ['$scope', '$routeParams', '$timeout', '$cookies', '$uibModal', '$location', 'notificationService', 'navigationService', 'errorHandlingService', 'uiGridConstants', 'localStorageService', 'utils', '$interval', 'Constants','security','cacheService',
            function ($scope, $routeParams, $timeout, $cookies, $uibModal, $location, notificationService, navigationService, errorHandlingService, uiGridConstants, localStorageService, utils, $interval, Constants, security, cacheService) {

                navigationService.setCurrentMenuStatus(
                    'errorHandling','apErrorHandlingDetails');

                var vm = this;
                vm.NoFilterProvided = false;
                vm.status = Constants.TransactionStatus;
                vm.transactionType = Constants.TransactionTypeAP;

                vm.isDisabled = function () {
                    return true;
                };

                vm.back = function () {
                    $location.path("/errorHandling/ap");
                };
                vm.exportToCsv = function () {
                    vm.exporting = true;
                    vm.beforeSearch();
                    errorHandlingService.exportAPTransactionSearch(vm.formattedSearch).then(
                        function (response) {
                            vm.exporting = false;
                            var file = new Blob([response.data], { type: 'text/csv' });
                            saveAs(file, 'APTransactionList.csv');
                        },
                        function (response) {
                            vm.exporting = false;
                        });

                };

                vm.exportToCsvGrid = function () {
                    vm.exporting = true;
                    vm.beforeSearch();
                    errorHandlingService.exportAPTransactionSearchDetails(vm.id).then(
                        function (response) {
                            vm.exporting = false;
                            var file = new Blob([response.data], { type: 'text/csv' });
                            saveAs(file, 'APTransactionList.csv');
                        },
                        function (response) {
                            vm.exporting = false;
                        });
                };

                vm.setData = function (data) {
                    vm.results = data;
                    vm.gridOptions.data = angular.copy(vm.results);
                    vm.noResultsFound = vm.results.length == 0;
                };

                vm.beforeSearch = function () {
                    vm.formattedSearch = [];
                    vm.formattedSearch = angular.copy(vm.search);
                    if (vm.search.fromDate)
                        vm.formattedSearch.fromDate = vm.formattedSearch.fromDate.getFullYear() + '-' + (vm.formattedSearch.fromDate.getMonth() + 1) + '-' + vm.formattedSearch.fromDate.getDate();
                    if (vm.search.toDate)
                        vm.formattedSearch.toDate = vm.formattedSearch.toDate.getFullYear() + '-' + (vm.formattedSearch.toDate.getMonth() + 1) + '-' + vm.formattedSearch.toDate.getDate();
                };

                vm.getSearchResults = function () {

                    vm.NoFilterProvided = false;
                    vm.ExceedMaxRecords = false; 
                    vm.loaded = false;
                    vm.searching = true;
                    vm.beforeSearch();
                    if ((vm.formattedSearch.batchNumber == null || vm.formattedSearch.batchNumber.length == 0)
                        && vm.formattedSearch.fromDate == null
                        && vm.formattedSearch.toDate == null
                        && (vm.formattedSearch.vendorName == null || vm.formattedSearch.vendorName.length == 0)
                        && (vm.formattedSearch.vendorId == null || vm.formattedSearch.vendorId.length == 0)
                        && vm.formattedSearch.tranactionCode == null
                        && vm.formattedSearch.status == null) {

                        vm.NoFilterProvided = true;
                        vm.loaded = true;
                        vm.searching = false;
                        vm.results = [];
                        return;

                    }

                    vm.saveFilters();

                    errorHandlingService.getApTransactionSearch(vm.formattedSearch).then(
                        function (result) {
                            vm.loaded = true;
                            vm.searching = false;
                            vm.NoFilterProvided = false;
                            vm.results = [];

                            vm.searchResultMaxLimit = Constants.MaximumSearchResultAllowed;

                            if (result.data.length > vm.searchResultMaxLimit) {                                
                                result.data.pop();
                                vm.setData(result.data);
                                vm.ExceedMaxRecords = true;                                
                            }
                            else {
                                vm.ExceedMaxRecords = false;
                                vm.setData(result.data);
                            }
                            
                        },
                        function (result) {
                            vm.loaded = true;
                            vm.searching = false;
                        }
                    );
                };

                vm.getDetails = function () {
                    errorHandlingService.getApErrorDetails(vm.id).then(
                        function (result) {
                            vm.loaded = true;
                            vm.results = [];
                            vm.setData(result.data.transactions);
                        });
                };

                vm.clearFilters = function () {
                    vm.NoFilterProvided = false;
                    vm.ExceedMaxRecords = false; 

                    vm.search = { includeArchived: true };
                    vm.dateOptionsTo.minDate = null;
                    vm.dateOptionsFrom.maxDate = new Date();
                    cacheService.removeItemFromCache('apDetailFilters.' + $cookies.get("UserId"));
                };

                vm.saveFilters = function () {
                    cacheService.saveToCache('apDetailFilters.' + $cookies.get("UserId"), vm.search);
                };

                vm.retrieveFilters = function () {
                    return cacheService.readFromCache('apDetailFilters.' + $cookies.get("UserId"));
                };

                vm.loadSavedFilters = function () {
                    var savedData = vm.retrieveFilters();
                    if (savedData) {
                        vm.search = savedData;
                    } else {
                        vm.clearFilters();
                    }
                }
                vm.resetAP = function () {

                   

                    var model = {
                        BatchId: vm.id ? vm.id : vm.details.batchNumber,
                        RowVersion: null
                    }
                    errorHandlingService.resetAPInvoice(model).then(
                        function(response){
                            utils.alert('Reset for AP batch completed. Please re-submit payment to initiate another invoice.');
                        });
                }

                var getGridOptions = function () {
                    return {
                        fastWatch: true,
                        enableGridMenu: true,
                        enableSorting: true,
                        enableFiltering: false,
                        enableColumnResizing: true,
                        enableHorizontalScrollbar: uiGridConstants.scrollbars.ALWAYS,
                        enableVerticalScrollbar: uiGridConstants.scrollbars.ALWAYS,
                        enableRowSelection: true,
                        enableFullRowSelection: true,
                        enableRowHeaderSelection: false,
                        enableSelectAll: false,
                        multiSelect: false,
                        enableRowHashing: false,
                        columnDefs: getGridColumnDefs(),
                        appScopeProvider: {
                            openItem: function (row) {
                                openItem(row);
                            },
                            redirectItem: function (row) {
                                redirectItem(row);
                            },
                        },
                        rowStyle: function (row) {
                            return 'status-' + row.entity.status + '-class';
                        },
                        rowTemplate:
                        '<div ng-click="grid.appScope.openItem(row.entity);" ng-class="grid.options.rowStyle(row)">' +
                        '<div class="ui-grid-cell" ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" ui-grid-cell ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader}" style="cursor:pointer"> ' +
                        '</div>' +
                        '</div>',
                        onRegisterApi: function (gridApi) {
                            vm.gridApi = gridApi;
                            vm.gridApi.core.on.rowsRendered($scope, rowsRendered);
                            vm.gridApi.colResizable.on.columnSizeChanged($scope, saveState);
                            vm.gridApi.core.on.columnVisibilityChanged($scope, saveState);
                            vm.gridApi.core.on.filterChanged($scope, saveState);
                            vm.gridApi.core.on.sortChanged($scope, saveState);
                            // call resize every 500 ms for 5 s after modal finishes opening - usually only necessary on a bootstrap modal
                            $interval(function () {
                                vm.gridApi.core.handleWindowResize();
                            }, 500, 10);
                            // Restore previously saved state.
                            restoreState();
                        }
                    }
                };

                function saveState() {
                    var state = vm.gridApi.saveState.save();
                    localStorageService.set('apDetails.gridState.' + $cookies.get("UserId"), state);
                }

                function restoreState() {
                    $timeout(function () {
                        var state = localStorageService.get('apDetails.gridState.' + $cookies.get("UserId"));
                        if (state) vm.gridApi.saveState.restore($scope, state);
                    });
                }

                var rowsRendered = function () {

                    vm.filteredRecords = vm.gridApi.core.getVisibleRows(vm.gridApi.grid).length;
                    if ($routeParams.orderNumber && vm.gridOptions.data.length > 0) {
                        vm.gridApi.selection.selectAllVisibleRows();
                    }
                    else {
                        vm.gridApi.selection.clearSelectedRows();
                    }
                };

              

                var getGridColumnDefs = function () {
                    return [
                        {
                            field: 'batchNumber',
                            displayName: 'Batch number',
                            visible: true,
                            enableHiding: false,
                            enableColumnMenu: false,
                            width: '12%'
                        },  
                        {
                            field: 'invoiceReferenceNo',
                            displayName: 'Invoice reference number',
                            visible: true,
                            enableHiding: false,
                            enableColumnMenu: false,
                            width: '12%'
                        },  
                        {
                            field: 'replacementBatchId',
                            displayName: 'Replacement batch number',
                            visible: true,
                            enableHiding: false,
                            enableColumnMenu: false,
                            cellTemplate: '<div class="ui-grid-cell-contents">' +
                            '<a href="" ng-click="grid.appScope.redirectItem(row.entity.replacementBatchId)">{{row.entity.replacementBatchId}}</a>' +
                            '</div>',
                            width: '12%'
                        },
                        {
                            field: 'applicationNo',
                            displayName: 'Application number',
                            visible: true,
                            enableHiding: false,
                            enableColumnMenu: false,
                            width: '10%'
                        },
                        {
                            field: 'oracleDate',
                            displayName: 'Oracle date',
                            enableHiding: true,
                            enableColumnMenu: false,
                            cellClass: 'date-field',
                            cellFilter: Constants.GridDateFormat,
                            width: '10%'
                        },                       
                        {
                            field: 'amount',
                            displayName: 'Amount',
                            cellFilter: 'phapcurrencycent',
                            enableHiding: true,
                            cellClass: 'currency',
                            enableColumnMenu: false,
                            width: '10%'
                        },
                        {
                            field: 'vendorName',
                            displayName: 'Vendor',
                            enableHiding: true,
                            enableColumnMenu: false,
                            width: '12%'
                        },
                        {
                            field: 'transactionCode',
                            displayName: 'Transaction code',
                            enableHiding: true,
                            enableColumnMenu: false,
                            width: '10%'
                        },
                        {
                            field: 'status',
                            displayName: 'Status',
                            enableHiding: false,
                            enableColumnMenu: false,
                            width: '12%'
                        },
                    ]
                };

                var openItem = function (data) {
                    vm.showDetails = true;
                    vm.details = data;
                };

                var redirectItem = function (batchNumber) {
                    $location.path('/errorHandling/ap/details/' + batchNumber)
                };

                vm.setToMindate = function () {
                    vm.dateOptionsTo.minDate = new Date(vm.search.fromDate);
                };

                vm.setFromMaxdate = function () {
                    vm.dateOptionsFrom.maxDate = new Date(vm.search.toDate);
                };

                vm.dateOptionsFrom = {
                    maxDate: new Date(),
                    dateFormat: Constants.DatePickerFormat
                };

                vm.dateOptionsTo = {
                    maxDate: new Date(),
                    dateFormat: Constants.DatePickerFormat
                };

                vm.checkId = function () {
                    vm.id = $routeParams.id;
                    if (vm.id) {
                        vm.canSearch = false;
                        vm.getDetails();
                    }
                    else {
                        vm.canSearch = true;
                        //vm.getSearchResults();
                    }
                };

                var checkPermission = function () {
                    security.getCurrentUserSecurityInfo().then(
                        function (result) {
                            vm.currentUserSecurityInfo = result;
                            vm.hasPermission = result.privileges.ViewPhapSystemMonitoring;
                            vm.hasResetPermission = result.privileges.EditPhapSystemMonitoring;
                            if (vm.hasPermission) {
                                vm.loadSavedFilters();
                            }
                        },
                        function () {
                            vm.currentUserSecurityInfo = {};
                        })
                };

                vm.$onInit = function () {
                    vm.loaded = true;
                    vm.searching = false;
                    vm.noResultsFound = false;
                    vm.exporting = false;
                    vm.hasPermission = true;
                    vm.showDetails = false;
                    vm.gridOptions = getGridOptions();
                    vm.gridOptions.data = [];
                    vm.results = [];
                    vm.search = { includeArchived: true};
                    vm.checkId();
                    checkPermission();
                };
            }],
        controllerAs: 'apDetailsVM'
    });

})();