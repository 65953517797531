(function () {
    angular.module('phapApp')
        .service('cacheService', ['$window', function ($window) {

            // save to session storage
            function saveToCache(key, data) {
                $window.sessionStorage.setItem(key, JSON.stringify(data));
            }

            // read from session storage
            function readFromCache(key) {
                var item = $window.sessionStorage.getItem(key);
                if (item) {
                    return JSON.parse(item, JSON.dateParser);
                }
                return null;
            }

            // remove from session storage
            function removeItemFromCache(key) {
                $window.sessionStorage.removeItem(key);
            }

            return {
                saveToCache: saveToCache,
                readFromCache: readFromCache,
                removeItemFromCache: removeItemFromCache
            }
        }]);
})();