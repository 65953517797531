(function () {

    var app = angular.module('phapApp');

    app.component('reEstablishmentAmount', {
        templateUrl: 'app/administration/configuration/reEstablishmentAmount.html',
        bindings: {
            mode: '@' //create/edit
        },
        controller: ['$rootScope', '$scope', '$filter', 'utils', 'referenceService', 'configurationValidationService', 'Constants', 'security',
            function ($rootScope, $scope, $filter, utils, referenceService, configurationValidationService, constants, security) {

                var vm = this;
                vm.originalDataSet = [];
                vm.dislayItems = [];
                vm.historyUrl = "reEstablishmentAmount/0";
                vm.deleteConfirmation = "Are you sure you want to delete selected version?";
                vm.saveWarningMessage =
                    "The following changes will affect the assistance amount offered to DHHS PHAP clients from the date selected. Ensure this change has been endorsed through formal change management processes and all relevant testing has been conducted";
                vm.popupInfo = {
                    templateUrl: 'app/administration/configuration/reEstablishmentAmountPopup.html',
                    controller: 'reEstablishmentAmountPopupCtrl'
                }

                var getSelectdVersionData = function () {
                    referenceService.getReestablishmentAmountVersion(vm.selectedVersionId).then(
                        function (reliefAssistance) {
                            var list = [];
                            angular.forEach(reliefAssistance.data.paymentAmounts, function (value) {
                                this.push({ data: value, isUpdated: false, isAdded: false });
                            }, list);

                            vm.gridOptions.data = list;
                            vm.originalDataSet = angular.copy(vm.gridOptions.data);
                            $scope.$broadcast('displayLoading', false);
                            $scope.$broadcast('selectedVersionDataChange', list);
                        });
                }

                var getVersions = function (isShowCurrent) {
                    referenceService.getReestablishmentAmountVersions().then(
                        function (versions) {
                            vm.dislayItems = versions.data;
                            $rootScope.$emit("DataChange", vm.dislayItems);
                            if (isShowCurrent) {
                                angular.forEach(vm.dislayItems.versions,
                                    function (item) {
                                        if (item.isCurrentVersion) {
                                            vm.selectedVersionId = item.id;

                                            getSelectdVersionData();

                                        }
                                    });
                            } else {
                                vm.selectedVersionId = vm.dislayItems.versions[vm.dislayItems.versions.length - 1].id;
                                getSelectdVersionData();
                            }



                        });
                }

                var init = function () {
                    getSecurity();
                };
                var getSecurity = function () {
                    $scope.$broadcast('displayLoading', true);
                    //getting logged in user security
                    security.getCurrentUserSecurityInfo()
                        .then(function (result) {
                            vm.gridOptions.privileges = result.privileges.UpdateReestablishAdmin;
                            vm.gridOptions.showPrivilegePanel = true;
                            if (vm.gridOptions.privileges)
                                getVersions(true);
                        },
                        function () {
                            vm.gridOptions.privileges = false;
                        });
                };

                vm.gridOptions = {
                    treeRowHeaderAlwaysVisible: true,
                    headerTemplate: 'app/administration/configuration/gridHeaderTemplate.html',
                    enableCellEdit: false,
                    enableSorting: false,
                    enableCellEditOnFocus: false,
                    enableExpandAll: true,
                    newObject: { data: { id:0,numberOfAdults: null, numberOfChildren: null, amountToBePaid: null, isDeleted: false, isReadOnly: false }, isUpdated: false, isAdded: true }
                };
                
                vm.gridOptions.columnDefs = [
                    {
                        name: 'data.numberOfAdults', displayName: 'Adults', grouping: { groupPriority: 0 }, enableColumnMenu: false,
                        cellClass: 'number-center',
                        cellTemplate: '<div class="ui-grid-cell-contents">' +
                        '<div class="row-group-header"  ng-if="row.groupHeader"><span class="group-left-padding">{{grid.appScope.createGroupHeader(COL_FIELD)}}<span></div>' +
                        '<div ng-if="!row.groupHeader">{{row.entity.data.numberOfAdults}} <span title="{{row.entity.data.numberOfAdults}} (or more)" ng-if="row.entity.data.isNumberOfAdultsGreaterThan"> +</span></div></div>'
                    },
                    {
                        name: 'data.numberOfChildren', displayName: 'Children',  enableColumnMenu: false, cellClass: 'number-center',
                        cellTemplate: '<div class="ui-grid-cell-contents">' +
                        '<div ng-if="!row.groupHeader">{{row.entity.data.numberOfChildren}} <span title="{{row.entity.data.numberOfChildren}} (or more)" ng-if="row.entity.data.isNumberOfChildrenGreaterThan"> +</span></div></div>'
                    },
                    {
                        name: 'data.maximumAmount', displayName: 'Maximum limit',  enableColumnMenu: false, cellClass: 'currency',
                        cellTemplate: '<p>{{row.entity.data.maximumAmount | phapcurrency}}</p>'
                    },
                    {
                        name: 'data.isDeleted', displayName: '', enableCellEdit: false, enableColumnMenu: false, enableSorting: false,
                        cellClass: function (grid, row) {
                            if (!row.groupHeader) {
                                if (row.entity.data.isReadOnly) {
                                    return "grid-readonly-button";
                                }
                                return "grid-Delete-button";
                            }
                            return "";
                        },
                        cellTemplate: '<span class="gird-icon-space"> <a ng-if="!row.groupHeader &&(!row.entity.data.isReadOnly && !row.entity.data.isDeleted)" title="Edit" ng-click="grid.appScope.update(row.entity)"><span class="glyphicon glyphicon-edit gird-icon-edit" ></span></a></span>' +
                        '<a ng-if="!row.groupHeader &&(!row.entity.data.isReadOnly && !row.entity.data.isDeleted)" title="Delete" ng-click="grid.appScope.deleteRow(row.entity)"><span class="glyphicon glyphicon-trash grid-Delete-button gird-icon-space" ></span></a>' +
                        '<a ng-if="!row.groupHeader && (!row.entity.data.isReadOnly && row.entity.data.isDeleted)" title="ReActivate" ng-click="grid.appScope.reActivate(row.entity)"><span class="glyphicon glyphicon-plus grid-Add-button gird-icon-space" ></span></a>' +
                        '<span ng-if="!row.groupHeader && row.entity.data.isReadOnly" title="Read only" class="glyphicon glyphicon-lock gird-icon-space" ></span>'

                    }
                ];

                vm.savevalidation = function (currentData) {
                    return configurationValidationService.isFutureDate(currentData.effectiveDate);
                }

                vm.savePayments = function (list, refreshAfterSave) {
                    referenceService.saveReestablishmentAmount(list).then(
                        function (result) {
                            if (result.data.length > 0) {
                                getVersions(false);
                                refreshAfterSave(result);
                            }
                        },
                        // Error
                        function (result) {
                            refreshAfterSave(result);
                        });
                }

                vm.getSelectedVersion = function (id) {
                    vm.selectedVersionId = id;
                    getSelectdVersionData();
                }

                vm.deleteversion = function (versionId) {

                    referenceService.deleteReestablishmentAmount(versionId).then(
                        function (result) {
                            if (result.data) {
                                getVersions(true);
                                refreshAfterSave(result);
                            }

                        },
                        // Error
                        function (result) {
                            refreshAfterSave(result);
                        });
                }

                init();

            }],
        controllerAs: 'reEstablishmentAmountVm'
    });

})();