(function () {
    angular.module('phapApp')
        .directive('memberInCurrentIncident', ['$window', function ($window) {
            return {
                restrict: 'E',
                templateUrl: 'app/home/relief/_memberInCurrentIncident.html',
                controller: ['$scope', '$uibModal', 'utils', '$q', controller]
            };
        }]);


    function controller($scope, $uibModal, utils, $q) {
    }

})();