(function () {

    var app = angular.module('phapApp');

    app.component('immediateReliefType', {
        templateUrl: 'app/administration/configuration/immediateReliefType.html',
        bindings: {
            mode: '@' //create/edit
        },
        controller: ['$scope', '$filter', 'utils', 'referenceService', 'security', function ($scope, $filter, utils, referenceService, security) {

            var vm = this;
            vm.originalDataSet = [];
            vm.gridApi = null;
            vm.historyUrl = "immediateRelief/0";
            vm.saveConfirmationMEssage =
                "The following changes will affect the assistance amount offered to DHHS PHAP clients from the date selected. Ensure this change has been endorsed through formal change management processes and all relevant testing has been conducted";
            var getImmediateReliefTypes = function() {
                referenceService.getImmediateReliefTypes().then(
                    function (immediateReliefTypes) {
                        var list = [];
                        angular.forEach(immediateReliefTypes.data, function (value, key) {
                            this.push({ data: value, isUpdated: false, isAdded: false });
                        }, list);
                        vm.gridOptions.data = list;
                        vm.originalDataSet = angular.copy(vm.gridOptions.data);
                        $scope.$broadcast('displayLoading', false);
                    });
            }

            var init = function () {
                getSecurity();
            };
            var getSecurity = function () {
                $scope.$broadcast('displayLoading', true);
                //getting logged in user security
                security.getCurrentUserSecurityInfo()
                    .then(function (result) {
                        vm.gridOptions.privileges = result.privileges.UpdateReliefAdmin;
                        vm.gridOptions.showPrivilegePanel = true;
                        if (vm.gridOptions.privileges)
                            getImmediateReliefTypes();

                    },
                    function () {
                        vm.gridOptions.privileges = false;
                    });
            };

            vm.gridOptions = {
                showPrivilegePanel : false,
                enableCellEdit: false,
                enableSorting: true,
                enableCellEditOnFocus: true,
                newObject: { data: { description: null, isDeleted: false, isReadOnly: false }, isUpdated: false, isAdded: true }
            };
            
            vm.gridOptions.columnDefs = [
                { name: 'data.description', displayName: 'Immediate relief requirements', enableCellEdit: true, enableColumnMenu: false, enableSorting:true },
                {
                    name: 'data.isDeleted', displayName: '', enableCellEdit: false, enableColumnMenu: false, width: 100, enableSorting:false,
                    cellClass: function(grid, row) {

                        if (row.entity.data.isReadOnly) {
                            return "grid-readonly-button";
                        }
                        return "grid-Delete-button";
                    },
                    cellTemplate: '<a ng-if="!row.entity.data.isReadOnly && !row.entity.data.isDeleted" title="Delete" ng-click="grid.appScope.deleteRow(row.entity)"><span class="glyphicon glyphicon-trash grid-Delete-button" ></span></a>' +
                                  '<a ng-if="!row.entity.data.isReadOnly && row.entity.data.isDeleted" title="ReActivate" ng-click="grid.appScope.reActivate(row.entity)"><span class="glyphicon glyphicon-plus grid-Add-button" ></span></a>' +
                                    '<span ng-if="row.entity.data.isReadOnly" title="Read only" class="glyphicon glyphicon-lock" ></span>'
                        
                }
            ];

            vm.validation=function(row,colDef) {
                if (colDef.name === 'data.description') {
                    if (utils.isNullOrEmpty(row.description)) {
                        return { isValid: false, errors: ['Immediate Relief Type required'] };
                    }
                }
                return { isValid: true, error: '' }
            }

            vm.saveImmediateReliefTypes = function (list, refreshAfterSave) {
                referenceService.saveImmediateReliefTypes(list).then(
                    function (result) {
                        if (result.data.length > 0) {
                            getImmediateReliefTypes();
                            refreshAfterSave(result);
                        }
                    },
                    // Error
                    function (result) {
                     refreshAfterSave(result);
                    });
            }
            
            init();

        }],
        controllerAs: 'immediateReliefTypeVm'
    });

})();