(function () {
	var app = angular.module('phapApp');
	app.component('cardSearch', {
		templateUrl: 'app/financeManagement/cards/cardSearch.html',
		bindings: {
			mode: '@',

		},
		controller: ['$scope', '$routeParams', '$location', 'uiGridConstants', 'cardService', 'cardStorageService',
			'Constants', 'config', '$window', '$cookies', '$timeout', 'security', 'cacheService', '$q',
			'$uibModal', 'utils', 'localStorageService', 'navigationService', 'notificationService', '$interval',
			function ($scope, $routeParams, $location, uiGridConstants, cardService, cardStorageService, constants,
				config, $window, $cookies, $timeout, security, cacheService, $q, $uibModal, utils, localStorageService, navigationService, notificationService, $interval) {
				var vm = this;
				
				var isRunningInExtraSmallScreen = false;
				vm.hasPermission = false;
				vm.paramNumberFromRouteParams = $routeParams.orderNumber;
				vm.cardStatusFromRouteParams = $routeParams.cardStatus;
				vm.doesUserHaveOrderRequestRegionId = true;
				vm.$onInit = function () {
					vm.scanCard = false;
					vm.constants = constants;
					vm.loaded = false;
					vm.firstSearch = undefined;
					vm.searching = false;
					vm.exporting = false;
					vm.enableSearchPaging = true; // This can be made in to an attribute of the component to allow for paging / non paging searches
					vm.allPromises = [];
					vm.filterVisible = true;

					if (vm.mode === "activity") {
						navigationService.setCurrentMenuStatus(
							navigationService.topMenuCodes.financeManagement,
							navigationService.pageCodes.cardActivity);

					} else {
						navigationService.setCurrentMenuStatus(
							navigationService.topMenuCodes.financeManagement,
							navigationService.pageCodes.cardSearch);
					}

					setGridStateDefaults();

					vm.gridOptions = getGridOptions();
					vm.gridOptions.data = [];
					vm.refdata = {
						locations: null,
						regions: null,
						cardStatuses: [],
						storageTypes: []
					}
					vm.checkIfvalidActivitySearchScenario = function () {
						if (vm.mode === 'activity') {
							if (vm.search.region) {
								return true;
							}
						}
						else if (vm.mode === 'main') {
							return true;
						}
						return false;
					}
					vm.initSearchParms();

					vm.allPromises.push(
						security.getCurrentUserSecurityInfo().then(
							function (result) {
								vm.currentUserSecurityInfo = result;
								vm.hasPermission = (vm.mode === 'main' && result.privileges.SearchCards) || (vm.mode === 'activity' && result.privileges.SearchActivityRequestOrder);
							},
							function () {
								vm.currentUserSecurityInfo = {};
							})
					);
					vm.allPromises.push(
						cardService.getRegionLocationRefData().then(
							function (data) {
								vm.refdata.locations = data.locations;
								vm.refdata.regions = data.regions;
							}
						)
					);
					vm.allPromises.push(
						cardStorageService.getLocationRefData().then(
							function (data) {
								vm.refdata.storageTypes = data.cardStorageLocations;
							}
						)
					);
					vm.allPromises.push(security.getActiveUser(false).then(
						function (data) {
							vm.currentUserLoggedIn = data;
							vm.userRegions = data.regions;

						}
					));
					$q.all(vm.allPromises).then(
						function (result) {
							cardService.mapToModel(constants.cardStatusNames, vm.refdata.cardStatuses);
							if (vm.mode == 'activity') {
								var lookup = _.keyBy(vm.userRegions, function (o) { return o.regionId });
								// find all users where "id" exists in index, one loop, quick lookup.
								var result = _.filter(vm.refdata.regions, function (u) {
									return lookup[u.id] !== undefined;
								});
								vm.refdata.regions = result;
								cardService.regionsForuser = result;
							}
							if (vm.paramNumberFromRouteParams && vm.paramNumberFromRouteParams !== "" && vm.cardStatusFromRouteParams) {
								var status = parseInt(vm.cardStatusFromRouteParams) ? [{ id: parseInt(vm.cardStatusFromRouteParams) }] : [];
								vm.initSearchParms(vm.paramNumberFromRouteParams, status);
								vm.getSearchResults(true);
							}

							else {
								vm.loadSavedFilters();
							}
							vm.loaded = true;

						}, function (data) {
							var test = data;
						});
				};
				// #region Init routines
				vm.initSearchParms = function (paramNumber, cardStatusFromRouteParams) {
					var cardStatus = [];
					if (!cardStatusFromRouteParams) {
						cardStatus = [];
					}
					else {
						cardStatus = cardStatusFromRouteParams;
					}

					vm.search = {
						showBatch: false,
						currentPage: 1,
						recordsPerPage: 100,
						region: null,
						location: null,
						cardId: '',
						orderNumber: '',
						batchNumber: '',
						incidentNumber: '',
						boxId: '',
						searchCardStatuses: cardStatus,
						storageTypes: [],
						excludedCardIds: [],
						serialNumberFrom: '',
						serialNumberTo: '',
						cardRecipientName: '',
						searchMode: vm.mode == "main" ? 1 : 2
					};

					if (angular.isDefined(paramNumber)) {
						if (paramNumber.substring(0, 1) === 'B') {
							vm.search.showBatch = true;
							vm.search.batchNumber = paramNumber;
						}
						else {
							vm.search.showBatch = false;
							vm.search.orderNumber = paramNumber;
						}
					} else {
						vm.search.showBatch = false;
						vm.search.orderNumber = "";
						vm.search.batchNumber = "";
					}

					vm.totalSearchResults = 0;
					vm.noResultsFound = false;
				};
				// #endregion

				// #region Search
				var getSearchCriteria = function (pageResults) {

					var searchCriteria = angular.copy(vm.search);

					var statusIds = [];
					_.map(searchCriteria.searchCardStatuses, function (item) { return statusIds.push(item.id); });
					searchCriteria.searchCardStatuses = statusIds;

					if (!pageResults) {
						searchCriteria.currentPage = undefined;
						searchCriteria.recordsPerPage = undefined;
					}
					return searchCriteria;
				}

				vm.exportToCsv = function () {
					vm.exporting = true;
					var savedData = vm.retrieveFilters();
					//have to use saved data to get the data that is contained in the data grid otherwise it
					//will get the data that is on the screen
					if (savedData) {
						vm.search = savedData;
						
						cardService.exportSearch(getSearchCriteria(false)).then(
							function (response) {
								vm.exporting = false;
								var file = new Blob([response.data], { type: 'text/csv' });
								saveAs(file, 'Cardlist.csv');
							},
							function (response) {
								vm.exporting = false;
							});
					}
				};

				vm.getCard = function () {
					vm.scanCard = false;
				};


				function validSearchCriteria() {
					if (vm.mode == 'activity') {
						if (!vm.search.region &&
							(!vm.search.location || vm.search.location == '') &&
							vm.search.cardId == '' &&
							vm.search.boxId == '' &&
							vm.search.searchCardStatuses.length == 0 &&
							vm.search.serialNumberFrom == '' &&
							vm.search.serialNumberTo == '' &&
							(!vm.search.storageLocation || vm.search.storageLocation.length == 0) &&
							(!vm.search.cardRecipientName || vm.search.cardRecipientName == '')) {
							utils.alert("You must enter at least one region in the search criteria.");
							return false;
						}
						if (vm.checkIfvalidActivitySearchScenario()) {
							return true;
						}
						else {
							utils.alert("You must enter at least one region in the search criteria.");
							return false;
						}
					}
					return true;
				}

				vm.getSearchResults = function (newSearch, pageResults, isScanCard) {
					
					if (!isScanCard ) {

						if (!validSearchCriteria()) return;
					}
					vm.searching = true;
					if (newSearch) {
						vm.gridOptions.data = [];
					}

					vm.gridApi.selection.clearSelectedRows();

					vm.saveFilters();
					var promise = $q.defer();
					var searchCriteria = getSearchCriteria(pageResults);
						if (vm.search.cardId.length > 10) {
							vm.clearFilters();
						}
					
					cardService.cardSearch(searchCriteria).then(
						function (response) {
							vm.totalSearchResults = response.data.totalResultCount;
							vm.noResultsFound = vm.totalSearchResults == 0;	
							vm.scanCard = false;
							
							if (newSearch === false) {
								vm.gridOptions.data = response.data.cardResults;
								if (vm.gridOptions.data && vm.gridOptions.data.length > 0) {
									vm.search.cardId = '';

									var finalRows = vm.gridOptions.data;
									var appendRowsToGrid = function (element) {
										if (element) {
											if (_.filter(finalRows, { cardId: element.cardId }).length === 0) {
												finalRows.unshift(element);
											}
										};
									}
									_(response.data.cardResults).forEach(appendRowsToGrid);
									vm.gridOptions.data = finalRows;
								}

							}
							else {
								vm.gridOptions.data = response.data.cardResults;
							}
							vm.firstSearch = vm.firstSearch == undefined;

							vm.searchComplete = false;
							vm.searching = false;
						}, function (error) {
							vm.searching = false;
							promise.reject();
						});
					return promise.promise;
				};

				// #region Grid
				var openItem = function (row) {
					$location.path("/financeManagement/cards/details/" + row.entity.id + "/" + vm.mode);
				};

				var templateWithTooltip = '<div class="ui-grid-cell-contents" title="Accurate as at {{row.entity.cardBalanceRetreived}}">{{ COL_FIELD }}</div>';

				var getGridColumnDefs = function () {
					return [
						{
							field: 'id',
							visible: false,
							enableHiding: false
						},
						{
							tooltip: 'Card ID',
							field: 'cardId',
							displayName: 'Card ID',
							enableHiding: false,
							enableColumnMenu: false,
							cellTemplate: '<div class="ui-grid-cell-contents">' +
							'<a href="" ng-click="grid.appScope.openItem(row)">{{row.entity.cardId}}</a>' +
                            '</div>',
                            width: '10%'
						},
						{
							field: 'serialNumber',
							displayName: 'Serial number',
							enableHiding: true,
                            enableColumnMenu: false,
                            width: '10%'
						},
						{
							field: 'expiryDate',
							displayName: 'Expiry date',
							enableHiding: true,
                            enableColumnMenu: false,
                            cellClass: 'date-field',
                            cellFilter: constants.GridDateFormat,
                            width: '10%'
						},
						{
							field: 'cardStatus',
							displayName: 'Card status',
							enableHiding: true,
                            enableColumnMenu: false,
                            width: '10%'
						},
						{
							field: 'region',
							displayName: 'Region',
							enableHiding: true,
                            enableColumnMenu: false,
                            width: '10%'
						},
						{
							field: 'location',
							displayName: 'Location',
							enableHiding: true,
                            enableColumnMenu: false,
                            width: '10%'
						},
						{
							field: 'storageType',
							displayName: 'Storage type',
							enableHiding: true,
                            enableColumnMenu: false,
                            width: '10%'
						},
						{
							field: 'boxId',
							displayName: 'Box ID',
							enableHiding: true,
                            enableColumnMenu: false,
                            width: '10%'
						},
						{
							field: 'cardBalance',
                            displayName: 'Balance',
                            type : 'number',
							enableHiding: true,
                            enableColumnMenu: false,
                            cellFilter: 'phapcurrency',
                            cellClass: 'currency',
                            width: '10%'
						},
						{
							field: 'cardValue',
							displayName: 'Card value',
                            enableHiding: true,
                            type: 'number',
                            cellFilter: 'phapcurrency',
                            cellClass: 'currency',
                            enableColumnMenu: false,
                            width: '10%'
						},
						{
							field: 'cardType',
							displayName: 'Card type',
							enableHiding: true,
                            enableColumnMenu: false,
                            width: '10%'
						},
						{
							field: 'active',
							displayName: 'Active',
							enableHiding: true,
                            enableColumnMenu: false,
                            cellTemplate: templateWithTooltip,
                            width: '10%'
						},
						{
							field: 'incidentNumber',
							displayName: 'Incident number',
							enableHiding: true,
                            enableColumnMenu: false,
                            width: '10%'
						},
						{
							field: 'recipientName',
							displayName: 'Recipient name',
							enableHiding: true,
                            enableColumnMenu: false,
                            width: '10%'
						},
						{
							field: 'preloaded',
							displayName: 'Preloaded',
							enableHiding: true,
                            enableColumnMenu: false,
                            width: '10%'
						},
						{
							field: 'checkInDate',
							displayName: 'Check in date',
                            enableHiding: true,
                            cellClass: 'date-field',
                            cellFilter: constants.GridDateFormat,
                            enableColumnMenu: false,
                            width: '10%'
						},
						{
							field: 'checkOutDate',
							displayName: 'Check out date',
							enableHiding: true,
                            enableColumnMenu: false,
                            cellClass: 'date-field',
                            cellFilter: constants.GridDateFormat,
                            width: '10%'
						},
						{
							field: 'checkedOutToName',
							displayName: 'Checked out to',
							enableHiding: true,
                            enableColumnMenu: false,
                            width: '10%'
						}
					]
				};
				function saveState() {
					var state = vm.gridApi.saveState.save();
					localStorageService.set('cardSearch.gridState.' + vm.mode + $cookies.get("UserId"), state);
				}

				function restoreState() {
					$timeout(function () {
						var state = localStorageService.get('cardSearch.gridState.' + vm.mode + $cookies.get("UserId"));
						if (state) vm.gridApi.saveState.restore($scope, state);
					});
				}
				var getGridOptions = function () {
                    return {
						infiniteScrollRowsFromEnd: 10,
						infiniteScrollUp: false,
						enableGridMenu: true,
						infiniteScrollDown: true,
						enableSorting: true,
						enableFiltering: false,
						enableColumnResizing: true,
						enableHorizontalScrollbar: uiGridConstants.scrollbars.ALWAYS,
						enableVerticalScrollbar: uiGridConstants.scrollbars.ALWAYS,
						enableRowSelection: true,
						enableFullRowSelection: true,
						enableRowHeaderSelection: true,
						enableSelectAll: true,
						multiSelect: true,
						enableRowHashing: false,
						columnDefs: getGridColumnDefs(),
						appScopeProvider: {
							openItem: function (row) {
								openItem(row);
							}
						},
						rowTemplate:
						'<div ng-click="grid.appScope.selectRow();"> ' +
						'<div class="ui-grid-cell" ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" ui-grid-cell ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader }" style="cursor:pointer"> ' +
						'</div>' +
						'</div>',
						onRegisterApi: function (gridApi) {
							vm.gridApi = gridApi;
							vm.gridApi.core.on.rowsRendered($scope, rowsRendered);
							vm.gridApi.colResizable.on.columnSizeChanged($scope, saveState);
							vm.gridApi.core.on.columnVisibilityChanged($scope, saveState);
							vm.gridApi.core.on.filterChanged($scope, saveState);
							vm.gridApi.core.on.sortChanged($scope, saveState);
							// call resize every 500 ms for 5 s after modal finishes opening - usually only necessary on a bootstrap modal
							$interval(function () {
								vm.gridApi.core.handleWindowResize();
							}, 500, 10);
							// Restore previously saved state.
							restoreState();
						}
					}
				};

				var rowsRendered = function () {

					vm.filteredRecords = vm.gridApi.core.getVisibleRows(vm.gridApi.grid).length;
					if ($routeParams.orderNumber && vm.firstSearch && vm.gridOptions.data.length > 0) {
						vm.gridApi.selection.selectAllVisibleRows();
					}
					else {
						vm.gridApi.selection.clearSelectedRows();
					}
				};

				var checkCardStatusValidForAssignment = function (selectedRows) {
					var result = !_.some(selectedRows, function (item) {
						return (item.cardStatus != _.find(constants.cardStatusNames, function (s) { return s.id == constants.cardStatus.stored; }).name
							&& item.cardStatus != _.find(constants.cardStatusNames, function (s) { return s.id == constants.cardStatus.received; }).name);
					});
					if (!result) {
						utils.alert('The card status is invalid.  Please ensure the status is Stored or Received.');
					}
					return result;
				}

				var checkCardStatusValidForCheckout = function (selectedRows, message) {
					var result = !_.some(selectedRows, function (item) {
						return (item.cardStatus != _.find(constants.cardStatusNames,
							function (s) { return s.id == constants.cardStatus.stored; }).name);
					});
					if (!result) {
						utils.alert(message);
					}
					return result;
				}

				var checkCardStatusValidForCheckin = function (selectedRows, message) {
					var result = !_.some(selectedRows, function (item) {
						return (item.cardStatus != _.find(constants.cardStatusNames,
							function (s) { return s.id == constants.cardStatus.checkedOut; }).name);
					});
					if (!result) {
						utils.alert(message);
					}
					return result;
				}

				var checkCardLocationValidForAssignment = function (selectedRows, message) {
					var uniqueLocation = _.uniq(_.map(selectedRows, 'location'));
					if (uniqueLocation) {
						if (uniqueLocation.length > 1) {
							utils.alert(message);
							return false;
						}
						else return true;
					}
					return false;
				}

				// #endregion

				// #region Filters 
				// TODO: FM: Add page to filters to allow for caching per page if this component is used on multiple pages ??
				vm.clearFilters = function () {
					vm.initSearchParms();
					vm.gridOptions.data = [];
					if (vm.gridApi && vm.gridApi.selection) {
						vm.gridApi.selection.clearSelectedRows();
					}

					if (vm.enableSearchPaging) {
						$timeout(function () {
							// timeout required to allow for digest cycle to complete and grid to finish refresh.
							vm.gridApi.infiniteScroll.resetScroll(false, false);
						});
					}
					vm.filterVisible = true;
					cacheService.removeItemFromCache('cardSearchFilters.' + vm.mode + $cookies.get("UserId"));
				};

				vm.saveFilters = function () {
					cacheService.saveToCache('cardSearchFilters.' + vm.mode + $cookies.get("UserId"), vm.search);
				};

				vm.retrieveFilters = function () {
					return cacheService.readFromCache('cardSearchFilters.' + vm.mode + $cookies.get("UserId"));
				};

				vm.loadSavedFilters = function () {
					var savedData = vm.retrieveFilters();
					if (savedData) {
						vm.search = savedData;
					} else {
						vm.clearFilters();
					}
				}
				// #endregion

				// #region Button methods
				vm.bulkUpload = function () {
					$location.path("/financeManagement/cards/cardBulkUpload");
				};

				vm.doesUserbelongToTheRegionAndMinimumRowsSelected = function () {
					var selectedRows = vm.gridApi.selection.getSelectedRows();
					if (selectedRows.length > 0) {
						//vm.usersInRegion = result;
						var regionid = _.find(vm.refdata.regions, function (r) { return r.name == selectedRows[0].region; }).id;

						if (_.some(vm.userRegions, function (i) {
							return i.userId === vm.currentUserLoggedIn.id && i.regionId === regionid;
						})) {
							return true;
						};
					}
					return false;
				}

				vm.hasSelectedRows = function () {
					vm.selectedRows = vm.gridApi.selection.getSelectedRows();
					return vm.selectedRows.length > 0;
				}

				vm.onAssignLocation = function () {
					var selectedRows = vm.gridApi.selection.getSelectedRows();
					$scope.location = selectedRows[0].location;
					if (checkCardStatusValidForAssignment(selectedRows, constants.errorMessage.invalidStatusForStoreLocation)
						&& checkCardLocationValidForAssignment(selectedRows, constants.errorMessage.invalidMultipleLocation)) {
						$scope.selectedCards =
							_.map(selectedRows,
								function (c) {
									return {
										id: c.id,
										rowVersion: c.rowVersion,
										selected: true
									};
								});
						$scope.openModal("app/financeManagement/cards/assignCardLocation.html", "assignCardLocation", $scope);
					}
				}

				vm.onCheckoutCards = function () {
					var selectedRows = vm.gridApi.selection.getSelectedRows();
					if (checkCardStatusValidForCheckout(selectedRows, constants.errorMessage.invalidStatusForCheckout)
						&& checkCardLocationValidForAssignment(selectedRows, constants.errorMessage.invalidMultipleLocation)) {
						$scope.regionId = _.find(vm.refdata.regions, function (r) { return r.name == selectedRows[0].region; }).id;
						$scope.location = selectedRows[0].location;
						$scope.selectedCards =
							_.map(selectedRows,
								function (c) {
									return {
										id: c.id,
										rowVersion: c.rowVersion,
										selected: true
									};
								});
						$scope.openModal("app/financeManagement/cards/checkoutCard.html", "checkoutCard", $scope);

					}
				}

				vm.onCheckinCards = function () {
					var selectedRows = vm.gridApi.selection.getSelectedRows();
					if (checkCardStatusValidForCheckin(selectedRows, constants.errorMessage.invalidStatusForCheckin)
						&& checkCardLocationValidForAssignment(selectedRows, constants.errorMessage.invalidMultipleLocation)) {

						vm.selectedCards =
							_.map(selectedRows,
								function (c) {
									return {
										id: c.id,
										rowVersion: c.rowVersion,
										selected: true
									};
								});
						var title = 'Checkin cards';
						var content = "The selected cards will be checked in and made available for re-distribution.</br>Are you sure you want to return the cards?";
						utils.confirm(title, content, 'Proceed', 'Cancel')
							.then(function () {
								$scope.updating = true;
								var model = {
									cards: [],
								};
								model.cards = vm.selectedCards;
								cardService.checkInCards(model).then(
									function (data) {
										if (data == "604") {
											utils.alert(constants.cardsStatusInvalid)
										}
										if (data == "605") {
											utils.alert(constants.cardsStatusInactive)
										}
										else {
											if (data && data.length > 0) {
												vm.getSearchResults(true);
											}
										}
									}
								);

							}, function () {
								return;
							});
					}
				}


				$scope.openModal = function (templateUrl, controller, scope, resolve, windowClass) {
					if ($scope.dlg === undefined) {
						$scope.dlg = {
							location: $scope.location,
							regionId: $scope.regionId,
							selectedCardNumbers: $scope.selectedCards
						};
					}
					utils.openModal(templateUrl, controller, scope, resolve, 'storage-location-modal');
					// NOTE: Please note that this is called from the assignCardLocation.js file when it closes to refresh the data, hence 
					// it is located on the $scope (the 'broadcast/on' event bubbling does not appear to be working hence this approacah)
					$scope.loadData = function () {
						vm.getSearchResults(true);

					};
				};
				// #endregion

				// #region Drop filter
				vm.statusTypeDropdownTranslationTexts = {
					buttonDefaultText: "All statuses",
					uncheckAll: "Clear filter"
				};

				vm.statusDropdownSettings = {
					smartButtonMaxItems: 3,
					smartButtonTextConverter: function (item) { return item; },
					buttonClasses: "btn-multi-select-bare",
					showCheckAll: false
				};

				vm.setCardActivityType = function (value, mode) {
					vm.activityTitle = mode + " card activity request  - " + value;
				}
				vm.setRegionUserAccess = function (activityRequestOrderRegionId) {
					var regionMatch = _.find(vm.userRegions, function (o) {
						return o.regionId === activityRequestOrderRegionId;
					});
					if (regionMatch) {
						vm.doesUserHaveOrderRequestRegionId = true;
					}
					else {
						vm.doesUserHaveOrderRequestRegionId = false;
					}
				}

				// #endregion
				function setGridStateDefaults() {
					var state = localStorageService.get('cardSearch.gridState.' + vm.mode + $cookies.get("UserId"));
					if (!state) {
						var defaultState = {
							"columns": [{ "name": "id", "visible": false, "width": "*", "sort": {}, "filters": [{}] },
							{ "name": "cardId", "visible": true, "width": 159, "sort": {}, "filters": [{}] },
							{ "name": "serialNumber", "visible": true, "width": 136, "sort": {}, "filters": [{}] },
							{ "name": "expiryDate", "visible": true, "width": 105, "sort": {}, "filters": [{}] },
							{ "name": "cardStatus", "visible": true, "width": 105, "sort": {}, "filters": [{}] },
							{ "name": "region", "visible": true, "width": 129, "sort": {}, "filters": [{}] },
							{ "name": "location", "visible": false, "width": 149, "sort": {}, "filters": [{}] },
							{ "name": "storageType", "visible": false, "width": 141, "sort": {}, "filters": [{}] },
							{ "name": "boxId", "visible": false, "width": "*", "sort": {}, "filters": [{}] },
							{ "name": "cardBalance", "visible": true, "width": "*", "sort": {}, "filters": [{}] },
							{ "name": "cardValue", "visible": true, "width": "*", "sort": {}, "filters": [{}] },
							{ "name": "cardType", "visible": true, "width": "*", "sort": {}, "filters": [{}] },
							{ "name": "incidentNumber", "visible": false, "width": "*", "sort": {}, "filters": [{}] },
							{ "name": "preloaded", "visible": true, "width": "*", "sort": {}, "filters": [{}] },
							{ "name": "checkInDate", "visible": false, "width": "*", "sort": {}, "filters": [{}] },
							{ "name": "checkOutDate", "visible": false, "width": "*", "sort": {}, "filters": [{}] },
							{ "name": "checkedOutToName", "visible": false, "width": "*", "sort": {}, "filters": [{}] },
							{ "name": "recipientName", "visible": false, "width": "*", "sort": {}, "filters": [{}] }],
							"scrollFocus": {}, "selection": [], "grouping": {}, "treeView": {}, "pagination": {}
						};
						if (vm.mode == "activity") {
							defaultState = {
								"columns": [{ "name": "id", "visible": false, "width": "*", "sort": {}, "filters": [{}] },
								{ "name": "cardId", "visible": true, "width": 113, "sort": {}, "filters": [{}] },
								{ "name": "serialNumber", "visible": true, "width": 126, "sort": {}, "filters": [{}] },
								{ "name": "expiryDate", "visible": false, "width": 105, "sort": {}, "filters": [{}] },
								{ "name": "cardStatus", "visible": true, "width": 109, "sort": {}, "filters": [{}] },
								{ "name": "region", "visible": false, "width": 129, "sort": {}, "filters": [{}] },
								{ "name": "location", "visible": true, "width": 166, "sort": {}, "filters": [{}] },
								{ "name": "storageType", "visible": true, "width": 124, "sort": { "priority": 0, "direction": "desc" }, "filters": [{}] },
								{ "name": "boxId", "visible": true, "width": 73, "sort": {}, "filters": [{}] },
								{ "name": "cardBalance", "visible": true, "width": 87, "sort": {}, "filters": [{}] },
								{ "name": "cardValue", "visible": true, "width": 96, "sort": {}, "filters": [{}] },
								{ "name": "cardType", "visible": false, "width": "*", "sort": {}, "filters": [{}] },
								{ "name": "incidentNumber", "visible": false, "width": "*", "sort": {}, "filters": [{}] },
								{ "name": "preloaded", "visible": false, "width": 105, "sort": {}, "filters": [{}] },
								{ "name": "checkInDate", "visible": false, "width": "*", "sort": {}, "filters": [{}] },
								{ "name": "checkOutDate", "visible": false, "width": "*", "sort": {}, "filters": [{}] },
								{ "name": "recipientName", "visible": true, "width": "*", "sort": {}, "filters": [{}] },
								{ "name": "checkedOutToName", "visible": false, "width": "*", "sort": {}, "filters": [{}] }],
								"scrollFocus": {}, "selection": [], "grouping": {}, "treeView": {}, "pagination": {}
							}
						}
						localStorageService.set('cardSearch.gridState.' + vm.mode + $cookies.get("UserId"), defaultState);
					}

				}



			}
		],
		controllerAs: 'cardSearch'
	});
})();