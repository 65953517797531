(function () {
    angular.module('phapApp')
        .service('configurationService', ['$http', '$q', '$filter', 'utils', 'config', 'Constants', function ($http, $q, $filter, utils, config, Constants) {

            var service = this;
            service.configurationValuesConfig;

            var constants = {
                configurationValueUrl: "configurationValue",
                houseHoldUrl: "household"
            };

            var getReestablishmentConfigurations = function () {
                var deferred = $q.defer();

                if (!service.configurationValuesConfig) {
                    var res = $http.get(config.apiBaseUrl + constants.configurationValueUrl)
                        .then(function (data) {
                            service.configurationValuesConfig = data.data;
                            deferred.resolve(service.configurationValuesConfig);
                        }, function (data) {
                            deferred.reject(data);
                        });
                } else {
                    deferred.resolve(service.configurationValuesConfig);
                }

                return deferred.promise;
            };
            
            var getConfigurationValue = function (id) {
                return $http.get(config.apiBaseUrl + constants.configurationValueUrl + "/" + id);
            };

            var saveConfigurationValue = function (configurationValue) {
                var deferred = $q.defer();
                var res = $http.post(config.apiBaseUrl + constants.configurationValueUrl + "/update", configurationValue)
                    .then(function (data) {
                        deferred.resolve(data);
                    }, function (data) {
                        deferred.reject(data);
                    });

                return deferred.promise;
            };
            
            var modifyValuesBeforeApplying = function (data) {
                if (data.futurePeriod && data.futurePeriod.effectiveFromDate)
                    data.futurePeriod.effectiveFromDate = new Date(data.futurePeriod.effectiveFromDate);
                var periodsList = angular.copy(data.periods);
                data.periodsList = setPeriodListForView(periodsList);
            };

            var setCanAddFutureDate = function (data) {
                data.futurePeriod = null;
                data.currentPeriod = null;
                data.periods.forEach(function (value, key) {
                    var today = new Date($filter('date')(new Date()));
                    var fromDate = new Date(value.effectiveFromDate);
                    var toDate = null;
                    if (value.effectiveToDate != null)
                        toDate = new Date(value.effectiveToDate);
                    if (toDate == null && fromDate > today)
                        data.futurePeriod = value;
                    else if (fromDate <= today && (toDate == null || toDate >= today))
                        data.currentPeriod = value;
                });
                if (data.futurePeriod == null)
                    data.canAddFuturePeriod = true;
                else
                    data.canAddFuturePeriod = false;
            };

            var validation = function (currentData) {
                if (utils.isNullOrEmpty(currentData.value))
                    return { isValid: false, error: 'Value required' };
                if (utils.isNullOrEmpty(currentData.effectiveFromDate))
                    return { isValid: false, error: 'Effective from date required' };
                else
                {
                    var today = new Date($filter('date')(new Date()));
                    var futureDate = new Date(currentData.effectiveFromDate);
                    if (futureDate <= today)
                        return { isValid: false, error: 'Forecast date should be greater than today\'s date' };
                }
                return { isValid: true, error: '' }
            }


            var setUpdateValueRules = function (data) {
                setCanAddFutureDate(data);
                modifyValuesBeforeApplying(data);
            };

            var setPeriodListForView = function (periods) {
                var viewPeriodList = [];
                periods.forEach(function (item) {
                    var today = new Date();
                    var fromDate = new Date(item.effectiveFromDate)
                    if (item.effectiveToDate != null && new Date(item.effectiveToDate) < today)
                        viewPeriodList.push(item);
                });
                return viewPeriodList;
            };

            //house hold functions
            var houseHoldValidation = function (options) {
                if (utils.isNullOrEmpty(options.effectiveFromDate))
                    return { isValid: false, error: 'Forecast start date required' };
                else {
                    var today = new Date($filter('date')(new Date()));
                    var futureDate = new Date(options.effectiveFromDate);
                    if (futureDate <= today)
                        return { isValid: false, error: 'Forecast date should be greater than today\'s date' };
                }
                var isValueNull;
                var isValueNumber = true;
                options.items.forEach(function (item) {
                    if (utils.isNullOrEmpty(item.futurePeriod.value))
                        isValueNull = true;
                    if (!angular.isNumber(item.futurePeriod.value*1))
                        isValueNumber = false;
                });
                if (isValueNull)
                    return { isValid: false, error: 'Value required ' };
                if (!isValueNumber)
                    return { isValid: false, error: 'Value has to be a number ' };
                return { isValid: true, error: '' }
            }
            var getHouseHoldDetails = function () {
                return $http.get(config.apiBaseUrl + constants.houseHoldUrl);
            };

            var setCurrentAndFutureVersions = function (data) {
                data.futurePeriod = null;
                data.currentPeriod = null;
                data.periods.forEach(function (value, key) {
                    var today = new Date();
                    var fromDate = new Date(value.set.effectiveFromDate);
                    var toDate = null;
                    if (value.effectiveToDate != null)
                        toDate = new Date(value.set.effectiveToDate);
                    if (toDate == null && fromDate > today) {
                        data.futurePeriod = value;
                        data.futurePeriodOriginal = angular.copy(data.futurePeriod);
                    }
                    else if (fromDate <= today && (toDate == null || toDate >= today)) {
                        data.currentPeriod = value;
                        data.selectedPeriod = value;
                    }

                });
                if (data.futurePeriod == null)
                    data.canAddFuturePeriod = true;
                else 
                    data.canAddFuturePeriod = false;
            };

            var setVersions = function (data) {
                data.items.forEach(function (item) {
                    setCurrentAndFutureVersions(item);
                });
            };

            var setPreviousVersion = function (data, setId) {
                data.selectedPeriod = null;
                data.periods.forEach(function (item) {
                    if (item.set.id == setId)
                        data.selectedPeriod = item;
                });
            };

            var setPreviousVersions = function (data) {
                if (data.selectedVersion != null) {
                    data.items.forEach(function (item) {
                        setPreviousVersion(item, data.selectedVersion.id);
                    });
                    try {
                        if (data.items[0].futurePeriod.set.id == data.selectedVersion.id)
                            data.effectiveFromDate = new Date($filter('date')(data.selectedVersion.effectiveFromDate, Constants.DatePickerFormat));
                    }
                    catch (err) {}
                }
            };

            var setHouseHoldPeriodListForView = function (data) {
                data.periodsList =[];
                data.items[0].periods.forEach(function (period) {
                    data.periodsList.push(period.set);
                });
                var today = new Date($filter('date')(new Date()));
                data.periodsList.forEach(function (period) {
                    var from = new Date(period.effectiveFromDate);
                    var to = null;
                    if (period.effectiveToDate != null)
                        to = new Date(period.effectiveToDate);
                    if (from <= today && (to >= today || to == null))
                        data.selectedVersion = period;
                });

            };

            var setHouseHoldValueRules = function (data) {
                setHouseHoldPeriodListForView(data);
                setVersions(data);
                setPreviousVersions(data);
            };
            var saveHouseHoldValue = function (items) {
                var deferred = $q.defer();
                var res = $http.post(config.apiBaseUrl + constants.houseHoldUrl + "/update", items)
                    .then(function (data) {
                        deferred.resolve(data);
                    }, function (data) {
                        deferred.reject(data);
                    });

                return deferred.promise;
            };

            var remove = function (id, api) {
                var deferred = $q.defer();
                var res = $http.post(config.apiBaseUrl + api + "/remove/" + id)
                    .then(function (result) {
                        deferred.resolve(result);
                    }, function (result) {
                        deferred.reject(result);
                    });

                return deferred.promise;
            };

            var deleteHouseHold = function (id) {
                return remove(id, constants.houseHoldUrl);
            };
            
            //return all functions
            return {
                getHouseHoldDetails: getHouseHoldDetails,
                getReestablishmentConfigurations: getReestablishmentConfigurations,
                getConfigurationValue: getConfigurationValue,
                saveConfigurationValue: saveConfigurationValue,
                setUpdateValueRules: setUpdateValueRules,
                setHouseHoldValueRules: setHouseHoldValueRules,
                validation: validation,
                setPreviousVersions: setPreviousVersions,
                saveHouseHoldValue: saveHouseHoldValue,
                houseHoldValidation: houseHoldValidation,
                deleteHouseHold: deleteHouseHold
            };


        }]);
})();