(function () {
    angular.module('phapApp')
        .service('referenceService', ['$http', '$q', 'config', function ($http, $q, config) {

            var constants = {
                incidentTypeUrl: "reference/incidenttype",
                incidentStatusUrl: "reference/incidentstatus",
                emergencyTypeUrl: "reference/emergencytype",
                genderUrl: "reference/gender",
                idTypeUrl: "reference/idtype",
                errorRefsUrl: "reference/errorrefs",
                glStringConfigUrl: "reference/gl-string-config",
                immediateReliefUrl: "reference/immediaterelieftype",
                emergencyReliefPaymentUrl: "reference/emergencyeeliefpayment",
                reestablishmentAmountUrl: "reference/reestablishmentamount",
                principlePlaceOfResidenceUrl: "reference/principlePlaceOfResidence",
                weeklyIncomeThresholdUrl: 'reference/weeklyincomethreshold',
                regionUrl: 'reference/region',
                divisionUrl: 'reference/division'
            };

            var get = function (api, method, isCache) {
                return $http.get(config.apiBaseUrl + api + method, { cache: isCache});
            }

            var getAll = function (api) {
                return get(api, "/get");
            }

            var save = function (data, api) {
                var deferred = $q.defer();
                var res = $http.post(config.apiBaseUrl + api + "/save", data)
                    .then(function (result) {
                        deferred.resolve(result);
                    }, function (result) {
                        deferred.reject(result);
                    });

                return deferred.promise;
            }

            var remove = function (id, api) {
                var deferred = $q.defer();
                var res = $http.post(config.apiBaseUrl + api + "/remove/" + id)
                    .then(function (result) {
                        deferred.resolve(result);
                    }, function (result) {
                        deferred.reject(result);
                    });

                return deferred.promise;
            }

            var getIncidentTypes = function () {
                return getAll(constants.incidentTypeUrl);
            };

            var getIncidentStatuses = function () {
                return getAll(constants.incidentStatusUrl);
            };

            var getEmergencyTypes = function () {
                return getAll(constants.emergencyTypeUrl);
            };

            var getGenderList = function () {
                return getAll(constants.genderUrl);
            };

            var getIdTypes = function() {
                return getAll(constants.idTypeUrl);
            };

            var getErrorRefs = function() {
                return getAll(constants.errorRefsUrl);
            };

            var getEmergencyReliefPaymentVersions = function () {
                return get(constants.emergencyReliefPaymentUrl, "/getversions",false);
            };

            var getEmergencyReliefPaymentVersion = function (id) {
                return get(constants.emergencyReliefPaymentUrl, "/version/" + id, false);
            };

            var getGlStringConfig = function () {
                return get(constants.glStringConfigUrl, "/all-versions", false);
            };

            var getReestablishmentAmountVersions = function () {
                return get(constants.reestablishmentAmountUrl, "/getversions", false);
            };

            var getReestablishmentAmountVersion = function (id) {
                return get(constants.reestablishmentAmountUrl, "/version/" + id, false);
            };

            var getImmediateReliefTypes = function () {
                return getAll(constants.immediateReliefUrl);
            };

            var getPrinciplePlaceOfResidence = function () {
                return get(constants.principlePlaceOfResidenceUrl ,"/get",false);
            };

            var getWeeklyIncomeThresholdVersions = function () {
                return get(constants.weeklyIncomeThresholdUrl, "/getversions", false);
            };

            var getWeeklyIncomeThresholdVersion = function (id) {
                return get(constants.weeklyIncomeThresholdUrl, "/version/" + id, false);
            };

            var getDivisionList = function (id) {
                return getAll(constants.divisionUrl);
            };

            var getRegionList = function (id) {
                return getAll(constants.regionUrl);
            };

            var saveIncidentTypes = function (data) {
                var incidentTypes = {
                    data: data
                };
                return save(incidentTypes, constants.incidentTypeUrl);
            };

            var saveGender = function (data) {
                var genderList = {
                    data: data
                };
                return save(genderList, constants.genderUrl);

            };

            var saveIdTypes = function (data) {
                var idTypes = {
                    data: data
                };
                return save(idTypes, constants.idTypeUrl);
            };

            var saveImmediateReliefTypes = function (data) {
                var immediateReliefTypes = {
                    data: data
                };
                return save(immediateReliefTypes, constants.immediateReliefUrl);
            };

            var saveWeeklyIncomeThreshold = function (data) {
                // clear the created and updated by users (not their ids) as the payload can be large due to the inclusion of roles and role privileges
                _.each(data.Data, function (d) {
                    d.data.createdByUser = null;
                    d.data.updatedByUser = null;
                    d.data.incomeLimitOne.createdByUser = null;
                    d.data.incomeLimitOne.updatedByUser = null;
                    d.data.incomeLimitThree.createdByUser = null;
                    d.data.incomeLimitThree.updatedByUser = null;
                    d.data.incomeLimitTwo.createdByUser = null;
                    d.data.incomeLimitTwo.updatedByUser = null;
                })
                return save(data, constants.weeklyIncomeThresholdUrl);
            };

            var saveEmergencyReliefPayments = function (data) {
                // clear the created and updated by users (not their ids) as the payload can be large due to the inclusion of roles and role privileges
                _.each(data.Data, function (d) {
                    d.data.createdByUser = null;
                    d.data.updatedByUser = null;
                });
                return save(data, constants.emergencyReliefPaymentUrl);
            };

            var saveDivision = function (data) {
                var divisionList = {
                    data: data
                };
                return save(divisionList, constants.divisionUrl);

            };

            var saveRegion = function (data) {
                var regionList = {
                    data: data
                };
                return save(regionList, constants.regionUrl);

            };
           
            var deleteEmergencyReliefPayments = function (id) {
                return remove(id, constants.emergencyReliefPaymentUrl);
            };

            var saveReestablishmentAmount = function (data) {
                // clear the created and updated by users (not their ids) as the payload can be large due to the inclusion of roles and role privileges
                _.each(data.Data, function (d) {
                    d.data.createdByUser = null;
                    d.data.updatedByUser = null;
                });
                return save(data, constants.reestablishmentAmountUrl);
            };

            var deleteReestablishmentAmount = function (id) {
                return remove(id, constants.reestablishmentAmountUrl);
            };

            var deleteWeeklyIncomeThreshold=function(id) {
                return remove(id, constants.weeklyIncomeThresholdUrl);
            }
            var savePrinciplePlaceOfResidence = function (data) {
                var principlePlaceOfResidence = {
                    data: data
                };
                var deferred = $q.defer();
                var res = $http.post(config.apiBaseUrl + constants.principlePlaceOfResidenceUrl + "/save", principlePlaceOfResidence)
                    .then(function (data) {
                        deferred.resolve(data);
                    }, function (data) {
                        deferred.reject(data);
                    });

                return deferred.promise;
            };

            return {
                getIncidentTypes: getIncidentTypes,
                saveIncidentTypes: saveIncidentTypes,
                getEmergencyTypes: getEmergencyTypes,
                getIncidentStatuses: getIncidentStatuses,
                getGenderList: getGenderList,
                getIdTypes: getIdTypes,
                getErrorRefs: getErrorRefs,
                getImmediateReliefTypes: getImmediateReliefTypes,
                getEmergencyReliefPaymentVersions: getEmergencyReliefPaymentVersions,
                getEmergencyReliefPaymentVersion: getEmergencyReliefPaymentVersion,
                getReestablishmentAmountVersion: getReestablishmentAmountVersion,
                getReestablishmentAmountVersions: getReestablishmentAmountVersions,
                getGlStringConfig: getGlStringConfig,
                getPrinciplePlaceOfResidence: getPrinciplePlaceOfResidence,
                getWeeklyIncomeThresholdVersions: getWeeklyIncomeThresholdVersions,
                getWeeklyIncomeThresholdVersion: getWeeklyIncomeThresholdVersion,
                getDivisionList: getDivisionList,
                getRegionList: getRegionList,
                saveGender: saveGender,
                saveImmediateReliefTypes: saveImmediateReliefTypes,
                saveIdTypes: saveIdTypes,
                saveEmergencyReliefPayments: saveEmergencyReliefPayments,
                savePrinciplePlaceOfResidence: savePrinciplePlaceOfResidence,
                saveWeeklyIncomeThreshold: saveWeeklyIncomeThreshold,
                saveDivision: saveDivision,
                saveRegion: saveRegion,
                deleteEmergencyReliefPayments: deleteEmergencyReliefPayments,
                savePrinciplePlaceOfResidence: savePrinciplePlaceOfResidence,
                saveReestablishmentAmount: saveReestablishmentAmount,
                deleteReestablishmentAmount: deleteReestablishmentAmount,
                deleteWeeklyIncomeThreshold: deleteWeeklyIncomeThreshold
               
            };


        }]);
})();