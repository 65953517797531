(function () {
    angular.module('phapApp')
        .directive('confirmOnExit', function () {
            var msg = 'There are unsaved changes, are you sure you want to leave this page?';
            return {
                require: 'form',
                link: function ($scope, elem, attr, form) {
                    window.onbeforeunload = function () {
                        if (form.$dirty) {
                            return msg;
                        }
                    };
                    $scope.$on('$routeChangeStart', function (e, next, current) {
                        if (form.$dirty) {
                            if (!confirm(msg)) {
                                e.preventDefault();
                            }
                        }
                    });
                }
            };
        });
})();