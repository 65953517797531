(function () {
	angular.module('phapApp')
		.service('security', ['$http', '$q', '$cookies', 'config', 'Constants', 'utils', '$location', '$rootScope',
			function ($http, $q, $cookies, config, Constants, utils, $location, $rootScope) {

				var activeUser = null;

				var currentUserSecurityInfo = {
					regionsForUser: function (refData) {
						var divisions = this.divisions;
						return _.filter(refData.regions, function (region) {
							return _.some(refData.regionDivisionMappings, function (rdm) {
								// Which are mapped to a division in the current user's list
								if (region.id !== rdm.regionId) return false;

								return _.indexOf(
									divisions,
									rdm.divisionId
								) >= 0;
							});
						});
					},
					userHasAccessToRegion: function (refData, regionId) {
						if (regionId) {
							var isRegionCurrentRegion = function (r) {
								return r.id === regionId;
							}
							return !!_.find(this.regionsForUser(refData), isRegionCurrentRegion);
						} else {
							return true;
						}
					},
					userHasAccessToDivision: function (divisionId) {
						if (divisionId) {
							return 0 <= _.indexOf(this.divisions, divisionId);
						} else {
							return true;
						}
					},
					userHasAccessToUpdateRelief: function (reliefId, incident) {
						// relief form: incident is NOT closed, user has access to this division, user has privileges
						// uesr can still modify relief if the incident is closed
						if (incident) {
							return (incident.status !== Constants.IncidentStatus.Closed || reliefId > 0)
                                && ((this.privileges.UpdateRelief && this.userHasAccessToDivision(incident.divisionId))
								 || this.privileges.UpdateReliefInAnyDivision);
						}
					},
					userHasAccessToUpdateReestablish: function (reestablishId, incident) {
						if (incident) {
							return (incident.status !== Constants.IncidentStatus.Closed || reestablishId > 0)
                                && ((this.privileges.UpdateReestablish && this.userHasAccessToDivision(incident.divisionId))
								    || this.privileges.UpdateReestablishInAnyDivision);
						}
					},

					userIsInRegion: function (cardShipmentRegionId, regions) {
						return _.find(regions, function (c) { return c.regionId === cardShipmentRegionId; }) !== undefined;
					}

				};

				var getCurrentUserSecurityInfo = function (refresh) {
					var deferred = $q.defer();

					if (!currentUserSecurityInfo.privileges || refresh) {
						var url = config.apiBaseUrl + 'security/current-user';
						$http.get(url).then(
							function (result) {
                                if (result && result.data) {
									var info = {
										privileges: {},
                                        divisions: result.data.divisions,
                                        givenName: result.data.givenName,
                                        surname: result.data.surname
									};

									$cookies.put('UserId', result.data.userId);

									_.each(result.data.privileges, function (p) {
										info.privileges[p] = true;
									});

									_.extend(currentUserSecurityInfo, info);
									deferred.resolve(currentUserSecurityInfo);
								} else {
									deferred.reject(data);
								}
							},
							function (data) {
								deferred.reject(data);
							});
					} else {
						deferred.resolve(currentUserSecurityInfo);
					}
					return deferred.promise;
				}

				var getUserId = function () {
					return $cookies.get("UserId");
				}

                var getLocationsForCurrentUser = function () {
                    var deferred = $q.defer();
                    var res = $http.post(config.apiBaseUrl + 'security/location-user')
                        .then(function (response, status, headers, config) {
                            deferred.resolve(response.data);
                        }, function (error, status, headers, config) {
                            deferred.reject(data);
                        });
                    return deferred.promise;
                }

				return {
					getUserId: getUserId,
                    getCurrentUserSecurityInfo: getCurrentUserSecurityInfo,
                    getLocationsForCurrentUser: getLocationsForCurrentUser,

					getAllUsersWithPrivelegeByRegionId: function (regionId, privilege) {
						var search = {
							RegionId: regionId,
							Privilege: privilege
						};
						var url = config.apiBaseUrl + "security/users-privilege-region";
						return $http.post(url, search).then(
							function (result) {
								return usersAndPrivelageInRegion = result.data;

							});
					},
					getActiveUser: function () {
						var deferred = $q.defer();
						if (!activeUser) {
							var userId = $cookies.get("UserId");

							var url = config.apiBaseUrl + "security/users/user/" + userId;
							$http.get(url).then(function (result) {
								activeUser = result.data;
								deferred.resolve(activeUser);
							});
						} else {
							deferred.resolve(activeUser);
						}

						return deferred.promise;
					},
					getAllUsers: function (refresh) {
						var url = config.apiBaseUrl + "security/all-users";
						return $http.get(url, { cache: !refresh }).then(function (result) {
							return result.data.users;
						});
					}
				}
			}]);
})();