(function () {
	var app = angular.module('phapApp');

	app.directive('reestablishPayment', ['$window', function ($window) {
		return {
			replace: true,
			templateUrl: 'app/home/reestablish/reestablishPayment.html',
			controller: ['$element', '$scope', 'reestablishService', '$location', '$filter', 'utils', '$http', '$timeout', 'Constants', '$q', 'notificationService', 'locationService', '$cookies',
				function ($element, $scope, reestablishService, $location, $filter, utils, $http, $timeout, constants, $q, notificationService, locationService, $cookies) {
					$scope.showLegalDeclarationContent = true;
					$scope.members = [];
					$scope.payment = {};
					$scope.payment.showdistributionPoint = false;
					$scope.showPaymentDetails = false;
					$scope.showDetails = true;
					$scope.showWarning = false;
					$scope.pageModes = constants.pageMode;
					InvalidGstAmount = false;

					var isRunningInExtraSmallScreen = $(document).width() < 768;
					$scope.$on('dataLoaded', function () {
						initializeData();
					});

					$scope.disableInvoiceReference = function () {
						if ($scope.payment.paymentDetails.flaggedForResubmission) {
							return false;
						} else {
							if ($scope.payment.paymentDetails.directDepositPayment)
								return !$scope.payment.paymentDetails.directDepositPayment.gstInclusive;
							else
								return false;
						}
					};
					$scope.refreshOpenedItem = function (paymentId) {
						var pay = _.find($scope.item.payments, { paymentId: paymentId });
						if (pay) {
							$scope.openItem(pay);							
							return true;
						}
					};

					$scope.$root.$on('updateReestablishAdjustmentPayment', function (event, args) {
						if (args.totalAdjustmentAmount != null) {
							$scope.adjustmentAmount = args.totalAdjustmentAmount;
						}

						if (args.remainingEntitlementAmount != null) {
							$scope.remainingAmount = args.remainingEntitlementAmount;
						}
					});

					// opens the particular row for viewing
					$scope.openItem = function (row) {
						var openItemCore = function () {
							setPristine();
							$scope.payment.pageMode = $scope.pageModes.view;
							$scope.showPaymentDetails = true;
							if (row.declarationAccepted) {
								$scope.setAsReadonly = true;
								$scope.payment.openLegalDeclaration = true;
								$scope.payment.showLegalDeclarationContent = false;
							}
							if (row.paymentMethod == constants.paymentMethod.cheque.description) {
								$scope.paymentMode = constants.paymentMethod.cheque.description;
								$scope.payment.chequePayment = true;
								$scope.payment.cardPayment = false;
								$scope.payment.eftPayment = false;
							}
							else if (row.paymentMethod == constants.paymentMethod.card.description) {
								$scope.paymentMode = constants.paymentMethod.card.description;
								$scope.payment.chequePayment = false;
								$scope.payment.cardPayment = true;
								$scope.payment.eftPayment = false;
							} else if (row.paymentMethod == constants.paymentMethod.directDeposit.description) {
								$scope.paymentMode = constants.paymentMethod.directDeposit.description;
								$scope.payment.chequePayment = false;
								$scope.payment.cardPayment = false;
								$scope.payment.eftPayment = true;
							}

							$scope.payment.editItem = row;

							$scope.disableRemovePayment =
								$scope.payment.editItem.paymentStatusId == constants.paymentStatus.cancelled.id
								|| $scope.payment.editItem.paymentStatusId == constants.paymentStatus.deleted.id
								|| ($scope.payment.editItem.paymentMethodId == constants.paymentMethod.directDeposit.id
									&& $scope.payment.editItem.paymentStatusId >= constants.paymentStatus.approved.id);

							$scope.disableUpdatePayment = $scope.payment.editItem.paymentStatusId >= constants.paymentStatus.pendingReview.id;
						};

						if (!$scope.payment_form.$pristine) {
							callPopup().then(function () {
								openItemCore(row);
							})
								.catch(function () {
									
								});
						} else {
							openItemCore(row);
						}
					}

					/*Custom Functions*/
					//Calculate the total eligible amount.
					function getTotalEligibleAmount() {
						$scope.remainingAmount = $scope.item.remainingPaymentAmount;
						$scope.paymentType = $scope.item.exceptionalCircumstancesExist || $scope.item.initialPaymentAmount == 0 ? constants.paymentType.further.id : constants.paymentType.initial.id;
					}

					//Initialize data.
					function initializeData() {
						fetchMemberDetails();
						fetchDistributionPoints();
						getTotalEligibleAmount();
						var gridData = $scope.item.payments;
						$scope.currentGst = $scope.item.currentGst;

						$scope.IsInvoiceReferenceNumberAlreadyUsed = false;

						$scope.adjustmentAmount = 0;
						if ($scope.item.adjustmentAmount != null) {
							$scope.adjustmentAmount = $scope.item.adjustmentAmount;
						}

						//$scope.gridOptions.data = gridData;
					}

					function loadPayment() {
						if ($scope.selectedPayment) {
							$scope.refreshOpenedItem(parseInt($scope.selectedPayment));
						}
					}

					$scope.$watch('loaded', function (newValue) {
						if (newValue) {
							loadPayment();
						}
					});
					//Inject the payment method and pushing it to list.
					function injectAndReset(paymentDetails) {
						if ($scope.paymentMode == constants.paymentMethod.cheque.description) {
							paymentDetails.paymentMethodId = constants.paymentMethod.cheque.id;
							paymentDetails.paymentMethod = constants.paymentMethod.cheque.description;
						}
						else if ($scope.paymentMode == constants.paymentMethod.card.description) {
							paymentDetails.paymentMethodId = constants.paymentMethod.card.id;
							paymentDetails.paymentMethod = constants.paymentMethod.card.description;
						}
						else {
							paymentDetails.paymentMethodId = constants.paymentMethod.directDeposit.id;
							paymentDetails.paymentMethod = constants.paymentMethod.directDeposit.description;
						}
						paymentDetails.declarationAccepted = true;
						paymentDetails.paymentStatusId = constants.paymentStatus.active.id; //Need to change in future.
						paymentDetails.createPayment = ($scope.payment.pageMode === $scope.pageModes.add) ? true : false;
						updateDirectDepositDetails(paymentDetails);
						paymentDetails.ReestablishApplicationId = $scope.item.id;
						if ($scope.payment.cardPayment || $scope.payment.chequePayment) {
							paymentDetails.recipientId = paymentDetails.chequeRecipient.chequeRecipientId;
						}
						if ($scope.payment.eftPayment) {
							paymentDetails.directDepositPayment.paymentAmount = paymentDetails.amount;
						}
					}

					//Setting the entire form to pristine.
					function setPristine() {
						$scope.setAsReadonly = false;
						$scope.finishFirst = false;
						$scope.payment.openLegalDeclaration = false;
						$scope.payment.legalCheckone = false;
						$scope.payment.legalChecktwo = false;
						$scope.payment.legalCheckthree = false;
						$scope.payment_form.$setPristine();
						$scope.payment_form.$setUntouched();
						$scope.showWarning = false;
						$scope.disablePayment = false;
						$scope.payment.addPayments = false;
						$scope.payment.paymentDetails = {};
						$scope.payment.showdistributionPoint = false;
						$scope.payment.removalSuccessMessage = false;
						$scope.payment.removalErrorMessage = false;
						$scope.payment.successMessage = false;
						$scope.payment.errorMessage = false;
						$scope.payment.pageMode = null;
						$scope.payment.submitSuccessMessage = false;
						$scope.payment.submitErrorMessage = false;
						$scope.disableAdd = false;
						$scope.showAmountExceededMessage = false;
						$scope.payment.resubmitSuccess = false;
						$scope.payment.resubmitSuccessError = false;
						$scope.vendorNameSearchText = '';
					}
					$scope.checkDistributionPoint = function (item) {
						$scope.payment_form.distributionPoint.$invalid = false;
						$scope.payment_form.distributionPoint.$valid = true;
						if (item) {
							if ($scope.item.incident.regionId != item.region && item.region != 0) {
								var title = 'Distribution point mismatch';
								var content = 'Warning: The selected distribution point location is not within the defined DHHS region for this incident. To continue with the Distribution point as defined, select proceed';
								utils.confirm(title, content, 'Proceed', 'Cancel')
									.then(function () {
										$scope.payment.paymentDetails.distributionPointName = item.name;
										$scope.payment_form.distributionPoint.$invalid = false;
									}, function () {
										$scope.payment_form.distributionPoint.$invalid = true;
										$scope.payment.paymentDetails.distributionPointName = "";
										return;
									});
							}
							else
								$scope.payment.paymentDetails.distributionPointName = item.name;
						}
						else {
							var isValid = false;
							$scope.distributionPoints.forEach(function (dp) {
								if (dp.name == $scope.payment.paymentDetails.distributionPointName)
									isValid = true;
							});
							if (!isValid) {
								$scope.payment_form.distributionPoint.$invalid = true;
								$scope.payment_form.distributionPoint.$valid = false;
							}

						}
					};
					//Fetch all distribution points
					function fetchDistributionPoints() {
						locationService.getAllDistributionDetails().then(function (result) {
							$scope.distributionPoints = [];
							$scope.distributionPoints.push({ name: "Affected address", region: 0 });
							$scope.distributionPoints.push({ name: "Contact address", region: 0 });
							result.forEach(function (item) {
								$scope.distributionPoints.push({ name: item.name, region: item.regionId });
							});
						});
					};
					//Fetching the member details for the dropdown in cheque recipient.
					function fetchMemberDetails() {
						$scope.members = [];
						$scope.members.push({ chequeRecipientName: $scope.item.applicant.givenName + ' ' + $scope.item.applicant.surname, DOB: $scope.item.applicant.dob, chequeRecipientId: $scope.item.applicant.id });
						_.forEach($scope.item.householdMembers,
							function (householdMember) {
								$scope.members.push({
									chequeRecipientName: householdMember.givenName + ' ' + householdMember.surname,
									DOB: householdMember.dob,
									chequeRecipientId: householdMember.id
								});
							});
					}

					function clearStatus() {
						setPristine();
						$scope.showPaymentDetails = false;
						$scope.payment.openLegalDeclaration = true;
						$scope.payment.showLegalDeclarationContent = true;
					}
					function callPopup() {
						var title = 'Change payment?';
						var content = 'There are unsaved changes which will be discarded, please confirm (Yes/No)';
						return utils.confirm(title, content, 'Yes', 'No')
					}

					function scrollToLegal(timeoutValue) {
						timeoutValue = timeoutValue ? timeoutValue : 200;
						$timeout(function () {
							var headerBannerHeight = angular.element('#header-banner')[0].offsetHeight;
							var pos = angular.element("#legal-declaration").offset().top - angular.element('#fixed-form-header')[0].offsetHeight - headerBannerHeight - 60;
							if (pos) {
								angular.element('body,html').animate({ scrollTop: pos }, 700);
							}
							}, timeoutValue);
					}
					function callChequePayment() {
						clearStatus();
						$scope.payment.pageMode = $scope.pageModes.add;
						$scope.paymentMode = constants.paymentMethod.cheque.description;
						$scope.payment.chequePayment = true;
						$scope.payment.cardPayment = false;
						$scope.payment.eftPayment = false;
						scrollToLegal();
					}
					function callCardPayment() {
						clearStatus();
						$scope.payment.pageMode = $scope.pageModes.add;
						$scope.paymentMode = constants.paymentMethod.card.description;
						$scope.payment.cardPayment = true;
						$scope.payment.chequePayment = false;
						$scope.payment.eftPayment = false;
						scrollToLegal();
					}
					function callEftPayment() {
						clearStatus();
						$scope.payment.pageMode = $scope.pageModes.add;
						$scope.paymentMode = constants.paymentMethod.directDeposit.description;
						$scope.payment.eftPayment = true;
						$scope.payment.cardPayment = false;
						$scope.payment.chequePayment = false;
						$scope.payment.paymentDetails.directDepositPayment = {
							invoiceReference: $scope.item.applicationNumber + Math.random().toString(36).substring(7, 13).toUpperCase()
						}
						scrollToLegal();
					}
					var paymentExists = function (paymentOld, paymentNew) {
						if ($scope.payment.pageMode === $scope.pageModes.add) {
							return (paymentOld.paymentNumber === paymentNew.paymentNumber);
						} else if (paymentOld.paymentNumber === paymentNew.paymentNumber &&
							paymentOld.chequePaymentId !== paymentNew.chequePaymentId &&
							paymentOld.cardPaymentId !== paymentNew.cardPaymentId) {
							return true;
						}
						return false;
					}

					var checkIfNumberIsDuplicate = function (paymentDetails) {
						if ($scope.payment.cardPayment) return false; // will be validated by server-side
						if ($scope.payment.eftPayment) {
							paymentDetails.paymentNumber = paymentDetails.directDepositPayment.invoiceReference;
						}
						if (_.some($scope.item.payments, function (cheque) {
							return paymentExists(cheque, paymentDetails);
						})) {
							$scope.payment.duplicateChequeNumber = {
								paymentNumber: paymentDetails.paymentNumber
							};
							return true;
						}
						$scope.payment.duplicateChequeNumber = false;
						return false;
					}

					//Click action for cheque payment
                    $scope.chequePayment = function () {
                        clearAllMessages();
						if ($scope.payment.pageMode === $scope.pageModes.update) {
							callPopup().then(function () {
								callChequePayment();
							})
								.catch(function () {
									
								})
						}
						else {
							callChequePayment();
						}
					}

					$scope.searchVendor = function (event) {
						if ($scope.payment.paymentDetails !== undefined && $scope.payment.paymentDetails.directDepositPayment !== undefined &&
							$scope.payment.paymentDetails.directDepositPayment.vendor !== undefined) {
							$scope.vendorNameSearchText = $scope.payment.paymentDetails.directDepositPayment.vendor.name;
						}
						utils.openModal('app/home/vendor/vendorSearchPopup.html', 'vendorSearchPopupCtrl', $scope, null, "small-top-margin", selectVendorCallback);
					}

					function selectVendorCallback(vendor) {
						//vendor will be undefined when the user clicks cancel on vendor search popup
						if (vendor !== undefined) {
							if ($scope.payment &&
								$scope.payment.paymentDetails &&
								$scope.payment.paymentDetails.directDepositPayment) {
								setVendorDirtyState($scope.payment.paymentDetails.directDepositPayment.vendor, vendor);
								$scope.payment.paymentDetails.directDepositPayment.vendor = vendor;
							} else {
								$scope.payment.paymentDetails["directDepositPayment"] = { vendor: vendor };
							}
							$scope.payment_form.$setDirty();
						}
					}

					function setVendorDirtyState(oldVendor, newVendor) {
						if (utils.isNullOrEmpty(oldVendor)) return;
						if (oldVendor.id !== newVendor.id) {
							$scope.payment_form.$setDirty();
						}
					}

					//Click action for card payment
                    $scope.cardPayment = function () {
                        clearAllMessages();
						if ($scope.payment.pageMode === $scope.pageModes.update) {
							callPopup().then(function () {
								callCardPayment();
							})
								.catch(function () {
									
								})
						}
						else {
							callCardPayment();
						}
					}
					//Click action for card payment
                    $scope.eftPayment = function () {
                        clearAllMessages();
						if ($scope.payment.pageMode === $scope.pageModes.update) {
							callPopup().then(function () {
								callEftPayment();
							})
								.catch(function () {
									
								})
						}
						else {
							callEftPayment();
						}
					}
					/*End of Custom Functions*/
					//Check if all the checkboxes are selected in legal declaration
					$scope.validateDeclaration = function () {
						if (($scope.payment.legalCheckone && $scope.payment.legalChecktwo && $scope.payment.legalCheckthree) || $scope.disableAdd) {
							$scope.payment.showdistributionPoint = true;
							$scope.payment.pageMode = $scope.pageModes.add;
						}
						else {
							$scope.payment.showdistributionPoint = false;
						}
					}
					$scope.cancelPayment = function () {
						var title = 'Cancel Payment';
						var content = 'Are you sure you wish to cancel?';
						clearAllMessages();
						utils.confirm(title, content, 'Yes', 'No')
							.then(function () {
								var isUpdate = $scope.payment.pageMode === $scope.pageModes.update;
								setPristine();

								if (isUpdate) {
									$scope.showPaymentDetails = true;
								}
							});
					}

					$scope.cancelView = function () {
						setPristine();
					}

					$scope.clickAddOrUpdate = function () {

                        clearAllMessages();

						if ($scope.payment_form.$valid) {

                            if ($scope.payment_form.invoiceref != null) {

                                if ($scope.item.payments.length === 0) {
                                    // if this is the first payment for application then no check required.
                                    $scope.IsInvoiceReferenceNumberAlreadyUsed = false;
                                }
                                else if ($scope.item.payments.length > 0) {

                                    // if there are already payment for this application and new payment to be added

                                    if ($scope.payment.pageMode === "Add") {
                                        var matchedItems = _.filter($scope.item.payments, { paymentNumber: $scope.payment_form.invoiceref.$modelValue });
                                        if (matchedItems.length > 0) {
                                            $scope.IsInvoiceReferenceNumberAlreadyUsed = true;
                                            return;
                                        }
                                        else {
                                            $scope.IsInvoiceReferenceNumberAlreadyUsed = false;
                                        }
                                    }


                                }
                            }

							if ($scope.payment.paymentDetails && $scope.payment.paymentDetails.directDepositPayment && $scope.payment.paymentDetails.directDepositPayment.gstInclusive
								&& (!$scope.payment.paymentDetails.directDepositPayment.vendor.abn || $scope.payment.paymentDetails.directDepositPayment.vendor.abn === 0)) {
								utils.alert("Vendor does not have a valid abn number, hence unable to complete a gst inlcusive payment.");
							}
							else {
								if ($scope.payment.paymentDetails.directDepositPayment != null) {

									if ($scope.payment.paymentDetails.directDepositPayment.gstInclusive) {

										var calculatedGst = ($scope.payment.paymentDetails.directDepositPayment.invoiceAmount * $scope.currentGst) / 100;

										if ($scope.payment.paymentDetails.directDepositPayment.gstAmount == 0) {
											$scope.InvalidGstAmount = true;
											return;
										}

										if ($scope.payment.paymentDetails.directDepositPayment.gstAmount > calculatedGst) {
											$scope.GstMoreThanCurrentGst = true;
											return false;
										}
										else if ($scope.payment.paymentDetails.directDepositPayment.gstAmount <= calculatedGst) {
											$scope.GstMoreThanCurrentGst = false;
										}
									}
									else if (!$scope.payment.paymentDetails.directDepositPayment.gstInclusive) {
										$scope.payment.paymentDetails.amount = $scope.payment.paymentDetails.directDepositPayment.invoiceAmount;
									}
								}

								$q.all([validateDirectDeposit(), validateCard()]).then(function () {
									if ($scope.payment.pageMode === $scope.pageModes.add) {
										$scope.addpaymentDetails();
									} else {
										// do update here
										if ($scope.payment_form.$dirty) {
											updatePaymentDetails();
										}
									}
								});
							}
						} else {
							$scope.finishFirst = true;
							return;
						}
					}
					//push the distribution point details to an array till it is saved to db.
					$scope.addpaymentDetails = function () {
						if ($scope.payment.paymentDetails.amount > $scope.remainingAmount) {
							$scope.showAmountExceededMessage = {
								totalPayment: $scope.payment.paymentDetails.amount,
								remainingAmount: $scope.remainingAmount
							};
							return;
						}
						if ($scope.payment.eftPayment && $scope.payment.paymentDetails.directDepositPayment.gstInclusive) {
							var title = 'Total amount';
							var taxInvoiceAmount = $filter('currency')($scope.payment.paymentDetails.amount);
							var content = "Is the Total invoice amount " + taxInvoiceAmount + " the same as the Total amount (including GST) as presented on the Vendor invoice? If yes, proceed. If no, cancel and re-enter the payment and GST amounts";
							utils.confirm(title, content, 'Proceed', 'Cancel')
								.then(function () {
									$scope.saveToDb();
								}, function () {
									
									return;
								});
						}
						else {
							$scope.saveToDb();
						}
					}

					function validateDirectDeposit() {

						var deferred = $q.defer();
						if ($scope.payment.eftPayment) {
							if ($scope.payment.paymentDetails.directDepositPayment.vendor.abn &&
								!$scope.payment.paymentDetails.directDepositPayment.gstInclusive) {
								var title = "Payment Validation";
								var content =
									"This Vendor has an ABN and the GST flag has not been selected. If this is correct, select 'Proceed'. If the invoice includes a GST amount, select 'Cancel' and manually set the GST flag.";
								return utils.confirm(title, content, 'Proceed', 'Cancel');
							}
						}
						deferred.resolve();
						return deferred.promise;
					}

					function validateCard() {
						if ($scope.payment.cardPayment) {
							return reestablishService.getCardState($scope.payment.paymentDetails.paymentNumber)
								.then(function (data) {

									if (data.preloaded && data.status === constants.cardStatus.checkedOut
										&& parseFloat($scope.payment.paymentDetails.amount) != parseFloat(data.amount)) {
										var title = "Payment Validation";
										var content = "The card selected is already Loaded with the following amount $" + data.amount + ".<br/>"
											+ "Select <strong>Proceed</strong> to continue the payment with the Loaded amount.<br/>"
											+ "Select <strong>Cancel</strong> to reset the payment in order for a different card to be used.";
										return utils.confirm(title, content, 'Proceed', 'Cancel')
											.then(function () {
												$scope.payment.paymentDetails.amount = data.amount;
												return $q.resolve();
											}, function () {
												$scope.payment.paymentDetails.paymentNumber = undefined;
												$scope.payment.paymentDetails.amount = undefined;
												return $q.reject();
											});
									}
									else if (data.preloaded && data.status !== constants.cardStatus.checkedOut) {
										var title = "Invalid card status";
										$scope.payment.errorMessage = true;
										$scope.payment.errorDetail = constants.cardNoCheckout;
										$scope.payment.paymentDetails.paymentNumber = undefined;
										$scope.payment.paymentDetails.amount = undefined;
										return $q.reject();

									}
								}, function (error) {
									notificationService.notify('showLoadingPanel', false);
									$scope.payment.errorMessage = true;
									$scope.payment.errorDetail = error.data.errors[0].message;
									$scope.setFieldValidity($scope.payment_form, error.data.errors, false);
									return $q.reject(error);
								});
						}
						return $q.resolve();
					}

					function updateDirectDepositDetails(paymentDetails) {
						if (paymentDetails.paymentMethod == constants.paymentMethod.directDeposit.description) {
							if (!paymentDetails.directDepositPayment.gstInclusive) {
								paymentDetails.directDepositPayment.gstAmount = 0;
							}
						}
					}

					function clearAllMessages() {
						with ($scope) {
							disableAdd = false;
							finishFirst = false;
							payment.addPayments = false;
							showAmountExceededMessage = false;
							payment.duplicateChequeNumber = false;
							payment.successMessage = false;
							payment.errorMessage = false;
							payment.removalSuccessMessage = false;
							payment.removalErrorMessage = false;
							payment.submitSuccessMessage = false;
							payment.submitErrorMessage = false;
							payment.resubmitSuccessError = false;
							payment.cancellationReasonRequiredMessage = false;
                            InvalidGstAmount = false;
                            $scope.GstMoreThanCurrentGst = false;
                            IsInvoiceReferenceNumberAlreadyUsed = false;
						}
					}

					//push the distribution point details to an array till it is saved to db.
					function updatePaymentDetails() {
						var paymentDetails = $scope.payment.paymentDetails;
						var existingPaymentAmount = $scope.payment.existingUpdateObject.amount;
						// calculate the remaining amount by adding the original amount and deducting the new amount
						var calculatedRemainingAmount = parseFloat($scope.remainingAmount) + existingPaymentAmount - paymentDetails.amount;
						if (calculatedRemainingAmount < 0) {
							$scope.showAmountExceededMessage = {
								totalPayment: paymentDetails.amount,
								remainingAmount: parseFloat($scope.remainingAmount) + existingPaymentAmount
							};
							return;
						}
						else {
							if (checkIfNumberIsDuplicate(paymentDetails)) return;

							notificationService.notify('showLoadingPanel', true);
							$scope.showAmountExceededMessage = false;
							var dataTobeSaved = {};
							$scope.isUpdating = true;
							dataTobeSaved.payments = [];
							dataTobeSaved.id = $scope.item.id;
							dataTobeSaved.rowVersion = $scope.item.rowVersion;
							paymentDetails.createPayment = false;
							updateDirectDepositDetails(paymentDetails);
							dataTobeSaved.payments.push(paymentDetails);
							reestablishService.updateReestablishPayment(dataTobeSaved).then(function (data) {

								$scope.isUpdating = false;
								var cardInvalidErrorOccured = showCustomErrorMessage(data);
								if (cardInvalidErrorOccured == false) {
									$scope.item.payments = data.payments;
									$scope.item.rowVersion = data.rowVersion;									
									$scope.item.remainingPaymentAmount = data.remainingPaymentAmount;
									getTotalEligibleAmount();
									setPristine();
									$scope.refreshOpenedItem(paymentDetails.paymentId);
									$scope.payment.successMessage = true;
									$scope.payment.addPayments = false;
								}
								notificationService.notify('showLoadingPanel', false);
							}, function (error) {
								$scope.isUpdating = false;
								$scope.payment.errorMessage = true;
								$scope.payment.errorDetail = error.data.errors[0].message;
								$scope.setFieldValidity($scope.payment_form, error.data.errors, false);
								notificationService.notify('showLoadingPanel', false);
							});
						}
					}

	
					$scope.changeRecipient = function () {
						var chequeRecipient = $scope.payment.paymentDetails.chequeRecipient;
						$scope.payment.paymentDetails.recipientId = chequeRecipient.chequeRecipientId;
						$scope.payment.paymentDetails.recipientName = chequeRecipient.chequeRecipientName;

						$scope.checkAge(chequeRecipient.DOB);
					}

					//Checks the age.
					$scope.checkAge = function (dob) {
						if (utils.getAge(dob) < 18) {
							$scope.showWarning = true;
							return;
						}
						$scope.showWarning = false;
					}

					//Removing single payment
					$scope.removePayment = function (editItem) {
						clearAllMessages();

						var title = 'Remove Payment';
						var content = 'Are you sure you wish to remove this payment?';

						var prevDisableRemove = $scope.disableRemovePayment;
						var prevDisableUpdate = $scope.disableUpdatePayment;
						utils.confirm(title, content, 'Yes', 'No')
							.then(function () {
								var dataTobeSaved = {};
								$scope.isRemoving = true;
								dataTobeSaved.payments = [];
								editItem.createPayment = false;
								dataTobeSaved.payments.push(editItem);
								dataTobeSaved.id = $scope.item.id;
								dataTobeSaved.rowVersion = $scope.item.rowVersion;

								notificationService.notify('showLoadingPanel', true);
								reestablishService.removeReestablishPayment(dataTobeSaved).then(function (data) {
									notificationService.notify('showLoadingPanel', false);
									$scope.isRemoving = false;
									$scope.item.payments = data.payments;
									$scope.item.rowVersion = data.rowVersion;

									setPristine();
									$scope.disableRemovePayment = true;
									$scope.disableUpdatePayment = true;
									$scope.payment.removalSuccessMessage = true;
								
									$scope.item.remainingPaymentAmount = data.remainingPaymentAmount;

									// $scope.refreshOpenedItem(editItem.paymentId);
									$scope.showPaymentDetails = false;
									getTotalEligibleAmount();
									$scope.item.state = data.state;
									$scope.item.displayedState = $scope.getStateName(data.state);
								}, function (error) {
									notificationService.notify('showLoadingPanel', false);
									$scope.isRemoving = false;
									$scope.disableRemovePayment = prevDisableRemove;
									$scope.disableUpdatePayment = prevDisableUpdate;
									$scope.payment.removalErrorMessage = true;
									if (error.data !== undefined && error.data.errors !== undefined && error.data.errors.length > 0) {
										$scope.payment.cancelErrorMessage = true;
										$scope.payment.errors = error.data.errors;
									}
									else {
										$scope.payment.removalErrorMessage = true;
									}
									editItem.paymentStatus = constants.paymentStatus.active.description;
								});
							});
					}

					//Saves the payment to Database.
					$scope.saveToDb = function () {
						clearAllMessages();
						if (checkIfNumberIsDuplicate($scope.payment.paymentDetails)) return;

						injectAndReset($scope.payment.paymentDetails);

						$scope.isSaving = true;
						var dataTobeSaved = {};
						$scope.payment.paymentDetails.paymentTypeId = $scope.paymentType;
						dataTobeSaved.payments = [$scope.payment.paymentDetails];
						dataTobeSaved.remainingEntitlementAmount = $scope.remainingAmount;
						dataTobeSaved.id = $scope.item.id;
						dataTobeSaved.paymentType = $scope.paymentType;
						dataTobeSaved.rowVersion = $scope.item.rowVersion;
						notificationService.notify('showLoadingPanel', true);
						return reestablishService.saveReestablishPayments(dataTobeSaved).then(function (data) {
							$scope.isSaving = false;
							var cardInvalidErrorOccured = showCustomErrorMessage(data);

							if (cardInvalidErrorOccured == false) {
								loadServerData(data);
								setPristine();
								$scope.payment.paymentDetails = {};
								$scope.payment.successMessage = true;
								$scope.item.state = data.state;
								$scope.item.displayedState = $scope.getStateName(data.state);
							}
							notificationService.notify('showLoadingPanel', false);
							return data;

						}, function (error) {
							$scope.isSaving = false;
							$scope.payment.errorMessage = true;
							$scope.payment.errorDetail = error.data.errors[0].message;
							$scope.setFieldValidity($scope.payment_form, error.data.errors, false);
							notificationService.notify('showLoadingPanel', false);
							return error;
						});
					};
					var showCustomErrorMessage = function (data) {
						if (data == "600") {
							$scope.payment.errorMessage = true;
							$scope.payment.errorDetail = constants.cardNoCheckout;
							$scope.payment.paymentDetails.paymentNumber = "";
							return true;
						}
						else if (data == "601") {
							$scope.payment.errorMessage = true;
							$scope.payment.errorDetail = constants.cardNoCheckout;
							$scope.payment.paymentDetails.paymentNumber = "";
							return true;
						}
						else if (data == "602") {
							$scope.payment.errorMessage = true;
							$scope.payment.errorDetail = constants.cardCheckoutToDifferentIncident;
							$scope.payment.paymentDetails.paymentNumber = "";
							return true;
						}
						else if (data == "603") {
							$scope.payment.errorMessage = true;
							$scope.payment.errorDetail = constants.cardInactive;
							$scope.payment.paymentDetails.paymentNumber = "";
							return true;
						}
						else if (data == "606") {
							$scope.payment.errorMessage = true;
							$scope.payment.errorDetail = constants.amountExceeded;
							$scope.payment.paymentDetails.paymentNumber = "";
							return true;
						}
						return false;
					}
					var loadServerData = function (data) {
						$scope.item.payments = data.payments;

						$scope.item.remainingPaymentAmount = data.remainingPaymentAmount;
						getTotalEligibleAmount();

						$scope.item.rowVersion = data.rowVersion;						
						$scope.item.payments = data.payments;
					};

					//Watch the remaining amount.
					$scope.$watch('remainingAmount', function (newval, oldval) {
						if (newval == 0.00 || newval == 0) {
							$scope.disableAdd = true;
						}
						else {
							$scope.disableAdd = false;
						}
					});

					$scope.calculatePaymentAmount = function () {
						if ($scope.paymentMode === constants.paymentMethod.directDeposit.description && $scope.payment.paymentDetails.directDepositPayment) {
							$scope.payment.paymentDetails.directDepositPayment.invoiceAmount = Number($scope.payment.paymentDetails.amount || 0) - Number($scope.payment.paymentDetails.directDepositPayment.gstAmount || 0);
						};
					}
					//reset all payments when gst inclusive flag changes
					$scope.resetAllPayment = function () {
						$scope.payment.paymentDetails.directDepositPayment.invoiceAmount = null;
						$scope.payment.paymentDetails.directDepositPayment.gstAmount = null;
						$scope.payment.paymentDetails.amount = null;
					}
					$scope.updatePayment = function () {
						setPristine();
						$scope.payment.existingUpdateObject = $scope.payment.editItem;
						$scope.payment.paymentDetails = angular.copy($scope.payment.editItem);
						$scope.payment.paymentDetails.chequeRecipient = _.find($scope.members, function (o) {
							return o.chequeRecipientId === $scope.payment.editItem.recipientId;
						});
						$scope.payment.showdistributionPoint = true;
						$scope.payment.pageMode = $scope.pageModes.update;
						$scope.showPaymentDetails = false;
						if ($scope.paymentMode === constants.paymentMethod.directDeposit.description) {
							$scope.payment.paymentDetails.directDepositPayment.invoiceAmount = Number($scope.payment.paymentDetails.amount || 0) - Number($scope.payment.paymentDetails.directDepositPayment.gstAmount || 0);
						};
					}

					$scope.isEditMode = function () {
						return $scope.payment.pageMode === $scope.pageModes.update;
					}

					var paymentIsNotSaved = function (p) {
						return p.paymentStatusId == constants.paymentStatus.active.id;
					};

					$scope.enableSubmitPayment = function () {
						return !$scope.payment.showdistributionPoint && $scope.item && $scope.item.payments && _.some($scope.item.payments, paymentIsNotSaved);
					};

					$scope.reloadData = function (data, paymentId, showSuccess) {
						loadServerData(data);
						$scope.refreshOpenedItem(paymentId);
						$scope.payment.successMessage = showSuccess;
					}

					var submitPayment = function () {
						var paymentSlim = function (payment) {
							return payment;
						};

						var submitModel = {
							id: $scope.item.id,
							rowVersion: $scope.item.rowVersion,
							payments: _.map(_.filter($scope.item.payments, paymentIsNotSaved), paymentSlim)
						};

						$scope.isSaving = true;
						notificationService.notify('showLoadingPanel', true);
						reestablishService.submitPayments(submitModel).then(function (data) {
							notificationService.notify('showLoadingPanel', false);
							$scope.isSaving = false;
							loadServerData(data);
							if ($scope.payment.editItem) {
								if (!$scope.refreshOpenedItem($scope.payment.editItem.paymentId)) {
									$scope.showPaymentDetails = false;
									$scope.payment.editItem = null;
								}
							}
							$scope.payment.submitSuccessMessage = true;
							$scope.payment.submitErrorMessage = false;
						}, function (error) {
							notificationService.notify('showLoadingPanel', false);
							$scope.isSaving = false;
							$scope.payment.submitSuccessMessage = false;
							$scope.payment.submitErrorMessage = true;
							$scope.payment.errorDetail = error.data.errors[0].message;
						});
					}

					$scope.submitPayment = function () {
						clearAllMessages();

						var negativePayments = _.find(_.filter($scope.item.payments, paymentIsNotSaved), function (p) { return p.amount < 0; });
						if (negativePayments) {
							utils.confirm('Confirm negative payment', 'One or more payments are for a negative amount. Are you sure you want to submit these payments?', 'Proceed', 'Cancel')
								.then(function () {
									submitPayment();
								});
						}
						else {
							submitPayment();
						}
					};

					$scope.removePaymentAllowed = function () {
						if ($scope.item && $scope.currentUserSecurityInfo) {
							return $scope.canApplyPayments() && $scope.currentUserSecurityInfo.privileges.UpdateReestablishPayment;
						} else {
							return false;
						}
					}

					$scope.updatePaymentAllowed = function () {
						if ($scope.item && $scope.currentUserSecurityInfo) {
							return $scope.canApplyPayments() && $scope.currentUserSecurityInfo.privileges.UpdateReestablishPayment;
						} else {
							return false;
						}
					}

					$scope.paymentIsOpen = function (payment) {
						if (!payment) return false;

						return payment.paymentStatusId <= constants.paymentStatus.pendingReview.id;
					};

					$scope.isPendingReview = function (payment) {
						if (!payment) return false;
						return payment.paymentStatusId == constants.paymentStatus.pendingReview.id;
					};
					$scope.isRejectedUserSubmitting = function (payment) {
						var userId = $cookies.get("UserId");
						return payment.rejectedById == userId;
					};

					$scope.resubmitForApproval = function (payment) {
						var title = 'Confirm';
						var content = 'This will resubmit the payment for approval.';
						$scope.payment.resubmitSuccessError = false;
						$scope.payment.resubmitSuccess = false;

						utils.confirm(title, content, 'OK', 'Cancel').then(
							function () {
								notificationService.notify('showLoadingPanel', true);
								var data = {
									applicationId: $scope.item.id,
									rowVersion: payment.rowVersion,
									payment: payment
								}
								reestablishService.resubmitForApproval(data).then(function (result) {
									loadServerData(result);
									$scope.refreshOpenedItem(payment.paymentId);
									$scope.payment.resubmitSuccess = true;
									notificationService.notify('showLoadingPanel', false);
								}, function () {
									notificationService.notify('showLoadingPanel', false);
									$scope.payment.resubmitSuccessError = true;
								});
							})
					};
				}]
		};
	}]);
})();