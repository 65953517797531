(function () {
    
    var app = angular.module('phapApp');
    app.component('popupLoader', {
        templateUrl: 'app/_components/popupLoader.html',
        bindings: {
        },
        controller: ['notificationService', '$scope', function (notificationService, $scope) {
            var vm = this;

            vm.showLoader = false;

            notificationService.subscribe($scope, 'showLoadingPanel', function (event, value) {
                vm.showLoader = value;
                if (value) {
                    angular.element("body").css("overflow", "hidden");
                } else {
                    // Revert to no overflow style rather than initial (doesn't work with IE11)
                    angular.element("body").css("overflow", "");
                }
                
            });
            }],
        controllerAs: 'popupLoader'
    });

})();