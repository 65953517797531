(function () {
    angular.module('phapApp')
        .directive('principalPlaceOfResidence',
        [
            '$window', function ($window) {
                return {
                    restrict: 'E',
                    templateUrl: 'app/home/reestablish/_principal-place-of-residence.html',
                    controller: ['$scope', 'Constants', 'utils','reestablishService', pporCtrl]
                };
            }
        ]);

    function pporCtrl($scope, Constants, utils, reestablishService) {

        $scope.Constants = Constants;
        $scope.ppoeUploadController = {};

        var ppoeNoDocumentsAttached = function () {
            if ($scope.item && $scope.item.documents) {
                var documents = $scope.item.documents[Constants.reestablishSections.principalPlaceOfResidence];
                return !angular.isDefined(documents) || documents.length === 0;
            }
            return false;
        };

        // #region Business Rule checking

        $scope.$on('dataLoaded', function () {
            $scope.ppoeCheckBusinessRules();
        });

        $scope.$watchCollection('[item.principalPlaceOfResidenceCopyAttached]', function (newValues) {
            if (!angular.isDefined(newValues[0]))
                return;
            $scope.ppoeCheckBusinessRules();
        });

        $scope.ppoeCheckBusinessRules = function () {
            $scope.formSections.eligibilityCriteria.subMenu.principalPlaceOfResidence.rulesOverridden = $scope.item.principalPlaceOfResidenceCopyAttached === Constants.YesNoNotyet.No;
            $scope.formSections.eligibilityCriteria.subMenu.principalPlaceOfResidence.evidenceRequired = $scope.item.principalPlaceOfResidenceCopyAttached === Constants.YesNoNotyet.NotYet;
            reestablishService.setExceptionalCircumstances($scope.formSections, $scope.item);
        }

        // #endregion 

        var confirm = function () {
            var title = 'Evidence documents required!';
            var content = 'Evidence attached has been selected as Yes and no attachments have been loaded. \nDocuments must be loaded in order to set the Evidence attached field to Yes.';
            utils.confirm(title, content, 'Proceed', 'Cancel')
                .then(function () {
                    $scope.item.principalPlaceOfResidenceCopyAttached = Constants.YesNoNotyet.NotYet;
                }, function () {
                    $scope.item.principalPlaceOfResidenceCopyAttached = null;
                });
        };

        $scope.checkEvidence = function () {
            if (ppoeNoDocumentsAttached()) {
                $scope.ppoeUploadController.$newUpload($scope.ppoeDocumentTypes);
            }
            $scope.ppoeCheckBusinessRules();
        };

        $scope.ppoeOnCancelUpdate = function () {
            if (ppoeNoDocumentsAttached()) {
                confirm();
            }
        };

        $scope.ppoeOnFileListChanged = function () {
            if ($scope.item && $scope.item.principalPlaceOfResidenceCopyAttached === Constants.YesNoNotyet.Yes) {
                if (ppoeNoDocumentsAttached()) {
                    confirm();
                }
            }
        };

        $scope.ppoeShowNoSelectedWarning = function () {
            var title = 'Principal place of residence!';
            var content = 'Based on the information provided, the applicant is Ineligible for a re-establishment payment . If the application is saved now, the form is no longer modifiable and no re-establishment payments can be added to this application.';
            utils.confirm(title, content, 'Proceed', 'Ineligible')
                .then(function () {
                    // NOTE: We leave the button as NO, as this is an override / proceed scenario
                    $scope.ppoeCheckBusinessRules();
                }, function () {
                    $scope.saveAsIneligible();
                });
        };
    };
})();