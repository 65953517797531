(function () {

    var app = angular.module('phapApp');

    app.component('gender', {
        templateUrl: 'app/administration/configuration/gender.html',
        bindings: {
            mode: '@'
        },
        controller: ['$scope', '$filter', 'utils', 'referenceService', 'security', function ($scope, $filter, utils, referenceService, security) {

            var vm = this;
            vm.originalDataSet = [];
            vm.gridApi = null;
            vm.historyUrl = "gender/0";
            var getGenderList = function () {
                referenceService.getGenderList().then(
                    function (genderList) {
                        var list = [];
                        angular.forEach(genderList.data, function (value, key) {
                            this.push({ data: value, isUpdated: false, isAdded: false });
                        }, list);
                        vm.gridOptions.data = list;
                        vm.originalDataSet = angular.copy(vm.gridOptions.data);
                        $scope.$broadcast('displayLoading', false);
                    });
            }

            var init = function () {
                getSecurity();
            };
            var getSecurity = function () {
                $scope.$broadcast('displayLoading', true);
                //getting logged in user security
                security.getCurrentUserSecurityInfo()
                    .then(function (result) {
                        vm.gridOptions.privileges = result.privileges.UpdateGeneralAdmin;
                        vm.gridOptions.showPrivilegePanel = true;
                        if (vm.gridOptions.privileges)
                            getGenderList();
                    },
                    function () {
                        vm.gridOptions.privileges = false;
                    });
            };

            vm.gridOptions = {
                showPrivilegePanel : false,
                enableCellEdit: false,
                enableSorting: true,
                enableCellEditOnFocus: true,
                newObject: { data: { description: null, isDeleted: false, isReadOnly: false }, isUpdated: false, isAdded: true }
            };

            vm.gridOptions.columnDefs = [
                { name: 'data.description', displayName: 'Gender', enableCellEdit: true, enableColumnMenu: false, enableSorting: true  },
                {
                    name: 'data.isDeleted', displayName: '', enableCellEdit: false, enableColumnMenu: false, width: 100, enableSorting: false ,
                    cellClass: function (grid, row) {

                        if (row.entity.data.isReadOnly) {
                            return "grid-readonly-button";
                        }
                        return "grid-Delete-button";
                    },
                    cellTemplate: '<a ng-if="!row.entity.data.isReadOnly && !row.entity.data.isDeleted" title="Delete" ng-click="grid.appScope.deleteRow(row.entity)"><span class="glyphicon glyphicon-trash grid-Delete-button" ></span></a>' +
                        '<a ng-if="!row.entity.data.isReadOnly && row.entity.data.isDeleted" title="ReActivate" ng-click="grid.appScope.reActivate(row.entity)"><span class="glyphicon glyphicon-plus grid-Add-button" ></span></a>' +
                        '<span ng-if="row.entity.data.isReadOnly" title="Read only" class="glyphicon glyphicon-lock" ></span>'

                }
            ];

            vm.validation = function (row, colDef, currentData) {
                if (colDef.name === 'data.description') {
                    if (utils.isNullOrEmpty(row.description)) {
                        return { isValid: false, errors: ['Gender description required'] };
                    }
                }

                return { isValid: true, error: '' }
            }

            vm.saveGenderList = function (list, refreshAfterSave) {
                referenceService.saveGender(list).then(
                    function (result) {
                        if (result.data.length > 0) {
                            getGenderList();
                            refreshAfterSave(result);
                        }

                    },
                    // Error
                    function (result) {
                        refreshAfterSave(result);
                    });
            }

            init();

        }],
        controllerAs: 'genderVm'
    });

})();