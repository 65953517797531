(function () {

    var app = angular.module('phapApp');
    app.component('viewErrorsTasks', {
        templateUrl: 'app/home/dashboard/viewErrorsTasks.html',
        bindings: {
            tasks: '=',
            errorName: '@'
        },
        controller: ['$rootScope', function ($rootScope) {
            var vm = this;

        }],
        controllerAs: 'vm'
    });

})();