(function () {
    angular.module('phapApp')
        .directive('includeTemplate',['$rootScope', function ($rootScope) {
            return {
                restrict: 'E',
                scope: true,
                templateUrl: function (elem, attr) {
                    return attr.src;
                },
                link: function (scope, element, attrs, model) {
                    for (var a in attrs) {
                        if (a.startsWith('param')) {
                            (function () {
                                var exp = attrs[a];
                                var name = a[5].toLowerCase() + a.substring(6);
                                scope.$watch(function () {
                                    var val = scope.$parent.$eval(exp);
                                    return val;
                                }, function (newv, oldv) {
                                    scope[name] = newv;
                                });
                            })();
                        }
                    }
                }
            };
        }]);
})();