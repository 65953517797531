(function () {

	var app = angular.module('phapApp');
	app.component('widget', {
		templateUrl: 'app/home/dashboard/widget.html',
		bindings: {
			title: '@',
			heading: '<',
			tasks: '<',
			noItemLabel: '@',

			isColorCoded: '<',
			isDays: '<',
			isRightDiv: '<',
			isCurrency: '<',
			hideTime: '<',
			timeLapsed: '<',


			label1: '@',
			label2: '@',
			label3: '@',
			link: '@',

		},
		transclude: true,
		controller: ['$rootScope', '$location', 'dashboardService', 'uiGridConstants', 'NgTableParams','$scope', function ($rootScope, $location, dashboardService, uiGridConstants, NgTableParams,$scope) {
			var vm = this;
			
			vm.tableParams = new NgTableParams({
				page: 1,
				count: 10
				
			},
				{
					//total: vm.tasks.length,
					//Returns the data for rendering
					getData: function ($defer, params) {
						if (vm.tasks) {
							vm.data = (vm.tasks.slice((params.page() - 1) * params.count(), params.page() * params.count()));
							$defer.resolve(vm.data);

							params.total(vm.tasks.length);
							params.settings().counts = null;
							//vm.data = _.orderBy(vm.data, ['dateCreated'], ['desc']);
							return vm.data;
						}
					}
				});

			vm.getNotificationFontClass = function (timeDiff) {
				return dashboardService.getNotificationFontClass(timeDiff * 1 - vm.timeLapsed * 1);
			};
			vm.getNotificationBorderFontClass = function (timeDiff) {
				return dashboardService.getNotificationBorderFontClass(timeDiff * 1 - vm.timeLapsed * 1);
			};
			vm.redirectFromDashboard = function (url) {
				if (url)
					$location.path(url);
				else
					$location.path(vm.link);
			};

			var getGridColumnDefs = function () {
				return [
					{
						field: 'incident',
						displayName: 'IncidentNumber',
						enableHiding: false
					}
				];
			};

			vm.$onInit = function () {
				if (!vm.heading || vm.heading.length == 0)
					vm.heading = vm.title;
				//vm.gridOptions = getGridOptions();	
			};
			var getGridOptions = function () {
				return {
					enableSorting: true,
					enableFiltering: false,
					enableColumnResizing: true,
					enableHorizontalScrollbar: uiGridConstants.scrollbars.NEVER,
					enableVerticalScrollbar: uiGridConstants.scrollbars.ALWAYS,
					enableRowSelection: true,
					enableFullRowSelection: true,
					enableRowHeaderSelection: false,
					enableSelectAll: true,
					multiSelect: false,
					enableRowHashing: false,
					columnDefs: getGridColumnDefs(),
					appScopeProvider: {
						openItem: function (row) {
							openItem(row);
						}
					},
					rowTemplate:
					'<div ng-click="grid.appScope.openItem(row);"> ' +
					'<div class="ui-grid-cell" ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" ui-grid-cell ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader }" style="cursor:pointer"> ' +
					'</div>' +
					'</div>',
					onRegisterApi: function (gridApi) {

					}
				}
			};
			//$(document).ready(function () {
			//	vm.gridOptions.data = vm.tasks;
			//});

		}],
		controllerAs: 'widget'
	});

})();