(function () {
	angular.module('phapApp')
		.service('reliefService', ['$http', '$q', 'config', 'Constants', function ($http, $q, config, constants) {
			var service = this;
			service.maxAdults = 0;

			var loadRefData = function () {
				return $http.get(config.apiBaseUrl + "relief/ref-data")
					.then(
					function (result) {
						result.data.$longGenderStr = result.data.genders.map(function (g) { return g.description; });
						result.data.$shortGenderStr = result.data.genders.map(function (g) { return g.description[0].toUpperCase(); });
						result.data.$longGenders = {};
						result.data.$shortGenders = {};
						_.each(result.data.genders, function (g) {
							result.data.$longGenders[g.id] = g.description;
							result.data.$shortGenders[g.id] = g.description[0].toUpperCase();
						});
						return result;
					});
			};

			var loadSearchFilterRefData = function () {
				return $http.get(config.apiBaseUrl + "relief/ref-search-filter-data")
					.then(
						function (result) {
							return result.data;
						});
			};

			var getOnlineApplicationPaymentPreferences = function (id) {
				return $http.get(config.apiBaseUrl + "relief/payment-preferences/" + id)
					.then(
						function (result) {
							return result.data;
						});
			};

			var loadRelief = function (id, cloned) {
				return $http.get(config.apiBaseUrl + "relief/relief-application/" + id + (cloned ? '/true' : ''));
			};

			var getMaximumAdults = function (id) {
				var deferred = $q.defer();
				if (!service.maxAdults) {
					var res = $http.get(config.apiBaseUrl + "relief/maximum-adults/" + id)
						.then(function (data) {
							service.maxAdults = data.data;
							deferred.resolve(service.maxAdults);
						}, function (data) {
							deferred.reject(data);
						});
				} else {
					deferred.resolve(service.maxAdults);
				}

				return deferred.promise;
			};

			// #region Saving

			var saveRelief = function (data) {
				beforeSave(data);

				if (data.state == constants.ReliefApplicationState.Ineligible) {
					return $http.post(config.apiBaseUrl + "relief/relief-application/additional-info", data);
				} else {
					return $http.post(config.apiBaseUrl + "relief/relief-application", data);
                }
			};

			var saveAsWithdrawn = function (data) {
				beforeSave(data);
				return $http.post(config.apiBaseUrl + "relief/relief-application/save-as-withdrawn", data);
			};

			var saveAsIneligible = function (data) {
				beforeSave(data);
				return $http.post(config.apiBaseUrl + "relief/relief-application/save-as-ineligible", data);
			};
			var saveReliefPayments = function (data) {
				var url = config.apiBaseUrl + "payment/relief-application/CreateReliefPayment";
				return $http({
					method: 'POST',
					url: url,
					data: angular.toJson(data),
					headers: { 'Content-Type': 'application/json' }
				}).then(function (response) {
					return response.data;
				});
			}

			var updateReliefPayment = function (data) {
				var url = config.apiBaseUrl + "payment/relief-application/UpdateReliefPayment";
				return $http({
					method: 'POST',
					url: url,
					data: angular.toJson(data),
					headers: { 'Content-Type': 'application/json' }
				}).then(function (response) {
					return response.data;
				});
			}

			var removeReliefPayment = function (data) {
				var url = config.apiBaseUrl + "payment/relief-application/CancelPayment";
				return $http({
					method: 'POST',
					url: url,
					data: angular.toJson(data),
					headers: { 'Content-Type': 'application/json' }
				}).then(function (response) {
					return response.data;
				});
			}

			var submitPayments = function (submitModel) {
				return $http.post(config.apiBaseUrl + "payment/relief-application/SubmitPayments", submitModel)
					.then(function (response) {
						//$http.get(config.apiBaseUrl + "relief/CloseReliefApplications");
						return response.data;
					});
			};

			var beforeSave = function (data) {
				// remove incident object
				delete data.incident;

				if (data.applicant && data.applicant.dob) {
					data.applicant.dob = moment(data.applicant.dob).add(1, 'days').toDate();
				}

				if (data.householdMembers) {
					_.each(data.householdMembers, function (m) {
						if (m.dob) {
							m.dob = moment(m.dob).add(1, 'days').toDate();
						}
						delete m.gender;
					});
				}
				if (data.immediateReliefTypes) {
					var types = [];
					for (var k in data.immediateReliefTypes) {
						if (data.immediateReliefTypes[k] && parseInt(k)) {
							types.push({ immediateReliefTypeId: k });
						}
					}
					data.immediateReliefTypes = types;
				}
				if (data.damageAssessments && data.damageAssessments.length > 0) {
					var types = [];
					for (var k in data.damageAssessments) {
						if (data.damageAssessments[k]) {
							if (data.damageAssessments[k].name && data.damageAssessments[k].name != ""
								&& data.damageAssessments[k].id > 0) {
								types.push({ name: data.damageAssessments[k].name, id: data.damageAssessments[k].id });
							}
						}
					}
					data.damageAssessments = types;
				}
				if (data.affectedAddress) {
					if (data.affectedAddress.insuranceStatuses) {
						var types = [];
						for (var k in data.affectedAddress.insuranceStatuses) {
							if (data.affectedAddress.insuranceStatuses[k] && parseInt(k)) {
								types.push({ insuranceStatusId: k });
							}
						}
						data.affectedAddress.insuranceStatuses = types;
					}
					if (data.affectedAddress.lga && (!data.affectedAddress.lga.lgaCode || !data.affectedAddress.lga.name)) {
						data.affectedAddress.lga = null;
					}
				}
			};

			// #endregion
			var changeImmediateToArray = function (data) {
				if (data.applicant && data.applicant.dob) {
					data.applicant.dob = moment(data.applicant.dob).add(1, 'days').toDate();
				}

				if (data.householdMembers) {
					_.each(data.householdMembers, function (m) {
						if (m.dob) {
							m.dob = moment(m.dob).add(1, 'days').toDate();
						}
						delete m.gender;
					});
				}
				if (data.immediateReliefTypes) {
					var types = [];
					for (var k in data.immediateReliefTypes) {
						if (data.immediateReliefTypes[k] && parseInt(k)) {
							types.push({ immediateReliefTypeId: k });
						}
					}
					data.immediateReliefTypes = types;
				}
				if (data.affectedAddress) {
					if (data.affectedAddress.insuranceStatuses) {
						var types = [];
						for (var k in data.affectedAddress.insuranceStatuses) {
							if (data.affectedAddress.insuranceStatuses[k] && parseInt(k)) {
								types.push({ insuranceStatusId: k });
							}
						}
						data.affectedAddress.insuranceStatuses = types;
					}
					if (data.affectedAddress.lga && (!data.affectedAddress.lga.lgaCode || !data.affectedAddress.lga.name)) {
						data.affectedAddress.lga = null;
					}
				}
				return data;
			}
			var validateAffectedAddress = function (incidentId, applicationNumber, affectedAddress) {
				var defer = $q.defer();

				if (affectedAddress && affectedAddress.lga && affectedAddress.lga.name && affectedAddress.address) {
					if (!_.isEmpty(affectedAddress.lga.name)
						&& !_.some(['streetNo', 'streetName', 'suburb', 'postcode'],
							function (prop) {
								return _.isEmpty(affectedAddress.address[prop]);
							})) {
						var model = {
							incidentId: incidentId,
							applicationNumber: applicationNumber,
							addressToCheck: affectedAddress.address
						};

						$http.post(config.apiBaseUrl + 'relief/relief-application/is-affected-address-already-in-use', model)
							.then(function (result) {
								if (result && result.data) {
									defer.resolve(result.data);
								} else {
									defer.resolve(null);
								}
							}, function (data) {
								deferred.reject(data);
							});
						return defer.promise;
					}
				}

				defer.resolve(true);
				return defer.promise;
			};

			var validateLga = function (incidentId, lga) {
				var defer = $q.defer();

				if (lga && lga.lgaCode != null && lga.name) {
					var model = {
						incidentId: incidentId,
						lga: lga
					};

					$http.post(config.apiBaseUrl +
						'relief/relief-application/is-affected-address-lga-invalid',
						model)
						.then(function (result) {
							if (result && result.data) {
								defer.resolve(result.data);
							} else {
								defer.resolve(true);
							}
						});
					return defer.promise;
				}
				defer.resolve(true);
				return defer.promise;
			};

			var getLga = function () {
				return $http.get(config.apiBaseUrl + "lga");
			};

			var getAssessingOfficers = function (userNameKey) {
				var defer = $q.defer();
				if (userNameKey.length > 0) {
					return $http.get(config.apiBaseUrl + 'security/users/' + userNameKey)
						.then(function (result) {
							return result.data.users;
						});
				} else {
					defer.resolve();
					return defer.promise;
				}
			};

			var isReliefEligible = function (relief) {
				return relief &&
					(relief.previouslyReceivedPayment === undefined || !relief.previouslyReceivedPayment) &&
					(relief.howPeopleApplyingAffected === undefined || relief.howPeopleApplyingAffected) &&
					(relief.isImmediateReliefRequired === undefined || relief.isImmediateReliefRequired);
			};

			var updateAllowed = function ($scope) {
				if ($scope.item && $scope.currentUserSecurityInfo && $scope.currentUserSecurityInfo.userHasAccessToUpdateRelief) {
					return $scope.currentUserSecurityInfo.userHasAccessToUpdateRelief($scope.item.id, $scope.item.incident) == true;
				}
			};

			var getEditPageUrl = function (id, showSuccess) {
				if (showSuccess === undefined) showSuccess = true;
				return "/home/relief/edit/" + id + "/" + (showSuccess ? "true" : "");
			};

			var getCardState = function (cardId) {
				return $http.get(config.apiBaseUrl + "payment/card-state/" + cardId)
					.then(function (response) {
						return response.data;
					});
			};

			var $injector = {
				injectScope: function (scope) {
					scope.$isRelief = true;
				}
			};

			var reopenApplication = function (model) {
				return $http.post(config.apiBaseUrl + "relief/relief-application/reopen-relief-application", model)
					.then(function (response) {
						return response.data;
					});
			}

			var requestBankDetails = function (model) {
				return $http.post(config.apiBaseUrl + "relief/relief-application/update-bank-details", model)
					.then(function (response) {
						return response.data;
					});
			}

			var assignReliefApplicationToIncident = function (model) {
				return $http.post(config.apiBaseUrl + "relief/relief-application/set-incident", model)
					.then(function (response) {
						return response.data;
					});
			}

			var approvePayment = function (data) {
				var url = config.apiBaseUrl + "payment/relief-application/ApprovePayment";
				return $http({
					method: 'POST',
					url: url,
					data: angular.toJson(data),
					headers: { 'Content-Type': 'application/json' }
				}).then(function (response) {
					return response.data;
				});
			}
			var rejectPayment = function (data) {
				var url = config.apiBaseUrl + "payment/relief-application/RejectPayment";
				return $http({
					method: 'POST',
					url: url,
					data: angular.toJson(data),
					headers: { 'Content-Type': 'application/json' }
				}).then(function (response) {
					return response.data;
				});
			}

			var resubmitForApproval = function (data) {
				var url = config.apiBaseUrl + "payment/relief-application/ResubmitForApproval";
				return $http({
					method: 'POST',
					url: url,
					data: angular.toJson(data),
					headers: { 'Content-Type': 'application/json' }
				}).then(function (response) {
					return response.data;
				});
			}
			return {
				loadData: loadRelief,
				loadRefData: loadRefData,
				loadSearchFilterRefData: loadSearchFilterRefData,
				saveData: saveRelief,
				validateAffectedAddress: validateAffectedAddress,
				getLga: getLga,
				beforeSave: changeImmediateToArray,
				validateLga: validateLga,
				getAssessingOfficers: getAssessingOfficers,
				isEligible: isReliefEligible,
				getEditPageUrl: getEditPageUrl,
				updateAllowed: updateAllowed,
				saveAsWithdrawn: saveAsWithdrawn,
				saveAsIneligible: saveAsIneligible,
				saveReliefPayments: saveReliefPayments,
				submitPayments: submitPayments,
				updateReliefPayment: updateReliefPayment,
				removeReliefPayment: removeReliefPayment,
				getCardState: getCardState,
				getMaximumAdults: getMaximumAdults,
				reopenApplication: reopenApplication,
				requestBankDetails: requestBankDetails,
				rejectPayment: rejectPayment,
				assignReliefApplicationToIncident: assignReliefApplicationToIncident,
				approvePayment: approvePayment,
				resubmitForApproval: resubmitForApproval,
				getOnlineApplicationPaymentPreferences: getOnlineApplicationPaymentPreferences,
				$injector: $injector
			};
		}]);
})();