(function () {
	var app = angular.module('phapApp');

    app.service('cardShipmentService', ['$http', '$q', 'config', 'security', 'Constants', function ($http, $q, config, security, Constants) {

		var createCardShipmentOrder = function (data) {			
			var deferred = $q.defer();
			var res = $http.post(config.apiBaseUrl + 'card-shipment/create', data)
				.then(function (data, status, headers, config) {
					deferred.resolve(data);
				}, function (data, status, headers, config) {
					deferred.reject(data);
				});

			return deferred.promise;
		};

		var updateCardShipmentOrder = function (data) {		
			var deferred = $q.defer();
			var res = $http.post(config.apiBaseUrl + 'card-shipment/update', data)
				.then(function (data, status, headers, config) {
					deferred.resolve(data);
				}, function (data, status, headers, config) {
					deferred.reject(data);
				});

			return deferred.promise;
		};		

		var updateCardShipmentDetails = function (data) {
			var deferred = $q.defer();
			var res = $http.post(config.apiBaseUrl + 'card-shipment/update-card-shipment-details', data)
				.then(function (data, status, headers, config) {
					deferred.resolve(data);
				}, function (data, status, headers, config) {
					deferred.reject(data);
				});

			return deferred.promise;
		};		

		var withdrawCardShipmentOrder = function (orderId, rowVersion) {

			var deferred = $q.defer();
			var res = $http.post(config.apiBaseUrl + 'card-shipment/withdraw', { id: orderId, rowVersion: rowVersion })
				.then(function (data, status, headers, config) {
					deferred.resolve(data);
				}, function (data, status, headers, config) {
					deferred.reject(data);
				});
			return deferred.promise;
		};
		
		var getCardShipmentOrderDetails = function (csoId) {
			var deferred = $q.defer();

			var res = $http.get(config.apiBaseUrl + 'card-shipment/details/' + csoId)
				.then(function (response, status, headers, config) {
					deferred.resolve(response.data);
				},
				function (error, status, headers, config) {
					deferred.reject(data);
				});
			return deferred.promise;
		}

		var searchCardShipments = function (search, exportToFile) {
			var deferred = $q.defer();

			var result = $http.post(config.apiBaseUrl + "card-shipment/search/" + (exportToFile === true ? 'csv' : ''), search)
				.then(function (data, status, headers, config) {
					deferred.resolve(data);
				}, function (data, status, headers, config) {
					deferred.reject(data);
				});

			return deferred.promise;
		}

		var getCardShipmentWithCardsForReconcile = function (csoId) {
			var deferred = $q.defer();

            var res = $http.get(config.apiBaseUrl + 'card-shipment/shipment-card-details-reconcile/' + csoId)
				.then(function (response, status, headers, config) {
					deferred.resolve(response.data);
				},
				function (error, status, headers, config) {
					deferred.reject(data);
				});
			return deferred.promise;
		}

        var getCardShipmentReconcileReport = function (csoId) {
            var deferred = $q.defer();

            var res = $http.get(config.apiBaseUrl + 'card-shipment/shipment-card-reconcile-report/' + csoId)
                .then(function (response, status, headers, config) {
                    deferred.resolve(response.data);
                },
                function (error, status, headers, config) {
                    deferred.reject(data);
                });
            return deferred.promise;
        }

		var reconcileCardsInShipment = function (cardsInOrder) {
			var deferred = $q.defer();
			var res = $http.post(config.apiBaseUrl + 'card-shipment/shipment-card-reconcile', cardsInOrder)
				.then(function (response, status, headers, config) {
					deferred.resolve(response.data);
				}, function (data, status, headers, config) {
					deferred.reject(data);
				});

			return deferred.promise;
		}
		
		return {
			createCardShipmentOrder: createCardShipmentOrder,
			updateCardShipmentOrder: updateCardShipmentOrder,
			updateCardShipmentDetails: updateCardShipmentDetails,
			withdrawCardShipmentOrder: withdrawCardShipmentOrder,
            getCardShipmentOrderDetails: getCardShipmentOrderDetails,
            getCardShipmentWithCardsForReconcile: getCardShipmentWithCardsForReconcile,
            searchCardShipments: searchCardShipments,
            getCardShipmentReconcileReport: getCardShipmentReconcileReport,
            reconcileCardsInShipment: reconcileCardsInShipment			
		}
	}]);

})();