(function () {
    angular.module('phapApp')
        .controller('affectedAddressCtrl', ['$scope', 'utils', function ($scope, utils) {

            $scope.$watch('item.affectedAddress.principalPlaceOfResidence', function(nv, ov){
                if (nv !== ov) {
                    if (ov === null || ov === undefined && $scope.item.affectedAddress.principalPlaceOfResidence !== null && $scope.item.affectedAddress.principalPlaceOfResidence !== undefined) {
                        $scope.item.oldPrincipalPlaceOfResidence = $scope.item.affectedAddress.principalPlaceOfResidence;
                    }
                    $scope.recalculateFormEligibility(); 
                }
            });

            $scope.showPprWarning = function () {
                var title = 'Ineligible application!';
                var content = 'Re-establishment assistance is only available to properties that are principal places of residence.';
                utils.confirm(title, content, 'Cancel', 'Ineligible', $scope)
                    .then(function () {
                        $scope.item.affectedAddress.principalPlaceOfResidence = true;
                        $scope.item.oldPrincipalPlaceOfResidence = $scope.item.affectedAddress.principalPlaceOfResidence;
                        $ngConfirm('This address is now becoming the principle place of residence.');
                    },
                    function () {
                        $scope.item.affectedAddress.principalPlaceOfResidence = false;
                        $scope.item.oldPrincipalPlaceOfResidence = $scope.item.affectedAddress.principalPlaceOfResidence;
                        $scope.item.state = 1;
                        $scope.saveAsIneligible();
                    });
            };
        }]);
})();