// Declare the templates module ahead of time.
angular.module('templates', []);

var phapApp =
    angular.module(
        'phapApp',
        [
            'ngCookies', 'ngSanitize', 'ngRoute', 'ngMessages', 'ui.bootstrap', 'ui.grid', 'ui.grid.edit', 'ui.grid.cellNav', 'ui.grid.selection', 'ui.grid.grouping',
            'ui.grid.autoResize', 'ui.grid.infiniteScroll', 'ngFileUpload', 'checklist-model', 'angularFileUpload', 'digestHud', 'templates',
            'angularjs-dropdown-multiselect', 'ui.grid.saveState', 'angularMoment', 'cp.ngConfirm', 'daterangepicker', 'credit-cards', 'LocalStorageModule', 'ui.grid.resizeColumns', 'AdalAngular', 'ngTable',
        ])
        .config(
        [
            'config', '$routeProvider', '$compileProvider', '$httpProvider', 'digestHudProvider', '$qProvider',
            '$provide', 'localStorageServiceProvider', 'adalAuthenticationServiceProvider', '$locationProvider',
            function (config, $routeProvider, $compileProvider, $httpProvider, digestHudProvider, $qProvider,
                $provide, localStorageServiceProvider, adalAuthenticationServiceProvider, $locationProvider) {

                // By default, Angular 1.6 uses #! rather than just # in the URL. This caused problems with
                // ADAL, so I've switched it back to just # instead.
                $locationProvider.hashPrefix('');

                var azureAd = config.authMode == "AzureAD";

                if (azureAd) {
                    var anonymousEndpoints = [];

                    adalAuthenticationServiceProvider.init(
                        {
                            instance: config.authSettings.instance,
                            tenant: config.authSettings.tenant,
                            clientId: config.authSettings.clientId,                           
                            popUp: false,
                            cacheLocation: 'sessionStorage',
                            requireADLogin: azureAd,
                            anonymousEndpoints: anonymousEndpoints,
                            endpoints: config.authSettings.endpoints
                        },
                        $httpProvider
                    );
                }


                //Polyfill for IE11 to allow for compatibility with startsWith
                if (!String.prototype.startsWith) {
                    String.prototype.startsWith = function (searchString, position) {
                        position = position || 0;
                        return this.substr(position, searchString.length) === searchString;
                    };
                }

                //local storage used to store the grid options for card grid
                localStorageServiceProvider
                    .setPrefix('grid-demo')
                    .setStorageType('localStorage')
                    .setNotify(true, true);

                //Add date parser to JSON.parse
                if (window.JSON && !window.JSON.dateParser) {
                    var reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
                    JSON.dateParser = function (key, value) {
                        if (typeof value === 'string') {
                            var isDate = reISO.exec(value);
                            if (isDate) {
                                return new Date(value);
                            }
                        }
                        return value;
                    };
                }

                //digestHudProvider.enable();

                $compileProvider.debugInfoEnabled(false);

                //Fix to get rid of error message "Possibly unhandled rejection: canceled"
                //http://stackoverflow.com/questions/41063947/angular-1-6-0-possibly-unhandled-rejection-error
                $qProvider.errorOnUnhandledRejections(false);

                $routeProvider
                    .when('/home/online-application-management', { templateUrl: 'app/home/online-app-mgmt/online-app-mgmt.html', controller: 'onlineAppMgmtController' })

                    .when('/administration/incidents', { templateUrl: 'app/administration/incidents/administrationIncidents.html', controller: 'administrationIncidentsController' })
                    .when('/administration/incidents/create', { templateUrl: 'app/administration/incidents/editIncident.html', controller: 'editIncidentController' })
                    .when('/administration/incidents/edit/:id', { templateUrl: 'app/administration/incidents/editIncident.html', controller: 'editIncidentController' })
                    .when('/administration/incidents/edit/:id/:fromCreate', { templateUrl: 'app/administration/incidents/editIncident.html', controller: 'editIncidentController' })
                    .when('/home/relief', { templateUrl: 'app/home/relief/reliefApplications.html', controller: 'reliefApplicationsController' })
                    .when('/home/relief/edit/:id/:fromCreate?/:paymentId?', { templateUrl: 'app/home/relief/editRelief.html', controller: 'editReliefController' })
                    .when('/home/relief/create/:incidentId', { templateUrl: 'app/home/relief/editRelief.html', controller: 'editReliefController' })
                    .when('/home/relief/clone/:id', { templateUrl: 'app/home/relief/editRelief.html', controller: 'editReliefController' })

                    .when('/home/reestablish', { templateUrl: 'app/home/reestablish/editReestablish.html', controller: 'editReestablishController' })
                    .when('/home/reestablish/edit/:id/:fromCreate?/:paymentId?', { templateUrl: 'app/home/reestablish/editReestablish.html', controller: 'editReestablishController' })
                    .when('/home/reestablish/create/:incidentId', { templateUrl: 'app/home/reestablish/editReestablish.html', controller: 'editReestablishController' })
                    .when('/home/reestablish/clone/:id', { templateUrl: 'app/home/reestablish/editReestablish.html', controller: 'editReestablishController' })
                    .when('/home/reestablish/search', { template: '<reestablish-search />' })
                    .when('/home/lga/matchLga/:lga', { templateUrl: 'app/home/relief/editRelief.html', controller: 'editReliefController' })
                    .when('/home/lga/searchLga/:lga', { templateUrl: 'app/home/relief/editRelief.html', controller: 'editReliefController' })
                    .when('/home/vendor/search/:name?/:bsb?/:account?/:contact?', { template: '<vendor-search display-mode="standalone"></vendor-search>' })
                    .when('/home/vendor/create', { templateUrl: 'app/home/vendor/create.html' })
                    .when('/home/vendor/edit/:id', { templateUrl: 'app/home/vendor/edit.html' })
                    .when('/home/vendor/edit/:id/:fromCreate', { templateUrl: 'app/home/vendor/edit.html' })
                    .when('/administration/configuration/reliefCentre', { template: '<relief-centre />' })
                    .when('/administration/configuration/lga', { template: '<lga />' })

                    .when('/administration/configuration/:name', { template: '<configurable-list />' })
                    .when('/administration/security/:name', { template: '<security-list />' })
                    .when('/emergencyManagement/cardShipment/search', { template: '<card-shipment-order-search />' })

                    .when('/financeManagement/dashboard', { template: '<finance-dashboard />' })

                    .when('/financeManagement/cards/search/:orderNumber/:cardStatus', { template: '<card-search mode="main"/>' })
                    .when('/financeManagement/cards/search', { template: '<card-search mode="main"/>' })
                    .when('/financeManagement/cards/details/:id/:type', { template: '<card-view />' })
                    .when('/financeManagement/cards/receiveBatch/:id', { template: '<card-receive type="batch" />' })
                    .when('/financeManagement/cards/receiveOrder/:id', { template: '<card-receive type="order" />' })
                    .when('/financeManagement/cards/cardStorage/:batchNumber', { template: '<card-storage />' })
                    .when('/financeManagement/cards/cardAllocate/:id', { template: '<card-allocate />' })
                    .when('/financeManagement/cards/cardBulkUpload', { template: '<card-bulk-upload />' })
                    .when('/financeManagement/cards/devCardUpload', { template: '<dev-card-upload />' })
                    .when('/financeManagement/cards/cardActivitySearch', { template: '<card-activity-search />' })
                    .when('/financeManagement/cards/cardActivity/:requestType/:id?', { template: '<card-search mode="activity"/>' })
                    .when('/financeManagement/cards/stocktakeSearch', { template: '<card-stocktake-search />' })
                    .when('/financeManagement/cards/stocktake/create', { template: '<card-stocktake mode="create" />' })
                    .when('/financeManagement/cards/stocktake/view/:id', { template: '<card-stocktake mode="view" />' })
                    .when('/financeManagement/cheques/chequeSearch', { template: '<cheque-search />' })
                    .when('/financeManagement/cheques/details/:id', { template: '<cheque-view />' })

                    .when('/financeManagement/cardShipment/search', { template: '<card-shipment-order-search />' })
                    .when('/financeManagement/cardShipmentOrder/create', { template: '<card-shipment-order mode="create" />' })
                    .when('/financeManagement/cardShipmentOrder/edit/:id/', { template: '<card-shipment-order mode="edit" />' })
                    .when('/financeManagement/cardShipmentOrder/edit/:id/:fromCreate', { template: '<card-shipment-order mode="edit" />' })

                    .when('/financeManagement/directDeposit', { template: '<direct-deposit>' })
                    .when('/financeManagement/directDeposit/details/:id', { template: '<direct-deposit-details>' })

                    .when('/reports/:id', { template: '<reports-home />' })

                    .when('/test-support/gl-string-config', { templateUrl: 'app/test-support/gl-string-config.html', controller: 'glStringConfigController' })

                    .when('/dashboard', { templateUrl: 'app/home/dashboard/dashboard.html', controller: 'dashboardController' })

                    .when('/administration/history/:entity/:id', { template: '<history />' })
                    .when('/administration/history/:entity/:id/:referenceNumber', { template: '<history />' })

                    .when('/administration/error', { template: '<error-list />' })

                    .when('/errorHandling/ap', { template: '<ap-error-search>' })
                    .when('/errorHandling/ap/details/:id', { template: '<ap-error-details>' })
                    .when('/errorHandling/ap/details/', { template: '<ap-error-details>' })
                    .when('/errorHandling/gl', { template: '<gl-error-search>' })
                    .when('/errorHandling/gl/details/:id', { template: '<gl-error-details>' })
                    .when('/errorHandling/gl/details/', { template: '<gl-error-details>' })

                    .otherwise({ redirectTo: '/dashboard' });

                // Configure the httpProvider so that CORS requests will succeed
                $httpProvider.defaults.useXDomain = true;
                $httpProvider.defaults.withCredentials = true;

                delete $httpProvider.defaults.headers.common['X-Requested-With'];

                if (!$httpProvider.defaults.headers.get) {
                    $httpProvider.defaults.headers.get = {};
                }
                //disable IE ajax request caching
                $httpProvider.defaults.headers.get['If-Modified-Since'] = 'Mon, 26 Jul 1997 05:00:00 GMT';
                // extra
                $httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache';
                $httpProvider.defaults.headers.get['Pragma'] = 'no-cache';

                $provide.factory('httpErrorHandlingResponseInterceptor', ['$q', '$rootScope', 'notificationService', 'adalAuthenticationService',
                    function ($q, $rootScope, notificationService, adalAuthenticationService) {
                        return {
                            'responseError': function (rejection) {
                                notificationService.notify('showLoadingPanel', false);
                                if (typeof (rejection.status) === "undefined") {
                                    // Don't do anything; this isn't an actual HTTP error coming back - it may be a rejection by
                                    // ADAL
                                    // check status AADSTS50058 which means that A silent sign-in request was sent but no user is signed in.
                                    // in this case we will need the user to sign in.
                                    // this could be the case when the user token has expired.
                                    if (rejection.data && rejection.data.startsWith('AADSTS50058')) {
                                        adalAuthenticationService.login();
                                    }
                                    return;
                                }

                                // If status is "Bad Request", and we have a collection (maybe empty!) of request validation issues,
                                // then we do not report an error to the user here; it's the responsibility of the code calling $http
                                // to report/handle.
                                if (rejection.status !== 400 ||
                                    (rejection.data && !rejection.data.errors)) {
                                    $rootScope.$broadcast("handleError", rejection);
                                }

                                return $q.reject(rejection);
                            }
                        };
                    }]);

                $httpProvider.interceptors.push('httpErrorHandlingResponseInterceptor');
            }
        ]
        )
        .run(
        [
            // We include errorReportingService in the set of dependencies for run() so that the errorReportingService initialises
            // on startup
            '$http', '$cookies', 'errorReportingService', '$location', '$rootScope', '$route',
            function ($http, $cookies, errorReportingService, $location, $rootScope, $route) {

                // monitor acquire of token. if user is not authenticated after completion, reload.
                $rootScope.$on('adal:acquireTokenSuccess', function (event) {
                    if (event.targetScope && event.targetScope.userInfo && event.targetScope.userInfo.isAuthenticated === false) {
                        $route.reload();
                    }
                });

                //this will close all popups when back button is pressed
                $rootScope.$on('$locationChangeStart', function (event) {
                    var button = document.getElementsByClassName("popupButton");
                    if (button && button.length > 0) {
                        event.preventDefault();
                    }
                });
            }
        ]
        );

// We defer bootstrap of angular until we've loaded configuration.json, which gives us the address of the API server
angular.element(document).ready(function () {
    $.get('configuration.json?' + new Date().getTime(),
        function (data) {
            // Now that we have configuration json, we initialise our 'constants' accordingly
            phapApp.constant('config', {
                apiBaseUrl: data.apiBaseUrl,
                authMode: data.authMode,
                authSettings: data.authSettings,
                environment: data.environment
            });

            angular.bootstrap(document, ['phapApp']);
        });
});