(function () {
    var app = angular.module('phapApp');

    app.service('vendorService', ['$http', '$q', 'config', function ($http, $q, config) {


        var createVendor = function (vendor) {
            data = vendor;
            var deferred = $q.defer();
            var res = $http.post(config.apiBaseUrl + 'vendor/create', data)
                .then(function (data, status, headers, config) {
                    deferred.resolve(data);
                }, function (data, status, headers, config) {
                    deferred.reject(data);
                });

            return deferred.promise;
        };

        var getVendorStatusList = function () {
            var deferred = $q.defer();

            var res = $http.get(config.apiBaseUrl + 'vendor/get-vendor-status-list')
                .then(function (response, status, headers, config) {
                    deferred.resolve(response.data);
                },
                function (error, status, headers, config) {
                    deferred.reject(data);
                });

            return deferred.promise;
        }

        var updateVendor = function (vendor) {
            data = vendor;
            var deferred = $q.defer();
            var res = $http.post(config.apiBaseUrl + 'vendor/update', data)
                .then(function (data, status, headers, config) {
                    deferred.resolve(data);
                }, function (data, status, headers, config) {
                    deferred.reject(data);
                });

            return deferred.promise;
        };

        var getVendorDetails = function (vendorId) {
            var deferred = $q.defer();

            var res = $http.get(config.apiBaseUrl + 'vendor/details/' + vendorId)
                .then(function (response, status, headers, config) {
                    deferred.resolve(response.data);
                },
                function (error, status, headers, config) {
                    deferred.reject(data);
                });

            return deferred.promise;
        }

        var searchVendors = function (vendorSearchModel, exportToCsv) {
      
            var deferred = $q.defer();

			var res = $http.post(config.apiBaseUrl + 'vendor/search/' + (exportToCsv === true ? 'csv' : ''),
				vendorSearchModel)
                .then(function (data, status, headers, config) {
                    deferred.resolve(data);
                }, function (data, status, headers, config) {
                    deferred.reject(data);
                });

            return deferred.promise;
        }  

        return {
            createVendor: createVendor,
            updateVendor: updateVendor,
            getVendorStatusList: getVendorStatusList,
            getVendorDetails: getVendorDetails,
            searchVendors: searchVendors
        }
    }]);

})();