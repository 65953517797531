(function () {
	angular
		.module('phapApp')
		.controller('checkoutCard', ['$scope', '$timeout', 'utils', 'cardStorageService', '$q', 'security', 'incidentService', 'cardService',
			function ($scope, $timeout, utils, cardStorageService, $q, security, incidentService, cardService) {
				var vm = this;
				$scope.loaded = false;
				$scope.updating = false;
				$scope.checkoutDate = moment().format("DD-MMM-YYYY");

				$scope.initcheckoutCardDialog = function () {
					vm.allPromises = [];
					vm.selectedCardsToBecheckout = $scope.selectedCards;

					vm.location = $scope.location;

					vm.allPromises.push(security.getAllUsersWithPrivelegeByRegionId($scope.regionId, null)
						.then(function (result) {
							$scope.usersInRegion = result;
						}));
					vm.allPromises.push(incidentService.getOpenIncidents()
						.then(function (result) {
							$scope.incidents = result.data;
						}));
					$q.all(vm.allPromises).then(
						function (result) {
							$scope.loaded = true;
						});
				};

				vm.checkoutCard = function (userId, incidentId, checkoutDate) {
					$scope.updating = true;
					var model = {
						cards: [],
						incidentId: incidentId,
						checkoutToUserId: userId.id,
						checkOutDate: checkoutDate
					};
					model.cards = vm.selectedCardsToBecheckout;
					cardService.checkOutCards(model).then(
						function (data) {
							if (data == "604") {
								utils.alert("One or more cards do not have a valid status.")
							}
							if (data == "605") {
								utils.alert("One or more cards do not have a active status.")
							}
							else {
								$scope.updating = false;
								if (data && data.length > 0) {
									$scope.closeAndHide();
									$scope.loadData();
								}
							}
						}
					);

				}
				$scope.enable = function () {
					return (vm.boxId == '' && vm.storageLocation == '');
				}
				vm.applyChanges = function () {
					var content = "";
					$scope.copyBack();
					$scope.closeAndHide();
				};

				this.cancelEdit = function () {
					$scope.closeAndHide();
				};

				$scope.copyBack = function () {
					// Finally set the form to dirty!
					$scope.frm.$setDirty();
				};

				$scope.closeAndHide = function () {
					$scope.$close(true);
				}
				$scope.initcheckoutCardDialog();

			}]);
	controllerAs: 'checkoutCard'
})();

