(function () {

	var app = angular.module('phapApp');

	app.component('cardShipmentOrderSearch', {
		templateUrl: 'app/financeManagement/cardShipment/cardShipmentOrderSearch.html',
		bindings: {
			mode: '@'
		},
        controller: ['$routeParams', '$location', '$scope', '$timeout', 'uiGridConstants', 'cardService', 'cardShipmentService', 'navigationService', 'localStorageService', 'Constants', 'config', '$window', '$cookies', 'security', 'cacheService', '$q', 'notificationService','$interval',
            function ($routeParams, $location, $scope, $timeout, uiGridConstants, cardService, cardShipmentService, navigationService, localStorageService, constants, config, $window, $cookies, security, cacheService, $q, notificationService, $interval) {
				var vm = this;
				vm.loaded = false;
                vm.constants = constants;
                vm.filterVisible = true;

                if ($location.path().indexOf("financeManagement") > -1) {
                    navigationService.setCurrentMenuStatus(
                        navigationService.topMenuCodes.financeManagement,
                        navigationService.pageCodes.cardShipment2);
                } else {
                    navigationService.setCurrentMenuStatus(
                        navigationService.topMenuCodes.emergencyManagement,
                        navigationService.pageCodes.cardShipment);
                }
                

				// #region Grid

				var getCardSearchGridColumnDefs = function () {
                    return [
                        {
                            field: 'id',
                            visible: false
                        },
                        {
                            field: 'shipmentNumber',
                            displayName: 'Order no',
                            enableHiding: false,
                            width: '10%',
                            enableColumnMenu: false
                        },
                        {
                            field: 'orderDate',
                            displayName: 'Date',
                            enableHiding: false,
                            width: '12%',
                            enableColumnMenu: false,
                            cellClass: 'date-field',
                            cellFilter: constants.GridDateFormat
                        },
                        {
                            field: 'urgency',
                            displayName: 'Urgency',
                            enableHiding: false,
                            width: '10%',
                            enableColumnMenu: false
                        },
                        {
                            field: 'cardQuantity',
                            displayName: 'Qty',
                            type: 'number',
                            enableHiding: false,
                            width: '6%',
                            enableColumnMenu: false
                        },
                        {
                            field: 'orderStatus',
                            displayName: 'Status',
                            enableHiding: false,
                            width: '12%',
                            enableColumnMenu: false
                        },
                        {
                            field: 'region',
                            displayName: 'Region',
                            enableHiding: false,
                            width: '15%',
                            enableColumnMenu: false
                        },
                        {
                            field: 'location',
                            displayName: 'Location',
                            enableHiding: false,
                            width: '20%',
                            enableColumnMenu: false
                        },
                        {
                            field: 'recipient',
                            displayName: 'Recipient',
                            enableHiding: false,
                            width: '15%',
                            enableColumnMenu: false
                        }

                    ]
				};

				var getGridOptions = function () {
                    return {
						enableSorting: true,
						enableFiltering: false,
						enableHorizontalScrollbar: uiGridConstants.scrollbars.ALWAYS,
						enableVerticalScrollbar: uiGridConstants.scrollbars.ALWAYS,
						enableRowSelection: false,
						enableFullRowSelection: false,
						enableRowHeaderSelection: false,
						enableSelectAll: false,
						multiSelect: false,
						columnDefs: getCardSearchGridColumnDefs(),
						appScopeProvider: {
							selectRow: function (row) {
								$location.path("/financeManagement/cardShipmentOrder/edit/" + row.entity.id);
							}
						},
						rowTemplate:
                        '<div ng-click="grid.appScope.selectRow(row);"> ' +
                        '<div class="ui-grid-cell cursor-pointer" ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" ui-grid-cell ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader }" > ' +
                        '</div>' +
                        '</div>',
						onRegisterApi: function (gridApi) {
                            vm.gridApi = gridApi;
                            vm.gridApi.colResizable.on.columnSizeChanged($scope, saveState);
                            vm.gridApi.core.on.columnVisibilityChanged($scope, saveState);
                            vm.gridApi.core.on.filterChanged($scope, saveState);
                            vm.gridApi.core.on.sortChanged($scope, saveState);
                            // call resize every 500 ms for 5 s after modal finishes opening - usually only necessary on a bootstrap modal
                            $interval(function () {
                                vm.gridApi.core.handleWindowResize();
                            }, 500, 10);
                            // Restore previously saved state.
                            restoreState();
						}
					}
				};

				// #endregion

                function saveState() {
                    var state = vm.gridApi.saveState.save();
                    localStorageService.set('cardShipmentSearch.gridState.' + $cookies.get("UserId"), state);
                }

                function restoreState() {
                    $timeout(function () {
                        var state = localStorageService.get('cardShipmentSearch.gridState.' + $cookies.get("UserId"));
                        if (state) vm.gridApi.saveState.restore($scope, state);
                    });
                }

				vm.filterLocations = function (location) {
					if (vm.search.region === -1) {
						return true;
					}
					return location.regionId === vm.search.region.id;
				};

				vm.resetSearchParams = function () {
					vm.search = {
						region: -1,
						location: null,
						orderStatuses: [],
						shipmentNumber: "",
						startDate: null,
						endDate: null,
						endDateError: false,
						startDateError: false
					}
				}

				vm.clearErrorMessages = function () {
					vm.validationErrorMsg = "";
					vm.showInvalidSearchMessage = false;
					vm.search.endDateError = false;
					vm.search.startDateError = false;
				}

				vm.clearFilters = function () {
					vm.resetSearchParams();

                    vm.timeFilter.show = false;
                    vm.filterVisible = true;
					vm.clearErrorMessages();
					vm.gridOptions.data = [];
					cacheService.removeItemFromCache('cardShipmentSearchFilters.' + $cookies.get("UserId"));
				};

				vm.$onInit = function () {
                    vm.userInfo = null;
					vm.loaded = false;
					vm.timeFilter = { show: false };
					vm.resetSearchParams();
					vm.clearErrorMessages();
					vm.allPromises = [];
					vm.refdata = {
						locations: [],
						regions: [],
						orderStatuses: []
                    }
                    vm.gridOptions = getGridOptions();

					cardService.mapToModel(constants.cardShippingStatusNames, vm.refdata.orderStatuses);

					vm.allPromises.push(security.getCurrentUserSecurityInfo()
						.then(function (result) {

							vm.currentUserSecurityInfo = result;

							if (vm.currentUserSecurityInfo.privileges.SearchCardShipmentOrder === true) {
								vm.allPromises.push(cardService.getRegionLocationRefData()
									.then(
									function (data) {
										vm.refdata.locations = data.locations;
										vm.refdata.regions = data.regions;
										vm.restoreFilters();
									}));

							}
						},
						function () {
							vm.currentUserSecurityInfo = {};
						})
					);

					$q.all(vm.allPromises).then(
						function (result) {
                            initialise();
                            vm.loaded = true;
						});
				};

				function initialise() {
					vm.loadSavedFilters();
				}

				vm.createNew = function () {
					$location.path("/financeManagement/cardShipmentOrder/create");
				};

				// #region Filters 
				vm.restoreFilters = function () {
					var locationIds = $window.localStorage.getItem("locationIds");
					if (locationIds) {
						vm.search.locations = JSON.parse(locationIds);
					}
				};

				vm.saveFilters = function () {
					cacheService.saveToCache('cardShipmentSearchFilters.' + $cookies.get("UserId"), vm.search);
				};

				vm.retrieveFilters = function () {
					return cacheService.readFromCache('cardShipmentSearchFilters.' + $cookies.get("UserId"));
				};

				vm.loadSavedFilters = function () {
					var savedData = vm.retrieveFilters();

					if (savedData) {
						vm.search = savedData;
					} else {
						vm.clearFilters();
					}
				}
				// #endregion

				vm.checkSearchDates = function () {
					var startDate = null;
					var endDate = null;

					vm.searchForm.endDate.$setValidity('greaterThanStartDate', true);

					if (vm.search.startDate) {
						startDate = moment(vm.search.startDate);
					}

					if (vm.search.endDate) {
						endDate = moment(vm.search.endDate);
						if (!startDate || moment(endDate, 'YYYY-MM-DD').isBefore(startDate)) {
							vm.searchForm.endDate.$setValidity('greaterThanStartDate', false);
						}
					}
				}

				vm.searchRegionChanged = function () {
					vm.search.location = null;
				}

				vm.exportToCsv = function () {
					vm.searchCardShipments(true);
				}

				vm.searchCardShipments = function (exportToCsv) {
					var startDate = null;
					var endDate = null;
					if (vm.search.startDate) {
						startDate = moment(vm.search.startDate).format('YYYY-MM-DD');
					}
					if (vm.search.endDate) {
						endDate = moment(vm.search.endDate).format('YYYY-MM-DD');
					}
					var search = {};
					search.orderStatus = [];
					var statusIds = [];
					vm.clearErrorMessages();
					var orderStatuses = [];
					if (exportToCsv) {
						var savedStartDate = vm.search.startDate;
						var savedEndDate = vm.search.endDate;
						vm.exporting = true;
						vm.search = vm.retrieveFilters();
						orderStatuses = vm.search.orderStatuses;
						vm.search.startDate = savedStartDate;
						vm.search.endDate = savedEndDate;
					}
					else {
						vm.searching = true;
						vm.gridOptions.data = [];
						vm.saveFilters();
					}
					search.cardShipmentDateFrom = startDate;
					search.cardShipmentDateTo = endDate;
					search.regionId = vm.search.region !== -1 ? vm.search.region.id : null;
					search.locationId = vm.search.location ? vm.search.location.id : null;
					search.shipmentNumber = vm.search.shipmentNumber;
					orderStatuses = vm.search.orderStatuses;
					_.map(orderStatuses, function (item) { return statusIds.push(item.id); });
					search.orderStatus = statusIds;

					// create search object


					cardShipmentService.searchCardShipments(search, exportToCsv)
						.then(function (response) {
							if (exportToCsv) {
								var file = new Blob([response.data], { type: 'text/csv' });
								saveAs(file, 'Orderlist.csv');
							}
							else {
								if (response.data.length > 0) {
									vm.noResultsFound = false;
									vm.gridOptions.data = response.data;
								}
								else if (response.data.length === 0) {
									vm.noResultsFound = true;
								}
                            }
                            vm.exporting = false;
                            vm.searching = false;
						});
				};

				vm.locationDropdownSettings = {
					smartButtonMaxItems: 3,
					smartButtonTextConverter: function (item) { return item; },
					buttonClasses: "btn-multi-select-bare",
					showCheckAll: false
				};

				vm.statusDropdownSettings = {
					smartButtonMaxItems: 3,
					smartButtonTextConverter: function (item) { return item; },
					buttonClasses: "btn-multi-select-bare",
					showCheckAll: false
				};

				vm.statusTypeDropdownTranslationTexts = {
					buttonDefaultText: "All order status",
					uncheckAll: "Clear status filter"
				};
			}
		],
		controllerAs: 'csSearch'
	});
})();