(function () {
    angular.module('phapApp')
        .controller(
            'editReliefController',
            [
                '$scope', '$location', '$timeout', 'uiGridConstants', '$route', '$routeParams', '$http', '$q', 'config', 'security',
                '$uibModal', '$document', '$compile', 'lgaService', 'incidentService', 'utils', 'Constants', 'shared', 'searchMemberService',
                'reliefService', 'navigationService', 'notificationService',
                bizController]
        );

    function bizController($scope, $location, $timeout, uiGridConstants, route, $routeParams, $http, $q, config, security,
        $uibModal, $document, $compile, lgaService, incidentService, utils, Constants, shared, searchMemberService, bizService, navigationService, notificationService) {
        $scope.allExpanded = true;
        $scope.collapseMenu = true;

        incidentService.getOpenIncidents()
            .then(function (result) {
                $scope.openIncidents = result.data;
            });

        $scope.setIncident = function () {
            $scope.state.submitting = true;

            var model = {
                applicationId: $scope.item.id,
                incidentId: $scope.incidentIdToAssign
            };

            bizService.assignReliefApplicationToIncident(model).then(
                function (result) {
                    $scope.state.submitting = false;
                    utils.alert('The relief application has been assigned to the selected incident.');
                    route.reload();
                },
                function (result) {
                    $scope.state.submitting = false;
                }
            );
        }

        var securityInfo = {};

        $scope.damageAssessments = [
            { name: "Destroyed", displayName: "Destroyed", id: 1, description: "Premises has been destroyed or damaged beyond repair.", isChecked: false },
            { name: "Major", displayName: "Major", id: 2, isChecked: false, description: "Premises not habitable for a short period < 2 months. Premises may have damage to non-essential areas or damage that can be repaired in a short time." },
            { name: "Moderate", displayName: "Moderate", id: 3, isChecked: false, description: "Premises is habitable but non-essential areas such as bedrooms have been impacted. Premised may be temporarily uninhabitable (estimated < 1 week) while repairs are carried out." },
            { name: "Minor", displayName: "Minor", id: 4, isChecked: false, description: "Premises is habitable. Premises impacted mostly externally with no residual affect." },

            { name: "Inaccessible", displayName: "Inaccessible", id: 5, isChecked: false, description: "Premises impact is unknown as the applicant evacuated before impact. Indicate how long the premises is likely to be inaccessible for." },
            { name: "FloodedAboveFloor", displayName: "Flooded above floor", id: 6, isChecked: false, description: "Premises has been flooded to above floor level." },
            { name: "Other", displayName: "Other", id: 7, description: "", isChecked: false },
        ];

        //
        $scope.toggleSelection = function toggleSelection(item) {
            $scope.item.damageAssessments = [];

            var idx = $scope.damageAssessments.indexOf(item);
            // Is currently selected
            item.isChecked = !item.isChecked;
            _.forEach($scope.damageAssessments, function (value) {
                if (value.isChecked == true) {
                    $scope.item.damageAssessments.push(value)
                }
            });
        }

        ////
        Array.prototype.inArray = function (comparer) {
            for (var i = 0; i < this.length; i++) {
                if (comparer(this[i])) return true;
            }
            return false;
        };

        // adds an element to the array if it does not already exist using a comparer
        // function
        Array.prototype.pushIfNotExist = function (element, comparer) {
            if (!this.inArray(comparer)) {
                this.push(element);
            }
        };
        ///
        $scope.formSections = {
            internalUseOnly: { id: 'internalUseOnly', sectionName: "Assessment and review notes", expanded: false, pageState: Constants.pageState.reliefForm, showNav: true },
            privacy: { id: 'privacy', sectionName: "Privacy Notice", expanded: false, pageState: Constants.pageState.reliefForm, showNav: true },
            affectedAddress: { id: 'affectedAddress', sectionName: "Affected Address", expanded: true, pageState: Constants.pageState.reliefForm, showNav: true },
            applicant: { id: 'applicantDetails', sectionName: "Applicant", expanded: false, pageState: Constants.pageState.reliefForm, showNav: true },
            householdMembers: { id: 'householdMembers', sectionName: "Household Members", expanded: false, pageState: Constants.pageState.reliefForm, showNav: false },
            damageAssessment: { id: 'damageAssessment', sectionName: "Damage Assessment", expanded: false, pageState: Constants.pageState.reliefForm, showNav: true },
            validationRules: {
                id: 'validationRules', sectionName: 'Validation Rules', expanded: true, showNav: true,
                subMenu: {
                    receivedPayment: { id: 'paymentReceived', sectionName: "Previously received payment", expanded: false, pageState: Constants.pageState.reliefForm, showNav: true },
                    affectedPeople: { id: 'affectedPeople', sectionName: "Affected by the incident", expanded: false, pageState: Constants.pageState.reliefForm, showNav: true },
                    immediateRelief: { id: 'immediateRelief', sectionName: "Immediate relief required", expanded: false, pageState: Constants.pageState.reliefForm, showNav: true }
                }
            },
            documents: { id: 'documents', sectionName: "Documents", expanded: false, pageState: Constants.pageState.reliefForm, showNav: true },

            applicationAmounts: {
                id: 'applicationAmounts', sectionName: 'Payment amount', expanded: true, pageState: Constants.pageState.reliefForm, showNav: true,
                subMenu: {
                    paymentAmount: { id: 'paymentAmount', sectionName: "Relief assistance amount", expanded: true, pageState: Constants.pageState.reliefForm, showNav: true }
                }
            },
            paymentDetails: { id: 'paymentDetails', sectionName: "Payment Details", expanded: true, pageState: Constants.pageState.reliefPayment, showNav: true }
        };
        navigationService.subMenus.reliefMenu.menu = $scope.formSections;
        navigationService.setCurrentMenuStatus(
            navigationService.topMenuCodes.emergencyManagement,
            navigationService.pageCodes.reliefApplications,
            'Application');


        $scope.$watch('loaded', function (newValue) {
            if (newValue) {
                notificationService.notify('clickTopMenu', {
                    sectionCode: navigationService.topMenuCodes.emergencyManagement,
                    pageCode: navigationService.pageCodes.reliefApplications,
                    leftSubMenuCode: 'Application',
                    showMenu: $scope.item.id > 0
                });
            }

            setValidRefernceDate();
        });
        $scope.HideUploadButton = function () {
            return $scope.item.state == Constants.ReliefApplicationState.Ineligible;
        };
        function setValidRefernceDate() {
            if ($scope.refData) {
                if ($scope.item.id != 0) {
                    $scope.refData.gendersValid = angular.copy($scope.refData.genders);
                    $scope.refData.idTypesValid = angular.copy($scope.refData.idTypes);
                    $scope.refData.immediateReliefTypesValid = angular.copy($scope.refData.immediateReliefTypes);
                }
                else {
                    $scope.refData.gendersValid = [];
                    $scope.refData.genders.forEach(function (type) {
                        if (!type.isDeleted)
                            $scope.refData.gendersValid.push(type);
                    });
                    $scope.refData.idTypesValid = [];
                    $scope.refData.idTypes.forEach(function (type) {
                        if (!type.isDeleted)
                            $scope.refData.idTypesValid.push(type);
                    });
                    $scope.refData.immediateReliefTypesValid = [];
                    $scope.refData.immediateReliefTypes.forEach(function (type) {
                        if (!type.isDeleted)
                            $scope.refData.immediateReliefTypesValid.push(type);
                    });
                }
                $scope.longGenderStr = $scope.refData.gendersValid.map(function (g) { return g.description; });
                $scope.longGenders = [];
                $scope.showPayment = false;
                $scope.formValue = {};
                _.each($scope.refData.gendersValid, function (g, i) {
                    $scope.longGenders.push({ id: g.id, isDeleted: g.isDeleted, description: g.description });
                });
                $scope.maximumNoOfAdults();
            }
        };

        $scope.clickAccept = function () {
            notificationService.notify('showMenu', {
                pageCode: navigationService.pageCodes.reliefApplications,
                showMenu: $scope.item.iAccept
            });
        }

        $scope.pageStates = Constants.pageState;
        $scope.pageState = Constants.pageState.reliefForm;

        $scope.expandSections = function (expanded) {
            utils.expandSections(expanded, $scope.formSections);
            $scope.allExpanded = expanded;
            setTimeout((function () {
            }), 1000);
            return;
        }

        $scope.isChild = function (member) {
            if ($scope.item && member && member.dob) {
                var asofDate = $scope.item.dateCreated ? moment($scope.item.dateCreated) : moment();
                return utils.getAge(member.dob, asofDate) < 18;
            }
        };

        $scope.saveApplication = function () {

            if ($scope.item.displayedState == 'Submitted' ||
                $scope.item.displayedState == 'Draft') {

                $scope.state.applicationsWithSameAddress = [];
                $scope.state.addressInUse = false;

                return bizService.validateAffectedAddress($scope.item.incidentId, $scope.item.applicationNumber, $scope.item.affectedAddress)
                    .then(function (result) {
                        var addressIsInUse = result && result.length > 0;
                        var proceedToSave = true;
                        if (addressIsInUse) {
                            $scope.state.addressInUse = true;
                            $scope.state.applicationsWithSameAddress = result;
                            $scope.openAffectedAddressModal().then(function () {

                                proceedToSave = $scope.state.proceedOnAddressInUse;

                                if (proceedToSave) {
                                    $scope.saveData();
                                }

                                return $scope.state.proceedOnAddressInUse;
                            });
                        }
                        else {

                            if (proceedToSave) {
                                $scope.saveData();
                            }
                        }

                    });

            }
            else {

                $scope.saveData();
            }
        };

        $scope.openAffectedAddressModal = function () {
            var deferred = $q.defer();

            var address = $scope.item.affectedAddress.address;
            var strAddress = '';
            if (!!address.streetNo && !!address.streetName && !!address.suburb && !!address.postcode) {
                if (!!address.unitNo) {
                    strAddress = address.unitNo + '/';
                }
                strAddress += address.streetNo +
                    ' ' +
                    address.streetName +
                    ', ' +
                    address.suburb +
                    ', ' +
                    address.postcode +
                    ', Victoria, Australia';
            }

            if (!$scope.state.affectedAddressModalOpened) {
                $scope.state.affectedAddressModalOpened = true;
                var resolve = {
                    applications: function () {
                        return $scope.state.applicationsWithSameAddress;
                    },
                    address: function () {
                        return !strAddress ? $scope.item.address : strAddress;
                    },
                    defer: function () {
                        return deferred;
                    }
                };
                utils.openModal('app/home/relief/_affectedAddressCheckModalTemplate.html', 'AffectedAddressCheckModalCtrl', $scope, resolve);
            } else {
                deferred.resolve();
            }
            return deferred.promise;
        };

        $scope.$on('refDataLoaded', function (event, result) {
            $scope.refData = result.data;
            setValidRefernceDate();
        });
        $scope.$on('dataLoaded', function () {
            $scope.item.documents = $scope.item.documents || {};
            if (!$scope.state.showSavedMessage) {
                $scope.expandSections(false);
                $scope.formSections.privacy.expanded = false;
            }
            setPaymentAmountDisplay($scope.item);
            setDamageAssesment();
            if ($routeParams.paymentId) {
                $scope.pageState = Constants.pageState.reliefPayment;
                $scope.selectedPayment = $routeParams.paymentId;
            }

            // Save state of fields as loaded - used to decide which fields should be editable.
            $scope.itemAsLoaded = {
                fraudFlag: $scope.item.fraudFlag,
                moreThanThreeAdultsFlag: $scope.item.moreThanThreeAdultHouseholdMembersFlag,
                multipleIncidentsOfferedFlag: $scope.item.multipleIncidentsOfferedFlag,
                lgaSpecifiedManuallyFlag: $scope.item.lgaSpecifiedManuallyFlag
            };
        });

        $scope.$on('newDataCreated', function (evt, item) {
            if (item.id <= 0) {
                $scope.expandSections(true);
                $scope.formSections.privacy.expanded = true;
            }
            setPaymentAmountDisplay(item);
        });

        function setPaymentAmountDisplay(item) {
            $scope.formSections.applicationAmounts.subMenu.paymentAmount.showNav = (item.id > 0 && !$scope.IsIneligible());
        }

        function setDamageAssesment() {
            _.forEach($scope.item.damageAssessments, function (value) {
                _.forEach($scope.damageAssessments, function (da) {
                    if (value.id == da.id)
                        da.isChecked = true;
                });
            });
        }

        $scope.newReestablishEnabled = function () {
            return $scope.item && $scope.item.id && $scope.item.incident && $scope.currentUserSecurityInfo.userHasAccessToUpdateReestablish(0, $scope.item.incident);
        };

        $scope.ReassessAsNewReliefApplicationAllowed = function (item) {
            // If we've already 'cloned' an application then we don't allow a second clone
            if (item.parentReliefId > 0) {
                return false;
            }

            // Check the application state, but ensure we've loaded the item!
            if (item.state === undefined) {
                return false;
            }

            // If user doesn't have access to the division
            if (!$scope.currentUserSecurityInfo || !$scope.currentUserSecurityInfo.userHasAccessToUpdateRelief($scope.item.id, $scope.item.incident)) {
                return false;
            }

            //if incident is closed
            if (item && item.incident && item.incident.status == 1)
                return false;

            // Check the application state
            return item.state === Constants.ReliefApplicationState.Ineligible;
        }

        $scope.AdditionalAssistanceAllowed = function (item) {
            // If we've already 'cloned' an application then we don't allow a second clone
            if (item.parentReliefId > 0) {
                return false;
            }

            // Check the application state, but ensure we've loaded the item!
            if (item.state === undefined) {
                return false;
            }

            // If user doesn't have access to the division
            if (!$scope.currentUserSecurityInfo || !$scope.currentUserSecurityInfo.userHasAccessToUpdateRelief($scope.item.id, $scope.item.incident)) {
                return false;
            }

            //if incident is closed
            if (item && item.incident && item.incident.status == 1)
                return false;

            // Check the application state
            if (item.state === Constants.ReliefApplicationState.Withdrawn ||
                item.state === Constants.ReliefApplicationState.Active ||
                item.state === Constants.ReliefApplicationState.Closed) {
                return true;
            }

            return false;
        }

        function getAgeinYearsAtEffectiveDate(dob) {
            // If the application does not yet have a creation date, then use the current time
            var relativeToDate = $scope.item.dateCreated ? moment($scope.item.dateCreated) : moment();

            var age = relativeToDate.diff(dob, 'years');

            return age;
        }

        $scope.numberOfAdults = function () {
            var n = 0;

            var age = getAgeinYearsAtEffectiveDate(moment($scope.item.applicant.dob));

            if (age >= 18) n += 1;

            if ($scope.item && $scope.item.householdMembers) {
                _.each($scope.item.householdMembers, function (m) {
                    age = getAgeinYearsAtEffectiveDate(moment(m.dob));

                    if (age >= 18) n += 1;
                });
            }

            return n;
        };

        $scope.maximumNoOfAdults = function () {
            if (!$scope.maxAdults || $scope.maxAdults == 0) {
                var id = 0;
                if ($scope.item.incidentId) {
                    id = $scope.item.incidentId;
                    bizService.getMaximumAdults(id).then(
                        function (data) {
                            $scope.maxAdults = data * 1;
                            return $scope.maxAdults;
                        });
                }
            }
            else
                return $scope.maxAdults;
        };
        $scope.maxAdults = 0;

        $scope.getApplicantNameForTitle = function () {
            if ($scope.item && $scope.item.applicant) {
                if ($scope.item.applicant.givenName && $scope.item.applicant.surname && $scope.item.applicant.givenName.trim() !== '' && $scope.item.applicant.surname.trim() !== '') {
                    return '- ' + $scope.item.applicant.givenName + ' ' + $scope.item.applicant.surname;
                }
            }
            return '';
        }
        $scope.printOrder = function (divName) {
            $scope.printElement(document.getElementById("printableArea"));
            window.print();
        }
        $scope.printElement = function (elem) {
            var domClone = elem.cloneNode(true);
            var $printSection = document.getElementById("printSection");
            if (!$printSection) {
                var $printSection = document.createElement("div");
                $printSection.id = "printSection";
                document.body.appendChild($printSection);
            }
            $printSection.innerHTML = "";
            $printSection.appendChild(domClone);
        }

        shared.reliefControllerSharedBiz.apply(this, arguments);
        // #region documents
        var maxDocumentsAllowed = 5;

        $scope.uploadController = {};

        $scope.documentSection = Constants.documentDefaultSection;

        $scope.validateDocumentLimit = function (options) {
            if ($scope.item && $scope.item.documents && options && options.files) {
                var count = 0;
                var toAdd = options.files.length;
                angular.forEach($scope.item.documents, function (v, k) {
                    if (angular.isArray(v)) {
                        count += v.length;
                    }
                });

                var noOfDocumentSelectedToUpload = toAdd - count;

                if ((count + noOfDocumentSelectedToUpload) > maxDocumentsAllowed) {
                    options.cancelled = true;
                    options.maxAllowedToAdd = maxDocumentsAllowed - count;
                }
            }
        };
        // #endregion

        $scope.isNotEligibleOrWithdrawn = function () {
            return $scope.item.id > 0 &&
                $scope.item.state !== Constants.ReliefApplicationState.Ineligible &&
                $scope.item.state !== Constants.ReliefApplicationState.Withdrawn;
        }

        $scope.hasReopenPrivilege = function () {
            return $scope.currentUserSecurityInfo.privileges.ReopenReliefApplication;
        }

        $scope.canReopenApplication = function () {
            return $scope.item && $scope.item.state === Constants.ReliefApplicationState.Closed && $scope.hasReopenPrivilege();
        }

        $scope.reopenApplication = function () {
            $scope.state.submitting = true;
            var model = {
                applicationId: $scope.item.id,
                rowVersion: $scope.item.rowVersion
            };
            bizService.reopenApplication(model).then(
                function (result) {
                    $scope.state.submitting = false;
                    utils.alert('Relief application has been re-opened successfully.');
                    route.reload();
                },
                function (result) {
                    $scope.state.submitting = false;
                }
            );
        }

        $scope.requestBankDetails = function () {
            $scope.state.submitting = true;
            var model = {
                applicationId: $scope.item.id
            };
            bizService.requestBankDetails(model).then(
                function (result) {
                    $scope.state.submitting = false;
                    utils.alert('Request for update bank details has submitted');
                    route.reload();
                },
                function (result) {
                    $scope.state.submitting = false;
                }
            );
        }

        $scope.showSave = function () {
            return $scope.item.iAccept;
        }

        $scope.clickMenu = function () {
            $scope.collapseMenu = !$scope.collapseMenu;
        }

        $scope.showPaymentTab = function () {
            if ($scope.item) {
                var result = $scope.item.id > 0 &&
                    $scope.item.state !== Constants.ReliefApplicationState.ClientDraft &&
                    $scope.item.state !== Constants.ReliefApplicationState.Submitted &&
                    $scope.item.state !== Constants.ReliefApplicationState.PendingAssessment &&
                    $scope.item.state !== Constants.ReliefApplicationState.Draft &&
                    $scope.item.state !== Constants.ReliefApplicationState.Withdrawn &&
                    $scope.item.entitlementAmount;

                $scope.formSections.paymentDetails.showNav = result;

                return result;
            }
            return false;
        }

        $scope.showPaymentAdjustmentsTab = function () {
            return $scope.showPaymentTab() &&
                $scope.currentUserSecurityInfo.privileges.ViewAdditionalFundsToReliefPayment;
        }

        $scope.isAuthorisedToAdjustPayment = function () {
            return $scope.currentUserSecurityInfo.privileges.AddAdditionalFundsToReliefPayment === true || false;
        }

        $scope.clickApplicationTab = function (pageState) {
            $scope.pageState = pageState;
            utils.scrollToTop(pageState.id);
            if (pageState.id == "#adjustmentDetails")
                $scope.$broadcast('load-adjustments', { loadData: true });
        }

        $scope.showPaymentAmounts = function () {
            var result = $scope.item.id > 0 && $scope.item.state !== Constants.ReliefApplicationState.Ineligible;
            $scope.formSections.applicationAmounts.showNav = result;
            return result;
        }

        $scope.$watch('showHouseholderMembers', function (newValue) {
            if ($scope.formSections && newValue) {
                $scope.formSections.householdMembers.showNav = newValue;
            }
        });

        $scope.removeMenu = function () {
            if ($scope.frm.dirty == false) {
                navigationService.subMenus.reliefMenu.menu = {};
                notificationService.notify('showMenu', {
                    pageCode: navigationService.pageCodes.reliefApplications,
                    showMenu: false
                });
            }
        }
    }
}());