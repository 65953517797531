(function () {

    var app = angular.module('phapApp');

    app.component('configurationValueGrid', {
        templateUrl: 'app/administration/configuration/configurationValueGrid.html',
        bindings: {
            gridOptions: '=',
            originalDataSet: '=',
            saveCallback: '=',
            validator: '=' //this method should return {isValid:true/false,errors:['error message']}
        },
        controller: ['$scope', '$filter', 'uiGridConstants', 'utils', 'Constants', function ($scope, $filter, uiGridConstants, utils, Constants) {

            var vm = this;
            vm.pageErrors = [];
            vm.showSavedMessage = false;
            vm.submitting = false;
            vm.dateOptions = {
                minDate: new Date().setDate(new Date().getDate() + 1),
                dateFormat: Constants.DatePickerFormat
            };
            vm.refreshAfterSave = function (result) {
                if (result.status == 200) {
                    vm.showSavedMessage = true;
                    vm.submitting = false;
                    if ($scope.admin) {
                        $scope.admin.$setPristine();
                    }
                } else {
                    //Add Errors
                    vm.submitting = false;
                    vm.pageErrors = [];
                    if (result.data.errors.length > 0) {
                        var errorList = [];
                        for (var i = 0; i < result.data.errors.length; i++) {
                            errorList.push(result.data.errors[i].message);
                        }
                        vm.pageErrors = vm.pageErrors.concat(errorList);
                    }
                }
            };

            vm.update = function () {
                var result = vm.validator(vm.gridOptions.futurePeriod);
                if (result.isValid) {
                    var title = 'Warning';
                    var content = "Saving will commit all changes made on this page";
                    utils.confirm(title, content, 'Proceed', 'Cancel')
                        .then(function () {
                            vm.submitting = true;
                            vm.pageErrors = [];
                            vm.gridOptions.futurePeriod.effectiveFromDate = vm.gridOptions.futurePeriod.effectiveFromDate.getFullYear() + '-' + (vm.gridOptions.futurePeriod.effectiveFromDate.getMonth() + 1) + '-' + vm.gridOptions.futurePeriod.effectiveFromDate.getDate();
                            vm.gridOptions.periods.forEach(function (period) {
                                period.value = utils.parseDollarToNumber(period.value);
                            })
                            vm.saveCallback(vm.gridOptions.futurePeriod, vm.refreshAfterSave);
                        },
                        function () {
                            return;
                        });
                }
                else {
                    vm.pageErrors.push(result.error);
                }
            };
            vm.removeMessages = function () {
                vm.showSavedMessage = false;
            };

            vm.disabledUpdate = function () {
                return vm.pageErrors.length !== 0;
            }

            vm.calculateEndDate = function (effectiveToDate) {
                var endDate = "";
                if (effectiveToDate == null)
                    endDate = " onwards";
                else
                    endDate = " to " + $filter('date')(effectiveToDate, Constants.DatePickerFormat);
                return endDate
            };
            
        }],
        controllerAs: 'configurationValueGridVm'
    });

})();