(function () {
    var app = angular.module('phapApp');
    app.service('onlineApplicationService', ['$http', '$q', 'config', 'security', 'Constants', 'notificationService', '$rootScope', 'utils',
        function ($http, $q, config) {
            var usersForTaskAssignmentDeferred = null;

            var getUsersForTaskAssignment = function () {
                if (usersForTaskAssignmentDeferred == null) {

                    usersForTaskAssignmentDeferred = $q.defer();

                    $http.get(config.apiBaseUrl + "online-applications/user-info").then(
                        function (result) {
                            // The server has returned a list of users, with permission & division info. Now, build the lists we need per-division

                            // First, set name (built out of givenName and surname)
                            _.each(result.data, function (u) { u.name = u.givenName + ' ' + u.surname; });

                            // Put the user list in order
                            result.data =
                                _.sortBy(
                                    result.data,
                                    function (x) { return x.givenName + ' ' + x.surname; }
                                );

                            // Now, build lists of users - one master list for use with incidents that don't have divisions, and
                            // one list filtered to each division
                            var userLists = {
                                withoutUnassign: {
                                    noDivision: _.filter(result.data, function (u) { return u.hasUpdateReliefInAnyDivisionPrivilege }),
                                    byDivision: {}
                                },
                                withUnassign: {
                                    noDivision: [],
                                    byDivision: {}
                                }
                            };

                            var MaxDivisionId = 5;

                            for (var i = 1; i <= MaxDivisionId; i++) {
                                userLists.withoutUnassign.byDivision[i] =
                                    _.filter(
                                        result.data,
                                        function (u) {
                                            return u.hasUpdateReliefInAnyDivisionPrivilege ||
                                                   _.indexOf(u.divisionIds, i) != -1;
                                        });

                                userLists.withUnassign.byDivision[i] = _.clone(userLists.withoutUnassign.byDivision[i]);

                                userLists.withUnassign.byDivision[i].splice(0, 0, { id: null, name: '[Unassign]' });
                            }

                            usersForTaskAssignmentDeferred.resolve(userLists);
                        },
                        function () {
                            usersForTaskAssignmentDeferred.reject();
                        }
                    );
                }

                return usersForTaskAssignmentDeferred.promise;
            };

            var getUnassignedOnlineApplications = function (pageNumber, pageSize) {
                var deferred = $q.defer();

                $http.get(config.apiBaseUrl + 'online-applications/unassigned?pageNumber=' + pageNumber + '&pageSize=' + pageSize)
                    .then(
                        function (response) {
                            deferred.resolve(response.data);
                        },
                        function (data) {
                            deferred.reject(data);
                        }
                    );

                return deferred.promise;
            };

            var getMyOnlineApplications = function (pageNumber, pageSize) {
                var deferred = $q.defer();

                $http.get(config.apiBaseUrl + 'online-applications/mine?pageNumber=' + pageNumber + '&pageSize=' + pageSize)
                    .then(
                        function (response) {
                            deferred.resolve(response.data);
                        },
                        function (data) {
                            deferred.reject(data);
                        }
                    );

                return deferred.promise;
            };

            var reassign = function (callbackRequestId, newAssigneeId) {
                var deferred = $q.defer();

                $http.post(config.apiBaseUrl + 'online-applications/' + callbackRequestId + '/reassign', { targetUserId: newAssigneeId })
                    .then(function (data) {
                        deferred.resolve(data);
                    }, function (data) {
                        deferred.reject(data);
                    });

                return deferred.promise;
            }

            return {
                getMyOnlineApplications: getMyOnlineApplications,
                getUnassignedOnlineApplications: getUnassignedOnlineApplications,
                getUsersForTaskAssignment: getUsersForTaskAssignment,
                reassign: reassign
            }
        }]);
})();