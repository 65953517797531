(function () {

    var app = angular.module('phapApp');
    app.component('taskWidget', {
        templateUrl: 'app/home/dashboard/taskWidget.html',
        bindings: {
            title: '@',
            tasks: '<',
            count: '<',
            heading: '='
        },
        transclude: true,
        controller: ['$rootScope', function ($rootScope) {
            var vm = this;
            vm.isOpen = true;
            vm.getCount = function () {
                // If the parent has passed in an override for the "total record count" (i.e. we're
                // using pagination), then use that
                if (vm.count) return vm.count;

                // Otherwise, if we have a list of tasks, use its length
                if (vm.tasks) return vm.tasks.length;

                // Otherwise, we don't know how many tasks fit in this category
                return null;
            };
            vm.close = function () {
                vm.isOpen = !vm.isOpen;
            };

        }],
        controllerAs: 'taskWidget'
    });

})();