(function () {
    angular.module('phapApp')
        .directive('householdMember', ['$window', function ($window) {
            return {
                restrict: 'E',
                templateUrl: 'app/home/relief/_householdMember.html',
                controller: ['$scope', 'lgaService', '$uibModal', 'utils', '$q', controller]
            };
        }]);

    function controller($scope, lgaService, $uibModal, utils, $q) {

        $scope.removehouseholdMember = function (index) {

            if (confirm('Are you sure you want to remove this household member ?')) {
                if ($scope.item && $scope.item.householdMembers) {
                    $scope.item.householdMembers.splice(index, 1);
                }
            }
        };

        $scope.newHouseHolder = function () {
            if ($scope.item) {
                if (!$scope.item.householdMembers) {
                    $scope.item.householdMembers = [];
                }
                var applicantEmail = angular.copy($scope.item.applicant.contactDetails.email);
                $scope.item.householdMembers.push({ contactDetails: { preferredContactMethod: null, email: applicantEmail }, weeklyIncome:'' });
            }
        };
    }

})();