(function () {
	var app = angular.module('phapApp');

	app.component('cardReceive', {
		templateUrl: 'app/financeManagement/cards/cardReceive.html',
		bindings: {
			type: '@'
		},
		controller: ['cardBulkUploadService', 'cardShipmentService', 'uiGridConstants', '$routeParams', '$location', 'security', 'Constants', 'utils', 'navigationService', '$q', '$filter', 'notificationService', '$interval',
			function (cardBulkUploadService, cardShipmentService, uiGridConstants, $routeParams, $location, security, constants, utils, navigationService, $q, $filter, notificationService, $interval) {

				var getCardColumnDefs = function () {
					return [
						{
							field: 'id',
							visible: false
						},
						{
							field: 'cardId',
							displayName: 'Card ID',
							type: 'number',
							enableHiding: false,
							enableColumnMenu: false,
							width: '50%'
						},
						{
							field: 'serialNumber',
							displayName: 'Serial number',
							type: 'number',
							enableHiding: false,
							enableColumnMenu: false,							
							width: '50%'

						}
					]
				};

				var getSelectedCardGridOptions = function (allowSelectAll) {
					return {
						enableSorting: true,
						enableFiltering: false,
						enableHorizontalScrollbar: uiGridConstants.scrollbars.ALWAYS,
						enableVerticalScrollbar: uiGridConstants.scrollbars.ALWAYS,
						enableRowSelection: true,
						enableFullRowSelection: true,
						enableRowHeaderSelection: true,
						enableSelectAll: allowSelectAll,
						multiSelect: true,
						columnDefs: getCardColumnDefs(),
						appScopeProvider: {
							disabledGrid: false,
							selectRow: function (row) {
								selectRow(row);
							}
						},
						rowTemplate:
						'<div ng-click="grid.appScope.selectRow(row, $event, 1)"> ' +
						'<div class="ui-grid-cell" ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" ui-grid-cell ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader, \'cursor-default\': grid.appScope.disabledGrid, \'cursor-pointer\': !grid.appScope.disabledGrid }"> ' +
						'</div>' +
						'</div>',
						onRegisterApi: function (gridApi) {
							vm.selectedGridApi = gridApi;
						}
					}
				};

				var getReceivedCardGridOptions = function () {
					return {
						enableSorting: true,
						enableFiltering: false,
						enableHorizontalScrollbar: uiGridConstants.scrollbars.ALWAYS,
						enableVerticalScrollbar: uiGridConstants.scrollbars.ALWAYS,
						enableRowSelection: false,
						enableFullRowSelection: false,
						enableRowHeaderSelection: false,
						columnDefs: getCardColumnDefs(),
						appScopeProvider: {
							disabledGrid: true,
							selectRow: function (row) {
								selectRow(row);
							}
						},
						rowTemplate:
						'<div class="ui-grid-cell" ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" ui-grid-cell ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader, \'cursor-default\': grid.appScope.disabledGrid, \'cursor-pointer\': !grid.appScope.disabledGrid }"> ' +
						'</div>',
						onRegisterApi: function (gridApi) {
							vm.receivedGridApi = gridApi;
							// call resize every 500 ms for 5 s after modal finishes opening - usually only necessary on a bootstrap modal
							$interval(function () {
								vm.gridApi.core.handleWindowResize();
							}, 500, 10);
						}
					}
				};

				var selectRow = function (row) {
					if (vm.selectedCardGridOptions.enableRowSelection) {
						vm.form.$setDirty();
					}
				}

				var mapCards = function () {
					var cards = [];
					_.each(vm.selectedGridApi.grid.rows, function (row) {
						cards.push({ selected: angular.isDefined(row.isSelected) ? row.isSelected : false, id: row.entity.id, rowVersion: row.entity.rowVersion });
					})

					return {
						id: vm.type == constants.cardReceiveType.batch ? vm.batch.id : vm.order.id,
						rowVersion: vm.type == constants.cardReceiveType.batch ? vm.batch.rowVersion : vm.order.rowVersion,
						cards: cards
					}
				}

				var mapSelectedCards = function () {
					var cards = [];
					_.each(vm.selectedGridApi.selection.getSelectedRows(), function (row) {
						cards.push({ selected: true, id: row.id, rowVersion: row.rowVersion });
					})

					return {
						id: vm.type == constants.cardReceiveType.batch ? vm.batch.id : vm.order.id,
						rowVersion: vm.type == constants.cardReceiveType.batch ? vm.batch.rowVersion : vm.order.rowVersion,
						cards: cards
					}
				}

				var disableGridSelection = function () {
					vm.selectedGridApi.grid.appScope.disabledGrid = true;
					vm.selectedCardGridOptions.enableSelectAll = false;
					vm.selectedCardGridOptions.enableFullRowSelection = false;
					vm.selectedCardGridOptions.noUnselect = true;
					vm.selectedCardGridOptions.isRowSelectable = function (row) { return false; };
					vm.selectedGridApi.core.notifyDataChange(uiGridConstants.dataChange.ALL);
				}

				var reconcileBatch = function () {
					vm.isReconciling = true;
					notificationService.notify('showLoadingPanel', true);
					cardBulkUploadService.reconcileCardsInBatch(mapCards()).then(
						function (data) {
							vm.form.$setPristine();
							notificationService.notify('showLoadingPanel', false);
							vm.isReconciling = false;
							vm.isReconciled = true;
							vm.isSaved = false;

							$location.path("/financeManagement/cards/cardBulkUpload");
						},
						function (data) {
							notificationService.notify('showLoadingPanel', false);
							vm.isReconciling = false;
							vm.isReconciled = false;
							vm.isSaved = false;
						}
					);
				}

				var getSerialNumberAddOutcome = function (addCount) {
					if (addCount == 0) {
						return { title: 'Serial numbers already confirmed', content: 'Serial numbers within the entered range have already been confirmed on the receive list.' };
					}
					else if (addCount == 1) {
						return { title: 'Serial number confirmed', content: '1 serial number was confirmed on the receive list.' };
					}
					else {
						return { title: 'Serial numbers confirmed', content: addCount + ' serial numbers were confirmed on the receive list.' };
					}
				}

				var vm = this;

				vm.constants = constants;

				vm.$onInit = function () {

					if ($location.path().indexOf("financeManagement") > -1) {
						navigationService.setCurrentMenuStatus(
							navigationService.topMenuCodes.financeManagement,
							navigationService.pageCodes.cardShipment2);
					} else {
						navigationService.setCurrentMenuStatus(
							navigationService.topMenuCodes.emergencyManagement,
							navigationService.pageCodes.cardShipment);
					}

					vm.originalGridData = [];
					vm.allPromises = [];
					vm.currentUserSecurityInfo = null;
					vm.hasAccess = false;
					vm.canUpdate = false;
					vm.loaded = false;

					vm.batch = null;
					vm.order = null;

					vm.isReconciled = false;
					vm.isReconciling = false;
					vm.isSaving = false;
					vm.isSaved = false;
					vm.isMismatchConfirmed = false;

					vm.selectedCardGridOptions = getSelectedCardGridOptions(vm.type == constants.cardReceiveType.order);

					if (vm.type == constants.cardReceiveType.batch) {
						vm.receivedCardGridOptions = getReceivedCardGridOptions();
					}

					vm.allPromises.push(security.getCurrentUserSecurityInfo().then(
						function (result) {
							vm.currentUserSecurityInfo = result;
							vm.hasAccess =
								(vm.type == constants.cardReceiveType.batch && vm.currentUserSecurityInfo.privileges.ReceiveCardsForBatch) ||
								(vm.type == constants.cardReceiveType.order && vm.currentUserSecurityInfo.privileges.ReceiveCardsForOrder);
						}));

					$q.all(vm.allPromises).then(
						function (result) {
							if ($routeParams.id && vm.hasAccess) {
								if (vm.type == constants.cardReceiveType.batch) {
									cardBulkUploadService.getBatchWithCards($routeParams.id).then(
										function (data) {
											vm.batch = data;
											vm.selectedCardGridOptions.data = data.notReceivedCards;
											vm.originalGridData = data.notReceivedCards;
											vm.receivedCardGridOptions.data = data.receivedCards;
											vm.canUpdate = vm.batch.batchStatusDisplay == constants.cardBatchStatus.loaded;
											vm.loaded = true;
										}
									);
								}
								else if (vm.type == constants.cardReceiveType.order) {
									cardShipmentService.getCardShipmentWithCardsForReconcile($routeParams.id).then(
										function (data) {
											vm.order = data;
											vm.order.orderStatusText = _.find(constants.cardShippingStatusNames, { id: vm.order.orderStatus }).name;
											vm.selectedCardGridOptions.data = data.cards;
											vm.originalGridData = vm.selectedCardGridOptions.data;
											vm.canUpdate = vm.order.orderStatus == constants.cardShippingStatus.inTransit;
											vm.loaded = true;
										}
									);
								}
								else {
									vm.loaded = true;
								}
							}
							else {
								vm.loaded = true;
							}
						}
					);
				};

				vm.addCard = function () {
					var messages = [];

					if (vm.details.cardToAdd && vm.details.cardToAdd.length > 0) {
						if (_.find(vm.selectedGridApi.selection.getSelectedRows(), function (c) { return c.cardId == vm.details.cardToAdd; }) ||
							(vm.type == constants.cardReceiveType.batch && _.find(vm.receivedCardGridOptions.data, function (c) { return c.cardId == vm.details.cardToAdd; }))) {
							messages.push({ title: 'Card ID already confirmed', content: 'Card ID has already been confirmed on the receive list.' });
						}
						else {
							var existMatch = _.find(vm.selectedCardGridOptions.data, function (c) { return c.cardId == vm.details.cardToAdd; });
							if (existMatch) {
								vm.selectedGridApi.grid.modifyRows(vm.selectedCardGridOptions.data);
								vm.selectedGridApi.selection.selectRow(existMatch);
								vm.details.cardToAdd = null;
							}
							else {
								messages.push({ title: 'Card ID not found', content: 'Card ID does not exist on the receive list.' });
							}
						}
					}
					if (vm.details.serialNumberFrom && vm.details.serialNumberFrom.length > 0) {
						var receivedCards = vm.receivedCardGridOptions ? vm.receivedCardGridOptions.data : [];
						var toAdd = $filter('rangeSearch')(vm.details.serialNumberFrom, vm.details.serialNumberTo, vm.selectedCardGridOptions.data.concat(receivedCards));
						if (toAdd.length > 0) {
							var alreadyAdded = $filter('rangeSearch')(vm.details.serialNumberFrom, vm.details.serialNumberTo, vm.selectedGridApi.selection.getSelectedRows().concat(receivedCards));

							vm.selectedGridApi.grid.modifyRows(vm.selectedCardGridOptions.data);
							_.each(toAdd, function (m) { vm.selectedGridApi.selection.selectRow(m); });

							messages.push(getSerialNumberAddOutcome(toAdd.length - alreadyAdded.length));

							if (toAdd.length - alreadyAdded.length > 0) {
								vm.details.serialNumberTo = '';
								vm.details.serialNumberFrom = '';
							}
						}
						else {
							messages.push({ title: 'Serial numbers not found', content: 'No matching serial number/s exist on the receive list.' });
						}
					}

					// Invoke messages in reverse order so they display in the correct order (oldest message first)
					if (messages.length > 0) {
						_.each(messages.reverse(), function (m) { utils.alert(m.content, m.title); });
					}
				};

				vm.reconcile = function () {
					var selectedCards = vm.selectedGridApi.selection.getSelectedRows();

					if (vm.type == constants.cardReceiveType.batch) {
						if (selectedCards.length == vm.selectedCardGridOptions.data.length) {
							reconcileBatch();
						}
						else {
							utils.confirm('Please acknowledge mismatch', 'There is a mismatch between the number of cards received and the number of cards in the batch. Please acknowledge the mismatch by clicking \'Ok\' or click \'Cancel\' to return.', 'Ok', 'Cancel').then(
								function () {
									reconcileBatch();
								}
							)
						}
					}
					else {
						if (vm.isMismatchConfirmed || selectedCards.length == vm.selectedCardGridOptions.data.length) {
							vm.isReconciling = true;
							notificationService.notify('showLoadingPanel', true);
							cardShipmentService.reconcileCardsInShipment(mapCards()).then(
								function (data) {
									notificationService.notify('showLoadingPanel', false);
									disableGridSelection();

									vm.order = data;
									vm.order.orderStatusText = _.find(constants.cardShippingStatusNames, { id: vm.order.orderStatus }).name;

									vm.isReconciling = false;
									vm.isReconciled = true;

									vm.form.$setPristine();
								},
								function (data) {
									notificationService.notify('showLoadingPanel', false);
									vm.isReconciling = false;
									vm.isReconciled = false;
								}
							);
						}
						else {
							utils.alert('Please acknowledge mismatch of cards received by clicking the checkbox.', 'Please acknowledge mismatch');
						}
					}

				}

				vm.save = function () {
					vm.isSaving = true;
					cardBulkUploadService.saveForReconcileCardsInBatch(mapSelectedCards()).then(
						function (data) {
							vm.form.$setPristine();
							vm.selectedGridApi.selection.clearSelectedRows();

							vm.isSaving = false;
							vm.isSaved = true;
							vm.batch = data;
							vm.selectedCardGridOptions.data = data.notReceivedCards;
							vm.originalGridData = vm.selectedCardGridOptions.data;
							vm.receivedCardGridOptions.data = data.receivedCards;
						},
						function (data) {
							vm.isSaving = false;
						}
					);
				}

				vm.assignLocation = function () {
					$location.path('/financeManagement/cards/search/' + vm.order.orderNumber + '/' + constants.cardStatus.received);
				}

				vm.cancel = function () {
					if (vm.type == constants.cardReceiveType.batch) {
						$location.path('/financeManagement/cards/cardBulkUpload');
					}
					else {
						$location.path('/financeManagement/cardShipmentOrder/edit/' + vm.order.id + '/');
					}
				};
			}],
		controllerAs: 'cr'
	});

})();