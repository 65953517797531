(function () {
	var app = angular.module('phapApp');
	app.component('devCardUpload', {
		templateUrl: 'app/financeManagement/cards/devCardUpload.html',
		bindings: {
			mode: '@'
		},
        controller: ['$scope', 'cardBulkUploadService', '$routeParams', '$location', 'Constants', 'utils', 'FileUploader', 'security', 'uiGridConstants','navigationService', function ($scope, cardBulkUploadService, $routeParams, $location, constants, utils, FileUploader, security, uiGridConstants, navigationService) {
            var vm = this;
            var isRunningInExtraSmallScreen = $(document).width() < 768;

			$scope.fileUploader = createFileUploaderForAttachment();

            vm.$onInit = function () {
                vm.loaded = false;
                vm.errorMessage = null;
                vm.successMessage = null;
                vm.selectedBatch = null;
                vm.constants = constants;
                vm.currentUserSecurityInfo = null;

                // Redirect out if not localhost.
                if (window.location.hostname !== 'localhost' && window.location.hostname !== '127.0.0.1')
                {
                    $location.path("/financeManagement/cards/search");
                }
                else {
                    security.getCurrentUserSecurityInfo()
                        .then(function (result) {
                            vm.currentUserSecurityInfo = result;
                            vm.loaded = true;
                        },
                        function () {
                            vm.errorMessage = 'Could not retrieve user security information.';
                        });
                }
			};

			function createFileUploaderForAttachment() {
				var fileUploader = $scope.uploader = new FileUploader({});

				fileUploader.onAfterAddingFile = function (fileItem) {
					var userId;
					vm.successMessage = null;
					security.getActiveUser(false).then(
						function (data) {
							userId = data.id;
							fileItem.headers = {
								userId: userId
							};
							if (fileItem && fileItem.file.size > 0) {
								if (checkIfFileCanBeAttached(fileItem)) {
									cardBulkUploadService.uploadNewCards(fileItem);
								}
							}
							else {
								document.getElementById('file-input').value = "";
								vm.errorMessage = "The file cannot be processed: an empty file has been selected.";
							}
						}
					);
				}
				fileUploader.onErrorItem = function (fileItem, response, status, headers) {
					document.getElementById('file-input').value = "";
					vm.errorMessage = "The file could not be uploaded, please try again.";
				};
				fileUploader.onSuccessItem = function (fileItem, response, status, headers) {
					vm.errorMessage = null;
                    vm.successMessage = "File upload successful";
                    $location.path("/financeManagement/cards/cardBulkUpload");
				};
				return fileUploader;
			}

			var checkIfFileCanBeAttached = function (fileItem) {
				if (isValidExtensions(fileItem.file.type, fileItem.file.name) == false) {
					document.getElementById('file-input').value = "";
					vm.errorMessage = "The file cannot be processed: the file format is incorrect";
					return false;
				}
				return true;
			}

			var isValidExtensions = function (fileType, fileName) {
				switch (fileType) {
					case 'text/plain':
					case 'application/vnd.ms-excel':
						return true;
						break;
					default:
						return false;
				}
				return false;
            }
		}],
		controllerAs: 'dcu'
	});

})();