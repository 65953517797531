(function () {
    var app = angular.module('phapApp');

    app.factory('authenticationService', ['$injector', '$http', '$q', 'config', function ($injector, $http, $q, config) {
        var impl;

        // If the adalAuthenticationService is available, use it, otherwise, provide a dummy implementation
        try {
            impl = $injector.get('adalAuthenticationService');
        }
        catch (e) {
            impl = {
                logOut: angular.noop
            };
        }

        return {
            logOut: impl.logOut
        };
    }]);
})();