
//    phapcurrency used to filter the currency data if value is null or 0 then this will asign empty otherwise this will apply angular currency filter
(function () {
    angular.module('phapApp')
        .filter('phapcurrency', ['$filter', function ($filter) {
            return function (amount) {
                if (amount === '' || amount === null)
                    return '';
                else
                {
                    return $filter('currency')(amount);
                }
            };
        }])
        .filter('phapcurrencycent', ['$filter', function ($filter) {
            return function (amount) {
                if (amount === '' || amount === null)
                    return '';
                else {
                    return $filter('currency')(amount/100);
                }
            };
        }]);
})();