(function () {

    var app = angular.module('phapApp');

    app.component('securityList', {
        templateUrl: 'app/administration/security/securityList.html',
        bindings: {
            mode: '@' //create/edit
        },
        controller: ['$scope', '$routeParams', '$location', 'Constants', 'configurationService', 'notificationService', '$timeout', 'navigationService',
            function ($scope, $routeParams, $location, Constants, configurationService, notificationService, $timeout, navigationService) {

                var vm = this;
                vm.collapseMenu = false;
                vm.currentSection = $routeParams.name;

                navigationService.setCurrentMenuStatus(
                    navigationService.topMenuCodes.administration,
                    navigationService.pageCodes.userRoleManagement,
                    vm.currentSection);


                vm.menuIds = Constants.userRoleMenuId;

            }],
        controllerAs: 'securityListVm'
    });

})();