(function () {
	var app = angular.module('phapApp');

	app.component('cardStocktake', {
		templateUrl: 'app/financeManagement/cards/cardStocktake.html',
		bindings: {
			mode: '@'
		},
		controller: ['uiGridConstants', '$routeParams', '$location', '$filter', 'security', 'Constants', 'utils', 'cardService', 'navigationService', '$q', 'notificationService', '$cookies', '$interval',
			function (uiGridConstants, $routeParams, $location, $filter, security, constants, utils, cardService, navigationService, $q, notificationService, $cookies, $interval) {

				navigationService.setCurrentMenuStatus(navigationService.topMenuCodes.financeManagement, navigationService.pageCodes.cardStocktake);

				var vm = this;
				vm.constants = constants;

				vm.$onInit = function () {

					vm.constants = constants;
					vm.allPromises = [];
					vm.currentUserSecurityInfo = null;
					vm.hasAccess = false;
					vm.loaded = false;
					vm.searched = false;

					vm.isReadOnly = false;
					vm.isReconciled = false;
					vm.isReconciling = false;
					vm.isMismatchConfirmed = false;

					vm.gridOptions = getGridOptions();
					vm.gridOptions.data = [];
					vm.stocktake = null;
					vm.search = {};
					vm.refdata = { locations: null };

					vm.allPromises.push(security.getCurrentUserSecurityInfo().then(
						function (result) {
							vm.currentUserSecurityInfo = result;
							vm.hasAccess = vm.currentUserSecurityInfo.privileges.CardStocktake;
						})
					);
					vm.allPromises.push(
						security.getLocationsForCurrentUser().then(
							function (data) {
								vm.refdata.locations = data.locations;
							},
							function () {
								vm.refdata.locations = {};
							}
						)
					);
					$q.all(vm.allPromises).then(
						function (result) {
							if (vm.hasAccess) {
								if (vm.mode === constants.mode.create) {
									vm.search.stocktakeDate = moment().format('DD-MMM-YYYY');
									vm.loaded = true;
								}
								else {
									cardService.getStocktakeDetails($routeParams.id).then(
										function (data) {
											populateStocktake(data);
											vm.searched = true;
											vm.loaded = true;
										}
									);
								}
							}
							else {
								vm.loaded = true;
							}
						}
					);
				};

				vm.getCardsForStocktake = function () {
					vm.searching = true;
					cardService.getCardsForNewStocktake(vm.search.location.id).then(
						function (result) {
							if (result.id > 0) {
								$location.path('/financeManagement/cards/stocktake/view/' + result.id);
								utils.alert('Card stocktake for ' + vm.search.location.name + ' has already been completed today.', 'Stocktake already exists', 'OK');
							}
							else {
								vm.gridOptions.data = result.cards;
							}
							vm.searched = true;
							vm.searching = false;
							vm.form.$setPristine();
						},
						function (result) {
							vm.searching = false;
						}
					);
				}

				vm.addCard = function () {
					var messages = [];

					if (vm.details && vm.details.cardToAdd && vm.details.cardToAdd.length > 10) {
						if (_.find(vm.gridApi.selection.getSelectedRows(), function (c) { return c.cardId == vm.details.cardToAdd; })) {
							messages.push({ title: 'Card ID already confirmed', content: 'Card ID has already been confirmed on the stocktake list.' });
						}
						else {
							var existMatch = _.find(vm.gridOptions.data, function (c) { return c.cardId == vm.details.cardToAdd; });
							if (existMatch) {
								vm.gridApi.grid.modifyRows(vm.gridOptions.data);
								vm.gridApi.selection.selectRow(existMatch);
								vm.details.cardToAdd = null;
							}
							else {
								messages.push({ title: 'Card ID not found', content: 'Card ID does not exist on the stocktake list.' });
							}
						}
					}

					// Serial Number Add
					if (vm.details.serialNumberFrom && vm.details.serialNumberFrom.length > 0) {
						var toAdd = $filter('rangeSearch')(vm.details.serialNumberFrom, vm.details.serialNumberTo, vm.gridOptions.data);
						if (toAdd.length > 0) {
							var alreadyAdded = $filter('rangeSearch')(vm.details.serialNumberFrom, vm.details.serialNumberTo, vm.gridApi.selection.getSelectedRows());

							vm.gridApi.grid.modifyRows(vm.gridOptions.data);
							_.each(toAdd, function (m) { vm.gridApi.selection.selectRow(m); });

							messages.push(getSerialNumberAddOutcome(toAdd.length - alreadyAdded.length));

							if (toAdd.length - alreadyAdded.length > 0) {
								vm.details.serialNumberTo = '';
								vm.details.serialNumberFrom = '';
							}
						}
						else {
							messages.push({ title: 'Serial numbers not found', content: 'No matching serial number/s exist on the receive list.' });
						}
					}

					// Invoke messages in reverse order so they display in the correct order (oldest message first)
					if (messages.length > 0) {
						_.each(messages.reverse(), function (m) { utils.alert(m.content, m.title); });
					}
				};

				vm.rejectStocktake = function () {
					if (vm.rejectionReason) {
						notificationService.notify('showLoadingPanel', true);
						var stockTakeModel = {
							id: parseInt(vm.stocktake.id),
							rowVersion: vm.stocktake.rowVersion,
							rejectionReason: vm.rejectionReason
						}
						cardService.rejectReconcileStocktake(stockTakeModel).then(
							function (result) {
								vm.form.$setPristine();
								notificationService.notify('showLoadingPanel', false);
								utils.alert('Card stocktake has been rejected.', 'Success', 'OK');
								$location.path('/financeManagement/cards/stocktakeSearch');
							},
							function (result) {
							}
						);
					}
					else {
						utils.alert('Please enter a rejection reason.', 'Missing reason', 'OK');
					}
				}

				vm.approveStocktake = function () {
					if (!vm.rejectionReason) {
						notificationService.notify('showLoadingPanel', true);
						vm.isReconciling = true;
						var stockTakeModel = {
							id: parseInt(vm.stocktake.id),
							rowVersion: vm.stocktake.rowVersion
						}
						cardService.approveReconcileStocktake(stockTakeModel).then(
							function (result) {
								vm.form.$setPristine();
								notificationService.notify('showLoadingPanel', false);
								utils.alert('Card stocktake approved successfully.', 'Success', 'OK');
								$location.path('/financeManagement/cards/stocktakeSearch');
							},
							function (result) {
							}
						);
					}
					else {
						utils.alert('Rejection reason is not applicable for approvals. The rejection reason will not be saved.', 'Success', 'OK');
						vm.rejectionReason = null;
					}
				}
				vm.reconcileStocktake = function () {
					if ((vm.gridApi.selection.getSelectedCount() < vm.gridOptions.data.length) &&
						(!vm.isMismatchConfirmed || !vm.notes || vm.notes.trim().length == 0)) {
						utils.alert('Please acknowledge mismatch between in stock and physical cards by clicking the checkbox and entering details in the notes field.', 'Mismatch not acknowledged', 'OK');
					}
					else {
						vm.isReconciling = true;
						cardService.reconcileStocktake(getReconcileData()).then(
							function (result) {
								vm.form.$setPristine();
								vm.isReconciling = false;
								vm.isReconciled = true;
								utils.alert('Card stocktake created successfully.', 'Success', 'OK');
								$location.path('/financeManagement/cards/stocktakeSearch');
							},
							function (result) {
								vm.isReconciling = false;
								vm.isReconciled = false;
							}
						);
					}
				}

				vm.cancel = function () {
					$location.path('/financeManagement/cards/stocktakeSearch');
				};
				vm.hasApprovalAccess = function () {
					if (vm.currentUserSecurityInfo.privileges.ApproveCardStocktake) {
						if (vm.stocktake && (vm.stocktake.status == constants.cardStocktakeStatus.PendingApproval)) {
							var locationMatchFound = _.find(vm.refdata.locations, function (l) {
								return l.id == vm.stocktake.locationId;
							});
							if (locationMatchFound) {
								if ($cookies.get("UserId") && vm.stocktake.createdById !== parseInt($cookies.get("UserId"))) {
									return true;
								}
							}
						}
					}
					return false;
				}

				var populateStocktake = function (data) {
					vm.stocktake = data;
					vm.search.stocktakeNumber = vm.stocktake.cardStocktakeNumber;
					vm.search.stocktakeDate = vm.stocktake.stocktakeDate;
					vm.search.stocktakeApprovalDate = vm.stocktake.approvalDate;
					vm.search.stocktakeRejectionDate = vm.stocktake.rejectionDate;
					vm.search.location = _.find(vm.refdata.locations, function (l) {
						return l.id == vm.stocktake.locationId;
					});
					vm.search.createdBy = vm.stocktake.createdByEmail;
					vm.search.approvedBy = vm.stocktake.approvedByEmail;
					vm.search.rejectedBy = vm.stocktake.rejectedByEmail;
					vm.notes = vm.stocktake.notes;

					vm.gridOptions.data = vm.stocktake.cards;
					vm.isReadOnly = true;
				}

				var getCardColumnDefs = function () {
					return [
						{
							field: 'id',
							name: 'id',
							visible: false
						},
						{
							field: 'cscId',
							name: 'cardStocktakeCardId',
							visible: false
						},
						{
							field: 'cardId',
							displayName: 'Card ID',
							type: 'number',
							enableHiding: false,
							enableColumnMenu: false,
							width: '33%'
						},
						{
							field: 'serialNumber',
							displayName: 'Serial number',
							type: 'number',
							enableHiding: false,
							enableColumnMenu: false,
							width: '33%'
						},
						{
						    field: 'cardType',
						    displayName: 'CardType',
						    enableHiding: false,
						    enableColumnMenu: false,
						    width: '33%'
						}
					]
	};

	var getGridOptions = function () {
		return {
			enableSorting: true,
			enableFiltering: false,
			enableHorizontalScrollbar: uiGridConstants.scrollbars.ALWAYS,
			enableVerticalScrollbar: uiGridConstants.scrollbars.ALWAYS,
			enableRowSelection: true,
			enableFullRowSelection: true,
			enableRowHeaderSelection: true,
			enableSelectAll: true,
			multiSelect: true,
			columnDefs: getCardColumnDefs(),
			appScopeProvider: {
				disabledGrid: false,
				selectRow: function (row) {
					selectRow(row);
				}
			},
			rowTemplate:
			'<div ng-click="grid.appScope.selectRow(row, $event, 1)"> ' +
			'<div class="ui-grid-cell" ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" ui-grid-cell ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader, \'cursor-default\': grid.appScope.disabledGrid, \'cursor-pointer\': !grid.appScope.disabledGrid }"> ' +
			'</div>' +
			'</div>',
			onRegisterApi: function (gridApi) {
				vm.gridApi = gridApi;
				if (vm.mode === constants.mode.view) {
					selectInStockCards();
					disableGridSelection();
				}
				// call resize every 500 ms for 5 s after modal finishes opening - usually only necessary on a bootstrap modal
				$interval(function () {
					vm.gridApi.core.handleWindowResize();
				}, 500, 10);
				vm.form.$setPristine();
			}
		}
	};

	var selectRow = function (row) {
		if (vm.gridOptions.enableRowSelection) {
			vm.form.$setDirty();
		}
	}

	var getReconcileData = function () {
		var cards = [];
		_.each(vm.gridApi.grid.rows, function (row) {
			cards.push(
				{
					id: row.entity.id,
					cscId: row.entity.cscId,
					selected: angular.isDefined(row.isSelected) ? row.isSelected : false,
					rowVersion: row.entity.rowVersion
				});
		})

		if (vm.stocktake) {
			return {
				id: vm.stocktake.id,
				locationId: vm.search.location.id,
				locationName: vm.search.location.name,
				notes: vm.notes,
				cards: cards,
				rowVersion: vm.stocktake.rowVersion
			}
		}
		else {
			return {
				id: null,
				locationId: vm.search.location.id,
				locationName: vm.search.location.name,
				notes: vm.notes,
				cards: cards,
				rowVersion: null
			}
		}
	}

	var selectInStockCards = function () {
		vm.gridApi.grid.modifyRows(vm.gridOptions.data);
		_.each(vm.gridOptions.data, function (m) { if (m.isMissing === false) { vm.gridApi.selection.selectRow(m) }; });
	}

	var disableGridSelection = function () {
		vm.gridApi.grid.appScope.disabledGrid = true;
		vm.gridOptions.enableSelectAll = false;
		vm.gridOptions.enableRowSelection = false;
		vm.gridOptions.enableFullRowSelection = false;
		vm.gridOptions.noUnselect = true;
		vm.gridOptions.isRowSelectable = function (row) { return false; };
		vm.gridApi.core.notifyDataChange(uiGridConstants.dataChange.ALL);
	}

	var getSerialNumberAddOutcome = function (addCount) {
		if (addCount == 0) {
			return { title: 'Serial numbers already confirmed', content: 'Serial numbers within the entered range have already been confirmed on the stocktake list.' };
		}
		else if (addCount == 1) {
			return { title: 'Serial number confirmed', content: '1 serial number was confirmed on the stocktake list.' };
		}
		else {
			return { title: 'Serial numbers confirmed', content: addCount + ' serial numbers were confirmed on the stocktake list.' };
		}
	}
}],
controllerAs: 'cst'
	});

})();