(function () {

    var app = angular.module('phapApp');

    app.controller('createEditRegionPopupCtrl', ['$scope', 'Constants', 'utils', 'referenceService', 'isAdd', 'data',
        function ($scope, constants, utils, referenceService, isAdd, data) {
            var vm = this;
            vm.isAdd = isAdd;
            vm.data = data.data;
            vm.originalData = angular.copy(data.data);
            vm.gridData = data.gridData;
            vm.gridIndex = data.itemIndex;
            vm.divisions = [];
            vm.loaded = false;
            vm.title = isAdd ? 'Add new region' : 'Edit region';
            vm.searchPlaceholderText='Search divisions'
            vm.popupLeftList = 'popupLeftList';

            var init = function () {
                referenceService.getDivisionList().then(
                    function (divisionList) {
                        vm.divisions = divisionList.data;
                        vm.originalDataSet = angular.copy(vm.divisions);
                        vm.loaded = true;
                    });
            }

            var generateDivisionNames = function () {
                var divisionNames = ''
                for (var i = 0; i < vm.data.data.divisions.length; i++) {
                    if (!vm.data.data.divisions[i].isDeleted) {
                        if (divisionNames == '') {
                            divisionNames = vm.data.data.divisions[i].name;
                        }
                        else {
                            divisionNames = divisionNames + ',' + vm.data.data.divisions[i].name;
                        }
                    }
                    
                }

                return divisionNames;
            }
            vm.isValidData = function () {
                vm.errors = [];
                if (vm.data.data.name === '') {
                    vm.errors.push({
                        message: "Region name required"
                    });
                    return false;
                }

                if (vm.data.data.divisions.length == 0) {
                    vm.errors.push({
                        message: "Region must map to at least one division"
                    });
                    return false;
                }
                var notdeleted = [];
                angular.forEach(vm.data.data.divisions, function (value, key) {
                    if (!value.isDeleted) {
                        this.push(value);
                    }

                }, notdeleted);
                
                if (notdeleted.length == 0) {
                    vm.errors.push({
                        message: "Region must map to at least one division"
                    });
                    return false;
                }

                if (vm.isAdd) {
                    var duplicates = [];
                    angular.forEach(vm.gridData, function (value, key) {
                        if (value.data.name.toLowerCase() == vm.data.data.name.toLowerCase()) {
                            this.push(vm.data.data);
                        }

                    }, duplicates);

                    if (duplicates.length > 0) {
                        vm.errors.push({
                            message: "Duplicate region names not allowed"
                        });
                        return false;
                    }
                }



                return true;
            };

            vm.applyChanges = function () {
                if (!$scope.regionAddEdit.form.$invalid && vm.isValidData()) {
                    var data = {
                        originalData: vm.originalData,
                        data: vm.data,
                        gridIndex: vm.gridIndex
                    }
                    $scope.$close(data);
                }
            }
            vm.close = function () {
                $scope.$dismiss({ $value: 'cancel' });
            }

            vm.addSelectedDivision = function (item) {
                var index = vm.data.data.divisions.indexOf(item);
                if (index === -1) {
                    vm.data.data.divisions.push(item);
                    vm.data.data.divisionNames = generateDivisionNames();
                }
            }

            vm.deleteSelectedDivision = function (index) {
                if (index !== -1) {
                    if (vm.isAdd) {
                        vm.data.data.divisions.splice(index, 1);
                    }
                    else
                    {
                        vm.data.data.divisions[index].isDeleted = true;
                       
                    }
                    vm.data.data.divisionNames = generateDivisionNames();
                }

            }
            vm.reactivateDivision = function (index) {
                if (index !== -1) {
                    vm.data.data.divisions[index].isDeleted = false;
                    vm.data.data.divisionNames = generateDivisionNames();
                }

            }
            init();
        }
    ]);

})();
