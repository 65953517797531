(function () {
    angular.module('phapApp')
        .directive('reliefDamageAssessment', ['$window', function ($window) {
            return {
                restrict: 'E',
                templateUrl: 'app/home/relief/_reliefDamageAssessment.html',
                controller: ['$scope', controller]
            };
        }]);
    function controller($scope) {

    }


})();