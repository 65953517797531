(function () {
    angular.module('phapApp')
        .controller(
            'administrationIncidentsController',
            [
                '$scope', '$location', '$cookies', '$timeout', '$http', '$q', 'uiGridConstants', 'config', '$window', '$cookies', 'security', 'utils', 'navigationService', 'notificationService','Constants',
                function ($scope, $location, $cookies, $timeout, $http, $q, uiGridConstants, config, $window, $cookies, security, utils, navigationService, notificationService, Constants) {

                    navigationService.setCurrentMenuStatus(
                        navigationService.topMenuCodes.emergencyManagement,
                        navigationService.pageCodes.administrationIncidents);

                    $scope.allowExport = false;
                    $scope.dateFormat = Constants.DatePickerFormat;

                    $scope.currentUserSecurityInfo = {};
                    
                    $scope.timeFilter = { show: false };

                    $scope.regionDropdownSettings = {
                        smartButtonMaxItems: 3,
                        smartButtonTextConverter: function (item) { return item; },
                        buttonClasses: "btn-multi-select-bare",
                        showCheckAll: false
                    };

                    $scope.regionDropdownTranslationTexts = {
                        buttonDefaultText: "All regions",
                        uncheckAll: "Clear region filter"
                    };

                    $scope.incidentTypeDropdownSettings = {
                        smartButtonMaxItems: 3,
                        smartButtonTextConverter: function (item) { return item; },
                        buttonClasses: "btn-multi-select-bare",
                        showCheckAll: false
                    };

                    $scope.incidentTypeDropdownTranslationTexts = {
                        buttonDefaultText: "All categories",
                        uncheckAll: "Clear category filter"
                    };

                    $scope.errorCountForField = function (fieldName) {
                        var field = $scope.frm[fieldName];

                        if (!field) {
                            throw "frm does not contain a key '" + fieldName + "'";
                        }

                        return _.keys(field.$error).length;
                    };

                    var getColumnDefs = function () {
                        return [
                            {
                                field: 'id',
                                visible: false
                            },
                            {
                                field: 'number',
                                displayName: 'Number',
                                type: 'number',
                                enableHiding: false,
                                enableColumnMenu: false                                
                            },
                            {
                                field: 'name',
								displayName: 'Name',
								width:'30%',
                                enableHiding: false,
                                enableColumnMenu: false
                           },                           
                           {
                                field: 'date',
                                displayName: 'Date',
                                cellClass: 'date-field',
                                enableHiding: false,
                                enableColumnMenu: false,
                                cellFilter: Constants.GridDateFormat
                            },                           
                           {
                               field: 'category',
                               displayName: 'Category',
                               enableHiding: false,
                               enableColumnMenu: false
                           },
                           {
                               field: 'region',
                               displayName: 'Region',
                               enableHiding: false,
                               enableColumnMenu: false
                           },
                           {
                               field: 'status',
                               displayName: 'Status',
                               enableHiding: false,
                               enableColumnMenu: false
                           }                           
                        ]
                    };

                    // Returns the number of rows currently in the grid
                    var rowsRendered = function () {
                        $scope.filteredRecords = $scope.gridApi.core.getVisibleRows($scope.gridApi.grid).length;
                    };

                    $scope.saveState = function (data) {                        
                        $window.localStorage.setItem("incidentSearch.gridState." + $cookies.get("UserId"), JSON.stringify($scope.gridApi.saveState.save()));                      
                    };

                    $scope.restoreState = function () {
                        var state = $window.localStorage.getItem("incidentSearch.gridState." + $cookies.get("UserId"));
						if (state) {
							$scope.gridApi.saveState.restore($scope, JSON.parse(state));
						}						
                    };                    

                    $scope.gridOptions = {
                        enableSorting: true,
                        enableFiltering: false,
                        enableHorizontalScrollbar: uiGridConstants.scrollbars.NEVER,
                        enableVerticalScrollbar: uiGridConstants.scrollbars.ALWAYS,
                        enableRowSelection: true,
                        enableFullRowSelection: true,
                        enableRowHeaderSelection: false,
                        enableSelectAll: false,
                        multiSelect: false,
                        // rowHeight: 56,                        
                        columnDefs: getColumnDefs(),                        
                        appScopeProvider: {
                            onDblClick: function (row) {
                                if (!$scope.iosOrMobile) {
                                    $scope.openItem(row.entity);
                                }
                            },
                            onEditTouchend: function (row) {
                                $scope.openItem(row.entity);
                            },
                            selectRow: function (row) {
                                $scope.openItem(row.entity);
                            }
                        },

                        rowTemplate:
                        '<div ' +
                            'ng-dblclick="grid.appScope.onDblClick(row)" ' +
                            'ng-click="grid.appScope.selectRow(row, $event, 1)"> ' +
                            '<div ' +
                                'class="ui-grid-cell" ' +
                                'ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" ' +
                                'ui-grid-cell ' +
                                'ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader }" ' +
                                'style="cursor:pointer"> ' +
                            '</div>' +
                        '</div>',

                        onRegisterApi: function (gridApi) {
							$scope.gridApi = gridApi; 
							$scope.restoreState();
                            $scope.gridApi.core.on.rowsRendered($scope, rowsRendered);                            
                        }
                    };                    

                    $http.get(config.apiBaseUrl + "incidents/ref-data")
                        .then(
                        function (result) {
                            $scope.refData = result.data;
                            $scope.regions = [];
                            _.map(result.data.regions, function (item) {
                                return $scope.regions.push({ id: item.id, label: item.name } );
                            });
                            $scope.incidentTypes = [];
                            _.map(result.data.incidentTypes, function (item) {
                                return $scope.incidentTypes.push({ id: item.id, label: item.name });
                            });

                            $scope.restoreFilters();
                        },
                        function (result) {
                            deferred.reject("HTTP call failed.");
                        }
                    );

                    $scope.saveFilters = function (regionIds, incidentTypeIds, startDate, endDate) {
                        $window.sessionStorage.setItem("incidentSearch.regionIds", JSON.stringify(regionIds ? regionIds : {}));
                        $window.sessionStorage.setItem("incidentSearch.incidentTypeIds", JSON.stringify(incidentTypeIds ? incidentTypeIds : {}));
                        $window.sessionStorage.setItem("incidentSearch.startDate", startDate ? startDate : "");
                        $window.sessionStorage.setItem("incidentSearch.endDate", endDate ? endDate : "");
                    };

                    deferredGetCurrentUserSecurityInfo = $q.defer();

                    security.getCurrentUserSecurityInfo()
                        .then(function (result) {
                            $scope.currentUserSecurityInfo = result;

                            deferredGetCurrentUserSecurityInfo.resolve();
                        },
                        function () {
                            $scope.currentUserSecurityInfo = {};
                            deferredGetCurrentUserSecurityInfo.reject('HTTP call failed');
                        });


                    $scope.restoreFilters = function () {
                        var regionIds = $window.sessionStorage.getItem("incidentSearch.regionIds");
                        if (regionIds) {
                            $scope.search.region = JSON.parse(regionIds);
                        }

                        var incidentTypeIds = $window.sessionStorage.getItem("incidentSearch.incidentTypeIds");
                        if (incidentTypeIds) {
                            $scope.search.incidentType = JSON.parse(incidentTypeIds);
                        }

                        var startDate = $window.sessionStorage.getItem("incidentSearch.startDate");
                        if (startDate) {
                            $scope.search.startDate = new Date(startDate);
                        }

                        var endDate = $window.sessionStorage.getItem("incidentSearch.endDate");
                        if (endDate) {
                            $scope.search.endDate = new Date(endDate);
                        }

						$scope.timeFilter.show = $scope.search.startDate && $scope.search.endDate;
                    };                    

                    $scope.clearFilters = function () {
                        $scope.search.region = [];
                        $scope.search.incidentType = [];
                        $scope.search.startDate = null;
                        $scope.search.endDate = null;
                        $scope.timeFilter.show = false;
                        $scope.allowExport = false;

                        // Save filter state
                        $scope.saveFilters(
                            $scope.search.region,
                            $scope.search.incidentType,
                            $scope.search.startDate,
                            $scope.search.endDate
                        );
                    };

                    $scope.searchIncidentsExport = function () {
                        $scope.exportToFile = true;

                        $scope.searchIncidents();
                    };

                    $scope.searchIncidents = function () {
                        if (!$scope.exportToFile)
                            $scope.gridOptions.data = [];

                        if (!$scope.frm.$valid) {
                            $scope.showInvalidSearchMessage = true;

                            return;
                        }

                        $scope.showInvalidSearchMessage = false;
                        
                        var regionIds = [];
                        var incidentTypeIds = [];
                        _.map($scope.search.region, function (item) {
                            return regionIds.push(item.id);
                        });
                        _.map($scope.search.incidentType, function (item) {
                            return incidentTypeIds.push(item.id);
                        });

                        if (!!$scope.search.startDate && !!$scope.search.endDate && $scope.search.startDate > $scope.search.endDate) {
                            // error
                            var msg = "<div>To date must be greater or equal to From date.</div>";
                            utils.alert(msg, "Search Error", 'Ok');
                            return;
                        }
                        var startDate;
                        var endDate;
                        if ($scope.search.startDate)
                        {
                            startDate = moment($scope.search.startDate).format('YYYY/MM/DD');                            
                        }

                        if ($scope.search.endDate) {
                            endDate = moment($scope.search.endDate).format('YYYY/MM/DD');                            
                        }
                        
                        //Save filters state
                        $scope.saveFilters(
                            $scope.search.region,
                            $scope.search.incidentType,
                            $scope.search.startDate,
                            $scope.search.endDate
                        );

                        if (!$scope.exportToFile)
                            $scope.submitting = true;
                        $http
                            .post(
                                    config.apiBaseUrl + ($scope.exportToFile ? "incidents/searchcsv" : "incidents/search"),
                                    {
                                        region: regionIds,
                                        incidentType: incidentTypeIds,
                                        incidentDateFrom: startDate,
                                        incidentDateTo: endDate
                                    }
                                    )
                                    .then(
                                    // Success
                            function (result) {
                                        if ($scope.exportToFile) {
                                            var file = new Blob([result.data], { type: 'text/csv' });
                                            saveAs(file, 'IncidentList.csv');
                                            $scope.exportToFile = false;
                                            return;
                                        }
                                        $scope.submitting = false;
                                        $scope.validationError = false;
                                        if (result.data.success === false) {
                                            $scope.validationError = true;
                                            $scope.validationErrorMsg = result.data.message;
                                            return;
                                        }
                                        else {
                                            $scope.allowExport = true;
                                            $scope.validationError = false;
                                        }
                                        
                                        $scope.gridOptions.data = result.data.incidents;
                                        $scope.noResultsFound = false;
                                        if ($scope.gridOptions.data.length === 0)
                                        {
                                            $scope.noResultsFound = true;
                                        }
                                        //Save grid state
                                        $scope.saveState($scope.gridOptions.data);                                        
                                    },
                                    // Error
                                    function (result) {
                                        $scope.submitting = false;
                                        if (result.data && result.data.errors && result.data.errors.length > 0) {
                                            _.each(result.data.errors,
                                                function(e) {
                                                    if (e.message === "IncidentDateToMustBeGreaterThanOrEqualToIncidentDateFrom") {
                                                        var msg = "<div>To date must be greater or equal to From date.</div>";
                                                            utils.alert(msg, "Search Error", 'Ok');
                                                            return;
                                                    }
                                                });
                                        }
                                    }
                            );
                    };

                    //Initialize scope variables
                    $scope.noResultsFound = false;
                    $scope.regions = [];
                    $scope.incidentTypes = [];
                    $scope.search = {};
                    $scope.search.region = [];
                    $scope.search.incidentType = [];   
                    $scope.search.date = { startDate: null, endDate: null };                        

                    $scope.openItem = function (entity) {
                        $location.path("/administration/incidents/edit/" + entity.id);
                    };

                    $scope.newIncident = function () {
                        $location.path("/administration/incidents/create");
                    };
                }
            ]
        );
}());