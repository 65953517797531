(function () {


    var app = angular.module('phapApp');
    app.component('paymentApproval', {
        templateUrl: 'app/_components/paymentApproval.html',
        bindings: {
            payment: '=',
            security: '<',
            application: '=',
            loadData: '&',
            isRelief: '<',
        },
        controller: ['utils', 'security', 'Constants', '$scope', 'reestablishService', 'reliefService', 'notificationService','$cookies',
            function (utils, security, constants, $scope, reestablishService, reliefService, notificationService, $cookies) {
                var vm = this;
                var userId = $cookies.get("UserId");

                vm.rejectPayment = function () {
                    var resolve = {
                        item: function () {
                            return $scope.paymentApproval.application;
                        },
                        payment: function () {
                            return $scope.paymentApproval.payment;
                        },
                        isRelief: function () {
                            return vm.isRelief;
                        }
                    }
                    utils.openModal('app/_components/rejectPopup.html', 'rejectPopupController', $scope, resolve, null, vm.reloadData, 'sm');
                }
                vm.reloadData = function (result) {
                    vm.loadData({ data: result, paymentId: vm.payment.paymentId });
                }
                vm.showRejectionReason = function () {
                    return vm.payment &&
                        !utils.isNullOrEmpty(vm.payment.rejectionReason) &&
                        (vm.payment.paymentStatusId < constants.paymentStatus.approved.id);
                }
                vm.showApprovalButtons = function () {
                    return (vm.payment && vm.security.privileges.ApproveFurtherPayment &&
                        vm.payment.paymentStatusId === constants.paymentStatus.pendingApproval.id &&
                        vm.payment.createdBy != userId &&
                        vm.payment.approvedById != userId &&
                        vm.payment.submittedById != userId);
                }
                vm.approvePayment = function () {
                    notificationService.notify('showLoadingPanel', true);
                    var submitModel = {
                        applicationId: vm.application.id,
                        rowVersion: vm.application.rowVersion,
                        payment: vm.payment
                    };
                    if (vm.isRelief)
                        vm.approveReliefPayment(submitModel);
                    else
                        vm.approveReEstPayment(submitModel);
                    
                }
                vm.approveReliefPayment = function (submitModel) {
                    reliefService.approvePayment(submitModel).then(function (result) {
                        notificationService.notify('showLoadingPanel', false);
                        vm.loadData({ data: result, paymentId: vm.payment.paymentId, showsuccess: true });
                    }, function () {
                        notificationService.notify('showLoadingPanel', false);
                    });
                };
                vm.approveReEstPayment = function (submitModel) {
                    reestablishService.approvePayment(submitModel).then(function (result) {
                        notificationService.notify('showLoadingPanel', false);
                        vm.loadData({ data: result, paymentId: vm.payment.paymentId, showsuccess: true });
                    }, function () {
                        notificationService.notify('showLoadingPanel', false);
                    });
                };
            }],
        controllerAs: 'paymentApproval'
    });

})();