(function () {
    var app = angular.module('phapApp');

    app.component('history', {
        templateUrl: 'app/_components/history.html',
        bindings: {
            mode: '@'
        },
        controllerAs: 'history',
        controller: historyController
    });
    historyController.$inject = ['historyService', '$routeParams', '$window', '$filter', 'Constants'];

    function historyController(historyService, $routeParams, $window, $filter, Constants) {

        var vm = this;
        vm.Constants = Constants;
        vm.jsondiffpatch = {};
        var diffs = [];
        var objDiffList = [];
        vm.toggleBtnText = "Expand All";

        vm.$onInit = function () {
            vm.entity = $routeParams.entity;
            vm.entityDisplay = vm.entity.replace(/-/g, ' ');
            vm.id = $routeParams.id;
            vm.referenceNumber = $routeParams.referenceNumber; // optional parameter to pass reference number for entity
            vm.createdBy = '';
            vm.createdDate = null;
            vm.isLoading = true;
            getEntityHistory();
            vm.jsondiffpatch = jsondiffpatch.create({
                // used to match objects when diffing arrays, by default only === operator is used
                objectHash: function (obj) {
                    // this function is used only to when objects are not equal by ref
                    return obj._Id || obj.id || obj.name || obj.paymentId;
                },
                arrays: {
                    // default true, detect items moved inside the array (otherwise they will be registered as remove+add)
                    detectMove: true,
                    // default false, the value of items moved is not included in deltas
                    includeValueOnMove: false
                },
                textDiff: {
                    // default 60, minimum string length (left and right sides) to use text diff algorythm: google-diff-match-patch
                    minLength: 60
                },
                propertyFilter: function (property, context) {
                    /*
                     this optional function can be specified to ignore object properties (eg. volatile data)
                      name: property name, present in either context.left or context.right objects
                      context: the diff context (has context.left and context.right objects)
                    */
                    return property.toUpperCase() !== "ID" && property.toUpperCase() !== "ACTIONTITLE";

                    //return (ignoreFields.indexOf(property.toUpperCase()) === -1);
                },
                cloneDiffValues: false /* default false. if true, values in the obtained delta will be cloned
              (using jsondiffpatch.clone by default), to ensure delta keeps no references to left or right objects. this becomes useful if you're diffing and patching the same objects multiple times without serializing deltas.
              instead of true, a function can be specified here to provide a custom clone(value)
              */
            });
        }

        vm.capitalize = function (str) {
            return _.capitalize(str);
        }

        vm.navBack = function () {
            $window.history.back();
        }
        //print related functionality
        vm.printOrder = function () {
            vm.printElement(document.getElementById("printableArea"));
            window.print();
        }

        vm.expandSections = function (expand) {
            if (expand) {
                _.forEach(vm.changelogs, function (e) {
                    e.displayDiff = true;
                    vm.toggleBtnText = "Collapse All";
                });
            }
            else {
                _.forEach(vm.changelogs, function (e) {
                    e.displayDiff = !e.displayDiff;
                });
                if (vm.changelogs[0].displayDiff) {
                    vm.toggleBtnText = "Collapse All";
                }
                else {
                    vm.toggleBtnText = "Expand All";
                }
            }
        }
        vm.printElement = function (elem) {

            var domClone = elem.cloneNode(true);
            var $printSection = document.getElementById("printSection");
            if (!$printSection) {
                var $printSection = document.createElement("div");
                $printSection.id = "printSection";
                document.body.appendChild($printSection);
            }
            $printSection.innerHTML = "";
            $printSection.appendChild(domClone);
        }

        var getEntityHistory = function () {
            historyService.getHistoryDetails(vm.entity, vm.id)
                .then(function (response) {
                    vm.heading = response.description;
                    vm.changelogs = response.version;
                    vm.changelogs[vm.changelogs.length - 1].updatedBy = vm.changelogs[vm.changelogs.length - 1].createdBy;

                    for (var i = 0; i < vm.changelogs.length; i++) {

                        var newVal = vm.changelogs[i];
                        var oldVal = {
                            model: {}
                        };

                        if (i !== vm.changelogs.length - 1) {
                            oldVal = vm.changelogs[i + 1]
                        }

                        var delta = vm.jsondiffpatch.diff(oldVal.model, newVal.model);
                        objDiffList = [];

                        var actionTitle = newVal.model.actionTitle ? newVal.model.actionTitle : '';

                        displayDiff(actionTitle, delta);
                        diffs = [];

                        vm.changelogs[i].hasDiff = true;
                        vm.changelogs[i].displayDiff = false;
                        vm.changelogs[i].diffs = objDiffList;

                        var actions = [];
                        var actionCounts = [];
                        for (var j = 0; j < objDiffList.length; j++) {
                            if (objDiffList[j].name !== "") {
                                // Only add if the new action does not exist in our action list, otherwise increment count of these actions
                                var newAction = vm.capitalize(objDiffList[j].name);
                                var pos = actions.indexOf(newAction);
                                if (pos == -1) {
                                    actions.push(vm.capitalize(objDiffList[j].name));
                                    actionCounts.push(1);
                                }
                                else {
                                    actionCounts[pos] = actionCounts[pos] + 1;
                                }
                            }
                        }
                        // Merge the action counts back to the actions for display
                        vm.changelogs[i].actions = _.map(actions, function (a, i) { return actionCounts[i] > 1 ? a + ' (' + actionCounts[i] + ')' : a; }).join(', ');
                    }
                    vm.isLoading = false;
                }, function () {
                    vm.isLoading = false;
                });
        };

        var displayDiff = function (objName, delta) {
            var objCollection = [];
            if (delta) {
                for (var property in delta) {
                    //This logic is for collection of objects and objects while updation
                    if (typeof delta[property] === "object" && !Array.isArray(delta[property])) {
                        objCollection.push({ name: property, operation: "u", value: delta[property] });
                    }
                    //This logic is for collection of objects and objects while creation
                    else if (Array.isArray(delta[property]) && delta[property].length > 0) {
                        if (typeof delta[property][0] == "object" && delta[property][0] !== null) {
                            //Collection of objects like payments, documents etc.,
                            if (Array.isArray(delta[property][0])) {
                                var objArray = delta[property][0];
                                for (objProp in objArray) {
                                    objCollection.push({ name: property, operation: "c", value: objArray[objProp] });
                                }
                            }
                            //One to one mapping objects like AffectedAddress, Applicant etc.,
                            else {
                                objCollection.push({ name: property, operation: "c", value: delta[property][0] });
                            }
                        }
                    }
                }

                getRootProperties(objName, delta);
                displayDifferences(objCollection);
            }
        }

        var displayDifferences = function (objCollection) {
            var diffCollection = [];

            for (var property in objCollection) {

                var obj = objCollection[property];
                var diff = { "name": obj.name, "value": [] };
                //Collection
                if (obj && obj.value) {
                    if (obj.value["_t"] !== undefined && obj.value["_t"] === "a") {
                        for (var prop in obj.value) {
                            if (Array.isArray(obj.value[prop])) {
                                if (obj.value[prop].length == 1) {
                                    diff.value.push(getProperties(diff.name, obj.value[prop][0], "c"));
                                }
                                if (obj.value[prop].length == 3) {
                                    diff.value.push(getProperties(diff.name, obj.value[prop][0], "d"));
                                }
                            }
                            else {
                                if (typeof obj.value[prop] === "object") {
                                    diff.value.push(getProperties(diff.name, obj.value[prop], "u"));
                                }
                            }
                        }
                    }
                    else {
                        //One to One Mapping
                        getProperties(obj.name, obj.value, obj.operation);
                    }
                }
            }
        }

        var getOperationName = function (op) {
            if (op == "u") {
                return "Updated";
            } else if (op === "d") {
                return "Deleted";
            }
            else if (op === "c") {
                return "Created";
            }
        }

        var IsEmpty_Null_Undefined = function (obj) {
            return (obj === null || obj === undefined || obj === '');
        }

        var getRootProperties = function (objName, obj) {
            var objdiff = { name: objName === 'root' ? '' : objName, diff: [] };
            for (var prop in obj) {
                //This is for when properties are added on the root object
                if (typeof obj[prop] === "object" && Array.isArray(obj[prop])) {
                    if (obj[prop].length == 1 && obj[prop][0] !== null) {
                        if (typeof obj[prop][0] !== "object") {
                            var oldVal = null;
                            var newVal = obj[prop][0];
                            if (!IsEmpty_Null_Undefined(newVal)) {
                                if (prop.toUpperCase() !== "ID") {
                                    objdiff.diff.push({ field: prop, oldValue: oldVal, newValue: newVal })
                                }
                            }
                        }
                    }
                    //This is for updation of properties on the root object
                    else if (obj[prop].length == 2) {
                        var oldVal = obj[prop][0];
                        var newVal = obj[prop][1];
                        if (!IsEmpty_Null_Undefined(oldVal) || !IsEmpty_Null_Undefined(newVal)) {
                            if (prop.toUpperCase() !== "ID") {
                                if (Array.isArray(newVal)) {
                                    newVal = newVal.join(' ');
                                }
                                objdiff.diff.push({ field: prop, oldValue: oldVal, newValue: newVal })
                            }
                        }
                    }
                    else if (obj[prop].length == 3) {
                        var oldVal = obj[prop][0];
                        var newVal = null;
                        if (!IsEmpty_Null_Undefined(oldVal) || !IsEmpty_Null_Undefined(newVal)) {
                            if (prop.toUpperCase() !== "ID") {
                                if (Array.isArray(newVal)) {
                                    newVal = newVal.join(' ');
                                }
                                objdiff.diff.push({ field: prop, oldValue: oldVal, newValue: newVal })
                            }
                        }
                    }
                }
            }

            if (objdiff.diff.length > 0) {
                objDiffList.push(objdiff);
            }

        }

        var getProperties = function (objName, obj, operation) {

            var fieldValues = [];
            var objdiff = { name: objName + " " + getOperationName(operation), diff: [] }
            if (operation === "u") {
                for (var prop in obj) {
                    var oldVal = obj[prop][0];
                    var newVal = obj[prop][1];
                    if ((!IsEmpty_Null_Undefined(oldVal) || !IsEmpty_Null_Undefined(newVal)) && prop.toUpperCase() !== "ID") {
                        fieldValues.push({ field: prop, oldValue: oldVal, newValue: newVal });
                    }

                }
                objdiff.diff = fieldValues;
            }
            else if (operation == "d" || operation === "c") {
                for (var prop in obj) {
                    var oldVal = (operation === "d") ? obj[prop] : null;
                    var newVal = (operation === "c") ? obj[prop] : null;
                    if ((operation === "c" && !IsEmpty_Null_Undefined(newVal)) || (operation == "d" && !IsEmpty_Null_Undefined(oldVal))) {
                        if (prop.toUpperCase() !== "ID") {
                            fieldValues.push({ field: prop, oldValue: oldVal, newValue: newVal })
                        }
                    }
                }
                objdiff.diff = fieldValues;
            }

            if (objdiff.diff.length > 0) {
                objDiffList.push(objdiff);
            }

        }

    }
})();