(function () {
    angular.module('phapApp')
        .controller(
            'editIncidentController',
            [
                '$scope', '$location', '$timeout', 'uiGridConstants', '$route', '$routeParams', '$http', '$q', 'config', 'security', 'Constants', 'applicationSearchService', 'navigationService', 'notificationService', 'Constants', '$window',
                function ($scope, $location, $timeout, uiGridConstants, $route, $routeParams, $http, $q, config, security, Constants, applicationSearchService, navigationService, notificationService, Constants, $window) {

                    var HOUSEFIRE_INCIDENTTYPE_ID = function () { return _.find($scope.refData.incidentTypes, { name: "House Fire" }).id; };
                    var EMERGENCYTYPE_NONDECLARED = function () { return _.find($scope.refData.emergencyTypes, { name: "Non Declared" }).id };
                    var EMERGENCYTYPE_DECLARED = function () { return _.find($scope.refData.emergencyTypes, { name: "Declared" }).id };

                    navigationService.setCurrentMenuStatus(
                        navigationService.topMenuCodes.emergencyManagement,
                        navigationService.pageCodes.administrationIncidents);

                    // page states for the incident edit page only
                    $scope.pageStateCodes = { edit: 1, relief: 2, reestablish: 3 };
                    $scope.pageState = $scope.pageStateCodes.edit;
                    $scope.reliefData = [];
                    $scope.reestablishData = [];
                    $scope.reliefDataLoaded = false;
                    $scope.reestablishDataLoaded = false;
                    $scope.hasReliefAccess = true;
                    $scope.hasReestablishAccess = true;

                    $scope.currentUserSecurityInfo = {};

                    $scope.loaded = false;

                    $scope.orig = null;

                    // Used by uib-datepicker
                    $scope.dateFormat = Constants.DatePickerFormat;

                    $scope.id = $routeParams.id;
                    $scope.showSavedMessage = $routeParams.fromCreate;

                    function padLeft(nr, n, str) {
                        return Array(n - String(nr).length + 1).join(str || '0') + nr;
                    }

                    function getDateStringFromLocalDate(date) {
                        if (!date) return null;


                        return padLeft(date.getFullYear(), 4, "0") + "-" + padLeft((date.getMonth() + 1), 2, "0") + "-" + padLeft(date.getDate(), 2, "0");
                    }

                    var allSetupPromises = [];

                    var deferredGetCurrentUserSecurityInfo = $q.defer();
                    allSetupPromises.push(deferredGetCurrentUserSecurityInfo.promise);

                    security.getCurrentUserSecurityInfo()
                        .then(function (result) {
                            $scope.currentUserSecurityInfo = result;

                            deferredGetCurrentUserSecurityInfo.resolve();
                        },
                            function () {
                                $scope.currentUserSecurityInfo = {};
                                deferredGetCurrentUserSecurityInfo.reject('HTTP call failed');
                            });

                    var deferredLoadRefData = $q.defer();

                    allSetupPromises.push(deferredLoadRefData.promise);

                    $http.get(config.apiBaseUrl + "incidents/ref-data")
                        .then(
                            function (result) {
                                $scope.refData = result.data;


                                deferredLoadRefData.resolve();
                            },
                            function (result) {
                                deferredLoadRefData.reject("HTTP call failed.");
                            }
                        );




                    $scope.loadData = loadData;
                    $scope.failedToLoad = false;

                    $q.all(allSetupPromises)
                        .then(function () {
                            return $scope.loadData();
                        })
                        .then(
                            function () {
                                $scope.loaded = true;
                                $scope.updateStates();
                            },
                            function () {
                                $scope.failedToLoad = true;
                            }
                        );

                    function loadData(deferredLoadIncident) {
                        var deferredLoadIncident = deferredLoadIncident || $q.defer();
                        if ($scope.id) {
                            $http.get(config.apiBaseUrl + "incidents/incident/" + $scope.id)
                                .then(
                                    function (result) {

                                        $scope.item = result.data;
                                        $scope.loadedIncidentName = $scope.item.incidentName;

                                        if ($scope.item.incidentDate) {
                                            $scope.item.incidentDate = new Date($scope.item.incidentDate);
                                        }

                                        if ($scope.item.effectiveDate) {
                                            $scope.item.effectiveDate = new Date($scope.item.effectiveDate);
                                        }

                                        if ($scope.item.endDate) {
                                            $scope.item.endDate = new Date($scope.item.endDate);
                                        }
                                        else {
                                            $scope.item.endDate = null;
                                        }
                                        if ($scope.allLgasForRegionDivision) {
                                            $scope.allLgasForRegionDivision.forEach(function (obj) {

                                                if ($scope.item.linkedLgaCodes.includes(obj.code)) {
                                                    obj.checked = true;
                                                }
                                            });
                                        }

                                        if ($scope.item.incidentNumber) {
                                            $scope.item.incidentNumber = parseInt($scope.item.incidentNumber);
                                        }

                                        if ($scope.item.eventId) {
                                            $scope.item.eventId = parseInt($scope.item.eventId);
                                        }

                                        $scope.orig = angular.copy($scope.item);
                                        $scope.item.reasonEnabled = false;

                                        $scope.divisions = [];
                                        $scope.divisions.push($scope.item.division);

                                        searchReliefApplications();
                                        searchReestablishApplications();

                                        deferredLoadIncident.resolve();

                                    },
                                    function (result) {
                                        deferredLoadIncident.reject("HTTP call failed.");
                                    }
                                );
                        } else {
                            $scope.item = {
                                incidentDate: null,
                                effectiveDate: null,
                                endDate: null,
                                regionId: null,
                                divisionId: null,
                                reasonEnabled: false,
                                isIncidentVisibleCphap: false,
                                identifier: '00000'
                            };

                            $scope.divisions = [];
                            deferredLoadIncident.resolve();
                        }
                        setValidRefernceDate();
                        return deferredLoadIncident.promise;
                    };

                    function setValidRefernceDate() {
                        if ($scope.id) {
                            $scope.refData.incidentTypesValid = angular.copy($scope.refData.incidentTypes);
                        }
                        else {
                            $scope.refData.incidentTypesValid = [];
                            $scope.refData.incidentTypes.forEach(function (type) {
                                if (!type.isDeleted)
                                    $scope.refData.incidentTypesValid.push(type);
                            })
                        }
                    };

                    $scope.updateStates = function () {

                        if ($scope.item &&
                            !$scope.item.emergencyTypeId) {
                            $scope.item.emergencyTypeId = $scope.refData.defaultEmergencyTypeId;
                        }

                        $scope.regionsForUser = [];

                        $scope.item.$status = $scope.item.status == Constants.IncidentStatus.Closed ? 'Closed' : 'Open';

                        $scope.isClosed = $scope.item.status == Constants.IncidentStatus.Closed;

                        // Users with UpdateIncidentsInAnyDivision have access to all regions
                        if ($scope.currentUserSecurityInfo.privileges.UpdateIncidentsInAnyDivision) {
                            $scope.regionsForUser = $scope.refData.regions;
                        } else {
                            // Users with UpdateIncidentsInOwnDivisions have access only to regions that 
                            // are mapped to divisions that the user has access to
                            if ($scope.currentUserSecurityInfo.privileges.UpdateIncidentsInOwnDivisions) {
                                $scope.regionsForUser = $scope.currentUserSecurityInfo.regionsForUser($scope.refData);
                            }
                        }

                        if ($scope.item.regionId && !$scope.currentUserSecurityInfo.privileges.UpdateIncidentsInAnyDivision) {

                            $scope.userHasAccessToRegionAndDivision = $scope.currentUserSecurityInfo.userHasAccessToRegion($scope.refData, $scope.item.regionId)
                                && $scope.currentUserSecurityInfo.userHasAccessToDivision($scope.item.divisionId);

                        } else {
                            $scope.userHasAccessToRegionAndDivision = true;
                        }

                        $scope.loadDivisionsForSelectedRegion();

                        $scope.disabledWhenDeclared = $scope.item.emergencyTypeId == EMERGENCYTYPE_DECLARED()
                            && !$scope.currentUserSecurityInfo.privileges.UpdateDeclaredEmergencyIncidents;

                        $scope.CheckIdentifierEditable();

                        if (!$scope.currentUserSecurityInfo.privileges.UpdateNonHousefireIncident) {
                            if ($scope.id == null && $scope.item.incidentTypeId == null) {
                                $scope.item.incidentTypeId = HOUSEFIRE_INCIDENTTYPE_ID();
                            }
                        }

                        $scope.disabledOnNonHousefire = $scope.item.incidentTypeId != HOUSEFIRE_INCIDENTTYPE_ID()
                            && !$scope.currentUserSecurityInfo.privileges.UpdateNonHousefireIncident;

                        $scope.shouldDisable = (function () {
                            return $scope.isClosed || !$scope.userHasAccessToRegionAndDivision || $scope.disabledWhenDeclared || $scope.disabledOnNonHousefire;
                        })();

                        // relief form: incident is NOT closed, user has access to this division, user has privileges                        
                        $scope.userHasAccessToUpdateRelief = $scope.currentUserSecurityInfo.userHasAccessToUpdateRelief(0, $scope.item);
                        $scope.userHasAccessToUpdateReestablish = $scope.currentUserSecurityInfo.userHasAccessToUpdateReestablish(0, $scope.item);
                    };

                    $scope.$popups = {
                        incidentDate: { opened: false },
                        effectiveDate: { opened: false },
                        endDate: { opened: false }
                    };

                    $scope.errorCountForField = function (fieldName) {
                        var field = $scope.frm[fieldName];

                        if (!field) {
                            throw "frm does not contain a key '" + fieldName + "'";
                        }

                        return _.keys(field.$error).length;
                    };

                    $scope.dateOptions = {
                        dateFormat: Constants.DatePickerFormat
                    };

                    var getFormFieldCaseInsensitive = function (f) {
                        var targetField = $scope.frm[f];

                        _.each($scope.frm, function (value, key) {
                            if (key.toLowerCase() === f.toLowerCase()) {
                                targetField = value;

                                return false;
                            }
                        });

                        return targetField;
                    };

                    //$scope.$watch('item.incidentDate', function (newVal, oldVal) {
                    //    if (!$scope.item || !newVal) return;

                    //    if (!$scope.item.effectiveDate || (oldVal == $scope.item.effectiveDate)) {
                    //        $scope.item.effectiveDate = new Date(newVal);
                    //    }
                    //});

                    $scope.reasonVisible = function () {
                        return ($scope.orig && !_.isEmpty($scope.orig.reason)) || ($scope.item && $scope.item.reasonEnabled);
                    };

                    $scope.$watch('item.endDate', function (newVal, oldVal) {
                        if (newVal !== oldVal && angular.isDefined(oldVal) && $scope.item) {
                            $scope.item.reasonEnabled = true;
                            $scope.item.reason = '';
                        }
                    });

                    $scope.shouldDisableEmergencyType = function () {

                        return $scope.isClosed || !$scope.userHasAccessToRegionAndDivision || $scope.disabledOnNonHousefire || !$scope.currentUserSecurityInfo.privileges.UpdateEmergencyType;
                    };
                    $scope.checkIncidentDate = function (dateValue) {
                        $scope.frm.incidentDate.$setValidity('greaterThanTodaysDate', true);
                        var incidentDate = new Date(dateValue);
                        if (!incidentDate || incidentDate > new Date()) {
                            $scope.frm.incidentDate.$setValidity('greaterThanTodaysDate', false);
                        }
                    }
                    $scope.checkEffectiveDate = function (dateValue) {
                        $scope.frm.effectiveDate.$setValidity('greaterThanTodaysDate', true);
                        var effectiveDate = moment(dateValue);
                        if (!effectiveDate || moment(effectiveDate, 'YYYY-MM-DD').isAfter(moment())) {
                            $scope.frm.effectiveDate.$setValidity('greaterThanTodaysDate', false);
                        }
                    }
                    $scope.updateErrorsBasedOnSaveResult = function (result) {
                        // Clear form level $error
                        $scope.frm.$error = {};

                        // Clear all field-level $error data
                        _.each($scope.frm, function (value, key) {
                            if (key[0] !== '$') {
                                if (value.$error) {
                                    value.$error = {};
                                }
                            }
                        });

                        var form_errs, field_errs;
                        form_errs = field_errs = $scope.errors = [];

                        // Copy both form-level and field-level error info from response
                        if (result.data && result.data.errors && result.data.errors.length) {
                            $scope.errors = result.data.errors;
                            form_errs = _.filter($scope.errors, function (e) { return _.isEmpty(e.field); });
                            field_errs = _.difference($scope.errors, form_errs);
                        }

                        // set form-level errors
                        _.each(form_errs, function (e) {
                            $scope.frm.$error[e.message.toLowerCase()] = true;
                        });

                        // set field-level errors
                        _.each(field_errs, function (e) {
                            var targetField = getFormFieldCaseInsensitive(e.field);

                            if (targetField) {
                                var messageSegments = e.message.split('_');

                                targetField.$error[messageSegments[0].toLowerCase()] = true;

                                if (messageSegments.length > 1) {
                                    targetField.validationArguments = [parseInt(messageSegments[1])];
                                }
                            }
                        });
                    };

                    $scope.selectAll = function () {
                        this.allLgasForRegionDivision.forEach(function (obj) {
                            obj.checked = true;
                        });
                    }

                    $scope.unselectAll = function () {
                        this.allLgasForRegionDivision.forEach(function (obj) {
                            obj.checked = false;
                        });
                    }

                    $scope.loadDivisionsForSelectedRegion = function () {
                        // Filter down to just the divisions for the selected region
                        var rdms =
                            _.filter($scope.refData.regionDivisionMappings, function (rdm) {
                                return rdm.regionId == $scope.item.regionId;
                            });

                        $scope.divisions =
                            _.map(rdms, function (rdm) { return rdm.division; });

                        if (!$scope.currentUserSecurityInfo.privileges.UpdateIncidentsInAnyDivision) {
                            // Filter further to just the divisions that the current user is permitted to work with
                            $scope.divisions =
                                _.filter($scope.divisions, function (division) {
                                    return _.indexOf($scope.currentUserSecurityInfo.divisions, division.id) >= 0;
                                });
                        }

                        if ($scope.divisions.length == 1 && $scope.userHasAccessToRegionAndDivision) {
                            $scope.item.divisionId = $scope.divisions[0].id;
                        }

                        var lgardms =
                            _.filter($scope.refData.regionDivisionMappings, function (rdm) {
                                return rdm.regionId == $scope.item.regionId && rdm.divisionId == $scope.item.divisionId;
                            });

                        $scope.allLgasForRegionDivision =
                            _.map(lgardms[0].lgas, function (rdm) {

                                var isChecked = false;
                                if (!$scope.item.linkedLgaCodes || $scope.item.linkedLgaCodes.length == 0 || $scope.item.linkedLgaCodes.includes(rdm.lgaCode)) {

                                    isChecked = true;
                                }

                                return { checked: isChecked, name: rdm.name, code: rdm.lgaCode };
                            });

                    };

                    $scope.resetLgasAndLoadLgasForSelectedDivisionsAndRegion = function () {
                        $scope.allLgasForRegionDivision = null;

                        $scope.loadLgasForSelectedDivisionsAndRegion();
                    };

                    $scope.loadLgasForSelectedDivisionsAndRegion = function () {

                        var lgardms =
                            _.filter($scope.refData.regionDivisionMappings, function (rdm) {
                                return rdm.regionId == $scope.item.regionId && rdm.divisionId == $scope.item.divisionId;
                            });

                        $scope.allLgasForRegionDivision =
                            _.map(lgardms[0].lgas, function (rdm) {

                                var isChecked = false;
                                if (!$scope.item.linkedLgaCodes || $scope.item.linkedLgaCodes.length == 0 || $scope.item.linkedLgaCodes.includes(rdm.lgaCode)) {

                                    isChecked = true;
                                }

                                return { checked: isChecked, name: rdm.name, code: rdm.lgaCode };
                            });

                    };

                    $scope.resetDivisionAndLoadDivisionsForSelectedRegion = function () {
                        $scope.item.divisionId = null;

                        $scope.loadDivisionsForSelectedRegion();
                    };

                    $scope.areAllFieldsDisabled = function () {
                        return $scope.shouldDisable
                            && ($scope.shouldDisable || !$scope.currentUserSecurityInfo.privileges.UpdateNonHousefireIncident)
                            && ($scope.shouldDisableEmergencyType() || !$scope.userHasAccessToRegionAndDivision)
                            && (!$scope.currentUserSecurityInfo.privileges.UpdateEndDate || !$scope.userHasAccessToRegionAndDivision)
                    };

                    $scope.saveIncident = function () {
                        $scope.frm.$submitted = true;
                        $scope.showSavedMessage = false;
                        $scope.frm.$setValidity();
                        if ($scope.frm.incidentDate.$error['greaterThanTodaysDate'] || new Date($scope.frm.incidentDate) > new Date()) {
                            $scope.frm.incidentDate.$error['greaterThanTodaysDate'] = true;
                            return;

                        }
                        if ($scope.frm.effectiveDate.$error['greaterThanTodaysDate'] || new Date($scope.frm.effectiveDate) > new Date()) {
                            $scope.frm.effectiveDate.$error['greaterThanTodaysDate'] = true;
                            return;
                        }
                        if ($scope.frm.incidentDate.$error['validateMinDate'] && $scope.frm.effectiveDate.$error['validateMinDate']) {

                            return;
                        }

                        if ($scope.frm.$invalid) {
                            return;
                        }

                        $scope.submitting = true;

                        var selectedLgas =
                            _.filter($scope.allLgasForRegionDivision, function (rdm) {
                                return rdm.checked == true;
                            });

                        selectedLgas = _.map(selectedLgas, function (rdm) {
                            if (rdm.checked == true) {
                                return rdm.code;
                            }
                        });

                        if (!selectedLgas || selectedLgas.length == 0) {
                            selectedLgas = null;
                        }


                        $http
                            .post(
                                config.apiBaseUrl + "incidents/incident",
                                {
                                    id: $scope.item.id,
                                    incidentName: $scope.item.incidentName,
                                    incidentNumber: $scope.item.incidentNumber,
                                    incidentLocation: $scope.item.incidentLocation,
                                    incidentDate: getDateStringFromLocalDate($scope.item.incidentDate),
                                    effectiveDate: getDateStringFromLocalDate($scope.item.effectiveDate),
                                    endDate: getDateStringFromLocalDate($scope.item.endDate),
                                    emergencyTypeId: $scope.item.emergencyTypeId,
                                    incidentTypeId: $scope.item.incidentTypeId,
                                    regionId: $scope.item.regionId,
                                    event: $scope.item.event,
                                    eventId: $scope.item.eventId,
                                    divisionId: $scope.item.divisionId,
                                    reason: $scope.item.reason,
                                    identifier: $scope.item.identifier.toUpperCase(),
                                    rowVersion: $scope.item.rowVersion,
                                    isIncidentVisibleCphap: $scope.item.isIncidentVisibleCphap,
                                    linkedLgaCodes: selectedLgas
                                }
                            )
                            .then(
                                // Success
                                function (result) {
                                    $scope.updateErrorsBasedOnSaveResult(result);

                                    $scope.submitting = false;

                                    $scope.showSavedMessage = true;

                                    $scope.item.linkedLgaCodes = result.data.linkedLgaCodes;

                                    $scope.item.status = result.data.status;

                                    $scope.item.rowVersion = result.data.rowVersion;

                                    $scope.item.reasonEnabled = false;

                                    $scope.item.isIncidentVisibleCphap = result.data.isIncidentVisibleCphap;

                                    $scope.orig = angular.copy($scope.item);

                                    $scope.loadedIncidentName = $scope.item.incidentName;

                                    $scope.frm.$setPristine();

                                    if (!$scope.id) {
                                        $location.path("/administration/incidents/edit/" + result.data.id + "/true");
                                    } else {
                                        $scope.updateStates();
                                    }
                                },
                                // Error
                                function (result) {
                                    $scope.submitting = false;

                                    // If an actual result was returned, then act on it (status == -1 means no response)
                                    if (result.status != -1) {
                                        $scope.updateErrorsBasedOnSaveResult(result);

                                        $scope.tempResponse = result;
                                    }
                                }
                            );
                    };

                    $scope.onCancel = function () {
                        $window.history.back();
                    };

                    function searchReliefApplications() {

                        applicationSearchService.searchReliefApplicationsByIncident($scope.item.id)
                            .then(
                                // Success
                                function (result) {
                                    $scope.reliefData = result.data.applications;
                                    $scope.reliefDataLoaded = true;

                                },
                                function () {
                                    if (data.status === 401)
                                        $scope.hasReliefAccess = false;
                                }
                            );
                    };
                    function searchReestablishApplications() {

                        applicationSearchService.searchReestablishmentApplicationsByIncident($scope.item.id)
                            .then(
                                // Success
                                function (result) {
                                    $scope.reestablishData = result.data;
                                    $scope.reestablishDataLoaded = true;
                                },
                                function (data) {
                                    if (data.status === 401)
                                        $scope.hasreestablishAccess = false;
                                }
                            );
                    };

                    $scope.clickTab = function (toPageState) {
                        if (toPageState === $scope.pageStateCodes.relief && $scope.reliefDataLoaded) {
                            $scope.pageState = toPageState;
                        } else if (toPageState === $scope.pageStateCodes.reestablish && $scope.reestablishDataLoaded) {
                            $scope.pageState = toPageState;
                        } else if (toPageState === $scope.pageStateCodes.edit) {
                            $scope.pageState = toPageState;
                        }
                    };

                    $scope.emergencyTypeChanged = function () {
                        $scope.CheckIdentifierEditable();
                    };

                    $scope.CheckIdentifierEditable = function () {
                        var isNonDeclared = $scope.item.emergencyTypeId == EMERGENCYTYPE_NONDECLARED();
                        if (isNonDeclared) {
                            $scope.item.identifier = '00000';
                        }
                        $scope.isIdentifierReadonly = isNonDeclared || !$scope.currentUserSecurityInfo.privileges.UpdateIncidentIdentifier;
                    };

                }
            ]
        );
}());