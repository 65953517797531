(function () {
    var app = angular.module('phapApp');

    app.component('incidentEditDetails', {
        templateUrl: 'app/administration/incidents/incidentEditDetails.html',
        bindings: {
            incident: '<',
        },
        controller: ['$element', '$scope', function ($element, $scope) {
        }],
        controllerAs: 'incidentEditDetails'
    });

})();