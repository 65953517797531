(function () {

    var app = angular.module('phapApp');

    app.component('reportsHome', {
        templateUrl: 'app/reports/home.html',
        bindings: {
            mode: '@' //create/edit
        },
        controller: ['$routeParams', '$location', 'navigationService', 'notificationService', 'Constants', function ($routeParams, $location, navigationService, notificationService, Constants) {

            var vm = this;

            vm.reportId = $routeParams.id;

            vm.$onInit = function () {
                navigationService.getReportMenu().then(
                    function (data) {
                        var curReport = _.find(data, function (r) { return r.id == vm.reportId; });
                        setNavigationMenu(curReport);
                        loadTableauReport(curReport);
                    }
                )
            };

            var setNavigationMenu = function (report) {
                var leftMenuSubCode = 'report' + vm.reportId;
                if (report.type == Constants.reportTypes.financeManagement) {
                    navigationService.setCurrentMenuStatus(navigationService.topMenuCodes.financeManagement, navigationService.pageCodes.fmReportMenu, leftMenuSubCode);
                }
                else if (report.type == Constants.reportTypes.emergencyManagement) {
                    navigationService.setCurrentMenuStatus(navigationService.topMenuCodes.emergencyManagement, navigationService.pageCodes.emReportMenu, leftMenuSubCode);
                }
            }

            var loadTableauReport = function (report) {
                var containerDiv = document.getElementById("reportContainer");
                var url = report.url;
                var options = {
                    hideTabs: false,
                    hideToolbar: false,
                    showAppBanner: false,
                    showShareOptions: true,
                    width: '100%',
                    height: '100vh',
                    embed: 'yes',
                    customViews: 'yes',
                    display_count: 'no',
                    showVizHome: 'yes',
                    onFirstInteractive: function () {

                    },
                    onFirstVizSizeKnown: function (e) {

                    }
                };

                vm.viz = new tableau.Viz(containerDiv, url, options);
            }

        }],
        controllerAs: 'reports'
    });

})();