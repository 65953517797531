(function () {

    var app = angular.module('phapApp');

    app.component('principlePlaceOfResidence', {
        templateUrl: 'app/administration/configuration/principlePlaceOfResidence.html',
        bindings: {
            mode: '@'
        },
        controller: ['$scope', '$filter', 'utils', 'referenceService', 'security', function ($scope, $filter, utils, referenceService, security) {

            var vm = this;
            vm.gridApi = null;
            vm.originalDataSet = [];
            vm.historyUrl = "principleResidence/0";
            var getPrinciplePlaceOfResidenceList = function () {
                referenceService.getPrinciplePlaceOfResidence().then(
                    function (principleList) {
                        var list = [];
                        angular.forEach(principleList.data, function (value, key) {
                            this.push({ data: value, isUpdated: false, isAdded: false });
                        }, list);
                        vm.gridOptions.data = list;
                        vm.originalDataSet = angular.copy(vm.gridOptions.data);
                        $scope.$broadcast('displayLoading', false);
                    });
            }

            var init = function () {
                getSecurity();
            };
            var getSecurity = function () {
                $scope.$broadcast('displayLoading', true);
                //getting logged in user security
                security.getCurrentUserSecurityInfo()
                    .then(function (result) {
                        vm.gridOptions.privileges = result.privileges.UpdateReestablishAdmin;
                        vm.gridOptions.showPrivilegePanel = true;
                        if (vm.gridOptions.privileges)
                            getPrinciplePlaceOfResidenceList();
                    },
                    function () {
                        vm.gridOptions.privileges = false;
                    });
            };

            vm.gridOptions = {
                showPrivilegePanel : false,
                enableCellEdit: false,
                enableSorting: true,
                enableCellEditOnFocus: true,
                newObject: { data: { name: null, isDeleted: false, isReadOnly: false }, isUpdated: false, isAdded: true }
            };

            vm.gridOptions.columnDefs = [
                { name: 'data.name', displayName: 'Document Id type', enableCellEdit: true, enableColumnMenu: false, enableSorting: true },
                {
                    name: 'data.isDeleted', displayName: '', enableCellEdit: false, enableColumnMenu: false, width: 100, enableSorting: false,
                    cellClass: function (grid, row) {

                        if (row.entity.data.isReadOnly) {
                            return "grid-readonly-button";
                        }
                        return "grid-Delete-button";
                    },
                    cellTemplate: '<a ng-if="!row.entity.data.isReadOnly && !row.entity.data.isDeleted" title="Delete" ng-click="grid.appScope.deleteRow(row.entity)"><span class="glyphicon glyphicon-trash grid-Delete-button" ></span></a>' +
                    '<a ng-if="!row.entity.data.isReadOnly && row.entity.data.isDeleted" title="ReActivate" ng-click="grid.appScope.reActivate(row.entity)"><span class="glyphicon glyphicon-plus grid-Add-button" ></span></a>' +
                    '<span ng-if="row.entity.data.isReadOnly" title="Read only" class="glyphicon glyphicon-lock" ></span>'

                }
            ];

            vm.validation = function (row, colDef, currentData) {
                if (colDef.name === 'data.name') {
                    if (utils.isNullOrEmpty(row.name)) {
                        return { isValid: false, errors: ['Description required'] };
                    }
                    var isDuplicate = 0;
                    var index;
                    vm.gridOptions.data.forEach(function (value, i) {
                        if (row.name == value.data.name)
                            isDuplicate++;
                    });
                    if (isDuplicate > 1)
                        return { isValid: false, errors: ['Already exists'] };
                }
                return { isValid: true, error: '' }
            }

            vm.savePrinciplePlaceOfResidence = function (list, refreshAfterSave) {
                referenceService.savePrinciplePlaceOfResidence(list).then(
                    function (result) {
                        if (result.data.length > 0) {
                            getPrinciplePlaceOfResidenceList();
                            refreshAfterSave(result);
                        }

                    },
                    // Error
                    function (result) {
                        refreshAfterSave(result);
                    });
            }

            init();

        }],
        controllerAs: 'principlePlaceOfResidenceVm'
    });

})();