(function () {
    var app = angular.module('phapApp');

    app.component('glErrorDetails', {
        templateUrl: 'app/errorHandling/glDetails.html',

        controller: ['$scope', '$routeParams', '$timeout', '$cookies', '$uibModal', '$location', 'notificationService', 'navigationService', 'errorHandlingService', 'uiGridConstants', 'localStorageService', 'utils', '$interval', 'Constants', 'security', 'cacheService',
            function ($scope, $routeParams, $timeout, $cookies, $uibModal, $location, notificationService, navigationService, errorHandlingService, uiGridConstants, localStorageService, utils, $interval, Constants, security, cacheService) {
                navigationService.setCurrentMenuStatus(
                    'errorHandling', 'glErrorHandlingDetails');

                var vm = this;

                vm.status = Constants.TransactionStatus;
                vm.transactionType = Constants.TransactionTypeGL;
                vm.NoFilterProvided = false;

                vm.isDisabled = function () {
                    return true;
                };
                vm.back = function () {
                    $location.path("/errorHandling/gl");
                };
                vm.exportToCsv = function () {
                    vm.exporting = true;
                    vm.beforeSearch();
                    errorHandlingService.exportGLTransactionSearch(vm.formattedSearch).then(
                        function (response) {
                            vm.exporting = false;
                            var file = new Blob([response.data], { type: 'text/csv' });
                            saveAs(file, 'GLTransactionList.csv');
                        },
                        function (response) {
                            vm.exporting = false;
                        });
                };

                vm.exportToCsvGrid = function () {
                    vm.exporting = true;
                    vm.beforeSearch();
                    errorHandlingService.exportGLTransactionSearchDetails(vm.id).then(
                        function (response) {
                            vm.exporting = false;
                            var file = new Blob([response.data], { type: 'text/csv' });
                            saveAs(file, 'GLTransactionList.csv');
                        },
                        function (response) {
                            vm.exporting = false;
                        });
                };

                vm.setData = function (data) {
                    vm.results = data;
                    vm.gridOptions.data = angular.copy(vm.results);
                    vm.noResultsFound = vm.results.length == 0;
                };

                vm.beforeSearch = function () {
                    vm.formattedSearch = [];
                    vm.formattedSearch = angular.copy(vm.search);
                    if (vm.search.fromDate)
                        vm.formattedSearch.fromDate = vm.formattedSearch.fromDate.getFullYear() + '-' + (vm.formattedSearch.fromDate.getMonth() + 1) + '-' + vm.formattedSearch.fromDate.getDate();
                    if (vm.search.toDate)
                        vm.formattedSearch.toDate = vm.formattedSearch.toDate.getFullYear() + '-' + (vm.formattedSearch.toDate.getMonth() + 1) + '-' + vm.formattedSearch.toDate.getDate();
                };

                vm.getSearchResults = function () {
                    vm.loaded = false;
                    vm.NoFilterProvided = false;
                    vm.searching = true;
                    vm.beforeSearch();

                    if (vm.formattedSearch.transactionCode == null
                        && vm.formattedSearch.status == null
                        && vm.formattedSearch.fromDate == null
                        && vm.formattedSearch.toDate == null
                        && vm.formattedSearch.oracleDate == null
                        && (vm.formattedSearch.oraclePeriod == null || vm.formattedSearch.oraclePeriod.length == 0)
                        && (vm.formattedSearch.cardId == null || vm.formattedSearch.cardId.length == 0)
                        && (vm.formattedSearch.chequeNo == null || vm.formattedSearch.chequeNo.length == 0)
                        && (vm.formattedSearch.applicationNo == null || vm.formattedSearch.applicationNo.length == 0)
                        && (vm.formattedSearch.transactionId == null || vm.formattedSearch.transactionId.length == 0)
                        && (vm.formattedSearch.batchNumber == null || vm.formattedSearch.batchNumber.length == 0)) {

                        vm.NoFilterProvided = true;
                        vm.loaded = true;
                        vm.searching = false;
                        vm.results = [];
                        return;

                    }


                    vm.saveFilters();


                   
                    errorHandlingService.getGLTransactionSearch(vm.formattedSearch).then(
                        function (result) {
                            vm.loaded = true;
                            vm.searching = false;
                            vm.results = [];

                            vm.searchResultMaxLimit = Constants.MaximumSearchResultAllowed;

                            if (result.data.length > vm.searchResultMaxLimit) {
                                result.data.pop();
                                vm.setData(result.data);
                                vm.ExceedMaxRecords = true;
                            }
                            else {
                                vm.ExceedMaxRecords = false;
                                vm.setData(result.data);
                            }
                            
                        },
                        function (result) {
                            vm.loaded = true;
                            vm.searching = false;
                        }
                    );
                };

                vm.getDetails = function () {
                    errorHandlingService.getGLErrorDetails(vm.id).then(
                        function (result) {
                            vm.loaded = true;
                            vm.results = [];
                            vm.setData(result.data.transactions);
                        });
                };

                vm.resetGL = function () {
                    var model = {
                        BatchId: vm.details.batchId,
                        RowVersion: null
                    }
                    errorHandlingService.resetGLBatch(model).then(
                        function (response) {
                            utils.alert('Reset for GL batch completed. New transactions have been created and are queued for submission.');
                            $location.path('/errorHandling/gl/details/' + vm.id + '/');
                        });
                }

                vm.clearFilters = function () {

                    vm.NoFilterProvided = false;
                    vm.ExceedMaxRecords = false; 

                    vm.search = { includeArchived: true };
                    vm.dateOptionsTo.minDate = null;
                    vm.dateOptionsFrom.maxDate = new Date();
                    cacheService.removeItemFromCache('glDetailFilters.' + $cookies.get("UserId"));
                };

                vm.saveFilters = function () {
                    cacheService.saveToCache('glDetailFilters.' + $cookies.get("UserId"), vm.search);
                };

                vm.retrieveFilters = function () {
                    return cacheService.readFromCache('glDetailFilters.' + $cookies.get("UserId"));
                };

                vm.loadSavedFilters = function () {
                    var savedData = vm.retrieveFilters();
                    if (savedData) {
                        vm.search = savedData;
                    } else {
                        vm.clearFilters();
                    }
                }

                var getGridOptions = function () {
                    return {
                        fastWatch: true,
                        enableGridMenu: true,
                        enableSorting: true,
                        enableFiltering: false,
                        enableColumnResizing: true,
                        enableHorizontalScrollbar: uiGridConstants.scrollbars.ALWAYS,
                        enableVerticalScrollbar: uiGridConstants.scrollbars.ALWAYS,
                        enableRowSelection: true,
                        enableFullRowSelection: true,
                        enableRowHeaderSelection: false,
                        enableSelectAll: false,
                        multiSelect: false,
                        enableRowHashing: false,
                        columnDefs: getGridColumnDefs(),
                        appScopeProvider: {
                            openItem: function (row) {
                                openItem(row);
                            }
                        },
                        rowStyle: function (row) {
                            return 'status-' + row.entity.status + '-class';
                        },
                        rowTemplate:
                        '<div ng-click="grid.appScope.openItem(row.entity);" ng-class="grid.options.rowStyle(row)"> ' +
                        '<div class="ui-grid-cell" ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" ui-grid-cell ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader}" style="cursor:pointer"> ' +
                        '</div>' +
                        '</div>',
                        onRegisterApi: function (gridApi) {
                            vm.gridApi = gridApi;
                            vm.gridApi.core.on.rowsRendered($scope, rowsRendered);
                            vm.gridApi.colResizable.on.columnSizeChanged($scope, saveState);
                            vm.gridApi.core.on.columnVisibilityChanged($scope, saveState);
                            vm.gridApi.core.on.filterChanged($scope, saveState);
                            vm.gridApi.core.on.sortChanged($scope, saveState);
                            // call resize every 500 ms for 5 s after modal finishes opening - usually only necessary on a bootstrap modal
                            $interval(function () {
                                vm.gridApi.core.handleWindowResize();
                            }, 500, 10);
                            // Restore previously saved state.
                            restoreState();
                        }
                    }
                };

                function saveState() {
                    var state = vm.gridApi.saveState.save();
                    localStorageService.set('glDetails.gridState.' + $cookies.get("UserId"), state);
                }

                function restoreState() {
                    $timeout(function () {
                        var state = localStorageService.get('glDetails.gridState.' + $cookies.get("UserId"));
                        if (state) vm.gridApi.saveState.restore($scope, state);
                    });
                }

                var rowsRendered = function () {
                    vm.filteredRecords = vm.gridApi.core.getVisibleRows(vm.gridApi.grid).length;
                    if ($routeParams.orderNumber && vm.gridOptions.data.length > 0) {
                        vm.gridApi.selection.selectAllVisibleRows();
                    }
                    else {
                        vm.gridApi.selection.clearSelectedRows();
                    }
                };

                var getGridColumnDefs = function () {
                    return [
                        {
                            field: 'batchNumber',
                            displayName: 'Batch number',
                            visible: true,
                            enableHiding: false,
                            enableColumnMenu: false,
                            width: '12%'
                        },
                        {
                            field: 'replacementTransactionId',
                            displayName: 'Replacement transaction number',
                            visible: true,
                            enableHiding: false,
                            enableColumnMenu: false,
                            width: '12%'
                        },
                        {
                            field: 'rejectionReason',
                            displayName: 'Rejection reason',
                            enableHiding: true,
                            enableColumnMenu: false,
                            width: '10%'
                        },
                        {
                            field: 'applicationNo',
                            displayName: 'Application number',
                            visible: true,
                            enableHiding: false,
                            enableColumnMenu: false,
                            width: '10%'
                        },
                        {
                            field: 'oraclePeriod',
                            displayName: 'Oracle date',
                            enableHiding: true,
                            enableColumnMenu: false,
                            cellFilter: Constants.GridDateFormat,
                            width: '10%'
                        },
                        {
                            field: 'amountCr',
                            displayName: 'Amount credit',
                            cellFilter: 'currency',
                            cellClass: 'currency',
                            enableHiding: true,
                            enableColumnMenu: false,
                            width: '8%'
                        },
                        {
                            field: 'amountDr',
                            displayName: 'Amount debit',
                            cellFilter: 'currency',
                            cellClass: 'currency',
                            enableHiding: true,
                            enableColumnMenu: false,
                            width: '8%'
                        },
                        {
                            field: 'transactionType',
                            displayName: 'Transaction code',
                            enableHiding: true,
                            enableColumnMenu: false,
                            width: '10%'
                        },
                        {
                            field: 'status',
                            displayName: 'Status',
                            enableHiding: true,
                            enableColumnMenu: false,
                            width: '10%'
                        },
                        {
                            field: 'postedPeriod',
                            displayName: 'Posted period',
                            enableHiding: true,
                            enableColumnMenu: false,
                            width: '10%'
                        },

                    ]
                };

                var openItem = function (data) {
                    vm.showDetails = true;
                    vm.details = data;
                };

                vm.canReset = function () {
                    vm.cantReset = "";
                    var alreadyReset = true;
                    vm.results.forEach(function (item) {
                        if (item.rejectionReason && item.rejectionReason.length != 0 && item.status != "Replaced") {
                            alreadyReset = false;
                            vm.cantReset = "Cant reset as there is no error or this transaction has alredy been reset."
                        }
                    });
                    return alreadyReset && vm.hasResetPermission;
                };

                vm.setToMindate = function () {
                    vm.dateOptionsTo.minDate = new Date(vm.search.fromDate);
                };

                vm.setFromMaxdate = function () {
                    vm.dateOptionsFrom.maxDate = new Date(vm.search.toDate);
                };

                vm.dateOptionsFrom = {
                    maxDate: new Date(),
                    dateFormat: Constants.DatePickerFormat
                };

                vm.dateOptionsTo = {
                    maxDate: new Date(),
                    dateFormat: Constants.DatePickerFormat
                };

                vm.dateOptionsOracle = {
                    maxDate: new Date(),
                    dateFormat: Constants.DatePickerFormat
                };

                vm.checkId = function () {
                    vm.id = $routeParams.id;
                    if (vm.id) {
                        vm.canSearch = false;
                        vm.getDetails();
                    }
                    else {
                        vm.canSearch = true;
                        //vm.getSearchResults();
                    }
                };

                var checkPermission = function () {
                    security.getCurrentUserSecurityInfo().then(
                        function (result) {
                            vm.currentUserSecurityInfo = result;
                            vm.hasPermission = result.privileges.ViewPhapSystemMonitoring;
                            vm.hasResetPermission = result.privileges.EditPhapSystemMonitoring;
                            if (vm.hasPermission) {
                                vm.loadSavedFilters();
                            }
                        },
                        function () {
                            vm.currentUserSecurityInfo = {};
                        })
                };

                vm.$onInit = function () {
                    vm.loaded = true;
                    vm.searching = false;
                    vm.noResultsFound = false;
                    vm.exporting = false;
                    vm.hasPermission = true;
                    vm.showDetails = false;
                    vm.gridOptions = getGridOptions();
                    vm.gridOptions.data = [];
                    vm.results = [];
                    vm.search = { includeArchived: true };
                    vm.checkId();
                    checkPermission();
                };
            }],
        controllerAs: 'glDetailsVM'
    });
})();