(function() {
    angular.module('phapApp')
        .service('incidentService', ['$http', '$q', 'config', function($http, $q, config) {
            

            var loadIncident = function (id) {
                return $http.get(config.apiBaseUrl + "incidents/incident/" + id);
            };

			var getOpenIncidents = function () {
				return $http.get(config.apiBaseUrl + "incidents/get-open-incidents");
			};
            
            return {
				loadIncident: loadIncident,
				getOpenIncidents: getOpenIncidents
            };


        }]);
})();