(function () {
	angular.module('phapApp')
		.filter('rangeSearch', function () {
			return function (startsWith, endsWith, items) {
				if ((endsWith === undefined || endsWith.trim() == "") && (startsWith !== undefined && startsWith.trim() !== "") ) {
					return _.filter(items, function (i) {
						return i.serialNumber === startsWith;
					})
				}
				else if (endsWith && startsWith && endsWith.trim() !== "" && startsWith.trim() !== "") {
					return _.filter(items, function (i) {
						return parseFloat(i.serialNumber) >= parseFloat(startsWith) && parseFloat(i.serialNumber) <= parseFloat(endsWith);
					})
				}
				else {
					return items;
				}
			};
		});
})();



