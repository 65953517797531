(function () {
    var app = angular.module('phapApp');

    app.component('cardShipmentOrder', {
        templateUrl: 'app/financeManagement/cardShipment/cardShipmentOrder.html',
        bindings: {
            mode: '@'
        },
        controller: ['cardShipmentService', 'cardService', 'navigationService', '$routeParams', '$location', '$q', 'security', 'Constants', 'utils', 'uiGridConstants', '$window', '$rootScope', 'notificationService', '$interval', '$filter',
            function (cardShipmentService, cardService, navigationService, $routeParams, $location, $q, security, constants, utils, uiGridConstants, $window, $rootScope, notificationService, $interval, $filter) {
                var vm = this;
                vm.constants = constants;
                vm.usersInRegion = [];

                vm.tabStates = {
                    orderDetails: 1,
                    cardDetails: 2
                }

                if ($location.path().indexOf("financeManagement") > -1) {
                    navigationService.setCurrentMenuStatus(
                        navigationService.topMenuCodes.financeManagement,
                        navigationService.pageCodes.cardShipment2);
                } else {
                    navigationService.setCurrentMenuStatus(
                        navigationService.topMenuCodes.emergencyManagement,
                        navigationService.pageCodes.cardShipment);
                }

                vm.$onInit = function () {
                    vm.loaded = false;
                    vm.allPromises = [];

                    vm.isSaveSuccess = false;
                    vm.isSaving = false;
                    vm.userInfo = null;
                    vm.userRegions = null;
                    vm.regions = null;
                    vm.locations = null;
                    vm.toolTipWithdraw = null;

                    vm.tabState = vm.tabStates.orderDetails;

                    vm.cardGridOptions = getCardGridOptions();
                    vm.cardGridOptions.data = [];

                    vm.details = {
                        orderId: null,
                        shipmentNumber: null,
                        orderDate: null,
                        orderStatus: null,
                        requestedById: null,
                        createdBy: null,
                        urgency: null,
                        cardQuantity: null,
                        regionId: null,
                        location: null,
                        addressText: null,
                        recipient: null,
                        notes: null,
                        cardShipmentStaffId: null,
                        cardShipmentStaff: null,
                        courierName: null,
                        courierContactNumber: null,
                        shippingRefNumber: null,
                        cardShipmentDate: null,
                        TransportType: null
                    };

                    vm.allPromises.push(security.getAllUsers(false).then(
                        function (data) {
                            vm.userInfo = data;
                        }));

                    vm.allPromises.push(security.getActiveUser(false).then(
                        function (data) {
                            vm.details.createdBy = data.givenName + ' ' + data.surname;
                            vm.details.requestedById = data.id;
                            vm.currentUserLoggedIn = data;
                            vm.userRegions = data.regions;
                        }
                    ));

                    vm.allPromises.push(security.getCurrentUserSecurityInfo()
                        .then(function (result) {
                            vm.currentUserSecurityInfo = result;
                        }));

                    vm.allPromises.push(cardService.getRegionLocationRefData().then(
                        function (data) {
                            vm.regions = data.regions;
                            vm.locations = data.locations;
                        }));

                    $q.all(vm.allPromises).then(
                        function (result) {
                            if (vm.mode === constants.mode.create) {
                                vm.actionText = 'Create';
                                vm.details.orderDate = new Date();
                                vm.details.orderStatus = _.find(constants.cardShippingStatusNames, { id: constants.cardShippingStatus.new });
                                filterRegionsForUser();
                                vm.loaded = true;
                            }

                            if (vm.mode === constants.mode.edit) {
                                vm.actionText = 'Update';
                                vm.details.orderId = $routeParams.id;
                                vm.isSaveSuccess = $routeParams.fromCreate;
                                if (vm.details.orderId) {
                                    cardShipmentService.getCardShipmentOrderDetails(vm.details.orderId).then(
                                        function (data) {
                                            if (data) {
                                                loadCardShipmentOrderDetails(data);
                                                if (vm.isUserInRegion()) {
                                                    filterRegionsForUser();
                                                }
                                            }
                                            else {
                                                vm.errorMessage = "Card shipment order could not be found";
                                                vm.loaded = true;
                                            }
                                        });
                                }
                                else {
                                    vm.errorMessage = "Card shipment order Id is invalid";
                                }
                            }
                        });
                };

                vm.printOrder = function (divName) {
                    vm.printElement(document.getElementById("printableArea"));
                    window.print();
                }

                vm.viewHistory = function () {
                    $location.path("/administration/history/card-shipment/" + vm.details.orderId + "/" + vm.details.shipmentNumber);
                }

                vm.isWithdrawButtonDisabled = function () {
                    if (vm.details.orderStatus.id === vm.constants.cardShippingStatus.new) {
                        vm.toolTipWithdraw = "Withdraw this card shipment order.";
                        return false;
                    }

                    vm.toolTipWithdraw = "The order is being fulfilled and can no longer be withdrawn.";
                    return true;
                }

                vm.printElement = function (elem) {
                    var domClone = elem.cloneNode(true);
                    var $printSection = document.getElementById("printSection");
                    if (!$printSection) {
                        var $printSection = document.createElement("div");
                        $printSection.id = "printSection";
                        document.body.appendChild($printSection);
                    }
                    $printSection.innerHTML = "";
                    $printSection.appendChild(domClone);
                }

                vm.filterUsersByRegion = function (user) {
                    return _.some(user.regions, function (r) { return r.regionId === vm.details.regionId; });
                };

                vm.regionChanged = function () {
                    vm.details.location = null;
                    vm.details.recipient = null;
                };

                vm.updateAddress = function () {
                    if (vm.details.location) {
                        vm.details.addressText = vm.details.location.addressString;
                    }
                    else {
                        vm.details.addressText = null;
                    }
                };

                vm.cardQuantityValid = function () {
                    var amount = utils.parseDollarAmount(vm.details.cardQuantity);
                    var valid = amount > 0 && amount <= 200;

                    vm.form.cardQuantity.$invalid = !valid;
                    vm.form.cardQuantity.$error = !valid;
                    if (!valid) {
                        vm.form.cardQuantity.$error.minmax = true;
                    }
                    return valid;
                };

                vm.save = function () {
                    if (vm.form.$valid && !vm.form.cardQuantity.$error && !(vm.form.courierContactNumber && vm.form.courierContactNumber.$error === false)) {
                        if (vm.mode === constants.mode.create) {
                            create(vm.details);
                        } else {
                            update(vm.details);
                        }
                    }
                };

                vm.cancel = function () {
                    if ($rootScope && $rootScope.pageUrl) {
                        $location.path($rootScope.pageUrl);
                    }
                    else {
                        $location.path('/financeManagement/cardShipment/search');
                    }
                }

                vm.withdraw = function (csoModel) {
                    utils.confirm("Withdraw this card shipment order", "This order will be set to the Withdrawn status and can no longer be modified.  If additional cards are required a new order will have to be created.<br/><br/>Are you sure you want to withdraw this card shipment order ?", 'Withdraw', 'Cancel')
                        .then(function () {
                            vm.isSaving = true;
                            cardShipmentService.withdrawCardShipmentOrder(csoModel.details.orderId, csoModel.details.rowVersion)
                                .then(
                                function (response) {
                                    vm.isSaving = false;
                                    vm.isSaveSuccess = true;
                                    loadCardShipmentOrderDetails(response.data);
                                    vm.form.$setPristine();
                                }, function (error) {
                                    vm.isSaving = false;
                                    vm.isSaveSuccess = false;
                                    vm.errorMessage = "Failed to withdraw the order";
                                });
                        });
                };

                vm.receive = function () {
                    $location.path("/financeManagement/cards/receiveOrder/" + vm.details.orderId);
                }

                vm.assignLocation = function (csoModel) {
                    $location.path("/financeManagement/cards/cardStorage/" + csoModel.details.shipmentNumber);
                };

                vm.isUserInRegion = function () {
                    if (vm.details.regionId) {
                        return vm.currentUserSecurityInfo.userIsInRegion(getCentralRegionId(), vm.userRegions) ||
                            vm.currentUserSecurityInfo.userIsInRegion(vm.details.regionId, vm.userRegions);
                    }
                    return true;
                }

                vm.allocateCards = function () {
                    $location.path("/financeManagement/cards/cardAllocate/" + vm.details.orderId);
                }

                vm.clickTab = function (tabState) {
                    vm.tabState = tabState;
                }

                var getCentralRegionId = function () {
                    var centralLocation = _.find(vm.locations, function (l) { return l.locationType === vm.constants.locationType.centralHeadOffice; });
                    if (centralLocation) {
                        return centralLocation.regionId;
                    }
                    else {
                        return null;
                    }
                }

                var filterRegionsForUser = function () {
                    var userRegionIds = _.map(vm.userRegions, function (r) {
                        return r.regionId;
                    });
                    if (userRegionIds.indexOf(getCentralRegionId()) > -1) {
                        // Central region users are not filtered
                        return;
                    }
                    else {
                        vm.regions = _.filter(vm.regions, function (r) { return userRegionIds.indexOf(r.id) > -1; });
                        if (vm.regions.length === 1) {
                            vm.details.regionId = vm.regions[0].id;
                        }
                    }
                }

                var getCardGridColumnDefs = function () {
                    return [
                        {
                            field: 'id',
                            visible: false
                        },
                        {
                            tooltip: 'Card ID',
                            field: 'cardId',
                            displayName: 'Card ID',
                            enableHiding: false,
                            width: '13%',
                            enableColumnMenu: false
                        },
                        {
                            field: 'serialNumber',
                            displayName: 'Serial number',
                            type: 'number',
                            enableHiding: false,
                            width: '10%',
                            enableColumnMenu: false
                        },
                        {
                            field: 'expiryDate',
                            displayName: 'Expiry date',
                            enableHiding: false,
                            width: '10%',
                            enableColumnMenu: false,
                            cellClass: 'date-field',
                            cellFilter: constants.GridDateFormat,
                        },
                        {
                            field: 'cardStatus',
                            displayName: 'Card status',
                            enableHiding: false,
                            width: '10%',
                            enableColumnMenu: false
                        },
                        {
                            field: 'region',
                            displayName: 'Region',
                            enableHiding: false,
                            width: '14%',
                            enableColumnMenu: false
                        },
                        {
                            field: 'location',
                            displayName: 'Location',
                            enableHiding: false,
                            width: '20%',
                            enableColumnMenu: false
                        },
                        {
                            field: 'storageType',
                            displayName: 'Storage type',
                            enableHiding: false,
                            width: '12%',
                            enableColumnMenu: false
                        },
                        {
                            field: 'boxId',
                            displayName: 'Box ID',
                            enableHiding: false,
                            width: '11%',
                            enableColumnMenu: false
                        }
                    ]
                };

                var getCardGridOptions = function () {
                    return {
                        enableSorting: true,
                        enableFiltering: false,
                        enableHorizontalScrollbar: uiGridConstants.scrollbars.ALWAYS,
                        enableVerticalScrollbar: uiGridConstants.scrollbars.ALWAYS,
                        enableRowSelection: true,
                        enableFullRowSelection: false,
                        enableRowHeaderSelection: true,
                        enableSelectAll: false,
                        multiSelect: false,
                        enableRowHashing: false,
                        columnDefs: getCardGridColumnDefs(),
                        appScopeProvider: {
                            selectRow: function (row) {
                            }
                        },
                        rowTemplate:
                        '<div ng-click="grid.appScope.selectRow(row);"> ' +
                        '<div class="ui-grid-cell cursor-default" ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" ui-grid-cell ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader }"> ' +
                        '</div>' +
                        '</div>',
                        onRegisterApi: function (gridApi) {
                            vm.gridApi = gridApi;
                            // call resize every 500 ms for 5 s after modal finishes opening - usually only necessary on a bootstrap modal
                            $interval(function () {
                                vm.gridApi.core.handleWindowResize();
                            }, 500, 10);
                        }
                    }
                };

                function create(csoModel) {
                    vm.isSaving = true;
                    var data = mapCardShipment(csoModel, constants.mode.create);
                    cardShipmentService.createCardShipmentOrder(data)
                        .then(
                        function (response) {
                            vm.isSaving = false;
                            vm.form.$setPristine();
                            $location.path("/financeManagement/cardShipmentOrder/edit/" + response.data.id + "/true");
                        }, function (error) {
                            vm.isSaving = false;
                            vm.isSaveSuccess = false;
                            vm.errorMessage = "Failed to create card shipment order";
                        }
                        );
                }

                function update(csoModel) {
                    var data = mapCardShipment(csoModel, constants.mode.edit);
                    data.rowVersion = csoModel.rowVersion;
                    if (vm.details.orderStatus.id === vm.constants.cardShippingStatus.inProgress &&
                        vm.details.transportType) {
                        utils.confirm("Update card shipment details", "Once they are saved, the shipping details cannot be changed. Are you sure you want to continue?", "Confirm", "Cancel")
                            .then(function () {
                                vm.isSaving = true;
                                cardShipmentService.updateCardShipmentDetails(data)
                                    .then(
                                    function (response) {
                                        vm.isSaving = false;
                                        vm.isSaveSuccess = true;
                                        loadCardShipmentOrderDetails(response.data);
                                        vm.form.$setPristine();
                                    }, function (error) {
                                        vm.isSaving = false;
                                        vm.isSaveSuccess = false;
                                        vm.errorMessage = "Failed to update card shipment order";
                                    });
                            });
                    }
                    else {
                        vm.isSaving = true;
                        cardShipmentService.updateCardShipmentOrder(data)
                            .then(
                            function (response) {
                                vm.isSaving = false;
                                vm.isSaveSuccess = true;
                                loadCardShipmentOrderDetails(response.data);
                                vm.form.$setPristine();
                            }, function (error) {
                                vm.isSaving = false;
                                vm.isSaveSuccess = false;
                                vm.errorMessage = "Failed to update card shipment order";
                            }
                            );
                    }
                };

                function mapCardShipment(cso, mode) {
                    var data = {
                        rowVersion: cso.rowVersion,
                        orderDate: cso.orderDate,
                        orderStatus: mode === constants.mode.edit ? cso.orderStatus.id : constants.cardShippingStatus.new,
                        requestedById: cso.requestedById,
                        urgency: cso.urgency,
                        cardQuantity: cso.cardQuantity,
                        location: cso.location,
                        locationId: cso.location.id,
                        recipientId: cso.recipient.id,
                        notes: cso.notes
                    };
                    if (mode === constants.mode.edit) {
                        data.id = cso.orderId;
                    }
                    if (vm.details.orderStatus.id === vm.constants.cardShippingStatus.inProgress ||
                        vm.details.orderStatus.id === vm.constants.cardShippingStatus.inTransit) {
                        if (vm.details.transportType === constants.TransportType.courier) {
                            data.courier = {
                                orderDate: cso.orderDate,
                                name: cso.courierName,
                                contactNumber: cso.courierContactNumber,
                                shipmentReferenceNumber: cso.shippingRefNumber,
                            };
                        }
                        else if (vm.details.transportType === constants.TransportType.staff) {
                            data.cardShipmentStaffId = cso.cardShipmentStaff.id;
                        }
                        //data.cardShipmentDate = $filter('date')(cso.cardShipmentDate, constants.DatePickerFormat);
                        data.TransportType = vm.details.transportType;
                    }
                    return data;
                };

                function getUsersPrivilegesBasedOnRegion(privilege) {
                    security.getAllUsersWithPrivelegeByRegionId(parseFloat(vm.details.regionId),
                        privilege)
                        .then(function (result) {
                            vm.usersInRegion = result;
                            vm.loaded = true;
                        })
                }

                function loadCardShipmentOrderDetails(csoModel) {
                    // TODO : FM : We are copying properties into a model and then we map them back again.
                    //  1/2 of the copying is done here and the other half is done in the service
                    //
                    //  Perhaps it would be better to avoid this pattern and to create local versions of
                    //  items that need local changes, and do the smaller mapping (when required in the
                    //  component responsible for viewing/updating it)
                    vm.details.rowVersion = csoModel.rowVersion;
                    vm.details.orderId = csoModel.id;
                    vm.details.shipmentNumber = csoModel.shipmentNumber;
                    vm.details.orderDate = csoModel.orderDate;
                    vm.details.orderStatus = _.find(constants.cardShippingStatusNames, { id: csoModel.orderStatus });
                    vm.details.requestedById = csoModel.requestedById;
                    vm.details.assignedToId = csoModel.assignedToId;
                    vm.details.assignedTo = csoModel.assignedTo;
                    vm.details.createdBy = csoModel.createdByUser.givenName + ' ' + csoModel.createdByUser.surname;
                    vm.details.transportType = csoModel.transportType;
                    vm.details.cardShipmentStaff = csoModel.cardShipmentStaff ? csoModel.cardShipmentStaff : vm.currentUserLoggedIn;
                    vm.details.courierContactNumber = csoModel.courier ? csoModel.courier.contactNumber : null;
                    vm.details.cardShipmentDate = csoModel.cardShipmentDate ? new Date(csoModel.cardShipmentDate) : new Date();
                    vm.details.shippingRefNumber = csoModel.courier ? csoModel.courier.shipmentReferenceNumber : null;
                    vm.details.courierName = csoModel.courier ? csoModel.courier.name : null;
                    vm.details.urgency = csoModel.urgency;
                    vm.details.cardQuantity = csoModel.cardQuantity;
                    vm.details.regionId = csoModel.location.regionId;
                    vm.details.location = _.find(vm.locations, { id: csoModel.location.id });
                    vm.details.recipient = _.find(vm.userInfo, { id: csoModel.recipient.id });
                    vm.details.notes = csoModel.notes;

                    vm.cardGridOptions.data = csoModel.cards;
                    vm.updateAddress();
                    vm.cardQuantityValid();

                    if (vm.details.orderStatus.id > vm.constants.cardShippingStatus.new) {
                        getUsersPrivilegesBasedOnRegion(constants.Privileges.FulfillShipmentOrder);
                    }
                    else {
                        vm.loaded = true;
                    }
                }
            }],
        controllerAs: 'cso'
    });
})();