(function () {

    var app = angular.module('phapApp');

    app.controller('createEditRoleCtrl', ['$scope', 'Constants', 'utils', 'isAdd', 'data',
        function ($scope, constants, utils, isAdd, data) {
            var vm = this;
            vm.title = isAdd ? 'Add new role' : 'Edit role';
            vm.role = data.role;
            vm.existingRoles = data.existingRoles;

            var validateNameExist=function() {
                vm.errors = [];
                var errorMessage = "Role name exist. Please enter unique name";
                angular.forEach(vm.existingRoles,
                    function(role) {
                        if (role.id !== vm.role.id && role.name.toLowerCase() === vm.role.name.toLowerCase()) {
                            vm.errors.push({
                                message: errorMessage
                            });
                        }
                    });
                    
                return vm.errors.length===0;
            }
            vm.applyChanges = function () {
                if (!$scope.role.form.$invalid && validateNameExist()) {
                    $scope.$close(vm.role);
                }
               
            }
            vm.close = function () {
                $scope.$dismiss({ $value: 'cancel' });
            }

        }
    ]);

})();
