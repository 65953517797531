(function () {
	var app = angular.module('phapApp');

	app.service('cardStorageService', ['$http', '$q', 'config', 'security', 'Constants', function ($http, $q, config, security, Constants) {

		var searchCards = function (search) {
			var deferred = $q.defer();

			var result = $http.post(config.apiBaseUrl + "card-storage/search/", search)
				.then(function (data, status, headers, config) {
					deferred.resolve(data);
				}, function (data, status, headers, config) {
					deferred.reject(data);
				});

			return deferred.promise;
		}
		var getLocationRefData = function () {
			var deferred = $q.defer();

			var res = $http.get(config.apiBaseUrl + 'card-storage/ref-data')
				.then(function (response, status, headers, config) {
					deferred.resolve(response.data);
				},
				function (error, status, headers, config) {
					deferred.reject(data);
				});

			return deferred.promise;
		}
		var updateLocation = function (data) {
			var deferred = $q.defer();
			var res = $http.post(config.apiBaseUrl + 'card-storage/update-storage-location', data)
				.then(function (response, status, headers, config) {
					deferred.resolve(response.data);
				},
				function (error, status, headers, config) {
					deferred.reject(data);
				});

			return deferred.promise;
		}

		return {
			searchCards: searchCards,
			getLocationRefData: getLocationRefData,
			updateLocation: updateLocation
		}
	}]);
})();