(function () {
    var app = angular.module('phapApp');

    app.controller('vendorSearchPopupCtrl', ['$scope', function ($scope) {
        //Setting the vendor name filter provided from the page which invoked this popup
        $scope.vendorSearchFilters = {
            vendornamefilter: $scope.vendorNameSearchText
        };

        //Setting payment details from online app
        if (!$scope.$parent.$isReestablish) {
            $scope.preferedPaymentDetails = {
                accountName: $scope.paymentPreferences.accountName,
                bsb: $scope.paymentPreferences.bsb,
                accountNumber: $scope.paymentPreferences.accountNumber,
                vendorName: $scope.paymentPreferences.vendorName,
                addressLine: $scope.item.applicant.contactDetails.address.unitNo
                    ? $scope.item.applicant.contactDetails.address.unitNo + " " : ""
                    + $scope.item.applicant.contactDetails.address.streetNo + " "
                    + $scope.item.applicant.contactDetails.address.streetName,
                suburb: $scope.item.applicant.contactDetails.address.suburb,
                state: $scope.item.applicant.contactDetails.address.state,
                postcode: $scope.item.applicant.contactDetails.address.postcode
            };
        }
        else {
            $scope.preferedPaymentDetails = null;
        }
        

        $scope.closeVendorCrudScreen = function () {
            $scope.displayVendorCrud = false;
        }

        $scope.updateSelectedVendor = function (id) {
            $scope.mode = "edit";
            $scope.vendorId = id;
            $scope.displayVendorCrud = true;
        }

        $scope.createNewVendor = function () {
            $scope.mode = "create";
            $scope.displayVendorCrud = true;
        }
        //close popup
        $scope.closeVendorSearchPopup = function () {
            $scope.$close();
            // This is necessary so IE retains scrolling after the popup closes
            $('body').css('overflow', 'inherit');
        }
        //apply vendor to direct deposit
        $scope.copySelectedVendorDetails = function (vendor) {
            $scope.$parent.selectedVendor = vendor;
            $scope.$close(vendor);
            // This is necessary so IE retains scrolling after the popup closes
            $('body').css('overflow', 'inherit');
        }
    }]);

})();