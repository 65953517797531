(function () {
    var app = angular.module('phapApp');
    app.component('cardStocktakeSearch', {
        templateUrl: 'app/financeManagement/cards/cardStocktakeSearch.html',
        bindings: {
        },
        controller: ['$routeParams', '$location', '$scope', 'uiGridConstants', 'cardService', 'localStorageService', 'Constants', '$cookies', '$timeout', 'security', 'cacheService', '$q', 'utils', 'navigationService', 'notificationService', '$interval',
            function ($routeParams, $location, $scope, uiGridConstants, cardService, localStorageService, constants, $cookies, $timeout, security, cacheService, $q, utils, navigationService, notificationService, $interval) {
                var vm = this;
                vm.hasPermission = false;

                navigationService.setCurrentMenuStatus(navigationService.topMenuCodes.financeManagement, navigationService.pageCodes.cardStocktake);

                vm.$onInit = function () {
                    vm.constants = constants;
                    vm.loaded = false;
                    vm.firstSearch = undefined;
                    vm.searching = false;
                    vm.exporting = false;
                    vm.allPromises = [];
                    vm.filterVisible = true;

                    navigationService.setCurrentMenuStatus(
                        navigationService.topMenuCodes.financeManagement,
                        navigationService.pageCodes.cardStocktakeSearch);


                    vm.gridOptions = getGridOptions();
                    vm.gridOptions.data = [];
                    vm.refdata = {
                        locations: null,
                        statuses: constants.cardStocktakeStatusDisplay
                    }

                    vm.initSearchParms();

                    vm.allPromises.push(
                        security.getCurrentUserSecurityInfo().then(
                            function (result) {
                                vm.currentUserSecurityInfo = result;
                                vm.hasPermission = result.privileges.CardStocktake;
                            },
                            function () {
                                vm.currentUserSecurityInfo = {};
                            })
                    );
                    vm.allPromises.push(
                        security.getLocationsForCurrentUser().then(
                            function (data) {
                                vm.refdata.locations = data.locations;
                            },
                            function () {
                                vm.refdata.locations = {};
                            }
                        )
                    );
                    $q.all(vm.allPromises).then(
                        function (result) {
                            vm.loadSavedFilters();
                            vm.loaded = true;
                        });
                };

                vm.initSearchParms = function () {

                    vm.search = {
                        cardStocktakeNumber: null,
                        location: null,
                        stocktakeStartDate: null,
                        stocktakeEndDate: null,
                        status: null
                    };

                    vm.totalSearchResults = 0;
                    vm.noResultsFound = false;
                };

                vm.checkStocktakeDates = function () {
                    var stocktakeStartDate = null;
                    var stocktakeEndDate = null;
                    vm.searchForm.stocktakeEndDate.$setValidity('greaterThanStocktakeStartDate', true);
                    vm.searchForm.stocktakeStartDate.$setValidity('greaterThanStocktakeStartDate', true);
                    if (vm.search.stocktakeEndDate) {
                        stocktakeStartDate = moment(vm.search.stocktakeStartDate);
                    }
                    if (vm.search.stocktakeEndDate) {
                        stocktakeEndDate = moment(vm.search.stocktakeEndDate);
                        if (!stocktakeStartDate || moment(stocktakeEndDate, 'YYYY-MM-DD').isBefore(stocktakeStartDate)) {
                            vm.searchForm.stocktakeEndDate.$setValidity('greaterThanStocktakeStartDate', false);
                        }
                    }
                }

                vm.exportToCsv = function () {
                    vm.exporting = true;
                    var savedData = vm.retrieveFilters();
                    //have to use saved data to get the data that is contained in the data grid otherwise it
                    //will get the data that is on the screen
                    if (savedData) {
                        vm.search = savedData;
                        cardService.stocktakeExportSearch(getSearchParams()).then(
                            function (response) {
                                vm.exporting = false;
                                var file = new Blob([response.data], { type: 'text/csv' });
                                saveAs(file, 'CardStocktakelist.csv');
                            },
                            function (response) {
                                vm.exporting = false;
                            });
                    }
                };

                vm.getSearchResults = function () {

                    vm.searching = true;
                    vm.gridOptions.data = [];
                    vm.gridApi.selection.clearSelectedRows();

                    vm.saveFilters();

                    cardService.stocktakeSearch(getSearchParams()).then(
                        function (response) {
                            vm.totalSearchResults = response.data.length;
                            vm.noResultsFound = vm.totalSearchResults == 0;
                            vm.gridOptions.data = response.data;
                            vm.searching = false;
                        },
                        function (error) {
                            vm.searching = false;
                        }
                    );
                };

                vm.newStocktake = function () {
                    $location.path('/financeManagement/cards/stocktake/create');
                }

                var openItem = function (row, event) {
                    var shouldSelect = event.target.attributes['data-select-row'];
                    if (!shouldSelect || shouldSelect.value === 'true') {
                        $location.path("/financeManagement/cards/stocktake/view/" + row.entity.id);
                    }
                };

                var openStocktakeReport = function (row) {
                    cardService.getStocktakeReconciliationReport(row.entity.id)
                        .then(function (data) {
                            var file = new Blob([data], { type: 'text/csv' });
                            var timeStamp = moment().format(constants.fileTimeStampFormat);
                            saveAs(file, 'CardStocktakeReconciliation_' + timeStamp + '.csv');
                        });
                }

                var getGridColumnDefs = function () {
                    return [
                        {
                            field: 'id',
                            visible: false,
                            enableHiding: false
                        },
                        {
                            field: 'cardStocktakeNumber',
                            displayName: 'Stocktake number',
                            enableHiding: false,
                            width: '12%',
                            enableColumnMenu: false
                        },
                        {
                            field: 'location',
                            displayName: 'Location',
                            enableHiding: false,
                            width: '15%',
                            enableColumnMenu: false
                        },
                        {
                            field: 'stocktakeDate',
                            displayName: 'Stocktake date',
                            enableHiding: false,
                            width: '11%',
                            enableColumnMenu: false,
                            cellClass: 'date-field',
                            cellFilter: constants.GridDateFormat
                        },
                        {
                            field: 'status',
                            displayName: 'Status',
                            enableHiding: false,
                            width: '11%',
                            enableColumnMenu: false
                        },
                        {
                            field: 'noCardsInStock',
                            displayName: 'Cards in stock',
                            enableHiding: false,
                            width: '10%',
                            enableColumnMenu: false,
                            type: 'number'
                        },
                        {
                            field: 'noCardsMissing',
                            displayName: 'Cards missing',
                            enableHiding: false,
                            width: '10%',
                            enableColumnMenu: false,
                            type: 'number'
                        },
                        {
                            field: 'reconciliationReport',
                            displayName: 'Reconciliation report',
                            enableHiding: false,
                            enableSorting: false,
                            width: '13%',
                            enableColumnMenu: false,
                            cellTemplate: '<a id="{{row.entity.id}}-rec-report" href="" data-select-row="false" ng-click="grid.appScope.openStocktakeReport(row)" >Download</a>'
                        },
                        {
                            field: 'notes',
                            displayName: 'Notes',
                            enableHiding: false,
                            width: '30%',
                            enableColumnMenu: false,
                            cellTemplate: '<div class="ui-grid-cell-contents" title="{{COL_FIELD}}">{{ COL_FIELD }}</div>'
                        }
                    ]
                };

                var getGridOptions = function () {
                    return {
                        enableGridMenu: false,
                        enableSorting: true,
                        enableFiltering: false,
                        enableColumnResizing: true,
                        enableHorizontalScrollbar: uiGridConstants.scrollbars.ALWAYS,
                        enableVerticalScrollbar: uiGridConstants.scrollbars.ALWAYS,
                        enableRowSelection: false,
                        enableFullRowSelection: false,
                        enableRowHeaderSelection: false,
                        enableSelectAll: false,
                        multiSelect: false,
                        enableRowHashing: false,
                        columnDefs: getGridColumnDefs(),
                        appScopeProvider: {
                            openItem: function (row, event) {
                                openItem(row, event);
                            },
                            openStocktakeReport: function (row) {
                                openStocktakeReport(row);
                            }
                        },
                        rowTemplate:
                        '<div ng-click="grid.appScope.openItem(row, $event);"> ' +
                        '<div class="ui-grid-cell" ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" ui-grid-cell ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader }" style="cursor:pointer"> ' +
                        '</div>' +
                        '</div>',
                        onRegisterApi: function (gridApi) {
                            vm.gridApi = gridApi;
                            vm.gridApi.colResizable.on.columnSizeChanged($scope, saveState);
                            vm.gridApi.core.on.columnVisibilityChanged($scope, saveState);
                            vm.gridApi.core.on.filterChanged($scope, saveState);
                            vm.gridApi.core.on.sortChanged($scope, saveState);
                            // call resize every 500 ms for 5 s after modal finishes opening - usually only necessary on a bootstrap modal
                            $interval(function () {
                                vm.gridApi.core.handleWindowResize();
                            }, 500, 10);
                            // Restore previously saved state.
                            restoreState();
                        }
                    }
                };

                function saveState() {
                    var state = vm.gridApi.saveState.save();
                    localStorageService.set('stocktakeSearch.gridState.' + $cookies.get("UserId"), state);
                }

                function restoreState() {
                    $timeout(function () {
                        var state = localStorageService.get('stocktakeSearch.gridState.' + $cookies.get("UserId"));
                        if (state) vm.gridApi.saveState.restore($scope, state);
                    });
                }

                vm.clearFilters = function () {
                    vm.initSearchParms();
                    vm.gridOptions.data = [];
                    if (vm.gridApi && vm.gridApi.selection) {
                        vm.gridApi.selection.clearSelectedRows();
                    }

                    vm.filterVisible = true;
                    cacheService.removeItemFromCache('stocktakeSearchFilters.' + $cookies.get("UserId"));
                };

                vm.saveFilters = function () {
                    cacheService.saveToCache('stocktakeSearchFilters.' + $cookies.get("UserId"), vm.search);
                };

                vm.retrieveFilters = function () {
                    return cacheService.readFromCache('stocktakeSearchFilters.' + $cookies.get("UserId"));
                };

                vm.loadSavedFilters = function () {
                    var savedData = vm.retrieveFilters();
                    if (savedData) {
                        vm.search = savedData;
                    } else {
                        vm.clearFilters();
                    }
                }
                var getSearchParams = function () {

                    return {
                        cardStocktakeNumber: vm.search.cardStocktakeNumber,
                        locationId: vm.search.location ? vm.search.location.id : null,
                        fromDate: vm.search.stocktakeStartDate,
                        toDate: vm.search.stocktakeEndDate,
                        status: vm.search.status === null ? 0 : vm.search.status
                    }
                }
            }
        ],
        controllerAs: 'cst'
    });
})();