(function () {
    angular
        .module('phapApp')
        .service('shared', ['$http', '$q', 'config', function ($http, $q, config) {
            return {
                reliefControllerSharedBiz: reliefControllerBase
            };
        }]);

    function reliefControllerBase($scope, $location, $timeout, uiGridConstants, route, $routeParams, $http, $q, config, security,
        $uibModal, $document, $compile, lgaService, incidentService, utils, Constants, shared, searchMemberService, bizService, navigationService, notificationService) {

        $scope.$bizService = bizService;

        initializeScope();

        var allSetupPromises = [];

        if ($routeParams.incidentId) {
            allSetupPromises.push(
                incidentService.loadIncident($routeParams.incidentId)
                    .then(
                        function (result) {
                            $scope.item.incident = result.data;
                            $scope.item.incidentId = result.data.id;
                        }
                    )
            );
        }

        allSetupPromises.push(bizService.getLga()
            .then(function (result) {
                $scope.lgas = result.data;
            })
        );

        $scope.validateDob = function (prefix, fieldName) {
            if (prefix == null) return null;
            var combinedName = prefix + fieldName;
            var field = $scope.frm[combinedName];
            if (!field) {
                throw "frm does not contain a key '" + combinedName + "'";
            }
            return _.keys(field.$error).length;
        };


        // load ref data
        allSetupPromises.push(bizService.loadRefData()
            .then(function (result) {
                // Broadcast the fact that the reference data has now loaded, this will be picked
                // up in the applicable controllers (relief vs reestablish)
                $scope.$broadcast('refDataLoaded', result, result.data);
            })
        );

        // load security
        allSetupPromises.push(
            security.getCurrentUserSecurityInfo()
                .then(function (result) {
                    $scope.currentUserSecurityInfo = result;
                },
                    function () {
                        $scope.currentUserSecurityInfo = {};
                    })
        );

        // active user
        allSetupPromises.push(
            security.getActiveUser()
                .then(function (result) {
                    $scope.activeUser = result;
                },
                    function () {
                        $scope.activeUser = {};
                    })
        );

        // load Data
        if ($scope.id) {
            allSetupPromises.push(
                bizService.loadData($scope.id, $scope.cloned)
                    .then(
                        function (result) {
                            $scope.item = result.data;
                            $scope.itemLoadedProcessing();

                            if ($scope.cloned) {
                                $scope.frm.$setDirty();
                            }
                        },
                        function () {
                            $scope.item = newDataObj();
                            $scope.state.legalCollapse = false;
                        })
            );
        } else {
            $scope.item = newDataObj($scope.item);
        }

        // after all loaded
        $q.all(allSetupPromises)
            .then(
                function (result) {
                    $scope.loaded = true;

                    if ($scope.id > 0 && $scope.item) {
                        formalizeScope();
                    }

                    if ($scope.activeUser) {
                        $scope.item.assessingOfficerUser = $scope.item.assessingOfficerUser || $scope.activeUser;
                        $scope.item.assessingOfficer = $scope.item.assessingOfficer || $scope.activeUser.id;
                    }

                    // form: incident is NOT closed, user has access to this division, user has privileges                        
                    $scope.userHasAccessToUpdate = $scope.updateAllowed();

                    // clear form $dirty state
                    $scope.frm.$setPristine();
                });

        $scope.updateAllowed = function () {
            if (bizService) {
                return bizService.updateAllowed($scope);
            }
        };

        $scope.$watch('userHasAccessToUpdate', function (nv, ov) {
            // If the new value is not equal to the new value
            if (nv != ov) {
                // Run a jquery query (oh no!) that will add or remove the "disabled" attribute from the following set of
                // elements:
                //    <input> inside <form>
                //    <button> inside <form>
                //    <select> inside <form>
                // where
                //    the element doesn't have id #rbAccept
                //    the element doesn't have attribute back-button
                //    (new and hot for 2021) the element doesn't have the attribute exclude-from-jquery-based-disabled-mechanism
                angular
                    .element('form input, form button, form select')
                    .not('[back-button], #rbAccept, [exclude-from-jquery-based-disabled-mechanism]')
                    .prop('disabled', !nv);
            }
        });

        $scope.recalculateFormEligibility = function () {
            $scope.item.$ineligible = !bizService.isEligible($scope.item);
        };

        notificationService.subscribe($scope, 'pageState', function (event, value) {
            $scope.pageState = value.pageState;
        })

        var shouldLetUserAddNewMember = function () {
            if ($scope.item === undefined || $scope.item.state === undefined) {
                return false;
            }

            if ($scope.item.state === Constants.ReliefApplicationState.Draft ||
                $scope.item.state === Constants.ReliefApplicationState.ClientDraft ||
                $scope.item.state === Constants.ReliefApplicationState.Submitted ||
                $scope.item.state === Constants.ReliefApplicationState.PendingAssessment
            ) {
                return true;
            }

            if ($scope.item.state === Constants.ReliefApplicationState.Eligible) {
                return true;
            }

            return false;
        };

        $scope.MemberAddressTitle = function (member) {
            if (member === undefined || member.givenName === undefined || member.surname === undefined)
                return "Contact address";
            return "Contact address for " + member.givenName + " " + member.surname;
        }

        $scope.IsIneligibleOrWithdrawn = function () {
            var rtn =
                $scope.item.state === Constants.ReliefApplicationState.Ineligible ||
                $scope.item.state === Constants.ReliefApplicationState.Withdrawn;
            return rtn;
        }

        $scope.IsNotEditable = function () {
            var rtn =
                $scope.item.state === Constants.ReliefApplicationState.Ineligible ||
                $scope.item.state === Constants.ReliefApplicationState.Withdrawn ||
                $scope.item.state === Constants.ReliefApplicationState.Active ||
                $scope.item.state === Constants.ReliefApplicationState.Closed ||
                $scope.item.incident === null;

            return rtn;
        }

        //Added as part of upload button changes.
        $scope.IsUploadNotEditable = function () {
            var rtn =
                $scope.item.state === Constants.ReliefApplicationState.Closed ||
                $scope.item.state === Constants.ReliefApplicationState.Ineligible;
            return rtn;
        }

        $scope.IsACloneThatShouldRestrictEditability = function () {
            var rtn = $scope.item &&
                $scope.item.parentReliefId > 0 &&
                // There are old ways of cloning applications (e.g. "Additional payment" button). There's also a
                // newer way, introduced in Nov 2022, which allows the user to start with an Ineligible relief
                // application & create a new application with the same details so that it can be re-assessed.
                // In the case of the old type of cloned application, we want to lock down editability and/or display
                // of some fields (address is not editable, and the "Has any person applying received a relief payment"
                // and "Have the people applying been affected" sections are not shown at all).
                // But for the new type of cloned application, we want to allow the user to see and modify all the
                // relief application data so that they can correct any errors that may have led to the application
                // being saved as Ineligible.
                $scope.item.parentState !== Constants.ReliefApplicationState.Ineligible;
            return rtn;
        }

        $scope.canApplyPayments = function () {
            var rtn =
                $scope.item.state === Constants.ReliefApplicationState.Eligible ||
                $scope.item.state === Constants.ReliefApplicationState.Active;
            return rtn;
        }

        $scope.canUpdateBankDetails = function () {
            var rtn =
                $scope.item.state === Constants.ReliefApplicationState.Eligible ||
                $scope.item.state === Constants.ReliefApplicationState.Active ||
                $scope.item.state === Constants.ReliefApplicationState.Closed;
            return rtn;
        }

        $scope.IsIneligible = function () {
            var rtn =
                $scope.item.state === Constants.ReliefApplicationState.Ineligible;
            return rtn;
        }

        $scope.itemLoadedProcessing = function () {

            $scope.state.legalCollapse = $scope.item.iAccept;

            $scope.item.displayedState = getStateName($scope.item.state);
            $scope.item.$ineligible = $scope.IsIneligibleOrWithdrawn();

            $scope.canAddNewMember = shouldLetUserAddNewMember();

            // Member Validation: If loading an existing application save givenName, surname, dob returned from the database for all app members
            if ($scope.item.applicant) {
                $scope.item.applicant.savedGivenName = $scope.item.applicant.givenName;
                $scope.item.applicant.savedSurname = $scope.item.applicant.surname;
                $scope.item.applicant.savedDob = $scope.item.applicant.dob;
            }
            if ($scope.item.householdMembers && $scope.item.householdMembers.length > 0) {
                _.each($scope.item.householdMembers,
                    function (m) {
                        m.savedGivenName = m.givenName;
                        m.savedSurname = m.surname;
                        m.savedDob = m.dob;
                    });
            }

            if ($scope.item.eligibilityValidationResults) {
                // Re-organise eligibilityValidationResults into a structure that's simpler to render
                var r = {};

                _.each($scope.item.eligibilityValidationResults, function (issue) {
                    _.each(issue.memberNames, function (memberName) {
                        // Last in wins
                        r[memberName] = issue.errorMessage;
                    });
                });

                $scope.item.eligibilityValidationResults = r;
            }

            // applicant is child, household member is not allowed 
            if ($scope.item.applicant)
                oldApplicantAge = $scope.item.applicant.dob;
            setHouseholdMembersVisibility();

            // Broadcase the dataLoaded event
            $scope.$broadcast('dataLoaded');
        }

        $scope.onMemberChangeNotifier = function (member, type) {

            if ($scope.item && member == $scope.item.applicant && type == 'dob') {
                validateChild();
            }

            // Broadcast this change for any interested area to process.
            $scope.$broadcast('onMemberChangeNotification', member, type);
        };

        var setHouseholdMembersVisibility = function () {
            $scope.showHouseholderMembers = true;
            if (!$scope.item) {
                return;
            }

            if ($scope.IsNotEditable() && $scope.item.householdMembers.length === 0) {
                $scope.showHouseholderMembers = false;
            } else {
                var asofDate = $scope.item.dateCreated ? moment($scope.item.dateCreated) : moment();

                if ($scope.item.applicant && $scope.item.applicant.dob) {
                }

                if ($scope.item.applicant && $scope.item.applicant.dob && utils.getAge($scope.item.applicant.dob, asofDate) < 18) {
                    $scope.showHouseholderMembers = false;
                }
            }
        };

        var oldApplicantAge;
        var validateChild = function () {
            if ($scope.item.applicant.dob) {
                var asofDate = $scope.item.dateCreated ? moment($scope.item.dateCreated) : moment();
                var age2 = utils.getAge($scope.item.applicant.dob, asofDate);
                if (age2 != null && age2 < 18 && !(oldApplicantAge < 18)) {
                    var msg = '<p>The applicant is under 18 years of age (' + age2 + '), based on the Date of birth provided.<p>';
                    msg += "<p><strong>Note: The applicant is under 18 years of age and no other person is allowed to be associated with this application.</strong></p>";
                    utils.confirm('Applicant', msg, 'Proceed', 'Cancel').then(function () {
                        oldApplicantAge = age2;
                        if ($scope.item.householdMembers) {
                            $scope.item.householdMembers.length = 0;
                        }
                    }, function () {
                        $scope.item.applicant.dob = oldApplicantAge;
                        setHouseholdMembersVisibility();
                    });
                } else {
                    oldApplicantAge = age2;
                }
            }

            setHouseholdMembersVisibility();
        };

        //#region saving

        var saveDataCore = function (saveFunc, beforeSavingDataFunc) {
            var deferred = $q.defer();

            $scope.beforeSavingData();

            var data = angular.copy($scope.item);
            if (beforeSavingDataFunc) {
                beforeSavingDataFunc(data);
            }

            saveFunc(data)
                .then(function (result) {
                    $scope.afterSavingData(result);
                    deferred.resolve(result);
                },
                    function (result) {
                        $scope.state.submitting = false;
                        utils.scrollToBottom();
                        // server side error
                        $scope.updateErrorsBasedOnSaveResult($scope, result);
                        deferred.reject(result);
                    });

            return deferred.promise;
        };

        $scope.beforeSavingData = function () {
            $scope.state.submitting = true;
            $scope.state.showSavedMessage = false;
            angular.element('.invalid').removeClass('invalid');
        };

        $scope.afterSavingData = function (result) {
            $scope.state.submitting = false;
            $scope.state.showSavedMessage = true;
            angular.copy(result.data, $scope.item);


            $scope.itemLoadedProcessing();

            formalizeScope();
            $scope.frm.$setPristine();
            utils.clearErrors($scope);

            if ($scope.state.showSavedMessage == true || $scope.fromCreate == undefined) {
                utils.alert("The application has been successfully saved.");
            }
            if ($scope.item.state == Constants.ReestablishApplicationState.Active || $scope.item.state == Constants.ReestablishApplicationState.Eligible || $scope.item.state == Constants.ReestablishApplicationState.Draft) {
                if (!$scope.fromCreate) {
                    utils.scrollToBottom();
                }
            }
            if (!$scope.id || $scope.cloned) {
                $location.path(bizService.getEditPageUrl(result.data.id));
            }
        };

        $scope.saveDataAsync = function (options) {
            var deferredSaveData = $q.defer();

            $scope.saveData(deferredSaveData, options);

            return deferredSaveData.promise;
        }

        $scope.saveData = function (deferred, options) {
            notificationService.notify('showLoadingPanel', true);

            return saveDataCore(bizService.saveData).then(function () {
                notificationService.notify('showLoadingPanel', false);
                if (deferred) {
                    deferred.resolve();
                }

            }, function () {
                notificationService.notify('showLoadingPanel', false);
                if (deferred) {
                    deferred.reject();
                }
            });
        };


        $scope.saveAsReapply = function () {
            return saveDataCore(bizService.saveAsReapply);
        };

        $scope.saveAsWithdrawn = function () {
            return saveDataCore(bizService.saveAsWithdrawn);
        };

        $scope.saveAsIneligible = function () {
            notificationService.notify('showLoadingPanel', true);

            return saveDataCore(bizService.saveAsIneligible).then(function () {
                notificationService.notify('showLoadingPanel', false);
                if (deferred) {
                    deferred.resolve();
                }

            }, function () {
                notificationService.notify('showLoadingPanel', false);
                if (deferred) {
                    deferred.reject();
                }
            });
        };
        //#endregion

        $scope.getStateName = function (state) {
            return getStateName(state);
        }

        function getStateName(state) {
            if (Constants.ReliefApplicationStateNames[state])
                return Constants.ReliefApplicationStateNames[state].Name;
            else
                return 'Unknown';
        }

        function initializeScope() {
            $scope.item = newDataObj($scope.item);
            $scope.state = {};
            $scope.longGenders = {};
            $scope.shortGenders = {};
            $scope.updateErrorsBasedOnSaveResult = utils.updateErrors;
            $scope.contactMethods = ['Mail', 'Phone', 'Email'];

            $scope.id = $routeParams.id;
            $scope.cloned = $location.path().indexOf('clone') > 0;
            $scope.state.showSavedMessage = $routeParams.fromCreate;
            $scope.fromCreate = $routeParams.fromCreate;

            $scope.dateFormat = Constants.DatePickerFormat;
            $scope.ReliefApplicationState = Constants.ReliefApplicationState;
            $scope.ReestablishApplicationState = Constants.ReestablishApplicationState;
            $scope.showAdditionalAssistanceButton = false;
            $scope.canAddNewMember = true;

            $scope.dateOptions = {
                dateFormat: Constants.DatePickerFormat,
                maxDate: new Date(2050, 1, 1),
                minDate: new Date(1900, 1, 1),
                startingDay: 1
            };

            $scope.getEditPageUrl = bizService.getEditPageUrl;

            $scope.errorCountForField = function (fieldName) {
                var field = $scope.frm[fieldName];

                if (!field) {
                    throw "frm does not contain a key '" + fieldName + "'";
                }

                return _.keys(field.$error).length;
            };


            $scope.maxLengthMsg = function (display, name) {
                var field = utils.getFormFieldCaseInsensitive($scope, name);
                if (field && field.validationArguments && field.validationArguments.length > 0) {
                    return display + " must be " + field.validationArguments[0] + " or fewer characters";
                }
            };

            $scope.memberInCurrentIncidentMsg = function (name, isReturnUrl) {
                var field = utils.getFormFieldCaseInsensitive($scope, name);
                if (field && field.validationArguments && field.validationArguments.length > 1) {
                    return isReturnUrl ? field.validationArguments[0] : field.validationArguments[1];
                }
            }



            if ($scope.$bizService && $scope.$bizService.$injector && $scope.$bizService.$injector.injectScope) {
                $scope.$bizService.$injector.injectScope($scope);
            }
        }

        $scope.setFieldValidity = function (form, errorsList, validity) {
            if (!errorsList) return;
            errorsList.forEach(function (value) {
                form[value.field].$setValidity("serverValid", validity);
            });
        }
        function newDataObj(oldItem) {
            var item = {
                id: 0,
                iAccept: false,
                state: Constants.ReliefApplicationState.Draft,
                displayedState: getStateName(Constants.ReliefApplicationState.Draft),
                affectedAddress: {
                    address: {
                        unitNo: '',
                        streetNo: '',
                        streetName: '',
                        suburb: '',
                        postcode: ''
                    }
                },
                applicant: { contactDetails: { preferredContactMethod: null } },
                householdMembers: [],
                documents: {}
            };
            if (oldItem) {
                item.incidentId = oldItem.incidentId;
                item.incident = oldItem.incident;
            }

            if ($scope.$bizService && $scope.$bizService.$injector && $scope.$bizService.$injector.injectNewData) {
                $scope.$bizService.$injector.injectNewData(item);
            }

            $scope.$broadcast('newDataCreated', item);

            return item;
        }

        function formalizeScope() {
            with ($scope.item) {
                if (applicant.dob) {
                    applicant.dob = moment(applicant.dob).toDate();
                }

                _.each(householdMembers, function (m) {
                    if (m.dob) {
                        m.dob = moment(m.dob).toDate();
                    }
                });


                if (incident && incident.incidentDate) {
                    incident.incidentDate = moment.utc(incident.incidentDate);
                }

                if (affectedAddress.insuranceStatuses) {
                    affectedAddress.insuranceStatuses = utils.arrayToObj(affectedAddress.insuranceStatuses, 'insuranceStatusId');
                }
            }


            if ($scope.item.immediateReliefTypes) {
                $scope.item.immediateReliefTypes = utils.arrayToObj($scope.item.immediateReliefTypes, 'immediateReliefTypeId');
            }

            if ($scope.item.immediateReestablishTypes) {
                $scope.item.immediateReestablishTypes = utils.arrayToObj($scope.item.immediateReestablishTypes, 'immediateReestablishTypeId');
            }
        }
    }

})();
