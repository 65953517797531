(function () {

    var app = angular.module('phapApp');

    app.component('glRules', {
        templateUrl: 'app/administration/configuration/glRules.html',
        bindings: {
        },
        controller: ['$rootScope', '$scope', '$filter', 'utils', 'referenceService', 'configurationValidationService', 'Constants', 'security',
            function ($rootScope, $scope, $filter, utils, referenceService, configurationValidationService, constants, security) {
                var vm = this;
                vm.currentVersion = {};

                var getData = function () {
                    referenceService.getGlStringConfig().then(
                        function (glStringConfig) {
                            vm.glStringConfig = glStringConfig.data;
                            vm.selectedVersion = vm.glStringConfig.length ? vm.glStringConfig[0] : null;
                            $scope.$broadcast('displayLoading', false);
                        });
                }

               
                vm.buildFriendlyName = function (code) {
                    return code.replace(/([a-z])([A-Z])/g, '$1 $2');
                }

                vm.calculateEndDate = function (effectiveFrom, effectiveTo) {

                    var endDateText = "";

                    var startDate = new Date(effectiveFrom);

                    if (effectiveTo != null) {
                        var endDate = new Date(effectiveTo);
                    }
                    
                    var todayDate = new Date();

                    if (effectiveTo != null) {
                        endDateText = " to " + $filter('date')(effectiveTo, constants.GLDropDownDateFormat);
                    }
                    

                    if (endDate != null && todayDate > startDate && todayDate < endDate) {
                        endDateText += " (current) ";
                    }

                    if (endDate == undefined && todayDate >= startDate) {
                        endDateText += " (current) ";
                    }

                    if (endDate == undefined && todayDate <= startDate) {
                        endDateText += " onwards ";
                    }

                    return endDateText;
                };

                vm.setRulesAndClass = function (rule, i) {
                    rule.rule = "R" + i;
                };

                vm.setRuleHeading = function (rule, key) {
                    return '<p class="glRules-' + rule + '">' + rule + ' : ' + vm.buildFriendlyName(key) + ' </p>';
                };

                getData();
            }],
        controllerAs: 'glRulesVm'
    });

})();