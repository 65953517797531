(function () {

    var app = angular.module('phapApp');

    app.component('configurationGrid', {
        templateUrl: 'app/administration/configuration/configurationGrid.html',
        bindings: {
            gridOptions: '=',
            originalDataSet: '=',
            saveCallback: '=',
            readOnly: '=', //true or false
            editMode: '<', //true or false
            validator: '=', //this method should return {isValid:true/false,errors:['error message']}
            customSaveConfirmation: '=',
            history: '=',
            gridApi: '=',
            isCreateNewVersion: '<',
            isEditNewVersion: '<',
            popupInfo: '=',//{templateUrl, controller}
            saveValidator: '=',
            clearCallback: '=',
            caluculateMaxAdults: '<',
            configContainerClass: '=',
            versionLabel: '@',
            isAddNewPopup: '<'
        },
        controller: ['$rootScope', '$scope', 'uiGridConstants', 'utils', 'notificationService', function ($rootScope, $scope, uiGridConstants, utils, notificationService) {

            var vm = this;
            vm.rowtpl = '<div ng-class="{\'row-deleted\':row.entity.data.isDeleted,\'row-gray\':row.entity.data.isReadOnly,\'row-group-header\':row.groupHeader}"><div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader,\'row-group-header\':col.groupHeader }" ui-grid-cell></div></div>';
            vm.updatedList = [];
            vm.pageErrors = [];
            vm.showSavedMessage = false;
            vm.submitting = false;
            vm.isUpdated = false;

            var saveData = function () {
                var title = 'Warning';
                var content = vm.customSaveConfirmation ? vm.customSaveConfirmation : "Saving will commit all changes made on this page";
                utils.confirm(title, content, 'Proceed', 'Cancel')
                    .then(function () {
                        notificationService.notify('showLoadingPanel', true);
                        vm.isUpdated = false;
                        vm.showSavedMessage = false;
                        vm.submitting = true;
                        vm.pageErrors = [];

                        vm.saveCallback(vm.updatedList, vm.refreshAfterSave, true);
                    },
                    function () {
                        return;
                    });
            }

            var createNewVersionPopup = function () {
                var resolve = {
                    isAdd: true,
                    data: {
                        item: angular.copy(vm.gridOptions.newObject),
                        list: angular.copy(vm.updatedList)
                    }
                }

                var windowClass = vm.popupInfo.windowClass ? vm.popupInfo.windowClass : null;
                utils.openModal(vm.popupInfo.templateUrl,
                    vm.popupInfo.controller,
                    $scope,
                    resolve,
                    windowClass,
                    vm.popupCall,
                    vm.popupInfo.size);
            }

            var createNewItemPopup = function () {
                var resolve = {
                    isAdd: true,
                    data: {
                        data: angular.copy(vm.gridOptions.newObject),
                        list: angular.copy(vm.updatedList),
                        gridData: angular.copy(vm.gridOptions.data)
                    }
                }

                var windowClass = vm.popupInfo.windowClass ? vm.popupInfo.windowClass : null;
                utils.openModal(vm.popupInfo.templateUrl,
                    vm.popupInfo.controller,
                    $scope,
                    resolve,
                    windowClass,
                    vm.createNewItemPopupCall,
                    vm.popupInfo.size);
            }

            vm.createNewItemPopupCall = function (result) {
                vm.updatedList.push(result.data)
                vm.gridOptions.data.push(result.data);
                vm.gridApi.core.refresh();
                vm.isUpdated = true;
            }

            vm.updateNewItemPopupCall = function (data) {
                var rowEntity = data.data;
                var gridIndex = data.gridIndex;
                var index = -1;
                for (var i = 0; i < vm.updatedList.length; i++) {
                    if (vm.updatedList[i].data.name == rowEntity.data.name) {
                        index = vm.updatedList.indexOf(vm.updatedList[i]);
                    }
                }
                rowEntity.isUpdated = true;
                if (index == -1) {
                    vm.updatedList.push(rowEntity)
                }
                else {
                    vm.updatedList.splice(index, 1);
                    vm.updatedList.push(rowEntity)
                }

                vm.gridOptions.data[gridIndex] = rowEntity;
                vm.gridApi.core.refresh();

            }
            vm.popupCall = function (result) {
                var newData = [];
                vm.showSavedMessage = false;
                //New group added
                if (result.isAdd && result.insertIndex === -1) {
                    newData = vm.updatedList;
                    newData.push(result.item);
                }
                else {
                    for (var i = 0; i < vm.updatedList.length; i++) {
                        if (result.isAdd) {//Add
                            newData.push(vm.updatedList[i]);
                            if (i === result.insertIndex - 1) {
                                newData.push(result.item);
                            }
                        } else {//Edit
                            if (i !== result.oldItemIndex) {
                                newData.push(vm.updatedList[i]);
                                if (i === (result.insertIndex - 1) && result.insertIndex !== result.oldItemIndex) {
                                    newData.push(result.item);
                                }
                                if (i === (result.insertIndex) && result.insertIndex > result.oldItemIndex && (newData.length - 1) != i) {
                                    newData.push(result.item);
                                }
                            } else {
                                if (i === result.insertIndex) { //Same item amount update
                                    newData.push(result.item);
                                }
                            }

                        }
                    }
                    if (result.insertIndex == -1) {
                        newData.push(result.item);
                    }
                }

                vm.updatedList = newData;

                //Set the isNumberOfChildrenGreaterThan for last item of the group
                var currentAdults = 0;
                for (var j = 0; j < vm.updatedList.length; j++) {
                    if (currentAdults === vm.updatedList[j].data.numberOfAdults) {
                        if (j < (vm.updatedList.length - 1)) {
                            vm.updatedList[j].data.isNumberOfChildrenGreaterThan = false;
                        } else {
                            vm.updatedList[j].data.isNumberOfChildrenGreaterThan = true;
                        }

                    } else {
                        currentAdults = vm.updatedList[j].data.numberOfAdults;
                        if (j < (vm.updatedList.length - 1)) {
                            vm.updatedList[j].data.isNumberOfChildrenGreaterThan = false;
                        } else {
                            vm.updatedList[j].data.isNumberOfChildrenGreaterThan = true;
                        }
                        //Last item of privious group include +
                        if (j != 0)
                            vm.updatedList[j - 1].data.isNumberOfChildrenGreaterThan = true;
                    }
                }
                if (vm.caluculateMaxAdults) {
                    //Set the isNumberOfAdultsGreaterThan for last item of the group
                    for (var j = 0; j < vm.updatedList.length; j++) {
                        if (currentAdults === vm.updatedList[j].data.numberOfAdults) {
                            vm.updatedList[j].data.isNumberOfAdultsGreaterThan = true;
                        } else {
                            vm.updatedList[j].data.isNumberOfAdultsGreaterThan = false;
                        }
                    }
                }

                vm.gridOptions.data = vm.updatedList;
                vm.gridApi.core.refresh();
                vm.isUpdated = true;
                $scope.admin.$setDirty();
            }

            vm.newversionUpdateList = function () {
                //Set the grid data as updated if isCreateNewVersion is true
                if (vm.isCreateNewVersion && vm.gridOptions.data) {
                    vm.updatedList = angular.copy(vm.gridOptions.data);
                }
            }

            vm.$onInit = function () {
                vm.gridOptions.enableVerticalScrollbar = uiGridConstants.scrollbars.NEVER;
                vm.gridOptions.enableHorizontalScrollbar = uiGridConstants.scrollbars.NEVER;
                vm.gridOptions.rowTemplate = vm.rowtpl;
                vm.gridOptions.cellEditableCondition = function ($scope) {
                    vm.showSavedMessage = false;
                    if (vm.readOnly) {
                        return false;
                    }
                    else if (vm.editMode) {
                        return !$scope.row.entity.data.isReadOnly && !$scope.row.entity.data.isDeleted;
                    }
                    //Only allow edit for newly added items
                    return $scope.row.entity.isAdded && !$scope.row.entity.data.isDeleted;
                };

                vm.gridOptions.onRegisterApi = function (gridApi) {

                    vm.gridApi = gridApi;

                    //expand all rows when loading the grid, otherwise it will only display the totals only
                    vm.gridApi.grid.registerDataChangeCallback(function () {
                        vm.gridApi.treeBase.expandAllRows();
                        notificationService.notify('showLoadingPanel', false);
                    });

                    vm.gridApi.edit.on.afterCellEdit($scope, function (rowEntity, colDef, newValue, oldValue) {
                        //validate the edited record
                        var result = vm.validator(rowEntity.data, colDef, vm.gridOptions.data);
                        if (result.isValid) {
                            vm.pageErrors = [];
                            colDef.cellClass = "";
                            rowEntity.isUpdated = true;
                            var index = vm.updatedList.indexOf(rowEntity);

                            if (index === -1) {
                                vm.updatedList.push(rowEntity);
                            } else {
                                vm.updatedList.splice(index, 1);
                                vm.updatedList.push(rowEntity);
                            }
                        } else {
                            vm.pageErrors = [];
                            vm.pageErrors = vm.pageErrors.concat(result.errors);
                            colDef.cellClass = function (grid, row, col, rowRenderIndex, colRenderIndex) {

                                if (row.entity.data) {
                                    if (rowEntity.data.id === row.entity.data.id) {
                                        return "cell-error";
                                    }
                                }

                                return "";
                            };
                            vm.gridApi.cellNav.scrollToFocus(rowEntity.data, vm.gridOptions.columnDefs[0]);

                        }
                        vm.gridApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);

                    });
                };

                vm.clear = function () {
                    var title = 'Warning';
                    var content = "Are you sure you want to discard unsaved data?";
                    utils.confirm(title, content, 'Yes', 'Cancel')
                        .then(function () {
                            vm.updatedList = [];
                            vm.gridOptions.data = angular.copy(vm.originalDataSet);
                            if (vm.isCreateNewVersion && vm.isEditNewVersion) {
                                vm.updatedList = angular.copy(vm.gridOptions.data);
                            }
                            else if (vm.isCreateNewVersion) {
                                vm.readOnly = true;
                            }


                            vm.showSavedMessage = false;
                            vm.submitting = false;
                            vm.pageErrors = [];
                            vm.isUpdated = false;
                            vm.gridApi.core.refresh();
                            if (vm.clearCallback)
                                vm.clearCallback();
                            $scope.admin.$setPristine();

                        },
                        function () {
                            return;
                        });
                }
            };

            vm.$onChanges = function () {
                //Set the grid data as updated if isCreateNewVersion is true
                vm.newversionUpdateList();

                vm.addNew = function () {
                    vm.showSavedMessage = false;
                    if (vm.isAddNewPopup) {
                        //Add new popup
                        createNewItemPopup();
                    }
                    else if (!vm.isCreateNewVersion) {
                        //Set scroll to bottom
                        vm.gridOptions.data.push(angular.copy(vm.gridOptions.newObject));
                        setTimeout(function () {
                            var selectedIndex = vm.gridOptions.data.length - 1;
                            vm.gridApi.cellNav.scrollToFocus(vm.gridOptions.data[selectedIndex],
                                vm.gridOptions.columnDefs[0]);
                        },
                            100);
                    } else {
                        //create new version popup
                        createNewVersionPopup();
                    }

                };
            }

            vm.refreshAfterSave = function (result) {
                if (result.status == 200) {

                    vm.updatedList = [];
                    vm.gridApi.core.refresh();
                    vm.showSavedMessage = true;
                    vm.submitting = false;
                    if ($scope.admin) {
                        $scope.admin.$setPristine();
                    }

                } else {
                    //Add Errores
                    vm.submitting = false;
                    vm.pageErrors = [];
                    if (result.data.errors.length > 0) {
                        var errorList = [];
                        for (var i = 0; i < result.data.errors.length; i++) {
                            errorList.push(result.data.errors[i].message);
                        }
                        vm.pageErrors = vm.pageErrors.concat(errorList);
                    }

                }
                notificationService.notify('showLoadingPanel', false);
            }

            vm.updateValidationErrors = function () {
                if (vm.saveValidator) {
                    var result = vm.saveValidator(vm.updatedList);
                    if (result.isValid) {
                        vm.pageErrors = [];
                    }
                }
            }

            vm.save = function () {
                if (vm.saveValidator) {
                    var result = vm.saveValidator(vm.updatedList);
                    if (result.isValid) {
                        saveData();
                    } else {
                        vm.pageErrors = [];
                        vm.pageErrors = vm.pageErrors.concat(result.errors);
                        vm.saveCallback(vm.pageErrors, vm.updateValidationErrors, false);
                    }
                } else {
                    saveData();
                }

            }

            vm.disabledSave = function () {

                if (!vm.isCreateNewVersion) {
                    return vm.pageErrors.length !== 0 ||
                        vm.updatedList.length === 0 ||
                        vm.submitting;
                }
                return vm.pageErrors.length !== 0 ||
                    vm.updatedList.length === 0 ||
                    vm.submitting ||
                    !vm.isUpdated;

            }

            vm.disabledCancel = function () {
                return vm.submitting || !$scope.admin.$dirty;
            }

            $scope.$on('effectiveDateChange', function (event, value) {
                vm.isUpdated = true;
                vm.showSavedMessage = false;
                vm.pageErrors = [];

            });

            $scope.$on('createEditNewVersion', function (event, isNew) {
                //Set the grid data as updated if isCreateNewVersion is true
                if (isNew) {
                    vm.newversionUpdateList();
                    $scope.admin.$setDirty();

                } else {//EditMode
                    vm.isCreateNewVersion = true;
                    vm.newversionUpdateList();
                }

            });

            $scope.$on('versionChanged', function (event, value) {
                vm.showSavedMessage = false;
            });

            $scope.$on('displayLoading', function (event, isLoading) {
                vm.loaded = !isLoading;

            });

            //This is the method call when grid row delete button click.Need to use $scope
            $scope.deleteRow = function (rowEntity) {
                if (!vm.readOnly) {
                    vm.showSavedMessage = false;
                    if (rowEntity.isAdded) {
                        var title = 'Warning';
                        var content = "Are you sure you want to delete new item without saving?";
                        utils.confirm(title, content, 'Yes', 'No')
                            .then(function () {
                                var index = vm.gridOptions.data.indexOf(rowEntity);
                                vm.gridOptions.data.splice(index, 1);

                                if (rowEntity.data.isNumberOfChildrenGreaterThan) {
                                    vm.updatedList[index - 1].data.isNumberOfChildrenGreaterThan = true;
                                    vm.gridOptions.data[index - 1].data.isNumberOfChildrenGreaterThan = true;
                                }
                                vm.updatedList.splice(index, 1);
                                if (vm.updatedList.length === 0) {
                                    vm.pageErrors = [];
                                }
                            },
                            function () {
                                return;
                            });

                    } else {
                        var title = 'Warning';
                        var content = "Are you sure you want to mark this as delete?";
                        utils.confirm(title, content, 'Yes', 'No')
                            .then(function () {
                                var index = vm.gridOptions.data.indexOf(rowEntity);
                                rowEntity.data.isDeleted = true;
                                rowEntity.isUpdated = true;
                                if (vm.isCreateNewVersion) {
                                    vm.isUpdated = true;
                                    vm.updatedList[index] = rowEntity;
                                } else {
                                    vm.updatedList.push(rowEntity);
                                }
                                $scope.admin.$setDirty();
                            },
                            function () {
                                return;
                            });

                    }
                }
            }

            $scope.reActivate = function (rowEntity) {
                if (!vm.readOnly) {
                    var title = 'Warning';
                    var content = "Are you sure you want to reactivate this?";
                    utils.confirm(title, content, 'Yes', 'No')
                        .then(function () {
                            var index = vm.updatedList.indexOf(rowEntity);
                            rowEntity.data.isDeleted = false;
                            rowEntity.isUpdated = true;
                            vm.showSavedMessage = false;
                            if (vm.isCreateNewVersion) {
                                vm.updatedList[index].data.isDeleted = false;
                                vm.updatedList[index].isUpdated = true;
                            } else {
                                vm.updatedList.push(rowEntity);
                            }
                            $scope.admin.$setDirty();
                        },
                        function () {
                            return;
                        });
                }
            };
            $scope.createGroupHeader = function (value) {
                if (value) {
                    var items = value.split(" ");
                    if (items[0] == 'Null')
                        return "New Item";
                    return items[0] + " Adults";
                }

                return value;
            }

            $scope.update = function (rowEntity) {
                vm.showSavedMessage = false;
                if (vm.isAddNewPopup) {
                    var itemIndex = vm.gridOptions.data.indexOf(rowEntity);
                    var resolve = {
                        isAdd: false,
                        data: {
                            data: angular.copy(rowEntity),
                            list: angular.copy(vm.updatedList),
                            itemIndex: itemIndex,
                            gridData: angular.copy(vm.gridOptions.data)
                        }
                    }

                    var windowClass = vm.popupInfo.windowClass ? vm.popupInfo.windowClass : null;
                    utils.openModal(vm.popupInfo.templateUrl,
                        vm.popupInfo.controller,
                        $scope,
                        resolve,
                        windowClass,
                        vm.updateNewItemPopupCall,
                        vm.popupInfo.size);
                }
                else {
                    //popup
                    var oldItemIndex = vm.gridOptions.data.indexOf(rowEntity);
                    var resolve = {
                        isAdd: false,
                        data: {
                            item: angular.copy(rowEntity),
                            list: angular.copy(vm.updatedList),
                            oldItemIndex: oldItemIndex
                        }
                    }

                    var windowClass = vm.popupInfo.windowClass ? vm.popupInfo.windowClass : null;
                    utils.openModal(vm.popupInfo.templateUrl,
                        vm.popupInfo.controller,
                        $scope,
                        resolve,
                        windowClass,
                        vm.popupCall,
                        vm.popupInfo.size);
                }


            };
        }],
        controllerAs: 'configurationGridVm'
    });

})();