(function () {
    angular.module('phapApp')
        .directive('assessingOfficer', ['$window', function ($window) {
            return {
                restrict: 'E',
                scope: true,
                templateUrl: 'app/home/relief/_assessingOfficer.html',
                controller: ['$scope', 'security', '$uibModal', 'utils', '$q', controller]
            };
        }]);



    function controller($scope, security, $uibModal, utils, $q) {        

        $scope.users = [];
        security.getAllUsers().then(function (users) {
            $scope.users = users;
        });

        $scope.$watch('item.assessingOfficerUser', function (nv) {
            $scope.item.assessingOfficer = nv == null ? null : nv.id;
        });
    }

})();