////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////// Google address widget /////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/// Usage: /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/// <div google-address-widget show-lga="true" ng-model="search.address" google-search-address="item.googleSearchAddress"></div> ///
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

(function () {
    angular.module('phapApp')
        .directive('googleAddressWidget', ['lgaService', function (lgaService, $parse) {
                return {
                    require: 'ngModel',
                    replace: true,
                    templateUrl: '/app/_directives/googleAddressWidget.html',
                    scope: {
                        ngModel: '=',
                        googleSearchAddress: '=',
                        showLga: '=',
                        fieldPrefix: '=',
                        isDisabled: '='
                    },
                    link: function (scope, element, attrs, model) {
                        scope.address = {};

                        scope.$watch('ngModel', function (newValue) {
                            if (!newValue) return;
                            scope.address = newValue;
                        });

                        // Initialise showLga
                        var showLga = scope.$eval(attrs.showLga);
                        if (showLga == null || showLga == 'undefined') {
                            showLga = false;
                        }

                        // Set the scope variable so it'll be availble for the html substitutions
                        scope.fieldPrefix = attrs.fieldPrefix;

                        scope.isDisabled = scope.$eval(attrs.isDisabled);
                        if (scope.isDisabled === null || scope.isDisabled === undefined) {
                            scope.isDisabled = false;
                        }

                        function getFieldFromGoogleAddressComponents(addressComponents, fieldName) {
                            var entry = addressComponents[fieldName];

                            if (!entry) return null;

                            return entry.long_name;
                        };

                        var mapGoogleMapsAddress = function (gMapsAddress, toAddress, addressString) {
                            if (gMapsAddress.address_components) {
                                var ac = {};

                                _.each(gMapsAddress.address_components, function (c) {
                                    _.each(c.types, function (t) {
                                        ac[t] = c;
                                    });
                                });

                                scope.addressComponents = ac;

                                toAddress.unitNo = getFieldFromGoogleAddressComponents(ac, "subpremise");
                                toAddress.streetNo = getFieldFromGoogleAddressComponents(ac, "street_number");
                                toAddress.streetName = getFieldFromGoogleAddressComponents(ac, "route");
                                toAddress.suburb = getFieldFromGoogleAddressComponents(ac, "locality");
                                toAddress.postcode = getFieldFromGoogleAddressComponents(ac, "postal_code");

                                // Apply patches to the address, as there are some tweaks we apply to normalise the google stuff
                                applyAddressPatches(toAddress, addressString);
                            }
                        };

                        // TODO : COMBINE THIS INTO A SINGLE COMPONENT!!! we should use the 
                        //      googleAddressWidget here rather than re-doing this when we have time to 
                        //      combine this !!!
                        //  NOTE: There is an addressInput.js directive WHICH IS NOT USED!
                        function applyAddressPatches(toAddress, addressString) {
                            // Scenario 1.  No street number was found, so let's see if we can find one!
                            if (toAddress.streetNo === null) {
                                // Okay there was no street number provided, so let's have a quick look to see if we can
                                // patch this as google doesnt always find the information, BUT we'll only do a quick
                                // test so we dont go nuts looking for different combos.....
                                //
                                //      i.e.  Unit 1/23 Some street - Skip these (with alpha's at the start)
                                //      i.e.  Appt 1/23 Some street - Skip these (with alpha's at the start)
                                //  BUT - we'll fix the following ones which google doesnt get right
                                //      100 Grampians Road, Mafeking, Victoria, Australia
                                //      105-107 Grampians Road, Mafeking, Victoria, Australia

                                var firstCharIdx = addressString.search(/[a-zA-Z]/);
                                if (firstCharIdx > 0 && firstCharIdx < 10) {
                                    // NOTE: We'll use the first 10 chars only incase there is some 
                                    // strange number, if so the user can manually fix enter this item
                                    var uptoChar = addressString.substring(0, firstCharIdx).trim();
                                    var slashIdx = uptoChar.indexOf("/");
                                    if (slashIdx === -1) {
                                        slashIdx = uptoChar.indexOf("\\");
                                    }
                                    if (slashIdx === -1) {
                                        toAddress.streetNo = uptoChar;
                                    } else {

                                        var unitNo = uptoChar.substring(0, slashIdx).trim();
                                        var streetNo = uptoChar.substring(slashIdx + 1, slashIdx).trim();

                                        toAddress.unitNo = unitNo;
                                        toAddress.streetNo = streetNo;
                                    }
                                }
                            }
                            else if (toAddress.streetNo !== null && toAddress.unitNo !== null) {

                                // Check for a building number being broken up into a unit/street number

                                // i.e. 3-4 Mernda Village Drive, Mernda, Victoria, Australia
                                //      ends up being (from google)
                                //         Unit 4 Street 3 
                                var comboLength = toAddress.unitNo.length + 1 + toAddress.streetNo.length;
                                var comboComp = addressString.substring(0, comboLength);

                                // Check if we've got a building number being split into unit/street number, if so
                                // we'll put it back
                                var idxDash = comboComp.indexOf("-");
                                if (idxDash !== -1) {

                                    // We've found a 3-4 scenario, let's see if google has it backward, if so
                                    // we swap things back around the way it appears in the address string
                                    var reBuilt = toAddress.unitNo + '-' + toAddress.streetNo;
                                    if (comboComp !== reBuilt) {
                                        var unitHold = toAddress.streetNo + '-' + toAddress.unitNo;
                                        toAddress.streetNo = unitHold;
                                        toAddress.unitNo = null;
                                    }
                                }
                            }
                        };

                        scope.$watch('item.googleAddress', function (newValue, oldValue, scope) {
                            if (!_.isEmpty(newValue) && newValue && newValue.address_components) {
                                mapGoogleMapsAddress(newValue, scope.address, scope.googleSearchAddress);

                                if (showLga) {
                                    var lga = getFieldFromGoogleAddressComponents(scope.addressComponents, "administrative_area_level_2");

                                    if (!!lga) {
                                        lgaService.matchLga(lga).then(function (result) {
                                                scope.address.lga = result.data;
                                                model.$setViewValue(scope.address);
                                            },
                                            function (error) {
                                                console.log(error);
                                                scope.address.lga = [
                                                    {
                                                        lgaCode: null,
                                                        name: null
                                                    }
                                                ];
                                                model.$setViewValue(scope.address);
                                            });
                                    }
                                }
                                else
                                    model.$setViewValue(scope.address);
                            }
                        });

                        model.$setViewValue(scope.address);
                    }
                };
            }
        ])
})();
