(function() {
    angular.module('phapApp')
        .service('reestablishService', ['$http', '$q', 'config', 'utils', 'Constants', '$filter', function ($http, $q, config, utils, Constants, $filter) {
            // #region Loading
            var loadRefData = function () {
                return $http.get(config.apiBaseUrl + "reestablish/ref-data")
                    .then(
                    function (result) {
                        return result;
                    });
            };
            var loadData = function (id, cloned) {
                var url = "reestablish/reestablish-application/";
                if (cloned) url += "clone/";
                url += id;                
                return $http.get(config.apiBaseUrl + url);
            };

            var loadPeriodConfig = function (date, forceToReload) {
                var url = config.apiBaseUrl + "reestablish/period-config/" + $filter('phapDate')(date);
                return $http.get(url, { cache: !forceToReload }).then(function (result) {
                    return result.data;
                });
            };

            var saveReestablishPayments = function (data) {
                var url = config.apiBaseUrl + "payment/reestablish-application/CreateReestablishmentPayment";
                return $http({
                    method: 'POST',
                    url: url,
                    data: angular.toJson(data),
                    headers: { 'Content-Type': 'application/json' }
                }).then(function (response) {
                    return response.data;
                });
            }
            var removeReestablishPayment = function (data) {
                var url = config.apiBaseUrl + "payment/reestablish-application/CancelPayment";
                return $http({
                    method: 'POST',
                    url: url,
                    data: angular.toJson(data),
                    headers: { 'Content-Type': 'application/json' }
                }).then(function (response) {
                    return response.data;
                });
            }
            // #endregion

            var getWeeklyThresholds = function (effectiveDate, noOfAdults, noOfChildren) {

                var effectiveDateMoment = effectiveDate ? moment(effectiveDate) : moment();
                var effectiveDateString = effectiveDateMoment.format("YYYY-MM-DD");

                return $http.get(config.apiBaseUrl + "reestablish/reestablish-application/weekly-threshold/" + effectiveDateString + "/" + noOfAdults + "/" + noOfChildren,
                    { cache: true })
                    .then(
                    function (result) {
                        if (result.status == 204) {
                            return {
                                ok: false,
                                message: "Error: server did not return grant amount."
                            };
                        }
                        else {
                            return {
                                ok: true,
                                data: result.data
                            };
                        }
                    });
            };

            // #region Saving
            var saveAsWithdrawn = function (data) {
                beforeSave(data);
                return $http.post(config.apiBaseUrl + "reestablish/reestablish-application/save-as-withdrawn", data);
            };

            var saveAsReapply = function (data) {
                beforeSave(data);
                return $http.post(config.apiBaseUrl + "reestablish/reestablish-application/reapply", data);
            };

            var saveAsIneligible = function (data) {
                beforeSave(data);
                return $http.post(config.apiBaseUrl + "reestablish/reestablish-application/save-as-ineligible", data);
            };

            var saveData = function (data) {
                beforeSave(data);
                return $http.post(config.apiBaseUrl + "reestablish/reestablish-application", data);
            };

            var beforeSave = function (data) {

                // remove incident object
                delete data.incident;

                if (data.applicant && data.applicant.dob) {
                    data.applicant.dob = moment(data.applicant.dob).add(1, 'days').toDate();
                }

                if (data.householdMembers) {
                    _.each(data.householdMembers, function (m) {
                        if (m.dob) {
                            m.dob = moment(m.dob).add(1, 'days').toDate();
                        }
                        delete m.gender;
                    });
                }
                if (data.affectedAddress) {
                    if (data.affectedAddress.insuranceStatuses) {
                        var types = [];
                        for (var k in data.affectedAddress.insuranceStatuses) {
                            if (data.affectedAddress.insuranceStatuses[k] && parseInt(k)) {
                                types.push({ insuranceStatusId: k });
                            }
                        }
                        data.affectedAddress.insuranceStatuses = types;
                    }
                    if (data.affectedAddress.lga && (!data.affectedAddress.lga.lgaCode || !data.affectedAddress.lga.name)) {
                        data.affectedAddress.lga = null;
                    }
                }
            };

            // #endregion

            var validateAffectedAddress = function (incidentId, applicationNumber, affectedAddress) {

                var defer = $q.defer();

                if (affectedAddress && affectedAddress.lga && affectedAddress.lga.name && affectedAddress.address) {
                    if (!_.isEmpty(affectedAddress.lga.name)
                        && !_.some(['streetNo', 'streetName', 'suburb', 'postcode'],
                            function (prop) {
                                return _.isEmpty(affectedAddress.address[prop]);
                            })) {
                        var model = {
                            incidentId: incidentId,
                            applicationNumber: applicationNumber,
                            addressToCheck: affectedAddress.address
                        };

                        $http.post(config.apiBaseUrl + 'reestablish/reestablish-application/is-affected-address-already-in-use', model)
                            .then(function (result) {
                                if (result && result.data) {
                                    defer.resolve(result.data);
                                } else {
                                    defer.resolve(null);
                                }
                            }, function (data) {
                                deferred.reject(data);
                            });
                        return defer.promise;
                    }
                }

                defer.resolve(true);
                return defer.promise;
            };

            var validateLga = function (incidentId, lga) {
                var defer = $q.defer();

                if (lga && lga.lgaCode != null && lga.name) {
                    var model = {
                        incidentId: incidentId,
                        lga: lga
                    };

                    $http.post(config.apiBaseUrl +
                        'relief/relief-application/is-affected-address-lga-invalid',
                        model)
                        .then(function (result) {
                            if (result && result.data) {
                                defer.resolve(result.data);
                            } else {
                                defer.resolve(true);
                            }
                        });
                    return defer.promise;
                }
                defer.resolve(true);
                return defer.promise;
            };

            var showInvalidLga = function ($scope) {
                var deferred = $q.defer();
                var lgaInvalidModel = {
                    region: $scope.item.incident.region.name, 
                    lga: $scope.item.affectedAddress.lga.name
                };
                var url = 'app/home/relief/_affectedAddressLgaCheckModalTemplate.html';
                var resolve = {
                    lgaInvalidModel: function () {
                        return lgaInvalidModel;
                    },
                    defer: function () {
                        return deferred;
                    }
                };
                utils.openModal(url, 'AffectedAddressLgaCheckModalCtrl', $scope, resolve);
            }

            var getLga = function () {
                return $http.get(config.apiBaseUrl + "lga");
            };

            var getStateName = function (state) {
                var states = ["Eligible", "Ineligible", "Withdrawn", "Active", "Closed"];
                if (state > 0 && state < states.length) {
                    return states[state];
                }
                return "";
            };

            var getAssessingOfficers = function (userNameKey) {
                var defer = $q.defer();
                if (userNameKey.length > 0) {
                    return $http.get(config.apiBaseUrl + 'security/users/' + userNameKey)
                        .then(function (result) {
                                return result.data.users;
                            });
                } else {
                    defer.resolve();
                    return defer.promise;
                }
            };

            var isEligible = function (relief) {                
                return relief && relief.affectedAddress.principalPlaceOfResidence && !relief.previouslyReceivedPayment;
            };

            var updateAllowed = function ($scope) {
                if ($scope.item && $scope.currentUserSecurityInfo && $scope.currentUserSecurityInfo.userHasAccessToUpdateReestablish) {
                    return $scope.currentUserSecurityInfo.userHasAccessToUpdateReestablish($scope.item.id, $scope.item.incident) == true;
                }
                return true;
            }

            var getEditPageUrl = function (id, showSuccess) {
                if (showSuccess === undefined) showSuccess = true;
                return "/home/reestablish/edit/" + id + "/" + (showSuccess ? "true" : "");
            };

            var validateMembers = function (data) {
                var result = null;
                if (!!data && !!data.incidentId && data.incidentId > 0) {
                    var url = "reestablish/members-exist-in-current-incident";
                    result = $http.post(config.apiBaseUrl + url, data);
                }
                return result;
            };

            var $injector = {
                injectScope: function (scope) {
                    scope.$isReestablish = true;
                },
                injectNewData: function (item) {
                    item.amountOfDebrisRemoval = 0;
                }
            };

            var paymentsExist = function (item) {
                if (item.state == Constants.ReestablishApplicationState.Draft ||
                    item.state == Constants.ReestablishApplicationState.Ineligible ||
                    item.state == Constants.ReestablishApplicationState.Withdrawn) {
                    return false;
                }

                return (
                    item.exceptionalCircumstancesAmount > 0 ||
                    item.furtherPaymentAmount > 0 ||
                    item.initialPaymentAmount > 0 );
            }

            var getMaxPayment = function (item) {
                if (!utils.isNullOrEmpty(item.weeklyIncomeTotalWeeklyIncome) && item.maxReestablishmentInitialPayment) {
                    var max = _.chain(item.maxReestablishmentInitialPayment)
                        .orderBy(['adults', 'children'], ['desc', 'desc'])
                        .find(function (o) { return o.adults <= item.adults && o.children <= item.children; })
                        .value();
                    return max == null ? 0 : max.max;
                }
                return 0;
            };

            var getTotalPaid = function (payments) {
                var totalPaid = 0;
                if (payments && payments.length > 0) {
                    // get the total paid
                    totalPaid = _.reduce(payments,
                        function (total, payment) {
                            if (payment.paymentStatusId !== Constants.paymentStatus.cancelled.id && 
                                payment.paymentStatusId !== Constants.paymentStatus.deleted.id) {
                                return total + payment.amount;
                            }
                            return total;
                        }, 0);
                }

                return totalPaid;
            };
           
            var calculateInitialPayment = function (item) {
                if (item) {
                    // only calculate if eligible
                    if (item.state === Constants.ReestablishApplicationState.Eligible) {
                        if (item.exceptionalCircumstancesExist) {
                            item.furtherPaymentAmount = 0;
                            item.initialPaymentAmount = 0;
                            var amount = item.exceptionalCircumstancesAmount || 0;
                        } else {
                            item.exceptionalCircumstancesAmount = 0;
                            if (!utils.isNullOrEmpty(item.weeklyIncomeTotalWeeklyIncome) &&
                                item.maxReestablishmentInitialPayment) {
                                var furtherPaymentAmount = 0;
                                var initialPaymentAmount = getMaxPayment(item);


                                // use the lower of total claim amount and weekly income max payment as calculated claim amount
                                var calculatedTotalClaim = item.paymentAmountsTotalClaimAmount;
                                if (calculatedTotalClaim > item.weeklyIncomeMaximumPayment) {
                                    calculatedTotalClaim = item.weeklyIncomeMaximumPayment;
                                }

                                // if the calculated claim amount is 0 then we use the payment amount from getMaxPayment() which is from the period configuration table
                                if (calculatedTotalClaim !== 0) {
                                    // if there all evidence is supplied we should move all the claim amount to the further payment amount field
                                    if (!item.evidenceRequired) {
                                        initialPaymentAmount = 0;
                                        furtherPaymentAmount = calculatedTotalClaim;
                                    } else {
                                        // if the calculated total claim amount is less than the initial payment then we should set the initial payment amount to the calculated claim amount
                                        if (calculatedTotalClaim < initialPaymentAmount) {
                                            initialPaymentAmount = calculatedTotalClaim;
                                        }
                                        // the further payment is the total amount minus the initial payment amount
                                        furtherPaymentAmount = calculatedTotalClaim - initialPaymentAmount;
                                    }
                                }


                                item.initialPaymentAmount = initialPaymentAmount;
                                item.furtherPaymentAmount = furtherPaymentAmount;

                            }
                        }
                    }
                }
            };



            var getPaymentConfigurations = function (item) {
                loadPeriodConfig(item.incident.incidentDate).then(function (data) {
                    if (data.initialPayment) {
                        item.maxReestablishmentInitialPayment = data.initialPayment;
                        calculateInitialPayment(item);
                    }
                });
            };

            var setApplicantHasInsurance = function (item) {
                if (item && item.applicant && item.applicant.memberType != null) {
                    if (item.applicant.memberType.id === Constants.memberTypes.owner) {
                        if (item.insuranceHasStructure === true && item.insuranceHasContent === true) {
                            item.applicantHasInsurance = true;
                        }
                        else if (item.insuranceHasStructure != undefined &&
                            item.insuranceHasStructure != null &&
                            item.insuranceHasContent != undefined &&
                            item.insuranceHasContent != null
                        ) {
                            item.applicantHasInsurance = false;
                        } else {
                            item.applicantHasInsurance = undefined;
                        }
                    }
                    else if (item.applicant.memberType.id === Constants.memberTypes.privateTenant ||
                        item.applicant.memberType.id === Constants.memberTypes.publicHousingTenant) {
                        item.applicantHasInsurance = item.insuranceHasContent;
                    }
                }
            }

            var setPaymentSectionVisibility = function (formSections, item) {

                if (formSections.categoriesOfAssessment.showNav) {
                    if (item.applicantHasInsurance != undefined) {
                        formSections.exceptionalCircumstances.showNav = item.exceptionalCircumstancesExist;
                        formSections.paymentTypes.showNav = !item.exceptionalCircumstancesExist;
                        formSections.applicationAmounts.showNav = !item.exceptionalCircumstancesExist;
                        return;
                    }
                } 
                formSections.exceptionalCircumstances.showNav = false;
                formSections.paymentTypes.showNav = false;
                formSections.applicationAmounts.showNav = false;
            }

            var setExceptionalCircumstances = function (formSections, item) {

                if (item) {
                    var all = angular.extend({}, formSections.categoriesOfAssessment.subMenu, formSections.eligibilityCriteria.subMenu);
                    // set variable based on if any rulesOverridden have been set to true
                    item.exceptionalCircumstancesExist = _.reduce(all, function (result, value) {
                        return result || (value && value.rulesOverridden && value.showNav);
                    }, false);
                    item.evidenceRequired = _.reduce(all, function (result, value) {
                        return result || (value && value.evidenceRequired && value.showNav);
                    }, false);

                    if (item.exceptionalCircumstancesExist) {
                        var totalPaid = getTotalPaid(item.payments);
                        if (item.exceptionalCircumstancesAmount < totalPaid) {
                            item.exceptionalCircumstancesAmount = totalPaid;
                            item.exceptionalCircumstancesEvidenceAttached = false;
                        }
                    }
                    setPaymentSectionVisibility(formSections, item);
                }
            };


            var submitPayments = function (submitModel) {                
                return $http.post(config.apiBaseUrl + "payment/reestablish-application/SubmitPayments", submitModel)
                .then(function (response) {
                    return response.data;
                });
            };


            var updateReestablishPayment = function (data) {
                var url = config.apiBaseUrl + "payment/reestablish-application/UpdateReestablishPayment";
                return $http({
                    method: 'POST',
                    url: url,
                    data: angular.toJson(data),
                    headers: { 'Content-Type': 'application/json' }
                }).then(function (response) {
                    return response.data;
                });
            }

            var getCardState = function (cardId) {
                return $http.get(config.apiBaseUrl + "payment/card-state/" + cardId)
                    .then(function (response) {
                        return response.data;
                    });
            };

            var reopenApplication = function (model) {
                return $http.post(config.apiBaseUrl + "reestablish/reestablish-application/reopen-reestablish-application", model)
                    .then(function (response) {
                        return response.data;
                    });
            }

            var approvePayment = function (data) {
                var url = config.apiBaseUrl + "payment/reestablish-application/ApprovePayment";
                return $http({
                    method: 'POST',
                    url: url,
                    data: angular.toJson(data),
                    headers: { 'Content-Type': 'application/json' }
                }).then(function (response) {
                    return response.data;
                });
            }
            var rejectPayment = function (data) {
                var url = config.apiBaseUrl + "payment/reestablish-application/RejectPayment";
                return $http({
                    method: 'POST',
                    url: url,
                    data: angular.toJson(data),
                    headers: { 'Content-Type': 'application/json' }
                }).then(function (response) {
                    return response.data;
                });
            }

            var resubmitForApproval = function (data) {
                var url = config.apiBaseUrl + "payment/reestablish-application/ResubmitForApproval";
                return $http({
                    method: 'POST',
                    url: url,
                    data: angular.toJson(data),
                    headers: { 'Content-Type': 'application/json' }
                }).then(function (response) {
                    return response.data;
                });
            }

            return {
                loadData: loadData,
                loadRefData: loadRefData,
                saveData: saveData,
                validateAffectedAddress: validateAffectedAddress,
                getStateName: getStateName,
                getLga: getLga,
                validateLga: validateLga,
                getAssessingOfficers: getAssessingOfficers,
                isEligible: isEligible,
                updateAllowed: updateAllowed,
                getEditPageUrl: getEditPageUrl,
                getWeeklyThresholds: getWeeklyThresholds,
                saveAsReapply: saveAsReapply,
                saveAsWithdrawn: saveAsWithdrawn,
                saveAsIneligible: saveAsIneligible,
                saveReestablishPayments: saveReestablishPayments,
                removeReestablishPayment: removeReestablishPayment,
                loadPeriodConfig: loadPeriodConfig,
                validateMembers: validateMembers,
                $injector: $injector,
                paymentsExist: paymentsExist,
                setApplicantHasInsurance: setApplicantHasInsurance,
                setExceptionalCircumstances: setExceptionalCircumstances,
                calculateInitialPayment: calculateInitialPayment,
                getPaymentConfigurations: getPaymentConfigurations,
                getTotalPaid: getTotalPaid,
                submitPayments: submitPayments,
                updateReestablishPayment: updateReestablishPayment,
                getCardState: getCardState,
                reopenApplication: reopenApplication,
                approvePayment: approvePayment,
                rejectPayment: rejectPayment,
                showInvalidLga: showInvalidLga,
                resubmitForApproval: resubmitForApproval
        };
        }]);
})();