(function () {

    var app = angular.module('phapApp');
    app.component('myCallbackTasks', {
        templateUrl: 'app/home/dashboard/myCallbackTasks.html',
        bindings: {
        },
        controller: ['callbackRequestService', function (callbackRequestService) {
            var vm = this;

            vm.pageSize = 5;
            vm.pageNumber = 1;
            vm.taskStates = [
                {
                    id: 0,
                    name: "Open"
                },
                {
                    id: 1,
                    name: "Cannot be contacted"
                },
                {
                    id: 2,
                    name: "Closed"
                },
            ];
            vm.notesMaxLength = 1000;
            vm.users = null;

            vm.save =
                function (task) {
                    task.saveInProgress = true;

                    callbackRequestService.save(task.id, task.updated.notes, task.updated.assigneeUserId, task.updated.status)
                        .then(
                            function () {
                                // The server returned success - the save was successful. Refresh the current page
                                vm.loadPage(vm.pageNumber);
                            },
                            function () {
                                // The server returned failure - the save was unsuccessful.
                                task.saveInProgress = false;
                            }
                        );
                };

            vm.shouldDisableSaveButton =
                function (task) {
                    if (task.saveInProgress) return true; // Don't let the user save if they're currently saving
                    if (task.updated.notes != null && task.updated.notes.length > vm.notesMaxLength) return true; // Notes too long.

                    return false;
                };

            vm.loadPage = function (pageNumber) {
                vm.loading = true;
                vm.tasks = null;

                callbackRequestService.getMyCallbackTasks(pageNumber, vm.pageSize).then(
                    function (tasks) {
                        vm.loading = false;
                        vm.tasks = tasks;

                        vm.pageNumber = pageNumber;
                        vm.firstRecord = (vm.pageNumber - 1) * vm.pageSize + 1;
                        vm.lastRecord = vm.firstRecord + vm.tasks.pageRecords.length - 1;

                        vm.maximumPermittedPage = Math.ceil(vm.tasks.totalCount / vm.pageSize);

                        _.each(
                            vm.tasks.pageRecords,
                            function (t) {
                                t.updated = {
                                    notes: t.notes,
                                    assigneeUserId: t.assignee,
                                    status: t.status
                                };
                            });

                        vm.loading = false;

                        if (vm.firstRecord > vm.lastRecord && vm.pageNumber > 1) {
                            // We've ended up past the end of the list. Move back a page.
                            vm.loadPage(vm.pageNumber - 1);
                        }
                    }
                )
            }

            vm.loadPage(1);

            callbackRequestService.getUsersForCallbackRequestAssignment().then(
                function (users) {
                    vm.users =
                        _.map(
                            _.sortBy(
                                users,
                                function (x) { return (x.givenName + ' ' + x.surname).toLowerCase(); }
                            ),
                            function (user) { return { name: user.name, id: user.id }; }
                        );

                    vm.users.splice(0, 0, { name: '[Unassign task]', id: null });
                });
        }],
        controllerAs: 'vm'
    });

})();