(function () {
	angular.module('phapApp')
		.controller(
			'editReestablishController',
			[
				'$scope', '$location', '$timeout', 'uiGridConstants', '$route', '$routeParams', '$http', '$q', 'config', 'security',
				'$uibModal', '$document', '$compile', 'lgaService', 'incidentService', 'utils', 'Constants', 'shared', 'searchMemberService',
				'reestablishService', 'navigationService', 'notificationService',
				bizController]
		);

	function bizController($scope, $location, $timeout, uiGridConstants, route, $routeParams, $http, $q, config, security,
		$uibModal, $document, $compile, lgaService, incidentService, utils, Constants, shared, searchMemberService, bizService, navigationService, notificationService) {
		$scope.formValue = {};
		$scope.formValue.value = "Reestablish";
		$scope.Constants = Constants;
		var maxDocumentsAllowed = 30;

		$scope.ppoeDocumentTypes = {};
		$scope.allExpanded = true;
		$scope.collapseMenu = true;

		$scope.formSections = {
			privacy: { id: 'privacy', sectionName: 'Privacy Notice', expanded: true, pageState: Constants.pageState.reestablishForm, showNav: true },
			affectedAddress: { id: 'affectedAddress', sectionName: 'Affected Address', expanded: true, pageState: Constants.pageState.reestablishForm, showNav: true },
			applicant: { id: 'applicant', sectionName: 'Applicant', expanded: true, pageState: Constants.pageState.reestablishForm, showNav: true },
			householdMembers: { id: 'householdMembers', sectionName: 'Household Members', expanded: true, pageState: Constants.pageState.reestablishForm, showNav: false },
			eligibilityCriteria: {
				id: 'eligibilityCriteria', sectionName: 'Eligibility Criteria', expanded: true, showNav: false, pageState: Constants.pageState.reestablishForm,
				subMenu: {
					insuranceSummary: { id: 'insuranceSummary', sectionName: 'Insurance Summary', expanded: true, pageState: Constants.pageState.reestablishForm, showNav: true },
					weeklyIncome: { id: 'weeklyIncome', sectionName: 'Weekly income', rulesOverridden: false, evidenceRequired: false, expanded: true, incomplete: false, pageState: Constants.pageState.reestablishForm, showNav: true },
					damageAssessment: { id: 'damageAssessment', sectionName: 'Damage assessment', rulesOverridden: false, evidenceRequired: false, expanded: true, incomplete: false, pageState: Constants.pageState.reestablishForm, showNav: true },
					principalPlaceOfResidence: { id: 'principalPlaceOfResidence', sectionName: 'Principal place of residence', rulesOverridden: false, evidenceRequired: false, expanded: true, incomplete: false, pageState: Constants.pageState.reestablishForm, showNav: true },
					urgentNeeds: { id: 'urgentNeeds', sectionName: 'Urgent needs assessment', rulesOverridden: false, evidenceRequired: false, expanded: true, incomplete: false, pageState: Constants.pageState.reestablishForm, showNav: true },
					insuranceDetails: { id: 'insuranceDetails', sectionName: 'Insurance details', rulesOverridden: false, validationMessages: [], evidenceRequired: false, expanded: true, incomplete: false, pageState: Constants.pageState.reestablishForm, showNav: true }
				}
			},
			categoriesOfAssessment: {
				id: 'categoriesOfAssessment', sectionName: 'Categories of Assessment', expanded: true, showNav: false, pageState: Constants.pageState.reestablishForm,
				subMenu: {
					altAccom: { id: 'altAccom', sectionName: 'Alternate accommodation', rulesOverridden: false, evidenceRequired: false, expanded: true, incomplete: false, pageState: Constants.pageState.reestablishForm, showNav: true },
					essentialHousehold: { id: 'essentialHousehold', sectionName: 'Essential household items', rulesOverridden: false, evidenceRequired: false, expanded: true, incomplete: false, pageState: Constants.pageState.reestablishForm, showNav: true },
					debrisRemoval: { id: 'debrisRemoval', sectionName: 'Removal of debris', rulesOverridden: false, evidenceRequired: false, expanded: true, incomplete: false, pageState: Constants.pageState.reestablishForm, showNav: true },
					demolitionRebuilding: { id: 'demolitionRebuilding', sectionName: 'Demolition / rebuilding', rulesOverridden: false, evidenceRequired: false, expanded: true, incomplete: false, pageState: Constants.pageState.reestablishForm, showNav: true },
					essentialRepairs: { id: 'essentialRepairs', sectionName: 'Essential repairs', rulesOverridden: false, evidenceRequired: false, expanded: true, incomplete: false, pageState: Constants.pageState.reestablishForm, showNav: true },
					safetyReconnection: { id: 'safetyReconnection', sectionName: 'Safety and reconnection', rulesOverridden: false, evidenceRequired: false, expanded: true, incomplete: false, pageState: Constants.pageState.reestablishForm, showNav: true }
				}
			},
			applicationAmounts: {
				id: 'applicationAmounts', sectionName: 'Payment Amounts', expanded: true, showNav: false, pageState: Constants.pageState.reestablishForm,
				subMenu: {
					maxAssistanceAmount: { id: 'maxAssistanceAmount', sectionName: 'Potential eligible amount', pageState: Constants.pageState.reestablishForm, showNav: true },
					totalClaimAmount: { id: 'totalClaimAmount', sectionName: 'Total claim amount', pageState: Constants.pageState.reestablishForm, showNav: true }
				}
			},
			paymentTypes: {
				id: 'paymentTypes', sectionName: 'Payment types', expanded: true, showNav: false, pageState: Constants.pageState.reestablishForm,
				subMenu: {
					initialPayment: { id: 'initialPayment', sectionName: 'Initial payment amount', pageState: Constants.pageState.reestablishForm, showNav: true },
					furtherPayment: { id: 'furtherPayment', sectionName: 'Further payment amount', pageState: Constants.pageState.reestablishForm, showNav: true }
				}
			},
			exceptionalCircumstances: { id: 'exceptionalCircumstances', sectionName: 'Exceptional circumstances', rulesOverridden: false, evidenceRequired: false, expanded: true, incomplete: false, pageState: Constants.pageState.reestablishForm, showNav: false },
			paymentDetails: { id: 'paymentDetails', sectionName: "Payment Details", expanded: true, pageState: Constants.pageState.reestablishPayment, showNav: false }
		};

		$scope.$watch('loaded', function (newValue) {
			if (newValue) {
				notificationService.notify('clickTopMenu', {
					sectionCode: navigationService.topMenuCodes.emergencyManagement,
					pageCode: navigationService.pageCodes.reestablishApplications,
					leftSubMenuCode: 'Application',
					showMenu: $scope.item.id > 0
				});
			}
			setValidRefernceDate();
		});

		function setValidRefernceDate() {
			if ($scope.refData) {
				if ($scope.item.id != 0) {
					$scope.refData.gendersValid = angular.copy($scope.refData.genders);
					$scope.refData.idTypesValid = angular.copy($scope.refData.idTypes);
					$scope.ppoeDocumentTypes = angular.copy($scope.refData.sectionDocumentTypes);
				}
				else {
					$scope.refData.gendersValid = [];
					$scope.refData.genders.forEach(function (type) {
						if (!type.isDeleted)
							$scope.refData.gendersValid.push(type);
					});
					$scope.refData.idTypesValid = [];
					$scope.refData.idTypes.forEach(function (type) {
						if (!type.isDeleted)
							$scope.refData.idTypesValid.push(type);
					});
					$scope.ppoeDocumentTypes = [];
					$scope.refData.sectionDocumentTypes.forEach(function (type) {
						if (!type.isDeleted)
							$scope.ppoeDocumentTypes.push(type);
					});
				}
				$scope.longGenderStr = $scope.refData.gendersValid.map(function (g) { return g.description; });
				$scope.longGenders = [];
				$scope.showPayment = false;
				$scope.formValue = {};
				_.each($scope.refData.gendersValid, function (g, i) {
					$scope.longGenders.push({ id: g.id, isDeleted: g.isDeleted, description: g.description });
				});
			}
		};
		$scope.clickAccept = function () {
			notificationService.notify('showMenu', {
				pageCode: navigationService.pageCodes.reestablishApplications,
				showMenu: $scope.item.iAccept
			});
			checkAllCoreDetailsProvided();
			bizService.setApplicantHasInsurance($scope.item);
			$scope.checkCategoriesOfAssessment();
		}

		navigationService.subMenus.reestablishMenu.menu = $scope.formSections;
		navigationService.setCurrentMenuStatus(
			navigationService.topMenuCodes.emergencyManagement,
			navigationService.pageCodes.reestablishApplications,
			'Application');

		$scope.pageStates = Constants.pageState;
		$scope.pageState = Constants.pageState.reestablishForm;

		$scope.expandSections = function (expanded) {
			utils.expandSections(expanded, $scope.formSections);
			$scope.allExpanded = expanded;
		}

		$scope.$on('refDataLoaded', function (event, result) {
			$scope.refData = result.data;

			// Extract data returned from the server
		});

		$scope.$on('newDataCreated', function (event, item) {
			item.weeklyIncomeMaximumPayment = item.weeklyIncomeMaximumPayment || 0;
			$scope.formSections.privacy.expanded = true;
			$scope.allCoreDetailsProvided = false;
			$scope.showCategoriesOfAssessment = false;
		});

		shared.reliefControllerSharedBiz.apply(this, arguments);

		$scope.sections = Constants.reestablishSections;
		$scope.memberTypes = Constants.memberTypes;

		var genericSaveIneligible = $scope.saveAsIneligible;
		$scope.saveAsIneligible = function () {
			return genericSaveIneligible();
		};

		$scope.withdraw = function () {
			var title = "Withdraw this application?";
			var content = "This application will be set to the Withdrawn status and can no longer be modified. If the applicant requires re-establishment assistance a new application will have to be created.";

			utils.confirm(title, content, 'Withdraw this application', "Cancel")
				.then(function () {
					$scope.saveAsWithdrawn();
				}, function () {
				});
		}

		$scope.reapply = function () {
			var title = "Reapply?";
			var content = "This application will be set to the Draft status, allowing it to be modified and resubmitted.";

			utils.confirm(title, content, 'Reapply', "Cancel")
				.then(function () {
					$scope.saveAsReapply();
				}, function () {
				});
		}

		$scope.$on('dataLoaded', function () {
			// initialise the totalassistanceamount
			$scope.item.totalAssistanceAmount = $scope.item.exceptionalCircumstancesExist ? $scope.item.exceptionalCircumstancesAmount
				: ($scope.item.initialPaymentAmount + $scope.item.furtherPaymentAmount);

			$scope.countAdultsAndChildren();
			$scope.item.weeklyIncomeMaximumPayment = $scope.item.weeklyIncomeMaximumPayment || 0;
			bizService.getPaymentConfigurations($scope.item);
			$scope.item.documents = $scope.item.documents || {};

			if ($scope.item.id <= 0 || $scope.state.showSavedMessage) {
				$scope.expandSections(true);
				$scope.formSections.privacy.expanded = true;
			} else {
				$scope.expandSections(false);
				$scope.formSections.privacy.expanded = false;
			}

			if ($scope.eligibilityValidationResultsContainsEntries()) {
				$scope.state.showSavedMessage = false;
			}

			if ($routeParams.paymentId) {
				$scope.pageState = Constants.pageState.reestablishPayment;
				$scope.selectedPayment = $routeParams.paymentId;
			}
			checkAllCoreDetailsProvided();
			bizService.setApplicantHasInsurance($scope.item);
			$scope.checkCategoriesOfAssessment();
			//map the document strucutre for print purpose
			$scope.documentObjectToArray($scope.item.documentDictionary, "item2");
			//
			$scope.createCustomObjectForAutoScroll();
		});

		$scope.$on('onMemberChangeNotification', function (event, args) {
			$scope.countAdultsAndChildren();
		});

		$scope.$watch('item.iAccept', function (newValue) {
			if (newValue && $scope.cloned && !$scope.cloneValidated) {
				$scope.validateClonedAppAffectedAddress().then(function (valid) {
					if (valid) {
						var members = [];
						if (!!$scope.item.applicant) {
							var item = angular.copy($scope.item.applicant);
							item.dob = moment(item.dob).add(1, 'days');
							members.push(item);
						}
						if (!!$scope.item.householdMembers && $scope.item.householdMembers.length > 0) {
							_.each($scope.item.householdMembers,
								function (m) {
									var i = angular.copy(m);
									i.dob = moment(i.dob).add(1, 'days');
									members.push(i);
								});
						}
						var model = {
							incidentId: $scope.item.incidentId,
							applicationId: $scope.item.id,
							members: members
						};
						bizService.validateMembers(model).then(function (response) {
							if (!!response && !!response.data) {
								$scope.openMemberSearchResultsModal(response.data);
							}
							$scope.cloneValidated = true;
						});
					}
				});
			}
		});

		$scope.validateClonedAppAffectedAddress = function () {
			if (!$scope.cloned) {
				return true;
			}
			$scope.state.applicationsWithSameAddress = [];
			$scope.state.addressInUse = false;

			return bizService.validateAffectedAddress($scope.item.incidentId, $scope.item.applicationNumber, $scope.item.affectedAddress)
				.then(function (result) {
					var addressIsInUse = result && result.length > 0;
					if (addressIsInUse) {
						$scope.state.addressInUse = true;
						$scope.state.applicationsWithSameAddress = result;
						return $scope.openAffectedAddressModal().then(function () {
							return $scope.state.proceedOnAddressInUse;
						});
					} else {
						return true;
					}
				});
		};

		$scope.openAffectedAddressModal = function () {
			var deferred = $q.defer();

			var address = $scope.item.affectedAddress.address;
			var strAddress = '';
			if (!!address.streetNo && !!address.streetName && !!address.suburb && !!address.postcode) {
				if (!!address.unitNo) {
					strAddress = address.unitNo + '/';
				}
				strAddress += address.streetNo +
					' ' +
					address.streetName +
					', ' +
					address.suburb +
					', ' +
					address.postcode +
					', Victoria, Australia';
			}

			if (!$scope.state.affectedAddressModalOpened) {
				$scope.state.affectedAddressModalOpened = true;
				var resolve = {
					applications: function () {
						return $scope.state.applicationsWithSameAddress;
					},
					address: function () {
						return !strAddress ? $scope.item.address : strAddress;
					},
					defer: function () {
						return deferred;
					}
				};
				utils.openModal('app/home/relief/_affectedAddressCheckModalTemplate.html', 'AffectedAddressCheckModalCtrl', $scope, resolve);
			} else {
				deferred.resolve();
			}
			return deferred.promise;
		};

		$scope.openMemberSearchResultsModal = function (members) {
			var deferred = $q.defer();
			if (!$scope.state.memberSearchResultsModalOpened) {
				var model = {
					isMemberValidation: true,
					applicationType: Constants.applicationType.reestablish
				};
				$scope.state.memberSearchResultsModalOpened = true;
				var memberSearchResultsModel = {
					filter: model,
					members: members
				};

				var resolve = {
					memberSearchResultsModel: function () {
						return memberSearchResultsModel;
					},
					defer: function () {
						return deferred;
					}
				};
				var popupClass = 'search-results-modal-popup';

				utils.openModal('app/home/relief/_memberSearch.html', 'SearchMemberResultsModalCtrl', $scope, resolve, popupClass);
			} else {
				deferred.resolve();
			}
			return deferred.promise;
		};

		$scope.countAdultsAndChildren = function () {
			if ($scope.item) {
				$scope.item.adults = $scope.item.children = 0;
				$scope.item.adults = $scope.isChild($scope.item.applicant) === false ? 1 : 0;
				$scope.item.children = $scope.isChild($scope.item.applicant) === true ? 1 : 0;

				if ($scope.item.householdMembers) {
					var adults = _.filter($scope.item.householdMembers, function (m) { return $scope.isChild(m) === false; }).length;
					var children = _.filter($scope.item.householdMembers, function (m) { return $scope.isChild(m) === true; }).length;
					$scope.item.adults += adults;
					$scope.item.children += children;
				}
			}
		};

		$scope.isChild = function (member) {
			if ($scope.item && member && member.dob) {
				var asofDate = $scope.item.dateCreated ? moment($scope.item.dateCreated) : moment();
				return utils.getAge(member.dob, asofDate) < 18;
			}
		};

		$scope.validateDocumentLimit = function (options) {
			if ($scope.item && $scope.item.documents && options && options.files) {
				var count = 0;
				var toAdd = options.files.length;
				angular.forEach($scope.item.documents, function (v, k) {
					if (angular.isArray(v)) {
						count += v.length;
					}
				});
				if ((count + toAdd) > maxDocumentsAllowed) {
					options.cancelled = true;
					options.maxAllowedToAdd = maxDocumentsAllowed - count;
				}
			}
		};

		$scope.reopenApplication = function () {
			$scope.state.submitting = true;
			var model = {
				applicationId: $scope.item.id,
				rowVersion: $scope.item.rowVersion
			};
			bizService.reopenApplication(model).then(
				function (result) {
					$scope.state.submitting = false;
					utils.alert('Reestablish application has been re-opened successfully.');
					route.reload();
				},
				function (result) {
					$scope.state.submitting = false;
				}
			);
		}

		$scope.hasReopenPrivilege = function () {
			return $scope.currentUserSecurityInfo.privileges.ReopenReestablishApplication;
		}

		$scope.canReopenApplication = function () {
			return $scope.item && $scope.item.state === Constants.ReestablishApplicationState.Closed && $scope.hasReopenPrivilege();
		}

		$scope.canSave = function () {
			return $scope.item.iAccept &&
				$scope.item.state !== Constants.ReestablishApplicationState.Ineligible;
		};

		$scope.canWithdraw = function () {
			// User can withdraw applications that are saved if they are Eligible or Draft
			if (!$scope.item.id) return false;

			if ($scope.item.state !== Constants.ReestablishApplicationState.Eligible &&
				$scope.item.state !== Constants.ReestablishApplicationState.Draft) {
				return false;
			}

			return true;
		};

		$scope.canReapply = function () {
			// User can reopen saved applications that are withdrawn or ineligible
			if (!$scope.item.id) return false;

			if ($scope.item.state !== Constants.ReestablishApplicationState.Ineligible &&
				$scope.item.state !== Constants.ReestablishApplicationState.Withdrawn) {
				return false;
			}

			return true;
		};

		var checkAllCoreDetailsProvided = function () {
			if (!$scope.item.id) {
				$scope.formSections.validationRules.showNav = false;
				$scope.formSections.eligibilityCriteria.showNav = false;
				$scope.allCoreDetailsProvided = false;
			} else {
				var anyErrors = false;

				_.each($scope.frm, function (v, k) {
					if (v && v.$error && _.keys(v.$error).length>0) {
						anyErrors = true;
						return false;
					}
				});

				$scope.allCoreDetailsProvided = !anyErrors;

				$scope.formSections.validationRules.showNav = $scope.allCoreDetailsProvided;
				$scope.formSections.eligibilityCriteria.showNav = $scope.allCoreDetailsProvided;
			}
		};

		$scope.checkCategoriesOfAssessment = function () {
			var originalValue = $scope.showCategoriesOfAssessment;
			if (!$scope.allCoreDetailsProvided ||
				$scope.item.state === Constants.ReestablishApplicationState.Draft ||
				$scope.item.applicantHasInsurance == undefined) {
				$scope.showCategoriesOfAssessment = false;
			} else {
				$scope.showCategoriesOfAssessment = true;
			}

			$scope.formSections.categoriesOfAssessment.showNav = $scope.showCategoriesOfAssessment;

			if ($scope.showCategoriesOfAssessment != originalValue) {
				notificationService.notify('changeMenu');
			}
		};

		$scope.eligibilityValidationResultsContainsEntries = function () {
			if (!$scope.item) return false;
			if (!$scope.item.eligibilityValidationResults) return false;

			return Object.keys($scope.item.eligibilityValidationResults).length > 0;
		};

		$scope.getApplicantNameForTitle = function () {
			if ($scope.item && $scope.item.applicant) {
				if ($scope.item.applicant.givenName &&
					$scope.item.applicant.surname &&
					$scope.item.applicant.givenName.trim() !== '' &&
					$scope.item.applicant.surname.trim() !== '') {
					return '- ' + $scope.item.applicant.givenName + ' ' + $scope.item.applicant.surname;
				}
			}
			return '';
		};

		$scope.onPrincipalPlaceOfResidenceClick = function () {
			$scope.item.affectedAddress.principalPlaceOfResidence = $scope.item.oldPrincipalPlaceOfResidence;
		}

		$scope.onApplicantHasInsuranceClick = function () {
			bizService.setApplicantHasInsurance($scope.item);
		};

		$scope.onPreviouslyRecievedPaymentClick = function () {
			if ($scope.item.state !== Constants.ReestablishApplicationState.Ineligible) {
				// $scope.item.previouslyReceivedPayment can be set to YES for ineligible applications only (if any member already exists in the current incident)
				$scope.item.previouslyReceivedPayment = false;
			}
		}

		$scope.onIncomeExceedsThresholdClick = function () {
			if ($scope.item.state !== Constants.ReestablishApplicationState.Ineligible) {
				// $scope.item.incomeExceedsThreshold cannot be changed by clicking on this button, it can be changed in WeeklyIncome section only)
				$scope.item.incomeExceedsThreshold = $scope.item.weeklyIncomeTotalWeeklyIncome > $scope.item.weeklyIncomeLimit;
			}
		}

		$scope.clickMenu = function () {
			$scope.collapseMenu = !$scope.collapseMenu;
		}

		$scope.clickApplicationTab = function (pageState) {
			$scope.pageState = pageState;
			utils.scrollToTop(pageState.id);
			if (pageState.id == "#adjustmentDetails")
				$scope.$broadcast('load-adjustments', { loadData: true });
		}

		$scope.showOwnerTenantAssessment = function () {
			if ($scope.item && $scope.item.applicant && $scope.item.applicant.memberType) {
				var result = $scope.item.applicant.memberType.id == $scope.memberTypes.owner ||
					$scope.item.applicant.memberType.id == $scope.memberTypes.privateTenant ||
					$scope.item.applicant.memberType.id == $scope.memberTypes.publicHousingTenant;

				$scope.formSections.categoriesOfAssessment.subMenu.altAccom.showNav = result;
				return result;
			}
		}

		$scope.showOwnerAssessment = function () {
			if ($scope.item && $scope.item.applicant && $scope.item.applicant.memberType) {
				var result = (($scope.item.applicant.memberType.id === $scope.memberTypes.owner) &&
					(($scope.item.insuranceHasStructure == false && $scope.item.insuranceHasContent == true) ||
						($scope.item.insuranceHasStructure == false && $scope.item.insuranceHasContent == false) ||
						($scope.item.insuranceHasStructure == true && $scope.item.insuranceHasContent == true)));
				$scope.formSections.categoriesOfAssessment.subMenu.debrisRemoval.showNav = result;
				$scope.formSections.categoriesOfAssessment.subMenu.demolitionRebuilding.showNav = result;
				$scope.formSections.categoriesOfAssessment.subMenu.essentialRepairs.showNav = result;
				$scope.formSections.categoriesOfAssessment.subMenu.safetyReconnection.showNav = result;
				return result;
			}
			return false;
		};
		$scope.showEssentialHousehold = function () {
			if ($scope.item && $scope.item.applicant && $scope.item.applicant.memberType) {
				var result = (!($scope.item.applicant.memberType.id == $scope.memberTypes.owner) ||
					!($scope.item.insuranceHasStructure == false && $scope.item.insuranceHasContent == true && $scope.item.applicant.memberType.id == $scope.memberTypes.owner));

				$scope.formSections.categoriesOfAssessment.subMenu.essentialHousehold.showNav = result;
				return result;
			}
		}

		$scope.$watch('showHouseholderMembers', function (newValue) {
			if ($scope.formSections && newValue) {
				$scope.formSections.householdMembers.showNav = newValue;
			}
		});

		$scope.showPaymentTab = function () {
			if ($scope.item) {
				var result = $scope.item.id > 0 &&
					$scope.item.applicantHasInsurance != undefined &&
					bizService.paymentsExist($scope.item);

				$scope.formSections.paymentDetails.showNav = result;
				return result;
			}
			return false;
		}

		$scope.showPaymentAdjustmentsTab = function () {
			return $scope.showPaymentTab() &&
				$scope.currentUserSecurityInfo.privileges.ViewAdditionalFundsToReestablishPayment;
		}

		$scope.isAuthorisedToAdjustPayment = function () {
			return $scope.currentUserSecurityInfo.privileges.AddAdditionalFundsToReestablishPayment === true || false;
		}

		$scope.showExceptionalCircWarnings = function () {
			return $scope.formSections.categoriesOfAssessment.showNav &&
				$scope.item.exceptionalCircumstancesExist;
		}

		$scope.showPaymentWarnings = function () {
			return $scope.formSections.categoriesOfAssessment.showNav &&
				!$scope.item.exceptionalCircumstancesExist;
		}
		$scope.createCustomObjectForAutoScroll = function () {
			var autoScrollItem = {};
			autoScrollItem.applicant = $scope.item.applicant;
			autoScrollItem.insuranceHasStructure = $scope.item.insuranceHasStructure;
			autoScrollItem.insuranceHasContent = $scope.item.insuranceHasContent;
			$scope.autoScrollItem = autoScrollItem;
		}
		$scope.saveApplication = function () {
			$scope.createCustomObjectForAutoScroll();

			if ($scope.item.displayedState == 'Draft') {

				return bizService.validateAffectedAddress($scope.item.incidentId, $scope.item.applicationNumber, $scope.item.affectedAddress)
					.then(function (result) {
						var addressIsInUse = result && result.length > 0;
						if (addressIsInUse) {
							$scope.state.addressInUse = true;
							$scope.state.applicationsWithSameAddress = result;
							return $scope.openAffectedAddressModal().then(function () {

								if ($scope.state.proceedOnAddressInUse) {

									//validate lga in case of reopening an ineligible application
									ValidateLGA(bizService, $scope, validateAmounts, validatePrincipalPlaceOfResidence).then(function (retVal) {

										if (retVal) {
											$scope.saveData();
										}
									});

								}
								return $scope.state.proceedOnAddressInUse;
							});
						} else {

							ValidateLGA(bizService, $scope, validateAmounts, validatePrincipalPlaceOfResidence).then(function (retVal) {

								if (retVal) {
									$scope.saveData();
								}
							});

							return true;
						}
					});
			}
			else {

				ValidateLGA(bizService, $scope, validateAmounts, validatePrincipalPlaceOfResidence).then(function (retVal) {

					if (retVal) {
						$scope.saveData();
					}
				});

			}


		}

		$scope.printOrder = function () {
			$scope.printElement(document.getElementById("printableArea"));
			window.print();
		}

		$scope.printElement = function (elem) {

			var domClone = elem.cloneNode(true);
			var $printSection = document.getElementById("printSection");
			if (!$printSection) {
				var $printSection = document.createElement("div");
				$printSection.id = "printSection";
				document.body.appendChild($printSection);
			}
			$printSection.innerHTML = "";
			$printSection.appendChild(domClone);
		}

		function validateAmounts() {
			var totalPaid = bizService.getTotalPaid($scope.item.payments);
			var paymentAmount = Number($scope.item.initialPaymentAmount) + Number($scope.item.furtherPaymentAmount) + Number($scope.item.exceptionalCircumstancesAmount);
			if (totalPaid > paymentAmount) {
				var msg =
					"Based on the new information provided, the Re-establishment assistance amount $" +
					paymentAmount.toFixed(2) +
					" is less than the amount already paid $" +
					totalPaid.toFixed(2) +
					" which is not valid. The new information cannot be saved to the system. Refer to an EM Manager for further instruction.";
				utils.alert(msg, "Payment Error", 'Ok');
				return false;
			}
			return true;
		}

		function validatePrincipalPlaceOfResidence() {
			if ($scope.item.affectedAddress && !$scope.item.affectedAddress.principalPlaceOfResidence) {
				var msg =
					"The affected address must be the applicant's principal place of residence!";
				utils.alert(msg, "Principal Place Of Residence", 'Ok');
				return false;
			}
			return true;
		}

		$scope.removeMenu = function () {
			if ($scope.frm.dirty == false) {
				navigationService.subMenus.reestablishMenu.menu = {};
				notificationService.notify('showMenu', {
					pageCode: navigationService.pageCodes.reestablishApplications,
					showMenu: false
				});
			}
		}

		$scope.documentObjectToArray = function (documentObject, propertyName) {
			$scope.item.mappedDocuments = _.mapValues(_.groupBy(_.values(documentObject), propertyName),
				function (clist) {
					return clist.map(
						function (item) {
							return _.omit(item, propertyName);
						}
					)
				});
		}
	}
}());

function ValidateLGA(bizService, $scope, validateAmounts, validatePrincipalPlaceOfResidence) {
	return bizService.validateLga($scope.item.incidentId, $scope.item.affectedAddress.lga)
		.then(function(result) {
			if (result != true) {
				bizService.showInvalidLga($scope);
				return false;
			}
			else {
				if (validateAmounts() && validatePrincipalPlaceOfResidence()) {
					//clear error in form before submitting so as to display error after getting the response
					_.each($scope.frm, function(v, k) {
						if (v && v.$error) {
							v.$error = {};
						}
					});

					return true;
				}
			}
		});
}
