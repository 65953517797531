(function () {
    angular.module('phapApp')
        .component('fileList', {
            templateUrl: '/app/_directives/fileList.html',
            bindings: {
                ids: '<',
                title: '@',
                showSize: '<',
                showType: '<',
                showDate: '<',
                showUser: '<',
                showHeader: '<',
                documentTypes: '<',
                isDisabled: '<',
                hide: '<',
                onAdd: '&',
                onDelete: '&',
                onFileSelect: '&',
                onCancel: '&'
            },
            controller: ['$scope', 'documentService', 'config', 'utils', function ($scope, documentService, config, utils) {
                var ctrl = this;
                this.files = [];

                if (angular.isDefined(ctrl.isDisabled)) {
                    $scope.isDisabled = false;
                }

                $scope.$watch(function () {
                    return ctrl.ids;
                }, function (nv, ov) {
                    var lengthN = (nv || []).length;
                    var lengthO = (ov || []).length;
                    //  if (lengthN != lengthO || _.intersection(nv, ov).length != lengthN) {
                    documentService.list(nv).then(function (result) {
                        ctrl.files = result.data;
                    });
                    //  }
                });

                $scope.onFileUploaded = function (files) {
                    ctrl.files = ctrl.files.concat(files);
                    var ids = _.map(files, function (d) { return d.id; });
                    ctrl.onAdd({ ids: ids });
                    $scope.$root.$broadcast('adjustment-evidence-Uploaded', { uploadedFiles: ctrl.files });
                };

                $scope.onCancel = function () {
                    ctrl.onCancel();
                };

                $scope.onFileSelect = function (addFileOptions) {
                    if (addFileOptions.files) {
                        ctrl.onFileSelect({ options: addFileOptions });
                    }
                };

                this.deleteFile = function (file) {
                    ctrl.files = _.without(ctrl.files, file);
                    $scope.$root.$broadcast('adjustment-evidence-deleted', { uploadedFiles: ctrl.files });
                    ctrl.onDelete({ id: file.id });
                };

                this.newUpload = function () {
                    var newScope = $scope.$new(false);
                    newScope.documentTypes = ctrl.documentTypes;
                    newScope.existingFiles = ctrl.files;
                    utils.openModal('app/_directives/fileUploader.html', 'fileUploaderCtrl', newScope);
                };

                this.getDownloadUrl = function (file) {
                    return config.apiBaseUrl + "document/" + file.id;
                };

                this.downloadFile = function (file) {
                    documentService.downloadFile(file.id).then(function (successResponse) {
                        var blob = new Blob([successResponse.data], {
                            type: (successResponse.headers())['content-type']
                        });

                        saveAs(blob, file.fileName);
                    });
                }

                $scope.$on('newUpload', function (event, args) {
                    if (angular.isDefined(args)) {
                        ctrl.documentTypes = args;
                    }

                    ctrl.newUpload();
                });
            }]
        });
})();