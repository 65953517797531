(function () {
	var app = angular.module('phapApp');

	app.service('cardBulkUploadService', ['$http', '$q', 'config', 'security', 'Constants', function ($http, $q, config, security, Constants) {

		var uploadNewCards = function (fileItem) {
			var formdata = {
			};
			fileItem.formData.push(formdata);
			fileItem.url = config.apiBaseUrl + 'mis-extract/dev-card-upload';
			fileItem.upload();
		};

		var saveAttachment = function (fileItem, batchId) {
			var url;
			if (batchId) {
				url = config.apiBaseUrl + 'card-import-batch/update-attachment';
			}
			else {
				url = config.apiBaseUrl + 'card-import-batch/upload-attachment';
			}
			var formdata = {
				batchId: batchId
			};
			fileItem.formData.push(formdata);
			fileItem.url = url;
			fileItem.upload();
		};
		var updateAttachment = function (batchId) {
			var formdata = {
				batchId: batchId
			};
			fileItem.formData.push(formdata);
			fileItem.url = config.apiBaseUrl + 'card-import-batch/update-attachment';
			fileItem.upload();

		}
		var deleteAttachment = function (batchId) {
			var deferred = $q.defer();
			var result = $http.post(config.apiBaseUrl + 'card-import-batch/delete-attachment/' + batchId)
				.then(function (data, status, headers, config) {
					deferred.resolve(data);
				}, function (data, status, headers, config) {
					deferred.reject(data);
				});

			return deferred.promise;
		}

		var getUsers = function (refresh) {
			return security.getAllUsers(refresh);
		}

		var getActiveUser = function (refresh) {
			return security.getActiveUser(refresh);
		}
		var getBulkUploadDetails = function () {
			var deferred = $q.defer();

			var result = $http.post(config.apiBaseUrl + "card-import-batch/load-batch-import/")
				.then(function (data, status, headers, config) {
					deferred.resolve(data);
				}, function (data, status, headers, config) {
					deferred.reject(data);
				});

			return deferred.promise;
		}

		var getBatchWithCards = function (batchId) {
			var deferred = $q.defer();
			var res = $http.get(config.apiBaseUrl + 'card-import-batch/batch-card-details/' + batchId)
				.then(function (response, status, headers, config) {
					deferred.resolve(response.data);
				}, function (data, status, headers, config) {
					deferred.reject(data);
				});

			return deferred.promise;
		};

		var reconcileCardsInBatch = function (cardsInBatch) {
			var deferred = $q.defer();
			var res = $http.post(config.apiBaseUrl + 'card-import-batch/batch-card-reconcile', cardsInBatch)
				.then(function (response, status, headers, config) {
					deferred.resolve(response.data);
				}, function (data, status, headers, config) {
					deferred.reject(data);
				});

			return deferred.promise;
		}

		var saveForReconcileCardsInBatch = function (cardsInBatch) {
			var deferred = $q.defer();
			var res = $http.post(config.apiBaseUrl + 'card-import-batch/batch-card-reconcile-save', cardsInBatch)
				.then(function (response, status, headers, config) {
					deferred.resolve(response.data);
				}, function (data, status, headers, config) {
					deferred.reject(data);
				});

			return deferred.promise;
		}

		var getCardImportReport = function (batchId) {
			var deferred = $q.defer();
			var res = $http.get(config.apiBaseUrl + 'card-import-batch/batch-card-import-report/' + batchId)
				.then(function (response, status, headers, config) {
					deferred.resolve(response.data);
				}, function (data, status, headers, config) {
					deferred.reject(data);
				});

			return deferred.promise;
		}

		var getCardReconciliationReport = function (batchId) {
			var deferred = $q.defer();
			var res = $http.get(config.apiBaseUrl + 'card-import-batch/batch-card-reconcile-report/' + batchId)
				.then(function (response, status, headers, config) {
					deferred.resolve(response.data);
				}, function (data, status, headers, config) {
					deferred.reject(data);
				});

			return deferred.promise;
		}
		var getPurchaseOrder = function (batchId) {
			var deferred = $q.defer();		
			var res = $http({
				method: 'POST',
				url: config.apiBaseUrl + 'card-import-batch/batch-card-purchase-order-report/' + batchId,	
				responseType: 'arraybuffer'
			}).then(function (response, status, headers, config) {
					deferred.resolve(response.data);
				}, function (data, status, headers, config) {
					deferred.reject(data);
				});

			return deferred.promise;
		}

		return {

			getUsers: getUsers,
			getActiveUser: getActiveUser,
            uploadNewCards: uploadNewCards,
			getBulkUploadDetails: getBulkUploadDetails,
			getBatchWithCards: getBatchWithCards,
			reconcileCardsInBatch: reconcileCardsInBatch,
			saveForReconcileCardsInBatch: saveForReconcileCardsInBatch,
			getCardImportReport: getCardImportReport,
			getCardReconciliationReport: getCardReconciliationReport,
			saveAttachment: saveAttachment,
			updateAttachment: updateAttachment,
			deleteAttachment: deleteAttachment,
			getPurchaseOrder: getPurchaseOrder
		}

	}]);

})();