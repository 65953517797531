(function () {
    angular.module('phapApp')
        .directive('searchToggle', function () {
            return {
                restrict: 'E',
                transclude: {
                    'title': '?titleContent',
					'content': '?searchContent'
                },
                bindToController: {
                    title: '@',
                    src: '@',
                    hideArrow: '<',
                    noCollapse: '<',
                    warningRequired: '<',
                    secondWarningRequired: '<',
                    expanded: '=?',
                    overrideClass: '@'
                },
                controllerAs: '$ctrl',
                scope: true,
                replace: true,
                templateUrl: '/app/_directives/searchToggle.html',
                controller: ['$scope', function ($scope) {
                }],
                link: function (scope, elem, attr, ctrl) {
                    ctrl.noCollapse = ctrl.noCollapse || false;
                    if (ctrl.noCollapse || ctrl.expanded === undefined) {
                        ctrl.expanded = true;
                    }

                    if (!angular.isDefined(ctrl.warningRequired)) {
                        ctrl.warningRequired = false;
                    }

                    if (!angular.isDefined(ctrl.secondWarningRequired)) {
                        ctrl.secondWarningRequired = false;
                    }

                    ctrl.clickTitle = function () {
                        if (ctrl.noCollapse) return;
                        ctrl.expanded = !ctrl.expanded;
                    }
                }
            };
        });
})();