(function () {
    angular.module('phapApp')
        .directive('insuranceDetail',
        [
            '$window', function ($window) {
                return {
                    restrict: 'E',
                    scope: true,
                    templateUrl: 'app/home/reestablish/_insurance-details.html',
                    controller: ['$scope', 'Constants', 'utils', 'reestablishService', insuranceDetailCtrl]
                };
            }
        ]);

    function insuranceDetailCtrl($scope, Constants, utils, reestablishService) {

        // #region Business Rule checking

        $scope.$watchCollection('[item.applicant.memberType.id, item.insuranceHasStructure, item.insuranceHasContent, item.insuranceICAConfirmation, item.insuranceInsurerPolicy]', function (newValues) {
                $scope.insuranceCheckBusinssRules();
            });

        $scope.$on('dataLoaded', function () {
                $scope.insuranceCheckBusinssRules();
            });

        $scope.insuranceCheckBusinssRules = function () {
            reestablishService.setApplicantHasInsurance($scope.item);
            $scope.checkCategoriesOfAssessment();

            $scope.formSections.eligibilityCriteria.subMenu.insuranceDetails.validationMessages = [];
            if ($scope.item.insuranceICAConfirmation === Constants.YesNoNotyet.No) {
                $scope.formSections.eligibilityCriteria.subMenu.insuranceDetails.validationMessages.push("ICA Confirmation Required");
            }
            if ($scope.item.insuranceHasContent === true) {
                // check for tenant && Public housing tenant
                if ($scope.item.applicant.memberType.id == Constants.memberTypes.privateTenant ||
                    $scope.item.applicant.memberType.id == Constants.memberTypes.publicHousingTenant) {
                    $scope.formSections.eligibilityCriteria.subMenu.insuranceDetails.validationMessages.push("Relevant Insurances Exist");
                }

                // check for owner
                if ($scope.item.applicant.memberType.id === Constants.memberTypes.owner &&
                    $scope.item.insuranceHasStructure === true) {
                    $scope.formSections.eligibilityCriteria.subMenu.insuranceDetails.validationMessages.push("Relevant Insurances Exist");
                }
            }

            $scope.formSections.eligibilityCriteria.subMenu.insuranceDetails.rulesOverridden =
                $scope.formSections.eligibilityCriteria.subMenu.insuranceDetails.validationMessages != null &&
                $scope.formSections.eligibilityCriteria.subMenu.insuranceDetails.validationMessages.length > 0;

            
            $scope.formSections.eligibilityCriteria.subMenu.insuranceDetails.evidenceRequired = false;
            if (!$scope.formSections.eligibilityCriteria.subMenu.insuranceDetails.rulesOverridden) {
                if ($scope.item.insuranceICAConfirmation === Constants.YesNoNotyet.NotYet) {
                    // check for tenant && Public housing tenant
                    if ($scope.item.applicant.memberType.id != Constants.memberTypes.owner && $scope.item.insuranceHasContent === false) {
                        $scope.formSections.eligibilityCriteria.subMenu.insuranceDetails.evidenceRequired = true;
                    }

                    // check for owner
                    if ($scope.item.applicant.memberType.id == Constants.memberTypes.owner
                        && ($scope.item.insuranceHasStructure === false || $scope.item.insuranceHasContent === false)) {
                        $scope.formSections.eligibilityCriteria.subMenu.insuranceDetails.evidenceRequired = true;
                    }

                    // overwrite message
                    if ($scope.formSections.eligibilityCriteria.subMenu.insuranceDetails.evidenceRequired) {
                        $scope.formSections.eligibilityCriteria.subMenu.insuranceDetails.validationMessages.push("ICA Confirmation Required");
                    }
                }
            }

            reestablishService.setExceptionalCircumstances($scope.formSections, $scope.item);

        };

        $scope.insuranceOptionsChanged = function () {
            reestablishService.setApplicantHasInsurance($scope.item);

            var content;
            if ($scope.item.applicant.memberType.id === Constants.memberTypes.owner) {
                if ($scope.item.insuranceHasStructure && $scope.item.insuranceHasContent) {
                    content = 'Re-establishment assistance is not available to owners with both Structural and Contents insurance.';
                }
            } else if ($scope.item.insuranceHasContent) {
                content = 'Re-establishment assistance is not available to tenants with contents insurance.';
            }
            if (content !== undefined) {
                utils.confirm('Insurance', content, 'Proceed', 'Ineligible')
                    .then(function () {
                        $scope.insuranceCheckBusinssRules();
                    }, function () {
                        $scope.saveAsIneligible();
                    });
            }
        };

        $scope.$watchGroup(['item.insuranceHasStructure', 'item.insuranceHasContent'],
            function (nv, ov) {
                if ($scope.item && (
                    ov[0] !== undefined && ov[0] !== nv[0] ||
                    ov[1] !== undefined && ov[1] !== nv[1]
                )) {
                    $scope.insuranceOptionsChanged();
                }
            });


        // #endregion

        $scope.uploadController = {};

        var noDocumentsAttached = function() {
            if ($scope.item && $scope.item.documents) {
                var documents = $scope.item.documents[Constants.reestablishSections.insuranceDetails];
                return !angular.isDefined(documents) || documents.length === 0;
            }
            return false;
        };

        var confirm = function() {
            var title = 'ICA confirmation required!';
            var content =
                'ICA confirmation has been selected as Yes and no attachments have been loaded. \nDocuments must be loaded in order to set the ICA confirmation field to Yes.';
            utils.confirm(title, content, 'Proceed', 'Cancel')
                .then(function() {
                        $scope.item.insuranceICAConfirmation = Constants.YesNoNotyet.NotYet;
                    },
                    function() {
                        $scope.item.insuranceICAConfirmation = null;
                    });
        };

        $scope.insuranceCheckEvidence = function() {
            if (noDocumentsAttached()) {
                $scope.uploadController.$newUpload();
            }
        };

        $scope.insuranceVarifyDocumentsAttached = function() {
            if ($scope.item && $scope.item.insuranceICAConfirmation === Constants.YesNoNotyet.Yes) {
                if (noDocumentsAttached()) {
                    confirm();
                }
            }
        };
    };
})();