(function () {
    angular.module('phapApp')
        .controller(
            'homeController',
            ['$scope', '$location', 'security', '$rootScope', 'config', '$cookies', '$q', '$http',
                function ($scope, $location, security, $rootScope, config, $cookies, $q, $http) {

                    $scope.allowAccess = false;
                    $scope.item = { activeUser: null };

                    var getSecurity = function () {
                        security.getCurrentUserSecurityInfo().then(function (result) {
                            $scope.allowAccess = true;
                            $scope.userInfo.profile.given_name = result.givenName;
                            $scope.userInfo.profile.family_name = result.surname;
                        });
                    }

                    // Whenever userInfo changes, we'll refresh the current user's security info and
                    // permit the user to proceed.
                    $scope.$watch("userInfo", function (newValue) {
                        if (newValue !== undefined) {
                            if (config.authMode === 'Local') {
                                getSecurity();
                            } else if (newValue && newValue.isAuthenticated) {
                                getSecurity();
                            }
                        }
                    });

                    // we have to get the security information after successful login as the watch
                    // above does not handle this after the login
                    $scope.$on('adal:loginSuccess', function () {
                        if ($scope.userInfo && $scope.userInfo.isAuthenticated) {
                            getSecurity();
                        }
                    });

                    if (config.authMode === 'Local') {
                        var userId = $cookies.get("UserId");
                        if (!userId) {
                            userId = 1;
                            $cookies.put('UserId', userId);
                        } else {
                            userId = parseInt(userId);
                        }
                        $http.defaults.headers.common = {
                            userId: userId
                        };
                        security.getAllUsers()
                            .then(
                                function (result) {
                                    $scope.allUserInfo = result;

                                    $scope.item.activeUser =
                                        _.find($scope.allUserInfo,
                                            function (user) {
                                                return user.id == userId;
                                            });

                                    if (!$scope.item.activeUser && $scope.allUserInfo.length > 0) {
                                        $scope.item.activeUser = $scope.allUserInfo[0];
                                    }

                                    $scope.userInfo = {
                                        profile: {
                                            given_name: $scope.item.activeUser.givenName,
                                            family_name: $scope.item.activeUser.surname
                                        }
                                    }
                                }
                            );
                    }

                    $scope.showLeftMenu = function () {
                        return (
                            $location.path() !== '/dashboard' &&
                            $location.path() !== '/home/online-application-management'
                        );
                    }
                }
            ]
        );
}());