(function () {
    angular.module('phapApp')
        .controller(
            'dashboardController',
            [
                '$scope', 'security', 'dashboardService', 'navigationService','notificationService',
                function ($scope, security, dashboardService, navigationService, notificationService) {
                    onInit();

                    navigationService.setCurrentMenuStatus(
                        null, null, null);

                    function getSecurity() {
                        security.getCurrentUserSecurityInfo().then(function (result) {
                            $scope.privileges = result.privileges;
                            dashboardService.refreshAllWidgets(result.privileges);
                            $scope.taskLists = dashboardService.taskLists;
                        }, function (data, status, headers, config) {
                        });
                    };

                    function getConstants() {
                        dashboardService.getConstants().then(function (result) {
                            $scope.constants = result;
                        });
                    };

                    function onInit() {
                        getSecurity();
                        getConstants();
                    }

                }
            ]
        );
}());