(function () {
    var app = angular.module('phapApp');
    app.service('chequeService', ['$http', '$q', 'config', 'security', 'Constants', function ($http, $q, config, security, constants) {

        var chequeSearch = function (search) {
            var deferred = $q.defer();
			var res = $http.post(config.apiBaseUrl + 'payment/cheque-search/', search)
                .then(function (data, status, headers, config) {
                    deferred.resolve(data);
                }, function (data, status, headers, config) {
                    deferred.reject(data);
                });
            return deferred.promise;
        }
		var updateChequePresentedDate = function (data) {
			var deferred = $q.defer();
			var res = $http.post(config.apiBaseUrl + 'payment/cheque-update/', data)
				.then(function (data, status, headers, config) {
					deferred.resolve(data);
				}, function (data, status, headers, config) {
					deferred.reject(data);
				});
			return deferred.promise;
		}
        var exportSearch = function (searchParms) {
            var deferred = $q.defer();
			var res = $http.post(config.apiBaseUrl + 'payment/cheque-search/csv', searchParms)
                .then(function (data, status, headers, config) {
                    deferred.resolve(data);
                }, function (data, status, headers, config) {
                    deferred.reject(data);
                });
            return deferred.promise;
        }

        var getChequeViewDetails = function (id) {
            var deferred = $q.defer();
            var res = $http.post(config.apiBaseUrl + 'payment/cheque-view/details/' + id)
                .then(function (response, status, headers, config) {
                    deferred.resolve(response.data);
                },
                function (error, status, headers, config) {
                    deferred.reject(data);
                });
            return deferred.promise;
        }

        return {          
			getChequeViewDetails: getChequeViewDetails,
            chequeSearch: chequeSearch,
            exportSearch: exportSearch, 
			updateChequePresentedDate: updateChequePresentedDate
		
        }
    }]);
})();