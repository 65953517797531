(function () {
    'use strict';

    angular
        .module('phapApp')
        .factory('directDepositService', directDepositService);

    directDepositService.$inject = ['$http', '$q', 'config'];

    function directDepositService($http, $q, config) {

        var searchDirectDeposit = function (data) {
            var deferred = $q.defer();
            var res = $http.post(config.apiBaseUrl + "payment/direct-debit/search", data)
                .then(function (data, status, headers, config) {
                    deferred.resolve(data);
                }, function (data, status, headers, config) {
                    deferred.reject(data);
                });
            return deferred.promise;
        };

        var getDirectDepositDetails = function (id) {
            return $http.get(config.apiBaseUrl + "payment/direct-debit/details/" + id);
        };
        var exportSearch = function (searchParms) {
            var deferred = $q.defer();
            var res = $http.post(config.apiBaseUrl + 'payment/direct-debit/csv', searchParms)
                .then(function (data, status, headers, config) {
                    deferred.resolve(data);
                }, function (data, status, headers, config) {
                    deferred.reject(data);
                });
            return deferred.promise;
        }

        var service = {
            searchDirectDeposit: searchDirectDeposit,
            getDirectDepositDetails: getDirectDepositDetails,
            exportSearch: exportSearch
        };

        return service;

    }
})();