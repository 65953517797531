(function () {
	var app = angular.module('phapApp');
	app.component('cardAllocate', {
		templateUrl: 'app/financeManagement/cards/cardAllocate.html',
		bindings: {
			mode: '@'
		},
        controller: ['$scope', '$routeParams', '$location', '$scope', 'uiGridConstants', 'cardService', 'cardStorageService', 'cardShipmentService', 'Constants', 'config', 'utils', '$cookies', '$timeout', 'security', 'cacheService', '$q', 'navigationService', 'notificationService','$interval', '$timeout', '$cookies', 'localStorageService',
            function ($scope, $routeParams, $location, $scope, uiGridConstants, cardService, cardStorageService, cardShipmentService, constants, config, utils, $cookies, $timeout, security, cacheService, $q, navigationService, notificationService, $interval, $timeout, $cookies, localStorageService) {
				var vm = this;

				// #region Init
				vm.$onInit = function () {
                    if ($location.path().indexOf("financeManagement") > -1) {
                        navigationService.setCurrentMenuStatus(
                            navigationService.topMenuCodes.financeManagement,
                            navigationService.pageCodes.cardShipment2);
                    } else {
                        navigationService.setCurrentMenuStatus(
                            navigationService.topMenuCodes.emergencyManagement,
                            navigationService.pageCodes.cardShipment);
                    }

					vm.constants = constants;
					vm.loaded = false;
					vm.allocating = false;
					vm.searching = false;
					vm.scanning = false;
					vm.enableSearchPaging = false;
					vm.allPromises = [];

					vm.gridOptions = getGridOptions();
					vm.gridOptions.data = [];

					vm.allocationGridOptions = getAllocationGridOptions();
					vm.allocationGridOptions.data = [];

					vm.scannedCardId = null;
					vm.order = {};

					vm.refdata = {
						locations: null,
						regions: null,
						cardStatuses: [],
						storageTypes: []
					}

					vm.initSearchParms();

					vm.allPromises.push(
						security.getCurrentUserSecurityInfo().then(
							function (result) {
								vm.currentUserSecurityInfo = result;
							},
							function () {
								vm.currentUserSecurityInfo = {};
							})
					);
					vm.allPromises.push(
						cardService.getRegionLocationRefData().then(
							function (data) {
								vm.refdata.locations = data.locations;
								vm.refdata.regions = data.regions;
							}
						)
					);
					vm.allPromises.push(
						cardStorageService.getLocationRefData().then(
							function (data) {
								vm.refdata.storageTypes = data.cardStorageLocations;
							}
						)
					);
					vm.allPromises.push(
						cardShipmentService.getCardShipmentOrderDetails($routeParams.id).then(
							function (data) {
								vm.order = data;
							}
						)
					);

					$q.all(vm.allPromises).then(
						function (result) {
							vm.loaded = true;

							// Only include applicable regions (central + order location region)
							var centralRegionId = _.find(vm.refdata.locations, function (l) { return l.locationType === constants.locationType.centralHeadOffice; }).regionId;
							vm.refdata.regions = _.filter(vm.refdata.regions, function (r) { return r.id === centralRegionId || r.id === vm.order.location.regionId; });

							setRegionLocationSearchDefaults();
						});
				};

				vm.initSearchParms = function () {
					vm.search = {
						currentPage: 1,
						recordsPerPage: 100,
						region: null,
						location: null,
						cardId: '',
						cardStatuses: [],
						storageTypes: [],
						boxId: '',
						serialNumberTo: '',
						serialNumberFrom: '',
						searchMode: 1
					};

					vm.totalSearchResults = 0;
					vm.noResultsFound = false;
				};
				// #endregion

				// #region Search
				var getEmptySearchCriteria = function () {

					return {
						currentPage: undefined,
						recordsPerPage: undefined,
						region: null,
						location: null,
						cardId: '',
						searchCardStatuses: [],
						storageTypes: [],
						boxId: '',
						serialNumberFrom: '',
						serialNumberTo: '',
						excludedCardIds: [],
						searchMode: 1
					}
				}

				// TODO: FM: This should override the search criteria of card search if that is used as a component in card allocate
				var getSearchCriteria = function (pageResults) {
					var searchCriteria = angular.copy(vm.search);
					searchCriteria.searchCardStatuses = [constants.cardStatus.stored];
					searchCriteria.cardExpiryDate = moment('00010101').format('YYYY-MM-DD');
					searchCriteria.excludedCardIds = _.map(vm.allocationGridOptions.data, function (c) { return c.id; });

					if (!pageResults) {
						searchCriteria.currentPage = undefined;
						searchCriteria.recordsPerPage = undefined;
					}

					return searchCriteria;
				}

				vm.getSearchResults = function (newSearch, pageResults) {
					vm.searching = true;

					if (newSearch) {
						vm.search.currentPage = 1;
					}
					vm.saveFilters();

					var promise = $q.defer();
					cardService.cardSearch(getSearchCriteria(pageResults)).then(
						function (response) {
							vm.scannedCardId = '';
							vm.search.cardID = '';
							vm.gridApi.selection.clearSelectedRows();
							vm.search.currentPage++;
							vm.gridApi.infiniteScroll.saveScrollPercentage();
							vm.totalSearchResults = response.data.totalResultCount;
							vm.noResultsFound = vm.totalSearchResults === 0;
							vm.gridOptions.data = newSearch ? response.data.cardResults : vm.gridOptions.data.concat(response.data.cardResults);

							vm.searching = false;
							if (pageResults) {
								var hasMorePages = vm.search.currentPage < (vm.totalSearchResults / vm.search.recordsPerPage) + 1;
								vm.gridApi.infiniteScroll.dataLoaded(false, hasMorePages).then(
									function () {
										if (newSearch) {
											vm.gridApi.infiniteScroll.resetScroll(false, hasMorePages);
										}
										promise.resolve();
									});
							}
							else {
								promise.resolve();
							}
						}, function (error) {
							vm.searching = false;
							$scope.gridApi.infiniteScroll.dataLoaded();
							promise.reject();
						});
					return promise.promise;
				};

				vm.getMoreSearchResults = function () {
					vm.getSearchResults(false);
				}

				vm.searchRegionChanged = function () {
					vm.search.location = null;
				}

				var setRegionLocationSearchDefaults = function () {
					var defaultRegionId = vm.order.location.parentId ? _.find(vm.refdata.locations, function (l) { return l.id === vm.order.location.parentId; }).regionId : vm.order.location.regionId;
					var defaultLocationId = vm.order.location.parentId ? vm.order.location.parentId : vm.order.location.id;

					vm.search.region = _.find(vm.refdata.regions, function (r) { return r.id === defaultRegionId; });
					vm.search.location = _.find(vm.refdata.locations, function (l) { return l.id === defaultLocationId; });
				}

				// #endregion

				// #region Grid
				vm.openItem = function (entity) {
				};

				var getGridColumnDefs = function () {
					return [
						{
							field: 'id',
							visible: false
						},
						{
							tooltip: 'Card ID',
							field: 'cardId',
							displayName: 'Card ID',
							enableHiding: false,
							width: '12%',
							enableColumnMenu: false
						},
						{
							field: 'serialNumber',
							displayName: 'Serial number',
							type: 'number',
							enableHiding: false,
							width: '10%',
							enableColumnMenu: false
						},
						{
							field: 'expiryDate',
							displayName: 'Expiry date',
							enableHiding: false,
							width: '9%',
                            enableColumnMenu: false,
                            cellClass: 'date-field',
                            cellFilter: constants.GridDateFormat
						},
						{
							field: 'cardStatus',
							displayName: 'Card status',
							enableHiding: false,
							width: '10%',
							enableColumnMenu: false
						},
						{
							field: 'region',
							displayName: 'Region',
							enableHiding: false,
							width: '14%',
							enableColumnMenu: false
						},						
						{
							field: 'storageType',
							displayName: 'Storage type',
							enableHiding: false,
							width: '9%',
							enableColumnMenu: false
						},
						{
							field: 'boxId',
							displayName: 'Box ID',
							enableHiding: false,
							width: '9%',
							enableColumnMenu: false
						},
						{
							field: 'cardType',
							displayName: 'Card type',
							width: '8%',
							enableHiding: true,
							enableColumnMenu: false
						},
						{
							field: 'active',
							displayName: 'Active',
							enableHiding: false,
							width: '8%',
							enableColumnMenu: false
						},
						{
							field: 'location',
							displayName: 'Location',
							enableHiding: false,
							width: '19%',
							enableColumnMenu: false
						},
						
					]
				};

				var getGridOptions = function () {
					return {
						infiniteScrollRowsFromEnd: 10,
						infiniteScrollUp: false,
						infiniteScrollDown: true,
						enableSorting: true,
						enableFiltering: false,
						enableHorizontalScrollbar: uiGridConstants.scrollbars.ALWAYS,
						enableVerticalScrollbar: uiGridConstants.scrollbars.ALWAYS,
						enableRowSelection: true,
						enableFullRowSelection: true,
						enableRowHeaderSelection: true,
						enableSelectAll: true,
						multiSelect: true,
						enableRowHashing: false,
						columnDefs: getGridColumnDefs(),
						appScopeProvider: {
							selectRow: function (row) {
								vm.openItem(row.entity);
							}
						},
						rowTemplate:
						'<div ng-click="grid.appScope.selectRow(row);"> ' +
						'<div class="ui-grid-cell" ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" ui-grid-cell ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader }" style="cursor:pointer"> ' +
						'</div>' +
						'</div>',
						onRegisterApi: function (gridApi) {
							vm.gridApi = gridApi;
							if (vm.enableSearchPaging) {
								vm.gridApi.infiniteScroll.on.needLoadMoreData($scope, vm.getMoreSearchResults);
                            }
                            vm.gridApi.colResizable.on.columnSizeChanged($scope, saveStateSelect);
                            vm.gridApi.core.on.columnVisibilityChanged($scope, saveStateSelect);
                            vm.gridApi.core.on.filterChanged($scope, saveStateSelect);
                            vm.gridApi.core.on.sortChanged($scope, saveStateSelect);
                            // call resize every 500 ms for 5 s after modal finishes opening - usually only necessary on a bootstrap modal
                            $interval(function () {
                                vm.gridApi.core.handleWindowResize();
                            }, 500, 10);
                            restoreStateSelect();
						}
					}
				};

				var getAllocationGridColumnDefs = function () {
					return [
						{
							field: 'id',
							visible: false
						},
						{
							tooltip: 'Card ID',
							field: 'cardId',
							displayName: 'Card ID',
							enableHiding: false,
							width: '13%',
							enableColumnMenu: false
						},
						{
							field: 'serialNumber',
							displayName: 'Serial number',
							type: 'number',
							enableHiding: false,
							width: '12%',
							enableColumnMenu: false
						},
						{
							field: 'expiryDate',
							displayName: 'Expiry date',
							enableHiding: false,
							width: '13%',
							enableColumnMenu: false,
                            cellClass: 'date-field',
                            cellFilter: constants.GridDateFormat
						},
						{
							field: 'cardStatus',
							displayName: 'Card status',
							enableHiding: false,
							width: '10%',
							enableColumnMenu: false
						},
						{
							field: 'region',
							displayName: 'Region',
							enableHiding: false,
							width: '14%',
							enableColumnMenu: false
						},
						{
							field: 'location',
							displayName: 'Location',
							enableHiding: false,
							width: '20%',
							enableColumnMenu: false
						},
						{
							field: 'storageType',
							displayName: 'Storage type',
							enableHiding: false,
							width: '10%',
							enableColumnMenu: false
						},
						{
							field: 'boxId',
							displayName: 'Box ID',
							enableHiding: false,
							width: '10%',
							enableColumnMenu: false
						}
					]
				};

				var getAllocationGridOptions = function () {
					return {
						infiniteScrollRowsFromEnd: 10,
						infiniteScrollUp: false,
						infiniteScrollDown: true,
						enableSorting: true,
						enableFiltering: false,
						enableHorizontalScrollbar: uiGridConstants.scrollbars.ALWAYS,
						enableVerticalScrollbar: uiGridConstants.scrollbars.ALWAYS,
						enableRowSelection: true,
						enableFullRowSelection: true,
						enableRowHeaderSelection: true,
						enableSelectAll: true,
						multiSelect: true,
						enableRowHashing: false,
						columnDefs: getAllocationGridColumnDefs(),
						appScopeProvider: {
						},
						rowTemplate:
						'<div> ' +
						'<div class="ui-grid-cell" ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" ui-grid-cell ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader }" style="cursor:pointer"> ' +
						'</div>' +
						'</div>',
						onRegisterApi: function (gridApi) {
                            vm.allocationGridApi = gridApi;
                            vm.allocationGridApi.colResizable.on.columnSizeChanged($scope, saveStateAllocate);
                            vm.allocationGridApi.core.on.columnVisibilityChanged($scope, saveStateAllocate);
                            vm.allocationGridApi.core.on.filterChanged($scope, saveStateAllocate);
                            vm.allocationGridApi.core.on.sortChanged($scope, saveStateAllocate);
                            // call resize every 500 ms for 5 s after modal finishes opening - usually only necessary on a bootstrap modal
                            $interval(function () {
                                vm.allocationGridApi.core.handleWindowResize();
                            }, 500, 10);
                            restoreStateAllocate();
						}
					}
				};
				// #endregion

                function saveStateSelect() {
                    var state = vm.gridApi.saveState.save();
                    localStorageService.set('cardAllocate.gridState.select' + $cookies.get("UserId"), state);
                }

                function restoreStateSelect() {
                    $timeout(function () {
                        var state = localStorageService.get('cardAllocate.gridState.select' + $cookies.get("UserId"));
                        if (state) vm.gridApi.saveState.restore($scope, state);
                    });
                }

                function saveStateAllocate() {
                    var state = vm.allocationGridApi.saveState.save();
                    localStorageService.set('cardAllocate.gridState.allocate' + $cookies.get("UserId"), state);
                }

                function restoreStateAllocate() {
                    $timeout(function () {
                        var state = localStorageService.get('cardAllocate.gridState.allocate' + $cookies.get("UserId"));
                        if (state) vm.allocationGridApi.saveState.restore($scope, state);
                    });
                }

				// #region Filters 
				vm.clearFilters = function () {
					vm.initSearchParms();
					vm.gridOptions.data = [];
					vm.gridApi.selection.clearSelectedRows();

					setRegionLocationSearchDefaults();

					if (vm.enableSearchPaging) {
						$timeout(function () {
							// timeout required to allow for digest cycle to complete and grid to finish refresh.
							vm.gridApi.infiniteScroll.resetScroll(false, false);
						});
					}
					cacheService.removeItemFromCache('cardAllocateSearchFilters.' + $cookies.get("UserId"));
				};

				vm.saveFilters = function () {
                    cacheService.saveToCache('cardAllocateSearchFilters.' + $cookies.get("UserId"), vm.search);
				};

				vm.retrieveFilters = function () {
                    return cacheService.readFromCache('cardAllocateSearchFilters.' + $cookies.get("UserId"));
				};

				vm.loadSavedFilters = function () {
					var savedData = vm.retrieveFilters();
					if (savedData) {
						vm.search = savedData;
						// TODO: FM: Potentially enhance this in the future to not call search here and just keep the existing grid data.
						vm.getSearchResults(false, vm.enableSearchPaging);
					} else {
						vm.clearFilters();
					}
				}
				// #endregion

				// #region Allocate Actions
				vm.add = function () {
					if (!areAnyCardsActive() &&
						!isCardAllocationExceeded(vm.gridApi.selection.getSelectedCount()) &&
						isValidCardLocation(vm.gridApi.selection.getSelectedRows()[0])) {

						// add to allocation and remove from search results
						vm.allocationGridOptions.data = vm.allocationGridOptions.data.concat(vm.gridApi.selection.getSelectedRows());
						_.each(vm.gridApi.selection.getSelectedRows(),
							function (r) {
								vm.gridOptions.data.splice(vm.gridOptions.data.indexOf(r), 1);
							}
						);
						vm.gridApi.selection.clearSelectedRows();
						vm.allocateForm.$setDirty();
					}
				}

				vm.getScannedCard = function () {
					if (vm.scannedCardId.length <= 0) {
						utils.alert(constants.cardLengthErrorMessage);
						return;
					}
					else {
						if (!isCardAllocationExceeded(1) && isCardUnique(vm.scannedCardId)) {
							vm.scanning = true;
							var searchCriteria = getEmptySearchCriteria();
							searchCriteria.cardId = vm.scannedCardId;
							searchCriteria.region = _.find(vm.refdata.regions, function (r) { return r.name != 'Central'; });
							searchCriteria.centralRegionSearchIncluded = _.find(vm.refdata.regions, function (r) { return r.name == 'Central'; }).id

							cardService.cardSearch(searchCriteria).then(
								function (response) {
									vm.scanning = false;
									if (response.data.cardResults.length === 0) {
										utils.alert('Card not found.');
									}
									else {
										var card = response.data.cardResults[0];
										if (card.cardStatus !== _.find(constants.cardStatusNames, function (s) { return s.id === constants.cardStatus.stored; }).name) {
											utils.alert('Card status is invalid.');
										}
										else if (card.active.toLowerCase() == "no") {
											utils.alert('Card is not active.');
										}
										else {
											if (isValidCardLocation(card)) {
												if (vm.allocationGridOptions.data && vm.allocationGridOptions.data.length > 0) {
													var finalRows = vm.allocationGridOptions.data;
													var appendRowsToGrid = function (element) {
														if (element) {
															if (_.filter(finalRows, { cardId: element.cardId }).length === 0) {
																finalRows.unshift(element);
															}
														};
													}
													_(response.data.cardResults).forEach(appendRowsToGrid);
													vm.allocationGridOptions.data = finalRows;
												}
												else {
													vm.allocationGridOptions.data = response.data.cardResults;
												}
												vm.allocateForm.$setDirty();
												var match = _.find(vm.gridOptions.data, function (c) { return c.cardId === searchCriteria.cardId; });
												if (match) {
													vm.gridOptions.data.splice(vm.gridOptions.data.indexOf(match), 1);
												}
												searchCriteria.cardId = undefined;

												//clear the input so that they can scan the next card

											}
										}
										vm.scannedCardId = null;
										document.getElementById('scannedCardID').focus();
										vm.searchForm.cardID = null;
									}
								}, function (error) {
									vm.scanning = false;
									vm.searching = false;
									vm.scannedCardId = null;
									document.getElementById('scannedCardID').focus();
								});
						}
					}

				}

				vm.remove = function () {
					removeCards(vm.allocationGridApi.selection.getSelectedRows());
				}

				vm.allocate = function () {
					if (vm.allocationGridOptions.data.length < vm.order.cardQuantity) {
						utils.confirm('Partial order allocation', 'You have not allocated the requested number of cards to the order.<br/>Are you sure you want to proceed?', 'Confirm', 'Cancel').then(
							function () {
								allocateCards();
							}
						)
					}
					else {
						allocateCards();
					}
				}

				vm.cancel = function () {
					$location.path('/financeManagement/cardShipmentOrder/edit/' + vm.order.id + '/');
				}

				var areAnyCardsActive = function () {
					var inActive = false;
					if (vm.gridOptions.data.length > 0) {
						var inActive = _.find(vm.gridApi.selection.getSelectedRows(), function (row) { return row.active.toLowerCase()=="no"; });
						if (inActive) {
							utils.alert('One or more cards chosen are currently inactive.<br/>Please remove inactive cards from your selection.');
						}
					}
					return inActive;
				}

				var isCardAllocationExceeded = function (noCardsToAdd) {
					if ((vm.allocationGridOptions.data.length + noCardsToAdd) > vm.order.cardQuantity) {
						utils.alert('More cards have been selected than are required.<br/>Please remove any additional cards.');
						return true;
					}
					return false;
				}

				var isValidCardLocation = function (card) {
					if (vm.allocationGridOptions.data.length > 0) {
						firstCardAdded = vm.allocationGridOptions.data[0];
						if (card.region !== firstCardAdded.region || card.location !== firstCardAdded.location) {
							utils.alert('Cards cannot be selected from multiple locations.<br/>Please check the card location and remove cards from the allocation panel if necessary.');
							return false;
						}
					}
					return true;
				}

				var isCardUnique = function (cardId) {
					var match = _.find(vm.allocationGridOptions.data, function (c) { return c.cardId === cardId; });
					if (match) {
						utils.alert('Card ID already added for allocation.');
						return false;
					}
					return true;
				}

				var removeCards = function (cardsToRemove) {
					_.each(cardsToRemove,
						function (r) { vm.allocationGridOptions.data.splice(vm.allocationGridOptions.data.indexOf(r), 1); }
					);

					vm.allocationGridApi.selection.clearSelectedRows();
					if (vm.allocationGridOptions.data.length === 0) {
						vm.allocateForm.$setPristine();
					}
				}

				var allocateCards = function () {
					vm.allocating = true;
					var cardShipment = {
						id: vm.order.id,
						rowVersion: vm.order.rowVersion
					};

					var cards = _.map(vm.allocationGridOptions.data,
						function (c) {
							return {
								id: c.id,
								selected: true,
								rowVersion: c.rowVersion
							};
						});

					var allocationData = {
						cardShipment: cardShipment,
						cards: cards
					};

					cardService.allocateCards(allocationData).then(
						function (response) {
							if (response.data.allocateSuccessful) {
								vm.allocating = false;
								vm.allocateForm.$setPristine();
								$location.path('/financeManagement/cardShipmentOrder/edit/' + vm.order.id);
							}
							else {
								var invalidCards = response.data.invalidCardIds;
								utils.alert(invalidCards.length + ' of the selected cards are no longer available.<br/>Please select additional cards to complete the order.');

								var cardsToRemove = _.filter(vm.allocationGridOptions.data, function (c) { return invalidCards.indexOf(c.id) > -1; });

								removeCards(cardsToRemove);
								vm.allocating = false;
							}
						}
					);
				}
				// #endregion
			}
		],
		controllerAs: 'cardAllocate'
	});
})();