
(function () {
    angular.module('phapApp')
        .directive('triggerButtonClick', ['$filter', function ($filter) {
			return {
				restrict: 'A',
				link: function (scope, element) {

					element.bind('click', function (e) {
						angular.element('#triggerBtn').trigger('click');
					});
				}
			};
        }]);
})();