(function () {
    var app = angular.module('phapApp');

    app.factory('dashboardService', ['$http', '$q', '$timeout', 'config', 'security', 'Constants', '$interval', 'referenceService', 'errorHandlingService',
        function ($http, $q, $timeout, config, security, Constants, $interval, referenceService, errorHandlingService) {

            var service = this;

            service.taskLists = {
                pendingApprovalTasks: null,
                pendingReviewTasks: null,
                activityRequestPendingApprovalTasks: null,
                activityRequestPendingReviewTasks: null,
                evidenceAttachedTasks: null,
                cardShipmentTasks: null,
                errorsIndueTasks: null,
                errorsPhapTasks: null,
                errorsOracleTasks: null,
                invoiceDraftAndReadyTasks: null,
                invoiceSendTasks: null,
				pendingVendorTasks: null,
				cardStocktaketasks: null
            };            

            var getNotificationFontClass = function (timeDiff) {
                if (timeDiff <= 4)
                    return '';
                else if (timeDiff > 4 && timeDiff <= 8)
                    return 'task-item-font-amber';
                else
                    return 'task-item-font-red';
            };

            var getNotificationBorderFontClass = function (timeDiff) {
                if (timeDiff <= 4)
                    return 'task-item-border-none';
                else if (timeDiff > 4 && timeDiff <= 8)
                    return 'task-item-border-amber task-item-font-amber';
                else
                    return 'task-item-border-red task-item-font-red';
            };

            var getPendingInvoiceDraftAndReady = function (refresh) {

                var deferred = $q.defer();

                if (refresh || !refresh && service.taskLists.invoiceDraftAndReadyTasks === null) {
                    var res = $http.get(config.apiBaseUrl + 'payment/invoice-pending-draft-ready')
                        .then(function (response, status, headers, config) {
                            service.taskLists.invoiceDraftAndReadyTasks = setPendingInvoice(response.data);
                            deferred.resolve(response.data);
                        },
                        function (data) {
                            deferred.reject(data);
                        });
                } else {
                    deferred.resolve(service.taskLists.invoiceDraftAndReadyTasks);
                }

                return deferred.promise;
            };

            var getPendingInvoiceSend = function (refresh) {

                var deferred = $q.defer();

                if (refresh || !refresh && service.taskLists.invoiceSendTasks === null) {
                    var res = $http.get(config.apiBaseUrl + 'payment/invoice-pending-send')
                        .then(function (response, status, headers, config) {
                            service.taskLists.invoiceSendTasks = setPendingInvoice(response.data);
                            deferred.resolve(response.data);
                        },
                        function (data) {
                            deferred.reject(data);
                        });
                } else {
                    deferred.resolve(service.taskLists.invoiceSendTasks);
                }

                return deferred.promise;
            };

            var setPendingInvoice = function (list) {
                var result = [];
                list.forEach(function (item) {
                    result.push({
                        id: item.id,
                        dateCreated: item.dateCreated,
                        item1: item.invoiceReference,
                        item2: item.batchId,
                        item3: item.createdBy,
                        item4: item.invoiceAmount,
                        time: item.timeDifference,
                        incident: item.incident,
                        transactionType: item.transactionType, 
                        url: item.url
                    });
                });
                return result;
            };

            var getPendingVendor = function (refresh) {

                var deferred = $q.defer();

                if (refresh || !refresh && service.taskLists.pendingVendorTasks === null) {
                    var res = $http.get(config.apiBaseUrl + 'vendor/vendor-pending-update')
                        .then(function (response, status, headers, config) {
                            service.taskLists.pendingVendorTasks = setPendingVendor(response.data);
                            deferred.resolve(response.data);
                        },
                        function (data) {
                            deferred.reject(data);
                        });
                } else {
                    deferred.resolve(service.taskLists.pendingVendorTasks);
                }

                return deferred.promise;
            }

            var setPendingVendor = function (list) {
                var result = [];
                list.forEach(function (item) {
                    result.push({
                        id: item.id,
                        dateCreated: item.dateCreated,
                        item1: item.vendorName,
                        item2: item.applicationNumber,
                        item3: item.address,
                        item4: item.region,
                        incident: item.incident,
                        time: item.timeDifference,
                        url: item.url
                    });
                });
                return result;
            };

            var getPendingApprovalTasks = function (refresh) {

                var deferred = $q.defer();

                if (refresh || !refresh && service.taskLists.pendingApprovalTasks === null) {
                    var res = $http.get(config.apiBaseUrl + 'payment/getPendingApprovalTasks')
                        .then(function (response, status, headers, config) {
                            service.taskLists.pendingApprovalTasks = setPayments(response.data);
                            deferred.resolve(response.data);
                        },
                        function (data) {
                            deferred.reject(data);
                        });
                } else {
                    deferred.resolve(service.taskLists.pendingApprovalTasks);
                }

                return deferred.promise;
            }

            var getPendingReviewTasks = function (refresh) {
                var deferred = $q.defer();

                if (refresh || !refresh && service.taskLists.pendingReviewTasks === null) {
                    var res = $http.get(config.apiBaseUrl + 'payment/getPendingReviewTasks')
                        .then(function (response, status, headers, config) {
                            service.taskLists.pendingReviewTasks = setPayments(response.data);
                            deferred.resolve(response.data);
                        },
                        function (data) {
                            deferred.reject(data);
                        });
                } else {
                    deferred.resolve(service.taskLists.pendingReviewTasks);
                }

                return deferred.promise;
            }

            var setPayments = function (list) {
                var result = [];
                list.forEach(function (item) {
                    result.push({
                        id: item.id,
                        dateCreated: item.submissionDate,
                        item1: item.paymentNumber,
                        item2: item.applicationNumber,
                        item3: item.paymentMethod,
                        incident: item.incident,
                        item4: item.totalAmount,
                        region: item.region,
                        url: item.url
                    });
                });
                return result;
            };

            var getActivityRequestPendingApprovalTasks = function (refresh) {

                var deferred = $q.defer();

                if (refresh || !refresh && service.taskLists.activityRequestPendingApprovalTasks === null) {
                    var res = $http.get(config.apiBaseUrl + 'activityrequest/get-pending-approval-tasks')
                        .then(function (response, status, headers, config) {
                            service.taskLists.activityRequestPendingApprovalTasks = setActivityRequestOrders(response.data);
                            deferred.resolve(response.data);
                        },
                        function (data) {
                            deferred.reject(data);
                        });
                } else {
                    deferred.resolve(service.taskLists.activityRequestPendingApprovalTasks);
                }

                return deferred.promise;
			}
			var getCardStocktakePendingApprovalTasks = function (refresh) {

				var deferred = $q.defer();

				if (refresh || !refresh && service.taskLists.cardStocktaketasks === null) {
					var res = $http.get(config.apiBaseUrl + 'card/get-pending-approval-tasks')
						.then(function (response, status, headers, config) {
							service.taskLists.cardStocktaketasks = setCardStocktakeReconcilliation(response.data);
							deferred.resolve(response.data);
						},
						function (data) {
							deferred.reject(data);
						});
				} else {
					deferred.resolve(service.taskLists.cardStocktaketasks);
				}

				return deferred.promise;
			}

            var getActivityRequestPendingReviewTasks = function (refresh) {
                var deferred = $q.defer();

                if (refresh || !refresh && service.taskLists.activityRequestPendingReviewTasks === null) {
                    var res = $http.get(config.apiBaseUrl + 'activityrequest/get-pending-review-tasks')
                        .then(function (response, status, headers, config) {
                            service.taskLists.activityRequestPendingReviewTasks = setActivityRequestOrders(response.data);
                            deferred.resolve(response.data);
                        },
                        function (data) {
                            deferred.reject(data);
                        });
                } else {
                    deferred.resolve(service.taskLists.activityRequestPendingReviewTasks);
                }

                return deferred.promise;
            }

            var setActivityRequestOrders = function (list) {
                var result = [];
                list.forEach(function (item) {
                    result.push({
                        id: item.id,
                        dateCreated: item.dateCreated,
                        item1: item.orderNumber,
                        item2: item.requestType,
                        item3: item.createdByEmail,
                        item4: item.totalAmount,
                        url: item.url
                    });
                });
                return result;
			};

			var setCardStocktakeReconcilliation = function (list) {
				var result = [];
				list.forEach(function (item) {
					result.push({
						id: item.id,
                        dateCreated: item.dateCreated,	
                        item1: item.cardStocktakeNumber,
						item3: item.createdByEmail,						
						url: item.url
					});
				});
				return result;
			};

            var getEvidenceAttachedTasks = function (refresh) {
                var deferred = $q.defer();

                if (refresh || !refresh && service.taskLists.evidenceAttachedTasks === null) {
                    var res = $http.get(config.apiBaseUrl + 'reestablish/get-all-evidence-not-attached-applications')
                        .then(function (response, status, headers, config) {
                            service.taskLists.evidenceAttachedTasks = setEvidenceAttachedTasks(response.data);
                            deferred.resolve(response.data);
                        },
                        function (data) {
                            deferred.reject(data);
                        });
                } else {
                    deferred.resolve(service.taskLists.evidenceAttachedTasks);
                }

                return deferred.promise;
            }

            var setEvidenceAttachedTasks = function (list) {
                var result = [];
                list.forEach(function (item) {
                    result.push({
                        id: item.id,
                        dateCreated: item.dateCreated,
                        item1: item.applicationNumber,
                        item2: item.applicant,
                        incident: item.incident,
                        item4: item.region,
                        time: item.daysDifference,
                        url: item.url
                    });
                });
                return result;
            };

            var getCardShipmentTasks = function (refresh) {
                var deferred = $q.defer();

                if (refresh || !refresh && service.taskLists.cardShipmentTasks === null) {
                    var res = $http.get(config.apiBaseUrl + 'user/task-list')
                        .then(function (response, status, headers, config) {
                            service.taskLists.cardShipmentTasks = setCardShipmentTasks(response.data);
                            deferred.resolve(response.data);
                        },
                        function (data) {
                            deferred.reject(data);
                        });
                } else {
                    deferred.resolve(service.taskLists.cardShipmentTasks);
                }

                return deferred.promise;
            }

            var setCardShipmentTasks = function (list) {
                var result = [];
                list.pagedData.forEach(function (item) {
                    result.push({
                        id: item.id,
                        dateCreated: item.dateCreated,
                        item1: item.taskNumber,
                        item2: item.shipmentNumber,
                        item3: item.description,
                        item4: item.taskStatusDisplay,
                        region: item.regionName,
                        cards: item.numberOfCards,
                        url: '/financeManagement/cardShipmentOrder/edit/' + item.cardShipmentId
                    });
                });
                return result;
            };

            var getErrorTasks = function (errorType) {
                referenceService.getErrorRefs().then(function (response) {
                    var type = response.data.errorTypes[errorType];
                    errorHandlingService.getPage(0, 10, type, null, true).then(function (response) {
                        service.taskLists['errors' + errorType] = setError(response.data);
                    });
                });
            };

            var setError = function (list) {
                var result = [];
                list.forEach(function (item) {
                    result.push({
                        dateCreated: item.createdTime,
                        item1: item.errorNumber,
                        item2: service.ref.errorCategories[item.category],
                        item3: item.description,
                        incident: item.incident,
                        item4: service.ref.errorStatus[item.status],
                        region: "Severity: " + item.severity,
                        time: moment().diff(moment(item.createdTime), 'hours'),
                        closedTime: item.closedTime,
                        url: item.url
                    });
                });
                return result;
            };


            var refreshCardWidgets = function (privileges) {
                if (privileges.ViewCardShipmentTasks) {
                    getCardShipmentTasks(true);
                } else {
                    service.taskLists.cardShipmentTasks = [];
                }
            };
            var refreshEvidenceWidgets = function (privileges) {
                if (privileges.ViewEvidenceAttachedTasks) {
                    getEvidenceAttachedTasks(true);
                } else {
                    service.taskLists.evidenceAttachedTasks = [];
                }
            };
            var refreshVendorWidgets = function (privileges) {
                if (privileges.ViewVendorStatusTasks) {
                    getPendingVendor(true);
                } else {
                    service.taskLists.pendingVendorTasks = [];
                }
            };
            var refreshOracleWidgets = function (privileges) {
                if (privileges.ViewFeedbackOracleTasks) {
                    getPendingInvoiceSend(true);
                } else {
                    service.taskLists.invoiceSendTasks = [];
                }
                if (privileges.ViewTransactionsOracleTasks) {
                    getPendingInvoiceDraftAndReady(true);
                } else {
                    service.taskLists.invoiceDraftAndReadyTasks = [];
                }
            };

            var refreshPaymentWidgets = function (privileges) {
                if (privileges.ViewPendingApprovalTasks) {
                    getPendingApprovalTasks(true);
                } else {
                    service.taskLists.pendingApprovalTasks = [];
                }
                if (privileges.ViewPendingReviewTasks) {
                    getPendingReviewTasks(true);
                } else {
                    service.taskLists.pendingReviewTasks = [];
                }
            };
            var refreshActivityWidgets = function (privileges) {
                if (privileges.ViewActivityRequestsPendingApprovalTasks) {
                    getActivityRequestPendingApprovalTasks(true);
                } else {
                    service.taskLists.activityRequestPendingApprovalTasks = [];
                }
                if (privileges.ViewActivityRequestsPendingReviewTasks) {
                    getActivityRequestPendingReviewTasks(true);
                } else {
                    service.taskLists.activityRequestPendingReviewTasks = [];
                }
			};
			var refreshCardStocktakeWidgets = function (privileges) {
                if (privileges.ViewActivityRequestsPendingApprovalTasks) {
                    getCardStocktakePendingApprovalTasks(true);
                } else {
                    service.taskLists.cardStocktaketasks = [];
                }
				
			};
            var refreshErrorWidgets = function (privileges) {
                referenceService.getErrorRefs().then(function (response) {
                    service.ref = response.data;
                    if (privileges.ViewErrorsIndue) getErrorTasks("Indue");
                    if (privileges.ViewErrorsOracle) getErrorTasks("Oracle");
                    if (privileges.ViewErrorsPHAP) getErrorTasks("PHAP");
                });
            };

            var refreshAllWidgets = function (privileges) {
                $timeout(function () {
                    refreshCardWidgets(privileges);
                    refreshEvidenceWidgets(privileges);
                    refreshActivityWidgets(privileges);
                    refreshCardStocktakeWidgets(privileges);
                    refreshPaymentWidgets(privileges);
                    refreshOracleWidgets(privileges);
                    refreshVendorWidgets(privileges);
                }, 50);
            };

            var getConstants = function () {
                var deferred = $q.defer();

                var res = $http.get(config.apiBaseUrl + 'payment/dashboard-constants')
                    .then(function (response, status, headers, config) {
                        deferred.resolve(response.data);
                    },
                    function (error, status, headers, config) {
                        deferred.reject(error);
                    });

                return deferred.promise;
            };

            return {
                refreshAllWidgets: refreshAllWidgets,
                getPendingApprovalTasks: getPendingApprovalTasks,
                getNotificationFontClass: getNotificationFontClass,
                getNotificationBorderFontClass: getNotificationBorderFontClass,
                taskLists: service.taskLists,
                getConstants: getConstants
            }
    }]);
})();