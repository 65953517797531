(function () {
	angular
		.module('phapApp')
		.controller('assignCardLocation', ['$scope', '$timeout', 'utils', 'cardStorageService', '$q', 'Constants', 'notificationService', function ($scope, $timeout, utils, cardStorageService, $q, constants, notificationService) {
			var vm = this;
			$scope.loaded = false;
			$scope.updating = false;
			vm.boxId = null;
			vm.data = {};
			vm.data.storage = { id: null, name: null };
			vm.data.otherLocation = {}
			$scope.storage = [];		

			$scope.initAssignCardLocationDialog = function () {
				vm.allPromises = [];				
				vm.selectedCardsToBeAssigned = $scope.selectedCards;
				vm.location = $scope.location;
				vm.allPromises.push(cardStorageService.getLocationRefData()
					.then(
					function (data) {
						$scope.storage = data.cardStorageLocations;
					}
					));
				$q.all(vm.allPromises).then(
					function (result) {
						$scope.loaded = true;
					});
			};

			vm.updateLocation = function (boxId, storageId, otherLocation) {
				utils.alert(constants.backgroundMessageDescriptive, 'Assign location','Ok');
				$scope.updating = true;
				var model = {
					cards: [],
					cardStorageLocation: storageId,
					cardBoxLocation: boxId,
					otherStorageLocation: null
				};
				if (otherLocation && storageId == '5') {
					model.otherStorageLocation = otherLocation;
				};
				model.cards = vm.selectedCardsToBeAssigned;
				notificationService.notify('showLoadingPanel', true);
				cardStorageService.updateLocation(model).then(
					function (data) {	
						notificationService.notify('showLoadingPanel', false);
						$scope.updating = false;
						if (data && data.length > 0) {
							$scope.closeAndHide();
							$scope.loadData();
						}
					},
					
					function (data) {
						notificationService.notify('showLoadingPanel', false);
					}
				);
			}
			$scope.enable = function () {
				return (vm.boxId == '' && vm.storageLocation == '');
			}
			vm.applyChanges = function () {
				var content = "";
				$scope.copyBack();
				$scope.closeAndHide();
			};

			vm.locationChanged = function () {
				vm.storage = null;
			};

			this.cancelEdit = function () {
				$scope.closeAndHide();
			};

			$scope.copyBack = function () {
				// Finally set the form to dirty!
				$scope.frm.$setDirty();
			};

			$scope.closeAndHide = function () {
				$scope.$close(true);
			}
			$scope.initAssignCardLocationDialog();

		}]);
	controllerAs: 'assignCardLocation'
	// TODO : FM : Priyanka - please check the structure of this component, Visual Studio indicates that the braces / parenthesis are not correct
	//      : This could be why the $broadcast/$on event bubbling was not working, so also please re-visit that implementation when this component
	//      : is adjusted 
})();

