(function () {
    var app = angular.module('phapApp');

    app.factory('applicationSearchService', ['$http', '$q', 'config', function ($http, $q, config) {

        var searchReliefApplicationsByIncident = function (incidentId, exportToFile) {
            var deferred = $q.defer();
            var url = config.apiBaseUrl + "incident/reliefs/" + incidentId;

            $http({
                    method: 'POST',
                    url: url
                }).then (
                function success(data) {
                    deferred.resolve(data);
                },
                function error(data, status) {
                    if (data.status === 401)
                        deferred.resolve(data, status);
                });


            return deferred.promise;

        }

        var searchReestablishmentApplicationsByIncident = function (incidentId, exportToFile) {
            var deferred = $q.defer();
            var url = config.apiBaseUrl + "incident/reestablishments/" + incidentId;

            $http({
                method: 'POST',
                url: url
            }).then(
                function success(data) {
                    deferred.resolve(data);
                },
                function error(data, status) {
                    if (data.status === 401)
                        deferred.resolve(data, status);
                });


            return deferred.promise;

        }

        var searchReestablishmentApplications = function (searchCriteria, exportToFile) {
            var deferred = $q.defer();
            var url = config.apiBaseUrl + (exportToFile ? "reestablish/searchcsv" : "reestablish/search");

            $http({
                method: 'POST',
                url: url,
                data: searchCriteria
            }).then(
                function success(data) {
                    deferred.resolve(data);
                },
                function error(data, status) {
                    deferred.resolve(data, status);
                });


            return deferred.promise;

        }

       
        return {
            searchReliefApplicationsByIncident: searchReliefApplicationsByIncident,
            searchReestablishmentApplicationsByIncident: searchReestablishmentApplicationsByIncident,
            searchReestablishmentApplications: searchReestablishmentApplications
        }

    }]);

})();