(function () {
    angular
        .module('phapApp')
        .controller('errorActionController', ['$scope', 'errorHandlingService', '$q', 'config', 'security', 'Constants', 'errorRow',
            function ($scope, errorHanlingService, $q, config, security, Constants, errorRow) {                
                $scope.errorRow = errorRow;
                $scope.error = errorRow.entity;
                if ($scope.errorRow) {
                    switch ($scope.error.errorCode) {
                        case 'FailedCreateBatch':
                        case 'FailedSaveXmlGL':
                            $scope.actionTemplate = '<reset-batch error-row="errorRow" />';
                            break;
                        default:
                            break;
                    }
                };
        }]);
})();