(function () {

    var app = angular.module('phapApp');
    app.component('topMenu', {
        templateUrl: 'app/home/navigation/topMenu.html',
        bindings: {
        },
        controller: ['navigationService', 'notificationService', '$location', '$scope',
            function (navigationService, notificationService, $location, $scope) {
            var vm = this;

            notificationService.subscribe($scope, 'clickTopMenu',
                function (event, args) {
                    vm.currentSectionCode = args.sectionCode;
                });

            vm.$onInit = function () {
                navigationService.getMenu().then(function (result) {
                    vm.navStructure = result;
                    vm.sectionUrl = result.sections[0].url;
                    vm.currentSectionCode = navigationService.getCurrentMenuStatus().currentSectionCode;
                });

                
            }

            vm.goToSection = function (section) {
                vm.currentSectionCode = section.code;
                vm.sectionUrl = section.url;
                $location.path(section.url ? section.url : section.code);
                notificationService.notify('clickTopMenu', { sectionCode: section.code, pageCode: section.defaultPageCode });
            }

            vm.clickHome = function() {
                $location.path('/dashboard');
            }

        }],
        controllerAs: 'vm'
    });

})();;