(function () {

    var app = angular.module('phapApp');

    app.component('maxEssentialHouseHold', {
        templateUrl: 'app/administration/configuration/maxEssentialHouseHold.html',
        bindings: {
            mode: '@',
            configType: '@'
        },
        controller: ['$scope', '$filter', 'utils', 'Constants', 'configurationService', 'security', function ($scope, $filter, utils, Constants, configurationService, security) {

            var vm = this;
            vm.gridOptions = { showPrivilegePanel:false};
            vm.originalDataSet = [];
            vm.historyUrl = "maxEssentialHouseHold/0";
            vm.popupInfo = {
                templateUrl: 'app/administration/configuration/essentialHouseHoldPopup.html',
                controller: 'essentialHouseHoldPopupCtrl',
                size: 'sm'
            }
            var getPeriodList = function () {
                configurationService.getHouseHoldDetails(vm.configType).then(
                    function (config) {
                        setUpdateValueRules(config.data);
                    });
            }

            var init = function () {
                getSecurity();
            };
            var getSecurity = function () {
                $scope.$broadcast('displayLoading', true);
                //getting logged in user security
                security.getCurrentUserSecurityInfo()
                    .then(function (result) {
                        vm.gridOptions.currentUserSecurityInfo = result;
                        getPeriodList();
                    },
                    function () {
                        vm.gridOptions.currentUserSecurityInfo = {};
                    });
            };

            vm.validation = function (items) {
                return configurationService.houseHoldValidation(items);
            }

            vm.save = function (items, refreshAfterSave) {
                configurationService.saveHouseHoldValue(vm.gridOptions.items).then(
                    function (result) {
                        if (result.data != null) {
                            setUpdateValueRules(result.data);
                            refreshAfterSave(result);
                        }
                    },
                    // Error
                    function (result) {
                        refreshAfterSave(result);
                    });
            };

            vm.deleteversion = function (versionId) {
                configurationService.deleteHouseHold(versionId).then(
                    function (result) {
                        if (result.data) {
                            getPeriodList();
                            refreshAfterSave(result);
                        }

                    },
                    // Error
                    function (result) {
                        refreshAfterSave(result);
                    });
            };

            var setUpdateValueRules = function (data) {
                vm.gridOptions.items = data;
                vm.gridOptions.showPrivilegePanel = true;
                vm.originalDataSet = angular.copy(vm.gridOptions);
                configurationService.setHouseHoldValueRules(vm.gridOptions);
                vm.gridOptions.description = "Maximum essential household";
                $scope.$broadcast('displayLoading', false);
            }
            this.$onInit = function () {
                init();
            }

        }],
        controllerAs: 'maxEssentialHouseHoldVm'
    });

})();