(function () {
	var app = angular.module('phapApp');
	app.component('cardStorage', {
		templateUrl: 'app/financeManagement/cards/cardStorage.html',
		bindings: {
			mode: '@'
		},
        controller: ['$scope', 'cardStorageService', 'cardService', '$routeParams', '$location', 'utils', 'security', 'uiGridConstants', '$uibModal', '$q', 'navigationService', 'notificationService', '$interval','Constants',
            function ($scope, cardStorageService, cardService, $routeParams, $location, utils, security, uiGridConstants, $uibModal, $q, navigationService, notificationService, $interval, Constants) {
				var vm = this;
				vm.loaded = false;

				// #region Initialisation	

                vm.$onInit = function () {
                    if ($location.path().indexOf("financeManagement") > -1) {
                        navigationService.setCurrentMenuStatus(
                            navigationService.topMenuCodes.financeManagement,
                            navigationService.pageCodes.cardShipment2);
                    } else {
                        navigationService.setCurrentMenuStatus(
                            navigationService.topMenuCodes.emergencyManagement,
                            navigationService.pageCodes.cardShipment);
                    }

					vm.allPromises = [];
					$scope.selectedCards = [];
					vm.batchNumber = $routeParams.batchNumber;
					vm.gridOptions = getGridOptions();
					vm.initSearchFilters();

					vm.allPromises.push(security.getCurrentUserSecurityInfo()
						.then(function (result) {
							vm.currentUserSecurityInfo = result;
						}));
					$q.all(vm.allPromises).then(
						function (result) {
							vm.loaded = true;
						});
				};

				// #endregion

				// #region Clear filters

				vm.initSearchFilters = function () {
					vm.search = {
						batchNumber: vm.batchNumber,
                        cardId: vm.cardId
					};
				}
				$scope.clearFilters = function () {
					vm.initSearchFilters();
					vm.gridOptions.data = [];
				};

				// #endregion

				// #region Open storage location dialog

				$scope.openModal = function (templateUrl, controller, scope, resolve, windowClass) {
					if ($scope.dlg === undefined) {
						$scope.dlg = {
							location: $scope.location,
							selectedCardNumbers: $scope.selectedCards
						};
					}
					utils.openModal(templateUrl, controller, scope, resolve, 'storage-location-modal');
				};

				// #endregion

				// #region Grid setup / functions
				var getCardSearchGridColumnDefs = function () {
					return [
						{
							field: 'id',
							visible: false
						},
						{
							field: 'cardId',
							displayName: 'Card ID',
							enableHiding: false,
							width: '13%',
							enableColumnMenu: false
						},
						{
							field: 'expiryDate',
							displayName: 'Expiry date',
							enableHiding: false,
							width: '13%',
							enableColumnMenu: false,
                            cellFilter: Constants.GridDateFormat,
                            cellClass: 'date-field'
						},
						{
							field: 'cardStatus',
							displayName: 'Status',
							enableHiding: false,
							width: '14%',
							enableColumnMenu: false
						},
						{
							field: 'officeLocation',
							displayName: 'Location',
							enableHiding: false,
							width: '30%',
							enableColumnMenu: false
						},
						{
							field: 'storageType',
							displayName: 'Storage Type',
							enableHiding: false,
							width: '15%',
							enableColumnMenu: false
						},
						{
							field: 'boxId',
							displayName: 'Box Id',
							enableHiding: false,
							width: '15%',
							enableColumnMenu: false
						}
					]
				};

				var getGridOptions = function () {
					return {
						enableSorting: true,
						enableFiltering: false,
						enableHorizontalScrollbar: uiGridConstants.scrollbars.ALWAYS,
						enableVerticalScrollbar: uiGridConstants.scrollbars.ALWAYS,
						enableRowSelection: true,
						enableFullRowSelection: true,
						enableRowHeaderSelection: true,
						enableSelectAll: true,
						multiSelect: true,
						columnDefs: getCardSearchGridColumnDefs(),
						appScopeProvider: {
							selectRow: function (row) {
							}
						},
						rowTemplate:
						'<div ' +
						'ng-dblclick="grid.appScope.onClick(row)" ' +
						'ng-click="grid.appScope.selectRow(row, $event, 1)"> ' +
						'<div ' +
						'class="ui-grid-cell" ' +
						'ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" ' +
						'ui-grid-cell ' +
						'ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader }" ' +
						'style="cursor:pointer"> ' +
						'</div>' +
						'</div>',
						onRegisterApi: function (gridApi) {
                            vm.gridApi = gridApi;
                            // call resize every 500 ms for 5 s after modal finishes opening - usually only necessary on a bootstrap modal
                            $interval(function () {
                                vm.gridApi.core.handleWindowResize();
                            }, 500, 10);
						}
					}
				};

				// #endregion

				// #region Button methods

				vm.onAssignLocation = function () {
					$scope.selectedCards =
						_.map(vm.gridApi.selection.getSelectedRows(),
							function (c) {
								return {
									id: c.id,
									rowVersion: c.rowVersion,
									selected: true
								};
							});
					$scope.openModal("app/financeManagement/cards/assignCardLocation.html", "assignCardLocation", $scope);
				}

				vm.searchCards = function () {
					vm.searching = true;
					vm.noResultsFound = false;

					cardStorageService.searchCards(vm.search)
                        .then(function (response) {
                            vm.gridApi.selection.clearSelectedRows();

                            if (response.data.length > 0) {
								$scope.location = response.data[0].officeLocation;
								vm.gridOptions.data = response.data;
							}
							else if (response.data.length == 0) {
								vm.noResultsFound = true;
								vm.gridOptions.data = [];								
                            }
							vm.searching = false;
						});
				};

				// NOTE: Please note that this is called from the assignCardLocation.js file when it closes to refresh the data, hence 
				// it is located on the $scope (the 'broadcast/on' event bubbling does not appear to be working hence this approacah)
				$scope.loadData = function () {
					vm.searchCards();
				};

				// #endregion
			}],
		controllerAs: 'cardStorage'
	});
})();

