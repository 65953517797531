(function () {
    angular.module('phapApp')
        .controller(
            'copyrightController',
            [
                '$scope',
                function ($scope) {
                    $scope.year = new Date().getFullYear();
                }
            ]
        );
}());