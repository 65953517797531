(function () {
    angular.module('phapApp')
        .directive('urgentNeeds',
        [
            '$window', function ($window) {
                return {
                    restrict: 'E',
                    templateUrl: 'app/home/reestablish/_urgent-needs.html',
                    controller: ['$scope', '$ngConfirm', '$uibModal', 'Constants', 'utils', '$q', urgentNeesdsCtrl]
                };
            }
        ]);

    function urgentNeesdsCtrl($scope, $ngConfirm, $uibModal, Constants, utils, $q) {
    };
})();