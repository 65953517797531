(function () {

    var app = angular.module('phapApp');
    app.component('evidenceAttachedTasks', {
        templateUrl: 'app/home/dashboard/evidenceAttachedTasks.html',
        bindings: {
            tasks: '=',
            timeLapsed: '='
        },
        controller: ['$rootScope', function ($rootScope) {
            var vm = this;
        }],
        controllerAs: 'vm'
    });

})();