(function () {

    var app = angular.module('phapApp');
    app.component('activityRequestPendingTasks', {
        templateUrl: 'app/home/dashboard/activityRequestPendingTasks.html',
        bindings: {
            tasks: '=',
            heading: '@',
            noItemLabel: '@'
        },
        controller: ['dashboardService', function (dashboardService) {
            var vm = this;

        }],
        controllerAs: 'vm'
    });

})();