(function () {
    angular.module('phapApp')
        .controller(
            'glStringConfigController',
            [
                '$scope', '$http', 'config',
                function ($scope, $http, config) {
                    $http.get(config.apiBaseUrl + 'test-support/gl-string-config')
                        .then(function (response, status, headers, config) {
                            $scope.glStringConfig = response.data;
                        });
                }
            ]
        );
}());


(function () {
    angular.module('phapApp')
        .directive('segmentRule', function () {
            return {
                require: 'ngModel',
                template:
                    '<span style="color: blue" ng-if="rule.constantValue">' +
                    '    {{rule.constantValue}}' +
                    '</span>' +
                    '<span style="color: green; font-size: 0.5em" ng-if="rule.ruleDefinitionCode" ng-init="def = glStringConfig.ruleDefinitions[rule.ruleDefinitionCode]">' +
                    '    <b>{{rule.ruleDefinitionCode}}</b>' +
                    '    <div ng-repeat="v in def.possibleReturnValues">' +
                    '        <span ng-if="$index > 0">otherwise, </span>' +
                    '        <span ng-if="v.parameterEqualityConditions.length">if</span> <span ng-repeat="c in v.parameterEqualityConditions">{{c.item1}} = {{c.item2}}</span> then {{v.valueIfAllConditionsMet}}' +
                    '    </div>' +
                    '</span>',
                scope: {
                    rule: '=',
                    glStringConfig: '='
                },
                controller: ['$scope', function ($scope) {

                }]
            }
        })
}
)();