(function () {

	var app = angular.module('phapApp');
	app.component('lga', {
		templateUrl: 'app/administration/configuration/lga.html',
		bindings: {
			mode: '@'
		},
        controller: ['$scope', 'lgaService', '$routeParams', '$location', 'Constants', 'utils', 'FileUploader', 'security', 'uiGridConstants', 'navigationService','notificationService',
            function ($scope, lgaService, $routeParams, $location, constants, utils, FileUploader, security, uiGridConstants, navigationService, notificationService) {
				var vm = this;
				var isRunningInExtraSmallScreen = $(document).width() < 768;

				$scope.fileUploader = createFileUploaderForAttachment();

				vm.$onInit = function () {

					vm.loaded = false;
					vm.errorMessage = null;
					vm.successMessage = null;
					vm.selectedBatch = null;
					vm.constants = constants;
					vm.currentUserSecurityInfo = null;
					vm.totalCount = 0;
					vm.IsFileUploaded = false;

                    vm.currentSection = 'lga';
                    navigationService.setCurrentMenuStatus(
                        navigationService.topMenuCodes.administration,
                        navigationService.pageCodes.administrationconfiguration,
                        vm.currentSection);
                    

					security.getCurrentUserSecurityInfo()
						.then(function (result) {
							vm.currentUserSecurityInfo = result;
							vm.gridOptions = getGridOptions();
							loadGridData();

						},
						function () {
							vm.errorMessage = 'Could not retrieve user security information.';
						});
				};

				vm.downloadFile = function () {
					lgaService.getLgaFile().then(function (data) {
						try {
							if (data.byteLength > 10) {
								var file = new Blob([data]);
								saveAs(file, "LocalGovernmentAreaDetails.xlsx");
							}
							else {
								utils.alert("The file does not exist.");
							}
						}
						catch (e) {
							$scope.utility.openPopup('errorDownloadDoc');
						}
					})
				};

				var loadGridData = function () {
					lgaService.getLgaDetailsWithRegionAndDivision().then(
						function (response) {
							if (response.length > 0) {
								$scope.noResultsFound = false;
								vm.gridOptions.data = response;
								vm.totalCount = vm.gridOptions.data.length;
								vm.IsFileUploaded = true;
							}
							else {
								vm.IsFileUploaded = false;
							}
							vm.loaded = true;
							vm.gridLoaded = true;
						},
						function (response) {
							vm.gridLoaded = true;
							vm.loaded = true;
							vm.errorMessage = 'Could not retrieve batch information.'
						}
					);
				}

				var getBatchColumnDefs = function () {
					return [
						{
							field: 'id',
							visible: false
						},
						{
							field: 'lgaCode',
							displayName: 'Lga code',
							enableHiding: false,
							enableColumnMenu: false
						},
						{
							field: 'name',
							displayName: 'Lga name',
							enableHiding: false,
							enableColumnMenu: false
						},
						{
							field: 'status',
							displayName: 'Status',
							enableHiding: false,
							enableColumnMenu: false
						},
						{
							field: 'region.name',
							displayName: 'Region name',
							enableHiding: false,
							enableColumnMenu: false
						},
						{
							field: 'division.name',
							displayName: 'Division name',
							enableHiding: false,
							enableColumnMenu: false
						}
					]
				};

				var getGridOptions = function () {
					return {
						enableSorting: true,
						enableFiltering: false,
						enableHorizontalScrollbar: uiGridConstants.scrollbars.NEVER,
						enableVerticalScrollbar: uiGridConstants.scrollbars.ALWAYS,
						columnDefs: getBatchColumnDefs(),
						appScopeProvider: {
						},
						onRegisterApi: function (gridApi) {
							vm.gridApi = gridApi;
						}
					}
				};


				function createFileUploaderForAttachment() {
					var fileUploader = $scope.uploader = new FileUploader({});

					//method executes after the file is chosen in file uploader
					fileUploader.onAfterAddingFile = function (fileItem) {
						var userId;
						vm.successMessage = null;
						security.getActiveUser(false).then(
							function (data) {
								userId = data.id;
                                var bearerToken = sessionStorage.getItem('adal.idtoken');
								if (!bearerToken) {
									vm.errorMessage = "Current user is unauthorized";
								}
								else {
									fileItem.headers = {
										Authorization: 'Bearer ' + bearerToken,
										userId: userId
									};
									if (fileItem && fileItem.file.size > 0) {

										if (checkForValidFileType(fileItem)) {
											vm.gridLoaded = false;
											vm.errorMessage = '';
											vm.successMessage = '';
											lgaService.uploadLgaFile(fileItem);
											document.getElementById('file-input').value = "";
										}

									}
									else {
										document.getElementById('file-input').value = "";
										vm.errorMessage = "The file cannot be processed: an empty file has been selected.";
									}
								}
							}
						);
					}
					fileUploader.onErrorItem = function (fileItem, response, status, headers) {
						vm.gridLoaded = true;
						vm.successMessage = null;
						document.getElementById('file-input').value = "";
						if (status === 409) {
							vm.errorMessage = constants.errorMessage.concurrencyMessage;
						}
						else
						{ vm.errorMessage = response; }
					};
					fileUploader.onSuccessItem = function (fileItem, response, status, headers) {
						vm.errorMessage = null;
						vm.gridLoaded = true;
						vm.successMessage = "File upload is successful and the table has been refreshed.";
						document.getElementById('file-input').value = "";
						vm.gridOptions.data = response;
						vm.totalCount = vm.gridOptions.data.length;
					};
					return fileUploader;
				}

				var checkForValidFileType = function (fileItem) {
					if (isValidExtensions(fileItem.file.type, fileItem.file.name) == false) {
						document.getElementById('file-input').value = "";
						vm.errorMessage = "The file cannot be processed: the file format is incorrect";
						return false;
					}
					return true;
				}

				var isValidExtensions = function (fileType, fileName) {
					switch (fileType) {
						case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
							return true;
							break;
						default:
							return false;
					}
					return false;
				}

			}],
		controllerAs: 'lga'
	});

})();