(function () {
    var app = angular.module('phapApp');
    app.controller('activityRequestList', ['$scope', 'utils', 'reestablishService', 'item', 'payment', 'notificationService','$interval',
        function ($scope, utils, reestablishService, item, payment, notificationService, $interval) {

            var getColumnDefs = function () {
                return [
                    {
                        field: 'cardNumber',
                        displayName: 'Reference number',
                        enableHiding: false,
                        width: isRunningInExtraSmallScreen ? '25%' : '25%',
                        enableColumnMenu: false
                    },
                    {
                        field: 'requestActivityType',
                        displayName: 'Payment method',
                        enableHiding: false,
                        width: isRunningInExtraSmallScreen ? '18%' : '20%',
                        enableColumnMenu: false
                    },
                    {
                        field: 'amount',
                        displayName: 'Payment amount',
                        enableHiding: false,
                        width: isRunningInExtraSmallScreen ? '17%' : '17%',
                        cellFilter: 'currency',
                        enableColumnMenu: false,
                    },
                    {
                        field: 'paymentStatus',
                        displayName: 'Payment status',
                        enableHiding: false,
                        width: isRunningInExtraSmallScreen ? '20%' : '20%',
                        enableColumnMenu: false
                    },
                    {
                        field: 'recipientName',
                        displayName: 'Recipient Name',
                        enableHiding: false,
                        width: isRunningInExtraSmallScreen ? '20%' : '20%',
                        enableColumnMenu: false,
                        cellTemplate: nameTemplate
                    }
                ];
            };

            $scope.gridOptions = {
                enableSorting: true,
                showTreeRowHeader: false,
                enableFiltering: false,
                enableHorizontalScrollbar: uiGridConstants.scrollbars.NEVER,
                enableRowHeaderSelection: false,
                enableSelectAll: false,
                multiSelect: false,
                enableRowHashing: false,
                enableColumnMenus: false,
                columnDefs: getColumnDefs(),
                appScopeProvider: {
                    onDblClick: function (row) {
                        if (!$scope.iosOrMobile) {
                            $scope.openItem(row.entity);
                        }
                    },
                    onEditTouchend: function (row) {
                        $scope.openItem(row.entity);
                    },
                    selectRow: function (row) {
                        $scope.openItem(row.entity);
                    }
                },

                rowTemplate:
                '<div ' +
                'ng-dblclick="grid.appScope.onDblClick(row)" ' +
                'ng-click="grid.appScope.selectRow(row, $event, 1)"> ' +
                '<div ' +
                'class="ui-grid-cell" ' +
                'ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" ' +
                'ui-grid-cell ' +
                'ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader }" ' +
                'style="cursor:pointer"> ' +
                '</div>' +
                '</div>',

                onRegisterApi: function (gridApi) {
                    $scope.gridApi = gridApi;
                    $scope.gridApi.core.on.rowsRendered($scope, rowsRendered);
                    // call resize every 500 ms for 5 s after modal finishes opening - usually only necessary on a bootstrap modal
                    $interval(function () {
                        vm.gridApi.core.handleWindowResize();
                    }, 500, 10);
                }
            };
            $scope.gridOptions.data = [];


            $scope.cancel = function () {
                $scope.$dismiss();
            }
            
        }]);
})();