(function () {
    angular.module('phapApp')
        .directive('serverValid', ['$window', function ($window) {
            return {
                require: 'ngModel',
                link: function (scope, elem, attrs, ctrl) {
                    ctrl.$validators.serverValid = function () {
                        return true;
                    };
                }
            };
        }]);
})();