(function () {
    var app = angular.module('phapApp');
    app.component('cardView', {
        templateUrl: 'app/financeManagement/cards/cardView.html',
        bindings: {
            mode: '@'
        },
        controller: ['$scope', '$routeParams', 'uiGridConstants', '$q', '$location', 'cardService', 'Constants', '$window', 'security', 'navigationService', 'notificationService', '$interval',
            function ($scope, $routeParams, uiGridConstants, $q, $location, cardService, constants, $window, security, navigationService, notificationService, $interval) {
                var vm = this;
                vm.loaded = false;
                vm.constants = constants;
                vm.refreshingBalance = false;
                vm.$onInit = function () {
                    if ($location.path().indexOf("financeManagement") > -1) {
                        navigationService.setCurrentMenuStatus(
                            navigationService.topMenuCodes.financeManagement,
                            navigationService.pageCodes.cardSearch);
                    }
                    ///new details

                    vm.allPromises = [];
                    vm.allPromises.push(security.getCurrentUserSecurityInfo()
                        .then(function (result) {
                            vm.currentUserSecurityInfo = result;
                            if (vm.currentUserSecurityInfo.privileges.ViewCardDetails === true) {
                                vm.card = {
                                    id: $routeParams.id
                                };
                            }
                            else {
                                vm.loaded = true;
                            }
                        },
                        function () {
                            vm.currentUserSecurityInfo = {};
                            vm.loaded = true;
                        }));

                    $q.all(vm.allPromises).then(
                        function (result) {
                            cardService.getCardViewDetails(vm.card.id)
                                .then(function (result) {
                                    vm.cardViewDetails = result;

                                    var gridOptions = [];

                                    _.forEach(vm.cardViewDetails.paymentCardViewDetails, function (paymentDetail) {

                                        paymentDetail.gridOption = getGridOptions();
                                        paymentDetail.gridOption.data = paymentDetail.cardPaymentTransactions;

                                        paymentDetail.postedDate = paymentDetail.cardPaymentTransactions.length > 0 && paymentDetail.cardPaymentTransactions[0].postedDate !== "" ? paymentDetail.cardPaymentTransactions[0].postedDate : "";
                                        paymentDetail.postedPeriod = paymentDetail.cardPaymentTransactions.length > 0 ? paymentDetail.cardPaymentTransactions[0].postedPeriod : "";

                                        paymentDetail.dateCreated = moment(paymentDetail.dateCreated).format('DD-MM-YYYY');
                                        paymentDetail.paymentDate = moment(paymentDetail.paymentDate).format('DD-MM-YYYY');
                                        paymentDetail.incident.incidentDate = moment(paymentDetail.incident.incidentDate).year() == "1" ? "" : moment(paymentDetail.incident.incidentDate).format('DD-MM-YYYY');
                                        paymentDetail.oraclePaymentId = paymentDetail.oraclePaymentId > 0 ? paymentDetail.oraclePaymentId : "";
                                        paymentDetail.oraclePaymentAmount = paymentDetail.oraclePaymentAmount != 0 ? paymentDetail.oraclePaymentAmount : "";
                                    });

                                    _.forEach(vm.cardViewDetails.activityRequestCardViewDetails, function (activityRequestDetail) {
                                        _.forEach(activityRequestDetail.activityRequestCardViewDetailsModels, function (item) {

                                            if (item.activityRequestTransactions.length > 0) {
                                                item.gridOption = getGridOptions();
                                                item.gridOption.data = [];
                                                item.gridOption.data = item.activityRequestTransactions;
                                            }
                                        });
                                    });
                                    vm.loaded = true;
                                });
                        });
                }
                var getGridColumnDefs = function () {
                    return [
                        {
                            field: 'id',
                            visible: false,
                            enableHiding: false
                        },
                        {
                            field: 'batchNumber',
                            displayName: 'BatchNumber',
                            enableHiding: false,
                            width: '12%'
                        },

                        {
                            field: 'reference1',
                            displayName: 'Details',
                            enableHiding: true,
                            width: '12%'
                        },
                        {
                            field: 'amount',
                            displayName: 'Amount',
                            enableHiding: false,
                            width: '12%'
                        },
                        {
                            field: 'transactionDate',
                            displayName: 'Transaction date',
                            enableHiding: false,
                            width: '12%'
                        },
                        {
                            field: 'transactionType',
                            displayName: 'Transaction type',
                            enableHiding: false,
                            width: '12%'
                        },
                        {
                            field: 'transactionNumber',
                            displayName: 'Transaction number',
                            enableHiding: false,
                            width: '12%'
                        },

                        {
                            field: 'transactionStatus',
                            displayName: 'Transaction Status',
                            enableHiding: false,
                            width: '12%'
                        },
                        {
                            field: 'entity',
                            displayName: 'Entity',
                            enableHiding: false,
                            width: '12%'
                        },
                        {
                            field: 'costCentre',
                            displayName: 'Cost centre',
                            enableHiding: true,
                            width: '12%'
                        },
                        {
                            field: 'activity',
                            displayName: 'Activity',
                            enableHiding: true,
                            width: '12%'
                        },
                        {
                            field: 'fund',
                            displayName: 'Fund',
                            enableHiding: true,
                            width: '12%'
                        },
                        {
                            field: 'account',
                            displayName: 'Account',
                            enableHiding: true,
                            width: '12%'
                        },
                        {
                            field: 'product',
                            displayName: 'Product',
                            enableHiding: true,
                            width: '12%'
                        },

                        {
                            field: 'identifier',
                            displayName: 'Identifier',
                            enableHiding: false,
                            width: '12%'
                        },

                        {
                            field: 'rejectionReason',
                            displayName: 'Rejection Reason',
                            enableHiding: true,
                            width: '12%'
                        },
                    ]
                };
                var rowtpl = '<div ng-class="{\'grey\':true, \'red\':row.entity.transactionType == 9012 }"><div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader }" ui-grid-cell></div></div>';
                var getGridOptions = function () {
                    return {
                        infiniteScrollRowsFromEnd: 10,
                        infiniteScrollUp: false,
                        enableGridMenu: true,
                        infiniteScrollDown: true,
                        enableSorting: true,
                        enableFiltering: false,
                        enableColumnResizing: true,
                        enableHorizontalScrollbar: uiGridConstants.scrollbars.ALWAYS,
                        enableVerticalScrollbar: uiGridConstants.scrollbars.ALWAYS,
                        enableRowSelection: true,
                        enableFullRowSelection: true,
                        enableRowHeaderSelection: false,
                        enableSelectAll: true,
                        multiSelect: false,
                        enableRowHashing: false,
                        columnDefs: getGridColumnDefs(),
                        appScopeProvider: {
                        },
                        rowTemplate: rowtpl,
                        onRegisterApi: function (gridApi) {
                            vm.gridApi = gridApi;
                            // call resize every 500 ms for 5 s after modal finishes opening - usually only necessary on a bootstrap modal
                            $interval(function () {
                                vm.gridApi.core.handleWindowResize();
                            }, 500, 10);
                        }
                    }
                };
                vm.printOrder = function (divName) {
                    vm.printElement(document.getElementById("printableArea"));
                    window.print();
                }
                vm.printElement = function (elem) {
                    var domClone = elem.cloneNode(true);
                    var $printSection = document.getElementById("printSection");
                    if (!$printSection) {
                        var $printSection = document.createElement("div");
                        $printSection.id = "printSection";
                        document.body.appendChild($printSection);
                    }
                    $printSection.innerHTML = "";
                    $printSection.appendChild(domClone);
                }
                vm.cancel = function () {
                    if ($routeParams.type && $routeParams.type == 'activity') {
                        $window.history.back();
                    } else {
                        $location.path("/financeManagement/cards/search");
                    }
                }
                vm.refreshBalance = function () {
                    vm.refreshingBalance = true;
                    cardService.refreshCardAvailableBalance(vm.cardViewDetails.cardDetails.cardId)
                        .then(function (result) {
                            vm.cardViewDetails.cardDetails.availableBalance = result;
                        }).finally(function () {
                            vm.refreshingBalance = false;
                        });
                }
                vm.viewHistory = function () {
                    $location.path("/administration/history/card/" + vm.card.id + "/" + vm.cardViewDetails.cardDetails.cardId);
                }
                vm.redirectToIncident = function (id) {
                    $location.path('/administration/incidents/edit/' + id);
                };
                vm.redirectToRelief = function (id) {
                    $location.path('/home/relief/edit/' + id);
                };
                vm.redirectToCardShipmentDetails = function (id) {
                    $location.path('/financeManagement/cardShipmentOrder/edit/' + id);
                };
                vm.redirectToReestablishment = function (id) {
                    $location.path('/home/reestablish/edit/' + id);
                };
                vm.redirectToActivityRequestOrder = function (id, cardTypeId) {
                    $location.path('/financeManagement/cards/cardActivity/' + parseInt(cardTypeId) + '/' + parseInt(id));
                };
            }

        ],
        controllerAs: 'cardView'
    });
})();