(function () {
    angular.module('phapApp')
        .directive('damageAssessment',
        [
            '$window', function ($window) {
                return {
                    restrict: 'E',
                    templateUrl: 'app/home/reestablish/_damage-assessment.html',
                    controller: ['$scope', 'Constants', 'utils', 'reestablishService', '$filter', damageAssessmentCtrl]
                };
            }
        ]);

    function damageAssessmentCtrl($scope, Constants, utils, reestablishService, $filter) {

        $scope.Constants = Constants;
        $scope.damageAssessmentUploadController = {};

        // #region Business Rule checking

        $scope.$on('dataLoaded', function () {
            $scope.damageAssesmentCheckBusinessRules();
        });

        $scope.$watchCollection('[item.damageAssesmentPropertyInaccessibleGreaterSevenDays, item.damageAssesmentPropertyUninhabitableGreaterSevenDays, item.damageAssesmentPropertyBeenDestroyed, item.damageAssesmentEvidenceAttached]', function (newValues) {
            $scope.damageAssesmentCheckBusinessRules();
        });

        $scope.damageAssesmentCheckBusinessRules = function () {

            var incidentdate;
            if ($scope.item && $scope.item.incident &&  $scope.item.incident.incidentDate)
                incidentdate = $filter('phapDate')($scope.item.incident.incidentDate);

            if (incidentdate &&  !$scope.item.uninhabitableDays ){
                reestablishService.loadPeriodConfig(incidentdate).then(function (data) {
                    if (data.uninhabitableDays && data.uninhabitableDays.value) {
                        $scope.item.uninhabitableDays = data.uninhabitableDays.value;
                    }
                });
            }

            var noDocs = damageAssesmentNoDocumentsAttached();

            $scope.formSections.eligibilityCriteria.subMenu.damageAssessment.rulesOverridden = $scope.item.damageAssesmentEvidenceAttached === Constants.YesNoNotyet.No;
            $scope.formSections.eligibilityCriteria.subMenu.damageAssessment.evidenceRequired = $scope.item.damageAssesmentEvidenceAttached === Constants.YesNoNotyet.NotYet;
            reestablishService.setExceptionalCircumstances($scope.formSections, $scope.item);
        };

        // #endregion 

        var damageAssesmentNoDocumentsAttached = function () {
            if ($scope.item && $scope.item.documents) {
                var documents = $scope.item.documents[Constants.reestablishSections.damageAssessment];
                return !angular.isDefined(documents) || documents.length === 0;
            }
            return false;
        };

        var confirm = function () {
            var title = 'Evidence documents required!';
            var content = 'Evidence attached has been selected as Yes and no attachments have been loaded. \nDocuments must be loaded in order to set the Evidence attached field to Yes.';
            utils.confirm(title, content, 'Proceed', 'Cancel')
                .then(function () {
                    $scope.item.damageAssesmentEvidenceAttached = Constants.YesNoNotyet.NotYet;
                }, function () {
                    $scope.item.damageAssesmentEvidenceAttached = null;
                });
        };

        $scope.damageAssesmentCheckEvidence = function () {
            if (damageAssesmentNoDocumentsAttached()) {
                $scope.damageAssessmentUploadController.$newUpload($scope.damageAssesmentNotes);
            }
        };

        $scope.damageAssesmentOnCancelUpdate = function () {
            if (damageAssesmentNoDocumentsAttached()) {
                confirm();
            }
        };

        $scope.damageAssesmentOnFileListChanged = function () {
            if ($scope.item && $scope.item.damageAssesmentEvidenceAttached === Constants.YesNoNotyet.Yes) {
                if (damageAssesmentNoDocumentsAttached()) {
                    confirm();
                }
            }
            $scope.damageAssesmentCheckBusinessRules();
        };

        $scope.damageAssessmentShowNoSelectedWarning = function () {
            var title = 'Damage assessment';
            var content = 'Re-establishment assistance is only available when Damage assessment is able to be confirmed';
            utils.confirm(title, content, 'Proceed', 'Ineligible')
                .then(function () {
                    // NOTE: We leave the button as NO, as this is an override / proceed scenario
                    $scope.damageAssesmentCheckBusinessRules();
                }, function () {
                    $scope.saveAsIneligible();
                });
        };
    };
})();