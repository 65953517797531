(function () {
    angular.module('phapApp')
        .directive('essentialRepairs', ['Constants', 'utils', function (Constants, utils) {
            return {
                scope: true,
                restrict: 'E',
                templateUrl: 'app/home/reestablish/_essential-repairs.html',
                controller: ['$scope','reestablishService', function ($scope, reestablishService) {

                    $scope.uploadController = {};

                    // #region Business Rule checking

                    $scope.$watchCollection('[item.essentialRepairsClaimAmount, item.essentialRepairsEvidenceAttached]', function (newValues) {
                        if (!angular.isDefined(newValues[0]))
                            return;
                        $scope.essentialRepairsBusinssRules();
                    });

                    $scope.$on('dataLoaded', function () {
                        $scope.essentialRepairsBusinssRules();
                    });

                    $scope.essentialRepairsBusinssRules = function () {
                        var amount = utils.parseDollarAmount($scope.item.essentialRepairsClaimAmount);
                        $scope.formSections.categoriesOfAssessment.subMenu.essentialRepairs.evidenceRequired = ($scope.item.applicant.memberType.id === Constants.memberTypes.owner && amount > 0 && $scope.item.essentialRepairsEvidenceAttached === false); 
                        reestablishService.setExceptionalCircumstances($scope.formSections, $scope.item);
                    };

                    // #endregion 

                    var essentialRepairsNoDocumentsAttached = function () {
                        if ($scope.item && $scope.item.documents) {
                            var documents = $scope.item.documents[Constants.reestablishSections.essentialRepairs];
                            return !angular.isDefined(documents) || documents.length === 0;
                        }
                        return false;
                    };

                    var confirm = function () {
                        var title = 'Evidence documents required!';
                        var content = 'Evidence attached has been selected as Yes and no attachments have been loaded. \nDocuments must be loaded in order to set the Evidence attached field to Yes.';
                        utils.confirm(title, content, 'Proceed', 'Cancel')
                            .then(function () {
                                $scope.item.essentialRepairsEvidenceAttached = false;
                            }, function () {
                                $scope.item.essentialRepairsEvidenceAttached = null;
                            });
                    };

                    $scope.essentialRepairsCheckEvidence = function () {
                        if (essentialRepairsNoDocumentsAttached()) {
                            $scope.uploadController.$newUpload();
                        }
                    };

                    $scope.$watch('item.applicant.memberType.id', function (nv, ov) {
                        if (!$scope.loaded || !$scope.item.documents) return;
                        var documents = $scope.item.documents[Constants.reestablishSections.essentialRepairs];
                        if (documents && documents.length) {
                            if (nv != null && ov != null && nv != ov && nv != Constants.memberTypes.owner) {
                                utils.alert('Evidence documents are attached for essential repairs. Please remove them before changing applicant to tenant.', 'Evidence documents attached!');
                                $scope.item.applicant.memberType.id = Constants.memberTypes.owner;
                            }
                        }
                    });

                    var essentialRepairsVerifyDocumentsAttached = function () {
                        if ($scope.item && $scope.item.essentialRepairsEvidenceAttached) {
                            if (essentialRepairsNoDocumentsAttached()) {
                                confirm();
                            }
                        }
                    };

                    $scope.essentialRepairsOnCancelUpdate = function () {
                        essentialRepairsVerifyDocumentsAttached();
                    };

                    $scope.essentialRepairsOnFileListChanged = function () {
                        essentialRepairsVerifyDocumentsAttached();
                    };
                }]
            };
        }]);
})();