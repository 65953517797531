(function () {
    var app = angular.module('phapApp');
    app.controller('activityRequestRejectPopupController', ['$scope', 'utils', 'cardService', 'notificationService', 'order',
        function ($scope, utils, cardService, notificationService, order) {
            $scope.showRejectMessage = false;
            $scope.rejectionReason = "";
            $scope.reject = function () {
                if (utils.isNullOrEmpty($scope.rejectionReason)) {
                    $scope.showRejectMessage = true;
                } else {
                    var submitModel = {
                        id: order.id,
                        rejectionReason: $scope.rejectionReason,
                        rowVersion: order.rowVersion
                    };
                    notificationService.notify('showLoadingPanel', true);
                    cardService.rejectActivityOrder(submitModel).then(function (data) {
                        notificationService.notify('showLoadingPanel', false);
                        $scope.$close(data);
                    }, function () {
                        notificationService.notify('showLoadingPanel', false);
                    });
                }
            }
            $scope.cancel = function () {
                $scope.$dismiss();
            }

        }]);
})();