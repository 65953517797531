(function () {
	angular.module('phapApp')
		.directive('adjustmentDetail', ['Constants', 'utils', '$route', 'documentService', 'notificationService', function (Constants, utils, $route, documentService, notificationService) {
			return {
				scope: true,
				restrict: 'E',
				scope: {
					isNew: '=',
					date: '=',
					adjustmentPayment: '=',
					isRelief: '=',
					applicationId: '=',
					remainingEntitlementAmount: '='
				},
				templateUrl: 'app/home/adjustments/adjustmentDetail.html',
				controller: ['$scope', 'adjustmentService', 'documentService', 'config', '$timeout', function ($scope, adjustmentService, documentService, config, $timeout) {
					$scope.showAllFieldMandatoryError = false;
					$scope.showAmountError = false;
					$scope.showEvidenceAttachmentError = false;
					$scope.showNotesError = false;
					$scope.showDocumentAttachmentError = false;
					$scope.IsAdjustmentAddedSuccessfully = false;

					$scope.baseApiUrl = config.apiBaseUrl;

					$scope.uploadController = {};

					var confirm = function () {
						var title = 'Evidence documents required!';
						var content = 'Evidence attached has been selected as Yes and no attachments have been loaded. \nDocuments must be loaded in order to set the Evidence attached field to Yes.';
						utils.confirm(title, content, 'Proceed', 'Cancel')
							.then(function () {
								$scope.adjustmentPayment.isEvidenceAttached = false;
							}, function () {
								$scope.uploadController.$newUpload();
							});
					};

					$scope.showConfirmationPopup = function () {
						var content = 'The Adjustment amount is only available when Adjustment amount approval is able to be confirmed';

						utils.confirm('', content).then(function () {
							$scope.adjustmentPayment.isEvidenceAttached = false;
						});
					}

					var discardFile = function (bytes) {
						var file = documentService.calculateFileSize(bytes);
						if (file.number > 2) //everthing above MB
							return true;
						if (file.number == 2 && file.size > Constants.FileMaxUploadSize) // MB and greater than 2
							return true;
						return false;
					};

					$scope.downloadFile = function (file) {
						documentService.downloadFile(file.documentId).then(function (successResponse) {
							var blob = new Blob([successResponse.data], {
								type: (successResponse.headers())['content-type']
							});

							saveAs(blob, file.documentName);
						});
					}

					$scope.save = function () {
						$scope.saving = true;

						$scope.showAllFieldMandatoryError = false;
						$scope.showAmountError = false;
						$scope.showEvidenceAttachmentError = false;
						$scope.showNotesError = false;
						$scope.showDocumentAttachmentError = false;

						if ($scope.adjustmentPayment != null) {
							if ($scope.adjustmentPayment.amount == undefined) {
								$scope.adjustmentPayment.amount = "$0.00";
							}
							$scope.adjustmentPayment.amount = utils.parseDollarToNumber($scope.adjustmentPayment.amount);
							if ($scope.adjustmentPayment.amount == null || $scope.adjustmentPayment.notes == null || $scope.adjustmentPayment.files == null) {
								$scope.showAllFieldMandatoryError = true;
								$scope.saving = false;
								return;
							}

							if ($scope.adjustmentPayment.amount != null && $scope.adjustmentPayment.amount == 0) {
								$scope.showAmountError = true;
								$scope.saving = false;
								return;
							}

							if ($scope.adjustmentPayment != null && $scope.adjustmentPayment.notes == '') {
								$scope.saving = false;
								$scope.showNotesError = true;
								return;
							}
							if ($scope.adjustmentPayment.files != null && $scope.adjustmentPayment.files.length == 0) {
								$scope.showDocumentAttachmentError = true;
								$scope.saving = false;
								return;
							}

							$scope.adjustmentPayment.files.forEach(function (file, i) {
								if (discardFile(file.size))
									$scope.adjustmentPayment.files.splice(i, 1);
							});
							var fileIds = [];
							$scope.adjustmentPayment.files.forEach(function (file) {
								fileIds.push(file.id);
							});

							if ($scope.applicationId.ReliefApplicationId != null) {
								$scope.adjustmentPayment.reliefApplicationId = $scope.applicationId.ReliefApplicationId;
							}
							else if ($scope.applicationId.ReestablishApplicationId != null) {
								$scope.adjustmentPayment.reestablishApplicationId = $scope.applicationId.ReestablishApplicationId;
							}

							$scope.adjustmentPayment.documents = { $DEFAULTS$: fileIds };

							// If the user has special access (confirmed on the backend), they can send the remainingEntitlementAmount below zero. Warn them before proceeding.
							if ($scope.adjustmentPayment.amount < 0 && Math.abs($scope.adjustmentPayment.amount) > $scope.remainingEntitlementAmount) {
								utils.confirm('Confirm negative payment adjustment', 'Making this payment adjustment will set the remaining entitlement amount to a value below zero. Are you sure you want to proceed?', 'Proceed', 'Cancel').then(function () {
									saveAdjustment();
								});
							}
							else {
								saveAdjustment();
							}
						}
					};

					var saveAdjustment = function () {
						notificationService.notify('showLoadingPanel', true);
						adjustmentService.saveAdjustment($scope.adjustmentPayment).then(
							function (result) {
								$scope.saving = false;
								$scope.IsAdjustmentAddedSuccessfully = true;
								notificationService.notify('showLoadingPanel', false);
								utils.alert('Adjustment has been successfully saved.', 'Save successful.', 'OK');
								//this forces the whole reestalbishment to reload so that we have always fresh updated data from the server
								$route.reload();
							},
							function (error) {
								$scope.IsAdjustmentAddedSuccessfully = false;
								notificationService.notify('showLoadingPanel', false);
								$scope.adjustmentPayment.amount = utils.parseDollarToNumber($scope.adjustmentPayment.amount);

								$scope.validationError = true;
								$scope.validationErrorMsgs = error.data.errors;
							});
					}

					$scope.close = function () {
						$scope.open = false;
					};

					$scope.$root.$on('adjustment-evidence-Uploaded', function (event, args) {
						$scope.adjustmentPayment.files = args.uploadedFiles
					});

					$scope.$root.$on('adjustment-evidence-deleted', function (event, args) {
						$scope.adjustmentPayment.files = args.uploadedFiles
					});

					//attachment functions
					$scope.onCancelUpdate = function () {
						verifyDocumentsAttached();
					};

					$scope.onFileListChanged = function () {
						verifyDocumentsAttached();
					};

					var verifyDocumentsAttached = function () {
						if ($scope.adjustmentPayment) {
							if (noDocumentsAttached()) {
								showConfirmationPopup();
							}
						}
					};
					var noDocumentsAttached = function () {
						if ($scope.adjustmentPayment.files == null) {
							return true;
						}
						else {
							return false;
						}
					};

					$scope.checkEvidence = function () {
						$scope.uploadController.$newUpload();
					}

					var init = function () {
						$scope.saving = false;
						//$scope.adjustment = $scope.data;
						$scope.adjustmentModel = {};
						$scope.sections = Constants.reestablishSections;
					};

					init();
				}]
			};
		}]);
})();