(function () {
    var app = angular.module('phapApp');
    app.service('cardService', ['$http', '$q', 'config', 'security', 'Constants', 'notificationService', '$rootScope', 'utils',
        function ($http, $q, config, security, constants, notificationService, $rootScope, utils) {
            var regionsForuser = [];
            var cardScan = false;
            var createdScope = $rootScope.$new();
            notificationService.subscribe(createdScope, 'requestComplete', function (event, type) {
                switch (type) {
                    case constants.activityResultStatus.approval:
                        utils.alert('Card activity request has been submitted for approval.', 'Submitted for approval', 'OK');
                        break;
                    case constants.activityResultStatus.error:
                        utils.alert('Card activity request order was submitted with errors. Please retry or contact the administrator for support.', 'Submission Error!', 'OK');
                        break;
                    default:
                        utils.alert('Card activity request order was successfully submitted.', 'Submitted', 'OK');
                        break;
                }

            });

            var cardSearch = function (searchParms) {
                var deferred = $q.defer();
                var res = $http.post(config.apiBaseUrl + 'card/search/', searchParms)
                    .then(function (data, status, headers, config) {
                        deferred.resolve(data);
                    }, function (data, status, headers, config) {
                        deferred.reject(data);
                    });
                return deferred.promise;
            }

            var getCardsForNewStocktake = function (id) {
                var deferred = $q.defer();
                var res = $http.get(config.apiBaseUrl + 'card/stocktake-cards/' + id)
                    .then(function (response, status, headers, config) {
                        deferred.resolve(response.data);
                    },
                    function (error, status, headers, config) {
                        deferred.reject(data);
                    });
                return deferred.promise;
            }

            var getStocktakeDetails = function (id) {
                var deferred = $q.defer();
                var res = $http.get(config.apiBaseUrl + 'card/stocktake/' + id)
                    .then(function (response, status, headers, config) {
                        deferred.resolve(response.data);
                    },
                    function (error, status, headers, config) {
                        deferred.reject(data);
                    });
                return deferred.promise;
            }

            var stocktakeSearch = function (searchParms) {
                var deferred = $q.defer();
                var res = $http.post(config.apiBaseUrl + 'card/stocktake-search/', searchParms)
                    .then(function (data, status, headers, config) {
                        deferred.resolve(data);
                    }, function (data, status, headers, config) {
                        deferred.reject(data);
                    });
                return deferred.promise;
            }

            var stocktakeExportSearch = function (searchParms) {
                var deferred = $q.defer();
                var res = $http.post(config.apiBaseUrl + 'card/stocktake-search/csv', searchParms)
                    .then(function (data, status, headers, config) {
                        deferred.resolve(data);
                    }, function (data, status, headers, config) {
                        deferred.reject(data);
                    });
                return deferred.promise;
            }

            var getStocktakeReconciliationReport = function (stocktakeId) {
                var deferred = $q.defer();
                var res = $http.get(config.apiBaseUrl + 'card/stocktake-reconcile-report/' + stocktakeId)
                    .then(function (response, status, headers, config) {
                        deferred.resolve(response.data);
                    }, function (data, status, headers, config) {
                        deferred.reject(data);
                    });

                return deferred.promise;
            }

            var reconcileStocktake = function (params) {
                var deferred = $q.defer();
                var res = $http.post(config.apiBaseUrl + 'card/stocktake-reconcile', params)
                    .then(function (data, status, headers, config) {
                        deferred.resolve(data);
                    }, function (data, status, headers, config) {
                        deferred.reject(data);
                    });
                return deferred.promise;
            }
            var approveReconcileStocktake = function (stocktake) {
                var deferred = $q.defer();
                var res = $http.post(config.apiBaseUrl + 'card/stocktake-reconcile-approve', stocktake)
                    .then(function (data, status, headers, config) {
                        deferred.resolve(data);
                    }, function (data, status, headers, config) {
                        deferred.reject(data);
                    });
                return deferred.promise;
            }
            var rejectReconcileStocktake = function (stocktake) {
                var deferred = $q.defer();
                var res = $http.post(config.apiBaseUrl + 'card/stocktake-reconcile-reject', stocktake)
                    .then(function (data, status, headers, config) {
                        deferred.resolve(data);
                    }, function (data, status, headers, config) {
                        deferred.reject(data);
                    });
                return deferred.promise;
            }


            var cardActivitySearch = function (searchParms) {
                var deferred = $q.defer();
                var res = $http.post(config.apiBaseUrl + 'activityrequest/search/', searchParms)
                    .then(function (data, status, headers, config) {
                        deferred.resolve(data);
                    }, function (data, status, headers, config) {
                        deferred.reject(data);
                    });
                return deferred.promise;
            }

            var exportSearch = function (searchParms) {
                var deferred = $q.defer();
                var res = $http.post(config.apiBaseUrl + 'card/search/csv', searchParms)
                    .then(function (data, status, headers, config) {
                        deferred.resolve(data);
                    }, function (data, status, headers, config) {
                        deferred.reject(data);
                    });
                return deferred.promise;
            }
            var exportCardActivitySearch = function (searchParms) {
                var deferred = $q.defer();
                var res = $http.post(config.apiBaseUrl + 'activityrequest/search/csv', searchParms)
                    .then(function (data, status, headers, config) {
                        deferred.resolve(data);
                    }, function (data, status, headers, config) {
                        deferred.reject(data);
                    });
                return deferred.promise;
            }

            var allocateCards = function (allocationData) {
                var deferred = $q.defer();
                var res = $http.post(config.apiBaseUrl + 'card/allocate/', allocationData)
                    .then(function (data, status, headers, config) {
                        deferred.resolve(data);
                    }, function (data, status, headers, config) {
                        deferred.reject(data);
                    });
                return deferred.promise;
            }

            var checkOutCards = function (data) {
                var deferred = $q.defer();
                var res = $http.post(config.apiBaseUrl + 'card/checkout', data)
                    .then(function (response, status, headers, config) {
                        deferred.resolve(response.data);
                    },
                    function (error, status, headers, config) {
                        deferred.reject(data);
                    });

                return deferred.promise;
            }

            var checkInCards = function (data) {
                var deferred = $q.defer();
                var res = $http.post(config.apiBaseUrl + 'card/checkin', data)
                    .then(function (response, status, headers, config) {
                        deferred.resolve(response.data);
                    },
                    function (error, status, headers, config) {
                        deferred.reject(data);
                    });

                return deferred.promise;
            }

            var mapToModel = function (sourceArray, targetArray) {
                for (var i = 0; i < sourceArray.length; i++) {
                    var newItem = {};
                    newItem.id = sourceArray[i].id;
                    newItem.label = sourceArray[i].name;
                    targetArray.push(newItem);
                }
            }

            var getRegionLocationRefData = function () {
                var deferred = $q.defer();
                var res = $http.post(config.apiBaseUrl + 'card/region-location-ref-data')
                    .then(function (response, status, headers, config) {
                        deferred.resolve(response.data);
                    },
                    function (error, status, headers, config) {
                        deferred.reject(data);
                    });
                return deferred.promise;
            }

            var getCardDetails = function (id) {
                var deferred = $q.defer();
                var res = $http.get(config.apiBaseUrl + 'card/details/' + id)
                    .then(function (response, status, headers, config) {
                        deferred.resolve(response.data);
                    },
                    function (error, status, headers, config) {
                        deferred.reject(data);
                    });
                return deferred.promise;
            }

            var getCardViewDetails = function (id) {
                var deferred = $q.defer();
                var res = $http.get(config.apiBaseUrl + 'card/view-details/' + id)
                    .then(function (response, status, headers, config) {
                        deferred.resolve(response.data);
                    },
                    function (error, status, headers, config) {
                        deferred.reject(data);
                    });
                return deferred.promise;
            }

            var refreshCardAvailableBalance = function (cardId) {
                var deferred = $q.defer();
                var res = $http.post(config.apiBaseUrl + 'card/refresh-available-balance', cardId)
                    .then(function (response, status, headers, config) {
                        deferred.resolve(response.data);
                    },
                    function (error, status, headers, config) {
                        deferred.reject(data);
                    });
                return deferred.promise;
            }

            var formatCreditCardString = function (value) {
                var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
                var matches = v.match(/\d{4,16}/g);
                var match = matches && matches[0] || '';
                var parts = [];
                for (i = 0, len = match.length; i < len; i += 4) {
                    parts.push(match.substring(i, i + 4));
                }
                if (parts.length) {
                    return parts.join('-');
                } else {
                    return value;
                }
            }

            var cardSearchActivity = function (searchParms) {
                var deferred = $q.defer();
                var res = $http.post(config.apiBaseUrl + 'card/searchCardActivity', searchParms)
                    .then(function (data) {
                        deferred.resolve(data);
                    }, function (data) {
                        deferred.reject(data);
                    });
                return deferred.promise;
            }

            var cardActivityOrderSave = function (order) {
                var deferred = $q.defer();
                var res = $http.post(config.apiBaseUrl + 'activityrequest/save', order, { timeout: 300000 })
                    .then(function (data) {
                        deferred.resolve(data);
                    }, function (data) {
                        deferred.reject(data);
                    });
                return deferred.promise;
            }

            var cardActivityOrderSubmit = function (order) {
                var deferred = $q.defer();
                var isDraft = order.activityOrderStatusId == constants.cardActivityRequestOrderStatusCodes.draft.id;
                var res = $http.post(config.apiBaseUrl + 'activityrequest/submit', order, { timeout: 300000 })
                    .then(function (data) {
                        notificationService.notify('showLoadingPanel', false);
                        var type = constants.activityResultStatus.success;
                        if (isDraft && data.data.activityOrderStatusId == constants.cardActivityRequestOrderStatusCodes.pendingApproval.id) {
                            type = constants.activityResultStatus.approval;
                        } else if (data.data.activityOrderStatusId == constants.cardActivityRequestOrderStatusCodes.error.id) {
                            type = constants.activityResultStatus.error;
                        }
                        notificationService.notify('requestComplete', type);
                        deferred.resolve(data);
                    }, function (data) {
                        deferred.reject(data);
                    });
                return deferred.promise;
            }

            var getCardActivityOrder = function (id) {

                var deferred = $q.defer();
                var res = $http.get(config.apiBaseUrl + 'activityrequest/getactivityorder/' + id)
                    .then(function (data) {
                        deferred.resolve(data);
                    }, function (data) {
                        deferred.reject(data);
                    });
                return deferred.promise;
            }

            var cardActivityRequestsDelete = function (order) {
                var deferred = $q.defer();
                var res = $http.post(config.apiBaseUrl + 'activityrequest/removerequests', order)
                    .then(function (data) {
                        deferred.resolve(data);
                    }, function (data) {
                        deferred.reject(data);
                    });
                return deferred.promise;
            }
            var deleteActivityOrder = function (order) {
                var deferred = $q.defer();
                var res = $http.post(config.apiBaseUrl + 'activityrequest/remove', order)
                    .then(function (data) {
                        deferred.resolve(data);
                    }, function (data) {
                        deferred.reject(data);
                    });
                return deferred.promise;
            }

            var approveActivityOrder = function (approvalModel) {
                var deferred = $q.defer();
                var res = $http.post(config.apiBaseUrl + 'activityrequest/approve', approvalModel)
                    .then(function (data) {
                        deferred.resolve(data);
                        notificationService.notify('showLoadingPanel', false);
                        var type = constants.activityResultStatus.success;
                        if (data.data.activityOrderStatusId == constants.cardActivityRequestOrderStatusCodes.error.id) {
                            type = constants.activityResultStatus.error;
                        }
                        notificationService.notify('requestComplete', type);
                    }, function (data) {
                        deferred.reject(data);
                    });
                return deferred.promise;
            }

            var rejectActivityOrder = function (approvalModel) {
                var deferred = $q.defer();
                var res = $http.post(config.apiBaseUrl + 'activityrequest/reject', approvalModel)
                    .then(function (data) {
                        deferred.resolve(data);
                    }, function (data) {
                        deferred.reject(data);
                    });
                return deferred.promise;
            }

            return {
                formatCreditCardString: formatCreditCardString,
                getCardDetails: getCardDetails,
                getRegionLocationRefData: getRegionLocationRefData,
                cardSearch: cardSearch,
                exportSearch: exportSearch,
                getCardsForNewStocktake: getCardsForNewStocktake,
                getStocktakeDetails: getStocktakeDetails,
                stocktakeSearch: stocktakeSearch,
                stocktakeExportSearch: stocktakeExportSearch,
                reconcileStocktake: reconcileStocktake,
                getStocktakeReconciliationReport: getStocktakeReconciliationReport,
                allocateCards: allocateCards,
                mapToModel: mapToModel,
                checkOutCards: checkOutCards,
                checkInCards: checkInCards,
                cardSearchActivity: cardSearchActivity,
                getCardActivityOrder: getCardActivityOrder,
                cardActivitySearch: cardActivitySearch,
                exportCardActivitySearch: exportCardActivitySearch,
                cardActivityOrderSave: cardActivityOrderSave,
                cardActivityOrderSubmit: cardActivityOrderSubmit,
                deleteActivityOrder: deleteActivityOrder,
                approveActivityOrder: approveActivityOrder,
                rejectActivityOrder: rejectActivityOrder,
                getCardViewDetails: getCardViewDetails,
                cardActivityRequestsDelete: cardActivityRequestsDelete,
                regionsForuser: regionsForuser,
                cardScan: cardScan,
                approveReconcileStocktake: approveReconcileStocktake,
                rejectReconcileStocktake: rejectReconcileStocktake,
                refreshCardAvailableBalance: refreshCardAvailableBalance
            }
        }]);
})();