(function () {
    var app = angular.module('phapApp');

    app.component('incidentReestablishApplications',
        {
            templateUrl: 'app/administration/incidents/incidentReestablishApplications.html',
            bindings: {
                incident: '<',
                hasAccess: '<',
                gridData: '<'
            },
            controllerAs: 'incidentReestablishApp',
            controller: ['$element', '$scope', 'config', 'uiGridConstants', '$location','Constants',
                function ($element, $scope, config, uiGridConstants, $location, Constants) {

            var vm = this;

            vm.$onInit = function() {

                vm.totalRecords = 50;
                vm.filteredRecords = 7;

                //Initialize scope variables
                vm.noResultsFound = false;
                vm.submitting = false;
                vm.exportToFile = false;
                vm.gridOptions.data = vm.gridData;
                vm.reestablishDataLoaded = vm.completed;
                vm.noResultsFound = vm.gridData.length === 0;

            };
                  
            vm.openItem = function (entity) {
                $location.path("/home/reestablish/edit/" + entity.id);
            };

            // Returns the number of rows currently in the grid
            var rowsRendered = function () {
                vm.filteredRecords = vm.gridApi.core.getVisibleRows(vm.gridApi.grid).length;
            };

            var getColumnDefs = function () {
                return [
                    {
                        field: 'id',
                        visible: false
                    },
                    {
                        field: 'applicationNumber',
                        displayName: 'Application #',
                        enableHiding: false,
                        width: '12%',
                        enableColumnMenu: false
                    },
                    {
                        field: 'applicantName',
                        displayName: 'Applicant name',
                        enableHiding: false,
                        width: '22%',
                        enableColumnMenu: false
                    },
                    {
                        field: 'affectedAddress',
                        displayName: 'Affected address',
                        enableHiding: false,
                        width: '32%',
                        enableColumnMenu: false
                    },
                    {
                        field: 'dateCreated',
                        displayName: 'Created date',
                        enableHiding: false,
                        width: '12%',
                        cellClass: 'date-field',
                        enableColumnMenu: false,
                        cellFilter: Constants.GridDateFormat
                    },
                    {
                        field: 'state',
                        displayName: 'Status',
                        enableHiding: false,
                        width: '10%',
                        enableColumnMenu: false
                    },
                    {
                        field: 'entitlementAmount',
                        displayName: 'Amount',
                        enableHiding: false,
                        width: '12%',
                        enableColumnMenu: false,
                        cellFilter: 'phapcurrency',
                        cellClass: 'currency'
                    }
                ];
            };
             //Grid Options
            vm.gridOptions = {
                enableSorting: true,
                enableFiltering: false,
                enableHorizontalScrollbar: uiGridConstants.scrollbars.NEVER,
                enableVerticalScrollbar: uiGridConstants.scrollbars.ALWAYS,
                enableRowSelection: true,
                enableFullRowSelection: true,
                enableRowHeaderSelection: false,
                enableSelectAll: false,
                multiSelect: false,
                enableRowHashing: false,
                // rowHeight: 56,
                columnDefs: getColumnDefs(),
                appScopeProvider: {
                    onDblClick: function (row) {
                        if (!vm.iosOrMobile) {
                            vm.openItem(row.entity);
                        }
                    },
                    onEditTouchend: function (row) {
                        vm.openItem(row.entity);
                    },
                    selectRow: function (row) {
                        vm.openItem(row.entity);
                    }
                },

                rowTemplate:
                    '<div ' +
                        'ng-dblclick="grid.appScope.onDblClick(row)" ' +
                        'ng-click="grid.appScope.selectRow(row, $event, 1)"> ' +
                        '<div ' +
                        'class="ui-grid-cell" ' +
                        'ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" ' +
                        'ui-grid-cell ' +
                        'ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader }" ' +
                        'style="cursor:pointer"> ' +
                        '</div>' +
                        '</div>',

                onRegisterApi: function (gridApi) {
                    vm.gridApi = gridApi;
                    vm.gridApi.core.on.rowsRendered($scope, rowsRendered);
                }
            };       

        }]
    });

})();