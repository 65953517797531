(function () {

    var app = angular.module('phapApp');

    app.component('user', {
        templateUrl: 'app/administration/security/user.html',
        bindings: {
            mode: '@'
        },
        controller: ['$scope', '$routeParams', '$filter', 'utils', 'security', 'securityManagementService', 'notificationService', 'navigationService',
            function ($scope, $routeParams, $filter, utils, security, securityManagementService, notificationService, navigationService) {

                var vm = this;
                vm.currentSection = $routeParams.name;
              
                navigationService.setCurrentMenuStatus(
                    navigationService.topMenuCodes.administration,
                    navigationService.pageCodes.userRoleManagement,
                    vm.currentSection);


                vm.showPrivilegePanel = false;
                vm.searchPlaceholderText = 'Search Users';
                vm.leftList = 'mainLeftList';
                vm.roles = [];
                vm.updatedList = [];
                vm.gridApi = null;

                vm.userRegions = [];
                vm.userDivisions = [];
                vm.menuIds = {
                    user: 'user',
                    region: 'region'
                };
                vm.selectedMenu = vm.menuIds.user;

                vm.addEditUserPopupInfo = {
                    templateUrl: 'app/administration/security/addEditUser.html',
                    controller: 'addEditUserCtrl',
                    windowClass: 'user-create-edit-modal-window'
                };

                vm.addRegionsPopupInfo = {
                    templateUrl: 'app/administration/security/addUserRegions.html',
                    controller: 'addUserRegionsCtrl',
                    windowClass: 'weekly-income-modal-window'
                };

                vm.isCurrentUser = function () {
                    return vm.selectedUser.id==security.getUserId();
                }

                var getActiveRoles = function () {
                    securityManagementService.getActiveRoles().then(
                        function (roles) {
                            vm.roles = roles.data;
                        });
                }

                var showHideLoading = function (isShow) {
                    notificationService.notify('showLoadingPanel', isShow);
                }

                var getUserRegions = function (id) {
                    securityManagementService.getUserRegions(id).then(
                        function (regions) {
                            for (var i = 0; i < regions.data.length; i++) {
                                if (vm.isCurrentUser()) {
                                    regions.data[i].isReadOnly = true;
                                }
                                else
                                {
                                    regions.data[i].isReadOnly = false;
                                }
                            }
                           
                            vm.userRegions = regions.data;
                            vm.userRegionsGridOptions.data = vm.userRegions;
                            vm.userRegionsOriginalData = angular.copy(vm.userRegionsGridOptions.data);
                            showHideLoading(false);
                            vm.userloaded = true;
                        },
                        function () {
                            showHideLoading(false);
                            vm.userloaded = true;
                        });
                }
                var getUser = function (id) {
                    securityManagementService.getUser(id).then(
                        function (user) {
                            vm.selectedUser = user.data;
                            vm.showRole = true;
                            vm.loaded = true;
                            getActiveRoles();
                            getUserRegions(id);
                           
                        });
                }

                var getUsers = function () {
                    securityManagementService.getAllUsers().then(
                        function (users) {
                            vm.data = users.data;
							vm.originalDataSet = angular.copy(vm.data);
							vm.originalDatasetWithNames = _.map(vm.originalDataSet, 'name').map(function (item) {
								return { name: item };
							});
                            getUser(vm.data[0].id);
                        });
                }

                var getSecurity = function () {
                    vm.loaded = false;
                    //getting logged in user security
                    security.getCurrentUserSecurityInfo()
                        .then(function (result) {
                            vm.privileges = result.privileges.UpdateUsers;
                            if (vm.privileges)
                                getUsers();
                            else {
                                vm.showPrivilegePanel = true;
                                vm.loaded = true;
                            }
                        },
                        function () {
                            vm.privileges = false;
                            vm.showPrivilegePanel = true;
                            vm.loaded = true;
                        });
                };

                var createUserPopupCallback = function (data) {
                    showHideLoading(true);
                    securityManagementService.createUser(data).then(
                        function (result) {
                            securityManagementService.getAllUsers().then(
                                function (users) {
                                    vm.data = users.data;
                                    vm.originalDataSet = angular.copy(vm.data);
                                    getUser(result.data.id);
                                    vm.showSavedMessage = true;
                                    showHideLoading(false);
                                },
                                function () {
                                    showHideLoading(false);
                                });
                        });
                }

                var updateUserPopupCallback = function (data) {
                    showHideLoading(true);
                    securityManagementService.updateUser(data).then(
                        function (result) {
                            securityManagementService.getAllUsers().then(
                                function (users) {
                                    vm.data = users.data;
                                    vm.originalDataSet = angular.copy(vm.data);
                                    getUser(result.data.id);
                                    vm.showSavedMessage = true;
                                    showHideLoading(false);
                                },
                                function () {
                                    showHideLoading(false);
                                });
                        });
                }

                var saveRegionsCallback = function (data) {
                    showHideLoading(true);
                    vm.showSavedMessage = true;
                    getUserRegions(vm.selectedUser.id);
                }

                var addRegionsPopup = function () {
                    vm.showSavedMessage = false;
                    var windowClass = vm.addRegionsPopupInfo.windowClass ? vm.addRegionsPopupInfo.windowClass : null;
                    var resolve = {
                        data: {
                            userId: vm.selectedUser.id
                        }

                    }
                    utils.openModal(vm.addRegionsPopupInfo.templateUrl,
                        vm.addRegionsPopupInfo.controller,
                        $scope,
                        resolve,
                        windowClass,
                        saveRegionsCallback,
                        vm.addRegionsPopupInfo.size);
                }

                var init = function () {
                    getSecurity();
                }
                
                vm.userRegionsGridOptions = {
                    enableCellEdit: false,
                    enableSorting: true,
                    enableCellEditOnFocus: false
                };

                vm.showUSerRegionWarning = function () {
                    return vm.selectedUser.active && vm.userRegions.length == 0;
                };

                vm.userRegionsGridOptions.columnDefs = [
                    { name: 'name', displayName: 'Region', enableCellEdit: false, enableColumnMenu: false, enableSorting: false },
                    { name: 'divisionNames', displayName: 'Divisions', enableCellEdit: false, enableColumnMenu: false, enableSorting: false },
                    {
                        name: 'isDeleted', displayName: '', enableCellEdit: false, enableColumnMenu: false, width: 100, enableSorting: false,
                        cellClass: function (grid, row) {
                            return "grid-Delete-button";
                        },
                        cellTemplate: '<a ng-if="!row.entity.isDeleted && !row.entity.isReadOnly"  title="Delete" ng-click="grid.appScope.deleteRow(row.entity)"><span class="glyphicon glyphicon-trash grid-Delete-button" ></span></a>' +
                        '<span ng-if="row.entity.isReadOnly"  class="glyphicon glyphicon-trash grid-Delete-disabled-button" ></span>'

                    }
                ];

                vm.createNewUser = function () {
                    vm.showSavedMessage = false;
                    var windowClass = vm.addEditUserPopupInfo.windowClass ? vm.addEditUserPopupInfo.windowClass : null;
                    var resolve = {
                        isAdd: true,
                        data: {
                            existingUsers: vm.data,
                            roles: vm.roles,
                            user: {
                                id: 0,
                                givenName: '',
                                surname: '',
                                email: '',
                                loginName: '',
                                contactNumber: '',
                                active: true
                            }

                        }
                    }
                    utils.openModal(vm.addEditUserPopupInfo.templateUrl,
                        vm.addEditUserPopupInfo.controller,
                        $scope,
                        resolve,
                        windowClass,
                        createUserPopupCallback,
                        vm.addEditUserPopupInfo.size);
                }

                vm.editUser = function () {
                    vm.showSavedMessage = false;
                    var windowClass = vm.addEditUserPopupInfo.windowClass ? vm.addEditUserPopupInfo.windowClass : null;
                    var resolve = {
                        isAdd: false,
                        data: {
                            existingUsers: vm.data,
                            roles: vm.roles,
                            user: {
                                id: vm.selectedUser.id,
                                givenName: vm.selectedUser.givenName,
                                surname: vm.selectedUser.surname,
                                email: vm.selectedUser.email,
                                loginName: vm.selectedUser.userName,
                                contactNumber: vm.selectedUser.contactNumber,
                                active: vm.selectedUser.active,
                                roleId: vm.selectedUser.roleId,
                                rowVersion: vm.selectedUser.rowVersion
                            }
                        }

                    }
                    utils.openModal(vm.addEditUserPopupInfo.templateUrl,
                        vm.addEditUserPopupInfo.controller,
                        $scope,
                        resolve,
                        windowClass,
                        updateUserPopupCallback,
                        vm.addEditUserPopupInfo.size);
                }

                vm.showSelectedUser = function (user) {
                    vm.showSavedMessage = false;
                    vm.userloaded = false;
                    getUser(user.id);
                }

                vm.showuser = function () {
                    vm.selectedMenu = vm.menuIds.user;
                }
                vm.showRegions = function () {
                    vm.selectedMenu = vm.menuIds.region;
                    vm.userRegionsGridOptions.data = vm.userRegions;
                    if (vm.gridApi) {
                        vm.gridApi.core.refresh();
                    }
                    vm.showSavedMessage = false;
                }

                vm.deleteUser = function () {
                    var title = 'Warning';
                    var content = "Are you sure you want to deactivate this user?";
                    utils.confirm(title, content, 'Yes', 'No')
                        .then(function () {
                            showHideLoading(true);
                            securityManagementService.removeUser({ id: vm.selectedUser.id, rowVersion: vm.selectedUser.rowVersion }).then(
                                function () {
                                    securityManagementService.getAllUsers().then(
                                        function (userList) {
                                            vm.data = userList.data;
											vm.originalDataSet = vm.data;											
                                            getUser(vm.selectedUser.id);
                                            showHideLoading(false);
                                        });
                                },
                                function () {
                                    showHideLoading(false);
                                });
                        },
                        function () {
                            return;
                        });
                }

                vm.reactivateUser = function () {
                    var title = 'Warning';
                    var content = "Are you sure you want to reactivate this user?";
                    utils.confirm(title, content, 'Yes', 'No')
                        .then(function () {
                            showHideLoading(true);
                            securityManagementService.reactivateUser({ id: vm.selectedUser.id, rowVersion: vm.selectedUser.rowVersion }).then(
                                function () {
                                    securityManagementService.getAllUsers().then(
                                        function (userList) {
                                            vm.data = userList.data;
                                            vm.originalDataSet = vm.data;
                                            getUser(vm.selectedUser.id);
                                            showHideLoading(false);
                                        });
                                },
                                function () {
                                    showHideLoading(false);
                                });
                        },
                        function () {
                            return;
                        });
                }

                vm.showActiveStatus = function () {
                    return vm.selectedUser.active ? 'Active' : 'Inactive';
                }

                vm.showSaveButtons = function () {

                    if (vm.selectedMenu === vm.menuIds.region && vm.userRegions.length > 0) {
                        return true;
                    }
                    else if (vm.selectedMenu === vm.menuIds.division && vm.userDivisions.length > 0) {
                        return true;
                    }

                    return false;
                }

                vm.addItems = function () {

                    if (vm.selectedMenu == vm.menuIds.region)
                        addRegionsPopup();
                }

                vm.gridUpdated = function (list) {
                    vm.showSavedMessage = false;
                    $scope.user.$setDirty();
                }

                vm.disabledSave = function () {
                    return vm.updatedList.length === 0 || vm.isCurrentUser();
                }

                vm.disabledCancel = function () {
                    return vm.updatedList.length === 0;
                }

                vm.save = function () {
                    vm.showSavedMessage = false;
                    var title = 'Warning';
                    var content = vm.customSaveConfirmation ? vm.customSaveConfirmation : "Saving will commit all changes made on this page";
                    utils.confirm(title, content, 'Proceed', 'Cancel')
                        .then(function () {
                            showHideLoading(true);
                            vm.isUpdated = false;
                            vm.showSavedMessage = false;
                            vm.submitting = true;
                            vm.pageErrors = [];
                            if (vm.selectedMenu === vm.menuIds.region)
								securityManagementService.removeUserRegions(vm.selectedUser.id, vm.updatedList).then(
                                    function () {
                                        vm.updatedList = [];
                                        getUserRegions(vm.selectedUser.id);
                                        vm.submitting = false;
                                        vm.showSavedMessage = true;
                                        showHideLoading(false);
                                    },
                                    function () {
                                        showHideLoading(false);
                                    });
                        },
                        function () {
                            return;
                        });
                }

                vm.clear = function () {

                    var title = 'Warning';
                    var content = "Are you sure you want to discard unsaved data?";
                    utils.confirm(title, content, 'Yes', 'Cancel')
                        .then(function () {
                            vm.updatedList = [];
                            vm.showSavedMessage = false;
                            if (vm.selectedMenu == vm.menuIds.region) {
                                vm.userRegionsGridOptions.data = angular.copy(vm.userRegionsOriginalData);
                                vm.gridApi.core.refresh();
                            }
                            $scope.user.$setPristine();
                        },
                        function () {
                            return;
                        });
                }
                init();
            }],
        controllerAs: 'userVm'
    });

})();