(function () {

    var app = angular.module('phapApp');
    app.component('invoiceTaskSend', {
        templateUrl: 'app/home/dashboard/invoiceTaskSend.html',
        bindings: {
            tasks: '=',
            heading: '@',
            timeLapsed: '='
        },
        controller: ['dashboardService', function (dashboardService) {
            var vm = this;

        }],
        controllerAs: 'vm'
    });

})();