(function () {
	var app = angular.module('phapApp');
	app.component('chequeView', {
		templateUrl: 'app/financeManagement/cheques/chequeView.html',
		bindings: {
			mode: '@'
		},
        controller: ['$scope', '$routeParams', '$timeout', 'utils', '$q', 'security', 'chequeService', '$location', 'Constants', 'uiGridConstants', '$interval', '$filter','navigationService',
            function ($scope, $routeParams, $timeout, utils, $q, security, chequeService, $location, constants, uiGridConstants, $interval, $filter, navigationService) {
				var vm = this;
				vm.paymentId = $routeParams.id
				vm.constants = constants;
				$scope.loaded = false;
				vm.updatePresentedDate = false;
                $scope.initChequeViewDialog = function () {
                    if ($location.path().indexOf("financeManagement") > -1) {
                        navigationService.setCurrentMenuStatus(
                            navigationService.topMenuCodes.financeManagement,
                            navigationService.pageCodes.chequeSearch);
                    }
					vm.gridOptions = getGridOptions();
					vm.gridOptions.data = [];
					vm.allPromises = [];
					vm.allPromises.push(chequeService.getChequeViewDetails(vm.paymentId)
						.then(function (result) {
							vm.chequeViewDetails = result;
                            vm.gridOptions.data = result.transactions;
                            var transactionCount = result.transactions.length;
                            vm.chequeViewDetails.postedDate = result.transactions.length > 0 && result.transactions[transactionCount-1].postedDate && result.transactions[transactionCount-1].postedDate != "" ? result.transactions[transactionCount-1].postedDate : "";
                            vm.chequeViewDetails.postedPeriod = result.transactions.length > 0 ? result.transactions[transactionCount-1].postedPeriod : "";
                            vm.chequeViewDetails.paymentDate = $filter('date')(vm.chequeViewDetails.paymentDate, vm.constants.DatePickerFormat);
                            vm.chequeViewDetails.presentedDate = moment(vm.chequeViewDetails.presentedDate).year() == "1" ? "" : moment(vm.chequeViewDetails.presentedDate).toDate();
                            vm.chequeViewDetails.incident.incidentDate = moment(vm.chequeViewDetails.presentedDate).year() == "1" ? "" : $filter('date')(vm.chequeViewDetails.incident.incidentDate, vm.constants.DatePickerFormat);
                            vm.chequeViewDetails.oraclePaymentId = vm.chequeViewDetails.oraclePaymentId > 0 ? vm.chequeViewDetails.oraclePaymentId : "";
                            vm.chequeViewDetails.oraclePaymentAmount = vm.chequeViewDetails.oraclePaymentAmount != 0 ? vm.chequeViewDetails.oraclePaymentAmount : "";
							$scope.loaded = true;
							vm.loaded = true;
						}));
					$q.all(vm.allPromises).then(
						function (result) {
							$scope.loaded = true;
						});
				};
				var getGridColumnDefs = function () {
					return [
						{
							field: 'id',
							visible: false,
							enableHiding: false
						},
						{
							field: 'batchNumber',
							displayName: 'BatchID',
                            enableHiding: false,
                            width: '12%'
						},
						{
							field: 'reference1',
							displayName: 'Details',
                            enableHiding: false,
                            width: '12%'
						},
						{
							field: 'amount',
                            displayName: 'Amount',
                            cellClass: 'currency',
                            enableHiding: false,
                            width: '12%'
						},
						{
							field: 'transactionDate',
							displayName: 'Transaction date',
                            enableHiding: false,
                            cellClass: 'date-field',
                            cellFilter: vm.constants.GridDateFormat,
                            width: '12%'
						},
						{
							field: 'transactionType',
							displayName: 'Transaction type',
                            enableHiding: false,
                            width: '12%'
						},
						{
							field: 'transactionNumber',
							displayName: 'Transaction number',
                            enableHiding: false,
                            width: '12%'
						},
						{
							field: 'sendToOracleDate',
							displayName: 'Send to oracle',
                            enableHiding: false,
                            cellFilter: vm.constants.GridDateFormat,
                            width: '12%'
						},
						{
							field: 'entity',
							displayName: 'Entity',
                            enableHiding: true,
                            width: '12%'
						},
						{
							field: 'costCentre',
							displayName: 'Cost centre',
                            enableHiding: true,
                            width: '12%'
						},
						{
							field: 'activity',
							displayName: 'Activity',
                            enableHiding: true,
                            width: '12%'
						},
						{
							field: 'fund',
							displayName: 'Fund',
                            enableHiding: true,
                            width: '12%'
						},
						{
							field: 'account',
							displayName: 'Account',
                            enableHiding: true,
                            width: '12%'
						},
						{
							field: 'product',
							displayName: 'Product',
                            enableHiding: true,
                            width: '12%'
						},
						{
							field: 'identifier',
							displayName: 'Identifier',
                            enableHiding: true,
                            width: '12%'
						},
						{
							field: 'rejectionReason',
							displayName: 'Rejection Reason',
                            enableHiding: true,
                            width: '12%'
						},
					]
				};
                var rowtpl = '<div ng-click="grid.appScope.openItem(row.entity);" ng-class="grid.options.rowStyle(row)">'
                    + '<div ng-class="{\'grey\':true, \'red\':row.entity.transactionType == 9012 }">' +
                    '<div style="cursor:pointer" ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader }" ui-grid-cell></div></div></div>';

                var openItem = function (data) {

                    console.log(data);
                    vm.chequeViewDetails.oracleLastUpdatedDate = data.dateUpdated;
                    vm.chequeViewDetails.oracleCreationDate = data.dateCreated;
                    vm.chequeViewDetails.postedDate = data.postedDate;
                    vm.chequeViewDetails.postedPeriod = data.postedPeriod;
                };

				var getGridOptions = function () {
					return {
						infiniteScrollRowsFromEnd: 10,
						infiniteScrollUp: false,
						enableGridMenu: true,
						infiniteScrollDown: true,
						enableSorting: true,
						enableFiltering: false,
						enableColumnResizing: true,
						enableHorizontalScrollbar: uiGridConstants.scrollbars.ALWAYS,
						enableVerticalScrollbar: uiGridConstants.scrollbars.ALWAYS,
						enableRowSelection: true,
						enableFullRowSelection: true,
						enableRowHeaderSelection: false,
						enableSelectAll: true,
						multiSelect: false,
						enableRowHashing: false,
						columnDefs: getGridColumnDefs(),
                        appScopeProvider: {
                            openItem: function (row) {
                                openItem(row);
                            },
                            
                        },
						rowTemplate: rowtpl,
						onRegisterApi: function (gridApi) {
                            vm.gridApi = gridApi;
                            // call resize every 500 ms for 5 s after modal finishes opening - usually only necessary on a bootstrap modal
                            $interval(function () {
                                vm.gridApi.core.handleWindowResize();
                            }, 500, 10);
						}
					}
				};
				vm.applyChanges = function () {
					var content = "";
					$scope.copyBack();
					$scope.closeAndHide();
				};
				vm.transactionType = {
					CancelCheque: 9012
				}

				vm.checkPresentedDate = function () {
					var presentedStartDate = null;
					var presentedEndDate = null;
					vm.form.presentedDate.$setValidity('greaterThanPaymentDate', true);
					vm.form.presentedDate.$setValidity('greaterThanTodaysDate', true);
					if (vm.chequeViewDetails.presentedDate) {
						presentedDate = moment(vm.chequeViewDetails.presentedDate, "DD-MM-YYYY");
					}
					if (presentedDate) {
                        if (!presentedDate || presentedDate.isBefore(moment(vm.chequeViewDetails.paymentDate, "DD-MM-YYYY"))) {
							vm.form.presentedDate.$setValidity('greaterThanPaymentDate', false);
						}
						if (!presentedDate || presentedDate.isSameOrAfter(moment())) {
							vm.form.presentedDate.$setValidity('greaterThanTodaysDate', false);
						}
					}
				}

				this.cancel = function () {
					$location.path("/financeManagement/cheques/chequeSearch");
				};
                this.editPresentedDate = function () {
                    if (moment(vm.chequeViewDetails.presentedDate, "DD-MM-YYYY").isBefore(moment(vm.chequeViewDetails.paymentDate, "DD-MM-YYYY"))) {
						utils.alert("Presented date must be greater than payment date.");
					}
					else {
						utils.confirm("Update presented date", "Proceeding will change the details of this Transaction.", 'Proceed', 'Cancel')
							.then(function () {
								vm.updatePresentedDate = true
								var data = {};
								data.chequeId = vm.chequeViewDetails.chequeId;
                                data.presentedDate = moment.utc(vm.chequeViewDetails.presentedDate, "DD-MM-YYYY").startOf('day').add(1, 'days');
								data.rowVersion = vm.chequeViewDetails.rowVersion;
								chequeService.updateChequePresentedDate(data)
									.then(function (result) {
										vm.chequeViewDetails.rowVersion = result.data.rowVersion;
										vm.updatePresentedDate = false;
										vm.form.$setPristine();
									},
									function (data) {
										vm.updatePresentedDate = false;
									});
							});
					}
				};
				this.redirectToIncident = function (id) {
					$location.path('/administration/incidents/edit/' + id);
				};

				this.redirectToRelief = function (id) {
					$location.path('/home/relief/edit/' + id);
				};
				this.redirectToReestablishment = function (id) {

					$location.path('/home/reestablish/edit/' + id);
				};

				vm.viewHistory = function () {
					$location.path("/administration/history/chequeDetail/" + vm.chequeViewDetails.chequeId);
				}
				vm.printOrder = function (divName) {
					vm.printElement(document.getElementById("printableArea"));
					window.print();
				}
				vm.printElement = function (elem) {
					var domClone = elem.cloneNode(true);
					var $printSection = document.getElementById("printSection");
					if (!$printSection) {
						var $printSection = document.createElement("div");
						$printSection.id = "printSection";
						document.body.appendChild($printSection);
					}
					$printSection.innerHTML = "";
					$printSection.appendChild(domClone);
				}
				vm.isCancelledTransaction = function (transaction) {
					if (transaction.transactionType === vm.transactionType.CancelCheque) {
						return true;
					}
					return false;
				};
				$scope.copyBack = function () {
					// Finally set the form to dirty!
					$scope.frm.$setDirty();
				};

				$scope.closeAndHide = function () {
					$scope.$close(true);
				}
				$scope.initChequeViewDialog();

			}
		],
		controllerAs: 'chequeView'
	});
})();