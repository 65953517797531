(function () {
    var app = angular.module('phapApp');

    app.component('vendorSearch', {
        templateUrl: 'app/home/vendor/vendorSearch.html',
        bindings: {
            displayMode: '@', //popup or standalone
            createNewVendorSelected: '&?',
            updateVendorSelected: '&?',
            cancelClick: '&?',
            applySelectedVendor: '&?',
            filters: "=?"
        },
        controllerAs: 'vendorsearch',
        controller: ['$element', '$location', 'uiGridConstants', 'vendorService', 'utils', '$scope', 'security', '$routeParams', 'Constants', 'navigationService', 'notificationService',
            function ($element, $location, uiGridConstants, vendorService, utils, $scope, security, $routeParams, Constants, navigationService, notificationService) {
                var vm;
                vm = this;

                vm.selectedVendor = undefined;
                
                $scope.vendorStatuses = [];
               
                $scope.statusfilter = [];
                $scope.statusDropdownSettings = {
                    smartButtonMaxItems: 3,
                    smartButtonTextConverter: function (item) { return item; },
                    buttonClasses: "btn-multi-select-bare",
                    showCheckAll: false
                };

                $scope.statusDropdownTranslationTexts = {
                    buttonDefaultText: "All statuses",
                    uncheckAll: "Clear status filter"
                };                

                var isRunningInExtraSmallScreen = false;

                var getVendorSearchGridColumnDefs = function () {
                    var columns = [];
                    columns.push({ field: 'id', visible: false });
                    columns.push({ field: 'name', displayName: 'Name', enableHiding: false, width: '30%', enableColumnMenu: false });
                    columns.push({ field: 'bsb', displayName: 'BSB', enableHiding: false, width: '10%', enableColumnMenu: false });
                    columns.push({ field: 'account', displayName: 'Account', enableHiding: false, width: '15%', enableColumnMenu: false });
                    columns.push({
                        field: 'statusText',
                        displayName: 'Vendor status',
                        enableHiding: false,
                        width: '25%',
                        enableColumnMenu: false,
                        cellTemplate: '<div class="ui-grid-cell-contents">{{ row.entity.status | vendorstatus }}</div>'
                    });

                    if (vm.IsStandAlone()) {
                        columns.push({ field: 'contactPerson', displayName: 'Contact person', enableHiding: false, width: '20%', enableColumnMenu: false });
                        // columns.push({ field: 'address.addressString', displayName: 'Address', enableHiding: false, width: '20%', enableColumnMenu: false });
                    }

                    if (vm.IsDisplayModePopup()) {

                        columns.push({
                            field: 'action',
                            displayName: 'Edit',
                            enableHiding: false,
                            width: '20%',
                            enableColumnMenu: false,
                            cellTemplate: '<div class="ui-grid-cell-contents" ><a href="" ng-click="grid.appScope.updateRow(row, $event)">Edit</a></div>'
                        });
                        angular.element("body").css("overflow", "hidden");

                    }

                    return columns;

                };
                //Grid Options
                var getGridOptions = function () {
                    return {
                        isRowSelectable: function (row) {
                            //Select the row only when the vendor status is not inactive
                            if (row.entity.status === Constants.vendorStatus.Inactive) return false;
                            else return true;
                        },
                        enableSorting: true,
                        enableFiltering: false,
                        enableHorizontalScrollbar: uiGridConstants.scrollbars.ALWAYS,
                        enableVerticalScrollbar: uiGridConstants.scrollbars.ALWAYS,
                        enableRowSelection: true,
                        enableFullRowSelection: true,
                        enableRowHeaderSelection: false,
                        enableSelectAll: false,
                        multiSelect: false,
                        columnDefs: getVendorSearchGridColumnDefs(),
                        appScopeProvider: {
                            onDblClick: function (row) {

                            },
                            onEditTouchend: function (row) {

                            },
                            selectRow: function (row, $event) {

                                if (vm.IsDisplayModePopup() && row.enableSelection) {
                                    if (row.isSelected) {
                                        vm.selectedVendor = row.entity;
                                    }
                                    else {
                                        vm.selectedVendor = undefined;
                                    }
                                }//Redirect to vendor details page on single click 
                                else if (vm.IsStandAlone()) {
                                    vm.form.$setPristine();
                                    $location.path("/home/vendor/edit/" + row.entity.id);
                                }
                            },
                            updateRow: function (row, e) {
                                if (vm.IsDisplayModePopup()) {
                                    if (!e) var e = window.event
                                    e.cancelBubble = true;
                                    if (e.stopPropagation) e.stopPropagation();
                                    vm.updateVendorSelected({ id: row.entity.id });
                                }
                            }
                        },
                        rowTemplate:
                            '<div ' +
                            'ng-dblclick="grid.appScope.onDblClick(row)" ' +
                            'ng-click="grid.appScope.selectRow(row, $event, 1)"> ' +
                            '<div ' +
                            'class="ui-grid-cell" ' +
                            'ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" ' +
                            'ui-grid-cell ' +
                            'ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader }" ' +
                            'style="cursor:pointer"> ' +
                            '</div>' +
                            '</div>',

                        onRegisterApi: function (gridApi) {
                            vm.gridApi = gridApi;
                            vm.gridApi.core.on.rowsRendered($scope, rowsRendered);
                        }
                    }
                };

                var getStatuses = function () {
                    vendorService.getVendorStatusList().then(function (response) {
                        response.forEach(function (element) {
                            $scope.vendorStatuses.push({ id: element.item1, label: element.item2 });
                        });
                    }, function () {
                    });
                };
                
                // Returns the number of rows currently in the grid
                var rowsRendered = function () {
                    vm.filteredRecords = vm.gridApi.core.getVisibleRows(vm.gridApi.grid).length;
                };

                vm.$onInit = function () {

                    if (!vm.IsDisplayModePopup()) {
                        navigationService.setCurrentMenuStatus(
                            navigationService.topMenuCodes.financeManagement,
                            navigationService.pageCodes.vendorManagement);
                    }

                    vm.gridOptions = getGridOptions();
                    getStatuses();
                    //Initialize the filters, Filters are not passed in standalone view mode of vendor search
                    if (_.isEmpty(vm.filters)) {
                        vm.filters = {};
                    }                  

                    //getting logged in user security
                    security.getCurrentUserSecurityInfo()
                        .then(function (result) {
                            vm.currentUserSecurityInfo = result;
                            if (vm.currentUserSecurityInfo.privileges.SearchVendor) {
                                if (vm.IsStandAlone()) {
                                    //Search click will have filter text as ' ' and by default if it loaded for first time filters will be undefined where we dont want to load the data
                                    if ($routeParams.name !== undefined || $routeParams.bsb !== undefined || $routeParams.account !== undefined || $routeParams.contact !== undefined || $routeParams.status !== undefined) {
                                        vm.filters.vendornamefilter = $routeParams.name !== undefined ? $routeParams.name.trim() : '';
                                        vm.filters.bsbfilter = $routeParams.bsb !== undefined ? parseInt($routeParams.bsb) : '';
                                        vm.filters.accountfilter = $routeParams.account !== undefined ? parseInt($routeParams.account) : '';
                                        vm.filters.contactpersonfilter = $routeParams.contact !== undefined ? $routeParams.contact.trim() : '';
                                        vm.filters.statusfilter = $routeParams.status !== undefined ? $routeParams.status.trim() : '';
                                        vm.searchVendors(false);
                                    }
                                }
                                else if (vm.IsDisplayModePopup()) {
                                    if (_.isEmpty(vm.filters.vendornamefilter) === false || _.isEmpty(vm.filters.bsbfilter) === false ||
                                        _.isEmpty(vm.filters.accountfilter) === false || _.isEmpty(vm.filters.contactpersonfilter) === false
                                       || _.isEmpty(vm.filters.statusfilter) === false
                                    ) {
                                        vm.searchVendors(false);
                                    }
                                }
                            }
                        },
                            function () {
                                vm.currentUserSecurityInfo = {};
                            });
                };


                vm.exportToCsv = function () {
                    vm.searchVendors(true);
                }

                //This search function is for standalone vendor screen
                vm.search = function () {
                    if (vm.form.$valid) {
                        vm.form.$setPristine();
                        $location.path("/home/vendor/search/" + (vm.filters.vendornamefilter === undefined ? ' ' : vm.filters.vendornamefilter) + '/'
                            + ((vm.filters.bsbfilter === undefined || vm.filters.bsbfilter === null ? ' ' : vm.filters.bsbfilter)) + '/'
                            + ((vm.filters.accountfilter === undefined || vm.filters.accountfilter === null ? ' ' : vm.filters.accountfilter)) + '/'
                            + (vm.filters.contactpersonfilter === undefined ? ' ' : vm.filters.contactpersonfilter) + '/'
                            + (vm.filters.statusfilter === undefined ? [] : vm.filters.statusfilter
                            )
                        );
                    }
                }

                vm.searchVendors = function (exportToCsv) {
                    var statusIds = [];
                    _.map($scope.statusfilter, function (item) {
                        return statusIds.push(item.id);
                    });

                    var vendorSearchModel = {
                        name: vm.filters.vendornamefilter,
                        bsb: vm.filters.bsbfilter,
                        account: vm.filters.accountfilter,
                        contactPerson: vm.filters.contactpersonfilter,
                        status: statusIds
                    };                                     

                    vendorService.searchVendors(vendorSearchModel, exportToCsv)
                        .then(function (response) {
                            if (exportToCsv) {
                                var file = new Blob([response.data], { type: 'text/csv' });
                                saveAs(file, 'VendorSearchList.csv');
                            }
                            else {
                                vm.gridOptions.data = response.data;
                                if (vm.gridOptions.data.length == 0) {
                                    vm.noResultsFound = true;
                                }
                                else {
                                    vm.noResultsFound = false;
                                }
                            }
                        }, function () {

                        });
                };

                vm.createNew = function () {
                    if (vm.IsStandAlone()) {
                        $location.path("/home/vendor/create/");
                    }
                    else {
                        vm.createNewVendorSelected();
                    }
                };

                vm.IsStandAlone = function () {
                    return vm.displayMode === 'standalone';
                }

                vm.IsDisplayModePopup = function () {
                    return vm.displayMode === 'popup';
                }

                vm.reset = function () {
                    vm.form.$setPristine();
                    vm.filters.vendornamefilter = '';
                    vm.filters.bsbfilter = '';
                    vm.filters.accountfilter = '';
                    vm.filters.contactpersonfilter = '';
                    vm.filters.statusfilter = [];
                    vm.gridOptions.data = [];
                };

                vm.cancel = function () {
                    if (vm.IsDisplayModePopup()) {
                        vm.filters.vendornamefilter = '';
                        vm.cancelClick();
                    }
                }

                vm.applyClick = function () {
                    if (vm.selectedVendor !== undefined && vm.selectedVendor !== null) {
                        vm.applySelectedVendor({ selectedVendor: vm.selectedVendor });
                        vm.filters.vendornamefilter = '';
                    } else {
                        alert("Please select the vendor");
                    }
                }
            }]
    });


})();