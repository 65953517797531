(function () {
    angular.module('phapApp')
        .service('securityManagementService', ['$http', '$q', 'config', function ($http, $q, config) {

            var constants = {
                roleManagementUrl: "rolemanagement",
                userManagementUrl: 'user',
                role: '/role',
                removePermission: '/remove/permissions',
                unAssignPermissions: '/role/unassignpermissions/',
                addPermissions: '/role/add/permissions',
                getRoleMembers: '/role/members/',
                unAssignUsers: '/role/unassignusers/',
                addMembers: '/role/add/members',
                reactivateRole: '/role/reactivate/'
            };

            var create = function (data, api) {
                var deferred = $q.defer();
                var res = $http.put(config.apiBaseUrl + api + "/create", data)
                    .then(function (result) {
                        deferred.resolve(result);
                    }, function (result) {
                        deferred.reject(result);
                    });

                return deferred.promise;
            }

            var update = function (data, api) {
                var deferred = $q.defer();
                var res = $http.post(config.apiBaseUrl + api + "/update", data)
                    .then(function (result) {
                        deferred.resolve(result);
                    }, function (result) {
                        deferred.reject(result);
                    });

                return deferred.promise;
            }

            var remove = function (data, api) {
                var deferred = $q.defer();
                var res = $http.post(config.apiBaseUrl + api + "/remove/" , data)
                    .then(function (result) {
                        deferred.resolve(result);
                    },
                    function (result) {
                        deferred.reject(result);
                    });

                return deferred.promise;
            }

            var getAllRoles = function () {
                return $http.get(config.apiBaseUrl + constants.roleManagementUrl + "/roles");
            }

            var getActiveRoles = function () {
                return $http.get(config.apiBaseUrl + constants.roleManagementUrl + "/roles/active");
            }

            var getUnAssignPermissions = function (id) {
                return $http.get(config.apiBaseUrl + constants.roleManagementUrl + constants.unAssignPermissions + id);
            }

            var getUnAssignUsers = function (id) {
                return $http.get(config.apiBaseUrl + constants.roleManagementUrl + constants.unAssignUsers + id);
            }

            var getRole = function (id) {
                return $http.get(config.apiBaseUrl + constants.roleManagementUrl + "/role/" + id);
            }

            var getRoleMembers = function (id) {
                return $http.get(config.apiBaseUrl + constants.roleManagementUrl + constants.getRoleMembers + id);
            }

            var reactivateRole = function (id, api) {
                var deferred = $q.defer();
                var res = $http.post(config.apiBaseUrl + constants.roleManagementUrl + constants.reactivateRole + id)
                    .then(function (result) {
                        deferred.resolve(result);
                    },
                    function (result) {
                        deferred.reject(result);
                    });

                return deferred.promise;
            };

            var removeRolePermission = function (data) {
                var deferred = $q.defer();
                var res = $http.post(config.apiBaseUrl + constants.roleManagementUrl + constants.role + constants.removePermission, data)
                    .then(function (result) {
                        deferred.resolve(result);
                    },
                    function (result) {
                        deferred.reject(result);
                    });

                return deferred.promise;
            };

            var addRolePermissions = function (roleId, permissions) {
                var data = {
                    roleId: roleId,
                    permissions: permissions
                };
                var deferred = $q.defer();
                var res = $http.post(config.apiBaseUrl + constants.roleManagementUrl + constants.addPermissions, data)
                    .then(function (result) {
                        deferred.resolve(result);
                    }, function (result) {
                        deferred.reject(result);
                    });

                return deferred.promise;
            }

            var addMembers = function (roleId, users) {
                var data = {
                    roleId: roleId,
                    users: users
                };
                var deferred = $q.defer();
                var res = $http.post(config.apiBaseUrl + constants.roleManagementUrl + constants.addMembers, data)
                    .then(function (result) {
                        deferred.resolve(result);
                    }, function (result) {
                        deferred.reject(result);
                    });

                return deferred.promise;
            }

            var createRole = function (data) {
                return create(data, constants.roleManagementUrl + constants.role);
            };

            var updateRole = function (data) {
                return update(data, constants.roleManagementUrl + constants.role);
            };

            var removeRole = function (id) {
                return remove(id, constants.roleManagementUrl + constants.role);
            };

            var getAllUsers = function () {
                return $http.get(config.apiBaseUrl + constants.userManagementUrl + "/all");
            }

            var getUser = function (id) {
                return $http.get(config.apiBaseUrl + constants.userManagementUrl + "/" + id);
            }

            var createUser = function (data) {
                return create(data, constants.userManagementUrl);
            };
            var updateUser = function (data) {
                return update(data, constants.userManagementUrl);
            };

            var removeUser = function (data) {
                return remove(data, constants.userManagementUrl);
            };

            var reactivateUser = function (data, api) {
                var deferred = $q.defer();
                var res = $http.post(config.apiBaseUrl + constants.userManagementUrl + "/reactivate/", data)
                    .then(function (result) {
                        deferred.resolve(result);
                    },
                    function (result) {
                        deferred.reject(result);
                    });

                return deferred.promise;
            };

            var getUserRegions = function (id) {
                return $http.get(config.apiBaseUrl + constants.userManagementUrl + "/regions/" + id);
            }
            var getUserDivisions = function (id) {
                return $http.get(config.apiBaseUrl + constants.userManagementUrl + "/divisions/" + id);
            }

            var getUnAssignRegions = function (id) {
                return $http.get(config.apiBaseUrl + constants.userManagementUrl + "/regions/unassign/" + id);
            }

            var addUserRegions = function (userId, regions) {
                // clear the created and updated by users (not their ids) as the payload can be large due to the inclusion of roles and role privileges
                _.each(regions, function (r) {
                    r.createdByUser = null;
                    r.updatedByUser = null;
                });
                var data = {
                    parentId: userId,
                    Children: regions
                };
                var deferred = $q.defer();
                var res = $http.post(config.apiBaseUrl + constants.userManagementUrl + "/regions/add", data)
                    .then(function (result) {
                        deferred.resolve(result);
                    }, function (result) {
                        deferred.reject(result);
                    });

                return deferred.promise;
            }

            var removeUserRegions = function (userId, regions) {
                // clear the created and updated by users (not their ids) as the payload can be large due to the inclusion of roles and role privileges
                _.each(regions, function (r) {
                    r.createdByUser = null;
                    r.updatedByUser = null;
                });
                var data = {
                    parentId: userId,
                    Children: regions
                };
                var deferred = $q.defer();
                var res = $http.post(config.apiBaseUrl + constants.userManagementUrl + "/regions/delete", data)
                    .then(function (result) {
                        deferred.resolve(result);
                    },
                    function (result) {
                        deferred.reject(result);
                    });

                return deferred.promise;
            };

            return {
                getAllRoles: getAllRoles,
                getRole: getRole,
                getRoleMembers: getRoleMembers,
                getUnAssignUsers: getUnAssignUsers,
                getUnAssignPermissions: getUnAssignPermissions,
                getActiveRoles: getActiveRoles,
                createRole: createRole,
                addRolePermissions: addRolePermissions,
                addMembers: addMembers,
                updateRole: updateRole,
                reactivateRole: reactivateRole,
                removeRole: removeRole,
                removeRolePermission: removeRolePermission,
                getAllUsers: getAllUsers,
                getUser: getUser,
                createUser: createUser,
                updateUser: updateUser,
                removeUser: removeUser,
                reactivateUser: reactivateUser,
                getUserRegions: getUserRegions,
                getUserDivisions: getUserDivisions,
                getUnAssignRegions: getUnAssignRegions,
                addUserRegions: addUserRegions,
                removeUserRegions: removeUserRegions
            };


        }]);
})();