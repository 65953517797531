(function () {

    var app = angular.module('phapApp');

    app.component('region', {
        templateUrl: 'app/administration/configuration/region.html',
        bindings: {
            mode: '@'
        },
        controller: ['$scope', '$filter', 'utils', 'referenceService', 'security', function ($scope, $filter, utils, referenceService, security) {
            var vm = this;
            vm.originalDataSet = [];
            vm.gridApi = null;
            vm.historyUrl = "region/0";
            vm.popupInfo = {
                templateUrl: 'app/administration/configuration/createEditRegionPopup.html',
                controller: 'createEditRegionPopupCtrl',
                windowClass: 'weekly-income-modal-window'
            }

            var getRegionList = function () {
                referenceService.getRegionList().then(
                    function (regionList) {
                        var list = [];
                        angular.forEach(regionList.data, function (value, key) {
                            this.push({ data: value, isUpdated: false, isAdded: false });
                        }, list);
                        vm.gridOptions.data = list;
                        vm.originalDataSet = angular.copy(vm.gridOptions.data);
                        $scope.$broadcast('displayLoading', false);
                    });
            }

            var init = function () {
                getSecurity();
            };
            var getSecurity = function () {
                $scope.$broadcast('displayLoading', true);
                //getting logged in user security
                security.getCurrentUserSecurityInfo()
                    .then(function (result) {
                        vm.gridOptions.privileges = result.privileges.UpdateGeneralAdmin;
                        vm.gridOptions.showPrivilegePanel = true;
                        if (vm.gridOptions.privileges)
                            getRegionList();
                    },
                    function () {
                        vm.gridOptions.privileges = false;
                    });
            };

            vm.gridOptions = {
                showPrivilegePanel: false,
                enableCellEdit: false,
                enableSorting: true,
                enableCellEditOnFocus: true,
                newObject: { data: { id: 0, name: null,email:null, isDeleted: false, isReadOnly: false, divisionNames: '', divisions: [] }, isUpdated: false, isAdded: true }
            };

            vm.gridOptions.columnDefs = [
                { name: 'data.name', displayName: 'Region', enableCellEdit: true, enableColumnMenu: false, enableSorting: true },
                { name: 'data.email', displayName: 'Region email', enableCellEdit: true, enableColumnMenu: false, enableSorting: true },
                { name: 'data.divisionNames', displayName: 'Divisions', enableCellEdit: true, enableColumnMenu: false, enableSorting: true },
                {
                    name: 'data.isDeleted', displayName: '', enableCellEdit: false, enableColumnMenu: false, width: 100, enableSorting: false,
                    cellClass: function (grid, row) {

                        if (row.entity.data.isReadOnly) {
                            return "grid-Delete-disabled-button";
                        }
                        return "grid-Delete-button";
                    },
                    cellTemplate: '<span class="gird-icon-space"> <a ng-if="!row.groupHeader &&(!row.entity.data.isDeleted)" title="Edit" ng-click="grid.appScope.update(row.entity)"><span class="glyphicon glyphicon-edit gird-icon-edit" ></span></a></span>' +
                    '<a ng-if="!row.entity.data.isReadOnly && !row.entity.data.isDeleted" title="Delete" ng-click="grid.appScope.deleteRow(row.entity)"><span class="glyphicon glyphicon-trash grid-Delete-button" ></span></a>' +
                    '<a ng-if="!row.entity.data.isReadOnly && row.entity.data.isDeleted" title="ReActivate" ng-click="grid.appScope.reActivate(row.entity)"><span class="glyphicon glyphicon-plus grid-Add-button" ></span></a>' +
                    '<span ng-if="row.entity.data.isReadOnly" title="This region cannot be deleted as there are active user(s) associated. In order to delete all users, need to be dissociated" class="glyphicon glyphicon-trash grid-Delete-disabled-button" ></span>'

                }
            ];

            vm.validation = function (row, colDef, currentData) {
                if (colDef.name === 'data.name') {
                    if (utils.isNullOrEmpty(row.name)) {
                        return { isValid: false, errors: ['region name required'] };
                    }

                    var duplicates = [];
                    angular.forEach(vm.gridOptions.data, function (value, key) {
                        if (value.data.id != 0 && value.data.name.toLowerCase() == row.name.toLowerCase()) {
                            this.push(row);
                        }

                    }, duplicates);
                    if (duplicates.length > 0) {
                        return { isValid: false, errors: ['Duplicate region names not allowed'] };
                    }
                }

                return { isValid: true, error: '' }
            }

            vm.saveRegionList = function (list, refreshAfterSave) {
                referenceService.saveRegion(list).then(
                    function (result) {
                        if (result.data.length > 0) {
                            getRegionList();
                            refreshAfterSave(result);
                        }

                    },
                    // Error
                    function (result) {
                        refreshAfterSave(result);
                    });
            }

            init();


        }],
        controllerAs: 'regionVm'
    });

})();