(function () {
    var app = angular.module('phapApp');
    app.component('directDepositDetails', {
        templateUrl: 'app/financeManagement/directDeposit/directDepositDetails.html',
        controller: ['$scope', '$routeParams', '$location', '$filter', 'directDepositService', 'navigationService', 'notificationService', 'uiGridConstants', '$interval', 'Constants',
            function ($scope, $routeParams, $location, $filter, directDepositService, navigationService, notificationService, uiGridConstants, $interval, Constants) {
				var vm = this;
				vm.constants = Constants;
                vm.Id = $routeParams.id;
                vm.transactionType = {
                    DirectCreditNonGst: 9013,
                    DirectCreditGst: 9014,
                    CancelDirectCreditNonGst: 9015,
                    CancelDirectCreditGst: 9016
                }

                vm.$onInit = function () {
                    vm.gridOptions = getGridOptions();
                    navigationService.setCurrentMenuStatus(
                        'financeManagement', null, 'directDeposit');
                };

                vm.cancel = function () {
                    $location.path('/financeManagement/directDeposit/')
                };

                vm.printOrder = function (divName) {
                    vm.printElement(document.getElementById("printableArea"));
                    window.print();
                }
                vm.printElement = function (elem) {
                    var domClone = elem.cloneNode(true);
                    var $printSection = document.getElementById("printSection");
                    if (!$printSection) {
                        var $printSection = document.createElement("div");
                        $printSection.id = "printSection";
                        document.body.appendChild($printSection);
                    }
                    $printSection.innerHTML = "";
                    $printSection.appendChild(domClone);
                }

                vm.getSearchResults = function () {
                    vm.loading = true;
                    directDepositService.getDirectDepositDetails(vm.Id).then(
                        function (result) {
                            vm.loading = false;
                            vm.details = {};
                            vm.details = result.data;
                            vm.formatData();
                            vm.gridOptions.data = vm.details.invoice.distributionDetails;
                        });
                };

                vm.formatData = function () {
                    if (vm.details.relief)
                        vm.details.incident = vm.details.relief.incident;
                    else {
                        vm.details.incident = vm.details.reestablishment.incident;
                        vm.details.reestablishment.assistanceAmount = (vm.details.reestablishment.furtherPaymentAmount * 1) + (vm.details.reestablishment.initialPaymentAmount * 1);
                    }
					vm.details.incident.incidentDate = $filter("date")(vm.details.incident.incidentDate, vm.constants.printDateFormat);
					vm.details.incident.effectiveDate = $filter("date")(vm.details.incident.effectiveDate, vm.constants.printDateFormat);
					vm.details.incident.endDate = $filter("date")(vm.details.incident.endDate, vm.constants.printDateFormat);
					vm.details.payment.paymentDate = $filter("date")(vm.details.payment.paymentDate, vm.constants.printDateFormat);
                    //oracle dates
					vm.details.payment.oracleCreationDate = $filter("date")(vm.details.payment.oracleCreationDate, vm.constants.printDateFormat);
					vm.details.payment.oracleLastUpdatedDate = $filter("date")(vm.details.payment.oracleLastUpdatedDate, vm.constants.printDateFormat);
					vm.details.payment.oraclePaymentDate = $filter("date")(vm.details.payment.oraclePaymentDate, vm.constants.printDateFormat);
					vm.details.payment.oracleVoidDate = $filter("date")(vm.details.payment.oracleVoidDate, vm.constants.printDateFormat);
                    if (vm.details.payment.oracleCreationDate == '01-01-0001')
                        vm.details.payment.oracleCreationDate = null;
                    if (vm.details.payment.oracleLastUpdatedDate == '01-01-0001')
                        vm.details.payment.oracleLastUpdatedDate = null;
                    if (vm.details.payment.oraclePaymentDate == '01-01-0001')
                        vm.details.payment.oraclePaymentDate = null;
                    if (vm.details.payment.oracleVoidDate == '01-01-0001')
                        vm.details.payment.oracleVoidDate = null;
                    var min = Math.min.apply(Math, vm.details.invoice.distributionDetails.map(function (item) { return item.amount; }));
                    var max = Math.max.apply(Math, vm.details.invoice.distributionDetails.map(function (item) { return item.amount; }));
                    vm.details.invoice.distributionDetails.forEach(function (dd) {
                        var debitCredit;

                        if (dd.transactionType == vm.transactionType.DirectCreditGst || dd.transactionType == vm.transactionType.CancelDirectCreditGst) {
                            dd.gst = "GST";
                        }

                        if (dd.transactionType == vm.transactionType.DirectCreditNonGst || dd.transactionType == vm.transactionType.CancelDirectCreditNonGst) {
                            dd.gst = "NON GST";
                        }

                        debitCredit = vm.isCancelledTransaction(dd) ? 'credit' : 'debit';
                        dd.gst = dd.gst + ' ' + debitCredit;
						dd.dateCreated = $filter("date")(dd.dateCreated, vm.constants.printDateFormat);
                        dd.batchId = vm.details.invoice.batchId;
						dd.sendToOracleDate = $filter("date")(vm.details.invoice.sendToOracleDate, vm.constants.printDateFormat);
						dd.sendToOracleDate = $filter("date")(vm.details.invoice.oracleInvoiceEarliestAccountDate, vm.constants.printDateFormat);
                        dd.invoiceReference = vm.details.invoice.invoiceReference;
                    });
                };
                vm.isCancelledTransaction = function (dd) {
                    if (dd.transactionType == vm.transactionType.CancelDirectCreditGst || dd.transactionType == vm.transactionType.CancelDirectCreditNonGst)
                        return true;
                    return false;
                };

                vm.redirectToIncident = function (id) {
                    $location.path('/administration/incidents/edit/' + id);
                };

                vm.redirectToRelief = function (id) {
                    $location.path('/home/relief/edit/' + id);
                };

                vm.redirectToVendor = function (id) {
                    $location.path('/home/vendor/edit/' + id);
                };

                vm.redirectToReestablishment = function (id) {
                    $location.path('/home/reestablish/edit/' + id);
                };

                var getGridColumnDefs = function () {
                    return [
                        {
                            field: 'id',
                            visible: false,
                            enableHiding: false
                        },
                        {
                            field: 'gst',
                            displayName: 'GST',
                            visible: true,
                            enableHiding: false,
                            headerCellClass: 'subHeading',
                            width: '12%'
                        },
                        {
                            field: 'batchId',
                            displayName: 'Batch ID',
                            visible: true,
                            enableHiding: false,
                            headerCellClass: 'subHeading',
                            width: '12%'
                        },
                        {
                            field: 'dateCreated',
                            displayName: 'Date',
                            visible: true,
                            enableHiding: false,
                            width: '12%'
                        },
                        {
                            field: 'amount',
                            displayName: 'Amount',
                            enableHiding: false,
                            cellFilter: 'phapcurrencycent',
                            cellClass: 'currency',
                            width: '12%'
                        },
                        {
                            field: 'invoiceReference',
                            displayName: 'Transaction number',
                            enableHiding: false,
                            width: '12%'
                        },
                        {
                            field: 'sendToOracleDate',
                            displayName: 'Send to oracle',
                            enableHiding: false,
                            cellFilter: Constants.GridDateFormat,
                            cellClass: 'date-field',
                            width: '12%'
                        },
                        {
                            field: 'invoiceReference',
                            displayName: 'Invoice reference',
                            enableHiding: false,
                            width: '12%'
                        },
                        {
                            field: 'entity',
                            displayName: 'Entity',
                            enableHiding: true,
                            width: '12%'

                        },
                        {
                            field: 'costCentre',
                            displayName: 'Cost centre',
                            enableHiding: true,
                            width: '12%'
                        },
                        {
                            field: 'activity',
                            displayName: 'Activity',
                            enableHiding: true,
                            width: '12%'
                        },
                        {
                            field: 'fund',
                            displayName: 'Fund',
                            enableHiding: true,
                            width: '12%'
                        },
                        {
                            field: 'account',
                            displayName: 'Account',
                            enableHiding: true,
                            width: '12%'
                        },
                        {
                            field: 'productType',
                            displayName: 'Product',
                            enableHiding: true,
                            width: '12%'
                        },
                        {
                            field: 'identifier',
                            displayName: 'Identifier',
                            enableHiding: true,
                            width: '12%'
                        }
                    ]
                };
                var rowtpl = '<div ng-class="{\'grey\':true, \'red\':row.entity.transactionType == 9015 || row.entity.transactionType == 9016 }"><div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader }" ui-grid-cell></div></div>';
                var getGridOptions = function () {
                    return {
                        infiniteScrollRowsFromEnd: 10,
                        infiniteScrollUp: false,
                        enableGridMenu: true,
                        infiniteScrollDown: true,
                        enableSorting: true,
                        enableFiltering: false,
                        enableColumnResizing: true,
                        enableHorizontalScrollbar: uiGridConstants.scrollbars.ALWAYS,
                        enableVerticalScrollbar: uiGridConstants.scrollbars.ALWAYS,
                        enableRowSelection: true,
                        enableFullRowSelection: true,
                        enableRowHeaderSelection: false,
                        enableSelectAll: true,
                        multiSelect: false,
                        enableRowHashing: false,
                        columnDefs: getGridColumnDefs(),
                        appScopeProvider: {
                        },
                        rowTemplate: rowtpl,
                        onRegisterApi: function (gridApi) {
                            // call resize every 500 ms for 5 s after modal finishes opening - usually only necessary on a bootstrap modal
                            $interval(function () {
                                vm.gridApi.core.handleWindowResize();
                            }, 500, 10);
                            vm.gridApi = gridApi;
                        }
                    }
                };
                vm.init = function () {
                    vm.details = {};
                    vm.loading = true;
                    vm.getSearchResults();
                };

                vm.init();
            }],
        controllerAs: 'ddDetailsVm'
    });
})();