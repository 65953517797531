(function () {
    angular.module('phapApp')
        .controller('fileUploaderCtrl', ['$scope', '$window', 'Upload', 'documentService', 'utils', 'Constants', function ($scope, $window, Upload, documentService, utils, Constants) {
            $scope.$uploading = false;
            $scope.selectedFiles = [];
            $scope.selectedDocumentType = {};
            $scope.selectedDocumentTypeClass = '';
            $scope.showDocumentSelectionErrorMsg = false;
            $scope.$errMsg = null;
            $scope.documentTypeChanged = function (selDocType) {
                $scope.selectedDocumentType = selDocType;

                // Clear any errors (if present)
                $scope.selectedDocumentTypeClass = '';
                $scope.showDocumentSelectionErrorMsg = false;
            };
            $scope.maximumUploadDocumentCount = Constants.MaximumUploadDocumentCount;
            var addExisitngFiles = function () {
                if ($scope.existingFiles && $scope.existingFiles.length > 0) {
                    $scope.existingFiles.forEach(function (file) {
                        file.name = file.fileName;
                        file.exisiting = true;
                        $scope.selectedFiles.push(file);
                    });
                }
            };
            addExisitngFiles();
            $scope.showFileSize = function (bytes) {
                var file = documentService.calculateFileSize(bytes);
                return (file.size + ' ' + file.unit);
            };

            $scope.discardFile = function (bytes) {
                var file = documentService.calculateFileSize(bytes);
                if (file.number > 2) //everthing above MB
                    return true;
                if (file.number == 2 && file.size > Constants.FileMaxUploadSize) // MB and greater than 2
                    return true;
                return false;
            };

            $scope.addFiles = function (files) {
                var addFileOptions = {
                    files: files,
                    cancelled: false,
                    maxAllowedToAdd: -1
                };
                $scope.onFileSelect(addFileOptions);

                if (addFileOptions.cancelled) {
                    if (addFileOptions.maxAllowedToAdd >= 0) {
                        var toRemove = $scope.selectedFiles.length - addFileOptions.maxAllowedToAdd;
                        var msg = "You have reached the maximum limit of documents allowed.\n" + toRemove + " document(s) will be removed from the upload list.";
                        $window.alert(msg);
                        $scope.selectedFiles.splice(addFileOptions.maxAllowedToAdd);
                    }
                } else {
                    $scope.$uploadFailed = false;
                    $scope.selectedFiles.concat(files);
                }
            };

            $scope.removeFile = function (f) {
                var index = $scope.selectedFiles.indexOf(f);
                if (index >= 0) {
                    $scope.selectedFiles.splice(index, 1);
                }
            };

            $scope.cancel = function () {
                $scope.$close(false);
                $scope.onCancel();
            };

            $scope.upload = function () {
                var docType = '';

                // Check if the document types have been provided, if so force the uesr to make a selection
                if (!angular.isUndefined($scope.documentTypes)) {
                    // Extract the selection from the document type dropdown
                    if (!angular.isUndefined($scope.selectedDocumentType)) {
                        docType = $scope.selectedDocumentType.name;
                    }

                    // Check that the user has selected a document type for the documents
                    if (angular.isUndefined(docType) || docType === '') {
                        $scope.selectedDocumentTypeClass = 'invalid';
                        $scope.showDocumentSelectionErrorMsg = true;
                        return;
                    }
                }

                $scope.$uploading = true;
                $scope.$uploadFailed = false;
                $scope.$errMsg = null;

                $scope.selectedFiles.forEach(function (file, i) {
                    if (file.exisiting)
                        $scope.selectedFiles.splice(i, 1);
                    else {
                        if ($scope.discardFile(file.size))
                            $scope.selectedFiles.splice(i, 1);
                    }
                });

                documentService
                    .upload($scope.selectedFiles, docType)
                    .then(
                    function (result) {
                        $scope.$uploading = false;
                        $scope.$errMsg = null;
                        $scope.onFileUploaded(result.data);
                        $scope.$close(true);
                    },
                    function (resp) {
                        $scope.$uploading = false;
                        $scope.$uploadFailed = true;
                        $scope.$errMsg = 'Error occurred while $uploading. \n Please try again or cancel';
                        if (resp.data && resp.data.errors && resp.data.errors.length) {
                            $scope.$errMsg = resp.data.errors[0].message;
                        }
                       
                    });
            };
        }]);
})();