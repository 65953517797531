(function () {
    angular.module('phapApp')
        .directive('adjustments', ['$window','utils', function ($window,utils) {
            return {
                replace: true,
                scope: {
                    app: '=',
                    isRelief: '=',
                    isAuthorisedToAdd: '='
                },
                templateUrl: 'app/home/adjustments/adjustments.html',
                controller: ['$element', '$scope', 'adjustmentService', 'Constants',
                    function ($element, $scope, adjustmentService, constants) {
                        $scope.initialLoad = true;
                        $scope.showAddNew = false;
                        $scope.adjustments = [];
                        $scope.createNewAdjustment = false;

                        var setData = function (data) {
                            $scope.loading = false;
                            $scope.adjustments = [];

                            if (data.adjustmentPayment != null) {
                                $scope.adjustments = data.adjustmentPayment;
                                var adjustmentDataLength = $scope.adjustments.length;

                                if (adjustmentDataLength > 0) {
                                    if ($scope.adjustments[0].reliefApplicationId != null) {
                                        $scope.$root.$broadcast('updateReliefAdjustmentPayment', { "totalAdjustmentAmount": data.totalAdjustmentAmount, "remainingEntitlementAmount": data.remainingEntitlementAmount });
                                    }
                                }

                                if (adjustmentDataLength > 0) {
                                    if ($scope.adjustments[0].reestablishApplicationId != null) {
                                        $scope.$root.$broadcast('updateReestablishAdjustmentPayment', { "totalAdjustmentAmount": data.totalAdjustmentAmount, "remainingEntitlementAmount": data.remainingEntitlementAmount });
                                    }
                                }
                            }
                            else {
                                $scope.$root.$broadcast('updateReliefAdjustmentPayment', { "totalAdjustmentAmount": 0 });
                                $scope.$root.$broadcast('updateReestablishAdjustmentPayment', { "totalAdjustmentAmount": 0 });
                            }

                            $scope.remainingEntitlementAmount = data.remainingEntitlementAmount;
                        };

                        var loadData = function () {
                            $scope.loading = true;
                            $scope.ApplicationIds = {};

                            if ($scope.isRelief == true) {
                                $scope.ApplicationIds.ReliefApplicationId = $scope.app.id;
                                $scope.createNewAdjustment = $scope.app.state === constants.ReliefApplicationState.Eligible || $scope.app.state === constants.ReliefApplicationState.Active;
                            }
                            else if ($scope.isRelief == false) {
                                $scope.ApplicationIds.ReestablishApplicationId = $scope.app.id;
                                $scope.createNewAdjustment = $scope.app.state != constants.ReestablishApplicationState.Eligible || $scope.app.state != constants.ReestablishApplicationState.Active;
                            }

                            adjustmentService.getAdjustments($scope.ApplicationIds).then(
                                function (result) {
                                    setData(result.data);
                                });
                        };

                        $scope.new = function () {
                            $scope.showAddNew = !$scope.showAddNew;
                        };

                        $scope.$on('load-adjustments', function (event, args) {
                            $scope.adjustments = [];
							$("html, body").animate({ scrollTop: 0 }, 100);
                            if (args.isAddNew != null) {
                                $scope.showAddNew = args.isAddNew;
                            }

                            if (args.loadData == true && $scope.showAddNew == false && ($scope.adjustments.length == 0 || ($scope.adjustments.length > 0 && args))) {
                                loadData();
                            }
                        });
                    }]
            };
        }]);
})();