(function () {
    var app = angular.module('phapApp');
    app.controller('rejectPopupController', ['$scope', 'utils', 'reestablishService', 'reliefService', 'item', 'payment', 'isRelief', 'notificationService',
        function ($scope, utils, reestablishService, reliefService, item, payment, isRelief, notificationService) {
            $scope.showRejectMessage = false;
            $scope.rejectionReason = "";
            $scope.reject = function () {
                if (utils.isNullOrEmpty($scope.rejectionReason)) {
                    $scope.showRejectMessage = true;
                } else {
                    var paymentModel = angular.copy(payment);
                    paymentModel.rejectionReason = $scope.rejectionReason;
                    var submitModel = {
                        applicationId: item.id,
                        rowVersion: item.rowVersion,
                        payment: paymentModel
                    };
                    notificationService.notify('showLoadingPanel', true);
                    if (isRelief)
                        $scope.rejectRelief(submitModel);
                    else
                        $scope.rejectReEst(submitModel)
                }
            };
            $scope.rejectRelief = function (submitModel) {
                reliefService.rejectPayment(submitModel).then(function (data) {
                    notificationService.notify('showLoadingPanel', false);
                    $scope.$close(data);
                }, function () {
                    notificationService.notify('showLoadingPanel', false);

                });
            };
            $scope.rejectReEst = function (submitModel) {
                reestablishService.rejectPayment(submitModel).then(function (data) {
                    notificationService.notify('showLoadingPanel', false);
                    $scope.$close(data);
                }, function () {
                    notificationService.notify('showLoadingPanel', false);

                });
            };

            $scope.cancel = function () {
                $scope.$dismiss();
            }
            
        }]);
})();