(function () {
    angular
        .module('phapApp')
        .controller('EditIncomeAmountsModalCtrl2', ['$scope', '$timeout', 'utils', 'Constants',
            function ($scope, $timeout, utils, Constants) {

                var _wo = _.pick($scope.item, ['applicant', 'householdMembers', 'weeklyIncomeTotalWeeklyIncome', 'weeklyIncomeLimit', 'weeklyIncomeMaximumPayment']);
                this.weeklyIncomeObj = angular.copy(_wo);        

                this.members = _.concat([this.weeklyIncomeObj.applicant], this.weeklyIncomeObj.householdMembers || []);

                this.checkweeklyIncomeRequired = function () {
                  return  _.find(this.members, function (m) { return m.weeklyIncome != null && m.weeklyIncome !== ''}) == null;
                }

                this.weeklyIncomeRequired = this.checkweeklyIncomeRequired();
                
                this.calc = function () {
                    this.weeklyIncomeRequired = this.checkweeklyIncomeRequired();
                    $scope.calc(this.weeklyIncomeObj);
                };

                this.applyChanges = function () {
                    if (this.weeklyIncomeRequired) return;

                    var content = "";
                    if (this.weeklyIncomeObj.applicant.memberType.id === Constants.memberTypes.owner) {
                        content = "Re-establishment assistance is not available to owners with the stated weekly income for the defined household composition";
                    } else {
                        content = "Re-establishment assistance is not available to tenants with the stated weekly income for the defined household composition";
                    }

                    // copy back
                    _.merge($scope.item, this.weeklyIncomeObj);
                    $scope.$close(true);

                    if (this.weeklyIncomeObj.weeklyIncomeLimit < this.weeklyIncomeObj.weeklyIncomeTotalWeeklyIncome) {
                        $scope.item.incomeExceedsThreshold = true;
                        utils.confirm("Weekly Income Limit", content, 'Ineligible', 'Proceed')
                            .then(function () {
                                $scope.saveAsIneligible();
                            }, function () {
                                $scope.item.weeklyIncomeIncomeEvidenceAttached = $scope.Constants.YesNoNotyet.NotYet;
                            });
                        return;
                    }
                };
        }]);
})();
