(function () {
    angular.module('phapApp')
        .service('configurationValidationService', ['Constants', 'utils',
            function (constants, utils) {

                var validateNumberOfAdults = function (value) {

                    if (utils.isNullOrEmpty(value)) {
                        return { isValid: false, errors: ['Number of adults required'] };
                    }

                    if (value < constants.configuration.minAdults || value > constants.configuration.maxAdults) {
                        return {
                            isValid: false, errors: ['Number of adults should be' +
                                constants.configuration.minAdults + "to " + constants.configuration.maxAdults]
                        };
                    }
                    return { isValid: true, errors: '' };
                };

                var validateNumberOfChilderen = function (value) {

                    if (utils.isNullOrEmpty(value)) {
                        return { isValid: false, errors: ['Number of Childrens required'] };
                    }

                    if (value < constants.configuration.minChildren || value > constants.configuration.maxChildren) {
                        return {
                            isValid: false, errors: ['Number of Childrens should be' +
                                constants.configuration.minChildren + "to " + constants.configuration.maxChildren]
                        };
                    }
                    return { isValid: true, errors: '' };
                };

                var validateAmount = function (value) {

                    if (utils.isNullOrEmpty(value)) {
                        return { isValid: false, errors: ['amount to be paid required'] };
                    }

                    if (value < 0) {
                        return {
                            isValid: false, errors: ['amount to be paid required']
                        };
                    }
                    return { isValid: true, errors: '' };
                };

                var isFutureDate = function (selectedDate) {
                    if (utils.isNullOrEmpty(selectedDate)) {
                        return {
                            isValid: false, errors: ['Date Required']
                        };
                    }

                    var today = new Date().getTime(),
                        idate = new Date(selectedDate).getTime();

                    return (today - idate) < 0
                        ? {
                            isValid: true,
                            errors: ''
                        }
                        : {
                            isValid: false,
                            errors: ['Date need to be a future date']
                        };
                }

                return {
                    validateNumberOfAdults: validateNumberOfAdults,
                    validateNumberOfChilderen: validateNumberOfChilderen,
                    validateAmount: validateAmount,
                    isFutureDate: isFutureDate
                }
            }]);
})();