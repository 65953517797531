(function () {
    angular.module('phapApp')
        .controller(
            'onlineAppMgmtController',
            [
                '$scope', 'security', 'dashboardService',
                function ($scope, security, dashboardService) {
                    onInit();

                    function getSecurity() {
                        security.getCurrentUserSecurityInfo().then(
                            function (result) {
                                $scope.privileges = result.privileges;
                            }
                        );
                    };

                    function getConstants() {
                        dashboardService.getConstants().then(
                            function (result) {
                                $scope.constants = result;
                            }
                        );
                    };

                    function onInit() {
                        getSecurity();
                        getConstants();
                    }
                }
            ]
        );
}());