(function () {
    angular.module('phapApp')
        .directive('safetyReconnection', ['Constants', 'utils', function (Constants, utils) {
            return {
                scope: true,
                restrict: 'E',
                templateUrl: 'app/home/reestablish/_safety-reconnection.html',
                controller: ['$scope','reestablishService', function ($scope, reestablishService) {

                    $scope.uploadController = {};

                    // #region Business Rule checking

                    $scope.$watchCollection('[item.safetyReconnectionClaimAmount, item.safetyReconnectionEvidenceAttached]', function (newValues) {
                        if (!angular.isDefined(newValues[0]))
                            return;
                        $scope.safetyReconnectionBusinssRules();
                    });

                    $scope.$on('dataLoaded', function () {
                        $scope.safetyReconnectionBusinssRules();
                    });

                    $scope.safetyReconnectionBusinssRules = function () {
                        var amount = utils.parseDollarAmount($scope.item.safetyReconnectionClaimAmount);
                        $scope.formSections.categoriesOfAssessment.subMenu.safetyReconnection.evidenceRequired = ($scope.item.applicant.memberType.id === Constants.memberTypes.owner && amount > 0 && $scope.item.safetyReconnectionEvidenceAttached !== true);
                        reestablishService.setExceptionalCircumstances($scope.formSections, $scope.item);
                    };

                    // #endregion 

                    var noDocumentsAttached = function () {
                        if ($scope.item && $scope.item.documents) {
                            var documents = $scope.item.documents[Constants.reestablishSections.safetyReconnection];
                            return !angular.isDefined(documents) || documents.length === 0;
                        }
                        return false;
                    };

                    var confirm = function () {
                        var title = 'Evidence documents required!';
                        var content = 'Evidence attached has been selected as Yes and no attachments have been loaded. \nDocuments must be loaded in order to set the Evidence attached field to Yes.';
                        utils.confirm(title, content, 'Proceed', 'Cancel')
                            .then(function () {
                                $scope.item.safetyReconnectionEvidenceAttached = false;
                            }, function () {
                                $scope.item.safetyReconnectionEvidenceAttached = null;
                            });
                    };

                    $scope.checkEvidence = function () {
                        if (noDocumentsAttached()) {
                            $scope.uploadController.$newUpload();
                        }
                    };

                    $scope.$watch('item.applicant.memberType.id', function (nv, ov) {
                        if (!$scope.loaded || !$scope.item.documents) return;
                        var documents = $scope.item.documents[Constants.reestablishSections.safetyReconnection];
                        if (documents && documents.length) {
                            if (nv != null && ov != null && nv != ov && nv != Constants.memberTypes.owner) {
                                utils.alert('Evidence documents are attached for Safety and Reconnection of Essential Services. Please remove them before changing the applicant to tenant.', 'Evidence documents attached!');
                                $scope.item.applicant.memberType.id = Constants.memberTypes.owner;
                            }
                        }
                    });

                    var verifyDocumentsAttached = function () {
                        if ($scope.item && $scope.item.safetyReconnectionEvidenceAttached) {
                            if (noDocumentsAttached()) {
                                confirm();
                            }
                        }
                    };

                    $scope.onCancelUpdate = function () {
                        verifyDocumentsAttached();
                    };

                    $scope.onFileListChanged = function () {
                        verifyDocumentsAttached();
                    };
                }]
            };
        }]);
})();