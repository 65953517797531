(function () {
	angular.module('phapApp')
		.service('lgaService', ['$http', '$q', 'config', function ($http, $q, config) {

			var matchLgaUrl = config.apiBaseUrl + "lga/match/{lga}";
			var searchLgaUrl = config.apiBaseUrl + "lga/search/{lga}";
			var uploadLgaUrl = config.apiBaseUrl + "lga/upload";
			var getLgaWithRegionAndDivisionUrl = config.apiBaseUrl + "lga/get-region-division";
			var getLgaFileUrl = config.apiBaseUrl + "lga/download";
			var matchLga = function (lga) {
				var url = matchLgaUrl.replace("{lga}", lga);
				return $http.get(url);
			}
			var searchLga = function (lga) {
				var url = searchLgaUrl.replace("{lga}", lga);
				return $http.get(url);
			}
			var getLgaFile = function () {
				var url = getLgaFileUrl;
				var deferred = $q.defer();
				var res = $http({
					method: 'POST',
					url: url,
					responseType: 'arraybuffer'
				}).then(function (response, status, headers, config) {
					deferred.resolve(response.data);
				}, function (data, status, headers, config) {
					deferred.reject(data);
				});

				return deferred.promise;
			}

			var getLgaDetailsWithRegionAndDivision = function () {
				var deferred = $q.defer();
				var res = $http.get(getLgaWithRegionAndDivisionUrl)
					.then(function (response, status, headers, config) {
						deferred.resolve(response.data);
					},
					function (error, status, headers, config) {
						deferred.reject(data);
					});
				return deferred.promise;
			}

			var uploadLgaFile = function (fileItem) {
				var url = uploadLgaUrl;
				var formdata = {};
				fileItem.formData.push(formdata);
				fileItem.url = url;
				fileItem.upload();
			}			
			return {
				matchLga: matchLga,
				searchLga: searchLga,
				uploadLgaFile: uploadLgaFile,
				getLgaFile: getLgaFile,
				getLgaDetailsWithRegionAndDivision: getLgaDetailsWithRegionAndDivision
			};

		}]);
})();