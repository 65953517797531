(function () {

    var app = angular.module('phapApp');
    app.component('vendorStatusTasks', {
        templateUrl: 'app/home/dashboard/vendorStatusTasks.html',
        bindings: {
            tasks: '=',
            timeLapsed: '='
        },
        controller: ['dashboardService', function (dashboardService) {
            var vm = this;
            
        }],
        controllerAs: 'vm'
    });

})();