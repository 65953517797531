(function () {
    'use strict';

    angular
        .module('phapApp')
        .factory('historyService', historyService);

    historyService.$inject = ['$http', '$q', 'config'];

    function historyService($http, $q, config) {

        var service = {
            getHistoryDetails: getHistoryDetails,
            getDiff: getDiff
        };

        return service;

        function getDiff(entity, id, oldVersionTimestamp, newVersionTimeStamp) {
            var deferred = $q.defer();

            var res = $http.get(config.apiBaseUrl + 'history/' + entity + '/get-diff/' + id + '?' + 'oldVersionTimestamp=' + oldVersionTimestamp + '&newVersionTimeStamp=' + newVersionTimeStamp)
                .then(function (response, status, headers, config) {
                    deferred.resolve(response.data);
                },
                function (error, status, headers, config) {
                    deferred.reject(data);
                });

            return deferred.promise;
        }

        function getHistoryDetails(entity, id) {
            var deferred = $q.defer();
            var res = $http.get(config.apiBaseUrl + 'history/get-versions/' + entity + '/' + id)
                .then(function (response, status, headers, config) {
                    deferred.resolve(response.data);
                },
                function (error, status, headers, config) {
                    deferred.reject(data);
                });

            return deferred.promise;
        }


    }
})();