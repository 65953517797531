(function () {

    var app = angular.module('phapApp');

    app.component('singleValueConfiguration', {
        templateUrl: 'app/administration/configuration/singleValueConfiguration.html',
        bindings: {
            mode: '@',
            configType: '@',
            money: '@'
        },
        controller: ['$scope', '$filter', 'utils', 'Constants', 'configurationService', 'security', function ($scope, $filter, utils, Constants, configurationService, security) {

            var vm = this;
            vm.gridOptions = { showPrivilegePanel: false};
            vm.originalDataSet = [];
            var getPeriodList = function () {
                configurationService.getConfigurationValue(vm.configType).then(
                    function (config) {
                        setUpdateValueRules(config.data);
                    });
            }

            var init = function () {
                getSecurity();
            };
            var getSecurity = function () {
                //getting logged in user security
                security.getCurrentUserSecurityInfo()
                    .then(function (result) {
                        vm.privileges = result.privileges.UpdateReestablishAdmin;
                        vm.gridOptions.showPrivilegePanel = false;
                        if (vm.privileges)
                            getPeriodList();
                        else
                            vm.gridOptions.showPrivilegePanel = true;
                    },
                    function () {
                        vm.currentUserSecurityInfo = {};
                    });
            };
            vm.validation = function (currentData) {
                return configurationService.validation(currentData);
            }

            vm.save = function (futurePeriod, refreshAfterSave) {
                futurePeriod.configuration = { id: vm.gridOptions.id, key: vm.gridOptions.key, dataType: vm.gridOptions.dataType, minValue: vm.gridOptions.minValue, maxValue: vm.gridOptions.maxValue, section: vm.gridOptions.section, description: vm.gridOptions.description }
                var foundIndex;
                vm.gridOptions.periods.forEach(function (value, i) {
                    value.configuration = { id: vm.gridOptions.id, key: vm.gridOptions.key, dataType: vm.gridOptions.dataType, minValue: vm.gridOptions.minValue, maxValue: vm.gridOptions.maxValue, section: vm.gridOptions.section, description: vm.gridOptions.description }
                    if (value.id == futurePeriod.id)
                        foundIndex = i;
                });
                if (foundIndex != null)
                    vm.gridOptions.periods[foundIndex] = futurePeriod;
                else
                    vm.gridOptions.periods.push(futurePeriod);

                configurationService.saveConfigurationValue(vm.gridOptions).then(
                    function (result) {
                        if (result.data != null) {
                            setUpdateValueRules(result.data);
                            refreshAfterSave(result);
                        }
                    },
                    // Error
                    function (result) {
                        refreshAfterSave(result);
                    });
            }

            var setUpdateValueRules = function (data) {
                data.showPrivilegePanel = true;
                vm.gridOptions = data;
                vm.gridOptions.privileges = vm.privileges;
                if (vm.money)
                    vm.gridOptions.isValueMoney = true;
                vm.originalDataSet = angular.copy(vm.gridOptions);
                configurationService.setUpdateValueRules(data);
            }
            this.$onInit = function () {
                init();
            };
            

        }],
        controllerAs: 'singleValueConfigurationVm'
    });

})();