(function () {

    var app = angular.module('phapApp');

    app.component('directDeposit', {
        templateUrl: 'app/financeManagement/directDeposit/directDeposit.html',
        controller: ['$scope', '$routeParams', '$timeout', '$cookies', '$uibModal', '$location', 'notificationService', 'navigationService', 'directDepositService', 'uiGridConstants', 'localStorageService', 'utils', '$interval', 'security', 'Constants', 'cacheService',
            function ($scope, $routeParams, $timeout, $cookies, $uibModal, $location, notificationService, navigationService, directDepositService, uiGridConstants, localStorageService, utils, $interval, security, Constants, cacheService) {

                navigationService.setCurrentMenuStatus(
                    navigationService.topMenuCodes.financeManagement,
                    navigationService.pageCodes.directDeposit);

                var vm = this;
                vm.filterVisible = true;
               

                vm.exportToCsv = function () {
                    vm.exporting = true;
                    var savedData = vm.retrieveFilters();
                    if (savedData) {
                        directDepositService.exportSearch(savedData).then(
                            function (response) {
                                vm.exporting = false;
                                var file = new Blob([response.data], { type: 'text/csv' });
                                saveAs(file, 'DirectDepositList.csv');
                            },
                            function (response) {
                                vm.exporting = false;
                            });
                    }
                };

                vm.validSearchCriteria = function (shouldShowError) {
                    if ((!vm.search.fromDate || vm.search.fromDate == '') &&
                        (!vm.search.toDate || vm.search.toDate == '') &&
                        (!vm.search.vendorName || vm.search.vendorName == '') &&
                        (!vm.search.contact || vm.search.contact == '') &&
                        (!vm.search.address || typeof Object.keys(vm.search.address)[0] === 'undefined') &&
						(!vm.search.incidentNumber || vm.search.incidentNumber == '')) {
						if (shouldShowError) {
							utils.alert("You must enter at least one search criteria first.");
						}
                        return false;
                    }
                    return true;
                };

                vm.setData = function (data) {
                    data.forEach(function (result) {
                        result.contact.name = result.contact.givenName + ' ' + result.contact.surname;
                    });
                    vm.results = data;
                    vm.gridOptions.data = angular.copy(vm.results);
                };

                vm.saveFilters = function () {
                    cacheService.saveToCache('directDepositSearchFilters.' + $cookies.get("UserId"), vm.search);
                };

                vm.retrieveFilters = function () {
                    return cacheService.readFromCache('directDepositSearchFilters.' + $cookies.get("UserId"));
                };

                vm.loadSavedFilters = function () {
                    var savedData = vm.retrieveFilters();
                    if (savedData) {
                        vm.search = savedData;
                    } else {
                        vm.clearFilters();
                    }
                }
                vm.beforeSearch = function () {
                    vm.formattedSearch = [];
                    vm.formattedSearch = angular.copy(vm.search);
                    if (vm.search.fromDate)
                        vm.formattedSearch.fromDate = vm.formattedSearch.fromDate.getFullYear() + '-' + (vm.formattedSearch.fromDate.getMonth() + 1) + '-' + vm.formattedSearch.fromDate.getDate();
                    if (vm.search.toDate)
                        vm.formattedSearch.toDate = vm.formattedSearch.toDate.getFullYear() + '-' + (vm.formattedSearch.toDate.getMonth() + 1) + '-' + vm.formattedSearch.toDate.getDate();
                };


                vm.getSearchResults = function (showAlert) {
					if (!vm.validSearchCriteria(showAlert)) return;
                    vm.searching = true;
                    vm.saveFilters();
                    vm.beforeSearch();

                    directDepositService.searchDirectDeposit(vm.formattedSearch).then(
                        function (result) {
                            vm.searching = false;
                            vm.results = [];

                            if (result.data.length == 0) {
                                vm.noResultsFound = true;
                            }
                            else {
                                vm.noResultsFound = false;
                            }

                            vm.setData(result.data)
                        });
                };

                vm.clearFilters = function () {
                    vm.search = {};
                    vm.filterVisible = true;
                    sessionStorage.removeItem('directDepositSearchFilters.' + $cookies.get("UserId"));
                };

                var getGridOptions = function () {
                    return {
                        enableGridMenu: false,
                        enableSorting: true,
                        enableFiltering: false,
                        enableColumnResizing: true,
                        enableHorizontalScrollbar: uiGridConstants.scrollbars.ALWAYS,
                        enableVerticalScrollbar: uiGridConstants.scrollbars.ALWAYS,
                        enableRowSelection: false,
                        enableFullRowSelection: false,
                        enableRowHeaderSelection: false,
                        enableSelectAll: false,
                        multiSelect: false,
                        enableRowHashing: false,
                        columnDefs: getGridColumnDefs(),
                        appScopeProvider: {
                            openItem: function (row) {
                                openItem(row);
                            }
                        },
                        rowTemplate:
                        '<div>' +
                        '<div class="ui-grid-cell" ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" ui-grid-cell ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader }" style="cursor:pointer" ng-click="grid.appScope.openItem(row.entity.paymentId)"> ' +
                        '</div>' +
                        '</div>',
                        onRegisterApi: function (gridApi) {
                            vm.gridApi = gridApi;
                            vm.gridApi.core.on.rowsRendered($scope, rowsRendered);
                            vm.gridApi.colResizable.on.columnSizeChanged($scope, saveState);
                            vm.gridApi.core.on.columnVisibilityChanged($scope, saveState);
                            vm.gridApi.core.on.filterChanged($scope, saveState);
                            vm.gridApi.core.on.sortChanged($scope, saveState);
                            // call resize every 500 ms for 5 s after modal finishes opening - usually only necessary on a bootstrap modal
                            $interval(function () {
                                vm.gridApi.core.handleWindowResize();
                            }, 500, 10);
                            // Restore previously saved state.
                            restoreState();
                        }
                    }
                };
                function saveState() {
                    var state = vm.gridApi.saveState.save();
                    localStorageService.set('directDepositSearch.gridState.' + $cookies.get("UserId"), state);
                }

                function restoreState() {
                    $timeout(function () {
                        var state = localStorageService.get('directDepositSearch.gridState.' + $cookies.get("UserId"));
                        if (state) vm.gridApi.saveState.restore($scope, state);
                    });
                }
                var rowsRendered = function () {

                    vm.filteredRecords = vm.gridApi.core.getVisibleRows(vm.gridApi.grid).length;
                    if ($routeParams.orderNumber && vm.gridOptions.data.length > 0) {
                        vm.gridApi.selection.selectAllVisibleRows();
                    }
                    else {
                        vm.gridApi.selection.clearSelectedRows();
                    }
                };

                var getGridColumnDefs = function () {
                    return [
                        {
                            field: 'processedDate',
                            displayName: 'Processed date',
                            enableHiding: true,
                            enableColumnMenu: false,
                            cellClass: 'date-field',
                            cellFilter: Constants.GridDateFormat,
                            width: '12%'
                        },
                        {
                            field: 'directDepositId',
                            visible: false,
                            enableHiding: false
                        },
                        {
                            field: 'vendorName',
                            displayName: 'Vendor',
                            enableHiding: true,
                            enableColumnMenu: false,
                            width: '15%'
                        },
                        {
                            field: 'invoiceReference',
                            displayName: 'Invoice reference',
                            enableHiding: true,
                            enableColumnMenu: false,
                            width: '16%'
                        },
                        {
                            field: 'paymentNumber',
                            displayName: 'Oracle payment number',
                            enableHiding: true,
                            enableColumnMenu: false,
                            width: '16%'
                        },
                        {
                            field: 'incidentName',
                            displayName: 'Incident name',
                            enableHiding: false,
                            enableColumnMenu: false,
                            width: '14%'
                        },
                        {
                            field: 'address',
                            displayName: 'Affected address',
                            enableHiding: false,
                            enableColumnMenu: false,
                            width: '15%'
                        },
                        {
                            field: 'contact.name',
                            displayName: 'Applicant',
                            enableHiding: false,
                            enableColumnMenu: false,
                            width: '12%'
                        }
                    ]
                };

                var openItem = function (directDepositId) {
                    $location.path('/financeManagement/directDeposit/details/' + directDepositId)
                };

                vm.updateMinDate = function () {
                    vm.dateOptionsTo.minDate = new Date(vm.search.fromDate);
                };
                vm.dateOptionsFrom = {
                    maxDate: new Date(),
                    dateFormat: Constants.DatePickerFormat
                };
                vm.dateOptionsTo = {
                    maxDate: new Date(),
                    dateFormat: Constants.DatePickerFormat
                };

                var checkPermission = function () {
                    security.getCurrentUserSecurityInfo().then(
                        function (result) {
                            vm.currentUserSecurityInfo = result;
                            vm.hasPermission = result.privileges.SearchDirectDeposits;
                            if (vm.hasPermission) {
                                vm.loadSavedFilters();
                                vm.getSearchResults(false);
                            }
                        },
                        function () {
                            vm.currentUserSecurityInfo = {};
                        })
                };
                vm.$onInit = function () {
                    vm.loaded = true;
                    vm.searching = false;
                    vm.exporting = false;
                    vm.hasPermission = true;
                    vm.gridOptions = getGridOptions();
                    vm.gridOptions.data = [];
                    vm.noResultsFound = false;
                    vm.results = [];
                    checkPermission();

                };

            }],
        controllerAs: 'directDepositVm'
    });

})();