(function () {
    angular.module('phapApp')
        .directive('addressLga', ['$window', function ($window) {
            return {
                restrict: 'E',
                templateUrl: 'app/home/relief/_affectedAddressViewTemplate.html',
                controller: ['$scope', 'lgaService', '$uibModal', 'utils', '$q', '$window', affectedAddressController],
                link: function ($scope, elem, attrs) {
                    var newScope = $scope.$new(false);
                    $scope.expanded = true;
                    angular.element('body').on('click change keydown', '.affected-addr input:not(button)', function (e) {
                        if (!$scope.IsNotEditable()) {
                            if (!$scope.state.affectedAddressEditModalOpened) {
                                newScope.origin = $scope;
                                newScope.originAffectedAddress = angular.copy($scope.item.affectedAddress);
                                newScope.state.affectedAddressEditModalOpened = true;
                                if (_.some(['streetName', 'streetNo', 'suburb', 'postcode', 'state'],
                                    function (prop) {
                                        return _.isEmpty(newScope.item.affectedAddress.address[prop]);
                                    })) {
                                    newScope.focus = "googleAddress";
                                }
                                else {
                                    newScope.focus = angular.element(e.target).attr('name');
                                }
                                $scope.openModal('app/home/relief/_affectedAddressEditTemplate.html', 'AffectedAddressEditModalCtrl', newScope);
                                e.preventDefault();
                            }
                        }
                    });
                    angular.element($window).bind("scroll", function () {
                        //Set new top to autocomplete dropdown
                        if (angular.element('#googleAddress').length > 0) {
                            var newTop = angular.element('#googleAddress').offset().top +
                                angular.element('#googleAddress').outerHeight();
                            angular.element('.pac-container').css('top', newTop + 'px');
                        }
                    });
                }
            };
        }]);

    function affectedAddressController($scope, lgaService, $uibModal, utils, $q, $window) {

        // Flag that when in 'view mode' we do not want to see the google adress lookup field
        $scope.showGoogleAddress = false;

        var bizService = $scope.$bizService;
        $scope.$watch('item.addressDetails', function (newValue, oldValue) {
            if (newValue && newValue.address_components) {
                $scope.item.affectedAddress.lga = ''; //clearing the lga of previous search to empty
                mapGoogleMapsAddress(newValue, $scope.item.affectedAddress.address, $scope.item.address);
                var lga = getFieldFromGoogleAddressComponents($scope.addressComponents, "administrative_area_level_2",true);
                if (!!lga) {
                    lgaService.matchLga(lga).then(function (result) {
                        $scope.item.affectedAddress.lga = result.data;
                    },
                        function (error) {
                            $scope.item.affectedAddress.lga = [
                                {
                                    lgaCode: null,
                                    name: null
                                }
                            ];
                        });
                }
            }

        });

        $scope.validateAffectedAddress = function (affectedAddress) {
            $scope.state.applicationsWithSameAddress = [];
            $scope.state.addressInUse = false;

            return bizService.validateAffectedAddress($scope.item.incidentId, $scope.item.applicationNumber, affectedAddress)
                .then(function (result) {
                    var addressIsInUse = result && result.length > 0;
                    if (addressIsInUse) {
                        $scope.state.addressInUse = true;
                        $scope.state.applicationsWithSameAddress = result;
                        return $scope.openAffectedAddressModal().then(function () {
                            return $scope.state.proceedOnAddressInUse;
                        });
                    } else {
                        return result;
                    }
                });
        };

        $scope.validateLga = function (lga) {
            $scope.state.affectedAddressLgaValidRegion = null;
            $scope.state.affectedAddressLgaInvalid = false;
            $scope.state.affectedAddressLgaName = null;

            return bizService.validateLga($scope.item.incidentId, lga)
                .then(function (result) {
                    if (result !== true) {
                        $scope.state.affectedAddressLgaModalOpened = false;
                        $scope.state.affectedAddressLgaInvalid = true;
                        $scope.state.affectedAddressLgaValidRegion = result;
                        $scope.state.affectedAddressLgaName = lga.name;
                        return $scope.openAffectedAddressLgaModal().then(function () {
                            return !$scope.state.cancelWhenAffectedAddressLgaInvalid;
                        });
                    } else {
                        return result;
                    }
                });
        };

        $scope.openAffectedAddressModal = function () {
            var deferred = $q.defer();

            var address = $scope.item.affectedAddress.address;
            var strAddress = '';
            if (!!address.streetNo && !!address.streetName && !!address.suburb && !!address.postcode) {
                if (!!address.unitNo) {
                    strAddress = address.unitNo + '/' ;
                }
                strAddress += address.streetNo +
                    ' ' +
                    address.streetName +
                    ', ' +
                    address.suburb +
                    ', ' +
                    address.postcode +
                    ', Victoria, Australia';
            }

            if (!$scope.state.affectedAddressModalOpened) {
                $scope.state.affectedAddressModalOpened = true;
                var resolve = {
                    applications: function () {
                        return $scope.state.applicationsWithSameAddress;
                    },
                    address: function () {
                        return !strAddress ? $scope.item.address : strAddress;
                    },
                    defer: function () {
                        return deferred;
                    }
                };
                $scope.openModal('app/home/relief/_affectedAddressCheckModalTemplate.html', 'AffectedAddressCheckModalCtrl', $scope, resolve);
            } else {
                deferred.resolve();
            }
            return deferred.promise;
        };

        $scope.openAffectedAddressLgaModal = function () {
            var deferred = $q.defer();
            if (!$scope.state.affectedAddressLgaModalOpened) {
                $scope.state.affectedAddressLgaModalOpened = true;
                var lgaInvalidModel = {
                    region: $scope.state.affectedAddressLgaValidRegion.name,
                    lga: $scope.state.affectedAddressLgaName
                };
                var url = 'app/home/relief/_affectedAddressLgaCheckModalTemplate.html';
                var resolve = {
                    lgaInvalidModel: function () {
                        return lgaInvalidModel;
                    },
                    defer: function () {
                        return deferred;
                    }
                };
                $scope.openModal(url, 'AffectedAddressLgaCheckModalCtrl', $scope, resolve);
            } else {
                deferred.resolve();
            }
            return deferred.promise;
        };

        $scope.openModal = function (templateUrl, controller, scope, resolve) {
            utils.openModal(templateUrl, controller, scope, resolve);
        };

        $scope.searchLga = function (val) {
            lgaService.searchLga(val).then(function (response) {
                return response.data;
            },
                function (error) {
                   
                    return [];
                });
        };

        $scope.lgaModelLabel = function (lgaModel) {
            return lgaModel && lgaModel.name || '';
        };

        function getFieldFromGoogleAddressComponents(addressComponents, fieldName, isLongName) {
            var entry = addressComponents[fieldName];

            if (!entry) return null;
            if (isLongName)
                return entry.long_name;
            else
                return entry.short_name;
        };
        var mapGoogleMapsAddress = function (gMapsAddress, toAddress, addressString) {
            if (gMapsAddress.address_components) {
                var ac = {};

                _.each(gMapsAddress.address_components, function (c) {
                    _.each(c.types, function (t) {
                        ac[t] = c;
                    });
                });

                $scope.addressComponents = ac;

                // only VIC addresses allowed
                var state = getFieldFromGoogleAddressComponents(ac, "administrative_area_level_1", true);
                var postcode = getFieldFromGoogleAddressComponents(ac, "postal_code", true);
                if (state == "Victoria" || (postcode && postcode.length && postcode[0] == '3')) {
                    toAddress.unitNo = getFieldFromGoogleAddressComponents(ac, "subpremise", true);
                    toAddress.streetNo = getFieldFromGoogleAddressComponents(ac, "street_number", true);
                    toAddress.streetName = getFieldFromGoogleAddressComponents(ac, "route", true);
                    toAddress.suburb = getFieldFromGoogleAddressComponents(ac, "locality", true);
                    // restrict suburb to 18 characters to comply with Indue address restrictions.
                    if (toAddress.suburb && toAddress.suburb.length > 18) {
                        toAddress.suburb = toAddress.suburb.substring(0, 18);
                    }
                    toAddress.postcode = getFieldFromGoogleAddressComponents(ac, "postal_code", true);
                    toAddress.state = getFieldFromGoogleAddressComponents(ac, "administrative_area_level_1", false);

                    // Apply patches to the address, as there are some tweaks we apply to normalise the google stuff
                    utils.applyAddressPatches(toAddress, addressString);
                } else {
                    $window.alert("Please enter a valid Victoria address.");
                    toAddress.unitNo = "";
                    toAddress.streetNo = "";
                    toAddress.streetName = "";
                    toAddress.suburb = "";
                    toAddress.postcode = "";
                    toAddress.state = "";
                }
            }
        };
    }
})();