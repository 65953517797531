(function () {
    angular.module('phapApp')
        .directive('backButton', ['$window', function ($window) {
            return {
                scope: {
                    callback: '&'
                },
                restrict: 'A',
                link: function (scope, elem, attrs) {
                    elem.bind('click', function () {
                        $window.history.back();
                    });
                }
            };
        }]);
})();