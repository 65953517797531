(function () {
    angular.module('phapApp')
        .controller(
        'headerController',
        [
            '$scope', '$http', '$cookies', 'config', 'security', 'authenticationService',
            function ($scope, $http, $cookies, config, security, authenticationService) {
                $scope.showTitleSection = false;

                $scope.authMode = config.authMode;

                $scope.signOut = function () {
                    authenticationService.logOut();
                };


                $scope.changeUser = function() {
                    var userId = $scope.item.activeUser.id;
                    $http.defaults.headers.common = {
                        userId: userId
                    };

                    $cookies.put('UserId', userId);
                    
                };

                $scope.getUserId = function () {
                    return $cookies.get("UserId");
                };

                $scope.environment =  config.environment;
            }
        ]
    );
}());