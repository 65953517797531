(function () {
    angular.module('phapApp')
        .directive('paymentAmounts', ['Constants', 'utils', 'reestablishService', function (Constants, utils, reestablishService) {
            return {
                scope: true,
                restrict: 'E',
                templateUrl: 'app/home/reestablish/_payment-amounts.html',
                controller: ['$scope', function ($scope) {
                    $scope.getTotalClaimAmount = function() {
                        if ($scope.item) {
                            var paymentAmountsTotalClaimAmount =
                                parseFloat($scope.item.altAccomTotalClaimAmount || 0) +
                                    parseFloat($scope.item.essentialHouseholdTotalAmount || 0) +
                                    parseFloat($scope.item.amountOfDebrisRemoval || 0) +
                                    parseFloat($scope.item.essentialRepairsClaimAmount || 0) +
                                    parseFloat($scope.item.safetyReconnectionClaimAmount || 0) +
                                    parseFloat($scope.item.demolitionRebuildingClaimAmount || 0);

                            paymentAmountsTotalClaimAmount = Math.round(paymentAmountsTotalClaimAmount * 100) / 100;
                            $scope.item.paymentAmountsTotalClaimAmount = paymentAmountsTotalClaimAmount;
                            return paymentAmountsTotalClaimAmount;
                        }
                        return 0;
                    }
                }]
            };
        }]);
})();