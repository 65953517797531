(function () {


    var app = angular.module('phapApp');

    app.component('vendor', {
        templateUrl: 'app/home/vendor/vendor.html',
        bindings: {
            mode: '@',
            vendorId: '@',
            cancelClick: '&',
            displayMode: '@', //popup or standalone
            applySelectedVendor: '&',
            preferedDetails: '='
        },
        controller: ['vendorService', '$scope', '$routeParams', '$location', 'security', '$window', 'Constants', 'navigationService', 'notificationService', 'utils',
            function (vendorService, $scope, $routeParams, $location, security, $window, Constants, navigationService, notificationService, utils) {
                var vm = this;
                vm.selectedVendor = undefined;
                vm.isSaveInProgress = false;

                $scope.confirmationDialogConfig = {};
                $scope.vendorname = '';
                $scope.updateAttempt = 1;

                $scope.confirmationDialog = function () {
                    $scope.confirmationDialogConfig = {
                        title: "Deactivate Vendor - " + vm.details.name,
                        message: "Are you sure you want to deactivate this vendor ?",
                        buttons: [{
                            label: "Deactivate",
                            action: "deactivate"
                        }]
                    };
                    $scope.showDialog(true);
                };

                $scope.showDialog = function (flag) {
                    jQuery("#confirmation-dialog .modal").modal(flag ? 'show' : 'hide');
                };

                $scope.deactivateFn = function (event) {
                    $scope.confirmationDialog();
                }

                $scope.executeDialogAction = function (action) {
                    if (typeof $scope[action] === "function") {
                        $scope[action]();
                    }
                };

                $scope.deactivate = function () {
                    vm.details.status = 2;
                    vm.details.requestType = 'UserDeactivateVendor';
                    update(vm.details);
                    $scope.showDialog(false);
                };

                vm.$onInit = function () {
                    if (!vm.IsPopup()) {
                        navigationService.setCurrentMenuStatus(
                            navigationService.topMenuCodes.financeManagement,
                            navigationService.pageCodes.vendorManagement);
                    }

                    vm.details = {
                        name: vm.preferedDetails && vm.preferedDetails.vendorName,
                        status: null, //pending update
                        statusText: null,
                        abn: null,
                        bankName: vm.preferedDetails && vm.preferedDetails.accountName,
                        bsb: vm.preferedDetails && vm.preferedDetails.bsb,
                        account: vm.preferedDetails && vm.preferedDetails.accountNumber,
                        contactPerson: vm.preferedDetails && vm.preferedDetails.vendorName,
                        siteName: null,
                        phapVendorNumber: null,
                        phone: null,
                        fax: null,
                        email: null,
                        address: {
                            addressLine1: vm.preferedDetails && vm.preferedDetails.addressLine,
                            suburb: vm.preferedDetails && vm.preferedDetails.suburb,
                            state: vm.preferedDetails && vm.preferedDetails.state,
                            postcode: vm.preferedDetails && vm.preferedDetails.postcode
                        }
                    };
                    vm.isLoading = true;

                    if (IsInEditMode()) {
                        var vendorId = vm.IsStandAlone() === false ? vm.vendorId : $routeParams.id;
                        vm.IsSaveSuccess = vm.IsStandAlone() === false ? '' : $routeParams.fromCreate;

                        if (vendorId !== undefined && vendorId !== null) {
                            vendorService.getVendorDetails(vendorId).then(function (data) {
                                vm.details = data;
                                $scope.vendorname = vm.details.name;
                                if (vm.IsPopup()) {
                                    vm.selectedVendor = data;
                                }
                                vm.isLoading = false;
                            }, function (error) {
                                vm.isLoading = false;
                            });
                        }
                    }
                    else {
                        vm.details.status = Constants.vendorStatus.PendingUpdate;
                        vm.details.statusText = "Pending update";
                        vm.isLoading = false;
                    }

                    //getting logged in user security 
                    security.getCurrentUserSecurityInfo()
                        .then(function (result) {
                            vm.currentUserSecurityInfo = result;
                        },
                            function () {
                                vm.currentUserSecurityInfo = {};
                            });
                };

                vm.hasPermissions = function () {
                    if (vm.currentUserSecurityInfo != null) {
                        if (IsInEditMode()) {
                            return vm.currentUserSecurityInfo.privileges.ModifyVendor;
                        } else if (IsInCreateMode()) {
                            return vm.currentUserSecurityInfo.privileges.CreateVendor;
                        }
                    } else {
                        return false;
                    }
                }

                vm.save = function () {
                    if (vm.form.$valid) {
                        console.log(vm.errors);
                        vm.errors = [];
                        vm.alerts = [];
                        if (IsInCreateMode()) {

                            create(vm.details);

                        } else {
                            update(vm.details);
                        }
                    }
                };

                vm.Cancel = function () {
                    if (vm.IsStandAlone()) {
                        $window.history.back();
                    }
                    else {
                        vm.cancelClick();
                    }
                };

                vm.navigateToHistory = function () {
                    $location.path('/administration/history/vendor/' + vm.details.id);
                }

                function create(vendorModel) {
                    vm.isSaveInProgress = true;

                    vendorService.createVendor(vendorModel)
                        .then(function (response) {
                            if (vendorModel.requestType == null && (response.data == false || response.data == true)) {
                                vendorModel.requestType = 'checkedBSBAccountCombination';
                                if (response.data == false) {
                                    vm.alerts.push({ message: 'A vendor already exists with the same BSB and Account Number' })
                                }
                                vm.isSaveInProgress = false;
                            }
                            else {
                                vm.isSaveInProgress = false;
                                vm.details = response.data;
                                vm.IsSaveSuccess = true;
                                vm.form.$setPristine();
                                if (vm.IsStandAlone()) {
                                    $location.path("/home/vendor/edit/" + response.data.id + "/true");
                                } else {
                                    //Since in popup mode we are not redirecting we have to explicitly set mode from create to edit here.
                                    vm.mode = "edit";
                                    vm.selectedVendor = vm.details;
                                }
                            }
                        }, function (response) {
                            vm.isSaveInProgress = false;
                            vm.IsSaveSuccess = false;
                            if (response.data.errors !== undefined) {
                                vm.errors = response.data.errors;
                            }
                        });
                }

                function update(vendorModel) {
                    notificationService.notify('showLoadingPanel', true);
                    vm.isSaveInProgress = true;
                    vendorModel.requestType = 'UpdateVendor';
                    //if the status has been set to active we need to read the acitvate flag and set the status to pending approval
                    if (vm.details.activate == true) {
                        vendorModel.status = 0;
                    }
                    vendorService.updateVendor(vendorModel)
                        .then(function (response) {
                            if (vendorModel.requestType == null && (response.data == false || response.data == true)) {
                                vendorModel.requestType = 'checkedBSBAccountCombination';
                                vm.isSaveInProgress = false;
                                notificationService.notify('showLoadingPanel', false);
                                if (response.data == false) {
                                    vm.alerts.push({ message: 'A vendor already exists with the same BSB and Account Number' })
                                }
                            }
                            else {
                                vm.isSaveInProgress = false;
                                vm.details = response.data;
                                if (vm.IsPopup()) {
                                    vm.selectedVendor = vm.details;
                                }
                                vm.IsSaveSuccess = true;
                                vm.form.$setPristine();
                                notificationService.notify('showLoadingPanel', false);
                            }
                        }, function (response) {
                            notificationService.notify('showLoadingPanel', false);
                            vm.isSaveInProgress = false;
                            if (response.data.errors !== undefined) {
                                vm.errors = response.data.errors;
                            }
                        });
                };               
               
                vm.IsStandAlone = function () {
                    return vm.displayMode === 'standalone';
                }

                vm.IsPopup = function () {
                    return vm.displayMode === 'popup';
                }

                vm.applyClick = function () {
                    if (vm.selectedVendor !== undefined && vm.selectedVendor !== null) {
                        vm.applySelectedVendor({ selectedVendor: vm.selectedVendor });
                    } else {
                        alert("Please select the vendor");
                    }
                }

                vm.IsInActive = function () {
                    return vm.details.status === Constants.vendorStatus.Inactive;
                }

                function IsInEditMode() {
                    return vm.mode === 'edit';
                }

                function IsInCreateMode() {
                    return vm.mode === 'create';
                }

            }],
        controllerAs: 'vendor'
    });

})();