(function () {
    var app = angular.module('phapApp');
    app.component('financeDashboard', {
        templateUrl: 'app/financeManagement/dashboard/financeDashboard.html',
        bindings: {
            mode: '@'
        },
        controller: ['userService', 'cardShipmentService', '$location', '$scope', '$timeout', '$cookies', 'security', 'uiGridConstants', 'Constants', '$q', 'navigationService', 'notificationService', '$interval', 'localStorageService',
            function (userService, cardShipmentService, $location, $scope, $timeout, $cookies, security, uiGridConstants, constants, $q, navigationService, notificationService, $interval, localStorageService) {
            var vm = this;
            vm.loaded = false;

            vm.$onInit = function () {
                vm.allPromises = [];

                navigationService.setCurrentMenuStatus(
                    navigationService.topMenuCodes.financeManagement,
                    navigationService.pageCodes.financedashboard);

                // Load security information for the current user
                vm.allPromises.push(security.getCurrentUserSecurityInfo()
                    .then(function (result) {
                        vm.currentUserSecurityInfo = result;

                    },
                    function () {
                        vm.currentUserSecurityInfo = {};
                    })
                );
                $q.all(vm.allPromises).then(
                    function (result) {
                        vm.gridOptions = getGridOptions();

                        if (canViewTasks()) {
                            userService.getUserTasks().then(
                                function (response) {
                                    vm.taskData = response.data;
                                    vm.gridOptions.data = vm.taskData.pagedData;

                                    if (vm.taskData.pagedData.length == 0) {
                                        vm.noResultsFound = true;
                                    }
                                    vm.loaded = true;
                                });
                        }
                        else {
                            vm.loaded = true;
                        }
                    });
            };

            var getGridColumnDefs = function () {
                return [
                    {
                        field: 'id',
                        visible: false
                    },
                    {
                        field: 'taskNumber',
                        displayName: 'Task',
                        enableHiding: false,
                        width: '11%',
                        enableColumnMenu: false
                    },
                    {
                        field: 'description',
                        displayName: 'Description',
                        enableHiding: false,
                        width: '30%',
                        enableColumnMenu: false,
                        cellTemplate: 
                        '<span class="ui-grid-cell-contents">{{row.entity.description}} </span>' + 
                        '<span class="pull-right label label-primary cursor-default ui-grid-cell-contents" title="{{row.entity.numberOfCards}} cards">{{row.entity.numberOfCards}}' +
                        '<span class="glyphicon glyphicon-credit-card"></span></span>'
                    },
                    {
                        field: 'shipmentNumber',
                        displayName: 'Order number',
                        enableHiding: false,
                        width: '15%',
                        enableColumnMenu: false,
                        cellTemplate:
                        '<span class="ui-grid-cell-contents">{{row.entity.shipmentNumber}}</span>' +
                        '<a ' +
                        'id="{{row.entity.id}}-rec-report" href="" data-select-row="false" ' +
                        'ng-if="' + vm.currentUserSecurityInfo.privileges.ReceiveCardsForOrder + ' && ' +
                        'row.entity.taskType ==\'' + constants.cardShippingTaskType.receiveCardShipmentTask + '\' && ' +
                        'row.entity.taskStatus ==\'' + constants.cardShippingTaskStatus.closed + '\'" ' +
                        'ng-click="grid.appScope.runCardReconciliationReport(row)" >' +
                        '<span class="badge-wide pull-right" title="Card reconciliation report">' +
                        '<span class="glyphicon glyphicon-file"></span></span>' +
                        '</a>'
                    },
                    {
                        field: 'taskStatusDisplay',
                        displayName: 'State',
                        enableHiding: false,
                        width: '12%',
                        enableColumnMenu: false
                    },
                    {
                        field: 'dateCreated',
                        displayName: 'Created date',
                        enableHiding: false,
                        width: '14%',
                        enableColumnMenu: false,
                        cellClass: 'date-field',
                        cellFilter: constants.GridDateFormat
                    },
                    {
                        field: 'createdByFullName',
                        displayName: 'Created by',
                        enableHiding: false,
                        enableColumnMenu: false,
                        width: '18%',
                    }
                ]
            };

            var getGridOptions = function () {
                return {
                    enableSorting: true,
                    enableFiltering: false,
                    enableHorizontalScrollbar: uiGridConstants.scrollbars.ALWAYS,
                    enableVerticalScrollbar: uiGridConstants.scrollbars.ALWAYS,
                    enableRowSelection: false,
                    enableFullRowSelection: false,
                    enableRowHeaderSelection: false,
                    enableSelectAll: false,
                    multiSelect: false,
                    columnDefs: getGridColumnDefs(),
                    appScopeProvider: {
                        selectRow: function (row, event) {
                            if (!vm.preventEventBubble) {
                                $location.path("/financeManagement/cardShipmentOrder/edit/" + row.entity.cardShipmentId);
                            }
                            vm.preventEventBubble = false;
                        },
                        runCardReconciliationReport: function (row) {
                            exportShipmentReconcileReport(row.entity.cardShipmentId);
                            vm.preventEventBubble = true;
                        }
                    },
                    rowTemplate:
                    '<div ng-click="grid.appScope.selectRow(row, $event, 1)">' +
                        '<div class="ui-grid-cell cursor-pointer" ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" ui-grid-cell ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader }">' +
                        '</div>' +
                    '</div>',
                    onRegisterApi: function (gridApi) {
                        vm.gridApi = gridApi;
                        vm.gridApi.colResizable.on.columnSizeChanged($scope, saveState);
                        vm.gridApi.core.on.columnVisibilityChanged($scope, saveState);
                        vm.gridApi.core.on.filterChanged($scope, saveState);
                        vm.gridApi.core.on.sortChanged($scope, saveState);
                        // call resize every 500 ms for 5 s after modal finishes opening - usually only necessary on a bootstrap modal
                        $interval(function () {
                            vm.gridApi.core.handleWindowResize();
                        }, 500, 10);
                        // Restore previously saved state.
                        restoreState();
                    }
                }
            };

            function saveState() {
                var state = vm.gridApi.saveState.save();
                localStorageService.set('shipmentTracking.gridState.' + $cookies.get("UserId"), state);
            }

            function restoreState() {
                $timeout(function () {
                    var state = localStorageService.get('shipmentTracking.gridState.' + $cookies.get("UserId"));
                    if (state) {
                        vm.gridApi.saveState.restore($scope, state);
                    }
                });
            }

            var canViewTasks = function () {
                return vm.currentUserSecurityInfo.privileges.ViewMyTasks || vm.currentUserSecurityInfo.privileges.ViewAllTasks;
            }

            var exportShipmentReconcileReport = function (orderId) {
                cardShipmentService.getCardShipmentReconcileReport(orderId).then(
                    function (data) {
                        var file = new Blob([data], { type: 'text/csv' });
                        var timeStamp = moment().format(constants.fileTimeStampFormat);
                        saveAs(file, 'CardReconciliation_' + timeStamp + '.csv');
                    }
                );
            }

        }],
        controllerAs: 'financeDashboard'
    });

})();