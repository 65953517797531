(function () {
    var app = angular.module('phapApp');

    app.component('leftSubNav', {
        templateUrl: '/app/home/navigation/leftSubNavigation.html',
        bindings: {
            pageSections: '=',
            collapse: '=',
            className: '@',
            selectedItem: '=',
            page: '<',
            clickSub:'&'
        },
        controller: ['$timeout', 'utils', 'notificationService', '$location', 'security',
            function ($timeout, utils, notificationService, $location, security) {
            var vm = this;
            vm.showScrollerTop = false;
            security.getCurrentUserSecurityInfo().then(function (result) { vm.privileges = result.privileges });

            vm.clickMenuItem = function (section, event) {
                var itemName = section.id;

                if (!section.preventCollapse && section.subMenu != undefined) {
                    vm.toggleSubItems(section, event);
                    return;
                }

                if (section.url) {
                    $location.path(section.url);
                    vm.selectedItem = section.id;
                    vm.clickSub();
                } else {
                    if (angular.element('#' + itemName).length === 0) {
                        return;
                    }

                    if (vm.pageState !== section.pageState) {
                        vm.pageState = section.pageState;
                        notificationService.notify('pageState', { pageState: section.pageState });
                        utils.scrollToTop(section.pageState.id);
                    }

                    section.expanded = true;
                    $timeout(function () {
                        var headerBannerHeight = angular.element('#header-banner')[0].offsetHeight;
                        var topMenu = angular.element('#topMenu')[0].offsetHeight;
                        var pos = angular.element('#' + itemName).offset().top - angular.element('#fixed-form-header')[0].offsetHeight - headerBannerHeight - topMenu;
                        angular.element('body,html').animate({
                                scrollTop: pos
                            },
                            700);
                    });
                }
                

                event.stopPropagation();

            }

            vm.scrollToTop = function () {
                event.stopPropagation();
                angular.element('body,html').animate({
                        scrollTop: 0
                    },
                    700);
            }

            vm.toggleMenu = function () {
                vm.collapse = !vm.collapse;
            }
            vm.hasSubMenu = function (section) {
                return section.subMenu !== undefined;
            }
            vm.toggleSubItems = function (section, event) {
                event.stopPropagation();
                // $timeout(vm.showScrollerBottom);
                return section.hideSubItems = !section.hideSubItems;
            }

            vm.checkItemExists = function (section) {
                return angular.element('#' + section.id).length > 0;
            }

            vm.hasSecurity = function (security) {
                if (vm.privileges) {
                    // if there is no security then anyone can see it, otherwise if it is in the list then show
                    return security === undefined || vm.privileges[security] !== undefined;
                }
                return false;
            } 
            
        }],
        controllerAs: 'leftMenu'
    });


})();

