(function () {
    angular.module('phapApp')
        .service('documentService', ['$http', '$q', 'config', function ($http, $q, config) {
            var listFiles = function (ids) {
                if (ids == null || ids.length == 0) {
                    var deferred = $q.defer();
                    deferred.resolve({ data: [] });
                    return deferred.promise;
                }

                var url = "document/info/" + (angular.isString(ids) ? ids : ids.join());

                return $http.get(config.apiBaseUrl + url);
            };

            var upload = function (files, documentType) {
                var fd = new FormData();

                if (!angular.isUndefined(documentType)) {
                    fd.append('documentType', documentType);
                }

                files.forEach(function (f) {
                    fd.append('documents', f);
                });
                return $http({
                    url: config.apiBaseUrl + 'document',
                    method: 'POST',
                    data: fd,
                    headers: { 'Content-Type': undefined },
                    transformRequest: angular.identity
                });
            };
            var calculateFileSize = function (bytes) {
                var file = {
                    bytes: bytes,
                    precision: 1,
                    units: ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'],
                }
                file.number = Math.floor(Math.log(bytes) / Math.log(1024));
                file.size = (file.bytes / Math.pow(1024, Math.floor(file.number))).toFixed(file.precision);
                file.unit = file.units[file.number];
                return file;
            };

            var downloadFile = function (fileId) {
                return $http({
                    url: config.apiBaseUrl + 'document/' + fileId,
                    method: 'GET',
                    responseType: 'arraybuffer'
                });
            };

            return {
                list: listFiles,
                upload: upload,
                calculateFileSize: calculateFileSize,
                downloadFile: downloadFile
            };
        }]);
})();