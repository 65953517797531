(function () {

    var app = angular.module('phapApp');

    app.controller('addEditUserCtrl', ['$scope', 'Constants', 'utils', 'isAdd', 'data',
        function ($scope, constants, utils, isAdd, data) {
            var vm = this;
            vm.title = isAdd ? 'Add new user' : 'Edit user';
            vm.roles = data.roles;
            vm.existingUsers = data.existingUsers;
            vm.user = data.user;
            vm.errors = [];
            var validateNameExist = function () {
                vm.errors = [];
                var errorMessage = "Username exists. Please enter unique DHHS Username";
                var isNameExist = false;
                angular.forEach(vm.existingUsers,
                    function (user) {
                        if (user.id !== vm.user.id && user.userName.toLowerCase() === vm.user.loginName.toLowerCase()) {
                            isNameExist = true;
                        }
                    });
                if (isNameExist)
                    vm.errors.push({
                        message: errorMessage
                    });
                return vm.errors.length === 0;
            }
            vm.applyChanges = function () {
                if (!$scope.user.form.$invalid && validateNameExist()) {
                    $scope.$close(vm.user);
                }

            }
            vm.close = function () {
                $scope.$dismiss({ $value: 'cancel' });
            }

            vm.disabledSave = function () {

                return $scope.user.form.$invalid;

            }
           
        }
    ]);

})();
