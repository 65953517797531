(function() {
    angular.module('phapApp')
        .service('searchMemberService', ['$http', '$q', 'config', function ($http, $q, config) {

            var incidentMemberSearchUrl = config.apiBaseUrl + "relief/incident-member-search";
            var memberSearchUrl = config.apiBaseUrl + "relief/member-search";
            var householdMembersSearchUrl = config.apiBaseUrl + "relief/household-members-search";

            var incidentMemberSearch = function (data) {
                 return $http.post(incidentMemberSearchUrl, data);
            };

            var memberSearch = function (data) {
                return $http.post(memberSearchUrl, data);
            };

            var householdMembersSearch = function(data) {
                return $http.post(householdMembersSearchUrl, data);
            };

            return {
                incidentMemberSearch: incidentMemberSearch,
                memberSearch: memberSearch,
                householdMembersSearch: householdMembersSearch
            };


        }]);
})();