(function () {
    angular.module('phapApp')
        .directive('reestablishEligibilityOptions', ['$window', function ($window) {
            return {
                restrict: 'E',
                templateUrl: 'app/home/reestablish/_eligibilityOptions.html',
                controller: ['$scope', 'lgaService', '$uibModal', 'utils', '$q', controller]
            };
        }]);



    function controller($scope, lgaService, $uibModal, utils, $q) {
   
    }
})();