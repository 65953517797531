(function () {
    var app = angular.module('phapApp');
    app.controller('essentialHouseHoldPopupCtrl', ['$scope', '$uibModalInstance', 'newItem', function ($scope, $uibModalInstance, newItem) {
        var vm = this;
        vm.newItem = newItem;
        vm.ok = function () {
            $uibModalInstance.close(vm.newItem);
        };

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

    }
    ]);

})();
