(function () {
    angular
        .module("phapApp")
        .directive("addressView", ["$window", function ($window) {
            return {
                restrict: "E",
                templateUrl: "app/_directives/address-view.html",
                scope: {
                    showAddress: "=",
                    addressLga: "=",
                    controlPrefix: "=",
                    showLga: "=",
                    editTitle: "=",
                    isDisabled: "<"
                },
                controller: ["$scope", "$uibModal", "utils", "$q", addressViewCtrl],
                link: function ($scope, elem, attr) {
                    angular.element(elem).on("click change keydown", ".address-view input:not(button)", function (e) {
                        if (!$scope.isDisabled && !$scope.state.editAddressOpen) {
                            $scope.origin = $scope;
                            // Copy scope items into edit variables for use in the modal dialog.
                            $scope.editAddress = angular.copy($scope.showAddress);
                            $scope.editLga = angular.copy($scope.addressLga);
                            $scope.state.editAddressOpen = true;
                            var focus = angular.element(e.target).attr("name").split('_');
                            $scope.focus = focus[focus.length - 1];
                            $scope.openModal("app/_directives/address-edit.html", "AddressEditModalCtrl", $scope);
                            e.preventDefault();
                        }
                    });
                }
            };
        }]);

    function addressViewCtrl($scope, $uibModal, utils, $q) {

        if ($scope.state === undefined) {
            $scope.state = {};
        }
        $scope.state.editAddressOpen = false;
      
        if ($scope.showLga === undefined) {
            $scope.showLga = false;
        }
        if ($scope.isDisabled === undefined) {
            $scope.isDisabled = false;
        }

        $scope.openModal = function (templateUrl, controller, scope, resolve) {
            utils.openModal(templateUrl, controller, scope, resolve);
        };


    }
})();
