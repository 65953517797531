(function () {
    var app = angular.module('phapApp');

    app.component('leftNav', {
        templateUrl: '/app/home/navigation/leftNavigation.html',
        bindings: {
            pages: '<',
            collapse: '='
        },
		controller: ['navigationService', 'utils', 'notificationService', '$scope', '$location', '$timeout', '$rootScope',
            function (navigationService, utils, notificationService, $scope, $location, $timeout, $rootScope) {
            var vm = this;
            vm.showScrollerTop = false;
            vm.showScrollerBottom = false;

            notificationService.subscribe($scope, 'clickTopMenu',
                function (event, args) {
                    vm.subMenuSelected = args.leftSubMenuCode;
                    populateMenu(args.sectionCode, args.pageCode, args.showMenu);
                });

            notificationService.subscribe($scope, 'showMenu',
                function (event, args) {
                    displayMenu(args.pageCode, args.showMenu);
                });

            notificationService.subscribe($scope, 'changeMenu',
                function () {
                    vm.setScrollerBottom();
                });

            vm.$onInit = function () {
                navigationService.getMenu().then(function (result) {
                    vm.navStructure = result;

                    vm.sectionCode = navigationService.getCurrentMenuStatus().currentSectionCode;
                    vm.currentPageCode = navigationService.getCurrentMenuStatus().currentPageCode;
                    vm.subMenuSelected = navigationService.getCurrentMenuStatus().currentLeftSubMenuCode;
                    populateMenu(vm.sectionCode, vm.currentPageCode);
                    notificationService.notify('menuLoaded');
                });
            }

            vm.goToPage = function (page) {
				if (page.url) {
					if (page.code === 'financedashboard' || page.code.startsWith('cardShipmentOrder')) {
						$rootScope.pageUrl = page.url;
					}
                    vm.currentPageCode = page.code;
                    resetMenu();
                    vm.setScrollerBottom();
                    $location.path(page.url);
                }
            };

            vm.toggleSubItems = function (page, event) {
                event.stopPropagation();
                $timeout(vm.setScrollerBottom());
                return page.collapseMenu = !page.collapseMenu;
            }
            function resetMenu() {
                vm.navStructure.sections.forEach(function(section) {
                    section.pages.forEach(function (page) {
                        if (page.code == navigationService.pageCodes.reestablishApplications
                            || page.code == navigationService.pageCodes.reliefApplications) {
                            page.showSubMenu = false;
                        }
                    })
                })
                vm.showScrollerTop = false;
                angular.element('#leftMenuItems').css('margin-top', '0px');
            }
            
            vm.setScrollerBottom = function () {
                $timeout(function () {
                    if (!vm.currentPageCode) return;

                    var menuItemsHeight = angular.element('#leftMenuItems').height();
                    var containerHeight = angular.element('#leftNavigation').height();
                    var currentPos = parseInt(angular.element('#leftMenuItems').css('margin-top'));
                    vm.showScrollerBottom = menuItemsHeight + currentPos > containerHeight;

                }, 500)
                
            };
            vm.scrollUp = function () {
                angular.element('#leftMenuItems').animate({ marginTop: "0px" });
                vm.showScrollerTop = false;
                vm.setScrollerBottom();
            };
            vm.scrollDown = function () {
                var maxTop = angular.element('#leftMenuItems').height() - angular.element('#leftNavigation').height() + 100;
                var newPos = angular.element('#leftMenuItems').css('margin-top');
                var newPosInt = parseInt(newPos) - 100;
                if (maxTop + newPosInt > 0 ) {
                    angular.element('#leftMenuItems').animate({ marginTop: newPosInt + "px" });
                    vm.showScrollerTop = true;
                } else {
                    vm.showScrollerBottom = false;
                }
                
            };

            vm.clickSubMenu = function (page) {
                vm.currentPageCode = page.code;
            }

            function populateMenu(sectionCode, pageCode, showMenu) {
                if (!vm.navStructure) return;
                resetMenu();
                vm.currentPageCode = pageCode;
                vm.sectionCode = sectionCode;
                // get the section to retrieve the pages for that left menu
                var section = _.find(vm.navStructure.sections, function (value) {
                      return value.code === sectionCode;
                });
                if (section) {
                    vm.pages = section.pages;
                }
                 // if there is a page code specified then select it and show the submenu if it exists
                  displayMenu(pageCode, showMenu === undefined ? true : showMenu);
                  vm.setScrollerBottom();
            }

            function displayMenu(pageCode, show) {
                if (pageCode) {
                    var page = _.find(vm.pages, function (value) {
                        return value.code === pageCode;
                    });
                    if (page && vm.subMenuSelected) page.showSubMenu = show;
                };
            }
        }],
        controllerAs: 'leftNav'
    });


})();

