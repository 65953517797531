phapApp.directive('scrollBottom', ['$routeParams', '$location',
	function ($routeParams, $location) {
		return {
			link: function (scope, element, attr) {
				var attributeValue = attr.scrollBottom;
				if ($location.path().indexOf("home/reestablish") == -1) {
					if (attributeValue) {
						if ($routeParams && _.isEmpty($routeParams) === false &&
							$routeParams[attributeValue] && $routeParams.id) {
							$("html, body").animate({ scrollTop: 20000 }, 100);
						}
					}
					else if ($routeParams.id) {
						$("html, body").animate({ scrollTop: 20000 }, 100);
					}
					element.click(function () {
						$("html, body").animate({ scrollTop: 20000 }, 100);
					});
				}
			}
		}
	}
]);