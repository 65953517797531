(function () {
	angular.module('phapApp')
		.service('errorReportingService', ['$ngConfirm', '$rootScope', '$log', '$timeout', 'utils', 'adalAuthenticationService',
			function ($ngConfirm, $rootScope, $log, $timeout, utils, adalAuthenticationService) {
				// $rootScope.$on('unhandled-server-error', unhandledServerError);

				//donot show popup for activity request save and submit because those request take long to complete
				var unhandledServerError = function (scope, rejection) {
					if (rejection.config && rejection.config.url) {
						if (rejection.config.url.indexOf("api/activityrequest/submit") !== -1 ||
							rejection.config.url.indexOf("api/activityrequest/save") !== -1 ||
							rejection.config.url.indexOf("api/activityrequest/approve") !== -1) {
							return;
						}
					}
					if (rejection.config && rejection.config.url && rejection.config.url.lastIndexOf(".html") >= 0) {
						$log.info("Ignoring failed request for template (avoids infinite loop due to failure to display error dialog to report error loading template for error dialog)");

						return;
					}

					var errorText = "";

					var createdScope = $rootScope.$new();

					createdScope.rejection = rejection;
					createdScope.displaySettings = { showDetails: false };
					createdScope.copyRejectionDetail = function () {
						var copyText = document.querySelector("#rejectionDetail");
						copyText.select();
						document.execCommand("Copy");
						createdScope.recentlyCopied = true;
						$timeout(function () { createdScope.recentlyCopied = false; createdScope.apply(); }, 2500);
					};

					$ngConfirm({
						title: rejection.status == -1 ? 'Failed to contact server' : 'Unexpected error',
						contentUrl: '/app/unhandledError.html',
						scope: createdScope,
						columnClass: 'large',
						type: 'red',
						buttons: {
							ok: {
								text: 'Ok',
								action: function (scope, button) {
									return true;
								}
							}
						}
					});
				}

				$rootScope.$on('handleError', function (scope, rejection) {
					switch (rejection.status) {
						case 600: content = "The user is not authenticated."; errorTitle = "PHAP Error"; break;
                        case 601: content = "The user is not recognised in the system."; errorTitle = "PHAP Error"; break;
                        case 602: content = "The user account is inactive."; errorTitle = "PHAP Error"; break;
						case 401: content = "The user does not have the appropriate privileges to access this page."; errorTitle = "PHAP Error"; break;
						case 404: content = "Server error: Path not found."; errorTitle = "PHAP Error"; break;
						case 409: content = "Unable to save: The record to be saved has already been updated by another user.  Please refresh current page to get the latest version."; errorTitle = "Concurrency Error"; break;
						default: unhandledServerError(scope, rejection); return;
					}
					var createdScope = $rootScope.$new();
					$ngConfirm({
						title: errorTitle,
						content: content,
						scope: createdScope,
						columnClass: 'large',
						type: 'red',
						buttons: {
							ok: {
								text: 'Ok',
								action: function (scope, button) {
									if (rejection.status == 600 || rejection.status == 601 || rejection.status == 602) {
										adalAuthenticationService.logOut();
									}
									return true;
								}
							}
						}
					});
				});



				return {
				};
			}]);
})();