(function () {
    angular.module('phapApp')
        .directive('alternativeAccommodation', ['Constants', 'utils', function (Constants, utils) {
            return {
                scope: true,
                restrict: 'E',
                templateUrl: 'app/home/reestablish/_alternative-accommodation.html',
                controller: ['$scope', 'reestablishService', function ($scope, reestablishService) {

                    $scope.uploadController = {};

                    // #region Business Rule checking

                    $scope.$watchCollection('[item.altAccomEvidenceAttached]', function (newValues) {
                        if (!angular.isDefined(newValues[0]))
                            return;
                        $scope.altAccomCheckBusinssRules();
                    });

                    $scope.$on('dataLoaded', function () {
                        $scope.altAccomCheckBusinssRules();
                    });

                    $scope.altAccomCheckBusinssRules = function () {
                        var amount = utils.parseDollarAmount($scope.item.altAccomTotalClaimAmount);
                        $scope.formSections.categoriesOfAssessment.subMenu.altAccom.evidenceRequired = (amount > 0 && $scope.item.altAccomEvidenceAttached === false);
                        reestablishService.setExceptionalCircumstances($scope.formSections, $scope.item);
                    };

                    // #endregion

                    var noDocumentsAttached = function () {
                        if ($scope.item && $scope.item.documents) {
                            var documents = $scope.item.documents[Constants.reestablishSections.altAccom];
                            return !angular.isDefined(documents) || documents.length === 0;
                        }
                        return false;
                    };

                    var confirm = function () {
                        var title = 'Evidence documents required!';
                        var content = 'Evidence attached has been selected as Yes and no attachments have been loaded. \nDocuments must be loaded in order to set the Evidence attached field to Yes.';
                        utils.confirm(title, content, 'Proceed', 'Cancel')
                            .then(function () {
                                $scope.item.altAccomEvidenceAttached = false;
                            }, function () {
                                $scope.item.altAccomEvidenceAttached = null;
                            });
                    };

                    $scope.checkEvidence = function () {
                        if (noDocumentsAttached()) {
                            $scope.uploadController.$newUpload();
                        }
                    };

                    $scope.onCancelUpdate = function () {
                        varifyDocumentsAttached();
                    };

                    $scope.onFileListChanged = function () {
                        varifyDocumentsAttached();
                    };

                    var varifyDocumentsAttached = function () {
                        if ($scope.item && $scope.item.altAccomEvidenceAttached) {
                            if (noDocumentsAttached()) {
                                confirm();
                            }
                        }
                    };

                    $scope.maxWeeks = 0;
                    var haveTriggeredLoadOfAltAccomConfig = false;
                    $scope.$watch('item.incident.effectiveDate', function (nv, ov) {
                        if (nv != null && (!haveTriggeredLoadOfAltAccomConfig || nv != ov)) {
                            reestablishService.loadPeriodConfig(nv).then(function (data) {
                                if (data.altAccomConfig && data.altAccomConfig.value) {
                                    $scope.maxWeeks = parseInt(data.altAccomConfig.value);
                                }
                                if (data.uninhabitableDays && data.uninhabitableDays.value)
                                    $scope.item.uninhabitableDays = data.uninhabitableDays.value;
                            });

                            haveTriggeredLoadOfAltAccomConfig = true;
                        }
                    });


                    $scope.$watchGroup(['maxWeeks', 'item.altAccomNumberOfWeeks', 'item.altAccomWeeklyAccomExpense', 'item.altAccomWeeklyAltAccomExpense'], function (nv) {
                        if (nv && $scope.item) {
                            var weeks = $scope.item.altAccomNumberOfWeeks || 0;
                            var expense1 = $scope.item.altAccomWeeklyAccomExpense || 0;
                            var expense2 = $scope.item.altAccomWeeklyAltAccomExpense || 0;
                            if (weeks > $scope.maxWeeks) {
                                weeks = $scope.maxWeeks;
                            };
                            var total = weeks * (convertToAmount(expense2) - convertToAmount(expense1));
                            if (total < 0) total = 0;
                            $scope.item.altAccomTotalClaimAmount = total;
                            $scope.altAccomCheckBusinssRules();
                        }
                    });

                    var convertToAmount = function (amount) {
                        return amount.toString().trim().replace(/,/g, "").replace('$', '') *1;
                    };

                    $scope.$watch('item.applicant.memberType.id', function (nv) {
                        if (nv != null && nv == $scope.memberTypes.owner) {
                            $scope.item.altAccomWeeklyAccomExpense = 0;
                        }
                    });
                }]
            };
        }]);
})();